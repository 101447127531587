import React, { useMemo, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useDebounce from '../../../../../../helpers/hooks/useDebounce';
import useSecuritySearch from './hooks/useSecuritySearch';

const CASH_OPTION = '$CASH ($CASH)';

const InputTicker: React.FC<{
  ticker?: string;
  setTicker: (ticker: string) => void;
}> = ({ setTicker }) => {
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchTerm = useDebounce(inputValue, 500);
  const {
    isLoading,
    isError,
    data: tickerList,
  } = useSecuritySearch(debouncedSearchTerm);

  // Models can have a valid ticker or cash
  const optionsList = useMemo(() => {
    if (isError) {
      return [];
    }
    return [CASH_OPTION, ...(tickerList || [])];
  }, [tickerList]);

  const handleSearchSecurity = (value: string) => {
    setInputValue(value);
  };

  const handleChange = (value: string) => {
    setTicker(value);
  };

  return (
    <div>
      <InputLabel
        htmlFor="ticker"
        sx={{ fontSize: 14, color: '#282829', marginBottom: '4px' }}
      >
        Security Name/Symbol
      </InputLabel>
      <Autocomplete
        disablePortal
        options={optionsList}
        loading={isLoading}
        loadingText="Please wait..."
        onChange={(e, value) => handleChange(value)}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={'Security Name/Symbol'}
            onChange={e => handleSearchSecurity(e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default InputTicker;
