import { Grid } from '@mui/material';
import { PortfolioStatisticsLineChart } from 'pages/Cases/components/PortfolioStatisticsLineChart';
import SummaryPerformanceTable from 'pages/Cases/components/SummaryPerformanceTable';
import {
  columnsGrowth,
  getPortfolioStatisticsRows,
  portfolioStatisticsChartFilter,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';
import { ChartBox, SlideTitle } from '../../../../features/slides';
export type GrowthSlideProps = {
  readonly filteredPerformance: any;
  readonly startDate?: string;
  readonly endDate?: string;
  readonly rowsGrowthTable?: boolean;
};

export function GrowthSlide({
  filteredPerformance,
  startDate,
  endDate,
  rowsGrowthTable,
}: GrowthSlideProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>
          Growth of $ / Annualized Statistics: {`${startDate} - ${endDate}`}
        </SlideTitle>
      </Grid>

      <ChartBox>
        <PortfolioStatisticsLineChart
          data={portfolioStatisticsChartFilter({
            filteredPerformance,
            name: 'performance_chart',
          })}
          formatChart={{
            unit: '',
            decimals: 2,
            prefix: '$',
          }}
          height={rowsGrowthTable ? 620 : 800}
        />
      </ChartBox>
      {rowsGrowthTable && (
        <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
          <SummaryPerformanceTable
            rows={getPortfolioStatisticsRows(
              portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'performance_stats',
              }),
            )}
            columns={columnsGrowth}
          />
        </Grid>
      )}
    </Grid>
  );
}
