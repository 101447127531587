import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CharlesSchwabIcon from '@icons/CharlesSchwabLogo';
import FidelityIcon from '@icons/Fidelity';
import BNYIcon from '@icons/BNYLogo';
import CRMCard from './CRMCard';
import RedtailIcon from '@icons/Redtail';
import WealthboxIcon from '@icons/Wealthbox';
import HubspotIcon from '@icons/Hubspot';
import SalesforceIcon from '@icons/SalesforceLogo';
import { actions as globalActions } from 'app/features/Global/slice';
import { useDispatch } from 'react-redux';
import { useRESTQuery } from '@hooks/useRESTQuery';
import CustodianCard from './CustodianCard';
import { useSearchParams } from 'react-router-dom';
import SuccessModal from '@components/SuccessModal';
import { Grid, Typography, Stack } from '@mui/material';
import { HUBSPOT_URL, SALESFORCE_URL, WEALTH_URL } from '@common/constant';
import { useMutation, useQueryClient } from 'react-query';
import { ECustodians } from 'app/enums';
import CustodianDrawer from './CustodianDrawer';
import { makeStyles } from '@mui/styles';
import { generateDirectionLetter, generateDocuSign } from './mutation';
import { shouldForwardProp } from 'utils/forwardProp';
import MemoOrionlIcon from '@icons/OrionLogo';
import { isEmpty } from 'utils/isEmpty';
import AltruistLogo from 'assets/images/Altruist_logo.png';

interface HeaderProps {
  active?: boolean;
}

const Header = styled(Typography).withConfig({
  shouldForwardProp,
})<HeaderProps>`
  cursor: pointer;
  color: ${({ active }) => (active ? '#fff' : '#475467')};
  background-color: ${({ active }) => (active ? '#000' : 'transparent')};
  border-radius: 50vw;
  padding: 4px 12px;
  font-size: 12px !important;
  line-height: 16px !important;
`;

const ContentContainer = styled(Grid)`
  height: 92%;
  max-height: 77vh;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
  overflow-y: auto;
  column-gap: 2rem;
  row-gap: 2rem;

  @media (min-width: 1200px) {
    column-gap: 1.75rem;
    row-gap: 1.75rem;
  }
`;
interface INestedData {
  logo: React.ReactElement | string;
  name: string;
  description: string;
  link?: string;
  source?: string;
  modalDescription?: string;
  modalInput?: string;
}

interface INestedProps {
  [key: string]: INestedData;
}

export const cardData: INestedProps = {
  Schwab: {
    logo: <CharlesSchwabIcon />,
    name: 'Charles Schwab',
    description:
      'Connect your Master Account Number to link your clients and accounts',
    source: ECustodians.Schwab,
    modalDescription:
      'Enter your Master Account Number to link clients and portfolios',
    modalInput: 'Master Account Number',
  },
  FidelityInvestmentsTrust: {
    logo: <FidelityIcon />,
    name: 'Fidelity Investments Trust',
    description:
      'Connect your Rep Code or G-Number to link your clients and accounts',
    source: ECustodians.FidelityInvestmentsTrust,
    modalDescription:
      'Connect your Fidelity Rep Code or G-Number to link clients and portfolios ',
    modalInput: 'Rep Code / G-number / Branch Code 3030#',
  },
  FidelityInstitutionalBrokerage: {
    logo: <FidelityIcon />,
    name: 'Fidelity Institutional Brokerage',
    description:
      'Connect your Rep Code or G-Number to link your clients and accounts',
    source: ECustodians.FidelityInstitutionalBrokerage,
    modalDescription:
      'Connect your Fidelity Rep Code or G-Number to link clients and portfolios ',
    modalInput: 'Rep Code / G-number / Branch Code 3030#',
  },
  Pershing: {
    logo: <BNYIcon />,
    name: 'BNY Mellon Pershing',
    description: 'Connect your IP Number and link your clients and accounts',
    source: ECustodians.Pershing,
    modalDescription:
      'Connect your IP Number and link your clients and accounts',
    modalInput: 'IP Number',
  },
  Orion: {
    logo: <MemoOrionlIcon />,
    name: 'Orion',
    description:
      'Connect your Orion Account and link your clients and accounts',
    source: ECustodians.Pershing,
    modalDescription:
      'Connect your Orion Account and link your clients and accounts',
    modalInput: 'IP Number',
  },
  Altruist: {
    logo: (
      <img src={AltruistLogo} width={120} height={40} alt="Altruist Logo" />
    ),
    name: 'Altruist Financial',
    description:
      'Connect your Master Account Number to link your clients and accounts',
    source: ECustodians.Altruist,
    modalDescription:
      'Enter your Master Account Number to link clients and portfolios',
    modalInput: 'Master Account Number',
  },
};

const CRMCardData: INestedProps = {
  Salesforce: {
    logo: <SalesforceIcon />,
    name: 'Salesforce',
    description:
      'Connect your Salesforce account to link your clients and prospects',
    link: SALESFORCE_URL,
  },
  Wealthbox: {
    logo: <WealthboxIcon />,
    name: 'Wealthbox',
    description:
      'Connect your Wealthbox account to link your clients and prospects',
    link: WEALTH_URL,
  },
  Redtail: {
    logo: <RedtailIcon />,
    name: 'Redtail',
    description:
      'Connect your Redtail account to link your contacts and their portfolios',
    link: '',
  },
  Hubspot: {
    logo: <HubspotIcon />,
    name: 'Hubspot',
    description:
      'Connect your Hubspot account to link your clients and prospects',
    link: HUBSPOT_URL,
  },
};

export const useStyles = makeStyles({
  drawer: {
    width: 600,
  },
  drawerPaper: {
    width: 600,
  },
  root: {
    display: 'flex',
  },
});

function IntegrationPage(): React.JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();

  const mutateGenerateDocuSign = useMutation(generateDocuSign);
  const mutateGenerateDirectionLetter = useMutation(generateDirectionLetter);

  const [selectedOption, setSelectedOption] = React.useState<number | null>(2);
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [integration, setIntegration] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [ctaMessage, setCtaMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  // loading staes for custodian ctas
  const [loadingCtas, setLoadingCtas] = useState<boolean[]>(
    Array(Object.values(cardData).length).fill(false),
  );
  // custodian modal states
  const [open, setOpen] = React.useState<boolean>(false);
  const [modalData, setModalData] = useState<INestedData>({
    logo: '',
    name: '',
    description: '',
  });

  const handleModalClose = () => {
    setSuccessModal(false);
    if (selectedOption === 2) {
      window.location.href = '/integrations';
    }
  };

  const handleCRMSuccess = () => {
    setSuccessMessage('Succesfully Connected!');
    setCtaMessage(
      'Please allow a few minutes for your contacts to flow into Sage',
    );
    setSuccessModal(true);
  };

  const handleCustodianSuccess = () => {
    setSuccessMessage('Document Signed Succesfully!');
    setCtaMessage(
      'Thanks for signing the authorization letter. You will see client portfolio data come through in 2 to 3 business days. We will notify you when that feed becomes active. You can also check the integration status on the Integrations page.',
    );
    setSuccessModal(true);
  };

  const handleOpen = (name: string, status?: string) => {
    const selectedCustodian = Object.values(cardData).find(
      custodian => custodian.name === name,
    );

    if (name === 'Orion' && status === 'CONNECTED') {
      disconnectOrionIntegration();
    }

    if (selectedCustodian && selectedCustodian.name !== 'Orion') {
      setModalData(selectedCustodian);
      setOpen(true);
    }
    if (
      selectedCustodian &&
      selectedCustodian.name === 'Orion' &&
      status !== 'CONNECTED'
    ) {
      window.location.href = `${process.env.REACT_APP_ORION_BASE_URL}/oauth?response_type=code&client_id=${process.env.REACT_APP_ORION_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ORION_REDIRECT_URI}`;
    }
  };

  const toggleHeader = (divNumber: number) => setSelectedOption(divNumber);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    divNumber: number,
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleHeader(divNumber);
    }
  };

  const onErrorResponse = error => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string'
    ) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: error.response.data.message,
        }),
      );
    } else {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Something went wrong',
        }),
      );
    }
  };

  const handleSuccess = res => {
    if (!isEmpty(res)) {
      res.redirectUrl && window.location.replace(res.redirectUrl);
      queryClient.invalidateQueries('integrations');
    } else {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Something went wrong.',
        }),
      );
    }
  };

  const handleConnect = (id: string, source: string) => {
    if (id && source) {
      const loadIndex = Object.values(cardData).findIndex(
        item => item.source === source,
      );
      const loadingIndexes = loadingCtas.map((item, i) =>
        i === loadIndex ? true : item,
      );
      setLoadingCtas(loadingIndexes);

      const payload = {
        integrationId: id,
      };
      if (source === ECustodians.FidelityInstitutionalBrokerage) {
        mutateGenerateDirectionLetter.mutate(payload, {
          onSuccess: handleSuccess,
          onError: onErrorResponse,
          onSettled: () => {
            setLoadingCtas(prev =>
              prev.map((item, index) => (index === loadIndex ? false : item)),
            );
          },
        });
      } else {
        mutateGenerateDocuSign.mutate(payload, {
          onSuccess: handleSuccess,
          onError: onErrorResponse,
          onSettled: () => {
            setLoadingCtas(prev =>
              prev.map((item, index) => (index === loadIndex ? false : item)),
            );
          },
        });
      }
    }
  };

  const { refetch: wbAuthApi } = useRESTQuery(
    ['wealthbox'],
    {
      endpoint: 'integrations/crm/wealthbox/authenticate',
      body: { code: authCode },
      method: 'POST',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      enabled: false,
      cacheTime: 0,
      onSuccess: () => {
        handleCRMSuccess();
      },
      onError: () => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage: 'Wealthbox authentication failed. Please try again.',
          }),
        );
      },
    },
  );

  const { refetch: sfAuthApi } = useRESTQuery(
    ['salesforce'],
    {
      endpoint: 'integrations/crm/salesforce/authenticate',
      body: { code: authCode },
      method: 'POST',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      enabled: false,
      cacheTime: 0,
      onSuccess: () => {
        handleCRMSuccess();
      },
      onError: () => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage: 'Salesforce authentication failed. Please try again.',
          }),
        );
      },
    },
  );

  const { refetch: integrationListApi } = useRESTQuery(
    ['integrations'],
    {
      endpoint: 'integrations/',
      queryParams: {},
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      onSuccess: res => {
        if (!isEmpty(res) && !isEmpty(res.message)) {
          if (res.message === 'Success') {
            setRows(res.data);
          } else {
            setRows(res.data);
          }
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Something went wrong.',
            }),
          );
        }
      },
      onError: error => {
        onErrorResponse(error);
      },
    },
  );

  const { refetch: orionIntegration } = useRESTQuery(
    ['orionIntegration'],
    {
      endpoint: `integrations/orion/authenticate?code=${authCode}`,
      queryParams: {},
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      enabled: false,
      cacheTime: 0,
      onSuccess: () => {
        handleCRMSuccess();
      },
      onError: () => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage: 'Orion authentication failed. Please try again.',
          }),
        );
      },
    },
  );

  const { refetch: disconnectOrionIntegration } = useRESTQuery(
    ['orion-disconnect'],
    {
      endpoint: 'integrations/orion/disconnect',
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      enabled: false,
      cacheTime: 0,
      onSuccess: () => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: 'Orion disconnected successfully',
          }),
        );
      },
      onError: () => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage: 'Orion disconnected Failed',
          }),
        );
      },
    },
  );

  const { refetch: hubSoptIntegration } = useRESTQuery(
    ['hubspotIntegration'],
    {
      endpoint: `integrations/crm/hubspot/authenticate?code=${authCode}`,
      queryParams: {},
      method: 'POST',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      enabled: false,
      cacheTime: 0,
      onSuccess: () => {
        handleCRMSuccess();
      },
      onError: () => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage: 'HubSpot authentication failed. Please try again.',
          }),
        );
      },
    },
  );

  useEffect(() => {
    if (location.pathname.startsWith('/authorize')) {
      const _integration = location.pathname.split('/')[2];
      if (location.search?.startsWith('?code=')) {
        const code = location.search?.split('=', 2)[1];
        if (code) {
          setAuthCode(decodeURIComponent(code));
          setIntegration(_integration);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (integration && authCode) {
      if (integration === 'orion' && typeof orionIntegration === 'function') {
        setLoading(true);
        orionIntegration();
      }
      if (integration === 'hubspot' && typeof orionIntegration === 'function') {
        setLoading(true);
        hubSoptIntegration();
      }
      if (integration === 'wealthbox' && typeof wbAuthApi === 'function') {
        setLoading(true);
        wbAuthApi();
      } else if (
        integration === 'salesforce' &&
        typeof wbAuthApi === 'function'
      ) {
        setLoading(true);
        sfAuthApi();
      }
    }
  }, [authCode, integration, wbAuthApi, sfAuthApi, orionIntegration]);

  useEffect(() => {
    if (searchParams.get('connected')) {
      handleCustodianSuccess();
    }
    if (searchParams.get('tab') === 'custodians') {
      setSelectedOption(1);
      setSearchParams({});
    }
  }, [searchParams]);

  return (
    <Grid container textAlign={'center'} rowGap={2} sx={{ p: 2 }}>
      <Grid
        container
        ml={1.2}
        sx={{
          lineHeight: '34.5px',
          borderBottom: '1px solid #E4E7EC',
          color: '#000000',
          fontWeight: '500',
          fontSize: '24px',
          margin: 0,
          paddingBottom: '16px',
        }}
      >
        Integrations
      </Grid>
      <Stack
        direction="row"
        ml={1.2}
        p={'4px'}
        sx={{
          backgroundColor: '#f5f5f5',
          borderRadius: '50vw',
        }}
      >
        <Header
          tabIndex={0}
          active={selectedOption === 1}
          onClick={() => toggleHeader(1)}
          onKeyDown={event => handleKeyDown(event, 1)}
          sx={{ width: '93px' }}
        >
          Custodian
        </Header>
        <Header
          tabIndex={0}
          active={selectedOption === 2}
          onClick={() => toggleHeader(2)}
          onKeyDown={event => handleKeyDown(event, 2)}
          sx={{ width: '56px' }}
        >
          CRM
        </Header>
      </Stack>
      <ContentContainer container>
        {selectedOption === 1 &&
          Object.keys(cardData).map((item, index) => {
            const custodianStatus = rows?.integrationsList?.find(
              e => e.name === item,
            );
            const custodianDetails = cardData[item];

            const isLoading = loadingCtas[index];
            return (
              <CustodianCard
                key={index}
                {...custodianDetails}
                custodianData={custodianStatus}
                isLoading={isLoading}
                handleOpen={handleOpen}
                handleConnect={handleConnect}
              />
            );
          })}
        {selectedOption === 2 &&
          Object.keys(CRMCardData).map((item, index) => {
            const crmStatus = rows?.integrationsList?.find(
              e => e.name === item,
            );
            const crmDetails = CRMCardData[item];
            return (
              <CRMCard
                key={index}
                {...crmDetails}
                crmData={crmStatus}
                integrationListApi={integrationListApi}
                handleSuccess={() => handleCRMSuccess()}
                isLoading={
                  crmDetails?.name.toLowerCase() === integration && loading
                }
              />
            );
          })}
      </ContentContainer>
      <div className={classes.root}>
        <CustodianDrawer
          open={open}
          setOpen={setOpen}
          modalData={modalData}
          onConnectSuccess={handleConnect}
        />
      </div>
      <SuccessModal
        isOpen={successModal}
        cta={'Continue'}
        message={successMessage}
        ctaMessage={ctaMessage}
        handleCtaClick={handleModalClose}
        handleClose={handleModalClose}
      />
    </Grid>
  );
}

export default IntegrationPage;
