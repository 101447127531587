import { Grid, Typography } from '@mui/material';
import { SlideSubtitle, SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import { ModelSetHolding } from 'features/premium-report/api/types';
export function ExplanationOfModels({
  modelSetHoldings,
}: {
  modelSetHoldings: ModelSetHolding;
}): React.ReactElement {
  return (
    <Grid container direction="row" spacing={1} className={styles.container}>
      <Grid item>
        <SlideTitle>Explanation of Models</SlideTitle>
      </Grid>
      <Grid item>
        <SlideSubtitle>
          {modelSetHoldings.abbreviated_model_set_name}
        </SlideSubtitle>
        <Typography component="p" className={styles.paragraph}>
          {modelSetHoldings.model_explaination}
        </Typography>
      </Grid>
    </Grid>
  );
}
