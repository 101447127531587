import React, { Fragment, useRef } from 'react';
import { Markup } from 'interweave';
import { isComponent } from 'utils/common/string';

interface HTMLParserProps {
  content: string;
}

export const HTMLParser = ({
  content = '',
}: HTMLParserProps): React.JSX.Element => {
  return (
    <Fragment>
      {isComponent(content) ? content : <Markup content={content} />}
    </Fragment>
  );
};

export function createReactElementFromString(
  htmlString: string,
): React.ReactNode | null {
  if (htmlString && !htmlString.includes('<table'))
    return <div>{htmlString}</div>;
  const containerRef: any = useRef(null);
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string into a DOM document
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Find the table element in the DOM
  const table = doc.querySelector('table');

  if (!table) {
    return <div />;
  }
  // Convert the HTMLTableElement to a React element manually
  const reactElement = (
    <table>
      {Array.from(table?.children).map((child: any, index: any) => (
        <tr key={index}>
          {Array.from(child?.children).map((td: any, tdIndex: any) => (
            <td key={tdIndex}>{td?.textContent}</td>
          ))}
        </tr>
      ))}
    </table>
  );

  return <div ref={containerRef}>{reactElement}</div>;
}
