import React from 'react';
import MarketplaceModelDetail from '../components/MarketplaceModelDetail';
import { useNavigate, useParams } from 'react-router-dom';

const MarketplaceModelId: React.FC = () => {
  const navigate = useNavigate();
  const { id: modelId } = useParams();

  const handleSaveSuccess = () => {
    navigate(-1);
  };

  return (
    <MarketplaceModelDetail
      modelId={modelId || ''}
      handleBackButtonClick={handleSaveSuccess}
    />
  );
};

export default MarketplaceModelId;
