// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Globals for slides */

.styles_slide__u530D {
  --blue-dark: #004f7e;
  --blue-light: #05acd8;
  --black: #2f302c;
  --white: #fff;
  width: 1280px;
  height: 720px;
  aspect-ratio: 16/9;
  background-color: #fff;
  font-family: 'Roboto', sans-serif !important;
}

.styles_slide__u530D * {
  font-family: 'Roboto', sans-serif !important;
}

.styles_slide__u530D > * {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.styles_slide__u530D :is(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Conversations/components/SlidesEditor/components/SlideRenderer/styles.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,oBAAoB;EACpB,qBAAqB;EACrB,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,4CAA4C;AAC9C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX","sourcesContent":["/* Globals for slides */\n\n.slide {\n  --blue-dark: #004f7e;\n  --blue-light: #05acd8;\n  --black: #2f302c;\n  --white: #fff;\n  width: 1280px;\n  height: 720px;\n  aspect-ratio: 16/9;\n  background-color: #fff;\n  font-family: 'Roboto', sans-serif !important;\n}\n\n.slide :global(*) {\n  font-family: 'Roboto', sans-serif !important;\n}\n\n.slide > * {\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n}\n\n.slide :is(h1, h2, h3, h4, h5, h6) {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": `styles_slide__u530D`
};
export default ___CSS_LOADER_EXPORT___;
