import { Chip, Menu, MenuItem } from '@mui/material';
import * as React from 'react';

const parseStatusCellLabel = value => {
  switch (value) {
    case 'notStarted':
      return 'Not started';
    case 'inProgress':
      return 'In progress';
    case 'complete':
      return 'Completed';
    default:
      return value;
  }
};

const parseStatusCellBackgroundColor = value => {
  switch (value) {
    case 'inProgress':
      return '#F5F3FF';
    case 'complete':
      return '#DAF4EB';
    default:
      return '';
  }
};

const parseStatusCellFontColor = value => {
  switch (value) {
    case 'inProgress':
      return '#6315C7';
    case 'complete':
      return '#00744C';
    default:
      return '';
  }
};

type CaseStatusChipProps = {
  value: string;
  isEditable?: boolean;
  handleStatusChange?: (status: string) => void;
};

const CaseStatusChip: React.FC<CaseStatusChipProps> = ({
  value,
  isEditable = false,
  handleStatusChange,
}): JSX.Element => {
  const chipRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <Chip
        ref={chipRef}
        label={parseStatusCellLabel(value)}
        sx={{
          height: '25px',
          backgroundColor: parseStatusCellBackgroundColor(value),
          fontSize: '14px',
          fontWeight: '500',
          color: parseStatusCellFontColor(value),
        }}
        onClick={() => setAnchorEl(chipRef.current)}
      />

      {/* menu */}
      {isEditable && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.32))',
                mt: 0.5,
              },
            },
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          {/* menu items */}
          <MenuItem
            disabled={value === 'inProgress'}
            onClick={() => handleStatusChange?.('inProgress')}
          >
            <span>In progress</span>
          </MenuItem>
          <MenuItem
            disabled={value === 'completed'}
            onClick={() => handleStatusChange?.('complete')}
          >
            <span>Completed</span>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default CaseStatusChip;
