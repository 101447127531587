import React, { useEffect, useState } from 'react';
import SelectComponent from 'components/Select';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Radio,
  IconButton,
  Typography,
  TextField,
  Grid,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
export type ModelRequested = {
  objectives_priority: {
    objective: 'Growth/Total Return' | 'Income' | 'Preservation';
    priority_level: number;
  }[];
  risk_profiles: number[];
  tax_status: 'Qualified' | 'Non-Qualified';
  aum_amount: number;
};

type ModelsRequestedTableProps = {
  modelsRequestedDetails: any[] | undefined;
  handleChange: (modelsRequested: ModelRequested[]) => void;
};

const ModelsRequestedTable = ({
  modelsRequestedDetails = [],
  handleChange,
}: ModelsRequestedTableProps): React.ReactNode => {
  const [modelsRequested, setModelsRequested] = useState<ModelRequested[]>([]);

  useEffect(() => {
    setModelsRequested(modelsRequestedDetails);
  }, [modelsRequestedDetails]);

  // render helpers

  const renderObjectivesPriority = (
    modelIndex: number,
    objectiveIndex: number,
  ) => {
    const value =
      modelsRequested[modelIndex].objectives_priority[
        objectiveIndex
      ].priority_level.toString();

    const options = [
      { key: '0', label: '0' },
      { key: '1', label: '1' },
      { key: '2', label: '2' },
    ];

    const handleItemChange = (key: string, value: string) => {
      const newModelsRequested = [...modelsRequested];
      newModelsRequested[modelIndex].objectives_priority[
        objectiveIndex
      ].priority_level = parseInt(value);
      setModelsRequested(newModelsRequested);
      handleChange(newModelsRequested);
    };

    return (
      <SelectComponent
        id={`objective-priority-${modelIndex}-${objectiveIndex}`}
        name={`objective-priority-${modelIndex}-${objectiveIndex}`}
        value={value}
        options={options}
        handleChange={handleItemChange}
      />
    );
  };

  const renderAumAmount = (index: number) => {
    const handleItemChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newModelsRequested = [...modelsRequested];
      newModelsRequested[index].aum_amount = parseFloat(ev.target.value);
      setModelsRequested(newModelsRequested);
      handleChange(newModelsRequested);
    };

    return (
      <TextField
        type="number"
        inputProps={{ min: 0 }}
        value={modelsRequested[index].aum_amount}
        onChange={handleItemChange}
        size="small"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '125px',
          '& .MuiInputBase-input': {
            padding: '8px 0',
            textAlign: 'right',
          },
        }}
      />
    );
  };

  const renderRiskProfiles = (modelIndex: number) => {
    const profiles = [1, 2, 3, 4, 5, 6];

    const handleItemChange = (
      ev: React.ChangeEvent<HTMLInputElement>,
      profile: number,
    ) => {
      if (ev.target.checked) {
        const newModelsRequested = [...modelsRequested];
        newModelsRequested[modelIndex].risk_profiles.push(profile);
        setModelsRequested(newModelsRequested);
        handleChange(newModelsRequested);
      } else {
        const newModelsRequested = [...modelsRequested];
        newModelsRequested[modelIndex].risk_profiles = newModelsRequested[
          modelIndex
        ].risk_profiles.filter(p => p !== profile);
        setModelsRequested(newModelsRequested);
        handleChange(newModelsRequested);
      }
    };

    return profiles.map((profile, index) => (
      <TableCell key={index}>
        <Checkbox
          checked={modelsRequested[modelIndex].risk_profiles.includes(profile)}
          onChange={ev => handleItemChange(ev, profile)}
        />
      </TableCell>
    ));
  };

  const renderTaxStatus = (modelIndex: number) => {
    const handleItemChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newModelsRequested = [...modelsRequested];
      newModelsRequested[modelIndex].tax_status = ev.target
        .value as ModelRequested['tax_status'];
      setModelsRequested(newModelsRequested);
      handleChange(newModelsRequested);
    };

    return (
      <>
        <TableCell>
          <Radio
            checked={modelsRequested[modelIndex].tax_status === 'Qualified'}
            onChange={handleItemChange}
            value="Qualified"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'Qualified' }}
          />
        </TableCell>
        <TableCell>
          <Radio
            checked={modelsRequested[modelIndex].tax_status === 'Non-Qualified'}
            onChange={handleItemChange}
            value="Non-Qualified"
            name="radio-buttons"
            inputProps={{ 'aria-label': 'Non-Qualified' }}
          />
        </TableCell>
      </>
    );
  };

  // event handlers

  const handleAddNewRow = () => {
    const emptyModelRequested: ModelRequested = {
      objectives_priority: [
        {
          objective: 'Growth/Total Return',
          priority_level: 0,
        },
        {
          objective: 'Income',
          priority_level: 0,
        },
        {
          objective: 'Preservation',
          priority_level: 0,
        },
      ],
      risk_profiles: [],
      tax_status: 'Qualified',
      aum_amount: 0,
    };
    const newModelsRequested = [...modelsRequested];
    newModelsRequested.push(emptyModelRequested);
    setModelsRequested(newModelsRequested);
    handleChange(newModelsRequested);
  };

  const handleRemoveRow = (index: number) => {
    const newModelsRequested = [...modelsRequested];
    newModelsRequested.splice(index, 1);
    setModelsRequested(newModelsRequested);
    handleChange(newModelsRequested);
  };

  return (
    <>
      <Grid container>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,

              '& .MuiTableCell-root': {
                textAlign: 'center',
                border: '1px solid rgba(224, 224, 224, 1)',
                padding: '8px',
              },
              '& .MuiTableCell-root > *': {
                justifyContent: 'center',
              },
              '& .MuiTableHead-root': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              '& .MuiTableRow-root': {
                '&:last-child td, &:last-child th': {
                  border: '1px solid rgba(224, 224, 224, 1)',
                },
              },
            }}
            aria-label="risk assessment table"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Objective - Priority
                </TableCell>
                <TableCell rowSpan={2}>Minimum Asset Size</TableCell>
                <TableCell colSpan={6} align="center">
                  Risk profiles
                </TableCell>
                <TableCell colSpan={2} align="center">
                  Tax Status
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Growth</TableCell>
                <TableCell>Income</TableCell>
                <TableCell>Preserve</TableCell>
                {[1, 2, 3, 4, 5, 6].map(num => (
                  <TableCell key={num} align="center">
                    {num}
                  </TableCell>
                ))}
                <TableCell align="center">Qual</TableCell>
                <TableCell align="center">Non-Qual</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {modelsRequested.map((model, modelIndex) => (
                <TableRow key={modelIndex}>
                  <TableCell>
                    {renderObjectivesPriority(modelIndex, 0)}
                  </TableCell>
                  <TableCell>
                    {renderObjectivesPriority(modelIndex, 1)}
                  </TableCell>
                  <TableCell>
                    {renderObjectivesPriority(modelIndex, 2)}
                  </TableCell>
                  <TableCell>{renderAumAmount(modelIndex)}</TableCell>
                  {renderRiskProfiles(modelIndex)}
                  {renderTaxStatus(modelIndex)}
                  <TableCell>
                    <IconButton onClick={() => handleRemoveRow(modelIndex)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <IconButton
        onClick={handleAddNewRow}
        title="Add new row"
        sx={{
          marginTop: '1rem',
          gap: '0.5rem',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'none',
          border: '1px solid rgba(0, 0, 0, 0.6)',
          borderRadius: '0.5rem',
          padding: '0.5rem 1rem',
        }}
      >
        <AddCircleOutlineIcon />
        <Typography>Add new row</Typography>
      </IconButton>
    </>
  );
};

export default ModelsRequestedTable;

export const areValidModelsRequested = (
  modelsRequested: ModelRequested[],
): boolean => {
  const areValid = modelsRequested.every(model => {
    if (model.aum_amount < 0) {
      // Negative values are not allowed.
      return false;
    } else if (model.risk_profiles.length === 0) {
      // At least one risk_profile must be selected
      return false;
    } else if (
      model.objectives_priority.filter(
        objective => objective.priority_level === 1,
      ).length !== 1
    ) {
      // There must be one and only one priority_level equal to 1
      return false;
    }
    return true;
  });
  return areValid && modelsRequested.length > 0;
};
