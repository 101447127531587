// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__Vljnb {
  padding: 32px !important;
}

.styles_title__-rOop {
  font-family: Roboto !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 100% !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
  margin-bottom: 16px !important;
  color: #004f7e !important;
}

.styles_paragraph__MNsni {
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 17px !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/premium-report/components/table-of-contents/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;EACjC,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;AACnC","sourcesContent":[".container {\n  padding: 32px !important;\n}\n\n.title {\n  font-family: Roboto !important;\n  font-weight: 600 !important;\n  font-size: 24px !important;\n  line-height: 100% !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n  margin-bottom: 16px !important;\n  color: #004f7e !important;\n}\n\n.paragraph {\n  font-family: Roboto !important;\n  font-weight: 400 !important;\n  font-size: 11px !important;\n  line-height: 17px !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__Vljnb`,
	"title": `styles_title__-rOop`,
	"paragraph": `styles_paragraph__MNsni`
};
export default ___CSS_LOADER_EXPORT___;
