import React, { useState } from 'react';
import { ITEM_TYPES } from 'utils/constant/itemTypes';
import { IEnhancementFeaturesProps } from '@components/ChatContent/types';
import { useChatEnhancementFeatures } from '@components/ChatContent/hooks/useChatEnhancementFeatures';
import AssessmentRiskResult from '@pages/ClientsV2/AssessmentRiskResult';
import { RiskAssessmentContainer } from '@components/ChatContent/styles';
import PrompQuestions from '@components/PrompQuestions';
import { Box } from '@mui/material';
import ProposalCheckboxes from './ProposalCheckboxes';
import ProposalView from 'components/ProposalView';

const ChatEnhancementFeatures: React.FC<IEnhancementFeaturesProps> = ({
  type,
  content,
  isLastItem,
  createdAt,
}) => {
  const [contentList, setContentList] = useState([]);
  const [isOpenProposalView, setIsOpenProposalView] = useState(false);
  const [proposalURL, setProposalURL] = useState('');

  const {
    handleProposal,
    isProposalLoading,
    portfolioRisk,
    riskCapacity,
    riskPreference,
  } = useChatEnhancementFeatures({
    content,
    type,
    createdAt,
    isLastItem,
    setProposalURL,
  });

  const disabled = !isLastItem || !!isProposalLoading;

  if (type === ITEM_TYPES.generate_proposal) {
    return (
      <Box>
        <ProposalCheckboxes
          disabled={disabled}
          handleCheckboxChange={setContentList}
        />

        <ProposalView
          url={!isOpenProposalView ? '' : proposalURL}
          isOpenProposalView={isOpenProposalView}
          setIsOpenProposalView={setIsOpenProposalView}
        />

        <PrompQuestions
          showDividers
          itemPy="10px"
          questions={['Preview Proposal']}
          disabled={disabled}
          setIsOpenProposalView={setIsOpenProposalView}
          setProposalURL={setProposalURL}
          handleQuestionClick={() =>
            isLastItem && handleProposal?.(contentList)
          }
        />
      </Box>
    );
  }

  if (type === ITEM_TYPES.risk_details) {
    return (
      <RiskAssessmentContainer>
        <AssessmentRiskResult
          riskCapacity={riskCapacity}
          riskPreference={riskPreference}
          portfolioRisk={portfolioRisk}
        />
      </RiskAssessmentContainer>
    );
  }

  return <></>;
};

export default ChatEnhancementFeatures;
