import React, { lazy, Suspense } from 'react';

const PdfModule = lazy(() => import('remoteApp/Proposals'));

const pageList = [
  {
    title: 'General',
    elements: [
      {
        title: 'Cover',
        type: 'COVER',
      },
      {
        title: 'Table of Contents',
        type: 'TABLE_OF_CONTENTS',
      },
      {
        title: 'IPS Introduction',
        type: 'IPS',
      },
    ],
  },
  {
    title: 'Driver Results',
    elements: [
      {
        title: 'Risk Band',
        type: 'RISK_BAND',
      },
      {
        title: 'Assessment Inputs',
        type: 'ASSESSMENT_INPUTS',
      },
    ],
  },
  {
    title: 'Recommendation',
    elements: [
      {
        title: 'Recommendation - Holdings',
        type: 'RECOMMENDED_PORTFOLIO_HOLDINGS',
      },
      {
        title: 'Recommendation - Allocations',
        type: 'RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS',
      },
    ],
  },
  {
    title: 'Current Portfolio',
    elements: [],
  },
  {
    title: 'Compare',
    hide: false,
    elements: [
      {
        title: 'Compare - Risk Scores',
        type: 'COMPARE_RISK',
      },
      {
        title: 'Compare - Expense Ratio',
        type: 'COMPARE_EXPENSE_RATIO',
      },
      {
        title: 'Compare - Holdings',
        type: 'COMPARE_HOLDINGS',
      },
      {
        title: 'Compare - Asset Allocation',
        type: 'ASSET_ALLOCATION',
      },
      {
        title: 'Compare - Region Allocation',
        type: 'REGION_ALLOCATION',
      },
      {
        title: 'Compare - Return Simulation',
        type: 'RETURN_SIMULATION',
      },
    ],
  },
  {
    title: 'Analytics',
    elements: [
      {
        title: 'Potential Return',
        type: 'POTENTIAL_RETURN',
      },
      {
        title: 'Risk Statistics',
        type: 'POTENTIAL_RISK',
      },
    ],
  },
  {
    title: 'Disclosures',
    elements: [
      {
        title: 'Duties and Responsibilities',
        type: 'DUTIES_AND_RESPONSIBILITIES',
      },
      {
        title: 'Disclosures',
        type: 'DISCLOSURES',
      },
      {
        title: 'TIFIN Wealth Disclosure',
        type: 'WEALTH_DISCLOSURES',
      },
      {
        title: 'Risks and Disclosures',
        type: 'RISK_DISCLOSURES',
      },
      {
        title: 'Appendix',
        type: 'APPENDIX',
      },
      {
        title: 'Appendix CMA',
        type: 'APPENDIX_CMA',
      },
    ],
  },
];

function Proposals(): React.JSX.Element {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <PdfModule
        showDownloadButton={true}
        wealthProposalId={'m8xnw92aJXb4xK5lFLsF'}
        pageList={pageList}
      />
    </Suspense>
  );
}

export default Proposals;
