import { Grid, Typography } from '@mui/material';
import { SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  ModelSetHolding,
  ModelSetPerformance,
} from 'features/premium-report/api/types';

export function PerformanceDisclosures({
  rows,
}: {
  rows: {
    id: string;
    full_name: string;
    index_proxy: string;
  }[];
}): React.ReactElement {
  return (
    <Grid container direction="column" spacing={2} className={styles.container}>
      <Grid item>
        <SlideTitle>Back-tested Performance Results:</SlideTitle>
        <Typography component="p" className={styles.paragraph}>
          The following pages provide charts illustrating rolling period excess
          returns and risk for the Growth Low Minimum
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          Qualified and Non-Qualified Profile 3 models and analysis of the
          results. Performance for the other model risk
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          profiles is included in the supplemental Zephyr performance reports.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          All returns are gross of fees and as of 8/31/2024.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          All portfolios use annual rebalancing, while the Global Index blends
          use monthly rebalancing. Global Blend is a mix
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          of MSCI ACWI and Bloomberg Global Aggregate indexes.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          Live strategist results have been used since inception of the
          strategy, which may include model performance returns
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          along with composites.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          Index/blend of indices is used prior to the inception of the strategy.
        </Typography>
        <Grid item xs={12} margin="10px 0">
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnMenu
            disableColumnFilter
            hideFooter
            disableColumnSorting
            disableColumnSelector
            disableMultipleRowSelection
            disableRowSelectionOnClick
            rowHeight={40}
            sx={{
              height: 'fit-content',
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#f0f0f0',
                whiteSpace: 'normal',
                height: 'auto',
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                  height: 'auto',
                },
              },
              '& .MuiDataGrid-cell': {
                fontSize: '11px',
                whiteSpace: 'normal !important',
                lineHeight: 'normal',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          />
        </Grid>
        <Typography component="p" className={styles.paragraph}>
          Performance shown generally includes &apos;Index,&apos;
          &apos;Model,&apos; and &apos;Composite&apos; return calculations.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          Index and Model returns use software programs to track investment
          portfolios, which are considered hypothetical
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          performance and do not reflect actual performance.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          Composite returns are based on actual investor portfolios.
        </Typography>
      </Grid>
    </Grid>
  );
}

const getUniqueHoldings = (modelSetHoldings: ModelSetHolding) => {
  let rowId = 0;
  return modelSetHoldings.portfolio_options.flatMap(option => {
    const allHoldings = option.portfolios.flatMap(
      (portfolio: any) => portfolio.holdings,
    );

    const uniqueHoldings = allHoldings.reduce((acc: any[], current: any) => {
      const exists = acc.find(
        newHolding =>
          newHolding.model_id === current.model_id ||
          newHolding.strategy === current.strategy,
      );
      if (!exists) {
        acc.push({ ...current, id: rowId++ });
      }
      return acc;
    }, []);

    return uniqueHoldings;
  });
};

export const getRowsDisclosures = (
  modelSetPerformance: ModelSetPerformance[],
  modelSetHoldings: ModelSetHolding,
): {
  id: string;
  full_name: string;
  index_proxy: string;
}[] => {
  const uniqueHoldings = getUniqueHoldings(modelSetHoldings);
  const uniqueEntries = new Map();

  uniqueHoldings.forEach(holding => {
    // Aplanar el array de proxy_metadata de todos los modelos
    const allProxyMetadata = modelSetPerformance.flatMap(
      model => model.proxy_metadata,
    );

    // Filtrar los metadata que coinciden con el holding actual
    const matchingPerformance = allProxyMetadata.filter(
      proxy => proxy.model_id === holding.model_id,
    );

    matchingPerformance.forEach(performance => {
      const key = `${performance.model_id}-${performance.strategy_start_date}`;

      // Solo agregar si no existe esta combinación
      if (!uniqueEntries.has(key)) {
        uniqueEntries.set(key, {
          id: key,
          full_name: `${holding.full_model_name} (${performance.strategy_start_date})`,
          index_proxy: performance.proxy_name || performance.model_id,
        });
      }
    });
  });

  return Array.from(uniqueEntries.values());
};

const columns: GridColDef[] = [
  {
    field: 'full_name',
    headerName: 'STRATEGY(INCEPTION)',
    flex: 1,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
  {
    field: 'index_proxy',
    headerName: 'INDEX PROXY USED PRIOR TO INCEPTION',
    flex: 1,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
];
