import {
  columnsCalendarYearAsOf,
  getPortfolioStatisticsRows,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';

import { Grid } from '@mui/material';
import { ChartBox, SlideTitle } from 'features/slides';
import { PortfolioStatisticsBarChart } from 'pages/Cases/components/PortfolioStatisticsBarChart';
import SummaryPerformanceTable from 'pages/Cases/components/SummaryPerformanceTable';
import { portfolioStatisticsChartFilter } from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';

export type CalendarYearReturnsProps = {
  readonly filteredPerformance: any;
  readonly rowsCalendarYearReturnsTable?: boolean;
};

export function CalendarYearReturns({
  filteredPerformance,
  rowsCalendarYearReturnsTable,
}: CalendarYearReturnsProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>Calendar Year Returns</SlideTitle>
      </Grid>

      <ChartBox>
        <PortfolioStatisticsBarChart
          data={portfolioStatisticsChartFilter({
            filteredPerformance,
            name: 'calendar_yr_returns',
          })}
          ytdFinal={true}
          height={rowsCalendarYearReturnsTable ? 620 : 800}
        />
      </ChartBox>
      {rowsCalendarYearReturnsTable && (
        <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
          <SummaryPerformanceTable
            rows={getPortfolioStatisticsRows(
              portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'calendar_yr_returns',
              }),
            )}
            columns={columnsCalendarYearAsOf}
          />
        </Grid>
      )}
    </Grid>
  );
}
