import React, { useEffect, useRef } from 'react';
import { useSlidesContent, useSlidesUI } from '../../context';
import SlideRenderer from '../SlideRenderer';
import styles from './styles.module.css';

function handleZoom(
  zoom: number,
  elementRef: React.RefObject<HTMLDivElement>,
): React.EffectCallback {
  return () => {
    const scale = zoom / 100;

    if (elementRef.current) {
      elementRef.current.style.transform = `scale(${scale})`;
    }
  };
}

const Viewport = React.memo((): React.ReactElement => {
  const { currentSlide } = useSlidesContent();
  const { zoom } = useSlidesUI();
  const viewportInnerRef = useRef<HTMLDivElement>(null);

  useEffect(handleZoom(zoom, viewportInnerRef), [zoom]);

  return (
    <div className={styles.viewport}>
      <div ref={viewportInnerRef} className={styles.viewportInner}>
        <SlideRenderer slide={currentSlide} />
      </div>
    </div>
  );
});

export default Viewport;
