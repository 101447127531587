import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';
import { Model } from '../types';

type UseGetModelByIdArguments = {
  modelId: string;
};

type UseGetModelByIdResult = {
  model: Model | undefined;
  isErrorModel: boolean;
  isLoadingModel: boolean;
};

type UseGetModelByIdResponse = {
  data: Model;
};

export const useGetModelById = ({
  modelId,
}: UseGetModelByIdArguments): UseGetModelByIdResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isError, isLoading } = useQuery<UseGetModelByIdResponse>(
    ['model', modelId],
    fetchQuery({ path: `/v2/models/${modelId}` }),
  );

  return {
    model: data?.data,
    isErrorModel: isError,
    isLoadingModel: isLoading,
  };
};
