import Box from '@mui/material/Box';
import React from 'react';

export function IconSidebar(): React.ReactElement {
  return (
    <Box>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 2.5H13.5C13.7652 2.5 14.0196 2.60536 14.2071 2.79289C14.3946 2.98043 14.5 3.23478 14.5 3.5V12.5C14.5 12.7652 14.3946 13.0196 14.2071 13.2071C14.0196 13.3946 13.7652 13.5 13.5 13.5H2.5C2.23478 13.5 1.98043 13.3946 1.79289 13.2071C1.60536 13.0196 1.5 12.7652 1.5 12.5V3.5C1.5 3.23478 1.60536 2.98043 1.79289 2.79289C1.98043 2.60536 2.23478 2.5 2.5 2.5ZM13.5 3.5H11V12.5H13.5V3.5ZM2.5 12.5H10V3.5H2.5V12.5Z"
          fill="#535256"
        />
      </svg>
    </Box>
  );
}
