import React, { useEffect, useState } from 'react';
import { useSlidesContent, useSlidesUI } from '../../context';
import { default as MuiButton } from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import styles from './styles.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';

interface FooterButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  disabled: boolean;
  loading?: boolean;
}

const Button = ({
  children,
  onClick,
  disabled,
  loading = false,
}: FooterButtonProps): React.ReactElement => {
  return (
    <MuiButton
      onClick={onClick}
      disabled={disabled || loading}
      disableRipple
      aria-busy={loading}
      sx={{
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#e5e5e5',
        },
        '&:disabled': {
          backgroundColor: 'hsl(0, 0.00%, 85%)',
          color: '#535256',
        },
        color: '#535256',
        py: 1,
        px: 2,
        borderRadius: 2,
        fontSize: '12px',
        lineHeight: '16px',
        border: '1px solid hsl(0, 0.00%, 89.80%)',
        textTransform: 'none',
        fontWeight: 500,
      }}
    >
      {loading && <CircularProgress size={12} sx={{ mr: 1 }} />}
      {children}
    </MuiButton>
  );
};

const IconSidebar = (): React.ReactElement => {
  return (
    <Box>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 2.5H13.5C13.7652 2.5 14.0196 2.60536 14.2071 2.79289C14.3946 2.98043 14.5 3.23478 14.5 3.5V12.5C14.5 12.7652 14.3946 13.0196 14.2071 13.2071C14.0196 13.3946 13.7652 13.5 13.5 13.5H2.5C2.23478 13.5 1.98043 13.3946 1.79289 13.2071C1.60536 13.0196 1.5 12.7652 1.5 12.5V3.5C1.5 3.23478 1.60536 2.98043 1.79289 2.79289C1.98043 2.60536 2.23478 2.5 2.5 2.5ZM13.5 3.5H11V12.5H13.5V3.5ZM2.5 12.5H10V3.5H2.5V12.5Z"
          fill="#535256"
        />
      </svg>
    </Box>
  );
};

const IconNoSidebar = (): React.ReactElement => {
  return (
    <Box>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4167 2.5H2.58333C2.29602 2.5 2.02047 2.59658 1.8173 2.76849C1.61414 2.94039 1.5 3.17355 1.5 3.41667V12.5833C1.5 12.8264 1.61414 13.0596 1.8173 13.2315C2.02047 13.4034 2.29602 13.5 2.58333 13.5H13.4167C13.704 13.5 13.9795 13.4034 14.1827 13.2315C14.3859 13.0596 14.5 12.8264 14.5 12.5833V3.41667C14.5 3.17355 14.3859 2.94039 14.1827 2.76849C13.9795 2.59658 13.704 2.5 13.4167 2.5ZM13.4167 12.5833H2.58333V3.41667H13.4167V12.5833Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
};

const ChevronDownIcon = (): React.ReactElement => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6848 5.55977L7.30977 9.93477C7.26914 9.97545 7.22089 10.0077 7.16778 10.0297C7.11467 10.0518 7.05774 10.0631 7.00024 10.0631C6.94275 10.0631 6.88582 10.0518 6.83271 10.0297C6.7796 10.0077 6.73134 9.97545 6.69071 9.93477L2.31571 5.55977C2.23362 5.47768 2.1875 5.36634 2.1875 5.25024C2.1875 5.13415 2.23362 5.0228 2.31571 4.94071C2.3978 4.85862 2.50915 4.8125 2.62524 4.8125C2.74134 4.8125 2.85268 4.85862 2.93477 4.94071L7.00024 9.00673L11.0657 4.94071C11.1064 4.90006 11.1546 4.86782 11.2077 4.84582C11.2608 4.82382 11.3178 4.8125 11.3752 4.8125C11.4327 4.8125 11.4897 4.82382 11.5428 4.84582C11.5959 4.86782 11.6441 4.90006 11.6848 4.94071C11.7254 4.98136 11.7577 5.02962 11.7797 5.08273C11.8017 5.13584 11.813 5.19276 11.813 5.25024C11.813 5.30773 11.8017 5.36465 11.7797 5.41776C11.7577 5.47087 11.7254 5.51913 11.6848 5.55977Z"
        fill="#343330"
      />
    </svg>
  );
};

const ChevronUpIcon = (): React.ReactElement => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6848 9.05988C11.6441 9.10055 11.5959 9.13282 11.5428 9.15484C11.4897 9.17686 11.4327 9.18819 11.3752 9.18819C11.3177 9.18819 11.2608 9.17686 11.2077 9.15484C11.1546 9.13282 11.1063 9.10055 11.0657 9.05988L7.00024 4.99386L2.93477 9.05988C2.85268 9.14197 2.74134 9.18809 2.62524 9.18809C2.50915 9.18809 2.3978 9.14197 2.31571 9.05988C2.23362 8.97778 2.1875 8.86644 2.1875 8.75034C2.1875 8.63425 2.23362 8.52291 2.31571 8.44081L6.69071 4.06581C6.73134 4.02514 6.7796 3.99287 6.83271 3.97085C6.88582 3.94883 6.94275 3.9375 7.00024 3.9375C7.05774 3.9375 7.11467 3.94883 7.16778 3.97085C7.22089 3.99287 7.26914 4.02514 7.30977 4.06581L11.6848 8.44081C11.7255 8.48144 11.7577 8.5297 11.7797 8.58281C11.8018 8.63592 11.8131 8.69285 11.8131 8.75034C11.8131 8.80784 11.8018 8.86477 11.7797 8.91788C11.7577 8.97099 11.7255 9.01924 11.6848 9.05988Z"
        fill="#343330"
      />
    </svg>
  );
};

const Footer = React.memo(
  ({
    title,
    panelOpen,
    setPanelOpen,
  }: {
    panelOpen: boolean;
    setPanelOpen: (open: boolean) => void;
    title: string;
  }): React.ReactElement => {
    const { slides, currentSlide, setCurrentSlideByIndex, handleSave } =
      useSlidesContent();

    const { dirty, setZoom, handleDownload, isSaving, isDownloading, zoom } =
      useSlidesUI();

    const slidesLength = slides.length;

    const [currentSlideIndex, setCurrentSlideIndex] = useState(
      slides.findIndex(s => s.slideTemplateId === currentSlide.slideTemplateId),
    );

    useEffect(() => {
      setCurrentSlideIndex(
        slides.findIndex(
          s => s.slideTemplateId === currentSlide.slideTemplateId,
        ),
      );
    }, [currentSlide, slides]);

    const handleNavigate = (direction: number) => {
      if (
        currentSlideIndex + direction < 0 ||
        currentSlideIndex + direction >= slidesLength
      )
        return;
      setCurrentSlideByIndex(currentSlideIndex + direction);
    };

    return (
      <Box
        className={styles.footer}
        sx={{
          display: 'flex',
          backgroundColor: '#EFEFEF',
          py: 2,
          px: 3,
        }}
      >
        <div className={styles.footerLeft}>
          <div>{title}</div>
          <span>
            Page: {currentSlideIndex + 1} / {slidesLength}
          </span>
          <IconButton
            aria-label="Previous"
            disableRipple
            onClick={() => handleNavigate(-1)}
            sx={{
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              borderRadius: '7px',
              height: '32px',
              border: '1px solid #e5e5e5',
            }}
          >
            <ChevronUpIcon />
          </IconButton>
          <IconButton
            aria-label="Next"
            disableRipple
            onClick={() => handleNavigate(1)}
            sx={{
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              borderRadius: '7px',
              height: '32px',
              border: '1px solid #e5e5e5',
            }}
          >
            <ChevronDownIcon />
          </IconButton>
        </div>

        <div className={styles.footerRight}>
          <Select
            value={zoom}
            onChange={e => setZoom(Number(e.target.value))}
            displayEmpty
            inputProps={{ 'aria-label': 'Zoom' }}
            sx={{
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#e5e5e5',
              },
              color: '#535256',
              height: '34px',
              borderRadius: 2,
              fontSize: '12px',
              lineHeight: '20px',
              border: '1px solid hsl(0, 0.00%, 89.80%)',
              fontWeight: 500,
            }}
          >
            <MenuItem value={100}>100%</MenuItem>
            <MenuItem value={75}>75%</MenuItem>
            <MenuItem value={50}>50%</MenuItem>
          </Select>
          <Button
            loading={isDownloading}
            onClick={() => handleDownload(title)}
            disabled={false}
          >
            Download
          </Button>
          <Button loading={isSaving} onClick={handleSave} disabled={!dirty}>
            Save
          </Button>
          <ButtonGroup
            disableElevation
            disableRipple
            aria-label="Basic button group"
            sx={{
              borderRadius: '8px',
              p: '1px',
              backgroundColor: '#E5E5E5',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              gap: '1px',
            }}
          >
            <IconButton
              aria-label="Hide sidebar"
              disableRipple
              onClick={() => setPanelOpen(false)}
              sx={{
                backgroundColor: panelOpen ? 'transparent' : '#fff',
                '&:hover': {
                  backgroundColor: '#f2f2f2',
                },
                borderRadius: '7px',
                height: '32px',
              }}
            >
              <IconNoSidebar />
            </IconButton>
            <IconButton
              aria-label="Show sidebar"
              disableRipple
              onClick={() => setPanelOpen(true)}
              sx={{
                backgroundColor: panelOpen ? '#fff' : 'transparent',
                '&:hover': {
                  backgroundColor: '#f2f2f2',
                },
                borderRadius: '7px',
                height: '32px',
              }}
            >
              <IconSidebar />
            </IconButton>
          </ButtonGroup>
        </div>
      </Box>
    );
  },
);

export default Footer;
