import { ModelSet } from 'features/performance-report/model-set';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';
import { useGetDisclosures } from 'features/performance-report/api';
import {
  filterPerformance,
  getPortfolioStatisticsRows,
  ModelSetsFilter,
  OptionFilter,
  portfolioStatisticsChartFilter,
  RiskProfileFilter,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  CoverSlideTemplate,
  Slides,
  TitleSlideTemplate,
} from '../../../slides';
import { usePostCalculatePerformance } from '../../api';
import { AnnualizedReturns } from '../annualized-returns';
import { AnnualizedReturnsTable } from '../annualized-returns-table';
import { CalendarYearReturns } from '../calendar-year-returns';
import { CalendarYearReturnsTable } from '../calendar-year-returns-table';
import { Disclosures } from '../disclosures';
import { GrowthSlide } from '../growth-slide';
import { GrowthSlideTable } from '../grow-slide-table';
import { ImportantInformation } from '../important-information';
import { RollingOneYearReturnAndExcessReturn } from '../rolling-one-year-return-and-excess-return';
import { RollingThreeYearReturnsAndExcessReturns } from '../rolling-three-year-returns-and-excess-returns/rolling-three-year-returns-and-excess-returns';
import { RollingThreeYearRiskAndSharpeRatio } from '../rolling-three-year-risk-and-sharpe-ratio';
import { ScatterPlots } from '../scatter-plots';
import { CircularProgress } from '@mui/material';

export function PerformanceReportSlides(): React.ReactElement {
  const { actionId = '', caseId = '' } = useParams();

  const [filter, setFilter] = React.useState({
    activeNames: [] as string[],
    activeOptions: [1] as number[],
    activeRiskProfile: 3,
  });

  const { action, isActionLoading, isActionFetching, getModelSetsBasicData } =
    useGetCaseActionById({ actionId, caseId });

  const { data: disclosures, isLoading: isDisclosuresLoading } =
    useGetDisclosures({
      enabled: !!action && !isActionLoading && !isActionFetching,
      caseId,
      actions: action?.id,
      modelSetsNames: getModelSetsBasicData().map(
        ({ model_set_name }) => model_set_name,
      ),
      options: filter.activeOptions,
      riskProfile: filter.activeRiskProfile,
    });

  const [coverState, setCoverState] = React.useState({
    title: 'Risk and Return Performance Report',
    subtitle1: 'William K Hall, Inc',
    subtitle2: 'Investment Portfolio Ideas',
    date: new Date(),
  });

  const [performanceChartsState, setPerformanceChartsState] = React.useState({
    title: 'Performance Charts',
  });

  const [modelSetTitleState, setModelSetTitleState] = React.useState({
    title: 'Model Sets in Scope',
  });

  const { data: performance, isLoading: isPerformanceLoading } =
    usePostCalculatePerformance({
      enabled: !!action && !isActionLoading && !isActionFetching,
      performanceData: getModelSetsBasicData(),
    });

  const filteredPerformance = React.useMemo(
    () =>
      performance && action
        ? filterPerformance(
            performance,
            filter.activeNames,
            filter.activeOptions,
            filter.activeRiskProfile,
          )
        : [],
    [
      performance,
      filter.activeNames,
      filter.activeOptions,
      filter.activeRiskProfile,
    ],
  );

  const modelSets = React.useMemo(() => {
    const performanceData = performance ?? [];
    const activeNames = filter.activeNames.length
      ? filter.activeNames
      : performanceData.map(({ model_set_name }) => model_set_name);
    return activeNames;
  }, [filter.activeNames, performance]);

  const showRowsTable = (name: string) =>
    getPortfolioStatisticsRows(
      portfolioStatisticsChartFilter({
        filteredPerformance,
        name: name,
      }),
    ).length > 3;

  const slides = [
    {
      id: 'cover',
      state: coverState,
      setState: state => setCoverState(state),
      render: ({ state, setState, editable }) => (
        <CoverSlideTemplate
          state={state}
          setState={setState}
          editable={editable}
        />
      ),
      footer: false,
    },
    {
      id: 'important-information',
      state: {},
      setState: () => {},
      render: () => (
        <ImportantInformation
          porfoliosReviewed={disclosures?.data?.portfoliosReviewed}
          strategistPerformance={disclosures?.data?.strategistPerformance}
        />
      ),
    },
    {
      id: 'disclosures',
      state: {},
      setState: () => {},
      render: () => <Disclosures />,
    },
    {
      id: 'model-set-title',
      state: modelSetTitleState,
      setState: state => setModelSetTitleState(state),
      render: ({ state, setState, editable }) => (
        <TitleSlideTemplate
          state={state}
          setState={setState}
          editable={editable}
        />
      ),
    },
    ...modelSets.map(name => ({
      id: `model-set-holdings-${name}`,
      state: {},
      setState: () => {},
      render: () => (
        <ModelSet name={name} action={action} options={filter.activeOptions} />
      ),
    })),
    {
      id: 'performance-charts',
      state: performanceChartsState,
      setState: state => setPerformanceChartsState(state),
      render: ({ state, setState, editable }) => (
        <TitleSlideTemplate
          state={state}
          setState={setState}
          editable={editable}
        />
      ),
    },
    {
      id: 'growth',
      state: {},
      setState: () => {},
      render: () => (
        <GrowthSlide
          filteredPerformance={filteredPerformance}
          startDate={performance?.[0].performance_metadata.start_date}
          endDate={performance?.[0].performance_metadata.end_date}
          rowsGrowthTable={!showRowsTable('performance_stats')}
        />
      ),
    },
    ...(showRowsTable('performance_stats')
      ? [
          {
            id: 'growth-table',
            state: {},
            setState: () => {},
            render: () => (
              <GrowthSlideTable
                filteredPerformance={filteredPerformance}
                startDate={performance?.[0].performance_metadata.start_date}
                endDate={performance?.[0].performance_metadata.end_date}
              />
            ),
          },
        ]
      : []),
    {
      id: 'annualized-returns',
      state: {},
      setState: () => {},
      render: () => (
        <AnnualizedReturns
          filteredPerformance={filteredPerformance}
          rowsAnnualizedReturnsTable={!showRowsTable('trailing_year_returns')}
        />
      ),
    },
    ...(showRowsTable('trailing_year_returns')
      ? [
          {
            id: 'annualized-returns-table',
            state: {},
            setState: () => {},
            render: () => (
              <AnnualizedReturnsTable
                filteredPerformance={filteredPerformance}
              />
            ),
          },
        ]
      : []),
    {
      id: 'calendar-year-returns',
      state: {},
      setState: () => {},
      render: () => (
        <CalendarYearReturns
          filteredPerformance={filteredPerformance}
          rowsCalendarYearReturnsTable={!showRowsTable('calendar_yr_returns')}
        />
      ),
    },
    ...(showRowsTable('calendar_yr_returns')
      ? [
          {
            id: 'calendar-year-returns-table',
            state: {},
            setState: () => {},
            render: () => (
              <CalendarYearReturnsTable
                filteredPerformance={filteredPerformance}
              />
            ),
          },
        ]
      : []),
    {
      id: 'scatter-plots',
      state: {},
      setState: () => {},
      render: () => <ScatterPlots filteredPerformance={filteredPerformance} />,
    },
    {
      id: 'rolling-one-year-return-and-excess-return',
      state: {},
      setState: () => {},
      render: () => (
        <RollingOneYearReturnAndExcessReturn
          filteredPerformance={filteredPerformance}
        />
      ),
    },
    {
      id: 'rolling-three-year-returns-and-excess-returns',
      state: {},
      setState: () => {},
      render: () => (
        <RollingThreeYearReturnsAndExcessReturns
          filteredPerformance={filteredPerformance}
        />
      ),
    },
    {
      id: 'rolling-three-year-risk-and-sharpe-ratio',
      state: {},
      setState: () => {},
      render: () => (
        <RollingThreeYearRiskAndSharpeRatio
          filteredPerformance={filteredPerformance}
        />
      ),
    },
  ];
  return isPerformanceLoading || isDisclosuresLoading ? (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '48px',
          display: 'flex',
          padding: '0 12px',
          gap: 10,
        }}
      >
        <ModelSetsFilter
          performance={performance || []}
          activeNames={filter.activeNames}
          setFilter={setFilter}
        />
        <OptionFilter
          activeOptions={filter.activeOptions}
          setFilter={setFilter}
        />
        <RiskProfileFilter
          activeRiskProfile={filter.activeRiskProfile}
          setFilter={setFilter}
        />
      </div>
      <div style={{ width: '100%', height: 'calc(100% - 48px)' }}>
        <Slides slides={slides} title="Performance Report" />
      </div>
    </div>
  );
}
