import React from 'react';

import BgImage from '../../../assets/assetmark/bg-image.png';
import Logo from '../../../assets/assetmark/logo-white.png';

import { EditableField } from '../../../slides';

import { Typography } from '@mui/material';
import styles from './styles.module.css';

export type CoverState = {
  preparedFor: string;
  preparedBy: string;
};

export type CoverProps = {
  readonly state: CoverState;
  readonly setState: (state: CoverState) => void;
  readonly editable?: boolean;
};

export function Cover({
  state,
  setState,
  editable = true,
}: CoverProps): React.ReactElement {
  return (
    <div
      className={styles.cover}
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <img className={styles.logo} src={Logo} alt="AMK Logo" />

      <div className={styles.title}>
        <Typography component="h1" className={styles.titleText}>
          Investment Consulting
        </Typography>
      </div>

      <div className={styles.preparedFor}>
        <Typography component="p" className={styles.preparedForTitle}>
          Prepared For
        </Typography>
        <EditableField
          field="preparedFor"
          type="block"
          state={state}
          setState={setState}
          editable={editable}
          editorClassName="coverFor"
        />
      </div>

      <div className={styles.preparedBy}>
        <Typography component="p" className={styles.preparedForTitle}>
          Prepared By
        </Typography>
        <EditableField
          field="preparedBy"
          type="block"
          state={state}
          setState={setState}
          editable={editable}
          editorClassName="coverBy"
        />
      </div>
    </div>
  );
}
