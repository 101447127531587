import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from 'styled-components';

const ModelName: React.FC<{
  name: string;
  disableSaveButton: boolean;
  handleChange: (name: string) => void;
  handleSave: () => void;
}> = ({ name, disableSaveButton, handleChange, handleSave }) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <TextField
        placeholder="Model Name"
        value={name}
        fullWidth
        size="small"
        onChange={ev => handleChange(ev.target.value)}
      />
      <Button
        sx={{
          textTransform: 'none',
          background: '#000000',
          width: '160px',
          borderRadius: '20px',
          color: '#ffffff',
          '&:hover': {
            background: '#00C3DE',
            color: '#000000',
            boxShadow: 'none',
          },
        }}
        size="medium"
        variant="contained"
        disabled={disableSaveButton}
        onClick={handleSave}
      >
        <SaveModelBtnText>Save Model</SaveModelBtnText>
      </Button>
    </Stack>
  );
};

export default ModelName;

export const SaveModelBtnText = styled.span`
  font-size: 14px;
`;
