import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePutCaseActionByIdArguments = {
  caseId: string;
  actionId: string;
  versionName: string;
  value: any;
  isDraft?: boolean;
  successToastMessage: string;
};

type UsePutCaseActionByIdResult = {
  putCaseActionByIdMutate: (data: UsePutCaseActionByIdArguments) => void;
  isSuccess: boolean;
  data: any;
  putCaseActionByIdIsLoading: boolean;
};

export const usePutCaseActionById = (): UsePutCaseActionByIdResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate, isSuccess, data, isLoading } = useMutation(
    (data: UsePutCaseActionByIdArguments) =>
      fetchMutation({
        path: `/v2/cases/${data.caseId}/milestones/actions/${data.actionId}`,
        method: 'PUT',
        body: {
          versionName: data.versionName,
          value: data.value,
          isDraft: data.isDraft === false ? false : true,
        },
        invalidateQueries: [
          ['cases', data.caseId],
          ['action', data.actionId],
          ['recentPortfolioConstructions'],
          ['fees'],
        ],
        successToastMessage: data.successToastMessage,
      }),
  );

  return {
    putCaseActionByIdMutate: mutate,
    isSuccess,
    data,
    putCaseActionByIdIsLoading: isLoading,
  };
};
