import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as MuiStylesThemeProvider } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { configureAppStore } from 'store/configureStore';
import { settings } from 'config';
import { mockServer } from 'mockServer';
import { COLORS } from 'utils/styles/colors';
import theme from 'utils/styles/theme';
import App from 'app';

const initializeApp = async () => {
  // mock server
  if (settings.mockServer.enabled) {
    await mockServer.start({ onUnhandledRequest: 'bypass' });
  }

  // redux
  const store = configureAppStore();

  // react query
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
        retryDelay: (attemptIndex: number) => 1000 * 2 ** attemptIndex,
        cacheTime: 1000 * 60 * 60, // 60 minutes
        staleTime: 1000 * 30, // 30 seconds
      },
    },
  });

  // sentry
  Sentry.init({
    dsn: settings.sentry.dsn,
    environment: settings.sentry.environment,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });

  // react
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MuiStylesThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={COLORS}>
                <App />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiStylesThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>,
  );
};

initializeApp();
