import { Grid, Typography } from '@mui/material';
import { SlideSubtitle, SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import { PortfolioStatisticsLineChart } from 'pages/Cases/components/PortfolioStatisticsLineChart';
import {
  filterPerformance,
  portfolioStatisticsChartFilter,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import { ModelSetPerformance } from 'features/premium-report/api/types';
import { calculateDateRange } from 'features/premium-report/utils';
export function ExcessReturnPerformance({
  modelSetPerformance,
}: {
  modelSetPerformance: ModelSetPerformance[];
}): React.ReactElement {
  const filteredPerformance = filterPerformance(modelSetPerformance, [], [], 3);

  if (!filteredPerformance || filteredPerformance.length === 0) {
    return (
      <Grid
        container
        direction="column"
        spacing={2}
        className={styles.container}
      >
        <Grid item>
          <SlideTitle>Excess Return Analysis</SlideTitle>
          <Typography component="p" className={styles.paragraph}>
            No data available to display excess return analysis.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const oneYearDateRange =
    calculateDateRange(
      filteredPerformance,
      'rolling_one_year_stats',
      '1_year_excess_returns',
      '12',
    ) || 'Rango de fechas no disponible';

  const threeYearDateRange =
    calculateDateRange(
      filteredPerformance,
      'rolling_three_year_stats',
      '3_year_excess_returns',
      '36',
    ) || 'Rango de fechas no disponible';

  return (
    <Grid container direction="column" spacing={2} className={styles.container}>
      <Grid item>
        <SlideTitle>Excess Return Analysis</SlideTitle>
        <Typography component="p" className={styles.paragraph}>
          <b>Model vs Benchmark: Excess Return</b>
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          {oneYearDateRange}
        </Typography>
        <Grid item>
          <PortfolioStatisticsLineChart
            data={portfolioStatisticsChartFilter({
              filteredPerformance: filteredPerformance,
              name: 'rolling_one_year_stats',
              subName: '1_year_excess_returns',
            })}
            height={218}
            fontSize={12}
            formatChart={{
              unit: '%',
              decimals: 2,
              prefix: '',
              value: 'Excess Return',
              label: 'Excess Return',
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography component="p" className={styles.paragraph}>
          <b>Model vs Benchmark: Excess Return</b>
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          {threeYearDateRange}
        </Typography>
        <Grid item>
          <PortfolioStatisticsLineChart
            data={portfolioStatisticsChartFilter({
              filteredPerformance: filteredPerformance,
              name: 'rolling_three_year_stats',
              subName: '3_year_excess_returns',
            })}
            height={218}
            fontSize={12}
            formatChart={{
              unit: '%',
              decimals: 2,
              prefix: '',
              value: 'Excess Return',
              label: 'Excess Return',
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <SlideSubtitle>Rolling period excess returns</SlideSubtitle>
        <Typography component="p" className={styles.paragraph}>
          Looking at the Profile 3 model relative to the Global 60/40 blended
          index, the model generally provided positive excess returns. For the
          12-month rolling window, the worst negative excess returns were under
          3%. Periods of negative excess returns in 2017 were the result of the
          model&apos;s US bias during a period when international was favored.
          Underperformance in 2023 was generally the result of the model&apos;s
          broad diversification when market returns were driven by more
          concentrated, higher beta growth equities. Over 36-month rolling
          periods however, excess returns were consistently positive with an
          generally above 1.5%.
        </Typography>
      </Grid>
    </Grid>
  );
}
