import React from 'react';
import { EditableField } from '../../components/EditableField';
import { type Slide } from '../../_data';
import { Grid, Typography } from '@mui/material';
import Footer from '../Footer';
export type Slide9ContentType = [
  {
    field: 'title';
    label: string;
    value: string;
    type: 'text';
  },

  {
    field: 'modelSet';
    label: string;
    value: string;
    type: 'modelSet';
  },
];

export const Slide9Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide9ContentType>;
    onChangeSlide: (
      slide: Slide<Slide9ContentType>,
      content: Slide9ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        sx={{
          padding: '12px 12px 0 12px',
          height: '100%',
          width: '100%',
        }}
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          sx={{
            height: 'calc(100% - 48px)',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'left',
              fontFamily: 'roboto',
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#004F7E',
              height: 'fit-content',
            }}
          >
            <EditableField
              field={'title'}
              slide={slide}
              onSlideContentChange={onChangeSlide}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '10.5px', color: '#282829' }}>
              The consideration of AssetMark proprietary strategies, including
              strategies that invest in funds managed by AssetMark, during the
              investment consulting process, creates a conflict of interest for
              AssetMark as AssetMark receives fees for the management of those
              strategies and funds. The recipient of this report, and not
              AssetMark, is responsible for the individualized suitability
              analyses and determinations as well as the investment
              recommendations for his or her clients. Materials provided by
              AssetMark in connection with this engagement, including any sample
              portfolios, are based on general information provided by the
              recipient of this report about his or her practice and are not
              individualized or client-specific investment advice. In providing
              investment consulting services AssetMark is not exercising
              investment discretion over any client account. Past investment
              performance is not a guarantee of future results, and there is no
              guarantee that materials provided by AssetMark hereunder,
              including any sample portfolios, will produce any particular
              investment outcome.
              <br />
              <br />
              This is for informational purposes only, is not a solicitation,
              and should not be considered investment, legal, or tax advice. The
              information has been drawn from sources believed to be reliable,
              but its accuracy is not guaranteed and is subject to change.
              Financial advisors may seek more information by contacting
              AssetMark at 800-664-5345.
              <br />
              <br />
              <b>
                Investing involves risk, including the possible loss of
                principal. Past performance does not guarantee future results.
              </b>{' '}
              Asset allocation cannot eliminate the risk of fluctuating prices
              and uncertain returns. There is no guarantee that a diversified
              portfolio will outperform a non-diversified portfolio. No
              investment strategy, such as asset allocation, can guarantee a
              profit or protect against loss. Actual client results will vary
              based on investment selection, timing, market conditions, and tax
              situation.
              <br />
              <br />
              It is not possible to invest directly in an index. Indexes are
              unmanaged, do not incur management fees, costs, or expenses, and
              cannot be invested in directly. Index performance assumes the
              reinvestment of dividends.
              <br />
              <br />
              Investments in equities, bonds, options, and other securities,
              whether held individually or through mutual funds and
              exchange-traded funds, can decline significantly in response to
              adverse market conditions, company-specific events, changes in
              exchange rates, and domestic, international, economic, and
              political developments. Investments in bonds and fixed income
              related securities involve market and interest rate risk (prices
              can decline, if interest rates increase), and default risk (an
              issuer being unable to repay principal and interest). High-yield
              bonds are generally subject to a greater risk of default than
              investment-grade bonds. Real estate investments are subject to
              credit and market risks, typically based on changes in interest
              rates and varied economic conditions. Investing in alternative
              investments, including managed futures, commodities, and
              currencies, is not appropriate for all persons, as the risk of
              loss is substantial. Investments in futures involve market,
              counterparty, leverage, liquidity, interest rate, foreign
              currency, commodity, volatility, and other risks.
              <br />
              <br />
              The exclusion of investments based on industry or ESG factors
              reduces the number of available investment choices and can impact
              overall investment performance.
              <br />
              <br />
              <b>
                For more complete information about the various investment
                solutions available, including the investment objectives, risks,
                and fees, please refer to the Disclosure Brochure and applicable
                Fund Prospectus. Please read them carefully before investing.
                For a copy, please contact an AssetMark Consultant.
              </b>
              <br />
              <br />
              AssetMark, Inc. is an investment adviser registered with the U.S.
              Securities and Exchange Commission. AssetMark Asset Management, a
              division of AssetMark, Inc., includes the firm’s proprietary
              investment strategies. GuideMark® and GuidePath® Funds are
              distributed by AssetMark Brokerage™, LLC, member FINRA, an
              affiliate of AssetMark, Inc. AssetMark, and third-party
              strategists and service providers are separate and unaffiliated
              companies. Each party is responsible for their own content and
              services.
              <br />
              <br />
              ©2025 AssetMark, Inc. All rights reserved.
              <br />
              <br />
              104811 | 6537789.1. | 04/2024 | EXP 4/2026
            </Typography>
          </Grid>
        </Grid>
        <Footer
          numberPage={slide.slideTemplateId}
          text="For Financial Advisor use only"
        />
      </Grid>
    );
  },
);

export default Slide9Content;
