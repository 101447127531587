import React from 'react';

import { Drawer, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgressBar from 'components/CircularProgressBar';
interface ProposalViewProps {
  url: string;
  isOpenProposalView: boolean;
  setIsOpenProposalView: (value: boolean) => void;
}

const ProposalView: React.FC<ProposalViewProps> = ({
  url,
  isOpenProposalView,
  setIsOpenProposalView,
}) => {
  const handleDrawerClose = () => {
    setIsOpenProposalView(false);
  };

  return (
    <DrawerCustom variant="persistent" anchor="right" open={isOpenProposalView}>
      <DrawerHeader sx={{ borderBottom: '1px solid #f1f1f1' }}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      {url === '' ? (
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          <CircularProgressBar text="Please wait while we generate a proposal for you." />
        </Stack>
      ) : (
        <iframe
          width="100%"
          height="100%"
          loading="lazy"
          style={{ border: 'none' }}
          src={url}
        />
      )}
    </DrawerCustom>
  );
};

export default ProposalView;

export const DrawerCustom = styled(Drawer)({
  variants: 'persistent',
  '& .MuiDrawer-paper': {
    width: '1100px',
    '@media (max-width: 1400px)': {
      width: '100%',
    },
  },
});

const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  justifyContent: 'flex-start',
});
