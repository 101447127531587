import { Grid } from '@mui/material';
import React from 'react';
import PrompQuestions from '@components/PrompQuestions';
import {
  QuestionsCardContainer,
  QuestionsCardItems,
  QuestionsCardsContainer,
  QuestionsCardsTitle,
  QuestionsCardTitle,
} from './styles';

interface QuestionsCardProps {
  title: string;
  titleIcon: string;
  questions: {
    title: string;
    icon: string;
    questions: {
      text: string;
      prompt: string;
      navigate: string;
    }[];
  }[];
  handleQuestionClick: (question: string) => void;
}

function QuestionsCards(props: QuestionsCardProps): React.JSX.Element {
  const calculateGridMdColumns = (questionsLength: number): number =>
    questionsLength <= 4 ? 12 / questionsLength : 3;

  return (
    <QuestionsCardsContainer>
      <QuestionsCardsTitle>
        <img src={props.titleIcon} alt="" />
        {props.title}
      </QuestionsCardsTitle>

      <Grid container>
        {props.questions.map((card, index) => (
          <Grid
            item
            xs={12}
            md={calculateGridMdColumns(props.questions.length)}
            p="3px"
            key={index}
          >
            <QuestionsCardContainer key={index}>
              <QuestionsCardTitle>
                <img src={card.icon} alt={card.title} />
                {card.title}
              </QuestionsCardTitle>

              <QuestionsCardItems>
                <PrompQuestions
                  questions={card.questions.map(({ text }) => text)}
                  itemColor="#fff"
                  itemPy="5px"
                  handleQuestionClick={props.handleQuestionClick}
                />
              </QuestionsCardItems>
            </QuestionsCardContainer>
          </Grid>
        ))}
      </Grid>
    </QuestionsCardsContainer>
  );
}

export default QuestionsCards;
