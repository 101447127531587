import {
  AppendedPooCommentaries,
  PooCommentaries,
  UseCreatePooArguments,
} from '../components/PresentationOfOptions/useCreatePoo';
export const parseCommentariesToPoo = (
  data: UseCreatePooArguments,
  commentaries: PooCommentaries | AppendedPooCommentaries,
): any => {
  switch (data.type) {
    case 'initial':
      return parseCommentariesToInitialPoo(data, commentaries);
    case 'appended':
      return parseCommentariesToAppendedPoo(data, commentaries);
    case 'final':
      return parseCommentariesToFinalPoo(data, commentaries);
    default:
      return null;
  }
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseModelSetToPoo = (modelSet, portfolio_options): any => {
  const modifiedModelSet = {
    ...modelSet,
    portfolio_options: [portfolio_options],
  };
  return modifiedModelSet;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const parseCommentariesToInitialPoo = (
  data: UseCreatePooArguments,
  commentaries: any,
): any => {
  return {
    version: '1',
    type: data.type,
    commentariesData: commentaries.data,
    pptTemplateId: '188ruy32zRgsD2crwdkE098lp1fjmnPxtn4xbkIH03bs',
    slides: [...slidesInitial(commentaries, data)],
  };
};

const parseCommentariesToAppendedPoo = (
  data: UseCreatePooArguments,
  commentaries: any,
): any => {
  return {
    version: '1',
    type: data.type,
    commentariesData: commentaries.data,
    pptTemplateId: '188ruy32zRgsD2crwdkE098lp1fjmnPxtn4xbkIH03bs',
    slides: [...slidesAppended(commentaries, data)],
  };
};

const parseCommentariesToFinalPoo = (
  data: UseCreatePooArguments,
  commentaries: any,
): any => {
  return {
    version: '1',
    type: data.type,
    commentariesData: commentaries,
    pptTemplateId: '188ruy32zRgsD2crwdkE098lp1fjmnPxtn4xbkIH03bs',
    slides: [...slidesFinal(commentaries, data)],
  };
};

const slidesFinal = (commentaries: any, data: UseCreatePooArguments) => {
  let slideId = 1;
  const slides: any[] = [];

  slides.push(
    ...slide1({
      ...commentaries,
      id: slideId,
      date: new Date().toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      }),
      caseName: data.caseName,
    }),
  );
  slideId++;
  slides.push(
    ...slide4({
      ...commentaries,
      id: slideId,
      model_set_name: 'Final Model Selection',
      date: new Date().toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      }),
    }),
  );
  slideId++;

  commentaries.generatedCommentaries.model_set_holdings.forEach((item: any) => {
    slides.push(
      ...slide10({
        ...commentaries,
        id: slideId,
        model_selected: item.model_selected,
      }),
    );
    slideId++;
    item.portfolio_options.forEach((option: any) => {
      slides.push(
        ...slide5({
          id: slideId,
          model_set_holdings: item,
          strategist_mix: option.strategist_mix,
          portfolio_options: option,
          option: option.option,
        }),
      );
      slideId++;
    });
  });
  if (commentaries.basePresentation === undefined) {
    slides.push(...slide9({ id: slideId }));
    slideId++;
  } else if (commentaries.basePresentation.type === 'initial') {
    slides.push(
      ...slide4({
        id: slideId,
        model_set_name: 'Initial Model Presentation',
        date: commentaries.basePresentation.slides[0].content[3].value,
      }),
      slideId++,
    );
  } /*else if (commentaries.basePresentation.type === 'appended') {
    slides.push(...slide10({ id: slideId }));
    slideId++;
  }*/
  if (commentaries.basePresentation !== undefined) {
    commentaries.basePresentation !== undefined &&
      commentaries.basePresentation.slides.slice(1).forEach((slide: any) => {
        slides.push({ ...slide, slideTemplateId: slideId });
        slideId++;
      });
  }
  return slides;
};

const slidesAppended = (commentaries: any, data: UseCreatePooArguments) => {
  let slideId = 1;
  const slides: any[] = [];

  slides.push(
    ...slide1({
      ...commentaries,
      id: slideId,
      date: new Date().toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      }),
      caseName: data.caseName,
    }),
  );
  slideId++;
  slides.push(
    ...slide4({
      ...commentaries,
      id: slideId,
      model_set_name: 'Model Updates',
      date: new Date().toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      }),
    }),
  );
  slideId++;
  slides.push(
    ...slide2({
      ...commentaries,
      id: slideId,
      title1: 'Model Update',
      title2: 'Models Updated',
      model_request: commentaries.generatedCommentaries.model_update,
      model_developed: commentaries.generatedCommentaries.models_updated,
    }),
  );
  slideId++;
  commentaries.generatedCommentaries.model_set_holdings.forEach((item: any) => {
    slides.push(
      ...slide4({ id: slideId, model_set_name: item.model_set_name, date: '' }),
    );
    slideId++;

    item.portfolio_options.forEach((option: any) => {
      slides.push(
        ...slide5({
          id: slideId,
          model_set_holdings: item,
          strategist_mix: option.strategist_mix,
          portfolio_options: option,
          option: option.option,
        }),
      );
      slideId++;

      const allHoldings = option.portfolios.flatMap(
        (portfolio: any) => portfolio.holdings,
      );
      const uniqueHoldings = allHoldings.reduce((acc: any[], current: any) => {
        const exists = acc.find(
          newHolding =>
            newHolding.model_id === current.model_id ||
            newHolding.strategy === current.strategy,
        );
        if (!exists) {
          acc.push(current);
        }
        return acc;
      }, []);

      const holdingsChunks = chunkArray(uniqueHoldings, 4);

      holdingsChunks.forEach(holdingsChunk => {
        slides.push(
          ...slide6({
            id: slideId,
            holdings: [{ holdings: holdingsChunk }],
            option: option.option,
          }),
        );
        slideId++;
      });
    });
    slides.push(
      ...slide7({
        id: slideId,
        model_set_holdings: item,
      }),
    );
    slideId++;

    slides.push(
      ...slide8({
        id: slideId,
        model_differences: item.model_differences,
      }),
    );
    slideId++;
  });
  if (commentaries.basePresentation !== undefined) {
    slides.push(
      ...slide4({
        id: slideId,
        model_set_name: 'Initial Model Presentation',
        date: commentaries.basePresentation.slides[0].content[3].value,
      }),
    );
    slideId++;
    commentaries.basePresentation.slides.slice(1).forEach((slide: any) => {
      slides.push({ ...slide, slideTemplateId: slideId });
      slideId++;
    });
  }
  return slides;
};

const slidesInitial = (commentaries: any, data: UseCreatePooArguments) => {
  let slideId = 1;
  const slides: any[] = [];

  slides.push(
    ...slide1({
      id: slideId,
      date: new Date().toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      }),
      caseName: data.caseName,
    }),
  );
  slideId++;

  slides.push(
    ...slide2({
      id: slideId,
      title1: 'Models Request',
      title2: 'Models Developed',
      model_request: commentaries.generatedCommentaries.model_request,
      model_developed: commentaries.generatedCommentaries.model_developed,
    }),
  );
  slideId++;
  slides.push(...slide3({ id: slideId }));
  slideId++;
  commentaries.generatedCommentaries.model_set_holdings.forEach((item: any) => {
    slides.push(
      ...slide4({ id: slideId, model_set_name: item.model_set_name }),
    );
    slideId++;

    item.portfolio_options.forEach((option: any) => {
      slides.push(
        ...slide5({
          id: slideId,
          model_set_holdings: item,
          strategist_mix: option.strategist_mix,
          portfolio_options: option,
          option: option.option,
        }),
      );
      slideId++;

      const allHoldings = option.portfolios.flatMap(
        (portfolio: any) => portfolio.holdings,
      );
      const uniqueHoldings = allHoldings.reduce((acc: any[], current: any) => {
        const exists = acc.find(
          newHolding =>
            newHolding.model_id === current.model_id ||
            newHolding.strategy === current.strategy,
        );
        if (!exists) {
          acc.push(current);
        }
        return acc;
      }, []);

      const holdingsChunks = chunkArray(uniqueHoldings, 4);

      holdingsChunks.forEach(holdingsChunk => {
        slides.push(
          ...slide6({
            id: slideId,
            holdings: [{ holdings: holdingsChunk }],
            option: option.option,
          }),
        );
        slideId++;
      });

      slides.push(
        ...slide7({
          id: slideId,
          model_set_holdings: item,
        }),
      );
      slideId++;

      slides.push(
        ...slide8({
          id: slideId,
          model_differences: item.model_differences,
        }),
      );
      slideId++;
    });
  });
  slides.push(...slide9({ id: slideId }));
  return slides;
};

const slide1 = (data: any) => {
  return [
    {
      id: 0,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: 'Investment Consulting',
          type: 'text',
        },
        {
          field: 'subtitle1',
          label: 'Subtitle 1',
          value: data.caseName,
          type: 'editableText',
        },
        {
          field: 'subtitle2',
          label: 'Subtitle 2',
          value: 'Investment Portfolio Ideas',
          type: 'text',
        },
        {
          field: 'date',
          label: 'Date',
          value: data.date,
          type: 'editableText',
        },
      ],
    },
  ];
};

const slide2 = (data: any) => {
  return [
    {
      id: 1,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title1',
          label: 'Title 1',
          value: data.title1,
          type: 'editableTextarea',
        },
        {
          field: 'title2',
          label: 'Title 2',
          value: data.title2,
          type: 'editableTextarea',
        },
        {
          field: 'model_request',
          label: 'Model Request',
          value: data.model_request,
          type: 'editableTextarea',
        },
        {
          field: 'model_developed',
          label: 'Model Developed',
          value: data.model_developed,
          type: 'editableTextarea',
        },
      ],
    },
  ];
};

const slide3 = (data: any) => {
  return [
    {
      id: 2,
      slideTemplateId: data.id,
      content: [],
    },
  ];
};

const slide4 = (data: any) => {
  return [
    {
      id: 3,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: data.model_set_name,
          type: 'editableText',
        },
        {
          field: 'date',
          label: 'Date',
          value: data.date,
          type: 'editableText',
        },
      ],
    },
  ];
};

const slide5 = (data: any) => {
  return [
    {
      id: 4,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: 'Option ' + data.option + ' - Strategist Mix',
          type: 'editableTextarea',
        },
        {
          field: 'subtitle1',
          label: 'CORE RETURN',
          value: data.strategist_mix,
          type: 'editableTextarea',
        },
        {
          field: 'modelSet',
          label: 'Model Set',
          value: parseModelSetToPoo(
            data.model_set_holdings,
            data.portfolio_options,
          ),
          type: 'modelSet',
        },
      ],
    },
  ];
};

const slide6 = (data: any) => {
  return [
    {
      id: 5,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: 'Option ' + data.option + ' - Strategist Selection',
          type: 'editableTextarea',
        },
        {
          field: 'table',
          label: 'Table',
          value: data.holdings,
          type: 'editableTextarea',
        },
      ],
    },
  ];
};

const slide7 = (data: any) => {
  return [
    {
      id: 6,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: 'Model Comparison',
          type: 'editableTextarea',
        },
        {
          field: 'modelSet',
          label: 'Model Set',
          value: data.model_set_holdings,
          type: 'modelSet',
        },
      ],
    },
  ];
};

const slide8 = (data: any) => {
  return [
    {
      id: 7,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: 'Model Differences',
          type: 'editableTextarea',
        },
        {
          field: 'text',
          label: 'Text',
          value: data.model_differences,
          type: 'editableTextarea',
        },
      ],
    },
  ];
};

const slide9 = (data: any) => {
  return [
    {
      id: 8,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: 'Important Information',
          type: 'simple',
        },
      ],
    },
  ];
};

const slide10 = (data: any) => {
  return [
    {
      id: 9,
      slideTemplateId: data.id,
      content: [
        {
          field: 'title',
          label: 'Title',
          value: 'Model Selected',
          type: 'editableTextarea',
        },
        {
          field: 'model_selected',
          label: 'Model Selected',
          value: data.model_selected,
          type: 'editableTextarea',
        },
      ],
    },
  ];
};

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};
