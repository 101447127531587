import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

export type CasesOrderBy = 'createdAt' | 'updatedAt';

export type CasesOrder = 'asc' | 'desc';

export type Case = {
  advisorName: string;
  id: string;
  description: string;
  name: string;
  owners: string;
  createdAt: string;
  stage: string;
  status: string;
  tier: string;
  updatedAt: string;
  milestones?: CaseMilestone[];
};

export type CaseMilestoneType =
  | 'consult_form'
  | 'portfolio_construction'
  | 'presentation_of_options'
  | 'performance_report'
  | 'premium_report';

export type CaseMilestone = {
  id: string;
  enterpriseId: string;
  displayName: string;
  type: CaseMilestoneType;
  createdAt: string;
  updatedAt: string;
  actions: CaseMilestoneAction[];
};

export type CaseMilestoneAction = {
  id: string;
  caseId: string;
  userId: string;
  milestoneId: string;
  versionName: string;
  createdAt: string;
  updatedAt: string;
  draft: boolean;
  value?: any;
};

type UseGetCasesArguments = {
  currentPage: number;
  myCasesFilter: boolean;
  order: string;
  orderBy: string;
  pageSize: number;
  searchFilter: string;
  statusFilter: string;
};

type UseGetCasesResult = {
  cases: Case[];
  casesStatusCount: {
    complete: number;
    inProgress: number;
  };
  casesCurrentPage: number;
  casesTotalCount: number;
  casesTotalPages: number;
  isLoadingCases: boolean;
};

type useGetCasesResponse = {
  data: Case[];
  statusCount: {
    complete: number;
    inProgress: number;
  };
  currentPage: number;
  totalCount: number;
  totalPages: number;
};

export const useGetCases = ({
  myCasesFilter,
  statusFilter,
  searchFilter,
  currentPage,
  order,
  orderBy,
  pageSize,
}: UseGetCasesArguments): UseGetCasesResult => {
  const { fetchQuery } = useFetchQuery();

  const getQueryParams = (isMyCases: boolean) => {
    const queryParams = new URLSearchParams();
    queryParams.set('page', currentPage.toString());
    queryParams.set('myCases', isMyCases.toString());
    queryParams.set('order', order);
    queryParams.set('orderBy', orderBy);
    queryParams.set('limit', pageSize.toString());
    queryParams.set('search', searchFilter);
    if (statusFilter) {
      queryParams.set('status', statusFilter);
    }
    return queryParams;
  };

  // Consulta para myCases = true
  const { data: myCasesData, isLoading: isLoadingMyCases } =
    useQuery<useGetCasesResponse>(
      [
        'cases',
        currentPage,
        true, // myCases true
        order,
        orderBy,
        pageSize,
        searchFilter,
        statusFilter,
      ],
      fetchQuery({ path: `v2/ocios/cases?${getQueryParams(true).toString()}` }),
    );

  // Consulta para myCases = false
  const { data: allCasesData, isLoading: isLoadingAllCases } =
    useQuery<useGetCasesResponse>(
      [
        'cases',
        currentPage,
        false, // myCases false
        order,
        orderBy,
        pageSize,
        searchFilter,
        statusFilter,
      ],
      fetchQuery({
        path: `v2/ocios/cases?${getQueryParams(false).toString()}`,
      }),
    );

  // Seleccionar los datos según el filtro actual
  const selectedData = myCasesFilter ? myCasesData : allCasesData;

  const cases = {
    cases: selectedData?.data || [],
    casesStatusCount: {
      complete: selectedData?.statusCount?.complete || 0,
      inProgress: selectedData?.statusCount?.inProgress || 0,
    },
    casesCurrentPage: selectedData?.currentPage || 1,
    casesTotalCount: selectedData?.totalCount || 0,
    casesTotalPages: selectedData?.totalPages || 0,
  };

  return {
    ...cases,
    isLoadingCases: myCasesFilter ? isLoadingMyCases : isLoadingAllCases,
  };
};
