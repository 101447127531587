export const initialProposalCheckboxes = [
  {
    title: 'General',
    column: 1,
    elements: [
      {
        title: 'Cover',
        type: 'COVER',
        // Specifies whether or not to display in the DOM
        // Whether displayed or not, it will always be sent to the server
        hidden: false,
        // Checkbox status
        checked: true,
      },
      {
        title: 'Table of Contents',
        type: 'TABLE_OF_CONTENTS',
        hidden: false,
        checked: true,
      },
      {
        title: 'IPS Introduction',
        type: 'IPS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Driver Results',
    column: 1,
    elements: [
      {
        title: 'Risk Band',
        type: 'RISK_BAND',
        hidden: false,
        checked: true,
      },
      {
        title: 'Assessment Inputs',
        type: 'ASSESSMENT_INPUTS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Recommendation',
    column: 1,
    elements: [
      {
        title: 'Snapshot',
        type: 'RECOMMENDED_PORTFOLIO_SNAPSHOT',
        hidden: false,
        checked: true,
      },
      {
        title: 'Holdings',
        type: 'RECOMMENDED_PORTFOLIO_HOLDINGS',
        hidden: false,
        checked: true,
      },
      {
        title: 'Risk',
        type: 'RECOMMENDED_PORTFOLIO_RISK_SCORE',
        hidden: false,
        checked: true,
      },
      {
        title: 'Expenses',
        type: 'RECOMMENDED_PORTFOLIO_EXPENSE_RATIO',
        hidden: false,
        checked: true,
      },
      {
        title: 'Allocations',
        type: 'RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Current Portfolio',
    column: 1,
    elements: [
      {
        title: 'Holdings',
        type: 'CURRENT_PORTFOLIO_HOLDINGS',
        hidden: false,
        checked: true,
      },
      {
        title: 'Allocations',
        type: 'CURRENT_PORTFOLIO_ALLOCATION_CHARTS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Compare',
    column: 2,
    elements: [
      {
        title: 'Risk Scores',
        type: 'COMPARE_RISK',
        hidden: false,
        checked: true,
      },
      {
        title: 'Expense Ratio',
        type: 'COMPARE_EXPENSE_RATIO',
        hidden: false,
        checked: true,
      },
      {
        title: 'Holdings',
        type: 'COMPARE_HOLDINGS',
        hidden: false,
        checked: true,
      },
      {
        title: 'Asset Allocation',
        type: 'ASSET_ALLOCATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Region Allocation',
        type: 'REGION_ALLOCATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Historical Performance',
        type: 'HISTORICAL_PERFORMANCE',
        hidden: false,
        checked: true,
      },
      {
        title: 'Return Simulation',
        type: 'RETURN_SIMULATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Historical Return Simulation',
        type: 'COMPARE_HISTORICAL_RETURN_SIMULATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Sectors',
        type: 'SECTOR_ALLOCATION',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Analytics',
    column: 2,
    elements: [
      {
        title: 'Historical Returns',
        type: 'HISTORICAL_RETURN',
        hidden: false,
        checked: true,
      },
      {
        title: 'Potential Return',
        type: 'POTENTIAL_RETURN',
        hidden: false,
        checked: true,
      },
      {
        title: 'Risk Statistics',
        type: 'POTENTIAL_RISK',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Disclosures',
    column: 2,
    elements: [
      {
        title: 'Duties and Responsibilities',
        type: 'DUTIES_AND_RESPONSIBILITIES',
        hidden: false,
        checked: true,
      },
      {
        title: 'Disclosures',
        type: 'DISCLOSURES',
        hidden: false,
        checked: true,
      },
      {
        title: 'Advisor Fee Disclosure',
        type: 'ADVISOR_FEE_DISCLOSURE',
        hidden: false,
        checked: true,
      },
      {
        title: 'Risks and Disclosures',
        type: 'RISK_DISCLOSURES',
        hidden: false,
        checked: true,
      },
      {
        title: 'Appendix',
        type: 'APPENDIX',
        hidden: false,
        checked: true,
      },
      {
        title: 'Appendix CMA',
        type: 'APPENDIX_CMA',
        hidden: false,
        checked: true,
      },
    ],
  },
];

export const initialRiskAssessmentCheckboxes = [
  {
    title: 'Driver Results',
    column: 1,
    elements: [
      {
        title: 'Risk Band',
        type: 'RISK_BAND',
        hidden: false,
        checked: true,
      },
      {
        title: 'Assessment Inputs',
        type: 'ASSESSMENT_INPUTS',
        hidden: false,
        checked: true,
      },
    ],
  },
];
