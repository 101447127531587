/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from 'utils/instances';

export const getChatHistory = async (): Promise<any> => {
  return await ApiInstance.get(`/chat/init`, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
    // TODO: if we need to encode sse data as well we can use below code in future
    // responseType: 'arraybuffer',
  }).then((res: any) => {
    return res.data;
  });
};

export const getBotResponse = async (params: any): Promise<any> => {
  return await ApiInstance.get(`/chat`, params).then(res => res.data);
};

export const submitChatFeedback = async (request: any): Promise<any> => {
  const { payload } = request;
  return await ApiInstance.post(`/chat/feedback`, payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  }).then(res => res.data);
};
