import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../../helpers/api';

type UsePostRecordLoginResult = {
  recordLoginMutate: () => void;
};

export const usePostRecordLogin = (): UsePostRecordLoginResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate } = useMutation(() =>
    fetchMutation({
      path: '/user/recordLogin',
      method: 'POST',
    }),
  );

  return { recordLoginMutate: mutate };
};
