import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type UseGetCaseActionByIdArguments = {
  actionId: string;
  caseId: string;
};

type UseGetCaseActionByIdResult = {
  action: any | undefined;
  isActionLoading: boolean;
  isActionFetching: boolean;
  getModelSetHolding: (modelSetName: string) => any;
  getModelSetsBasicData: () => any;
  error: any;
};

type UseGetCaseActionByIdResponse = {
  data: {
    id: string;
    case_id: string;
    user_id: string;
    milestone_id: string;
    version_name: string;
    created_at: string;
    updated_at: string;
    draft: boolean;
    value: any;
  };
};

export const useGetCaseActionById = ({
  actionId,
  caseId,
}: UseGetCaseActionByIdArguments): UseGetCaseActionByIdResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading, isFetching, error } =
    useQuery<UseGetCaseActionByIdResponse>(
      ['action', actionId],
      fetchQuery({
        path: `v2/cases/${caseId}/milestones/actions/${actionId}`,
      }),
      { enabled: !!actionId && !!caseId },
    );

  const getModelSetHolding = (modelSetName: string) => {
    if (!data?.data?.value?.model_set_holdings) return null;

    const modelSet = data.data.value.model_set_holdings.find(
      modelSetHolding => modelSetHolding.model_set_name === modelSetName,
    );

    if (!modelSet) return null;

    return {
      model_set_name: modelSet.model_set_name,
      portfolio_options: modelSet.portfolio_options.map(option => ({
        option: option.option || 1,
        portfolios: option.portfolios.map(portfolio => ({
          portfolio_name: portfolio.portfolio_name,
          risk_profile: portfolio.risk_profile,
          holdings: portfolio.holdings.map(holding => ({
            model_id: holding.model_id,
            weight: holding.weight,
          })),
        })),
      })),
    };
  };

  const getModelSetsBasicData = () => {
    return (
      data?.data.value.model_set_holdings.map(modelSetHolding => ({
        model_set_name: modelSetHolding.model_set_name,
        abbreviated_model_set_name: modelSetHolding.abbreviated_model_set_name,
        portfolio_options: modelSetHolding.portfolio_options.map(option => ({
          option: option.option,
          portfolios: option.portfolios.map(portfolio => ({
            portfolio_name: portfolio.portfolio_name,
            risk_profile: portfolio.risk_profile,
            holdings: portfolio.holdings.map(holding => ({
              model_id: holding.model_id,
              weight: holding.weight,
            })),
          })),
        })),
      })) || []
    );
  };

  return {
    action: data?.data || undefined,
    isActionLoading: isLoading,
    isActionFetching: isFetching,
    getModelSetsBasicData,
    getModelSetHolding,
    error,
  };
};
