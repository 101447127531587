import { settings } from 'config';
import { delay, http, HttpResponse } from 'msw';

function success() {
  const data = {
    message: 'ok',
    data: {
      foo: {
        title: 'My title',
      },
    },
  };

  const status = 200;
  return HttpResponse.json(data, { status });
}

export const getPooCommentaries = http.get(
  `${settings.api.baseUrl}/v2/cases/:caseId/milestones/poo/generatedCommentaries?portfolioConstructionId=:portfolioConstructionId&consultFormId=:consultFormId&presentationId=:presentationId&type=:type`,
  async () => {
    await delay(100);
    return success();
  },
);
