import { Grid } from '@mui/material';
import { ChartBox, SlideTitle } from 'features/slides';
import { PortfolioStatisticsBarChart } from 'pages/Cases/components/PortfolioStatisticsBarChart';
import SummaryPerformanceTable from 'pages/Cases/components/SummaryPerformanceTable';
import {
  columnsTrailingYearReturns,
  getPortfolioStatisticsRows,
  portfolioStatisticsAnnualChartFormatedNames,
  portfolioStatisticsChartFilter,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';

export type AnnualizedReturnsProps = {
  readonly filteredPerformance: any;
  readonly rowsAnnualizedReturnsTable?: boolean;
};

export function AnnualizedReturns({
  filteredPerformance,
  rowsAnnualizedReturnsTable,
}: AnnualizedReturnsProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>Annualized Returns</SlideTitle>
      </Grid>

      <ChartBox>
        <PortfolioStatisticsBarChart
          data={portfolioStatisticsAnnualChartFormatedNames(
            filteredPerformance,
            columnsTrailingYearReturns,
          )}
          ytdFinal={false}
          height={rowsAnnualizedReturnsTable ? 620 : 800}
        />
      </ChartBox>
      {rowsAnnualizedReturnsTable && (
        <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
          <SummaryPerformanceTable
            rows={getPortfolioStatisticsRows(
              portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'trailing_year_returns',
              }),
            )}
            columns={columnsTrailingYearReturns}
          />
        </Grid>
      )}
    </Grid>
  );
}
