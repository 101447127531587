import { SlideType } from 'features/slides/types';
import React from 'react';
import { useSlideContext } from '../../context';
import { Slide } from '../slide';
import styles from './styles.module.css';
import { Grid } from '@mui/material';

export type SlidesViewportProps = {
  readonly index: number;
  readonly slide: SlideType;
  readonly editable?: boolean;
};

export function SlidesViewport({
  index,
  slide,
  editable = true,
}: SlidesViewportProps): React.ReactElement {
  const { zoom } = useSlideContext();
  const viewportRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (viewportRef.current) {
      const scale = zoom / 100;
      const element = viewportRef.current.children[0] as HTMLElement;
      if (element) {
        // Aplicar transformación manteniendo el punto de origen
        element.style.transform = `scale(${scale})`;
        element.style.transformOrigin = 'top left';

        // Ajustar el tamaño del contenedor para acomodar el contenido escalado
        const originalWidth = element.scrollWidth / scale;
        const originalHeight = element.scrollHeight / scale;

        viewportRef.current.style.width = `${originalWidth * scale}px`;
        viewportRef.current.style.height = `${originalHeight * scale}px`;
      }
    }
  }, [zoom]);

  return (
    <Grid
      container
      className={styles.viewport}
      sx={{
        paddingLeft: '20px',
        paddingTop: '20px',
      }}
    >
      <Grid
        item
        xs={12}
        className={styles.viewportInner}
        sx={{
          transform: `scale(${zoom / 100})`,
          transformOrigin: 'top left',
        }}
      >
        <Slide slide={slide} index={index} editable={editable} />
      </Grid>
    </Grid>
  );
}
