import React from 'react';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { UsePostCalculateFeesResponse } from 'pages/Cases/hooks/usePostCalculateFees';

interface ModelSetsFeesTableProps {
  fees: UsePostCalculateFeesResponse;
}

const ModelSetsFeesTable: React.FC<ModelSetsFeesTableProps> = ({
  fees,
}: ModelSetsFeesTableProps) => {
  const expenseDate = fees.expense_date;

  const getColumns = (rows: any) => {
    if (rows.length === 0) {
      return [];
    }
    const baseColumns: GridColDef[] = [
      {
        field: 'metric',
        headerName: '',
        flex: 1.3,
        minWidth: 270,
        sortable: false,
        renderHeader: () => <Typography>As of: {expenseDate}</Typography>,
      },
    ];
    const optionColumns = Object.keys(rows[0])
      .filter(key => key.startsWith('option'))
      .map(field => ({
        field,
        headerName: field.split('_')[1],
        sortable: false,
        flex: 0.4,
        minWidth: 60,
        headerAlign: 'center' as const,
        align: 'center' as const,
        editable: false,
        type: 'number' as const,
      }));
    return [...baseColumns, ...optionColumns];
  };

  const getColumnGroupingModel = (modelSet: any, modelSetName: string) =>
    modelSet?.portfolio_options.map((option: any) => ({
      groupId: `Option${option.option}`,
      headerName: `${modelSetName}. Option ${option.option}`,
      description: modelSet.model_set_name + ' - Option ' + option.option,
      headerAlign: 'center',
      renderHeaderGroup: HeaderGroup,
      children: option.portfolios.map(portfolio => ({
        field: `option${option.option}_${portfolio.risk_profile}`,
        headerName: portfolio.risk_profile.toString(),
        renderHeader: () => portfolio.risk_profile.toString(),
      })),
    }));
  const HeaderGroup = (params: any) => (
    <Box sx={{ textAlign: 'center', width: '100%' }}>
      <Typography variant="caption" component="div">
        {params.description}
      </Typography>
    </Box>
  );

  const rows = [
    {
      id: 2,
      metric: 'Platform Fee* (bps)',
      editable: false,
      ...Object.fromEntries(
        fees.portfolio_options.flatMap(option =>
          option.portfolios.map(portfolio => [
            `option${option.option}_${portfolio.risk_profile}`,
            portfolio.platform_fee.toFixed(2),
          ]),
        ),
      ),
    },
    {
      id: 3,
      metric: 'Expense Ratio (bps)',
      editable: false,
      ...Object.fromEntries(
        fees.portfolio_options.flatMap(option =>
          option.portfolios.map(portfolio => [
            `option${option.option}_${portfolio.risk_profile}`,
            portfolio.expense_ratio.toFixed(2),
          ]),
        ),
      ),
    },
    {
      id: 4,
      metric: 'Total Expense* (bps)',
      editable: false,
      ...Object.fromEntries(
        fees.portfolio_options.flatMap(option =>
          option.portfolios.map(portfolio => [
            `option${option.option}_${portfolio.risk_profile}`,
            portfolio.total_expense.toFixed(2),
          ]),
        ),
      ),
    },
  ];

  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={getColumns(rows)}
          columnGroupingModel={getColumnGroupingModel(fees, 'Model Set')}
          hideFooter={true}
          rowHeight={36}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          sx={{
            borderTop: 'none',
            borderRight: 'none',
            '& *': {
              outline: 'none!important',
              cursor: 'default!important',
            },
            '& svg': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              borderBottom: 'none!important',
            },
            '& .MuiDataGrid-columnHeader': {
              borderTop: `1px solid rgba(224, 224, 224, 1)`,
              borderRight: `1px solid rgba(224, 224, 224, 1)`,
              backgroundColor: 'rgba(250, 250, 250, 1)',
              '&[data-field^="option"]': {
                backgroundColor: 'rgba(250, 250, 250, 1)',
              },
            },
            '& .MuiDataGrid-cell': {
              borderRight: `1px solid rgba(224, 224, 224, 1)`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ModelSetsFeesTable;
