import styled from 'styled-components';

export const StatusFilterContainer = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
  margin: 0;
  padding: 4px;
  border-radius: 18px;
  background-color: #f5f5f5;
  font-family: Inter, sans-serif;
  font-weight: 500;

  // item

  & .item {
    display: flex;
    align-items: center;
    padding: 0 2px 0 12px;
    height: 24px;
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
    transition:
      background-color 0.5s,
      color 0.5s;
  }

  & .item:hover {
    background-color: #fff;
  }

  & .item.selected {
    background-color: #000;
    color: #fff;
  }

  // title

  & .title {
    display: inline-block;
    padding: 0 4px 2px 0;
  }

  // badge

  & .badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    min-width: 10px;
    height: 20px;
    border-radius: 10px;
    font-size: 11px;
    background: #000;
    color: #fff;
  }

  & .item.selected .badge {
    background-color: #888;
  }

  & .item.empty-badge .badge {
    min-width: 0;
    background: transparent;
  }

  & .item.disabled {
    color: #bbb;
    cursor: default;

    & .badge {
      background: #bbb !important;
    }
  }
`;
