import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  MDXEditor,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  listsPlugin,
  BlockTypeSelect,
  ListsToggle,
  markdownShortcutPlugin,
  headingsPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import useClickOutside from '../../hooks/useClickOutside';
import styles from './styles.module.css';
import { type Slide } from '../../_data';
import { getSlideField } from '../../utils';
import { Grid } from '@mui/material';
import { SxProps } from '@mui/material';
type EditableFieldProps = {
  field: string;
  slide: Slide<any>;
  onSlideContentChange: (slide: Slide<any>, content: any) => void;
  type?: 'simple' | undefined;
  style?: SxProps;
  styleEditor?: SxProps;
  className?: string;
  editorClassName?: string;
};

export const EditableField = <T extends Record<string, unknown>>({
  field,
  slide,
  style,
  onSlideContentChange,
  type = undefined,
  className = '',
  editorClassName = '',
}: EditableFieldProps): React.ReactElement => {
  const [value, setValue] = useState(() => getSlideField(slide, field)?.value);
  const [readOnly, setReadOnly] = useState(true);
  const isUpdatingRef = useRef(false);

  const handleChange = useCallback(
    (markdown: string) => {
      if (isUpdatingRef.current) return;
      isUpdatingRef.current = true;

      setValue(markdown as T[keyof T]);
      const updatedContent = slide.content.map(item =>
        item.field === field ? { ...item, value: markdown } : item,
      );
      onSlideContentChange(slide, updatedContent);

      // Permitir la siguiente actualización después de un breve retraso
      setTimeout(() => {
        isUpdatingRef.current = false;
      }, 0);
    },
    [field, slide, onSlideContentChange],
  );

  // Solo actualizar el valor cuando el slide o field cambian
  useEffect(() => {
    const fieldValue = getSlideField(slide, field)?.value;
    if (!isUpdatingRef.current && fieldValue !== value) {
      setValue(fieldValue);
    }
  }, [slide.id, field]); // Dependencia más específica

  const ref = useClickOutside(event => {
    const target = event.target as HTMLElement;
    const editorElement = ref.current;

    // Verificar si el clic fue dentro del editor o en elementos del portal (dropdowns)
    const isInsideEditor = editorElement?.contains(target);
    const isPortalElement =
      document.querySelector('.cmd-menu')?.contains(target) || // Para los menús de comandos
      document.querySelector('[role="listbox"]')?.contains(target) || // Para dropdowns
      document.querySelector('[role="dialog"]')?.contains(target); // Para diálogos

    // Solo ocultamos si el clic no fue ni en el editor ni en elementos del portal
    if (!isInsideEditor && !isPortalElement) {
      setReadOnly(true);
    }
  });

  return (
    <Grid
      container
      ref={ref}
      className={`${styles.editableField} markdown-editor ${className}`}
      data-editable={!readOnly}
      onDoubleClick={() => setReadOnly(false)}
      sx={style}
    >
      <MDXEditor
        readOnly={readOnly}
        markdown={(value as string) || ''}
        onChange={handleChange}
        className={`${styles.mdxeditorRoot} ${styles[editorClassName] || ''}`}
        plugins={
          type === 'simple'
            ? []
            : [
                listsPlugin(),
                headingsPlugin(),
                markdownShortcutPlugin(),
                toolbarPlugin({
                  toolbarClassName: `slideToolbar ${readOnly ? styles.hidden : ''}`,
                  toolbarContents: () => (
                    <>
                      <BlockTypeSelect />
                      <ListsToggle options={['number', 'bullet']} />
                      <BoldItalicUnderlineToggles />
                    </>
                  ),
                }),
              ]
        }
      />
    </Grid>
  );
};
