import { Grid } from '@mui/material';
import { ChartBox, SlideTitle } from 'features/slides';
import { PortfolioStatisticsLineChart } from 'pages/Cases/components/PortfolioStatisticsLineChart';
import { SummaryStatisticsScatterChart } from 'pages/Cases/components/SummaryStatisticsScatterChart';
import { portfolioStatisticsChartFilter } from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';

export type ScatterPlotsProps = {
  readonly filteredPerformance: any;
};

export function ScatterPlots({
  filteredPerformance,
}: ScatterPlotsProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>Return and Risk Statistics</SlideTitle>
      </Grid>

      <Grid
        item
        container
        justifyContent="space-between"
        direction="row"
        gap={2}
      >
        <Grid item sx={{ width: 'calc(50% - 8px)' }}>
          <ChartBox>
            <SummaryStatisticsScatterChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'performance_stats',
              })}
              xValue="standard_deviation"
              yValue="cagr"
              name="Return / Standard Deviation"
              height={350}
            />
          </ChartBox>
        </Grid>
        <Grid item sx={{ width: 'calc(50% - 8px)' }}>
          <ChartBox>
            <SummaryStatisticsScatterChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'performance_stats',
              })}
              xValue="max_drawdown"
              yValue="cagr"
              name="Return / Maximum Drawdown"
              height={350}
            />
          </ChartBox>
        </Grid>
      </Grid>
      <ChartBox>
        <PortfolioStatisticsLineChart
          data={portfolioStatisticsChartFilter({
            filteredPerformance,
            name: 'drawdown_series',
          })}
          formatChart={{
            unit: '%',
            decimals: 2,
            prefix: '',
            value: 'Maximum Drawdown',
            label: 'Maximum Drawdown',
          }}
          name="Maximum Drawdown"
          height={350}
        />
      </ChartBox>
    </Grid>
  );
}
