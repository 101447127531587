import React, { createElement, useEffect, useState } from 'react';

import ListItems from '../ListItems';

import { Divider, Drawer, IconButton, List } from '@mui/material';

import ProfileMenuIcon from '@icons/ProfileMenuIcon';

interface DrawerCompProps {
  headerMenuList: any;
  profileMenuList: any;
  handleClose: () => void;
  handleLogout: () => void;
}

const DrawerComp: React.FC<DrawerCompProps> = ({
  headerMenuList,
  profileMenuList,
  handleClose,
  handleLogout,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const userType = localStorage.getItem('tifin-ai-user-type');

  const handleListItemClick = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    handleClose();
  }, []);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List sx={{ width: '250px' }}>
          {headerMenuList.map((item, index) => {
            if (item.showToUser.includes(userType)) {
              return (
                <ListItems
                  key={index}
                  path={item.path}
                  handleAction={handleListItemClick}
                  icon={createElement(item.Icon)}
                  text={item.name}
                />
              );
            }
          })}
        </List>
        <Divider />
        <List>
          {profileMenuList.map((item: any, index: number) => (
            <ListItems
              key={index}
              handleAction={
                item.path === '/logout' ? handleLogout : handleListItemClick
              }
              icon={item.Icon && <item.Icon sx={{ mr: 1.5 }} />}
              text={item.name}
              path={item.path}
            />
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: 'auto' }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <ProfileMenuIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
