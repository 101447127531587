/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@mui/material';

interface ProposalCheckboxesProps {
  disabled: boolean;
  hideCheckboxes: boolean;
  initialCheckboxes: any[];
  handleCheckboxChange: (newCheckboxes: any) => void;
}

const ProposalCheckboxes: FC<ProposalCheckboxesProps> = ({
  disabled,
  hideCheckboxes = false,
  initialCheckboxes,
  handleCheckboxChange,
}) => {
  const [checkboxes, setCheckboxes] = useState(initialCheckboxes);

  useEffect(() => {
    handleCheckboxChange(getCheckedElements(checkboxes));
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCheckboxes = checkboxes.map(group => ({
      ...group,
      elements: group.elements.map(element =>
        element.type === event.target.name
          ? {
              ...element,
              checked: event.target.checked,
            }
          : element,
      ),
    }));
    setCheckboxes(updatedCheckboxes);
    handleCheckboxChange(getCheckedElements(updatedCheckboxes));
  };

  const getCheckedElements = updatedCheckboxes =>
    updatedCheckboxes.map(group => ({
      title: group.title,
      elements: group.elements
        .filter(element => element.checked)
        .map(({ hidden, checked, ...rest }) => rest),
    }));

  const groupedCheckboxes = checkboxes.reduce((acc, group) => {
    if (!acc[group.column]) {
      acc[group.column] = [];
    }
    acc[group.column].push(group);
    return acc;
  }, {});

  const columns = Object.values(groupedCheckboxes).map(
    (checkboxGroups: any) => {
      return checkboxGroups.reduce((acc, checkboxGroup) => {
        acc[checkboxGroup.title] = checkboxGroup.elements
          .filter(element => !element.hidden)
          .map(element => {
            return { [element.type]: element.title };
          });
        return acc;
      }, {});
    },
  );

  if (hideCheckboxes) {
    return null;
  }

  return (
    <Grid container justifyContent={'start'} mb={4}>
      {columns.map((column, index) => (
        <Grid key={index} item xs={12} xl={6} pr={6}>
          <FormControl
            sx={{ color: 'black' }}
            component="fieldset"
            variant="standard"
            disabled={disabled}
          >
            {Object.entries(column).map(([key, value]) => (
              <Grid item key={key} sx={{ padding: '16px 0 0 0' }}>
                <FormLabel
                  component="legend"
                  sx={{
                    fontWeight: 'bold',
                    padding: '5px 0 0 0',
                    '&.Mui-focused': {
                      color: '#00000099',
                    },
                  }}
                >
                  {key}
                </FormLabel>
                <FormGroup sx={{ paddingLeft: '20px', paddingTop: '5px' }}>
                  {(value as { [key: string]: string }[]).map(item => {
                    const itemKey = Object.keys(item)[0];
                    const itemLabel = item[itemKey];
                    return (
                      <FormControlLabel
                        key={itemKey}
                        control={
                          <Checkbox
                            checked={
                              checkboxes
                                .flatMap(group => group.elements)
                                .find(element => element.type === itemKey)
                                ?.checked === true
                            }
                            onChange={handleChange}
                            name={itemKey}
                            sx={{ padding: '2px' }}
                          />
                        }
                        label={itemLabel}
                      />
                    );
                  })}
                </FormGroup>
              </Grid>
            ))}
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProposalCheckboxes;
