/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { settings } from 'config';
import { useMutation, useQueryClient } from 'react-query';

export const usePutCustomModelsMutation = () => {
  const queryClient = useQueryClient();

  const fetchData = async data => {
    const token = localStorage.getItem('x-tifin-ai-token') || '';
    const response = await axios.put(
      `${settings.api.baseUrl}/v2/models/${data.id}`,
      {
        name: data.name,
        holdings: data.holdings,
      },
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'x-tifin-ai-auth': token,
        },
      },
    );
    return response.data;
  };

  const { mutate } = useMutation(fetchData, {
    onSuccess() {
      queryClient.invalidateQueries('customModels');
    },
  });

  return {
    putCustomModelMutate: mutate,
  };
};
