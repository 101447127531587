import { portfolioStatisticsChartFilter } from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';

export function calculateDateRange(
  data: any[],
  name: string,
  subName: string,
  period: string,
): string {
  if (!data || !data.length) {
    return 'No data available';
  }

  const filteredPerformance = portfolioStatisticsChartFilter({
    filteredPerformance: data,
    name,
    subName,
  });

  const dates = Object.keys(filteredPerformance[0].data).map(date =>
    new Date(date).getTime(),
  );
  const startDate = new Date(Math.min(...dates)).toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  });
  const endDate = new Date(Math.max(...dates)).toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  });

  return `${startDate} - ${endDate} (${period} Month Moving Windows, Computed Monthly)`;
}
