import { Box, Grid, TextField } from '@mui/material';
import React, { FC } from 'react';
import { InputLabels } from '../Buttons';

interface EmailDisclosureProps {
  emailDisclosure: string | undefined;
  editable: boolean;
  onEmailDisclosureChange: (value: string) => void;
}

const EmailDisclosure: FC<EmailDisclosureProps> = ({
  emailDisclosure,
  editable,
  onEmailDisclosureChange,
}) => {
  return (
    <Grid container my={'24px'}>
      <Grid item sx={{ mr: '32px', width: '200px' }}>
        <Box mb={'6px'}>
          <InputLabels sx={{ color: '#000000', fontWeight: '500' }}>
            Email Disclosure
          </InputLabels>
        </Box>
        <Box>
          <p style={{ color: '#4F4F4F', fontWeight: '400', fontSize: '14px' }}>
            Updated 10.18.2025
          </p>
        </Box>
      </Grid>

      <Grid item sx={{ width: 'calc(100% - 232px)' }}>
        <TextField
          multiline
          fullWidth
          disabled={!editable}
          sx={{ paddingBottom: '24px' }}
          placeholder="You have not updated your email disclosure"
          value={emailDisclosure || ''}
          onChange={e => onEmailDisclosureChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default EmailDisclosure;
