import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { Tokens } from '@okta/okta-auth-js';
import { actions as profileActions } from '@features/Profile/slice';
import { recordLoginMutate } from './mutation';
import { config } from '@services/oktaAuth';
import OktaSigninWidget from './OktaSigninwidget';
import SageLogo from '@images/LogoSvg.svg';

function Login(): React.JSX.Element {
  const dispatch = useDispatch();
  const recordLoginMutation = useMutation(recordLoginMutate);

  const onSuccess = (tokens: Tokens) => {
    const { accessToken } = tokens;
    localStorage.setItem(
      'x-tifin-ai-token',
      `Bearer ${accessToken?.accessToken}`,
    );
    localStorage.setItem('tifin-ai-isLoggedIn', '1');
    if (accessToken?.accessToken) {
      dispatch(
        profileActions.userInfoRequest({
          token: `Bearer ${accessToken?.accessToken}`,
        }),
      );
      recordLoginMutation.mutate();
    }
  };

  const onError = (error: any) => {
    if (error?.message === 'PKCE verification failed.') {
      window.location.reload();
    }
    console.log('Sign in error:', error);
  };

  return (
    <LoginContainer>
      <LoginCover />
      <LoginFormContainer>
        <LoginForm>
          <LoginLogo alt="" src={SageLogo} />
          <LoginTitle>Sign in</LoginTitle>
          <LoginOktaContainer>
            <OktaSigninWidget
              config={config.widget}
              onSuccess={onSuccess}
              onError={onError}
            />
          </LoginOktaContainer>
          <BookDemoContainer>
            Don&apos;t have an account?{' '}
            <LoginBookADemo
              href="https://tifinsage.ai/"
              title="Book a demo"
              target="_blank"
            >
              Book a demo
            </LoginBookADemo>
          </BookDemoContainer>
        </LoginForm>
      </LoginFormContainer>
    </LoginContainer>
  );
}

export default Login;

/*
 * We put the styles here, because we do not want to use or modify the ./styles.tsx file, since it is being used in other components.
 * It is possible that the ./styles.tsx file can be deleted in the future.
 */

const LoginContainer = styled.div`
  display: flex;
  padding: 48px;
  height: 100vh;
  box-sizing: border-box;

  & > * {
    width: 50%;
  }
`;

const LoginCover = styled.div`
  background-image: url('/assets/images/login-cover.png');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
`;

const LoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 448px;
  padding-left: 48px;
`;

const LoginForm = styled.div`
  width: 400px;
`;

const LoginLogo = styled.img`
  width: 210px;
  margin-bottom: 20px;
`;

const LoginTitle = styled.h1`
  margin: 0 0 24px 0;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 500;
  border-top: 1px solid #ececec;
  padding-top: 24px;
`;

const LoginBookADemo = styled.a`
  font-weight: 600;
  color: #000;
`;

const LoginOktaContainer = styled.div`
  & #okta-sign-in,
  & #okta-sign-in * {
    all: initial !important; // reset all Okta styles
    font-family: 'Inter', sans-serif !important;
    font-size: 14px !important;
    box-sizing: border-box !important;
  }

  & #okta-sign-in .okta-form-title,
  & #okta-sign-in .auth-footer > .link.help.js-help,
  & #okta-sign-in .auth-footer .link.js-help-link,
  & #okta-sign-in .auth-footer .link.js-unlock,
  & #okta-sign-in .auth-footer .link.js-help,
  & #okta-sign-in .o-form-fieldset-container .accessibility-text {
    display: none !important;
  }

  // labels

  & #okta-sign-in .okta-form-label label {
    display: block !important;
    margin: 16px 0 4px 0 !important;
    font-weight: 400 !important;
  }

  // input fields

  & #okta-sign-in .okta-form-input-field input {
    display: block !important;
    width: 100% !important;
    padding: 12px 10px !important;
    border: 1px solid #e9e9e9 !important;
    border-radius: 4px !important;
    font-weight: 400 !important;
  }

  & #okta-sign-in #account-recovery-username {
    margin-bottom: 10px !important;
  }

  & #okta-signin-username,
  & #account-recovery-username {
    margin-bottom: 8px !important;
  }

  & #okta-signin-password {
    margin-bottom: 8px !important;
  }

  // errors

  & #okta-sign-in .okta-form-infobox-error {
    padding: 12px !important;
    border: 1px solid #ddd !important;
    border-left: 30px solid #e34843 !important;
    border-radius: 3px !important;

    &:before {
      display: none !important;
    }

    & .icon:before {
      display: inline-block !important;
      transform: translateX(-35px) !important;
    }
  }

  & #okta-sign-in .okta-form-input-error {
    display: block !important;
    margin-bottom: 16px !important;
    color: #d93934 !important;

    & .icon {
      position: relative !important;
      display: inline-block !important;
      margin-right: 20px !important;
      height: 15px !important;
      background-color: #00f !important;
    }
  }

  // remember me checkbox

  & #okta-sign-in .o-form-input-name-remember .custom-checkbox {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 24px !important;
    width: 50% !important;
    position: relative !important;
    z-index: 1 !important;
  }

  & #okta-sign-in input[type='checkbox'] {
    width: 20px !important;
    height: 20px !important;
    margin-right: 8px !important;
    border: 1px solid #e9e9e9 !important;
    border-radius: 4px !important;
    cursor: pointer !important;
  }

  & #okta-sign-in input[type='checkbox']:checked::after {
    content: '✓' !important;
    display: block !important;
    margin: -4px 0 0 0px !important;
    font-size: 20px !important;
    color: #1bc29a !important;
  }

  // submit buttons

  & #okta-sign-in #okta-signin-submit,
  & #okta-sign-in .button-primary {
    display: block !important;
    padding: 10px !important;
    margin-bottom: 4px !important;
    margin-top: 12px !important;
    width: 100% !important;
    background: #000 !important;
    text-align: center !important;
    color: #fff !important;
    cursor: pointer !important;
  }

  & #okta-sign-in .forgot-password .link-button {
    display: block !important;
    margin-top: 16px !important;
  }

  // forgot password and go back links

  & #okta-sign-in .js-forgot-password {
    display: block !important;
    height: 20px !important;
    text-align: right !important;
    cursor: pointer !important;
    margin-left: auto !important;
    width: fit-content !important;
    margin-top: 8px !important;
  }

  & #okta-sign-in .auth-footer > .link.js-cancel {
    display: block !important;
    margin: 10px 0 !important;
    cursor: pointer !important;
  }

  & #okta-sign-in .o-form-explain {
    display: block !important;
    margin-bottom: 10px !important;
  }

  // loading

  & #okta-sign-in .beacon-container {
    position: relative !important;
  }

  & #okta-sign-in .beacon-loading:after {
    top: 150px;
    left: 175px;
    width: 50px;
    height: 50px;
    border-top-color: #1bc29a;
  }
`;

const BookDemoContainer = styled.p`
  margin-top: 20px;
`;
