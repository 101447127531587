import { Grid } from '@mui/material';
import styled from 'styled-components';
import ArrowDoubleGo from '@images/arrow-double-go.svg';
import ArrowDoubleGoHover from '@images/arrow-double-go-hover.svg';

// cards container

export const NewUserStarterCardsContainer = styled.div`
  box-sizing: border-box;
  padding: 13px;
  width: 100%;
  background: #f5f5f5;
`;

export const NewUserStarterCardsTitle = styled.h2`
  display: flex;
  align-items: center;
  margin: 0 0 10px 6px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #4f4f4f;

  & img {
    margin-right: 8px;
  }
`;

// card container

export const NewUserStarterCardContainer = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  font-family: Inter, sans-serif;
`;

export const NewUserStarterCardTitle = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.75s cubic-bezier(0.53, -0.19, 0.49, 1.16);

  ${NewUserStarterCardContainer}:hover & {
    top: 20px;
    left: 20px;
    transform: translate(0, 0);
    font-size: 12px;
    color: #a1a1a1;
  }

  & img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    transition: all 0.75s cubic-bezier(0.53, -0.19, 0.49, 1.16);

    ${NewUserStarterCardContainer}:hover & {
      width: 14px;
      height: 14px;
    }
  }
`;

// card items

export const NewUserStarterCardItems = styled.ul`
  opacity: 0;
  margin: 0;
  padding: 45px 20px 10px 20px;
  transition: opacity 1s;
  list-style: none;
  cursor: pointer;

  ${NewUserStarterCardContainer}:hover & {
    opacity: 1;
  }

  &::after {
    // On touch devices you can click on the LIs without hovering first
    // We created this layer to prevent clicking on the LIs
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: width 0.75s;
  }

  ${NewUserStarterCardContainer}:hover &::after {
    // We hide the layer when we are on hover
    width: 0;
  }
`;

export const NewUserStarterCardItem = styled.li`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 0;
  font-size: 14px;
  color: #fff;
  transition: 0.1s all;
  &:hover {
    transition-delay: 0.1s;
    color: #1bc29a;
  }
`;

export const NewUserStarterCardItemMarker = styled.span`
  display: inline-block;
  font-size: 18px;

  ${NewUserStarterCardItem}:hover & {
    transition: transform 30s linear;
    transform: rotate(3600deg);
    color: #1bc29a;
  }
`;

export const NewUserStarterCardItemText = styled.a`
  width: 100%;
  padding-left: 8px;
`;

export const ActionsItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const ActionsItemsNumber = styled.span`
  font-size: 54px;
  font-weight: 800;
  line-height: 65.35px;
`;

export const ActionsItemsText = styled.span`
  margin: 0;
  color: #fff;
  height: auto;
  align-content: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  margin-left: 0.5em;
`;

export const ActionsItemsImage = styled.div`
  background: url(${ArrowDoubleGo});
  background-size: contain;
  background-repeat: no-repeat;
  width: 12.63px;
  height: 11.25px;
`;

export const StartedActions = styled(Grid)`
  font-weight: 500;
  font-size: 26px;
  color: #fff;
  background: #000;
  height: 148px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  transition: 0.1s all;
  &:hover {
    background: #00c3de;
    cursor: pointer;
    ${ActionsItemsText} {
      color: #000;
    }
    ${ActionsItemsNumber} {
      color: #000;
    }
    ${ActionsItemsImage} {
      background: url(${ArrowDoubleGoHover});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;
