import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePostOcioAdminArguments = {
  firstName: string;
  lastName: string;
  email: string;
  successToastMessage: string;
  advisoryFirmId: string;
};

type UsePostOcioAdminResult = {
  postOcioAdminMutate: (data: UsePostOcioAdminArguments) => void;
};

export const usePostOcioAdmin = (): UsePostOcioAdminResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate } = useMutation((data: UsePostOcioAdminArguments) =>
    fetchMutation({
      path: `/v2/ocios/admin`,
      method: 'POST',
      body: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        advisoryFirmId: data.advisoryFirmId,
      },
      invalidateQueries: [['ocio-admin']],
      successToastMessage: data.successToastMessage,
    }),
  );

  return { postOcioAdminMutate: mutate };
};
