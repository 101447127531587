import { Grid, Typography } from '@mui/material';
import { SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
export function StrategySelection({
  modelSetHoldings,
}: {
  modelSetHoldings: any[];
}): React.ReactElement {
  return (
    <Grid container direction="row" spacing={1} className={styles.container}>
      <Grid item xs={12}>
        <SlideTitle>Strategist Selection</SlideTitle>
        <DataGrid
          rows={modelSetHoldings}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          hideFooter
          disableColumnSorting
          disableColumnSelector
          disableMultipleRowSelection
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          sx={{
            mt: 2,
            height: 'fit-content',
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f0f0f0',
              whiteSpace: 'normal',
              height: 'auto',
              minHeight: '50px !important',
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
                height: 'auto',
              },
            },
            '& .MuiDataGrid-cell': {
              fontSize: '11px',
              whiteSpace: 'normal !important',
              lineHeight: 'normal',
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              minHeight: '50px !important',
              height: 'auto !important',
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
              height: 'auto !important',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

const columns: GridColDef[] = [
  {
    field: 'strategy',
    headerName: 'STRATEGY',
    flex: 1,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
  {
    field: 'description',
    headerName: 'DESCRIPTION',
    flex: 2,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
  {
    field: 'implementation',
    headerName: 'IMPLEMEN-\nTATION',
    flex: 0.6,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'pre-line',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
          textAlign: 'left',
          padding: '4px 0',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
  {
    field: 'main_purpose',
    headerName: 'MAIN PURPOSE',
    flex: 1.8,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
];
