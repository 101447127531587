import React from 'react';
import { Grid } from '@mui/material';
import { DataGrid, gridClasses, GridEventListener } from '@mui/x-data-grid';

interface CustomTableProps {
  rows: any[];
  columns: any[];
  initialState?: object;
  handleRowClick?: (id: string) => void;
}

const CustomModelsTable: React.FC<CustomTableProps> = ({
  rows,
  columns,
  initialState = {},
  handleRowClick,
}) => {
  const handleDataGridRowClick: GridEventListener<'rowClick'> = params => {
    handleRowClick?.(params.id as string);
  };

  return (
    <Grid container>
      <DataGrid
        rows={rows}
        columns={columns}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: 'none',
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 8,
            },
          },
          ...initialState,
        }}
        getRowId={row => row.id}
        onRowClick={handleDataGridRowClick}
        disableColumnMenu
        pageSizeOptions={[8]}
        disableColumnSelector
        disableMultipleRowSelection
      />
    </Grid>
  );
};
export default CustomModelsTable;
