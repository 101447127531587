import { Box, Grid } from '@mui/material';
import React, { FC } from 'react';
import { styled } from 'styled-components';
import { useGetFirmSettingsQuery } from './hooks/useGetFirmSettingsQuery';
import FirmHeader from './components/FirmHeader';
import FirmLogo from './components/FirmLogo';
import FirmFees from './components/FirmFees';
import FirmDisclosures from './components/FirmDisclosures';
import EmailDisclosure from './components/EmailDisclosure';

const FirmProfile: FC = () => {
  const { firmSettings } = useGetFirmSettingsQuery();

  return (
    <Wrapper>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <FirmHeader />
      </Grid>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <FirmLogo logoSrc={firmSettings?.logoSrc} />
      </Grid>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <FirmFees fee={firmSettings?.advisoryFee} />
      </Grid>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <FirmDisclosures
          disclosure={firmSettings?.firmDisclosure}
          dutiesAndResponsibilities={
            firmSettings?.firmDutiesAndResponsibilities
          }
          advisorFeeFullDisclosure={firmSettings?.firmAdvisorFeeFullDisclosure}
        />
      </Grid>
      <Grid item xs={12}>
        <EmailDisclosure emailDisclosure={firmSettings?.emailDisclosure} />
      </Grid>
    </Wrapper>
  );
};

export default FirmProfile;

const Wrapper = styled(Box)`
  background: #ffffff;
  padding: 16px;
`;
