import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  SxProps,
  Theme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ChartPopupProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

interface ChartProps {
  height?: number;
  [key: string]: any;
}

const DIALOG_STYLES: SxProps<Theme> = {
  width: '95vw',
  maxWidth: 'none',
  margin: '16px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  maxHeight: '90vh',
};

const DIALOG_TITLE_STYLES: SxProps<Theme> = {
  m: 0,
  p: '12px 16px',
  backgroundColor: '#fff',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const CLOSE_BUTTON_STYLES: SxProps<Theme> = {
  color: 'rgba(0, 0, 0, 0.54)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};

const DIALOG_CONTENT_STYLES: SxProps<Theme> = {
  p: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: 'calc(90vh - 48px)',
};

const CONTENT_BOX_STYLES: SxProps<Theme> = {
  p: '16px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

const ChartPopup: React.FC<ChartPopupProps> = ({
  open,
  onClose,
  title,
  children,
}) => {
  const childWithProps = React.Children.map(children, child => {
    if (React.isValidElement<ChartProps>(child)) {
      return React.cloneElement(child, { height: 600 });
    }
    return child;
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      PaperProps={{ sx: DIALOG_STYLES }}
    >
      <DialogTitle sx={DIALOG_TITLE_STYLES}>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={onClose} size="small" sx={CLOSE_BUTTON_STYLES}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={DIALOG_CONTENT_STYLES}>
        <Box sx={CONTENT_BOX_STYLES}>{childWithProps}</Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChartPopup;
