import React from 'react';
import PrompQuestions from '@components/PrompQuestions';
import axios from 'axios';

interface MeetingPrepDownloadProps {
  filename: string;
  url: string;
}

const MeetingPrepDownload: React.FC<MeetingPrepDownloadProps> = ({
  filename,
  url,
}) => {
  const handleDownload = async () => {
    try {
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the blob URL
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
      // Fallback to direct link if download fails
      window.open(url, '_blank');
    }
  };

  return (
    <PrompQuestions
      showDividers
      itemPy="10px"
      questions={[`Download the meeting prep document`]}
      handleQuestionClick={handleDownload}
    />
  );
};

export default MeetingPrepDownload;
