import React from 'react';

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link } from 'react-router-dom';

interface ListItemsProps {
  icon: any;
  text: string;
  path: string;
  handleAction: () => void;
}
const ListItems: React.FC<ListItemsProps> = ({
  icon,
  text,
  path,
  handleAction,
}) => {
  return (
    <ListItem onClick={handleAction} key={text} disablePadding>
      <ListItemButton component={Link} to={path}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

export default ListItems;
