import React, { createContext, ReactNode, useContext } from 'react';
import { type Slide } from '../_data';

// Re-exportar los hooks y contextos para mantener compatibilidad
import {
  SlidesContentProvider,
  useSlidesContent,
} from './SlidesContentContext';

import {
  SlidesUIProvider,
  SlidesUIContext,
  useSlidesUI,
} from './SlidesUIContext';

// Tipo combinado para mantener compatibilidad hacia atrás
interface SlidesContextType {
  slides: Slide<any>[];
  currentSlide: Slide<any>;
  setCurrentSlide: (slide: Slide<any>) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  updateSlideContent: (
    slide: Slide<any>,
    content: Slide<any>['content'],
  ) => void;
  setCurrentSlideByIndex: (index: number) => void;
  dirty: boolean;
  handleSave: () => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  isDownloading: boolean;
  handleDownload: (filename: string) => void;
}

// Mantener el contexto original para compatibilidad
export const SlidesContext = createContext<SlidesContextType | undefined>(
  undefined,
);

// Proveedor combinado que utiliza los dos contextos separados
export const SlidesProvider = ({
  children,
  action,
  handleSave,
}: {
  children: ReactNode;
  action: any;
  handleSave: (slides: any) => void;
}): React.ReactElement => {
  // Este proveedor simplemente combina los dos contextos
  return (
    <SlidesUIProvider>
      <SlidesUIConsumer>
        {uiContext => (
          <SlidesContentProvider
            action={action}
            handleSave={handleSave}
            setDirty={uiContext.setDirty}
          >
            <CombinedContextBridge uiContext={uiContext}>
              {children}
            </CombinedContextBridge>
          </SlidesContentProvider>
        )}
      </SlidesUIConsumer>
    </SlidesUIProvider>
  );
};

// Componente de puente para unir ambos contextos
const CombinedContextBridge = ({
  children,
  uiContext,
}: {
  children: ReactNode;
  uiContext: any;
}) => {
  const contentContext = useSlidesContent();

  // Combinamos ambos contextos en uno solo para mantener compatibilidad
  const combinedValue: SlidesContextType = {
    ...contentContext,
    ...uiContext,
  };

  return (
    <SlidesContext.Provider value={combinedValue}>
      {children}
    </SlidesContext.Provider>
  );
};

// Consumidor de SlidesUIContext para obtener su valor
const SlidesUIConsumer = ({
  children,
}: {
  children: (context: any) => React.ReactNode;
}) => {
  const uiContext = useContext(SlidesUIContext);
  if (!uiContext) {
    throw new Error('SlidesUIContext not found');
  }
  return children(uiContext);
};

// Re-exportamos los hooks para usar los contextos
export { useSlidesContent, useSlidesUI };

// Hook combinado para compatibilidad
export const useSlides = (): SlidesContextType => {
  const context = useContext(SlidesContext);
  if (!context) {
    throw new Error('useSlides must be used within a SlidesProvider');
  }
  return context;
};
