import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';
import { Case } from './useGetCases';

type UseGetCaseByIdArguments = {
  id: string;
};

type UseGetCaseByIdResult = {
  caseData: Case | undefined;
  isCaseLoading: boolean;
  error: any;
};

type UseGetCaseByIdResponse = {
  data: Case;
};

export const useGetCaseById = ({
  id,
}: UseGetCaseByIdArguments): UseGetCaseByIdResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading, error } = useQuery<UseGetCaseByIdResponse>(
    ['cases', id],
    fetchQuery({ path: `v2/ocios/cases/${id}` }),
    { cacheTime: 30000, enabled: !!id },
  );

  return {
    caseData: data?.data ? parseCase(data?.data) : undefined,
    isCaseLoading: isLoading,
    error,
  };
};

const parseCase = (caseData: Case) => ({
  ...caseData,
  milestones: caseData.milestones?.map(milestone => ({
    ...milestone,
    // order actions by createdAt
    actions: milestone.actions.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    ),
  })),
});
