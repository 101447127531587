import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledApplyButton, StyledButton } from '@pages/CIOViewpoints/styles';

import { actions as globalActions } from 'app/features/Global/slice';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { addFirmAdmin } from './mutation';
import {
  ModalContainer,
  StyledAdminText,
  StyledHorizontalRule,
  StyledLabel,
} from './styles';

interface AddFirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  firmDetails: any;
}

function FirmAdminModal({
  isOpen,
  onClose,
  firmDetails,
}: AddFirmModalProps): React.JSX.Element {
  const [firmFirstName, setFirmFirstName] = useState<string>('');
  const [firmLastName, setFirmLastName] = useState<string>('');
  const [firmEmail, setFirmEmail] = useState<string>('');
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const addFirmMutation = useMutation(addFirmAdmin);

  const handleAddFirmSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const payload = {
      firstName: firmFirstName,
      lastName: firmLastName,
      advioryFirmId: firmDetails?.id,
      email: firmEmail,
    };

    addFirmMutation.mutate(payload, {
      onSuccess: (response: any) => {
        if (response && response.data) {
          queryClient.invalidateQueries('firm-details');
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: response?.message,
            }),
          );
          setFirmFirstName('');
          setFirmLastName('');
          setFirmEmail('');
          onClose();
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Unexpected response from the server.',
            }),
          );
        }
      },
      onError: (error: any) => {
        console.log({ error });
        if (error?.response?.data && error?.response?.data?.message) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: error?.response?.data?.message,
            }),
          );
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'An error occurred while processing the query.',
            }),
          );
        }
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer container rowGap={2.5}>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h6" component="div">
            Add Firm admin
          </Typography>
          <Button
            onClick={onClose}
            style={{
              minWidth: 'unset',
              padding: '5px',
              marginLeft: 'auto',
            }}
          >
            <CloseIcon />
          </Button>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={5}>
            <StyledAdminText>Administrator Details</StyledAdminText>
          </Grid>
          <Grid item xs={7} justifyContent={'center'} alignSelf={'center'}>
            <StyledHorizontalRule />
          </Grid>
        </Grid>
        <Grid item container xs={12} columnGap={1}>
          <Grid item container xs={5.8}>
            <StyledLabel htmlFor="firstName">First Name</StyledLabel>
            <TextField
              id="firstName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter First Name"
              style={{ paddingTop: '5px' }}
              value={firmFirstName}
              onChange={e => setFirmFirstName(e.target.value)}
            />
          </Grid>
          <Grid item container xs={5.8}>
            <StyledLabel htmlFor="lastName">Last Name</StyledLabel>
            <TextField
              id="lastName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter Last Name"
              style={{ paddingTop: '5px' }}
              value={firmLastName}
              onChange={e => setFirmLastName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <StyledLabel htmlFor="email">Email</StyledLabel>
          <TextField
            id="email"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Email"
            style={{ paddingTop: '5px' }}
            value={firmEmail}
            onChange={e => setFirmEmail(e.target.value)}
          />
        </Grid>
        <Divider
          style={{
            border: '0.5px solid #e4e7ec',
            width: '100%',
          }}
        />
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <StyledButton
            variant="outlined"
            onClick={onClose}
            style={{ marginTop: '10px' }}
          >
            Cancel
          </StyledButton>
          <StyledApplyButton
            variant="outlined"
            onClick={handleAddFirmSubmit}
            style={{ marginTop: '10px' }}
            disabled={!firmFirstName || !firmLastName || !firmEmail}
          >
            Confirm
          </StyledApplyButton>
        </Grid>
      </ModalContainer>
    </Modal>
  );
}

export default FirmAdminModal;
