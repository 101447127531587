// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slide__6rGpq {
  background-color: #fff;
}

.styles_slideContent__p\\+Owx {
  box-sizing: border-box;
  overflow: hidden;
}

.styles_slideContent__p\\+Owx :is(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.styles_slideFooter__U4O0j {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  border-top: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 0 24px;
  background-color: #fff;
}

.styles_slideFooterLeft__0xYIX {
  display: flex;
  align-items: center;
}

.styles_slideFooterRight__kum1p {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.styles_slideFooterLogo__pSeMZ {
  height: 20px;
}

.styles_slideFooterLogo__pSeMZ img {
  width: auto;
  height: 100%;
}

.styles_slideFooterPageNumber__MJ0bU {
  font-size: 10px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/features/slides/components/slide-layout/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,sBAAsB;EACtB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".slide {\n  background-color: #fff;\n}\n\n.slideContent {\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n.slideContent :is(h1, h2, h3, h4, h5, h6) {\n  margin: 0;\n}\n\n.slideFooter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  height: 48px;\n  border-top: 1px solid #e0e0e0;\n  box-sizing: border-box;\n  padding: 0 24px;\n  background-color: #fff;\n}\n\n.slideFooterLeft {\n  display: flex;\n  align-items: center;\n}\n\n.slideFooterRight {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 16px;\n}\n\n.slideFooterLogo {\n  height: 20px;\n}\n\n.slideFooterLogo img {\n  width: auto;\n  height: 100%;\n}\n\n.slideFooterPageNumber {\n  font-size: 10px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": `styles_slide__6rGpq`,
	"slideContent": `styles_slideContent__p+Owx`,
	"slideFooter": `styles_slideFooter__U4O0j`,
	"slideFooterLeft": `styles_slideFooterLeft__0xYIX`,
	"slideFooterRight": `styles_slideFooterRight__kum1p`,
	"slideFooterLogo": `styles_slideFooterLogo__pSeMZ`,
	"slideFooterPageNumber": `styles_slideFooterPageNumber__MJ0bU`
};
export default ___CSS_LOADER_EXPORT___;
