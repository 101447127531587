import React, { useState } from 'react';
import { useGetCustomModelsQuery } from '../Custom/hooks/useGetCustomModelsQuery';
import CustomModelUpload from '../components/CustomModelUpload';
import PrompQuestions from '@components/PrompQuestions';

const ChatCustomModelDialog: React.FC = () => {
  // hook for fetching custom models
  const { refetch } = useGetCustomModelsQuery();

  //state for model dialog
  const [openModelDialog, setOpenModelDialog] = useState(false);

  // handles the model dialog
  const handleCloseModel = () => setOpenModelDialog(false);
  const handleClickOpenModel = () => setOpenModelDialog(true);
  const handleRefetch = () => refetch();

  return (
    <>
      <PrompQuestions
        showDividers
        itemPy="10px"
        questions={['Upload Custom Model']}
        handleQuestionClick={handleClickOpenModel}
      />
      <CustomModelUpload
        handleCloseModel={handleCloseModel}
        openModelDialog={openModelDialog}
        handleSuccesRefetch={handleRefetch}
      />
    </>
  );
};

export default ChatCustomModelDialog;
