import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Header from './components/Header';
import ModelName from './components/ModelName';
import ProgressInvested from './components/ProgressInvested';
import TickerForm from './components/TickerForm';
import TickerTable from './components/TickerTable';
import { usePutCustomModelsMutation } from '../../Custom/hooks/usePutCustomModelsMutation';
import { useGetModelById } from '../../hooks/useGetModelById';
import { Model } from 'pages/Models/types';
import ErrorMessage from 'components/ErrorMessage';
import CircularProgressBar from 'components/CircularProgressBar';

interface CustomModelEditProps {
  modelId: string;
  handleSaveSuccess: () => void;
}

const CustomModelEdit: React.FC<CustomModelEditProps> = ({
  modelId,
  handleSaveSuccess,
}) => {
  const { putCustomModelMutate, putCustomModelisSuccess } =
    usePutCustomModelsMutation();
  const [name, setName] = useState('');
  const [holdings, setHoldings] = useState<Model['holdings']>([]);
  const { model, isErrorModel, isLoadingModel } = useGetModelById({ modelId });

  useEffect(() => {
    setName(model?.name || '');
    setHoldings(model?.holdings || []);
  }, [model?.name]);

  // helpers

  const getTotalHoldingsWeight = () => {
    let total = holdings.reduce(
      // user cannot add decimals in a holding
      // multiply and divide by 100 to avoid floating point error
      (total, holding) => total + holding.weight,
      0,
    );
    total = Number(total.toFixed(4));
    return total;
  };

  const isValidFormData = () => name !== '' && getTotalHoldingsWeight() === 1;

  // events

  const handleSave = () => {
    if (isValidFormData()) {
      putCustomModelMutate({
        id: model!.id,
        name: name,
        holdings: holdings.map(holding => ({
          name: holding.instrument.name,
          ticker: holding.instrument.ticker,
          weight: holding.weight,
        })),
      });
    }
  };

  useEffect(() => {
    if (putCustomModelisSuccess) {
      handleSaveSuccess();
    }
  }, [putCustomModelisSuccess, handleSaveSuccess]);

  const handleAddHolding = (ticker, name, weight) => {
    const existsHolding = !!holdings.find(
      holding => holding.instrument.ticker === ticker,
    );
    if (!existsHolding) {
      const newHoldings = [...holdings];
      newHoldings.push({
        id: `${Date.now()}`,
        weight: weight,
        instrument: {
          ticker: ticker,
          name: name,
        },
      });
      setHoldings(newHoldings);
    }
  };

  const handleEditHoldingWeight = (holdingId, weight) => {
    const holding = holdings.find(holding => holding.id === holdingId);
    if (holding) {
      holding.weight = weight;
      setHoldings([...holdings]);
    }
  };

  const handleDeleteHolding = holdingId => {
    const newHoldings = holdings.filter(
      holding => holding.instrument.id !== holdingId,
    );
    setHoldings(newHoldings);
  };

  if (isLoadingModel) {
    return (
      <Box pt={2} m="auto">
        <CircularProgressBar />
      </Box>
    );
  }

  if (isErrorModel) {
    return (
      <ErrorMessage message="The model you are looking for does not exist" />
    );
  }

  return (
    <Box sx={{ background: '#fff', borderRadius: '4px', width: '100%' }}>
      <Header handleClickBack={handleSaveSuccess} name={name} />
      <Grid container gap={2} padding={{ xs: '10px', sm: '20px' }}>
        <Grid item xs={12}>
          <ModelName
            name={name}
            disableSaveButton={!isValidFormData()}
            handleChange={setName}
            handleSave={handleSave}
          />
        </Grid>
        <Grid item xs>
          <Stack gap={2}>
            <ProgressInvested
              value={holdings.length > 0 ? getTotalHoldingsWeight() : 1}
            />
            <TickerForm handleAddSecurity={handleAddHolding} />
          </Stack>
        </Grid>
        <Grid item xs>
          <TickerTable
            holdings={holdings}
            handleDeleteHolding={handleDeleteHolding}
            handleEditHoldingWeight={handleEditHoldingWeight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomModelEdit;
