import instance from 'utils/instances';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const LoginMutate = async (payload: any) => {
  const createResponse = await instance.post('/user/login', payload, {
    params: {
      queryWithoutToken: true,
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const recordLoginMutate = async () => {
  const createResponse = await instance.post(
    '/user/recordLogin',
    {},
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        'content-type': undefined,
      },
    },
  );
  return createResponse?.data;
};

export const getSessionUserCount = async (): Promise<{
  status: number;
  data: { count: number };
}> => {
  try {
    const response = await instance.get(`/v2/users/session/count`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      },
    });
    return response.data;
  } catch (error: any) {
    console.error('Error fetching get data:', error.message);
    throw new Error(error.message);
  }
};
