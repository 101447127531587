import { Grid, Typography } from '@mui/material';
import { SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import { ModelSetCharacteristicTable } from '../modelset-characteristic-table';
import { ModelSetCharacteristics } from 'features/premium-report/api/types';
import {
  PORTFOLIO1,
  PORTFOLIO3,
  PORTFOLIO2,
} from 'pages/Cases/hooks/usePostCalculatePortfolioCharacteristics';

export function PortfolioCharacteristics({
  portfolioCharacteristics,
  type,
}: {
  portfolioCharacteristics: ModelSetCharacteristics[];
  type: string;
}): React.ReactElement {
  return (
    <Grid container direction="column" spacing={2} className={styles.container}>
      {type === '1' && (
        <>
          <Grid item>
            <SlideTitle>Portfolio Characteristics</SlideTitle>
            <Typography component="p" className={styles.paragraph}>
              Based on portfolio allocations as of 3/31/2024. Exposures, fees,
              and yield may change over time, dependent on investment decisions
              made by strategists and investment managers. Platform fees and
              expense ratios are stated in basis points and as of 3/31/2024.
              Equity exposure, yield, high yield, and emerging markets bond (EM)
              exposures are as of 3/31/2024.
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              *Based on stated maximum fees, may be lower based on any advisor
              exceptions.{' '}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item>
        <ModelSetCharacteristicTable
          data={portfolioCharacteristics}
          template={
            type === '1' ? PORTFOLIO1 : type === '2' ? PORTFOLIO2 : PORTFOLIO3
          }
          fontSize={11}
        />
      </Grid>
    </Grid>
  );
}
