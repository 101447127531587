import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

interface HeaderProps {
  name: string;
  handleBackButtonClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ name, handleBackButtonClick }) => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          color: 'black',
        }}
        paddingInline={{ xs: '10px', sm: '20px' }}
      >
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            textDecoration: 'none',
            textTransform: 'none',
            color: 'black',
          }}
          onClick={handleBackButtonClick}
        >
          <ChevronLeftIcon />
          <Box>Models</Box>
        </Button>
        <Box
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {name}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default Header;
