import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import styled from 'styled-components';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import {
  Case,
  CaseMilestone,
  CaseMilestoneAction,
} from 'pages/Cases/hooks/useGetCases';
import { actions as chatActions } from '@features/Chat/slice';
import PrompQuestions from '@components/PrompQuestions';
import { useQueryClient } from 'react-query';
import { useGetDownloadPerformanceReportPDF } from 'pages/Cases/hooks/useGetDownloadPerformanceReportPDF';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

type AccordionExpandIconProps = {
  caseData: Case;
  milestone: CaseMilestone;
  index: number;
};

const AccordionExpandIcon: React.FC<AccordionExpandIconProps> = ({
  caseData,
  milestone,
  index,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = React.useState(false);

  // Initial determination of loading state
  React.useEffect(() => {
    if (!caseData.milestones) return;

    // Only consider portfolio construction milestones with no actions
    if (
      milestone.type !== 'portfolio_construction' ||
      milestone.actions.length > 0
    ) {
      setLoading(false);
      return;
    }

    // Check if there's a completed consult form milestone
    const hasCompletedConsultForm = caseData.milestones.some(
      m => m.type === 'consult_form' && m.actions.length > 0,
    );

    // Set loading only if consult form is completed and this portfolio construction has no actions
    setLoading(hasCompletedConsultForm);
  }, [caseData, milestone]);

  // Set up polling when in loading state
  React.useEffect(() => {
    if (!loading) return;

    let isMounted = true;
    const startTime = Date.now();
    const MAX_LOADING_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds

    const checkForUpdates = async () => {
      try {
        // Check if we've exceeded the maximum loading time
        if (Date.now() - startTime > MAX_LOADING_TIME) {
          console.log('Loading timeout reached (5 minutes). Stopping loader.');
          if (isMounted) {
            setLoading(false);
          }
          return;
        }

        // Force refetch from the server
        await queryClient.refetchQueries(['cases', caseData.id], {
          active: true,
          exact: true,
        });

        // Get the fresh data
        const freshData = queryClient.getQueryData([
          'cases',
          caseData.id,
        ]) as any;

        if (freshData?.data?.milestones) {
          // Find the current milestone in the fresh data
          const freshMilestone = freshData.data.milestones.find(
            (m: CaseMilestone) => m.id === milestone.id,
          );

          // If the milestone now has actions, stop loading
          if (
            freshMilestone &&
            freshMilestone.actions.length > 0 &&
            isMounted
          ) {
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Error checking for milestone updates:', error);
      }
    };

    // Initial check
    checkForUpdates();

    // Set up interval
    const intervalId = setInterval(checkForUpdates, 2000);

    // Clean up
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [loading, caseData.id, milestone.id, queryClient]);

  return (
    <Accordion
      disableGutters
      sx={{
        backgroundColor: '#fff',
        boxShadow: 'none',
        border: 'none',
        '&.MuiAccordion-root::before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          height: '60px',
          borderBottom: 'none',
        }}
      >
        <Box display="flex" alignItems="center" gap="8px" width="100%">
          {/*
           * We don't know when a milestone is complete and when it isn't.
           * When we know, we have to uncomment the next component.
           */}
          {/* <CompleteIconStatus>
            <CheckIcon sx={{ width: '18px', height: '18px' }} />
          </CompleteIconStatus> */}
          <InProgressStatus>{index + 1}</InProgressStatus>
          <MilestoneName milestone={milestone} />
          <MilestoneDate milestone={milestone} />
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <AccordionDetailsDivider milestone={milestone} margin="0 0 8px 36px" />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            {milestone.actions.map((action, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                m="4px 0 4px 36px"
              >
                <ActionName action={action} />
                <ActionDate action={action} />
                <ConsultFormViewButton milestone={milestone} />
                <PortfolioConstructionViewButton
                  milestone={milestone}
                  action={action}
                />
                <PerformanceReportViewButton
                  milestone={milestone}
                  action={action}
                />
                <PremiumReportViewButton
                  milestone={milestone}
                  action={action}
                />
                <PooViewButton milestone={milestone} action={action} />
              </Box>
            ))}
            <ConsultFormPromptButton
              caseData={caseData}
              milestone={milestone}
            />
            <PooPromptButton caseData={caseData} milestone={milestone} />
          </>
        )}
        <AccordionDetailsDivider milestone={milestone} margin="8px 0 0 36px" />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionExpandIcon;

const MilestoneName = ({ milestone }: { milestone: CaseMilestone }) => {
  return (
    <Box flex={1}>
      <Typography sx={{ fontSize: '16px', color: '#282829' }}>
        {milestone.displayName}
      </Typography>
    </Box>
  );
};

const MilestoneDate = ({ milestone }: { milestone: CaseMilestone }) => {
  const getMilestoneCreatedAt = (milestone: CaseMilestone) => {
    if (milestone.actions.length === 0) {
      return '';
    } else {
      // sort actions by createdAt
      const sortedActions = milestone.actions.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
      return `Created at ${moment(sortedActions[0].createdAt).format('MM/DD/YYYY')}`;
    }
  };
  return (
    <Typography
      sx={{
        paddingRight: '8px',
        fontSize: '12px',
        fontWeight: '400',
        color: '#888',
      }}
    >
      {getMilestoneCreatedAt(milestone)}
    </Typography>
  );
};

const ActionName = ({ action }: { action: CaseMilestoneAction }) => {
  return (
    <Typography
      sx={{
        paddingRight: '8px',
        fontSize: '14px',
        color: '#535256',
      }}
    >
      {action.versionName}
    </Typography>
  );
};

const ActionDate = ({ action }: { action: CaseMilestoneAction }) => {
  return (
    <Typography sx={{ fontSize: '12px', color: '#aaa' }}>
      {moment(action.createdAt).format('MM/DD/YYYY')}
    </Typography>
  );
};

const ConsultFormViewButton = ({ milestone }: { milestone: CaseMilestone }) => {
  return milestone.type === 'consult_form' ? <ViewButton link="" /> : null;
};

const PortfolioConstructionViewButton = ({
  milestone,
  action,
}: {
  milestone: CaseMilestone;
  action: CaseMilestoneAction;
}) => {
  return milestone.type === 'portfolio_construction' ? (
    <Box display="flex" gap={1}>
      <ViewButton
        link={`/cases/${action.caseId}/portfolio-constructions/${action.id}/summary`}
      />
    </Box>
  ) : null;
};

const PerformanceReportViewButton = ({
  milestone,
  action,
}: {
  milestone: CaseMilestone;
  action: CaseMilestoneAction;
}) => {
  return milestone.type === 'performance_report' ? (
    <Box display="flex" gap={1}>
      <DownloadButton caseId={action.caseId} actionId={action.id} />
    </Box>
  ) : null;
};

const PooViewButton = ({
  milestone,
  action,
}: {
  milestone: CaseMilestone;
  action: CaseMilestoneAction;
}) => {
  return milestone.type === 'presentation_of_options' ? (
    <ViewButton
      link={`/conversations/slides-editor/case/${action.caseId}/action/${action.id}`}
    />
  ) : null;
};

const PremiumReportViewButton = ({
  milestone,
  action,
}: {
  milestone: CaseMilestone;
  action: CaseMilestoneAction;
}) => {
  return milestone.type === 'premium_report' ? (
    <ViewButton
      link={`/cases/${action.caseId}/portfolio-constructions/${action.id}/premium-report`}
    />
  ) : null;
};

const ViewButton = ({ link = '' }: { link?: string }) => {
  return link ? (
    <Box flex={1} textAlign="right">
      <Link
        to={link}
        style={{ display: 'inline-block', textDecoration: 'none' }}
      >
        <Typography sx={{ fontSize: '12px', color: '#0084E3' }}>
          View
        </Typography>
      </Link>
    </Box>
  ) : (
    <Box flex={1} textAlign="right">
      <Typography sx={{ fontSize: '12px', color: '#bbb' }}>View</Typography>
    </Box>
  );
};

const DownloadButton = ({
  caseId,
  actionId,
}: {
  caseId: string;
  actionId: string;
}) => {
  const { url, isLoading } = useGetDownloadPerformanceReportPDF({
    caseId,
    actions: actionId,
  });

  return isLoading ? (
    <Box flex={1} textAlign="right">
      <CircularProgress size={24} />
    </Box>
  ) : (
    <Box flex={1} textAlign="right">
      <Link
        to={url || ''}
        style={{ display: 'inline-block', textDecoration: 'none' }}
      >
        <Typography sx={{ fontSize: '12px', color: '#0084E3' }}>
          Download
        </Typography>
      </Link>
    </Box>
  );
};

const AccordionDetailsDivider = ({
  milestone,
  margin,
}: {
  milestone: CaseMilestone;
  margin: string;
}) => {
  return milestone.actions.length > 0 ? <Divider sx={{ margin }} /> : null;
};

const ConsultFormPromptButton = ({
  caseData,
  milestone,
}: {
  caseData: Case;
  milestone: CaseMilestone;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleQuestionClick = () => {
    dispatch(
      chatActions.setSearchQueryData({
        query: `Create a new consult form for ${caseData.name}`,
        data: { caseId: caseData.id },
      }),
    );
    navigate('/conversations/start');
  };

  return milestone.actions.length === 0 && milestone.type === 'consult_form' ? (
    <Box sx={{ marginLeft: '36px' }}>
      <PrompQuestions
        showDividers
        itemPy="12px"
        questions={['Create New Form']}
        handleQuestionClick={handleQuestionClick}
      />
    </Box>
  ) : null;
};

const PooPromptButton = ({
  caseData,
  milestone,
}: {
  caseData: Case;
  milestone: CaseMilestone;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleQuestionClick = (question: string) => {
    dispatch(
      chatActions.setSearchQueryData({
        query: `${question} for ${caseData!.name}`,
        data: { caseId: caseData!.id },
      }),
    );
    navigate('/conversations/start');
  };

  return milestone.type === 'presentation_of_options' ? (
    <Box sx={{ marginLeft: '36px' }}>
      <PrompQuestions
        showDividers
        itemPy="12px"
        questions={['Generate presentation of options']}
        handleQuestionClick={() => {
          handleQuestionClick('Generate presentation of options');
        }}
      />
    </Box>
  ) : milestone.type === 'premium_report' ? (
    <Box sx={{ marginLeft: '36px' }}>
      <PrompQuestions
        showDividers
        itemPy="12px"
        questions={['Generate premium report']}
        handleQuestionClick={() => {
          handleQuestionClick('Generate premium report');
        }}
      />
    </Box>
  ) : null;
};

// const CompleteIconStatus = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 28px;
//   height: 28px;
//   background-color: #1bc29a;
//   border-radius: 50%;
//   color: #fff;
// `;

const InProgressStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #f5f5f5;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  color: #282829;
`;

// Loading animation component
const LoadingState = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px',
        marginLeft: '36px',
      }}
    >
      <CircularProgress
        size={24}
        sx={{ marginBottom: '8px', color: '#0084E3' }}
      />
      <Typography variant="body2" color="text.secondary">
        Optimizing the model sets... This may take a few moments
      </Typography>
    </Box>
  );
};
