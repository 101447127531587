import React from 'react';

import { SlideTheme } from '../types';
import { SlideContext } from './context';

export type SlideContextProviderProps = {
  readonly children: React.ReactNode;
  readonly theme: SlideTheme;
  readonly width: number;
  readonly height: number;
  readonly type: 'premium_report' | 'performance_report';
};

export function SlideContextProvider({
  children,
  theme,
  width,
  height,
  type,
}: SlideContextProviderProps): React.ReactNode {
  const [index, setIndex] = React.useState(0);
  const [isPanelOpen, setIsPanelOpen] = React.useState(true);
  const [zoom, setZoom] = React.useState(100);

  const value = React.useMemo(
    () => ({
      index,
      zoom,
      isPanelOpen,
      theme,
      width,
      height,
      type,
      setIndex,
      setZoom,
      setIsPanelOpen,
    }),
    [index, zoom, isPanelOpen, theme, width, height, type],
  );
  return (
    <SlideContext.Provider value={value}>{children}</SlideContext.Provider>
  );
}
