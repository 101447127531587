import React from 'react';
import { Grid } from '@mui/material';
import {
  DataGrid,
  gridClasses,
  GridEventListener,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';

interface CustomTableProps {
  rows: any[];
  columns: any[];
  handleRowClick?: (id: string) => void;
  sortModel?: GridSortModel;
  onSortModelChange?: (newSortModel: GridSortModel) => void;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: (paginationModel: GridPaginationModel) => void;
  getRowId: (row: any) => string;
  paginationMeta: {
    rowCount: number;
    totalPages: number;
    currentPage: number;
    hasNextPage: boolean;
  };
}

const CustomModelsTable: React.FC<CustomTableProps> = ({
  rows,
  columns,
  handleRowClick,
  sortModel,
  onSortModelChange,
  paginationModel,
  onPaginationModelChange,
  paginationMeta,
  getRowId,
}) => {
  const handleDataGridRowClick: GridEventListener<'rowClick'> = params => {
    handleRowClick?.(params.id as string);
  };

  const rowCountRef = React.useRef(paginationMeta?.rowCount || 0);

  const rowCount = React.useMemo(() => {
    if (
      paginationMeta?.rowCount !== undefined &&
      paginationMeta?.rowCount !== 0
    ) {
      rowCountRef.current = paginationMeta?.rowCount;
    }
    return rowCountRef.current;
  }, [paginationMeta?.rowCount]);

  return (
    <Grid container>
      <DataGrid
        rows={rows}
        columns={columns}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        rowCount={rowCount}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: 'none',
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 8,
            },
          },
        }}
        getRowId={getRowId}
        onRowClick={handleDataGridRowClick}
        disableColumnMenu
        pageSizeOptions={[8]}
        disableColumnSelector
        disableMultipleRowSelection
      />
    </Grid>
  );
};
export default CustomModelsTable;
