import PrompQuestions from '@components/PrompQuestions';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { usePostConsultForm } from 'pages/Conversations/hooks/usePostConsultForm';
import React, { useState } from 'react';
import UploadExcel, { ExcelTable } from '../../../../components/UploadExcel';
import { mapExcelToConsultForm } from './consultFormMap';
import ModelRequestedTable, {
  ModelRequested,
  areValidModelsRequested,
} from './ModelsRequestedTable';
import Loading from '@images/Loading.gif';

type UploadConsultFormProps = {
  caseId: string;
  isLastItem: boolean;
};

type State = 'idle' | 'mutating' | 'success' | 'error';

const UploadConsultForm = ({
  caseId,
  isLastItem,
}: UploadConsultFormProps): React.ReactNode => {
  const { mutateAsync } = usePostConsultForm();
  const [excelMessageError, setExcelMessageError] = useState('');
  const [excel, setExcel] = useState<any | null>(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<State>('idle');

  const isIdle = state === 'idle';
  const isMutating = state === 'mutating';
  const isSuccess = state === 'success';
  const isError = state === 'error';

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleExcelUpload = React.useCallback(
    (data: ExcelTable) => {
      try {
        const mappedExcel = mapExcelToConsultForm(data);
        setExcel(mappedExcel);
        setExcelMessageError('');
        handleClose();
      } catch (error: any) {
        setExcelMessageError(error.message);
      }
    },
    [handleClose],
  );

  const handleModelsRequestedChange = React.useCallback(
    (modelsRequested: ModelRequested[]) => {
      setExcel(prevExcel => ({
        ...prevExcel,
        models_requested: {
          ...prevExcel.models_requested,
          details: modelsRequested,
        },
      }));
    },
    [],
  );

  const handleSubmit = React.useCallback(() => {
    setState('mutating');
    mutateAsync({
      caseId,
      excel,
      successToastMessage: '',
    })
      .then(() => {
        setState('success');
      })
      .catch(() => {
        setState('error');
      });
  }, [caseId, excel, mutateAsync]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {isLastItem && isIdle ? (
        <PrompQuestions
          showDividers
          itemPy="10px"
          questions={['Upload consult form']}
          handleQuestionClick={() => {
            handleOpen();
          }}
        />
      ) : null}

      {isMutating ? (
        <Typography sx={{ fontSize: 14, color: '#000' }}>
          Initial consult form is being processed...
        </Typography>
      ) : null}

      {isSuccess ? (
        <>
          <Typography sx={{ fontSize: 14, color: '#000' }}>
            Initial consult form uploaded succesfully. We are processing the
            advisor preferences...
          </Typography>
          <Box
            sx={{
              marginTop: '12px',
              width: '250px',
              height: '20px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <img
              src={Loading}
              alt="Loading gif"
              style={{
                height: '40px',
                marginTop: '-20px',
              }}
            />
          </Box>
        </>
      ) : null}

      {isError ? (
        <Typography sx={{ fontSize: 14, color: '#000' }}>
          Initial consult form uploaded failed
        </Typography>
      ) : null}

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={theme => ({
            width: 500,
            bgcolor: '#fff',
            boxShadow: theme.shadows[5],
            borderRadius: '4px',
          })}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            alignItems={'center'}
            p="8px 8px 8px 16px"
          >
            <Typography
              sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}
            >
              Upload consult form
            </Typography>
            <Button
              style={{
                minWidth: 'unset',
                padding: '5px',
                marginLeft: 'auto',
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          </Grid>

          <Grid
            item
            container
            px={2}
            sx={{ border: '16px solid #f8f8f8', padding: '16px' }}
          >
            <UploadExcel handleExcelUpload={handleExcelUpload} />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-start">
            {excelMessageError && (
              <p>Excel error message: {excelMessageError}</p>
            )}
            <Grid item xs={12} sx={{ padding: '10px' }}>
              <a
                href="/assets/templates/excels/consult-form-example.xlsx"
                download
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Download template
              </a>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {excelMessageError === '' && excel?.models_requested && isIdle && (
        <Box sx={{ width: '100%', marginTop: '16px' }}>
          <ModelRequestedTable
            modelsRequestedDetails={excel?.models_requested?.details}
            handleChange={handleModelsRequestedChange}
          />
          <Button
            sx={{
              marginTop: '1rem',
              marginLeft: '1rem',
              gap: '0.5rem',
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '1rem',
              textTransform: 'none',
              border: '1px solid rgba(0, 0, 0, 0.6)',
              borderRadius: '0.5rem',
            }}
            variant="outlined"
            onClick={handleSubmit}
            disabled={
              !areValidModelsRequested(excel?.models_requested?.details)
            }
          >
            Send
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default UploadConsultForm;
