import React from 'react';
import { PrompQuestionsContainer, PrompQuestionsItem } from './styles';

interface PrompQuestionsProps {
  questions: string[];
  disabled?: boolean; // disable click and show disabled color
  itemColor?: string; // li color
  itemPy?: string; // li padding y
  showDividers?: boolean; // show border top and bottom
  showEllypsis?: boolean; // show ellypsis on text
  handleQuestionClick?: (question: string) => void;
}

const PrompQuestions = ({
  questions,
  disabled = false,
  itemColor,
  itemPy = '2px',
  showDividers = false,
  showEllypsis = false,
  handleQuestionClick,
}: PrompQuestionsProps): React.JSX.Element => {
  const handleClick = (ev: React.MouseEvent, question: string) => {
    ev.stopPropagation();
    if (!disabled) {
      handleQuestionClick?.(question);
    }
  };

  return (
    <PrompQuestionsContainer $showDividers={showDividers}>
      {questions?.map((question, index) => (
        <PrompQuestionsItem
          key={index}
          color={itemColor}
          disabled={disabled}
          py={itemPy}
          $showDividers={showDividers}
          $showEllypsis={showEllypsis}
          onClick={ev => handleClick(ev, question)}
        >
          <span className="promp-question-mark">✦</span>
          <a className="promp-question-text">{question}</a>
        </PrompQuestionsItem>
      ))}
    </PrompQuestionsContainer>
  );
};

export default PrompQuestions;
