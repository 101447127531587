import React from 'react';

import { SlideType } from '../../types';
import { SlideLayout } from '../slide-layout';

export type SlideProps = {
  readonly index: number;
  readonly slide?: SlideType;
  readonly editable?: boolean;
};

export function Slide({
  index,
  slide,
  editable = true,
}: SlideProps): React.ReactElement {
  if (!slide) {
    return <div>No slide</div>;
  }

  return (
    <SlideLayout index={index} footer={slide.footer}>
      {slide.render({
        index,
        state: slide.state,
        setState: slide.setState,
        editable,
      })}
    </SlideLayout>
  );
}
