import React, { useEffect, useState } from 'react';
import {
  Modal,
  Grid,
  IconButton,
  Box,
  Typography,
  TextField,
  Divider,
  InputLabel,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { useGetOcioUsers } from '../../hooks/useGetOcioUsers';
import { usePostCase } from '../../hooks/usePostCase';
import { tierOptions } from '../../AllCases';
import Select from 'components/Select';
import ModalButton from 'components/ModalButton';

const initialCase = {
  tier: '',
  name: '',
  description: '',
  secondaryOwner: '',
  advisorName: '',
  createdAt: new Date().toISOString(),
};

interface AddCaseModalProps {
  open: boolean;
  handleClose: () => void;
}

const AddCaseModal: React.FC<AddCaseModalProps> = ({ open, handleClose }) => {
  const [addCaseState, setAddCaseState] = useState(initialCase);

  useEffect(() => {
    setAddCaseState(initialCase);
  }, [open]);

  // secondary owners

  const loggedUser = localStorage.getItem('tifin-ai-loggedUser');
  const firstOwner = loggedUser ? JSON.parse(loggedUser).ownerId : '';
  const { ocioUsers } = useGetOcioUsers();
  const secondaryOwners = ocioUsers
    .filter(owner => owner.id !== firstOwner)
    .map(owner => ({
      key: owner.id,
      label: `${owner.firstName} ${owner.lastName} (${owner.email})`,
    }));

  // event handlers

  const handleChange = (key: string, value: string) => {
    setAddCaseState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { postCaseMutate } = usePostCase();
  const createCase = () => {
    if (
      !addCaseState.tier ||
      !addCaseState.name ||
      !addCaseState.secondaryOwner ||
      !addCaseState.advisorName
    ) {
      return;
    }
    postCaseMutate({
      ...addCaseState,
      successToastMessage: `Case "${addCaseState.name}" added successfully`,
    });
    handleClose();
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      sx={{
        display: 'flex',
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={theme => ({
          position: 'relative',
          width: 700,
          bgcolor: '#fff',
          boxShadow: theme.shadows[5],
          borderRadius: '4px',
        })}
      >
        <Box sx={{ padding: '16px' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" component="h2">
                Add new case
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: '#F7F7F7', padding: '16px' }}>
          <Box sx={{ padding: '16px', backgroundColor: '#fff' }}>
            <InputLabel htmlFor="tier" required>
              Tier
            </InputLabel>
            <Box sx={{ marginBottom: 2 }}>
              <Select
                id="tier"
                name="tier"
                options={tierOptions}
                value={addCaseState.tier}
                handleChange={handleChange}
              />
            </Box>

            <InputLabel htmlFor="name" required>
              Case Name
            </InputLabel>
            <TextField
              fullWidth
              id="name"
              size="small"
              sx={{ marginBottom: 2 }}
              value={addCaseState.name}
              onChange={({ target }) => handleChange('name', target.value)}
            />

            <InputLabel htmlFor="description">Case Description</InputLabel>
            <TextField
              fullWidth
              id="description"
              size="small"
              sx={{ marginBottom: 2 }}
              value={addCaseState.description}
              onChange={({ target }) =>
                handleChange('description', target.value)
              }
            />

            <InputLabel htmlFor="secondaryOwner" required>
              2nd Owner
            </InputLabel>
            <Box sx={{ marginBottom: 2 }}>
              <Select
                id="secondaryOwner"
                name="secondaryOwner"
                options={secondaryOwners}
                value={addCaseState.secondaryOwner}
                handleChange={handleChange}
              />
            </Box>

            <InputLabel htmlFor="primaryAdvisor" required>
              Primary advisor
            </InputLabel>
            <TextField
              fullWidth
              id="primaryAdvisor"
              size="small"
              sx={{ marginBottom: 2 }}
              value={addCaseState.advisorName}
              onChange={({ target }) =>
                handleChange('advisorName', target.value)
              }
            />

            <InputLabel htmlFor="createdAt" required>
              Created at
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': { height: 40 },
                }}
                value={dayjs(addCaseState.createdAt)}
                onChange={value =>
                  value && handleChange('createdAt', value.toISOString())
                }
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{ padding: '16px' }}
          display="flex"
          justifyContent="space-between"
          gap={1}
        >
          <ModalButton
            variant="secondary"
            label="Cancel"
            handleClick={handleClose}
          />
          <ModalButton label="Add Case" handleClick={createCase} />
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCaseModal;
