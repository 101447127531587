import React, { useState } from 'react';
import {
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { usePostMarketplaceFavoriteMutation } from '../../hooks/usePostMarketplaceFavoriteMutation';
import { useDeleteMarketplaceFavoriteMutation } from '../../hooks/useDeleteMarketplaceFavoriteMutation';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';

interface FavButtonProps {
  id: string;
  isFavorite: boolean;
  name: string;
}

const FavButton: React.FC<FavButtonProps> = ({ id, isFavorite, name }) => {
  const [icon, setIcon] = useState(<FavStarRateRoundedIcon />);

  const { postFavoriteModelMutate } = usePostMarketplaceFavoriteMutation();
  const { deleteFavoriteModelMutate } = useDeleteMarketplaceFavoriteMutation();

  const handleClickFavorite = () =>
    isFavorite
      ? deleteFavoriteModelMutate({
          modelId: id,
          successToastMessage: `Model ${name} removed from favorites`,
        })
      : postFavoriteModelMutate({
          modelId: id,
          successToastMessage: `Model ${name} added to favorites`,
        });

  const handleMouseEnter = () => setIcon(<FavStarRateRoundedIconHover />);

  const handleMouseLeave = () => setIcon(<FavStarRateRoundedIcon />);

  return (
    <CustomTooltip
      title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
      placement="left"
    >
      <FavButtonStyled
        onClick={ev => {
          ev.stopPropagation();
          handleClickFavorite();
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isFavorite ? icon : <FavStarBorderRoundedIcon />}
      </FavButtonStyled>
    </CustomTooltip>
  );
};

export default FavButton;

const FavButtonStyled = styled(IconButton)`
  background: transparent;
  border: none;
  padding: 6px;
  pointer-events: auto;

  &:hover {
    background: transparent;
    icon {
      color: #1bc29a;
    }
  }
`;

const FavStarRateRoundedIcon = styled(StarRateRoundedIcon)`
  color: #1bc29a;
  background: #000;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 6px;

  &:hover {
    color: #f5f5f5;
  }
`;

const FavStarRateRoundedIconHover = styled(StarBorderRoundedIcon)`
  color: #1bc29a;
  background: #000;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 6px;
`;

const FavStarBorderRoundedIcon = styled(StarBorderRoundedIcon)`
  color: #000;
  background: #f5f5f5;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 6px;

  &:hover {
    color: #1bc29a;
  }
`;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 10,
    fontWeight: 500,
    fontFamily: 'inter',
    lineHeight: '12px',
    padding: '4px 10px 5px 10px',
  },
}));
