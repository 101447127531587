import { Button, Grid } from '@mui/material';
import React, { FC } from 'react';
import { styled } from 'styled-components';
import { InputLabels } from '../Buttons';

const FirmHeader: FC = () => {
  return (
    <Grid
      container
      mb="16px"
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Grid item alignItems={'center'}>
        <InputLabels
          sx={{
            color: '#000000',
            fontWeight: '500',
            fontSize: '24px',
            margin: 0,
          }}
        >
          Firm Information
        </InputLabels>
      </Grid>
      <Grid item>
        <Button
          sx={{
            textTransform: 'none',
            background: '#000000',
            width: '160px',
            borderRadius: '20px',
            color: '#ffffff',
            '&:hover': {
              background: '#00C3DE',
              color: '#000000',
              boxShadow: 'none',
            },
          }}
          disabled
          size="medium"
          variant="contained"
        >
          <SaveModelBtnText>Save Changes</SaveModelBtnText>
        </Button>
      </Grid>
    </Grid>
  );
};

export default FirmHeader;

const SaveModelBtnText = styled.span`
  font-size: 14px;
`;
