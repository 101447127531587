import { InputLabel } from '@mui/material';
import { styled } from 'styled-components';

export const InputLabels = styled(InputLabel)`
  font-size: 14;
  font-weight: 500;
  color: '#000';
`;

export const InputSubLabels = styled(InputLabel)`
  font-size: 14;
  color: '#000';
  font-weight: 400;
`;
