import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useCreateProposalMutation } from 'pages/Conversations/hooks/useCreateProposalMutation';
import {
  initialProposalCheckboxes,
  initialRiskAssessmentCheckboxes,
} from './constants';
import ProposalCheckboxes from 'pages/Conversations/components/ProposalGenerator/ProposalCheckboxes';
import ProposalView from 'pages/Conversations/components/ProposalGenerator/ProposalView';
import PrompQuestions from '@components/PrompQuestions';

interface ProposalGeneratorProps {
  modelId: string;
  clientId: string;
  isLastItem: boolean;
  mode: 'proposal' | 'riskAssessmentAnwsers';
}

const ProposalGenerator: React.FC<ProposalGeneratorProps> = ({
  modelId = '',
  clientId = '',
  mode = 'proposal',
  isLastItem = false,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [contentList, setContentList] = useState([]);

  const { createdProposalId, isCreateProposalLoading, createProposalMutate } =
    useCreateProposalMutation();

  useEffect(() => {
    if (isLastItem && !!clientId && contentList.length) {
      createProposalMutate({ modelId, clientId, contentList });
    }
  }, [clientId, contentList]);

  // event handlers

  let triggerDownloadTimer: ReturnType<typeof setTimeout> | undefined =
    undefined;

  const triggerDownload = () => {
    const downloadLink = document.querySelector('.download-proposal-link');
    if (downloadLink === null) {
      // We have to wait for the https://gitlab.com/tifinorg/tifin-grow/frontend/investment-proposals/
      // > src/App.tsx > a.download-proposal-link element to be created
      // We can't know how long it will take to render
      clearTimeout(triggerDownloadTimer);
      triggerDownloadTimer = setTimeout(triggerDownload, 1000);
    } else {
      const pdfFileName =
        mode === 'proposal' ? 'Investment Proposal' : 'Risk Assessment Details';
      downloadLink.setAttribute('download', pdfFileName);
      document.dispatchEvent(new Event('download'));
    }
  };

  const buttonActions = useMemo(
    () => [
      {
        label:
          mode === 'proposal'
            ? 'Preview Proposal'
            : 'View Risk Assessment Details',
        handler: () => setIsDrawerOpen(true),
      },
      {
        label:
          mode === 'proposal'
            ? 'Download Proposal'
            : 'Download Risk Assessment Details',
        handler: triggerDownload,
      },
    ],
    [mode],
  );

  const handleButtonClick = text => {
    const action = buttonActions.find(({ label }) => label === text);
    action!.handler();
  };

  return (
    <Box>
      <ProposalCheckboxes
        hideCheckboxes={mode === 'riskAssessmentAnwsers'}
        initialCheckboxes={
          mode === 'proposal'
            ? initialProposalCheckboxes
            : initialRiskAssessmentCheckboxes
        }
        disabled={!isLastItem || isCreateProposalLoading}
        handleCheckboxChange={contentList => setContentList(contentList)}
      />

      {isLastItem && (
        <ProposalView
          contentList={contentList}
          proposalId={isCreateProposalLoading ? '' : createdProposalId}
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={() => setIsDrawerOpen(false)}
        />
      )}

      <PrompQuestions
        showDividers
        itemPy="10px"
        questions={buttonActions.map(({ label }) => label)}
        handleQuestionClick={handleButtonClick}
      />
    </Box>
  );
};

export default ProposalGenerator;
