import { useMutation, UseMutationResult } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePostConsultFormArguments = {
  caseId: string;
  excel: any;
  successToastMessage: string;
};

export function usePostConsultForm(): UseMutationResult<
  unknown,
  unknown,
  UsePostConsultFormArguments
> {
  const { fetchMutation } = useFetchMutation();

  return useMutation(
    ({ caseId, excel, successToastMessage }: UsePostConsultFormArguments) =>
      fetchMutation({
        path: `/v2/cases/${caseId}/consultForm`,
        method: 'POST',
        body: { data: excel },
        invalidateQueries: [['cases'], ['cases', caseId]],
        successToastMessage,
      }),
  );
}
