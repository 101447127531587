/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import 'sortable-tablesort/sortable.min.js';
import 'sortable-tablesort/sortable.min.css';
import {
  extractClientIdFromQuery,
  removeInvalidMarkdown,
} from '@common/string';
import {
  ChatTableBody,
  ChatTableCell,
  ChatTableContainer,
  ChatTableHead,
  ChatTableRow,
  ChatTableWrapper,
  MdxContainer,
  MdxList,
  MdxListItem,
  MdxOrderedList,
  MdxSpan,
  MdxTypography,
} from './styles';
import { TickerSpan } from './ContentDefault';
import { extractNumber } from '@common/number';
import { useMediaQuery, useTheme } from '@mui/material';

// TODO: Commented MDX components
// import { MDXProvider } from '@mdx-js/react';
// import MyContent from "@mdx/myContent.mdx";

interface TestProps {
  content: string;
  graphData?: any;
  selectTicker?: any;
  isSender?: boolean;
  isStreaming?: boolean;
  setSelectedModel?: any;
}

const ChatMarkdownText: React.FC<TestProps> = ({
  content,
  selectTicker,
  isSender,
  isStreaming,
  setSelectedModel,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cleanedContent = useMemo(
    () => removeInvalidMarkdown(content),
    [content],
  );

  const components = useMemo(
    () => ({
      h1: MdxTypography,
      h2: MdxTypography,
      h3: MdxTypography,
      h4: MdxTypography,
      h5: MdxTypography,
      h6: MdxTypography,
      p: MdxTypography,
      ol: MdxOrderedList,
      ul: MdxList,
      li: MdxListItem,
      table: props => (
        <ChatTableContainer>
          <ChatTableWrapper
            stickyHeader
            className="sortable asc n-last"
            data-key="content-default-table-container"
            sx={{
              border: '10px solid #fff',
              borderTop: 0,
              borderBottom: 0,
              width: '100%',
              overflowX: 'auto',
            }}
            {...props}
          />
        </ChatTableContainer>
      ),
      thead: ChatTableHead,
      tbody: ChatTableBody,
      tr: props => {
        // FIX: if possible find an alternative instead of accessing children and nested array index. Also, add a click handler for entire row
        return <ChatTableRow {...props} />;
      },
      th: props => {
        const displayProp = props?.children?.props?.['data-hide'];
        return (
          <ChatTableCell
            sx={{
              fontSize: '12px !important',
              textAlign: 'left',
              paddingTop: '15px !important',
              textWrap: 'wrap',
              maxWidth: isMobile ? '200px' : '400px',
              display: displayProp,
            }}
            {...props}
          />
        );
      },
      td: props => {
        // FIX: if possible find an alternative instead of accessing children and nested array index
        const childrenProps = extractNumber(props.children);

        return childrenProps.isNaN === true ? (
          <ChatTableCell
            sx={{
              textWrap: 'wrap',
              maxWidth: '400px',
            }}
            {...props}
          />
        ) : (
          <ChatTableCell
            sx={{
              textWrap: 'wrap',
              maxWidth: '400px',
            }}
            data-sort={childrenProps?.number}
            {...props}
          />
        );
      },
      a: (props: any) => {
        const id = extractClientIdFromQuery(props?.href);
        if (id) {
          return (
            <MdxSpan
              onClick={_ => {
                const id = extractClientIdFromQuery(props?.href);
                id && setSelectedModel?.(id);
              }}
              {...props}
            />
          );
        }
        return <a {...props} />;
      },
      span: (props: any) => {
        if (!isSender && props?.['data-ticker']) {
          const ticker = props?.children;
          return (
            <TickerSpan
              onClick={() => selectTicker?.(ticker)}
              key={ticker}
              {...props}
            >
              {ticker}
            </TickerSpan>
          );
        }
        return <span {...props} />;
      },
    }),
    [isMobile, isSender, selectTicker],
  );

  return (
    <MdxContainer>
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={components}
      >
        {cleanedContent}
      </Markdown>
    </MdxContainer>
  );
};

export default ChatMarkdownText;
