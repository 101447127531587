import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SlideType } from 'features/slides/types';
import styles from './styles.module.css';

interface TableOfContentsProps {
  slides: SlideType[];
}

const ContentItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '& .dotted-line': {
    borderBottom: `1px dotted ${theme.palette.text.secondary}`,
    flex: 1,
    margin: `0 ${theme.spacing(1)}`,
  },
  '& .page-number': {
    minWidth: '24px',
    textAlign: 'right',
  },
}));

const getTitleFromSlideId = (
  slide: SlideType,
  isGroupTitle = false,
): string => {
  // Mapping de IDs a títulos base
  const baseTitles: Record<string, string> = {
    cover: 'Cover',
    'table-of-contents': 'Contents',
    'what-you-want-to-accomplish': 'What You Want to Accomplish',
    'guiding-philosophy': 'Guiding Philosophy, Goals & Principles',
    'explanation-of-model': 'Explanation Of Model',
    'explanation-of-models': 'Explanation Of Model',
    'strategy-allocation': 'Strategy Allocation',
    'excess-return-performance': 'Excess Return Performance',
    'risk-analysis': 'Risk Analysis',
    'portfolio-story': 'Portfolio Story',
    'portfolio-characteristics': 'Portfolio Characteristics',
    'strategy-selection': 'Strategy Selection',
    'performance-disclosures': 'Performance Disclosures',
    'amk-profiles': 'AMK Profiles',
    disclosures: 'Disclosures',
  };

  if (isGroupTitle) {
    // Para títulos de grupo, extraer el monto y el tipo
    const amountMatch = slide.id.match(/\$(\d+,\d+)/);
    const amount = amountMatch ? `$${amountMatch[1]} minimum` : '';

    // Extraer y formatear el tipo (Non Qualified/Qualified y Growth/Total Return...)
    const typeMatch = slide.id.match(
      /(Non-Qualified|Qualified)\s*-\s*(Growth\/Total Return\s*-\s*(?:Preservation|Income))/i,
    );

    let type = '';
    if (typeMatch) {
      // Separar y formatear cada parte
      const [, qualification, purpose] = typeMatch;
      const formattedQualification = qualification.replace('-', ' ');
      const formattedPurpose = purpose
        .replace(/-/g, '') // Eliminar guiones
        .trim();

      type = `${formattedQualification} ${formattedPurpose}`;
    }

    return `${amount} ${type}`.trim();
  } else {
    // Para elementos dentro del grupo, extraer solo el nombre base
    const baseId = slide.id
      .split('-')
      .filter(part => !part.match(/^\d+/))
      .join('-')
      .split('Non-Qualified')[0]
      .split('Qualified')[0]
      .split('$')[0]
      .trim();

    // Usar el mapping o formatear el título si no existe en el mapping
    const baseTitle = baseTitles[baseId];
    if (baseTitle) return baseTitle;

    // Si no está en el mapping, formatear el título manualmente
    return baseId
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
};

interface GroupedSlide {
  slide: SlideType;
  originalIndex: number;
  modelSetName?: string;
}

interface SlideGroup {
  title: string;
  slides: GroupedSlide[];
}

export function TableOfContents({
  slides,
}: TableOfContentsProps): React.ReactElement {
  const groupedSlides = React.useMemo(() => {
    const orderedSlides = [...slides].map((slide, index) => ({
      slide,
      originalIndex: index,
    }));

    const groups: Record<string, SlideGroup> = {};
    const standalone: GroupedSlide[] = [];
    const seen = new Set<string>();

    // Primero identificar los grupos basados en los slides principales
    orderedSlides.forEach(({ slide, originalIndex }) => {
      // Identificar el tipo de modelo y el monto
      const modelMatch = slide.id.match(
        /(Non-Qualified|Qualified)\s*-\s*(Growth\/Total Return\s*-\s*(?:Preservation|Income))/i,
      );
      const modelType = modelMatch ? modelMatch[0] : '';

      const modelSetMatch = slide.id.match(/\$(\d+,\d+)/);
      const modelSetName = modelSetMatch
        ? `$${modelSetMatch[1]} minimum`
        : undefined;

      // Slides que siempre son standalone
      const isAlwaysStandalone =
        slide.id.includes('cover') ||
        slide.id.includes('contents') ||
        slide.id.includes('what-you-want') ||
        slide.id.includes('guiding-philosophy') ||
        slide.id.includes('portfolio-story') ||
        slide.id.includes('amk-profiles') ||
        slide.id.includes('disclosures');

      // Identificar performance disclosures generales vs específicos
      const isPerformanceDisclosure = slide.id.startsWith(
        'performance-disclosures',
      );
      const isGroupSpecificDisclosure =
        isPerformanceDisclosure &&
        (slide.id.includes('$25,000') || slide.id.includes('$100,000'));

      // Extraer el modelSetName del performance disclosure si es específico
      let effectiveModelSetName = modelSetName;
      if (isGroupSpecificDisclosure) {
        const pdModelSetMatch = slide.id.match(/\$(\d+,\d+)/);
        if (pdModelSetMatch) {
          effectiveModelSetName = `$${pdModelSetMatch[1]} minimum`;
        }
      }

      // Generar ID único para deduplicación
      const uniqueId = `${slide.id.split('-')[0]}-${modelType}-${effectiveModelSetName || ''}`;

      if (!seen.has(uniqueId)) {
        seen.add(uniqueId);

        const groupedSlide: GroupedSlide = {
          slide,
          originalIndex,
          modelSetName: effectiveModelSetName,
        };

        if (
          (effectiveModelSetName && !isAlwaysStandalone && modelType) ||
          isGroupSpecificDisclosure
        ) {
          const groupKey = `${effectiveModelSetName}-${modelType}`;
          if (!groups[groupKey]) {
            groups[groupKey] = {
              title: effectiveModelSetName || '',
              slides: [],
            };
          }
          groups[groupKey].slides.push(groupedSlide);
        } else {
          standalone.push(groupedSlide);
        }
      }
    });

    return { groups, standalone };
  }, [slides]);

  // Ordenar los grupos por el índice de su primer slide
  const orderedContent = React.useMemo(() => {
    const allItems: Array<{
      type: 'standalone' | 'group';
      content: GroupedSlide | { title: string; slides: GroupedSlide[] };
      firstIndex: number;
    }> = [
      // Standalone items
      ...groupedSlides.standalone.map(slide => ({
        type: 'standalone' as const,
        content: slide,
        firstIndex: slide.originalIndex,
      })),
      // Groups
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...Object.entries(groupedSlides.groups).map(([_, group]) => ({
        type: 'group' as const,
        content: group,
        firstIndex: Math.min(...group.slides.map(s => s.originalIndex)),
      })),
    ];

    return allItems.sort((a, b) => a.firstIndex - b.firstIndex);
  }, [groupedSlides]);

  return (
    <Box sx={{ padding: 2 }} className={styles.container}>
      <Typography gutterBottom className={styles.title}>
        Contents
      </Typography>

      {orderedContent.map(item => {
        if (item.type === 'standalone') {
          const { slide, originalIndex } = item.content as GroupedSlide;
          return (
            <ContentItem key={slide.id}>
              <Typography>{getTitleFromSlideId(slide)}</Typography>
              <div className="dotted-line" />
              <Typography className="page-number">
                {originalIndex + 1}
              </Typography>
            </ContentItem>
          );
        } else {
          const group = item.content as SlideGroup;
          const firstPageIndex = Math.min(
            ...group.slides.map(s => s.originalIndex),
          );
          // Usar el primer slide del grupo para obtener el título completo
          const groupTitle = getTitleFromSlideId(group.slides[0].slide, true);
          return (
            <React.Fragment key={group.title}>
              <ContentItem>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '0.9em',
                    color: 'text.secondary',
                  }}
                >
                  {groupTitle}
                </Typography>
                <div className="dotted-line" />
                <Typography className="page-number">
                  {firstPageIndex + 1}
                </Typography>
              </ContentItem>
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
}
