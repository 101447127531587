import React from 'react';

import { Typography } from '@mui/material';
import { useSlideContext } from 'features/slides/context';
import logo from '../../../assets/assetmark/logo.png';
import styles from './styles.module.css';

export type SlideLayoutProps = {
  readonly index: number;
  readonly children: React.ReactNode;
  readonly footer?: boolean;
};

export function SlideLayout({
  index,
  children,
  footer = true,
}: SlideLayoutProps): React.ReactElement {
  const { theme, width, height } = useSlideContext();

  return (
    <div
      className={[styles.slide, 'slide-item'].join(' ')}
      style={{ width, height, aspectRatio: width / height }}
    >
      <div
        className={styles.slideContent}
        style={{ width, height: footer ? height - 48 : height }}
      >
        {children}
      </div>
      {footer && (
        <div className={styles.slideFooter}>
          <div className={styles.slideFooterLeft}>
            <Typography
              sx={{
                color: theme.colors.ghost,
                fontFamily: theme.fontFamily.body,
                fontWeight: theme.fontWeight.regular,
                fontSize: theme.fontSize.xs,
              }}
            >
              For financial advisor use only
            </Typography>
          </div>
          <div className={styles.slideFooterRight}>
            <div className={styles.slideFooterLogo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={styles.slideFooterPageNumber}>
              <Typography
                sx={{
                  color: theme.colors.ghost,
                  fontFamily: theme.fontFamily.body,
                  fontWeight: theme.fontWeight.bold,
                  fontSize: theme.fontSize.xs,
                }}
              >
                {index + 1}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
