/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { settings } from 'config';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteCustomModelsMutation = setCurrentItemId => {
  const queryClient = useQueryClient();

  const fetchData = async data => {
    const token = localStorage.getItem('x-tifin-ai-token') || '';
    const response = await axios.delete(
      `${settings.api.baseUrl}/v2/models/${data.id}`,
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'x-tifin-ai-auth': token,
        },
      },
    );
    return response.data;
  };

  const { mutate, isLoading } = useMutation(fetchData, {
    onMutate: ({ id }) => {
      setCurrentItemId(id);
    },
    onSuccess() {
      queryClient.invalidateQueries('customModels');
    },
  });

  return {
    deleteCustomModelMutate: mutate,
    deleteCustomModelLoading: isLoading,
  };
};
