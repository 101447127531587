import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type UseGetAllModelsArguments = {
  model_ids?: string[];
};

type UseGetAllModelsResult = {
  models: any;
  isModelsLoading: boolean;
};

export const useGetAllModels = ({
  model_ids = [],
}: UseGetAllModelsArguments): UseGetAllModelsResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery(
    ['all-models'],
    fetchQuery({
      path: `/v2/proxy/investment-engine/api/enterprise/amk/models`,
      method: 'POST',
      body: {
        model_ids: model_ids,
      },
    }),
    { cacheTime: 0 },
  );

  return {
    models: data,
    isModelsLoading: isLoading,
  };
};
