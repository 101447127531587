import { handleError } from '@common/error';
import { extractText, getChatIdAndQuery } from '@common/string';
import { validArray } from '@common/validArray';
import {
  getEventStreamData,
  getSearchedQuery,
  getSearchedQueryData,
  isSampleQuestionsCalled,
} from '@features/Chat/selector';
import { actions as chatActions } from '@features/Chat/slice';
import { ChatFeedbackInterface } from '@features/Chat/types';
import { getAuthDetails } from '@features/Profile/selector';
import SageAvatar from '@icons/sageAvatar';
import Loading from '@images/Loading.gif';
import { Grid } from '@mui/material';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatBox from './ChatBox';
import ChatMarkdownText from './ChatMarkdownText';
import { generateChatlogId } from './mutations';
import {
  AvatarWrapper,
  ChatBoxContainer,
  ChatBoxContent,
  ChatContentContainer,
} from './styles';

type ChatContentProps = {
  sx?: object;
  isMinimized?: boolean;
  isStreaming: boolean;
  chatHistory: any[];
  onSelectChatOption: any;
  portfolioOptions: any[];
  loopCounter: number;
  setLoopCounter: React.Dispatch<number>;
  toggleFeedback: (props: any) => void;
};

function ChatContent({
  sx = {},
  isMinimized = false,
  chatHistory: renderData = [],
  isStreaming = true,
  onSelectChatOption,
  portfolioOptions,
  loopCounter,
  setLoopCounter,
  toggleFeedback,
}: ChatContentProps): React.JSX.Element {
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const { eventSource, isStreamInitated }: any =
    useSelector(getEventStreamData);
  const chatContentContainerRef = useRef<HTMLDivElement | null>(null);
  const messageBottomRef = useRef<HTMLDivElement | null>(null);
  const chatContentRef = useRef<HTMLDivElement | null>(null);
  const profileState: any = useSelector(getAuthDetails);
  const { isSampleQuestionAsked } = useSelector(isSampleQuestionsCalled);
  const query: any = useSelector(getSearchedQuery);
  const queryData: any = useSelector(getSearchedQueryData);

  const [message, setMessage] = useState('');
  const [markdownToDisplay, setMarkdownToDisplay] = useState('');
  const [streaming, setStreaming] = useState(false);

  const scrollToMessageTop = (
    delay: number = 50,
    noSmoothScroll: boolean = false,
  ) => {
    setTimeout(() => {
      if (chatContentContainerRef.current) {
        // Calculate the new scroll position to bring the last message to the top
        const lastMessageHeight =
          chatContentContainerRef.current.lastElementChild?.clientHeight || 0;
        const visibleHeight = chatContentContainerRef.current.clientHeight;
        const scrollHeight = chatContentContainerRef.current.scrollHeight;

        // Ensure the new scroll position brings the last message to the top
        chatContentContainerRef.current.scrollTo({
          top: scrollHeight - visibleHeight - lastMessageHeight,
          behavior: noSmoothScroll ? 'auto' : 'smooth',
        });
      }
    }, delay);
  };

  const onFeedbackSubmit = (data: ChatFeedbackInterface) => {
    dispatch(chatActions.submitChatFeedbackRequest(data));
  };

  const parseDataFromMessage = (message: string) => {
    const pattern = /\|\|startJson\s(.*?)\|\|endJson/gs;
    const match = pattern.exec(message);

    if (match) {
      const jsonData = match[1];
      const parsedData = JSON.parse(jsonData);
      return parsedData?.data;
    } else {
      return null;
    }
  };

  const addToHistory = (
    id: string,
    data: any,
    debugObj: any = {},
    isSender = false,
    createdAt: string = new Date().toISOString(),
    chatHistory: any[] = [],
  ) => {
    if (!validArray(chatHistory)) {
      chatHistory = renderData;
    }
    let showFeedback = true;
    if (!isSender && isSampleQuestionAsked) {
      showFeedback = false;
      data.push({
        id: id,
        type: 'whatElseButton',
      });
      dispatch(
        chatActions.searchSampleQuestion({ isSampleQuestionAsked: false }),
      );
    }

    const newList = chatHistory.concat([
      {
        isSender,
        debugObj,
        time: 'Now',
        id: isSender ? id : '',
        isIdAttachedToQuery: false, // new boolean field will used for query isSender = true only
        isChatHistory: true,
        isShowFeedback: showFeedback,
        mock: false,
        data,
        createdAt,
        feedback: {
          chatLogId: id,
          isSubmitted: false,
          scale: '0',
        },
      },
    ]);
    dispatch(
      chatActions.loadChatSuccess({
        chatHistory: newList,
      }),
    );
    return newList;
  };

  const addIdToHistory = (id: string, data) => {
    const lastItem = data[data.length - 1];
    const list = [
      ...data.slice(0, -1),
      {
        ...lastItem,
        isIdAttachedToQuery: lastItem.isSender ? true : false, // set state to true here as we are attaching id generated from /chat/stream API
        id,
      },
    ];
    if (!lastItem.isIdAttachedToQuery && lastItem.isSender) {
      dispatch(
        chatActions.loadChatSuccess({
          chatHistory: list,
        }),
      );
    }
  };

  const onEndStream = async (message: string) => {
    let chatHistory: any[] = []; // used for setting chatHistory, if needed
    const msgInfo = getChatIdAndQuery(message);
    const chatId = msgInfo ? msgInfo.chatId : '';

    const debugObj = {};
    const responses: Array<any> = [];

    const parsedJson = parseDataFromMessage(message);
    const { match: _message = '', startTextCount = 0 } = extractText(message);

    const _res = { type: 'markdown', content: _message };
    responses.push(_res);
    if (Array.isArray(parsedJson) && parsedJson.length > 0) {
      if (startTextCount > 2) {
        const suggestions = parsedJson.find(
          (item: any) => item?.type === 'suggestions',
        );
        responses.push(suggestions);
      } else {
        responses.push(...parsedJson);
      }
    }

    // if same advisor account is open on another window/broswer/system display query as soon as we get it.
    if (msgInfo) {
      const { chatId, query } = msgInfo;
      const lastData = renderData[renderData.length - 1];
      if (
        !lastData.isIdAttachedToQuery &&
        !lastData.isSender &&
        chatId !== lastData.id
      ) {
        chatHistory = addToHistory(chatId, query, {}, true); // attaching query triggered in another window/broswer/system for same advisor
      }
    }

    addToHistory(
      chatId,
      responses,
      debugObj,
      false,
      new Date().toISOString(),
      chatHistory,
    ); // attaching chatHistory coming from function addToHistory returned with query on another window/broswer/system for same advisor

    setStreaming(false);
    setMarkdownToDisplay('');
    setMessage('');
    if (isChatStreaming === 'true') {
      dispatch(chatActions.setSearchQuery(''));
    } else {
      dispatch(chatActions.searchQueryRequest({ query: '' }));
    }
  };

  useLayoutEffect(() => {
    // The delay is because the request has a delay of 500ms
    // before being launched and therefore the message sent
    // does not appear in the conversation and
    // is not taken into account in the first scroll.
    scrollToMessageTop(550, true);
  }, []);

  useEffect(() => {
    if (renderData?.length === 0 && pathname !== '/conversations/stream') {
      return navigate('/conversations');
    }
  }, [renderData]);

  useEffect(() => {
    if (
      profileState.advisorId &&
      pathname === '/conversations/stream' &&
      typeof query === 'string' &&
      query !== '' &&
      isStreamInitated
    ) {
      const attachQueryToHistoryWithId = async () => {
        try {
          const historyWithQuery = addToHistory('', query, {}, true);
          setStreaming(true);
          // Call the generateChatlogId only after stream is initiated
          const res = await generateChatlogId(
            query,
            profileState.advisorId,
            queryData,
          );
          // attach Id to current query so that we can identify it for concurrent open windows/systems
          addIdToHistory(res.data.data, historyWithQuery);
        } catch (error: any) {
          handleError(dispatch, error);
        }
      };

      attachQueryToHistoryWithId();
    } else {
      console.log('Stream not opened');
    }

    // TODO: need to handle interrupted queries via route change/multiple queries here
    // return () => {
    //   if (isChatStreaming === 'true') {
    //     dispatch(chatActions.setSearchQuery(''));
    //   } else {
    //     dispatch(chatActions.searchQueryRequest({ query: '' }));
    //   }
    // };
  }, [profileState.advisorId, query, pathname, isStreamInitated]);

  useEffect(() => {
    if (eventSource) {
      eventSource.onmessage = event => {
        const data = JSON.parse(event.data);
        setMessage(message => message + data);

        // TODO: if we need to encode sse data as well we can use below code in future
        // const base64Data = event.data;
        // const binaryData = Uint8Array.from(atob(base64Data), char =>
        //   char.charCodeAt(0),
        // );
        // setMessage(message => message + decode(binaryData));
      };

      eventSource.onerror = () => {
        console.log('Something went wrong while streaming');
        message && onEndStream(message);
      };
    }
  }, [eventSource]);

  // displaying markdown changes from stream api
  useEffect(() => {
    if (message.trim().endsWith('||endStream')) {
      onEndStream(message);
    }

    const { match: _message = '' } = extractText(message);
    setMarkdownToDisplay(_message);
  }, [message]);

  useEffect(() => {
    if (markdownToDisplay !== '') {
      setStreaming(false);
    }
  }, [markdownToDisplay]);

  return (
    <ChatContentContainer
      item
      sx={{
        ...sx,
        ['&>div:nth-last-of-type(3)']: {
          visibility: 'visible',
          minHeight:
            location?.state?.scroll !== 1
              ? chatContentContainerRef?.current?.offsetHeight
                ? streaming || isStreaming
                  ? 69
                  : 69
                : 0
              : '10vh',
        },
        ['&>div:nth-last-of-type(2)']: {
          visibility: 'visible',
          minHeight:
            location?.state?.scroll !== 1
              ? chatContentContainerRef?.current?.offsetHeight
                ? streaming || isStreaming
                  ? chatContentContainerRef.current &&
                    chatContentContainerRef.current.offsetHeight - 70
                  : chatContentContainerRef.current.offsetHeight - 70
                : 0
              : '50vh',
        },
      }}
      p={2}
      isminimized={isMinimized ? 1 : 0}
      ref={chatContentContainerRef}
    >
      {Array.isArray(renderData) &&
        renderData?.length > 0 &&
        renderData.map((chat: any, index: number) => {
          return (
            <React.Fragment key={`chat-container-${index}`}>
              <ChatBox
                key={`${index}+1`}
                isLastItem={index + 1 === renderData.length}
                index={index + 1}
                chat={chat}
                onSelectChatOption={onSelectChatOption}
                portfolioOptions={portfolioOptions}
                loopCounter={loopCounter}
                setLoopCounter={setLoopCounter}
                toggleFeedback={toggleFeedback}
                onFeedbackSubmit={onFeedbackSubmit}
                chatContentRef={chatContentRef}
                scrollToMessageTop={scrollToMessageTop}
              />
            </React.Fragment>
          );
        })}

      {query && markdownToDisplay && (
        <ChatBoxContainer
          container
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <Grid
            item
            container
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            columnGap={1}
            width={'calc(100% - 100px)'}
          >
            <AvatarWrapper>
              <SageAvatar />
            </AvatarWrapper>
            <ChatBoxContent item container sm={10} rowGap={2}>
              <ChatMarkdownText
                content={markdownToDisplay}
                isStreaming={true}
                isSender={false}
              />
            </ChatBoxContent>
          </Grid>
        </ChatBoxContainer>
      )}

      {(streaming || isStreaming) && (
        <ChatBoxContainer
          container
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <ChatBoxContent
            sx={{
              marginTop: '12px',
              marginLeft: '75px',
              width: '250px',
              height: '20px',
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            <img
              src={Loading}
              alt="Loading gif"
              style={{
                height: '40px',
                marginTop: '-20px',
                marginLeft: '-25px',
              }}
            />
          </ChatBoxContent>
        </ChatBoxContainer>
      )}

      <div id="idMessageEnd" ref={messageBottomRef} />
    </ChatContentContainer>
  );
}

export default ChatContent;
