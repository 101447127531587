import React from 'react';
import baseImg from './AMK-presentation-approach.png';
import { Grid } from '@mui/material';
import Footer from '../Footer';
import { type Slide } from '../../_data';

export type Slide3ContentType = [
  {
    field: 'title1';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
];
export const Slide3Content = React.memo(
  ({
    slide,
  }: {
    slide: Slide<Slide3ContentType>;
    onChangeSlide: (
      slide: Slide<Slide3ContentType>,
      content: Slide3ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding="12px 12px 0px 12px"
        sx={{
          height: '100%',
          width: '100%',
          fontFamily: 'Roboto',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            height: 'calc(100% - 48px)',
          }}
        >
          <img
            src={baseImg}
            alt="Model Request"
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Footer
          text="For Financial Advisor use only"
          numberPage={slide.slideTemplateId}
        />
      </Grid>
    );
  },
);

export default Slide3Content;
