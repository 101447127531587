import styled from 'styled-components';

const deafultColor = '#000';
const disabledColor = '#818181';
const hoverColor = '#1bc29a';
const dividersBorder = '1px solid #ececec';

export const PrompQuestionsContainer = styled.ul<{
  $showDividers?: boolean;
}>`
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: ${props => (props.$showDividers ? dividersBorder : 'none')};
`;

export const PrompQuestionsItem = styled.li<{
  color?: string;
  disabled?: boolean;
  py?: string;
  $showDividers?: boolean;
  $showEllypsis?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: ${props => props.py} 0;
  border-bottom: ${props => (props.$showDividers ? dividersBorder : 'none')};
  color: ${props =>
    props.disabled ? disabledColor : props.color || deafultColor};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  .promp-question-mark {
    font-size: 16px;
  }

  .promp-question-text {
    display: inline-block;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 400;
    white-space: ${props => (props.$showEllypsis ? 'nowrap' : 'normal')};
    overflow: ${props => (props.$showEllypsis ? 'hidden' : 'visible')};
    text-overflow: ${props => (props.$showEllypsis ? 'ellipsis' : 'clip')};
  }

  &:hover {
    color: ${props => (props.disabled ? disabledColor : hoverColor)};

    & .promp-question-mark {
      transition: ${props =>
        props.disabled ? 'none' : 'transform 30s linear'};
      transform: rotate(3600deg);
    }
  }
`;
