import { OktaAuth } from '@okta/okta-auth-js';
import { settings } from 'config';

const SCOPES = ['openid', 'profile', 'email', 'offline_access'];
const CLIENT_ID = settings.okta.clientId;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const ISSUER = `https://${settings.okta.issuer}/oauth2/default`;
const LABELS = {
  en: {
    // Labels
    'primaryauth.username.placeholder': 'Email',
    'error.username.required': 'Please enter an email',
    remember: 'Remember me',
    forgotpassword: 'Forgot Password?',
  },
};

export const config = {
  oidc: {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    scopes: SCOPES,
    redirectUri: REDIRECT_URI,
    tokenManager: {
      autoRenew: false,
      expireEarlySeconds: 300,
      syncStorage: true,
    },
    pkce: true,
  },
  widget: {
    issuer: ISSUER,
    i18n: LABELS,
    clientId: CLIENT_ID,
    scopes: SCOPES,
    redirectUri: REDIRECT_URI,
    useInteractionCodeFlow: true,
    pkce: true,
  },
};

export const oktaAuth = new OktaAuth(config.oidc);
