import axios from 'axios';
import { useQuery } from 'react-query';
import { settings } from 'config';
import { Model } from '../../types';

interface CustomModel {
  id: string;
  name: string;
  updatedAt: Date;
  securities: number;
  holdings: {
    id?: string;
    name: string;
    ticker: string;
    weight: number;
  }[];
}

interface UseGetCustomModelsQueryResult {
  pagination: {
    totalCount: number;
    totalPages: number;
    currentPage: number;
  };
  customModels: CustomModel[] | [];
  isCustomModelsLoading: boolean;
  isCustomModelsError: boolean;
  refetch: () => void;
}
export interface GetCustomModelsParams {
  limit?: number;
  page?: number;
  order?: 'asc' | 'desc';
  orderBy?: 'name' | 'updatedAt' | 'createdAt';
  search?: string;
}
export interface GetCustomModelsParams {
  limit?: number;
  page?: number;
  order?: 'asc' | 'desc';
  orderBy?: 'name' | 'updatedAt' | 'createdAt';
  search?: string;
}

export type GetCustomModelsResponse = {
  currentPage: number;
  data: Model[];
  message: string;
  totalCount: number;
  totalPages: number;
};

export const getCustomModelsQuery = async (
  params: GetCustomModelsParams,
): Promise<GetCustomModelsResponse> => {
  const limit = params?.limit || 10;
  const page = params?.page || 1;
  const token = localStorage.getItem('x-tifin-ai-token') || '';
  const queryParams = new URLSearchParams();
  queryParams.set('limit', limit.toString());
  queryParams.set('page', page.toString());
  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.set(key, value);
  });

  const response = await axios.get(
    `${settings.api.baseUrl}/v2/models?${queryParams.toString()}`,
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'x-tifin-ai-auth': token,
      },
    },
  );
  return response.data;
};

export const useGetCustomModelsQuery = (
  params?: GetCustomModelsParams,
): UseGetCustomModelsQueryResult => {
  const { data, isLoading, isError, refetch } = useQuery(
    [
      'customModels',
      params?.search,
      params?.orderBy,
      params?.order,
      params?.page,
      params?.limit,
    ],
    () => getCustomModelsQuery(params || {}),
    {
      select: data => {
        const models = data.data.map(model => ({
          id: model.id,
          name: model.name,
          updatedAt: new Date(model.updatedAt),
          securities: model.holdings.length,
          holdings: model.holdings.map(holding => ({
            id: holding.id,
            name: holding.instrument.name,
            ticker: holding.instrument.ticker,
            weight: holding.weight,
          })),
        }));
        return {
          models,
          totalCount: data.totalCount,
          totalPages: data.totalPages,
          currentPage: data.currentPage,
        };
      },
    },
  );

  return {
    pagination: {
      totalCount: data?.totalCount || 0,
      totalPages: data?.totalPages || 0,
      currentPage: data?.currentPage || 0,
    },
    customModels: data?.models || [],
    isCustomModelsLoading: isLoading,
    isCustomModelsError: isError,
    refetch,
  };
};
