import { Grid, Typography } from '@mui/material';
import { SlideSubtitle, SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import { ModelSetHolding } from 'features/premium-report/api/types';
import { DataGrid } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';

export function WhatYouWantToAccomplish({
  modelSetHoldings,
  specificConsiderations,
}: {
  modelSetHoldings: ModelSetHolding[];
  specificConsiderations: string;
}): React.ReactElement {
  return (
    <Grid container gap={2} className={styles.container}>
      <Grid item>
        <SlideTitle>What You Want to Accomplish</SlideTitle>
      </Grid>
      <Grid item>
        <SlideSubtitle>Key Objectives</SlideSubtitle>

        <Typography component="p" className={styles.paragraph}>
          Summary of main goals and objectives for using AssetMark:
        </Typography>

        <ul>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Provide compelling investment solutions
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Consolidate investment approaches used and stories told
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Build scale to accelerate growth and profitability
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Spend more time building and maintaining relationships with
              clients
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item>
        <SlideSubtitle>Specific Considerations</SlideSubtitle>
        <Typography component="p" className={styles.paragraph}>
          {specificConsiderations}
        </Typography>
      </Grid>

      <Grid item width="100%">
        <SlideSubtitle>Model Summary</SlideSubtitle>
        <Typography
          component="p"
          className={styles.paragraph}
          sx={{ marginBottom: '10px' }}
        >
          The following models were selected by the advisor through the
          consulting process:
        </Typography>

        <DataGrid
          rows={getRows(modelSetHoldings)}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          hideFooter
          disableColumnSorting
          disableColumnSelector
          disableMultipleRowSelection
          disableRowSelectionOnClick
          disableColumnResize
          rowHeight={35}
          sx={{
            height: 'fit-content',
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f0f0f0',
              whiteSpace: 'normal',
              height: 'auto',
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
                height: 'auto',
              },
            },
            '& .MuiDataGrid-cell': {
              fontSize: '11px',
              whiteSpace: 'normal !important',
              lineHeight: 'normal',
              display: 'flex',
              alignItems: 'center',
            },
          }}
        />
      </Grid>

      <Grid item>
        <Typography component="p" className={styles.paragraph}>
          *Trading minimums may be calculated using exception-based strategy
          trading minimums rather than stated minimums. Please consult your
          AssetMark sales representative for any questions on strategy minimums.
        </Typography>
      </Grid>
    </Grid>
  );
}
const formatAssetSize = (assetSize: number) => {
  if (assetSize >= 1000000) {
    return `$${assetSize / 1000000}M`;
  }
  if (assetSize >= 1000) {
    return `$${assetSize / 1000}k`;
  }
  return `$${assetSize}`;
};

const getRows = (modelSetHoldings: ModelSetHolding[]) => {
  return modelSetHoldings.map(modelSet => {
    const allRiskProfiles = modelSet.portfolio_options.flatMap(
      portfolioOption =>
        portfolioOption.portfolios.map(portfolio => portfolio.risk_profile),
    );

    const minRisk = Math.min(...allRiskProfiles);
    const maxRisk = Math.max(...allRiskProfiles);

    const taxStatus = modelSet.model_set_name.includes('Qualified')
      ? 'Qualified'
      : 'Non-Qualified';
    return {
      model_set_name: modelSet.model_set_name,
      id: `${modelSet.model_set_name}`,
      tax_status: taxStatus,
      asset_size: formatAssetSize(modelSet.metadata.asset_size),
      risk_profiles:
        allRiskProfiles.length > 1 ? `${minRisk}-${maxRisk}` : `${minRisk}`,
    };
  });
};

const columns: GridColDef[] = [
  {
    field: 'model_set_name',
    headerName: 'INVESTMENT OBJECTIVE',
    flex: 2,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
  {
    field: 'asset_size',
    headerName: 'INVESTMENT MINIMUM*',
    flex: 1,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
  {
    field: 'tax_status',
    headerName: 'ACCOUNT TAX STATUS',
    flex: 1,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
  {
    field: 'risk_profiles',
    headerName: 'RISK PROFILES',
    flex: 1,
    headerAlign: 'left',
    renderHeader: params => (
      <Typography
        style={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontSize: '9px',
          fontWeight: '600',
        }}
      >
        {params.colDef.headerName}
      </Typography>
    ),
  },
];
