import React from 'react';
import { InputFilterContainer } from './styles';
import searchIcon from '@images/client-search.svg';

interface InputFilterProps {
  placeholder?: string;
  value: string;
  handleInputChange: (value: string) => void;
}

function InputFilter(props: InputFilterProps): React.JSX.Element {
  return (
    <InputFilterContainer>
      <label className="label">
        <img className="icon" alt="" src={searchIcon} />
        <input
          type="text"
          placeholder={props.placeholder}
          className="input"
          value={props.value}
          onChange={ev => props.handleInputChange(ev.target.value)}
        />
      </label>
    </InputFilterContainer>
  );
}

export default InputFilter;
