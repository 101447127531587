import React from 'react';
import { Grid } from '@mui/material';
import CustomAddClientBox from './CustomAddClientBox';
import InputFilter from '@components/InputFilter';
import StatusFilter from '@components/StatusFilter';
import SortMenu from '@components/SortMenu';
import TabMenu from '@components/TabMenu';

interface ClientFilterProps {
  // tabs
  selectedTab: string;
  handleTabChange: (tab: string) => void;
  // notifications
  totalNotifications: {
    risk: number;
    opportunity: number;
  };
  selectedNotification: string;
  handleNotificationChange: (value: string) => void;
  // search
  search: string;
  handleSearchChange: (value: string) => void;
  // sort
  selectedSortType: 'asc' | 'desc';
  selectedSortOption: string;
  handleSortTypeChange: (type: 'asc' | 'desc') => void;
  handleSortOptionChange: (value: string) => void;
  // add client
  addClientModal: boolean;
  openAddClientModal: () => void;
  setAddClientModal: (value: boolean) => void;
  handleClickOpenModel: () => void;
}

const ClientFilter = ({
  // tabs
  selectedTab,
  handleTabChange,
  // notifications
  totalNotifications,
  selectedNotification,
  handleNotificationChange,
  // search
  search,
  handleSearchChange,
  // sort
  selectedSortType,
  selectedSortOption,
  handleSortTypeChange,
  handleSortOptionChange,
  // add client / prospect
  openAddClientModal,
  addClientModal,
  setAddClientModal,
  handleClickOpenModel,
}: ClientFilterProps): JSX.Element => {
  return (
    <Grid item xs={12} alignItems={'center'}>
      <Grid item xs={12}>
        <TabMenu
          tabs={['All', 'Client', 'Prospects']}
          activeTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      </Grid>

      <Grid container justifyContent="flex-start" alignItems="center" gap={1}>
        <Grid item>
          {/* This component is decorative for now, it does not change the functionality of the app */}
          <StatusFilter
            currentStatus={selectedNotification}
            statuses={[
              {
                title: 'All Statuses',
                value: '',
              },
              {
                title: 'Risk',
                value: 'risk',
                badgeColor: '#FF0000',
                badgeNumber: totalNotifications.risk,
              },
              {
                title: 'Opportunity',
                value: 'opportunity',
                badgeColor: '#7E19FE',
                badgeNumber: totalNotifications.opportunity,
              },
            ]}
            handleStatusChange={handleNotificationChange}
          />
        </Grid>

        <Grid item flexGrow={1}>
          <InputFilter
            placeholder="Search for any client or prospect"
            value={search}
            handleInputChange={handleSearchChange}
          />
        </Grid>

        <Grid item>
          <SortMenu
            selectedType={selectedSortType}
            selectedOptionValue={selectedSortOption}
            options={[
              { title: 'Sort by Name', placeholder: 'Name', value: 'Name' },
              { title: 'Sort by Value', placeholder: 'Value', value: 'Value' },
            ]}
            handleOptionChange={handleSortOptionChange}
            handleSortTypeChange={handleSortTypeChange}
          />
        </Grid>

        <Grid item>
          <CustomAddClientBox
            openAddClientModal={openAddClientModal}
            addClientModal={addClientModal}
            setAddClientModal={setAddClientModal}
            handleClickOpenModel={handleClickOpenModel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientFilter;
