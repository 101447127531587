// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_editableField__RSQVY {
  width: fit-content;
  border-radius: 2px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
  color: #04acd7;
}

.styles_editableField__RSQVY:hover {
  outline: 1px dashed hsla(0 0% 50%);
  background-color: hsla(0, 0%, 50%, 0.2);
}

.styles_editableField__RSQVY[data-editable='true'] {
  outline: 1px solid hsl(293, 55%, 41%);
  background-color: hsl(293 55% 41% / 0.2);
  cursor: text;
}

.styles_editableField__RSQVY[data-editable='false'] .styles_mdxeditor-toolbar__sASE4 {
  display: none;
}

.styles_contentEditable__Fu\\+zY {
  color: #fff;
  font-family: 'Roboto';
}

.styles_toolbar__Mc\\+yW {
  position: absolute;
  display: none;
  max-width: fit-content;
  width: calc(100% - 12px);
  top: -48px;
  left: 0;
  right: 0;
}

/* MDX Editor */
.styles_mdxeditor__oR\\+BA {
  position: relative;
  width: 100%;
}

.styles_editableField__RSQVY:focus-within .styles_toolbar__Mc\\+yW {
  display: flex;
}

.styles_coverFor__LcomF {
  font-family: Roboto !important;
  font-weight: 500 !important;
  font-size: 26px !important;
  color: #04acd7 !important;
}

.styles_coverBy__hBpYw {
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  vertical-align: middle !important;
  color: #04acd7 !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/slides/components/editable-field/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,kCAAkC;EAClC,uCAAuC;AACzC;;AAEA;EACE,qCAAqC;EACrC,wCAAwC;EACxC,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,UAAU;EACV,OAAO;EACP,QAAQ;AACV;;AAEA,eAAe;AACf;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,iCAAiC;EACjC,yBAAyB;AAC3B","sourcesContent":[".editableField {\n  width: fit-content;\n  border-radius: 2px;\n  cursor: pointer;\n  user-select: none;\n  width: 100%;\n  color: #04acd7;\n}\n\n.editableField:hover {\n  outline: 1px dashed hsla(0 0% 50%);\n  background-color: hsla(0, 0%, 50%, 0.2);\n}\n\n.editableField[data-editable='true'] {\n  outline: 1px solid hsl(293, 55%, 41%);\n  background-color: hsl(293 55% 41% / 0.2);\n  cursor: text;\n}\n\n.editableField[data-editable='false'] .mdxeditor-toolbar {\n  display: none;\n}\n\n.contentEditable {\n  color: #fff;\n  font-family: 'Roboto';\n}\n\n.toolbar {\n  position: absolute;\n  display: none;\n  max-width: fit-content;\n  width: calc(100% - 12px);\n  top: -48px;\n  left: 0;\n  right: 0;\n}\n\n/* MDX Editor */\n.mdxeditor {\n  position: relative;\n  width: 100%;\n}\n\n.editableField:focus-within .toolbar {\n  display: flex;\n}\n\n.coverFor {\n  font-family: Roboto !important;\n  font-weight: 500 !important;\n  font-size: 26px !important;\n  color: #04acd7 !important;\n}\n\n.coverBy {\n  font-family: Roboto !important;\n  font-weight: 400 !important;\n  font-size: 20px !important;\n  vertical-align: middle !important;\n  color: #04acd7 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editableField": `styles_editableField__RSQVY`,
	"mdxeditor-toolbar": `styles_mdxeditor-toolbar__sASE4`,
	"contentEditable": `styles_contentEditable__Fu+zY`,
	"toolbar": `styles_toolbar__Mc+yW`,
	"mdxeditor": `styles_mdxeditor__oR+BA`,
	"coverFor": `styles_coverFor__LcomF`,
	"coverBy": `styles_coverBy__hBpYw`
};
export default ___CSS_LOADER_EXPORT___;
