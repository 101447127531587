import { Grid, Typography } from '@mui/material';
import React from 'react';
import { type Slide } from '../../_data';
import { EditableField } from '../../components/EditableField';
import ModelSetSlideTable from '../../components/ModelSetSlideTable';
import { getSlideField } from '../../utils';
import Footer from '../Footer';
export type Slide7ContentType = [
  {
    field: 'title';
    label: string;
    value: string;
    type: 'text';
  },
  {
    field: 'modelSet';
    label: string;
    value: string;
    type: 'modelSet';
  },
];

export const Slide7Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide7ContentType>;
    onChangeSlide: (
      slide: Slide<Slide7ContentType>,
      content: Slide7ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        direction="row"
        sx={{
          padding: '12px 12px 0 12px',
          height: '100%',
          width: '100%',
        }}
      >
        <Grid
          container
          gap={2}
          sx={{
            width: '100%',
            height: 'calc(100% - 142px)',
          }}
        >
          <Grid
            item
            sx={{
              textAlign: 'left',
              fontFamily: 'roboto',
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#004F7E',
            }}
          >
            <EditableField
              field={'title'}
              slide={slide}
              onSlideContentChange={onChangeSlide}
            />
          </Grid>
          <Grid item xs={12}>
            <ModelSetField
              slide={slide}
              item={getSlideField(slide, 'modelSet')?.value}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            padding: '8px 0',
            height: '90px',
            backgroundColor: '#fff',
          }}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: '12px',
                color: '#7D7D7D',
                fontWeight: '400',
                marginLeft: '12px',
                fontFamily: 'roboto',
              }}
            >
              * Based on stated maximum fees – may be lower based on any advisor
              exceptions or breakpoints AssetMark US/Global Market Blend is the
              default option for Core exposure in cases where an adviser has
              identified cost as a factor in the decision-making process and to
              the extent that an adviser is not seeking tactical,
              actively-managed, factor-based or socially-focused Core exposure.
              The consideration of AssetMark proprietary strategies, including
              strategies managed by AssetMark, during the investment consulting
              process creates a conflict of interest for AssetMark as AssetMark
              receives fees for the management of those strategies and funds.
            </Typography>
          </Grid>
        </Grid>
        <Footer
          numberPage={slide.slideTemplateId}
          text="For Financial Advisor use only"
        />
      </Grid>
    );
  },
);

export default Slide7Content;

const ModelSetField = ({ item }: any) => {
  return (
    <Grid item xs={12}>
      <ModelSetSlideTable
        slideMode
        action={{
          id: '',
          caseId: '',
          userId: '',
          milestoneId: '',
          versionName: '',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          draft: false,
          value: { model_set_holdings: [item] },
        }}
        modelSetName={item.model_set_name || ''}
        maxHeight={480}
        fontSize={12}
      />
    </Grid>
  );
};
