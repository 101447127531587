import React, { useEffect } from 'react';
import { styled } from '@mui/system';
import { useRef } from 'react';

interface TextareaProps {
  style?: React.CSSProperties;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<TextareaProps> = ({ style, value, handleChange }) => {
  const elementRef = useRef<HTMLTextAreaElement>(null);
  const autoGrow = () => {
    return () => {
      const element = elementRef.current;
      if (element) {
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight + 5}px`;
      }
    };
  };

  useEffect(() => {
    autoGrow()();
  }, [value]);

  return (
    <TextareaAutosize
      style={{ ...style }}
      ref={elementRef}
      onInput={autoGrow()}
      onChange={handleChange}
      value={value}
    />
  );
};

export default Textarea;

const TextareaAutosize = styled('textarea')({
  boxSizing: 'border-box',
  width: '100%',
  resize: 'none',
  border: '2px solid transparent',
  borderRadius: '4px',
  overflow: 'hidden',
  transition: 'border 0.3s',

  '&:focus': {
    outline: 'none',
    border: '2px solid #1BC29A',
  },

  '&:hover': {
    outline: 'none',
    border: '2px solid #1BC29A',
  },
});
