import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  NewUserStarterCardsContainer,
  NewUserStarterCardContainer,
  NewUserStarterCardsTitle,
  StartedActions,
  ActionsItemsText,
  ActionsItemsNumber,
  ActionsItemsContainer,
  ActionsItemsImage,
} from './styles';

interface NewUserStarterCardProps {
  title: string;
  titleIcon: string;
}

function NewUserStarterCards(
  props: NewUserStarterCardProps,
): React.JSX.Element {
  const navigate = useNavigate();
  const handleRunCarrousel = () => {
    const carrouselSlide = document.getElementById('carrousel');
    carrouselSlide
      ? (carrouselSlide.style.transform = 'translateX(-50%)')
      : null;
    carrouselSlide
      ? (carrouselSlide.style.transition =
          'transform 1.25s cubic-bezier(0.28, 0.01, 0.58, 1.19)')
      : null;
  };

  const handleStartTour = () => {
    window.pendo.showGuideById('WgEI2ancgdEnrAd2Q0eX1vqln9c');
  };

  const menuOptions = [
    {
      point: '1.',
      title: 'Take the tour & see what Sage can do',
      action: () => handleStartTour(),
    },
    {
      point: '2.',
      title: 'Connect client accounts to prime Sage’s engine',
      action: () => navigate('/integrations'),
    },
    {
      point: '3.',
      title: 'Begin harnessing the power of AI',
      action: () => handleRunCarrousel(),
    },
  ];

  return (
    <NewUserStarterCardsContainer>
      <NewUserStarterCardsTitle>
        <img src={props.titleIcon} alt="" />
        {props.title}
      </NewUserStarterCardsTitle>
      <Grid container>
        {menuOptions.map(({ title, action, point }) => (
          <Grid item xs={12} md={12 / menuOptions.length} p="3px" key={point}>
            <NewUserStarterCardContainer key={point}>
              <StartedActions onClick={() => action()}>
                <ActionsItemsContainer>
                  <ActionsItemsNumber>{point}</ActionsItemsNumber>
                  <ActionsItemsText>{title}</ActionsItemsText>
                </ActionsItemsContainer>
                <ActionsItemsImage />
              </StartedActions>
            </NewUserStarterCardContainer>
          </Grid>
        ))}
      </Grid>
    </NewUserStarterCardsContainer>
  );
}

export default NewUserStarterCards;
