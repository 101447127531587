import React from 'react';
import { TabsContainer } from './styles';
import { Tab } from '@mui/material';
import ClientIcon from '../../../static/icons/UserCircleOutlined';
import ProspectIcon from '../../../static/icons/UserSquareOutlined';

interface TabMenuProps {
  tabs: string[];
  activeTab: string;
  handleTabChange: (value: string) => void;
}

function TabMenu(props: TabMenuProps): React.JSX.Element {
  return (
    <TabsContainer
      value={props.tabs.findIndex(tab => tab === props.activeTab)}
      onChange={(ev, newTabIndex: number) => {
        props.handleTabChange(props.tabs[newTabIndex]);
      }}
    >
      {props.tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab}
          data-name={tab}
          iconPosition="start"
          icon={
            tab === 'Client' ? (
              <ClientIcon />
            ) : tab === 'Prospects' ? (
              <ProspectIcon />
            ) : (
              ''
            )
          }
        />
      ))}
    </TabsContainer>
  );
}

export default TabMenu;
