/* eslint-disable react/prop-types */
import * as React from 'react';

function SageAvatar(props: any): React.JSX.Element {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18" cy="18" r="17.5" fill="black" stroke="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.155 8V13.7422H22.1273L22.1448 13.7601L19.4033 16.568H25V22.3102H24.972L24.9895 22.3281L22.2361 25.1483L22.2396 25.1518L19.4589 28L19.4549 27.996H13.8517V22.2538H13.8545L16.6016 19.44V19.4301H16.6043L16.6046 19.4298L16.6033 19.4284H11L11 13.6862H11.0028L13.7568 10.8654V10.8621H13.7596L13.792 10.8289V10.8242H13.7966L16.5375 8.01681L16.5488 8.02839V8H22.155ZM19.3938 16.5902L19.3877 16.584L19.3938 16.5778V16.5902Z"
        fill="white"
      />
    </svg>
  );
}

const MemoNoData = React.memo(SageAvatar);
export default MemoNoData;
