import {
  columnsCalendarYearAsOf,
  getPortfolioStatisticsRows,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';

import { Grid } from '@mui/material';
import { SlideTitle } from 'features/slides';
import SummaryPerformanceTable from 'pages/Cases/components/SummaryPerformanceTable';
import { portfolioStatisticsChartFilter } from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';

export type CalendarYearReturnsTableProps = {
  readonly filteredPerformance: any;
};

export function CalendarYearReturnsTable({
  filteredPerformance,
}: CalendarYearReturnsTableProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>Calendar Year Returns</SlideTitle>
      </Grid>

      <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
        <SummaryPerformanceTable
          rows={getPortfolioStatisticsRows(
            portfolioStatisticsChartFilter({
              filteredPerformance,
              name: 'calendar_yr_returns',
            }),
          )}
          columns={columnsCalendarYearAsOf}
        />
      </Grid>
    </Grid>
  );
}
