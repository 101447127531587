// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slide__IXWg4 {
  background-color: var(--blue-dark);
  color: var(--white);
  padding: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
  background-size: auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  font-family: 'Inter', sans-serif;
}

.styles_logo__WOiWj {
  width: 200px;
}

.styles_title__mqKgw {
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.styles_subtitleContainer__GFIu9 {
  display: flex;
  flex-direction: column;
  color: var(--blue-light);
}

.styles_subtitle1__A4NJY {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.styles_subtitle2__xwgGK {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 1rem;
}

.styles_date__RMGBY {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Conversations/components/SlidesEditor/slides/Slide1/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,SAAS;EACT,qBAAqB;EACrB,iCAAiC;EACjC,4BAA4B;EAC5B,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".slide {\n  background-color: var(--blue-dark);\n  color: var(--white);\n  padding: 6rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  gap: 2rem;\n  background-size: auto;\n  background-position: right bottom;\n  background-repeat: no-repeat;\n  font-family: 'Inter', sans-serif;\n}\n\n.logo {\n  width: 200px;\n}\n\n.title {\n  font-size: 54px;\n  font-weight: 600;\n  margin-bottom: 1.5rem;\n}\n\n.subtitleContainer {\n  display: flex;\n  flex-direction: column;\n  color: var(--blue-light);\n}\n\n.subtitle1 {\n  font-size: 28px;\n  font-weight: 500;\n  margin-bottom: 0.6rem;\n}\n\n.subtitle2 {\n  font-size: 22px;\n  font-weight: 400;\n  margin-bottom: 1rem;\n}\n\n.date {\n  font-size: 16px;\n  font-weight: 400;\n  color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": `styles_slide__IXWg4`,
	"logo": `styles_logo__WOiWj`,
	"title": `styles_title__mqKgw`,
	"subtitleContainer": `styles_subtitleContainer__GFIu9`,
	"subtitle1": `styles_subtitle1__A4NJY`,
	"subtitle2": `styles_subtitle2__xwgGK`,
	"date": `styles_date__RMGBY`
};
export default ___CSS_LOADER_EXPORT___;
