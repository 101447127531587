export function getClientDisplayName(clientName: string): string {
  const [firstName = '', lastName = ''] = clientName.trim().split(/\s+/);

  return firstName && lastName ? clientName : firstName || lastName;
}

type DataItem = {
  value?: number;
  clientName: string;
};

type SortOrder = 'asc' | 'desc';

export const sortData = (
  dataToFilter: DataItem[] = [],
  sortOrder: SortOrder = 'asc',
  selectedSortOption: string,
): DataItem[] => {
  return dataToFilter.sort((a, b) => {
    const compare =
      selectedSortOption === 'Name'
        ? a.clientName.localeCompare(b.clientName, undefined, {
            sensitivity: 'base',
          })
        : (b.value || 0) - (a.value || 0);
    return sortOrder === 'asc' ? compare : -compare;
  });
};
