import { Button, Checkbox } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AcceptTerms,
  CheckboxContainer,
  ConditionItem,
  ConditionsList,
  ConditionsWrapper,
  ContentWrapper,
  TermsContainer,
  TermsHeader,
} from './styles';
import logo1 from '@images/LogoSvg.svg';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { useDispatch } from 'react-redux';
import { actions as profileActions } from '@features/Profile/slice';
import { SplashOVerlay } from '@layout/styles';
import WelcomeSplashScreen from '@pages/WelcomeSplashScreen';
import TermsOfUse from './termsAndCondition';
import { HTMLParser } from '@components/HTMLParser';
import { TIFIN_LEGAL_CONSTANT } from '@common/constant';

const HEADINGS = [
  'Agreement Conditions',
  'Eligibility',
  'Account Registration & Requirements',
  'Privacy',
  'Rights to Use Services',
  'Use of the Service & Ownership',
  'Prohibited Use',
  'Permitted Data Use',
  'Third Party Service Provider Software',
  'Third Party Content',
  'Compliance with Laws',
  'Fees and Taxes',
  'Term',
  'Termination',
  'Disclaimer of Warranties',
  'Indemnification',
  'Limitation of Liability',
  'Modifications, Suspension & Terminations of Service',
  'Noticies, Communications & Electronic Signatures',
  'General Provisions',
];

function TermsAndCondition(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const [isChecked, setIsChecked] = React.useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = React.useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = React.useState('');
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleCheckboxChange = () => {
    const updatedIsChecked = !isChecked;
    setIsChecked(updatedIsChecked);
    setIsTermsAccepted(updatedIsChecked ? 'true' : 'false');
  };

  const handleScroll = () => {
    if (divRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;
      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;
      setIsAtEnd(scrollPercentage >= 95);
    }
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const { refetch: termsAndConditionApi } = useRESTQuery(
    ['termsAndCondition'],
    {
      endpoint: 'user/terms',
      method: 'PATCH',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
      body: {
        termsAndCondition: isTermsAccepted,
      },
    },
    {
      select: response => response,
      onSuccess: () => {
        dispatch(
          profileActions.updateTermsAndConditionState({
            termsAndConditionsAcceptedAt: isTermsAccepted,
          }),
        );
      },
      onError: error => {
        console.log(error);
      },
    },
  );

  const handleRedirect = () => {
    termsAndConditionApi();
    localStorage.setItem('welcome-state', '1');
    setShowWelcomeMessage(true);
  };

  const welcomeCallback = () => {
    localStorage.setItem('welcome-state', '0');
    navigate('/');
  };

  return (
    <>
      {showWelcomeMessage ? (
        <SplashOVerlay
          style={{
            width: '100%',
            position: 'absolute',
            opacity: 1,
            zIndex: 99,
          }}
        >
          <WelcomeSplashScreen callBack={welcomeCallback} />
        </SplashOVerlay>
      ) : (
        <>
          <TermsContainer>
            <TermsHeader>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={logo1}
                  alt="Sage logo"
                  style={{ width: '136px', height: '40px' }}
                />
              </div>
              <div
                style={{
                  marginLeft: '10px',
                  borderLeft: '2px solid #000',
                  height: '20px',
                  margin: '0 10px',
                }}
              ></div>
              Terms of Use
            </TermsHeader>
            <ConditionsWrapper>
              <ConditionsList>
                {HEADINGS.map((item, index) => {
                  return (
                    <ConditionItem key={index}>
                      {index + 1}. {item}
                    </ConditionItem>
                  );
                })}
              </ConditionsList>
              <ContentWrapper ref={divRef}>
                <HTMLParser content={TermsOfUse} />
              </ContentWrapper>
            </ConditionsWrapper>
          </TermsContainer>

          <CheckboxContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                color="primary"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={!isAtEnd}
              />
              <AcceptTerms>
                By checking this box, I acknowledge that I have read and agree
                to the{' '}
                <a
                  href={TIFIN_LEGAL_CONSTANT.TERMS_AND_CONDITIONS}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  href={TIFIN_LEGAL_CONSTANT.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </AcceptTerms>
            </div>
            <Button
              variant="contained"
              disabled={!isChecked}
              style={{
                marginTop: '10px',
                padding: '8px',
                backgroundColor: isChecked ? '#000' : '#ccc',
                color: '#fff',
                border: 'none',
                borderRadius: '20px',
                minWidth: '110px',
                textTransform: 'none',
                fontSize: '14px',
                height: '35px',
                cursor: isChecked ? 'pointer' : 'not-allowed',
              }}
              onClick={handleRedirect}
            >
              Continue {'>'}
            </Button>
          </CheckboxContainer>
        </>
      )}
    </>
  );
}

export default TermsAndCondition;
