import { useMutation } from 'react-query';
import { useFetchMutation, useFetchQuery } from '../../../../helpers/api';
import { getLastActionId } from 'pages/Cases/helpers/misc';
import { parseCommentariesToPoo } from 'pages/Conversations/helpers/poo';

type Poo = {
  version: string;
  type: string;
  slides: {
    templateId: string;
    slideIndex: number;
    uiComponentId: string;
    data: {
      type: string;
      label: string;
      name: string;
      value: any;
    }[];
  }[];
};

export type PooCommentaries = {
  generatedCommentaries: Record<string, any>;
  model_set_holdings: Array<{
    metadata: {
      objective: string;
      asset_size: number;
      taxable_status: string;
    };
    model_set_name: string;
    portfolio_options: Array<{
      option: number;
      is_final: boolean;
      portfolios: Array<{
        holdings: Array<{
          weight: number;
          profile: number | null;
          model_id: string;
          strategy: string;
          strategist?: string;
          description?: string;
          main_purpose?: string;
          platform_fee?: number;
          category_role: string;
          implementation?: string;
          full_model_name?: string;
          investment_minimum?: number;
        }>;
        risk_profile: number;
        portfolio_name: string;
      }>;
    }>;
  }>;
};

export type AppendedPooCommentaries = {
  basePresentation: Poo;
  generatedCommentaries: Record<string, any>;
  model_set_holdings: Array<{
    metadata: {
      objective: string;
      asset_size: number;
      taxable_status: string;
    };
    model_set_name: string;
    portfolio_options: Array<{
      option: number;
      is_final: boolean;
      portfolios: Array<{
        holdings: Array<{
          weight: number;
          profile: number | null;
          model_id: string;
          strategy: string;
          strategist?: string;
          description?: string;
          main_purpose?: string;
          platform_fee?: number;
          category_role: string;
          implementation?: string;
          full_model_name?: string;
          investment_minimum?: number;
        }>;
        risk_profile: number;
        portfolio_name: string;
      }>;
    }>;
  }>;
};

export type UseCreatePooArguments = {
  caseId: string;
  caseName: string;
  pooMilestoneId: string;
  versionName: string;
  portfolioConstructionId: string;
  presentationId?: string;
  consultFormId?: string;
  filteredModelSetNames?: string[];
  type: string;
  successToastMessage: string;
};

type UseCreatePooReturn = {
  createPoo: (data: UseCreatePooArguments) => Promise<any>;
};

export const useCreatePoo = (): UseCreatePooReturn => {
  const { fetchQuery } = useFetchQuery();
  const { fetchMutation } = useFetchMutation();

  const { mutateAsync } = useMutation((data: UseCreatePooArguments) => {
    return Promise.resolve()
      .then(() => getPooCommentaries(fetchQuery, data))
      .then(commentaries => parseCommentariesToPoo(data, commentaries.data))
      .then(poo => createPooAction(fetchMutation, data, poo))
      .then(() => getLastPooActionId(fetchQuery, data));
  });

  return { createPoo: mutateAsync };
};

const getPooCommentaries = (fetchQuery: any, data: UseCreatePooArguments) => {
  if (data.type === 'appended') {
    const filteredModelSetsQuery = data.filteredModelSetNames
      ? data.filteredModelSetNames
          .map(name => `filteredModelSetNames=${encodeURIComponent(name)}`)
          .join('&')
      : '';
    if (data.presentationId === 'none') {
      return fetchQuery({
        path: `/v2/cases/${data.caseId}/milestones/poo/generatedCommentaries?portfolioConstructionId=${data.portfolioConstructionId}&${filteredModelSetsQuery}&type=${data.type}`,
      })();
    } else {
      return fetchQuery({
        path: `/v2/cases/${data.caseId}/milestones/poo/generatedCommentaries?presentationId=${data.presentationId}&portfolioConstructionId=${data.portfolioConstructionId}&${filteredModelSetsQuery}&type=${data.type}`,
      })();
    }
  } else if (data.type === 'initial') {
    return fetchQuery({
      path: `/v2/cases/${data.caseId}/milestones/poo/generatedCommentaries?portfolioConstructionId=${data.portfolioConstructionId}&consultFormId=${data.consultFormId}&type=${data.type}`,
    })();
  } else if (data.type === 'final') {
    if (data.presentationId === 'none') {
      return fetchQuery({
        path: `/v2/cases/${data.caseId}/milestones/poo/generatedCommentaries?portfolioConstructionId=${data.portfolioConstructionId}&type=${data.type}`,
      })();
    } else {
      return fetchQuery({
        path: `/v2/cases/${data.caseId}/milestones/poo/generatedCommentaries?presentationId=${data.presentationId}&portfolioConstructionId=${data.portfolioConstructionId}&type=${data.type}`,
      })();
    }
  } else if (data.type === 'premium_report') {
    return fetchQuery({
      path: `/v2/cases/${data.caseId}/milestones/finalPremiumReport/generatedCommentaries?portfolioConstructionId=${data.portfolioConstructionId}`,
    })();
  }
};

const createPooAction = (
  fetchMutation: any,
  data: UseCreatePooArguments,
  poo: Poo,
) =>
  fetchMutation({
    path: `/v2/cases/${data.caseId}/milestones/actions`,
    method: 'POST',
    body: {
      caseId: data.caseId,
      milestoneId: data.pooMilestoneId,
      versionName: data.versionName,
      value: poo,
      isDraft: true,
    },
    successToastMessage: data.successToastMessage,
  });

const getLastPooActionId = (fetchQuery: any, data: UseCreatePooArguments) =>
  fetchQuery({ path: `v2/ocios/cases/${data.caseId}` })().then(response =>
    getLastActionId(response.data, 'presentation_of_options'),
  );
