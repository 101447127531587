import CircleIcon from '@mui/icons-material/Circle';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
interface SummaryStatisticsScatterChartProps {
  data: Array<{
    modelSetName: string;
    displayPortfolioName?: string;
    displayBenchmarkName?: string;
    data: Record<string, number>;
  }>;
  xValue: string;
  yValue: string;
  name: string;
  height?: number;
}

const OPTION1_COLORS = [
  '#04ACD7', // Blue
  '#004F7E', // Dark Blue
  '#003057', // Navy Blue
  '#00CFB4', // Teal
  '#8CAC89', // Sage Green
  '#00A892', // Green
];

const OPTION2_COLORS = [
  '#F4AF00', // Yellow
  '#E87722', // Orange
  '#AF0737', // Red
  '#8A1B61', // Deep Red
  '#CF9400', // Dark Yellow
  '#C2631C', // Dark Orange
];

export const SummaryStatisticsScatterChart = ({
  data,
  xValue,
  yValue,
  name,
  height = 400,
}: SummaryStatisticsScatterChartProps): JSX.Element => {
  const formattedData = data.map((item, index) => {
    const isBenchmark = index === data.length - 1;
    const optionNumber = item.modelSetName.includes('Opt1') ? 1 : 2;
    const colorIndex =
      index %
      (optionNumber === 1 ? OPTION1_COLORS.length : OPTION2_COLORS.length);
    const color = isBenchmark
      ? '#000000'
      : optionNumber === 1
        ? OPTION1_COLORS[colorIndex]
        : OPTION2_COLORS[colorIndex];

    return {
      ...item.data,
      modelSetName: item.modelSetName,
      displayPortfolioName: item.displayPortfolioName,
      displayBenchmarkName: item.displayBenchmarkName,
      fill: color,
      shape: isBenchmark ? 'diamond' : optionNumber === 1 ? 'square' : 'circle',
    };
  });

  const minYValue = Math.min(...formattedData.map(item => item[yValue]));

  const getAxisLabel = (value: string) => {
    switch (value) {
      case 'cagr':
        return 'Annualized Return';
      case 'standard_deviation':
        return 'Standard Deviation';
      case 'max_drawdown':
        return 'Maximum Drawdown';
      default:
        return value;
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" margin={2}>
      <Typography>{name}</Typography>
      <ResponsiveContainer width="100%" height={height}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 10,
            left: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xValue}
            type="number"
            unit="%"
            name={getAxisLabel(xValue)}
            style={{
              fontSize: '14px',
              marginBottom: '10px',
            }}
            label={{
              value: getAxisLabel(xValue),
              position: 'insideBottom',
              offset: -10,
              style: {
                textAnchor: 'middle',
              },
            }}
          />
          <YAxis
            dataKey={yValue}
            type="number"
            unit="%"
            name={getAxisLabel(yValue)}
            domain={[minYValue, 'auto']}
            style={{
              fontSize: '14px',
            }}
            label={{
              value: 'Return',
              angle: -90,
              position: 'insideLeft',
              style: {
                textAnchor: 'middle',
              },
            }}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={<CustomTooltip />}
          />
          <Legend content={props => renderLegend(props, data)} />
          {data.map((item, index) => (
            <Scatter
              key={item.modelSetName}
              data={[formattedData[index]]}
              name={item.modelSetName}
              fill={formattedData[index].fill}
              shape={formattedData[index].shape as any}
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </Grid>
  );
};

const renderLegend = (props, data) => {
  const { payload } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mt={3}
    >
      {payload.map((entry, index) => {
        const isBenchmark = index === payload.length - 1;
        const item = data.find(item => item.modelSetName === entry.value);
        const optionNumber = item?.modelSetName?.includes('Opt1') ? 1 : 2;
        const colorIndex =
          index %
          (optionNumber === 1 ? OPTION1_COLORS.length : OPTION2_COLORS.length);
        const color = isBenchmark
          ? '#000000'
          : optionNumber === 1
            ? OPTION1_COLORS[colorIndex]
            : OPTION2_COLORS[colorIndex];

        return (
          <Grid
            item
            key={`item-${index}`}
            mr={2}
            display="flex"
            alignItems="center"
          >
            <CircleIcon
              sx={{
                color,
                width: '12px',
                height: '12px',
                marginRight: '4px',
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                color,
              }}
            >
              {item?.displayPortfolioName ||
                item?.displayBenchmarkName ||
                entry.value}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
const CustomTooltip = (props: any) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          backgroundColor: 'white',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          maxWidth: '400px',
        }}
      >
        <Grid
          item
          sx={{
            paddingBottom: '5px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: payload[0].payload.fill,
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            {payload[0].payload.modelSetName}
          </Typography>
        </Grid>
        {payload.map((entry, index) => (
          <Grid
            item
            key={`item-${index}`}
            mr={2}
            display="flex"
            alignItems="center"
            textAlign="left"
            sx={{
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                textAlign: 'left',
              }}
            >
              {`${entry.name} : ${entry.value}%`}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  }
  return null;
};
