// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_panel__6XG9b {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 10px 10px 20px 10px;
  background-color: #efefef;
  border: 2px solid #ededee;
  margin-left: auto;
  z-index: 10;
}

.styles_panel__6XG9b[aria-hidden='true'] {
  width: 0;
  padding: 0;
}

.styles_item__CJzUm {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.styles_number__\\+O8Uf {
  font-size: 10px;
  color: #535256;
}

.styles_button__992Ur {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 0;
}

.styles_button__992Ur[aria-current='true'] {
  outline: 2px solid #00c3de;
}

.styles_viewportMini__4S\\+EK {
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #000;
  width: 1280px;
  height: 720px;
  aspect-ratio: 16/9;
  transform: scale(0.125);
  transform-origin: top left;
  margin-right: calc(calc(1280px - calc(1280px * 0.125)) * -1);
  margin-bottom: calc(calc(720px - calc(720px * 0.125)) * -1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Conversations/components/SlidesEditor/components/NavPanel/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;EAC5B,yBAAyB;EACzB,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,SAAS;EACT,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,6BAA6B;EAC7B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;EACpB,yBAAiB;UAAjB,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,0BAA0B;EAC1B,4DAA4D;EAC5D,2DAA2D;AAC7D","sourcesContent":[".panel {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  overflow-x: hidden;\n  overflow-y: auto;\n  height: 100%;\n  padding: 10px 10px 20px 10px;\n  background-color: #efefef;\n  border: 2px solid #ededee;\n  margin-left: auto;\n  z-index: 10;\n}\n\n.panel[aria-hidden='true'] {\n  width: 0;\n  padding: 0;\n}\n\n.item {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n}\n\n.number {\n  font-size: 10px;\n  color: #535256;\n}\n\n.button {\n  display: block;\n  padding: 0;\n  margin: 0;\n  border: 0;\n  outline: none;\n  appearance: none;\n  background-color: transparent;\n  cursor: pointer;\n  flex-shrink: 0;\n}\n\n.button[aria-current='true'] {\n  outline: 2px solid #00c3de;\n}\n\n.viewportMini {\n  pointer-events: none;\n  user-select: none;\n  box-sizing: border-box;\n  background-color: #fff;\n  border: 1px solid #000;\n  width: 1280px;\n  height: 720px;\n  aspect-ratio: 16/9;\n  transform: scale(0.125);\n  transform-origin: top left;\n  margin-right: calc(calc(1280px - calc(1280px * 0.125)) * -1);\n  margin-bottom: calc(calc(720px - calc(720px * 0.125)) * -1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `styles_panel__6XG9b`,
	"item": `styles_item__CJzUm`,
	"number": `styles_number__+O8Uf`,
	"button": `styles_button__992Ur`,
	"viewportMini": `styles_viewportMini__4S+EK`
};
export default ___CSS_LOADER_EXPORT___;
