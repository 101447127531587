import { ReactElement } from 'react';
import Slide1Content from './slides/Slide1';
import Slide2Content from './slides/Slide2';
import Slide3Content from './slides/Slide3';
import Slide4Content from './slides/Slide4';
import Slide5Content from './slides/Slide5';
import Slide6Content from './slides/Slide6';
import Slide7Content from './slides/Slide7';
import Slide8Content from './slides/Slide8';
import Slide9Content from './slides/Slide9';
import Slide10Content from './slides/slide10';
export type Slide<T> = {
  id: number;
  content: T;
  slideTemplateId?: string;
  slideTemplate?: ReactElement;
  pptTemplateId?: string;
};

export const slidesComponents: Record<
  number,
  (props: {
    slide: Slide<any>;
    onChangeSlide: (slide: Slide<any>, content: any) => void;
  }) => React.ReactNode
> = {
  0: Slide1Content,
  1: Slide2Content,
  2: Slide3Content,
  3: Slide4Content,
  4: Slide5Content,
  5: Slide6Content,
  6: Slide7Content,
  7: Slide8Content,
  8: Slide9Content,
  9: Slide10Content,
};
