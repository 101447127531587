import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const InputWeight: React.FC<{
  weight?: number;
  setWeight: (weight: number) => void;
}> = ({ weight = 0, setWeight }) => {
  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numValue = Number(e.target.value || 0);
    setWeight(Math.max(0, Math.min(numValue, 100)));
  };

  return (
    <div>
      <InputLabel
        htmlFor="weight"
        sx={{ fontSize: 14, color: '#282829', marginBottom: '4px' }}
      >
        Weight as Percentage
      </InputLabel>
      <TextField
        name="weight"
        id="weight"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onChange={handleWeightChange}
        value={weight}
        placeholder="Weight"
        variant="outlined"
        size="small"
        fullWidth
      />
    </div>
  );
};

export default InputWeight;
