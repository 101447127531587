import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePostCaseActionArguments = {
  caseId: string;
  milestoneId: string;
  versionName: string;
  value: any;
  isDraft?: boolean;
  successToastMessage: string;
};

type UsePostCaseActionResult = {
  postCaseActionMutate: (data: UsePostCaseActionArguments) => void;
  isSuccess: boolean;
  data: any;
};

export const usePostCaseAction = (): UsePostCaseActionResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate, isSuccess, data } = useMutation(
    (data: UsePostCaseActionArguments) =>
      fetchMutation({
        path: `/v2/cases/${data.caseId}/milestones/actions`,
        method: 'POST',
        body: {
          caseId: data.caseId,
          milestoneId: data.milestoneId,
          versionName: data.versionName,
          value: data.value,
          isDraft: data.isDraft === false ? false : true,
        },
        invalidateQueries: [
          ['cases', data.caseId],
          ['action'],
          ['recentPortfolioConstructions'],
        ],
        successToastMessage: data.successToastMessage,
      }),
  );

  return { postCaseActionMutate: mutate, isSuccess, data };
};
