import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgressBar from 'components/CircularProgressBar';
import ModalButton from 'components/ModalButton';
import SelectComponent from 'components/Select';
import { getLastActionId, getMilestoneId } from 'pages/Cases/helpers/misc';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';
import { useGetCaseById } from 'pages/Cases/hooks/useGetCaseById';
import { Case } from 'pages/Cases/hooks/useGetCases';
import React, { useEffect, useState } from 'react';
import { useCreatePoo } from './useCreatePoo';
import { useCreatePremiumReport } from 'features/premium-report/api/use-create-premium-report';
import { useNavigate } from 'react-router-dom';
const typeOptions = [
  { key: 'initial', label: 'Initial' },
  { key: 'appended', label: 'Appended' },
  { key: 'final', label: 'Final' },
];

type PooType = 'initial' | 'appended' | 'final';

type InputDataModalProps = {
  caseId: string;
  isModalOpen: boolean;
  closeModal: () => void;
  handleCreatePooActionId: (id: string) => void;
  isPremiumReport: boolean;
};

const InputDataModal = ({
  caseId,
  isModalOpen,
  closeModal,
  handleCreatePooActionId,
  isPremiumReport,
}: InputDataModalProps): React.ReactNode => {
  const defaultVersionName = 'Presentation of options';
  const [type, setType] = useState<PooType>('initial');
  const [portfolioActionId, setPortfolioActionId] = useState<string>('');
  const [presentationOfOptionsActionId, setPresentationOfOptionsActionId] =
    useState<string>('');
  const [versionName, setVersionName] = useState<string>(defaultVersionName);
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const {
    caseData,
    isCaseLoading,
    error: caseError,
  } = useGetCaseById({ id: caseId! });
  const { action: portfolioAction, error: portfolioError } =
    useGetCaseActionById({
      caseId: caseData?.id || '',
      actionId: portfolioActionId,
    });
  const { createPoo } = useCreatePoo();
  const { createPremiumReport } = useCreatePremiumReport();
  const [checkedModelSets, setCheckedModelSets] = useState<{
    [key: string]: boolean;
  }>({});
  const navigate = useNavigate();
  useEffect(() => {
    if (caseData) {
      setPortfolioActionId(getLastActionId(caseData, 'portfolio_construction'));
      setPresentationOfOptionsActionId(
        getLastActionId(caseData, 'presentation_of_options'),
      );
      setVersionName(isPremiumReport ? 'Premium Report' : defaultVersionName);
    }
  }, [caseData?.id]);

  useEffect(() => {
    if (caseError || portfolioError) {
      closeModal();
    }
  }, [caseError, portfolioError]);

  const isValidForm = () => versionName !== '';

  const handleCheckedModelSetHoldings = (modelSetName: string) => {
    setCheckedModelSets(prev => ({
      ...prev,
      [modelSetName]: !prev[modelSetName],
    }));
  };

  const handleSubmit = () => {
    if (isValidForm()) {
      setIsMutating(true);

      if (isPremiumReport) {
        createPremiumReport({
          caseId,
          caseName: caseData?.name || '',
          versionName,
          pooMilestoneId: getMilestoneId(caseData!, 'premium_report'),
          portfolioConstructionId: portfolioActionId,
          type: 'premium_report',
          successToastMessage: 'Premium report version created successfully',
        })
          .then(lastPooActionId => {
            navigate(
              `/cases/${caseId}/portfolio-constructions/${lastPooActionId}/premium-report`,
            );
            setIsMutating(false);
            closeModal();
          })
          .catch(() => {
            setIsMutating(false);
          });
      } else if (type === 'appended') {
        const selectedModelSets = Object.entries(checkedModelSets)
          .filter(([, isChecked]) => isChecked)
          .map(([modelSetName]) => modelSetName);

        createPoo({
          caseId,
          caseName: caseData?.name || '',
          versionName,
          pooMilestoneId: getMilestoneId(caseData!, 'presentation_of_options'),
          presentationId: presentationOfOptionsActionId,
          filteredModelSetNames: selectedModelSets,
          portfolioConstructionId: portfolioAction.id,
          type,
          successToastMessage:
            'Appended presentation of options version created successfully',
        })
          .then(lastPooActionId => {
            handleCreatePooActionId(lastPooActionId);
            setIsMutating(false);
            closeModal();
          })
          .catch(() => {
            setIsMutating(false);
          });
      } else if (type === 'initial') {
        createPoo({
          caseId,
          caseName: caseData?.name || '',
          versionName,
          pooMilestoneId: getMilestoneId(caseData!, 'presentation_of_options'),
          consultFormId: getLastActionId(caseData!, 'consult_form'),
          portfolioConstructionId: portfolioAction.id,
          type,
          successToastMessage:
            'New presentation of options version created successfully',
        })
          .then(lastPooActionId => {
            handleCreatePooActionId(lastPooActionId);
            setIsMutating(false);
            closeModal();
          })
          .catch(() => {
            setIsMutating(false);
          });
      } else if (type === 'final') {
        createPoo({
          caseId,
          caseName: caseData?.name || '',
          versionName,
          pooMilestoneId: getMilestoneId(caseData!, 'presentation_of_options'),
          presentationId: presentationOfOptionsActionId,
          portfolioConstructionId: portfolioAction.id,
          type,
          successToastMessage:
            'Final presentation of options version created successfully',
        })
          .then(lastPooActionId => {
            handleCreatePooActionId(lastPooActionId);
            setIsMutating(false);
            closeModal();
          })
          .catch(() => {
            setIsMutating(false);
          });
      }
    }
  };

  const formatedModelSetHoldingNames = (name: string): string => {
    const dollarMatch = name.match(/\$(\d+)/);
    const dollarAmount = dollarMatch ? `$${dollarMatch[1]}` : '';

    if (name.toLowerCase().includes('minimum - qualified')) {
      return `${dollarAmount}Min_Qual`;
    } else if (name.toLowerCase().includes('non-qualified')) {
      return `${dollarAmount}NonQual`;
    }
    return name;
  };

  const getActionChecks = (modelSetHoldings: any[]) => {
    return modelSetHoldings.map((modelSetHolding: any) => {
      const formattedName = formatedModelSetHoldingNames(
        modelSetHolding.model_set_name,
      );
      return (
        <Grid
          container
          item
          xs={4}
          pb={1}
          key={modelSetHolding.model_set_name}
          alignItems="center"
        >
          <Checkbox
            checked={!!checkedModelSets[modelSetHolding.model_set_name]}
            onChange={() =>
              handleCheckedModelSetHoldings(modelSetHolding.model_set_name)
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>{formattedName}</Typography>
        </Grid>
      );
    });
  };

  return (
    <>
      {!caseError && !portfolioError && !isCaseLoading && (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={isModalOpen}
          onClose={closeModal}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={theme => ({
              width: 800,
              bgcolor: '#fff',
              boxShadow: theme.shadows[5],
              borderRadius: '4px',
            })}
          >
            <Header closeModal={closeModal} isPremiumReport={isPremiumReport} />

            {isCaseLoading && <CircularProgressBar size={40} />}

            {!isCaseLoading && (
              <Grid
                item
                container
                px={2}
                sx={{ border: '16px solid #f8f8f8', padding: '16px' }}
              >
                {!isPremiumReport && (
                  <Grid item xs={12} mb={2}>
                    <CustomLabel label="What type of presentation do you want to review?" />
                    <SelectComponent
                      id="type"
                      name="type"
                      value={type}
                      options={typeOptions}
                      handleChange={(name, value) => setType(value as PooType)}
                    />
                  </Grid>
                )}

                <Grid item xs={12} mb={2}>
                  <CustomLabel label="Select which version of Model Construction you want to use" />
                  <SelectComponent
                    id="portfolioActionId"
                    name="portfolioActionId"
                    value={portfolioActionId}
                    options={getPortfolioActionsOptions(caseData!)}
                    handleChange={(name, value) => setPortfolioActionId(value)}
                  />
                </Grid>

                {type === 'appended' && (
                  <>
                    <Grid item xs={12} mb={2}>
                      <CustomLabel label="Select which Model Set had changes and will be added to the presentation*" />
                      <Grid container direction="row">
                        {portfolioAction?.value?.model_set_holdings
                          ? getActionChecks(
                              portfolioAction.value.model_set_holdings,
                            )
                          : null}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} mb={2}>
                      <CustomLabel label="Select which presentation version will be changes be appended to*" />
                      <SelectComponent
                        id="presentationOfOptionsActionId"
                        name="presentationOfOptionsActionId"
                        value={presentationOfOptionsActionId}
                        options={[
                          {
                            key: 'none',
                            label: 'None (generate just the appended section)',
                          },
                          ...getPresentationOfOptionsActionsOptions(caseData!),
                        ]}
                        handleChange={(name, value) =>
                          setPresentationOfOptionsActionId(value)
                        }
                      />
                    </Grid>
                  </>
                )}

                {type === 'final' && (
                  <Grid item xs={12} mb={2}>
                    <CustomLabel label="Select which presentation you want to use" />
                    <SelectComponent
                      id="presentationOfOptionsActionId"
                      name="presentationOfOptionsActionId"
                      value={presentationOfOptionsActionId}
                      options={[
                        { key: 'none', label: 'None' },
                        ...getPresentationOfOptionsActionsOptions(caseData!),
                      ]}
                      handleChange={(name, value) =>
                        setPresentationOfOptionsActionId(value)
                      }
                    />
                  </Grid>
                )}

                <Grid container item xs={12}>
                  <CustomLabel
                    label={
                      isPremiumReport
                        ? 'Name of Premium Report version'
                        : 'Type a name for this presentation of options version'
                    }
                  />
                  <TextField
                    fullWidth
                    id="versionName"
                    size="small"
                    style={{ paddingTop: '5px' }}
                    value={versionName}
                    onChange={ev => setVersionName(ev.target.value)}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              gap={1}
              p={2}
              display="flex"
              justifyContent="space-between"
            >
              <ModalButton
                label="Cancel"
                variant="secondary"
                handleClick={closeModal}
              />
              <ModalButton
                label={
                  isPremiumReport ? 'Preview Report' : 'Review presentation'
                }
                loadingLabel={
                  isPremiumReport
                    ? 'Generating premium report...'
                    : 'Generating presentation...'
                }
                disabled={!isValidForm() || isMutating}
                handleClick={handleSubmit}
                isLoading={isMutating}
              />
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default InputDataModal;

// render helpers

const Header = ({
  closeModal,
  isPremiumReport,
}: {
  closeModal: () => void;
  isPremiumReport: boolean;
}): React.ReactNode => {
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={'space-between'}
      alignItems={'center'}
      p="8px 8px 8px 16px"
    >
      <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>
        {isPremiumReport
          ? 'Generate Premium Report'
          : 'Edit Presentation of Options'}
      </Typography>
      <Button
        style={{
          minWidth: 'unset',
          padding: '5px',
          marginLeft: 'auto',
        }}
        onClick={closeModal}
      >
        <CloseIcon />
      </Button>
    </Grid>
  );
};

const CustomLabel = ({ label }: any): React.ReactNode => {
  return (
    <Typography
      sx={{
        marginBottom: '4px',
        width: '100%',
        fontSize: '12px',
        fontWeight: 400,
        color: '#282829',
      }}
    >
      {label}
    </Typography>
  );
};

// data helpers

const getPresentationOfOptionsActions = (caseData: Case): any[] =>
  caseData?.milestones?.find(
    milestone => milestone.type === 'presentation_of_options',
  )?.actions || [];

const getPresentationOfOptionsActionsOptions = (caseData: Case): any[] => {
  const actions = getPresentationOfOptionsActions(caseData);
  return actions.map(action => ({
    key: action.id,
    label: action.versionName,
  }));
};

const getPortfolioActions = (caseData: Case): any[] =>
  caseData?.milestones?.find(
    milestone => milestone.type === 'portfolio_construction',
  )?.actions || [];

const getPortfolioActionsOptions = (caseData: Case): any[] =>
  getPortfolioActions(caseData).map(action => ({
    key: action.id,
    label: action.versionName,
  }));
