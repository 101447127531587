import { Box, Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import {
  useGetAdvisorSettings,
  UseGetAdvisorSettingsQueryResult,
} from './hooks/useGetAdvisorSettings';
import { useSaveAdvisorSettings } from './hooks/useSaveAdvisorSettings';
import Header from './components/Header';
import Logo from './components/Logo';
import Fees from './components/Fees';
import Disclosures from './components/Disclosures';
import EmailDisclosure from './components/EmailDisclosure';

const FirmProfile: FC = () => {
  const { advisorSettingsData, advisorSettingsLoading } =
    useGetAdvisorSettings();
  const {
    saveAdvisorSettingsMutate,
    saveAdvisorSettingsLoading,
    saveAdvisorSettingsSuccess,
  } = useSaveAdvisorSettings();

  const [formData, setFormData] =
    useState<UseGetAdvisorSettingsQueryResult['advisorSettingsData']>(
      advisorSettingsData,
    );
  const [newAdvisoryFee, setNewAdvisoryFee] = useState<number | undefined>(
    undefined,
  );
  const [isFormDataChanged, setIsFormDataChanged] = useState(false);
  const settingsEditable = true;

  const handleFieldChange = (
    field: keyof NonNullable<
      UseGetAdvisorSettingsQueryResult['advisorSettingsData']
    >,
    value: string | number,
  ) => {
    setFormData(prev => ({ ...prev, [field]: value }) as any);
  };

  const handleAdvisoryFeeChange = (value: number) => {
    setNewAdvisoryFee(value);
  };

  const handleSaveChanges = () => {
    const formDataToSave = { ...formData, advisoryFee: newAdvisoryFee };
    formDataToSave && saveAdvisorSettingsMutate(formDataToSave);
  };

  useEffect(() => {
    advisorSettingsData && setFormData(advisorSettingsData);
  }, [advisorSettingsData]);

  useEffect(() => {
    setIsFormDataChanged(
      formData !== advisorSettingsData ||
        (formData?.advisoryFee !== newAdvisoryFee &&
          newAdvisoryFee !== undefined),
    );
  }, [formData, newAdvisoryFee]);

  useEffect(() => {
    setIsFormDataChanged(!saveAdvisorSettingsSuccess);
  }, [saveAdvisorSettingsSuccess]);

  return (
    <Wrapper>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <Header
          onSaveChanges={handleSaveChanges}
          isFormDataChanged={isFormDataChanged}
          isSaveAdvisorSettingsLoading={saveAdvisorSettingsLoading}
        />
      </Grid>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <Logo
          logoSrc={formData?.logo}
          onLogoChange={value => handleFieldChange('logo', value)}
          isLoading={advisorSettingsLoading}
        />
      </Grid>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <Fees
          fee={formData?.advisoryFee}
          onAdvisoryFeeChange={handleAdvisoryFeeChange}
          editable={settingsEditable}
        />
      </Grid>
      <Grid item xs={12} sx={{ borderBottom: '1px solid #ECECEC' }}>
        <Disclosures
          disclosure={formData?.firmDisclosure}
          dutiesAndResponsibilities={formData?.firmDutiesAndResponsibilities}
          advisorFeeFullDisclosure={formData?.firmAdvisorFeeFullDisclosure}
          editable={settingsEditable}
          onDisclosureChange={value =>
            handleFieldChange('firmDisclosure', value)
          }
          onDutiesAndResponsibilitiesChange={value =>
            handleFieldChange('firmDutiesAndResponsibilities', value)
          }
          onAdvisorFeeFullDisclosureChange={value =>
            handleFieldChange('firmAdvisorFeeFullDisclosure', value)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <EmailDisclosure
          emailDisclosure={formData?.emailDisclosure}
          editable={settingsEditable}
          onEmailDisclosureChange={value =>
            handleFieldChange('emailDisclosure', value)
          }
        />
      </Grid>
    </Wrapper>
  );
};

export default FirmProfile;

const Wrapper = styled(Box)`
  background: #ffffff;
  padding: 16px;
`;
