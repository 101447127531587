import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { QueriesProps } from './types';

export const initialState: QueriesProps = {
  queries: [],
  loading: false,
};
const queriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    /* --- Chat History --- */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadQueriesRequest(state, action: PayloadAction<any>) {
      // state.queries = [];
    },
    loadQueriesFetch(state) {
      state.loading = true;
    },
    loadQueriesSuccess(state, action: any) {
      state.loading = false;
      state.queries = [...action.payload.queries];
    },
    loadQueriesError(state) {
      state.loading = false;
      state.queries = [];
    },
  },
});
export const { actions, reducer, name: sliceKey } = queriesSlice;
