// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slide__TRV-6 {
  background-color: #004f7e;
  color: #fff;
  padding: 0 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  font-family: 'Inter', sans-serif;
  height: 100%;
}

.styles_logo__-Vyxu {
  width: 200px;
  margin-bottom: 18px;
}

.styles_title__Dj7WY {
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 48px;
  max-width: 520px;
}

.styles_subtitleContainer__Natis {
  display: flex;
  flex-direction: column;
  color: #05acd8;
}

.styles_subtitle1__-GkyJ {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 12px;
}

.styles_subtitle2__ZW4PO {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 32px;
}

.styles_date__ea6N- {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/features/slides/components/cover-slide-template/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,iCAAiC;EACjC,4BAA4B;EAC5B,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".slide {\n  background-color: #004f7e;\n  color: #fff;\n  padding: 0 6rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  background-size: auto;\n  background-position: right bottom;\n  background-repeat: no-repeat;\n  font-family: 'Inter', sans-serif;\n  height: 100%;\n}\n\n.logo {\n  width: 200px;\n  margin-bottom: 18px;\n}\n\n.title {\n  font-size: 54px;\n  font-weight: 600;\n  margin-bottom: 48px;\n  max-width: 520px;\n}\n\n.subtitleContainer {\n  display: flex;\n  flex-direction: column;\n  color: #05acd8;\n}\n\n.subtitle1 {\n  font-size: 28px;\n  font-weight: 500;\n  margin-bottom: 12px;\n}\n\n.subtitle2 {\n  font-size: 22px;\n  font-weight: 400;\n  margin-bottom: 32px;\n}\n\n.date {\n  font-size: 16px;\n  font-weight: 400;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": `styles_slide__TRV-6`,
	"logo": `styles_logo__-Vyxu`,
	"title": `styles_title__Dj7WY`,
	"subtitleContainer": `styles_subtitleContainer__Natis`,
	"subtitle1": `styles_subtitle1__-GkyJ`,
	"subtitle2": `styles_subtitle2__ZW4PO`,
	"date": `styles_date__ea6N-`
};
export default ___CSS_LOADER_EXPORT___;
