import { handleError } from '@common/error';
import ApiInstance from '@instances/index';
import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { CLIENT_PER_PAGE } from '../constant';

const fetchClients = async ({ pageParam = 1, queryKey }) => {
  const [
    ,
    search = '',
    isProspect = false,
    viewpoint = false,
    allClients = false,
    notifications = '',
  ] = queryKey;

  const queryParams = new URLSearchParams({
    page: String(pageParam),
    limit: '18',
    ...(search && { search }),
    ...(isProspect && { prospect: 'true' }),
    ...(viewpoint && { viewpoint: 'true' }),
    ...(allClients && { allClients: 'true' }),
    ...(notifications === 'risk' && { risk: 'true' }),
    ...(notifications === 'opportunity' && { opportunity: 'true' }),
  });

  const { data } = await ApiInstance.get(`/v2/clients?${queryParams}`, {
    headers: {
      'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return data;
};

export const useGetClients = ({
  search = '',
  prospect = false,
  viewpoint = false,
  allClients = false,
  notifications = '',
}: {
  search: string;
  prospect?: boolean;
  viewpoint?: boolean;
  allClients?: boolean;
  notifications?: string;
}): any => {
  const dispatch = useDispatch();

  return useInfiniteQuery(
    ['get-clients', search, prospect, viewpoint, allClients, notifications],
    fetchClients,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalPages = Math.ceil(lastPage.totalCount / CLIENT_PER_PAGE);
        return pages.length < totalPages ? pages.length + 1 : undefined;
      },
      select: data => ({
        pages: selectClients(data),
        totalNotifications: selectTotalNotifications(data),
        pageParams: data.pageParams,
      }),
      onError: (error: any) => handleError(dispatch, error),
      keepPreviousData: true,
    },
  );
};

const selectClients = data =>
  data.pages.flatMap(page =>
    page.data.clients.map(client => ({
      id: client.id,
      clientName: `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim(),
      clientEmail: client.email ?? '',
      value: client.marketValue ?? 0,
      constraints: (client.restrictions ?? []).join(', '),
      accounts: client.accounts,
      restrictions: client.restrictions ?? [],
      notifications: selectNotifications(client),
      viewpoint: client.viewpoint,
      portfolioDiff: client.portfolioDiff,
      currentPortfolioRisk: client.currentPortfolioRisk,
      riskPreference: client.riskPreference,
      riskCapacity: client.riskCapacity,
      isProspect: client.isProspect,
    })),
  );

const selectTotalNotifications = data =>
  data.pages?.[0].data.totalNotifications || {
    risk: 0,
    opportunity: 0,
  };

const selectNotifications = client =>
  client.notifications.sort((a, b) => {
    const order = ['risk', 'opportunity'];
    return order.indexOf(a.category) - order.indexOf(b.category);
  });
