import axios from 'axios';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { settings } from 'config';
import { AdvisorSettings } from '../types';
import { getAdvisorSettingsKey } from './useGetAdvisorSettings';

type SaveAdvisorSettingsPostData = {
  settings: AdvisorSettings;
};

const saveAdvisorSettings = async (formData: AdvisorSettings) => {
  const token = localStorage.getItem('x-tifin-ai-token') || '';

  const url = `${settings.api.baseUrl}/v2/advisors/settings`;

  const data: SaveAdvisorSettingsPostData = {
    settings: formData,
  };

  const config = {
    headers: {
      Accept: 'application/json, text/plain, */*',
      'x-tifin-ai-auth': token,
    },
  };

  const response = await axios.post(url, data, config);
  return response.data;
};

type UseSaveAdvisorSettingsResult = {
  saveAdvisorSettingsMutate: UseMutateFunction<any, unknown, any, unknown>;
  saveAdvisorSettingsData: (formData: any) => Promise<any>;
  saveAdvisorSettingsLoading: boolean;
  saveAdvisorSettingsError: boolean;
  saveAdvisorSettingsSuccess: boolean;
};

export const useSaveAdvisorSettings = (): UseSaveAdvisorSettingsResult => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading, isError, isSuccess } = useMutation(
    saveAdvisorSettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getAdvisorSettingsKey);
      },
    },
  );

  return {
    saveAdvisorSettingsMutate: mutate,
    saveAdvisorSettingsData: data,
    saveAdvisorSettingsLoading: isLoading,
    saveAdvisorSettingsError: isError,
    saveAdvisorSettingsSuccess: isSuccess,
  };
};
