// store/configureStore.ts
import { configureStore } from '@reduxjs/toolkit';
import { settings } from 'config';
import createSagaMiddleware from 'redux-saga';
import { rootReducer, rootSaga } from './reducers';

export function configureAppStore(): any {
  // Create the store with saga middleware
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [
    sagaMiddleware,
    // To enable redux logs we must:
    // - Add this line to the beginning of this file: import logger from 'redux-logger';
    // - Uncomment the following line: settings.environment !== 'production' ? logger : undefined,
  ];
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }).prepend(...middleware),
    devTools: settings.environment !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
