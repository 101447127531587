import axios from 'axios';
import { useQuery } from 'react-query';
import { settings } from 'config';
import { AdvisorSettings } from '../types';

export type GetAdvisorSettingsResponse = {
  data: AdvisorSettings;
  message: string;
};

const getAdvisorSettings = async (): Promise<GetAdvisorSettingsResponse> => {
  const token = localStorage.getItem('x-tifin-ai-token') || '';
  const response = await axios.get(
    `${settings.api.baseUrl}/v2/advisors/settings`,
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'x-tifin-ai-auth': token,
      },
    },
  );
  return response.data;
};

export interface UseGetAdvisorSettingsQueryResult {
  advisorSettingsData: AdvisorSettings | undefined;
  advisorSettingsLoading: boolean;
  advisorSettingsError: boolean;
}

export const getAdvisorSettingsKey = 'getAdvisorSettings';

export const useGetAdvisorSettings = (): UseGetAdvisorSettingsQueryResult => {
  const { data, isLoading, isError } = useQuery(
    getAdvisorSettingsKey,
    getAdvisorSettings,
    {
      select: data => {
        return {
          ...data.data,
        };
      },
    },
  );

  return {
    advisorSettingsData: data,
    advisorSettingsLoading: isLoading,
    advisorSettingsError: isError,
  };
};
