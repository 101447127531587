import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const TabsContainer = styled(Tabs)`
  margin-bottom: 24px;
  border-bottom: 1px solid #e4e7ec;

  & .MuiTab-root {
    text-transform: none;
    padding: 8px 12px;
    min-width: auto;
  }
`;
