import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import IntegrationPage from '@pages/IntegrationPage';
import FirmProfile from './FirmProfile';
import TabsLayout from '../../components/TabsLayout/TabsLayout';

const settingsTabs = [
  {
    label: 'Firm profile',
    path: 'profile',
  },
  {
    label: 'Integrations',
    path: 'integrations',
  },
];

const SettingsPage: React.FC = () => {
  return (
    <Routes>
      <Route element={<TabsLayout tabs={settingsTabs} />}>
        <Route index element={<Navigate to="profile" replace />} />
        <Route path="profile" element={<FirmProfile />} />
        <Route path="integrations" element={<IntegrationPage />} />
      </Route>
    </Routes>
  );
};

export default SettingsPage;
