// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slides__vAoH3 {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: minmax(0, 1fr) auto;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/features/slides/components/slides/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,0CAA0C;EAC1C,uCAAuC;EACvC,yBAAyB;EACzB,WAAW;EACX,YAAY;AACd","sourcesContent":[".slides {\n  box-sizing: border-box;\n  display: grid;\n  grid-template-columns: minmax(0, 1fr) auto;\n  grid-template-rows: minmax(0, 1fr) auto;\n  background-color: #f5f5f5;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slides": `styles_slides__vAoH3`
};
export default ___CSS_LOADER_EXPORT___;
