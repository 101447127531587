import { Grid } from '@mui/material';
import { ChartBox, SlideTitle } from 'features/slides';
import { PortfolioStatisticsLineChart } from 'pages/Cases/components/PortfolioStatisticsLineChart';
import { portfolioStatisticsChartFilter } from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';

export type RollingThreeYearReturnsAndExcessReturnsProps = {
  readonly filteredPerformance: any;
};

export function RollingThreeYearReturnsAndExcessReturns({
  filteredPerformance,
}: RollingThreeYearReturnsAndExcessReturnsProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>Rolling 3Y Returns and Excess Returns</SlideTitle>
      </Grid>
      <ChartBox>
        <PortfolioStatisticsLineChart
          data={portfolioStatisticsChartFilter({
            filteredPerformance,
            name: 'rolling_three_year_stats',
            subName: '3_year_rolling_returns',
          })}
          formatChart={{
            unit: '%',
            decimals: 2,
            prefix: '',
            value: 'Return',
            label: 'Return',
          }}
          height={380}
        />
      </ChartBox>

      <ChartBox>
        <PortfolioStatisticsLineChart
          data={portfolioStatisticsChartFilter({
            filteredPerformance,
            name: 'rolling_three_year_stats',
            subName: '3_year_excess_returns',
          })}
          formatChart={{
            unit: '%',
            decimals: 2,
            prefix: '',
            value: 'Excess Return',
            label: 'Excess Return',
          }}
          height={380}
        />
      </ChartBox>
    </Grid>
  );
}
