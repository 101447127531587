import axios from 'axios';
import { useMutation } from 'react-query';
import { settings } from 'config';
import { useState } from 'react';

interface useCreateProposalMutationResult {
  createdProposalId: string;
  isCreateProposalLoading: boolean;
  createProposalMutate: (body: any) => void;
}

export const useCreateProposalMutation =
  (): useCreateProposalMutationResult => {
    const [createdProposalId, setCreatedProposalId] = useState<string>('');

    const fetchData = async ({ modelId, clientId, contentList }) => {
      const body = modelId
        ? { modelId, clientId, contentList }
        : { clientId, contentList };

      const response = await axios.post(
        `${settings.api.baseUrl}/client/proposal`,
        body,
        {
          headers: {
            Accept: 'application/json, text/plain, */*',
            'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token') || '',
          },
        },
      );
      return response.data;
    };

    const { isLoading, mutate } = useMutation(fetchData, {
      onSuccess: res => setCreatedProposalId(res.data),
    });

    return {
      createdProposalId,
      isCreateProposalLoading: isLoading,
      createProposalMutate: mutate,
    };
  };
