import { LoadingButton } from '@mui/lab';
import { Divider, Grid, Stack } from '@mui/material';
import BackButton from 'components/BackButton';
import GreyFrame from 'components/GrayFrame';
import TabsLayout from 'components/TabsLayout/TabsLayout';
import SummaryCharts from 'pages/Cases/components/SummaryCharts';
import SummaryHeader from 'pages/Cases/components/SummaryHeader';
import SaveAsNewVersion from 'pages/Cases/components/SummarySaveAsNewVersion';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';
import { useGetCaseById } from 'pages/Cases/hooks/useGetCaseById';
import { usePostCalculatePerformance } from 'pages/Cases/hooks/usePostCalculatePerformance';
import { usePutCaseActionById } from 'pages/Cases/hooks/usePutCaseActionById';
import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import PrimaryButton from '../../../../components/PrimaryButton';
import PerformanceReport from './PerformanceReport';

const summaryTabs = [
  {
    label: 'Summary Model Sets',
    path: '',
    validPatterns: [
      'cases/:caseId/portfolio-constructions/:portfolioId/summary',
    ],
    replaceLocation: true,
  },
  {
    label: 'Summary Performance Report',
    path: 'performance-report',
    replaceLocation: true,
  },
];

const Summary: React.FC = () => {
  const { actionId, caseId } = useParams();
  const { caseData } = useGetCaseById({ id: caseId! });
  const { action, isActionLoading, isActionFetching, getModelSetsBasicData } =
    useGetCaseActionById({
      actionId: actionId || '',
      caseId: caseId || '',
    });
  // The performance calculation takes a long time

  const { performance, isPerformanceLoading } = usePostCalculatePerformance({
    enabled: !!action && !isActionLoading && !isActionFetching,
    body: getModelSetsBasicData(),
  });
  const {
    putCaseActionByIdMutate,
    isSuccess,
    data,
    putCaseActionByIdIsLoading,
  } = usePutCaseActionById();

  const [isSaveAsNewVersionModalOpen, setIsSaveAsNewVersionModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const modelSetHoldings = action?.value?.model_set_holdings;
  const isDraft = action?.isDraft;

  const handleLockVersion = () => {
    putCaseActionByIdMutate({
      caseId: caseId!,
      actionId: actionId!,
      versionName: '',
      isDraft: false,
      value: action.value,
      successToastMessage: 'Version locked successfully',
    });
  };

  useEffect(() => {
    if (isSuccess && data?.data?.actionId) {
      const newPath = `/cases/${caseId}/portfolio-constructions/${data.data.actionId}/summary${location.search}`;
      navigate(newPath, { replace: true });
    }
  }, [isSuccess, data, caseId, location.search]);

  return (
    <>
      <Stack
        sx={{
          overflowY: 'auto',
          width: '100%',
          height: '100%',
          paddingBottom: '48px',
        }}
        direction="column"
      >
        <Grid container item sx={{ padding: '16px' }}>
          <Grid item xs={12} sx={{ marginBottom: '16px' }}>
            <BackButton name="Portfolio Construction" />
          </Grid>
          <GreyFrame>
            <Grid
              container
              item
              sx={{ padding: '16px' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <SummaryHeader caseData={caseData} action={action} />
              <Grid item display="flex" gap="16px">
                <Grid item>
                  <LoadingButton
                    variant="outlined"
                    onClick={handleLockVersion}
                    disabled={!isDraft}
                    loading={putCaseActionByIdIsLoading}
                    loadingPosition="center"
                    sx={{
                      height: '32px',
                      borderRadius: '20px',
                      fontSize: '12px',
                      background: '#ddd',
                      textTransform: 'none',
                      color: '#000',
                      fontWeight: '500',
                      border: '1px solid #ddd',
                      '&:hover': {
                        background: '#000',
                        color: '#fff',
                      },
                      '&:disabled': {
                        background: '#ddd',
                        color: '#888',
                      },
                      '& .MuiCircularProgress-root': {
                        color: '#000',
                      },
                      minWidth: '180px',
                    }}
                  >
                    {putCaseActionByIdIsLoading ? '' : 'Lock Model Set Version'}
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    text="Save as new version"
                    heightVariant="small"
                    handleClick={() => setIsSaveAsNewVersionModalOpen(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ padding: '16px 0 0 16px' }}>
              <Routes>
                <Route
                  element={
                    <TabsLayout
                      tabs={summaryTabs}
                      removeGreyFrame
                      paddingBottom="0px"
                      rightComponent={
                        <PrimaryButton
                          text="Preview report"
                          heightVariant="small"
                          handleClick={() => {
                            navigate(
                              `/cases/${caseId}/portfolio-constructions/${actionId}/performance-report`,
                            );
                          }}
                        />
                      }
                    />
                  }
                >
                  <Route
                    path="/"
                    element={
                      <SummaryCharts modelSetHoldings={modelSetHoldings} />
                    }
                  />
                  <Route
                    path="/performance-report"
                    element={
                      <PerformanceReport
                        performance={performance}
                        isPerformanceLoading={isPerformanceLoading}
                      />
                    }
                  />
                </Route>
              </Routes>
            </Grid>
          </GreyFrame>
        </Grid>
      </Stack>
      <SaveAsNewVersion
        action={action}
        caseId={caseId!}
        isOpen={isSaveAsNewVersionModalOpen}
        handleClose={() => setIsSaveAsNewVersionModalOpen(false)}
      />
    </>
  );
};

export default Summary;
