import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import BackButton from 'components/BackButton';

interface HeaderProps {
  name: string;
  handleClickBack: () => void;
}

const Header: React.FC<HeaderProps> = ({ name, handleClickBack }) => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          color: 'black',
        }}
        paddingInline={{ xs: '10px', sm: '20px' }}
      >
        <BackButton name="Models" handleClickBack={handleClickBack} />
        <Box
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {name}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default Header;
