import { useMutation } from 'react-query';
import { useFetchMutation, useFetchQuery } from '../../../helpers/api';
import { getLastActionId } from 'pages/Cases/helpers/misc';

type PremiumReport = {
  version: string;
  type: string;
  slides: {
    templateId: string;
    slideIndex: number;
    uiComponentId: string;
    data: {
      type: string;
      label: string;
      name: string;
      value: any;
    }[];
  }[];
};

export type PremiumReportCommentaries = {
  generatedCommentaries: Record<string, any>;
};

export type UseCreatePremiumReportArguments = {
  caseId: string;
  caseName: string;
  pooMilestoneId: string;
  versionName: string;
  portfolioConstructionId: string;
  presentationId?: string;
  consultFormId?: string;
  filteredModelSetNames?: string[];
  type: string;
  successToastMessage: string;
};

type UseCreatePremiumReportReturn = {
  createPremiumReport: (data: UseCreatePremiumReportArguments) => Promise<any>;
};

export const useCreatePremiumReport = (): UseCreatePremiumReportReturn => {
  const { fetchQuery } = useFetchQuery();
  const { fetchMutation } = useFetchMutation();

  const { mutateAsync } = useMutation(
    (data: UseCreatePremiumReportArguments) => {
      return Promise.resolve()
        .then(() => getPremiumReportCommentaries(fetchQuery, data))
        .then(premiumReport =>
          createPremiumReportAction(fetchMutation, data, premiumReport),
        )
        .then(() => getLastPremiumReportActionId(fetchQuery, data));
    },
  );

  return { createPremiumReport: mutateAsync };
};

const getPremiumReportCommentaries = (
  fetchQuery: any,
  data: UseCreatePremiumReportArguments,
) =>
  fetchQuery({
    path: `v2/cases/${data.caseId}/milestones/finalPremiumReport/generatedCommentaries?portfolioConstructionId=${data.portfolioConstructionId}`,
  })();

const createPremiumReportAction = (
  fetchMutation: any,
  data: UseCreatePremiumReportArguments,
  premiumReport: PremiumReport,
) =>
  fetchMutation({
    path: `/v2/cases/${data.caseId}/milestones/actions`,
    method: 'POST',
    body: {
      caseId: data.caseId,
      milestoneId: data.pooMilestoneId,
      portfolioConstructionId: data.portfolioConstructionId,
      versionName: data.versionName,
      value: premiumReport,
      isDraft: true,
    },
    successToastMessage: data.successToastMessage,
  });

const getLastPremiumReportActionId = (
  fetchQuery: any,
  data: UseCreatePremiumReportArguments,
) =>
  fetchQuery({ path: `v2/ocios/cases/${data.caseId}` })().then(response =>
    getLastActionId(response.data, 'premium_report'),
  );
