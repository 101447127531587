import React from 'react';
import {
  DataGrid,
  type GridColDef,
  type GridCellParams,
} from '@mui/x-data-grid';
import { Box, Grid, Typography } from '@mui/material';
import { ModelSetCharacteristics } from 'features/premium-report/api/types';
import { calculateAdditionalCharacteristics } from 'pages/Cases/hooks/usePostCalculatePortfolioCharacteristics';
interface ModelSetCharacteristicTableProps {
  data: ModelSetCharacteristics[];
  template: any;
  fontSize?: number;
}

const ModelSetCharacteristicTable: React.FC<
  ModelSetCharacteristicTableProps
> = ({ data, template, fontSize = 14 }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <DataGrid
          rows={rows(data, template)}
          columns={getColumns(rows(data, template), data, fontSize)}
          columnGroupingModel={getColumnGroupingModel(data[0], fontSize)}
          hideFooter={true}
          rowHeight={36}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getCellClassName={params => getCellClassName(params, data, template)}
          sx={{
            fontSize: fontSize,
            borderTop: 'none',
            borderRight: 'none',
            fontFamily: 'Inter',
            fontWeight: '500',
            maxHeight: '750px',
            '& *': {
              outline: 'none!important',
              cursor: 'default!important',
            },
            '& svg': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              borderBottom: 'none!important',
              whiteSpace: 'normal',
              lineHeight: '1.2',
              textAlign: 'center',
              justifyContent: 'center',
            },
            '& .MuiDataGrid-columnHeader': {
              borderTop: `1px solid rgba(224, 224, 224, 1)`,
              borderRight: `1px solid rgba(224, 224, 224, 1)`,
              backgroundColor: 'rgba(250, 250, 250, 1)',
              '&[data-field^="option"]': {
                backgroundColor: 'rgba(250, 250, 250, 1)',
              },
            },
            '& .MuiDataGrid-cell': {
              borderRight: `1px solid rgba(224, 224, 224, 1)`,
              whiteSpace: 'normal !important',
              lineHeight: '1.2',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&[data-field="metric"]': {
                textAlign: 'left',
                justifyContent: 'flex-start',
              },
            },
            '& .blue': {
              backgroundColor: '#F5F9FF !important',
              fontWeight: 'bold',
              '&.total-error': {
                color: '#f00 !important',
              },
            },
            '& .grey': {
              backgroundColor: '#F5F5F5 !important',
              fontWeight: 'bold',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

const getColumns = (
  rows: any,
  props: ModelSetCharacteristics[],
  fontSize: number,
) => {
  if (rows.length === 0) {
    return [];
  }
  const baseColumns: GridColDef[] = [
    {
      field: 'metric',
      headerName: '',
      flex: 1,
      minWidth: 200,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderHeader: () => (
        <Typography
          sx={{
            color: '#666666',
            fontSize: fontSize,
            fontFamily: 'Inter',
            whiteSpace: 'normal',
            lineHeight: '1.2',
            width: '100%',
            textAlign: 'left',
          }}
        >
          As of: {props[0].portfolio_date}
        </Typography>
      ),
    },
  ];
  const optionColumns = Object.keys(rows[0])
    .filter(key => key.startsWith('option'))
    .map(field => ({
      field,
      headerName: field.split('_')[1],
      sortable: false,
      flex: 0.25,
      minWidth: 25,
      headerAlign: 'center' as const,
      align: 'center' as const,
      editable: false,
      type: 'number' as const,
      renderHeader: () => (
        <Typography
          sx={{
            color: '#666666',
            fontSize: fontSize,
            fontFamily: 'Inter',
          }}
        >
          {field.split('_')[1]}
        </Typography>
      ),
    }));
  return [...baseColumns, ...optionColumns];
};

const getColumnGroupingModel = (modelSet: any, fontSize: number) =>
  modelSet?.portfolio_options.map((option: any) => ({
    groupId: `Option${option.option}`,
    description: modelSet.model_set_name + ' - Option ' + option.option,
    headerAlign: 'center',
    renderHeaderGroup: (params: any) => HeaderGroup(params, fontSize),
    children: option.portfolios.map(portfolio => ({
      field: `option${option.option}_${portfolio.risk_profile}`,
      headerName: portfolio.risk_profile.toString(),
      renderHeader: () => portfolio.risk_profile.toString(),
    })),
  }));
const HeaderGroup = (params: any, fontSize: number) => (
  <Box
    sx={{
      textAlign: 'center',
      width: '100%',
      color: '#666666',
      fontSize: fontSize,
      fontFamily: 'Roboto',
      whiteSpace: 'normal',
      lineHeight: '1.2',
    }}
  >
    <Typography
      variant="caption"
      component="div"
      sx={{ fontFamily: 'Roboto', fontSize: fontSize }}
    >
      {params.description}
    </Typography>
  </Box>
);

const rows = (
  portfolioCharacteristics: ModelSetCharacteristics[],
  template: any,
) =>
  template.rows.map((row, index) => {
    const baseId = typeof row === 'string' ? row : row.id;
    return {
      id: `${baseId}_${index}`,
      baseId,
      metric: typeof row === 'string' ? row : row.name,
      editable: false,
      ...Object.fromEntries(
        (Array.isArray(portfolioCharacteristics)
          ? portfolioCharacteristics
          : [portfolioCharacteristics]
        ).flatMap(option =>
          option.portfolio_options.flatMap(portfolioOption =>
            portfolioOption.portfolios.map(portfolio => {
              const calculate = calculateAdditionalCharacteristics(portfolio);
              return [
                `option${portfolioOption.option}_${portfolio.risk_profile}`,
                typeof calculate[baseId] === 'number'
                  ? Number(calculate[baseId]).toFixed(1)
                  : calculate[baseId],
              ];
            }),
          ),
        ),
      ),
    };
  });

const getCellClassName = (
  params: GridCellParams,
  props: ModelSetCharacteristics[],
  template: any,
): string => {
  const row = rows(props, template).find(r => r.id === params.id);
  const baseId = row?.baseId;

  if (
    baseId === 'yield' ||
    baseId === 'bonds' ||
    baseId === 'equity' ||
    baseId === 'total_expense' ||
    baseId === 'alts_exposure'
  ) {
    return 'blue';
  } else if (
    baseId === 'us_equity_style_exposure' ||
    baseId === 'of_equity' ||
    baseId === 'pct_of_bonds'
  ) {
    return 'grey';
  }
  return '';
};

export default ModelSetCharacteristicTable;
