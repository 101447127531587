import React from 'react';
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Grid,
} from '@mui/material';

interface CircularProgressBarProps {
  text?: string;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({ text }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid container position="relative" width={90} mb={2}>
        <CircularProgress
          variant="determinate"
          sx={theme => ({
            color: theme.palette.grey[200],
            ...theme.applyStyles('dark', {
              color: theme.palette.grey[800],
            }),
          })}
          size={90}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          color="inherit"
          sx={theme => ({
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
            ...theme.applyStyles('dark', {
              color: '#308fe8',
            }),
          })}
          size={90}
          thickness={4}
        />
      </Grid>
      <Box>
        <span>{text}</span>
      </Box>
    </Grid>
  );
};

export default CircularProgressBar;
