import React from 'react';
import ErrorScreen from '../ErrorScreen';

export function NotFoundPage(): React.JSX.Element {
  return (
    <ErrorScreen
      title="Something's wrong here"
      message="This is a 404 error which means either you've clicked on a bad link or entered an invalid URL."
      variant="not-found"
    />
  );
}
