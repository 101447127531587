import React, { useState, useEffect, useRef } from 'react';
import {
  CustomButton,
  // CustomButton,
  DocumentContainer,
  ImageWrapper,
  ProfileCardContainer,
  StyledCard,
  StyledInputLabel,
  SubmitButton,
  TextWrapper,
  UploadButton,
} from './styles';
import {
  // Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChangePasswordModal from './changePasswordModal';
import { useSelector } from 'react-redux';
import { getAuthDetails } from '@features/Profile/selector';
import { actions as profileActions } from '@features/Profile/slice';
import CustomizedSwitches from '@components/Switch';
import { useDispatch } from 'react-redux';
import documentImg from '@images/document.svg';
import download from '@images/Download.svg';
import { actions as globalActions } from 'app/features/Global/slice';
import {
  AcceptedFileTypeLabelDiv,
  FileUploadContainer,
  StyledModalInput,
  UploadFileLabel,
  UploadFileLabelContainer,
} from '@pages/SuperAdminPage/styles';
import media from '@images/media.svg';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { useMutation } from 'react-query';
import {
  downloadReport,
  updateAddress,
  updateExpertMode,
  uploadProfilePic,
} from './mutation';
import { downloadBlobDoc } from '@common/downloadBlobData';
import profileIcon from '@images/profileIcon.svg';
import CustomTooltip from '@components/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TIFIN_LEGAL_CONSTANT } from '@common/constant';

const touFile = [
  {
    id: 'terms',
    filename: 'Terms of Use',
    link: TIFIN_LEGAL_CONSTANT.TERMS_AND_CONDITIONS,
  },
  {
    id: 'privacy',
    filename: 'Privacy Policy',
    link: TIFIN_LEGAL_CONSTANT.PRIVACY_POLICY,
  },
];

function MyProfile(): React.JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const advisorDetails = useSelector(getAuthDetails);
  const dispatch = useDispatch();
  const { mutate: addressMutate, isLoading: addressLoader } =
    useMutation(updateAddress);
  const { mutate: downloadMutate } = useMutation(downloadReport);
  const { mutate: expertModeMutate } = useMutation(updateExpertMode);
  const { mutate: uploadProfilePicMutation, isLoading: profilePicLoading } =
    useMutation(uploadProfilePic);
  const streaming = localStorage.getItem('streaming') as string;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isStreaming, setIsStreaming] = useState(
    streaming === 'true' ? true : false,
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [address, setAddress] = useState<string>(advisorDetails?.address);
  const [city, setCity] = useState<string>(advisorDetails?.city);
  const [zipcode, setZipCode] = useState<string>(advisorDetails?.zipcode);
  const [state, setState] = useState<string>(advisorDetails?.state);
  const [documentData, setDocumentData] = useState<any>([]);

  useEffect(() => {
    setAddress(advisorDetails?.address);
    setCity(advisorDetails?.city);
    setZipCode(advisorDetails?.zipcode);
    setState(advisorDetails?.state);
  }, [advisorDetails]);

  useEffect(() => {
    profileDocumentApi();
  }, []);
  useEffect(() => {
    setAddress(advisorDetails.address || '');
    setCity(advisorDetails.city || '');
    setState(advisorDetails.state || '');
    setZipCode(advisorDetails.zipcode || '');
  }, [advisorDetails]);

  const updateProfilePic = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const formData = new FormData();
    if (logoFile) {
      formData.append('file', logoFile);
      uploadProfilePicMutation(formData, {
        onSuccess: (res: any) => {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: res?.message,
            }),
          );
          const token = localStorage.getItem('x-tifin-ai-token') as string;
          if (token) {
            dispatch(
              profileActions.userInfoRequest({
                token,
              }),
            );
          }
        },
        onError: (error: any) => {
          console.log('error', error);
        },
      });
    }
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const payload = {
      address,
      city,
      state,
      zipcode,
    };

    addressMutate(payload, {
      onSuccess: res => {
        dispatch(
          profileActions.updateAddress({
            address,
            zipcode,
            state,
            city,
          }),
        );
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
      onError: (error: any) => {
        console.log('error', error);
      },
    });
  };

  const { refetch: profileDocumentApi } = useRESTQuery(
    ['document'],
    {
      endpoint: `/documents`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      onSuccess: (response: any) => {
        if (response && response.data) {
          setDocumentData([...touFile, ...response.data]);
        }
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    expertModeMutate();

    dispatch(
      profileActions.updateUserExpertState({
        expertMode: event.target.checked,
      }),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStreaming = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsStreaming(event.target.checked);
    localStorage.setItem('streaming', event.target.checked ? 'true' : 'false');
  };

  const handleFileUpload = e => {
    const uploadedFile = e.target.files?.[0];

    if (uploadedFile) {
      // Getting the file extension
      const fileName = uploadedFile.name;
      const extension = fileName.split('.').pop()?.toLowerCase();

      // Checkimg if the file extension is allowed (.png or .jpg)
      if (extension && ['png', 'jpg'].includes(extension)) {
        setLogoFile(uploadedFile);

        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImageUrl(reader.result as string);
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        console.error('Invalid file type. Please select a .png or .jpg file.');
      }
    }
  };
  const handleDocument = (id: string, name: string, link?: string) => {
    if (link) {
      return window.open(link, '_blank')?.focus();
    }
    if (id === 'tou') {
      return window.open(`/${name}`, '_blank')?.focus();
    }
    downloadMutate(id, {
      onSuccess: res => {
        downloadBlobDoc(res, name);
      },
      onError: error => {
        console.log({ error });
      },
    });
  };

  const handleInputChange = setStateFunction => event => {
    setStateFunction(event.target.value);
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <ProfileCardContainer container justifyContent={'center'}>
      <Container maxWidth="md">
        <form>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid
              item
              xs={6}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Typography variant="subtitle1">Personal Information</Typography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <SubmitButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={addressLoader}
              >
                Save Changes
              </SubmitButton>
            </Grid>
          </Grid>
          <Divider style={{ margin: '10px 0px 20px' }} />
          <Grid container>
            <Grid
              container
              pt={1}
              pb={3}
              rowSpacing={2}
              columnSpacing={2} // Ensure this is on the outer Grid container
            >
              <Grid item xs={12} md={2} container justifyContent={'center'}>
                <StyledCard>
                  {uploadedImageUrl ? (
                    <ImageWrapper
                      src={uploadedImageUrl}
                      alt="Preview uploaded image"
                    />
                  ) : advisorDetails?.profilePicUrl ? (
                    <ImageWrapper
                      src={advisorDetails.profilePicUrl}
                      alt="Uploaded image"
                    />
                  ) : (
                    <img
                      src={profileIcon}
                      alt="Default profile image"
                      style={{ objectFit: 'cover' }}
                      width={'100%'}
                      height={'100%'}
                    />
                  )}
                </StyledCard>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={10}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <FileUploadContainer
                  item
                  sx={{
                    padding: '5px',
                    height: '140px',
                    width: '100%',
                  }}
                >
                  <UploadFileLabelContainer
                    htmlFor="uploadLogoInput"
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleUploadClick();
                      }
                    }}
                  >
                    <StyledModalInput
                      type="file"
                      id="uploadLogoInput"
                      inputProps={{ accept: '.png, .jpg' }}
                      onChange={e => handleFileUpload(e)}
                      ref={fileInputRef}
                    />
                    <img src={media} alt="Media image" />
                    <UploadFileLabel
                      style={{
                        fontSize: '14px',
                        padding: '0px',
                        whiteSpace: 'normal',
                      }}
                    >
                      Click to upload Profile Image or drag and drop
                    </UploadFileLabel>
                    <AcceptedFileTypeLabelDiv>
                      .jpg or .PNG
                    </AcceptedFileTypeLabelDiv>
                  </UploadFileLabelContainer>
                </FileUploadContainer>
              </Grid>
            </Grid>

            {
              <Grid item xs={12}>
                {logoFile && (
                  <UploadButton
                    variant="contained"
                    color="primary"
                    onClick={updateProfilePic}
                    disabled={profilePicLoading}
                  >
                    Upload
                  </UploadButton>
                )}
              </Grid>
            }
            <Grid
              item
              container
              xs={12}
              columnSpacing={2}
              rowGap={4}
              pt={4}
              pb={2}
            >
              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="firstName">
                  First Name*
                </StyledInputLabel>
                <TextField
                  variant="outlined"
                  id="firstName"
                  size={'small'}
                  fullWidth
                  disabled
                  required
                  value={advisorDetails?.firstName}
                  sx={{ background: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="lastName">
                  Last Name*
                </StyledInputLabel>
                <TextField
                  id="lastName"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  disabled
                  required
                  value={advisorDetails?.lastName}
                  sx={{ background: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="email">Email*</StyledInputLabel>
                <TextField
                  id="email"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  type="email"
                  disabled
                  required
                  value={advisorDetails?.email}
                  sx={{ background: '#fff' }}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>

              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="address">Address</StyledInputLabel>
                <TextField
                  id="address"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  placeholder={'Enter your address'}
                  required
                  value={address}
                  onChange={handleInputChange(setAddress)}
                  sx={{ background: '#fff' }}
                  autoComplete="street-address"
                  name="address"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="city">City</StyledInputLabel>
                <TextField
                  id="city"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  placeholder={'Enter your city'}
                  required
                  value={city}
                  onChange={handleInputChange(setCity)}
                  sx={{ background: '#fff' }}
                  autoComplete="address-level2"
                  name="city"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="state">State</StyledInputLabel>
                <TextField
                  id="state"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  placeholder={'Enter your state'}
                  required
                  value={state}
                  onChange={handleInputChange(setState)}
                  sx={{ background: '#fff' }}
                  autoComplete="address-level1"
                  name="state"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="zipcode">ZIP Code</StyledInputLabel>
                <TextField
                  id="zipcode"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  placeholder={'Enter your zip code'}
                  required
                  value={zipcode}
                  onChange={handleInputChange(setZipCode)}
                  sx={{ background: '#fff' }}
                  autoComplete="on"
                  name="zipcode"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledInputLabel htmlFor="firmName">
                  Firm Name
                </StyledInputLabel>
                <TextField
                  id="firmName"
                  variant="outlined"
                  fullWidth
                  size={'small'}
                  placeholder=""
                  disabled
                  required
                  value={advisorDetails?.advisoryFirmName}
                  sx={{ background: '#fff' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Grid item container rowGap={2} py={2}>
          <Grid item xs={12}>
            <CustomizedSwitches
              handleChange={handleChange}
              value={advisorDetails?.expertMode}
              text="Expert Mode"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSwitches
              handleChange={handleStreaming}
              value={isStreaming}
              text="Streaming"
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} direction={'column'} rowGap={2} py={2}>
          <Grid item>
            <Typography variant="h6">My Documents</Typography>
            <Divider style={{ margin: '10px 0 0 0' }} />
          </Grid>
          <Grid item container rowGap={2}>
            {documentData?.map((document: any, index: number) => (
              <DocumentContainer
                item
                container
                xs={12}
                key={index}
                alignItems={'center'}
                p={2}
              >
                <Grid item container>
                  <Grid
                    item
                    container
                    alignItems={'center'}
                    columnGap={2}
                    xs={12}
                    md={6}
                  >
                    <Grid item>
                      <img src={documentImg} alt="Document image" />
                    </Grid>
                    <Grid item>
                      {isMobile ? (
                        <CustomTooltip title={document.filename}>
                          <TextWrapper>{document.filename}</TextWrapper>
                        </CustomTooltip>
                      ) : (
                        <Typography variant="subtitle2">
                          {document.filename}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                  >
                    <CustomButton
                      color="primary"
                      sx={{
                        padding: !isMobile ? '8px 16px' : '8px 5px',
                        width: '120px',
                      }}
                      onClick={() =>
                        handleDocument(
                          document.id,
                          document.filename,
                          document?.link,
                        )
                      }
                    >
                      {document.link ? (
                        <>
                          <OpenInNewIcon fontSize="medium" />
                          <p style={{ marginLeft: '5px' }}>View</p>
                        </>
                      ) : (
                        <>
                          <img
                            src={download}
                            alt="Download image"
                            style={{ marginRight: '5px' }}
                          />
                          {'Download'}
                        </>
                      )}
                    </CustomButton>
                  </Grid>
                </Grid>
              </DocumentContainer>
            ))}
          </Grid>
        </Grid>

        <ChangePasswordModal open={showModal} onClose={handleCloseModal} />
      </Container>
    </ProfileCardContainer>
  );
}

export default MyProfile;
