import React, { useEffect, useRef } from 'react';

import {
  Stack,
  Paper,
  MenuList,
  Popper,
  Grow,
  ClickAwayListener,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  styled,
  InputLabel,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SponsorsCount } from 'pages/Models/types';

interface FilterMenuProps {
  activeChecks: SponsorsCount[];
  rows: any[];
  handleFilter: (id) => void;
}

// MarketPlace ToDo: mover esto a un componente genérico, solo si vamos bien de tiempo
const FilterMenu: React.FC<FilterMenuProps> = ({
  rows,
  activeChecks,
  handleFilter,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      !(
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      )
    ) {
      setOpen(false);
    }
  };

  const isChecked = (id: string) =>
    activeChecks.some(check => check.sponsorId === id);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Stack>
      <FilterButton
        ref={anchorRef}
        id="filter-button"
        aria-controls={open ? 'filter-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <FilterListIcon
          sx={{ width: '16px', height: '16px', marginRight: '5px' }}
        />
        Filter
      </FilterButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        hidden={rows.length === 0}
        sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'top-start' ? 'right top' : 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <FilterMenuList
                  autoFocusItem={open}
                  id="filter-menu"
                  aria-labelledby="filter-button"
                  title="Filtros"
                >
                  <div
                    style={{
                      padding: '16px 16px 3px 16px',
                    }}
                  >
                    <InputLabel
                      sx={{ color: '#000', fontSize: 10, fontWeight: 500 }}
                    >
                      MODEL SPONSORS
                    </InputLabel>
                  </div>
                  <div
                    style={{
                      maxHeight: 300,
                      overflow: 'auto',
                      padding: '0 16px 0px 16px',
                    }}
                  >
                    <FormGroup>
                      {rows.map(filtro => (
                        <FormControlLabelSponsor
                          key={filtro.sponsorId}
                          control={
                            <Checkbox
                              key={filtro.sponsorId}
                              id={`filter-menu-${filtro.sponsorId}`}
                              checked={isChecked(filtro.sponsorId)}
                              size="medium"
                            />
                          }
                          label={
                            <FilterLabelCheckbox
                              htmlFor={`filter-menu-${filtro.sponsorId}`}
                            >
                              {filtro.sponsorName +
                                ' (' +
                                filtro.modelCount +
                                ')'}
                            </FilterLabelCheckbox>
                          }
                          onChange={() => handleFilter(filtro.sponsorId)}
                        ></FormControlLabelSponsor>
                      ))}
                    </FormGroup>
                  </div>
                </FilterMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

export default FilterMenu;

const FilterButton = styled(IconButton)`
  padding: 7px;
  background: transparent;
  pointer-events: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  min-width: 82px;
`;

const FilterMenuList = styled(MenuList)`
  background-color: #fff;
`;

const FilterLabelCheckbox = styled(InputLabel)`
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  font-family: 'Inter';
  cursor: pointer;
`;

const FormControlLabelSponsor = styled(FormControlLabel)`
  span {
    border: 0;

    &:hover {
      background-color: transparent;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
