import React, { useEffect, useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Grid,
  InputLabel,
  Divider,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePostOcioAdmin } from '../hooks/usePostOcioAdmin';
import { usePostOcioUser } from '../hooks/usePostOcioUser';
import Select from '../../../components/Select';
import ModalButton from '../../../components/ModalButton';

interface AddOcioModalProps {
  isOpen: boolean;
  handleClose: () => void;
  advisoryFirmId: string;
}

const AddOcioModal = ({
  advisoryFirmId,
  isOpen,
  handleClose,
}: AddOcioModalProps): React.JSX.Element => {
  const { postOcioAdminMutate } = usePostOcioAdmin();
  const { postOcioUserMutate } = usePostOcioUser();

  const [userRole, setUserRole] = useState<'admin' | 'user'>('admin');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    setUserRole('admin');
    setFirstName('');
    setLastName('');
    setEmail('');
  }, [isOpen]);

  const roleOptions = [
    { key: 'admin', label: 'OCIO admin' },
    { key: 'user', label: 'OCIO user' },
  ];

  // event helpers

  const handleConfirm = () => {
    if (!firstName || !lastName || !email) {
      return;
    }
    const data = {
      firstName,
      lastName,
      email,
      advisoryFirmId,
      successToastMessage: `"${firstName} ${lastName}" has been created with OCIO ${userRole} role`,
    };
    if (userRole === 'admin') {
      postOcioAdminMutate(data);
    } else {
      postOcioUserMutate(data);
    }
    handleClose();
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={isOpen}
      sx={{
        display: 'flex',
        p: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={theme => ({
          width: 450,
          bgcolor: '#fff',
          boxShadow: theme.shadows[5],
          borderRadius: '4px',
        })}
      >
        <Box sx={{ padding: '16px' }}>
          <Grid
            item
            container
            xs={12}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={2}
          >
            <Typography variant="h6" component="div">
              Add OCIO users
            </Typography>
            <Button
              style={{
                minWidth: 'unset',
                padding: '5px',
                marginLeft: 'auto',
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          </Grid>

          <Grid item container xs={12} mb={2}>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 500 }}>Select user role</Typography>
            </Grid>
            <Grid item xs={8} justifyContent={'center'} alignSelf={'center'}>
              <Divider />
            </Grid>
          </Grid>

          <Grid item container xs={12} mb={3}>
            <Select
              id="userRole"
              name="userRole"
              value={userRole}
              options={roleOptions}
              handleChange={(key, value) =>
                setUserRole(value as 'admin' | 'user')
              }
            />
          </Grid>

          <Grid item container xs={12} mb={2}>
            <Grid item xs={5}>
              <Typography sx={{ fontWeight: 500 }}>
                OCIO {userRole} details
              </Typography>
            </Grid>
            <Grid item xs={7} justifyContent={'center'} alignSelf={'center'}>
              <Divider />
            </Grid>
          </Grid>

          <Grid item container xs={12} columnGap={1} mb={2}>
            <Grid item container xs={5.8}>
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <TextField
                fullWidth
                id="firstName"
                size="small"
                placeholder="Enter first name"
                style={{ paddingTop: '5px' }}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Grid>

            <Grid item container xs={5.8}>
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
              <TextField
                fullWidth
                id="lastName"
                size="small"
                placeholder="Enter last name"
                style={{ paddingTop: '5px' }}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} mb={4}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <TextField
              fullWidth
              id="email"
              size="small"
              placeholder="Enter email"
              style={{ paddingTop: '5px' }}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            gap={1}
          >
            <ModalButton
              label="Cancel"
              variant="secondary"
              handleClick={handleClose}
            />
            <ModalButton label="Confirm" handleClick={handleConfirm} />
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddOcioModal;
