import { Grid } from '@mui/material';
import styled from 'styled-components';

export const ClientCardContainer = styled(Grid)`
  position: relative;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  font-family: Inter, sans-serif;
  transition:
    box-shadow 0.25s,
    transform 0.25s;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  // card content

  & .card-content {
    min-height: 130px;
  }

  & .card-content-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.25s;
  }

  & .card-name {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .card-price {
    font-size: 16px;
    color: #666;
  }

  &:hover .card-content-inner {
    padding: 8px 16px;
    background: #000;
    border-radius: 24px;

    * {
      font-weight: normal;
      color: #fff;
    }
  }

  &:hover .card-content-inner:hover {
    background: #00c3de;
    * {
      color: #000;
    }
  }

  // card footer

  & .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ececec;
    font-size: 14px;
    font-weight: 500;
    color: #666;
  }

  // card type

  & .card-type {
    display: flex;
    align-items: center;
  }

  & .card-type-icon {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }

  // card content nba

  & .card-content-nba {
    display: flex;
    align-items: center;
  }

  & .card-content-nba-badges {
    list-style: none;
    display: flex;
    gap: 6px;
    margin: 0;
    padding: 0 6px 0 0;
  }

  // card hover content

  & .card-hover-content {
    position: absolute;
    top: 58px;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 130px;
    padding: 0 16px;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    animation: fadeOutWithTouch 0.5s forwards;
  }

  &:hover .card-hover-content {
    animation: fadeInWithTouch 0.5s forwards;
  }

  // card buttons

  & .card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-bottom: 12px;
  }

  & .card-button {
    display: flex;
    align-items: center;
    height: 36px;
    width: 33%;
    background: #000;
    border-radius: 18px;

    .card-button-icon {
      display: flex;
      align-items: center;
    }

    .card-button-text {
      display: none;
      font-size: 11px;
      text-transform: capitalize;
    }
  }

  & .card-button:hover {
    background: #00c3de;

    .card-button-icon {
      display: none;
    }

    .card-button-text {
      display: block;
    }
  }

  & .card-nba-promp-questions {
    position: relative;

    .promp-question-text {
      // This style is useful when the width of the card is small
      // and ellipses appear in the questions
      padding-right: 24px;
    }

    .card-nba-promp-questions-badges {
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: -1px;
      right: 0;
      pointer-events: none;
    }

    .card-nba-badge {
      margin: 7px 0;
      width: 14px;
      height: 14px;
      font-size: 11px;
    }
  }

  // card content and hover nba

  & .card-nba-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    color: #fff;

    &.risk {
      background: #f00;
    }

    &.opportunity {
      background: #7e19fe;
    }
  }
`;
