// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__sOOGq {
  padding: 32px !important;
}

.styles_title__CWVno {
  font-family: Roboto !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 100% !important;
  letter-spacing: 0% !important;
  color: #004f7e !important;
  margin-bottom: 16px !important;
}

.styles_subtitle__V2FEF {
  font-family: Roboto !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 100% !important;
  letter-spacing: 0% !important;
  color: #004f7e !important;
  margin-bottom: 16px !important;
}

.styles_paragraph__WAsCo {
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 17px !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/premium-report/components/portfolio-story/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;AACnC","sourcesContent":[".container {\n  padding: 32px !important;\n}\n\n.title {\n  font-family: Roboto !important;\n  font-weight: 600 !important;\n  font-size: 16px !important;\n  line-height: 100% !important;\n  letter-spacing: 0% !important;\n  color: #004f7e !important;\n  margin-bottom: 16px !important;\n}\n\n.subtitle {\n  font-family: Roboto !important;\n  font-weight: 600 !important;\n  font-size: 12px !important;\n  line-height: 100% !important;\n  letter-spacing: 0% !important;\n  color: #004f7e !important;\n  margin-bottom: 16px !important;\n}\n\n.paragraph {\n  font-family: Roboto !important;\n  font-weight: 400 !important;\n  font-size: 11px !important;\n  line-height: 17px !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__sOOGq`,
	"title": `styles_title__CWVno`,
	"subtitle": `styles_subtitle__V2FEF`,
	"paragraph": `styles_paragraph__WAsCo`
};
export default ___CSS_LOADER_EXPORT___;
