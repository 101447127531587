import React from 'react';
import { EditableField } from '../../components/EditableField';
import { type Slide } from '../../_data';
import { Grid, Typography } from '@mui/material';
import AssetMarkLogo from './LogoAMK.png';

export type Slide10ContentType = [
  {
    field: 'title';
    label: string;
    value: string;
    type: 'editableTextarea';
  },

  {
    field: 'model_selected';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
];

export const Slide10Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide10ContentType>;
    onChangeSlide: (
      slide: Slide<Slide10ContentType>,
      content: Slide10ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        sx={{
          padding: '12px',
          height: '100%',
          width: '100%',
          fontFamily: 'Roboto',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            height: 'calc(100% - 48px)',
            borderBottom: '1px solid #DEE8F2',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: '#004F7E',
              color: '#FFFFFF',
              padding: '12px',
              width: '100%',
              fontFamily: 'Roboto',
            }}
          >
            <Grid item height="fit-content" pb={3}>
              <EditableField
                slide={slide}
                field={'title'}
                onSlideContentChange={onChangeSlide}
                style={{
                  fontSize: '38px',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                }}
              />
            </Grid>
            <Grid item height="fit-content">
              <EditableField
                slide={slide}
                field={'model_selected'}
                onSlideContentChange={onChangeSlide}
                style={{
                  height: '520px',
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            padding: '8px 0',
            height: '48px',
            backgroundColor: '#fff',
          }}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: '12px',
                color: '#7D7D7D',
                fontWeight: '400',
                marginLeft: '12px',
              }}
            >
              For financial advisor use only
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={3}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
          >
            <Grid item alignItems="flex-end" justifyContent="flex-end">
              <img
                src={AssetMarkLogo}
                alt="AssetMark1"
                style={{
                  height: '20px',
                  width: '135px',
                  borderRight: '1px solid #DEE8F2',
                  paddingRight: '12px',
                }}
              />
            </Grid>
            <Grid item sx={{ marginRight: '12px' }}>
              <Typography sx={{ fontSize: '12px' }}>
                {slide.slideTemplateId}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  },
);

export default Slide10Content;
