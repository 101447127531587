import React from 'react';

import { SlideContextProvider } from '../../../slides';
import { assetmarkTheme } from '../../../slides/themes/assetmark/assetmark';
import { PremiumReport } from './premium-report';

export function PremiumReportPage(): React.ReactNode {
  return (
    <SlideContextProvider
      theme={assetmarkTheme}
      width={612}
      height={792}
      type="premium_report"
    >
      <PremiumReport />
    </SlideContextProvider>
  );
}
