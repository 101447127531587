import { Typography } from '@mui/material';
import { SlideTitle, useSlideContext } from '../../../../features/slides';

import React from 'react';

export type ImportantInformationProps = {
  readonly porfoliosReviewed: string[] | undefined;
  readonly strategistPerformance: string[] | undefined;
};

export function ImportantInformation({
  porfoliosReviewed,
  strategistPerformance,
}: ImportantInformationProps): React.ReactElement {
  const { theme } = useSlideContext();

  const bodyStyle = {
    fontFamily: theme.fontFamily.body,
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.sm,
    color: theme.colors.neutral,
    lineHeight: '1.5',
  };

  return (
    <div>
      <div style={{ padding: '16px 48px' }}>
        <SlideTitle>Important Information</SlideTitle>
      </div>

      <div style={{ padding: '16px 48px' }}>
        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>
            Porfolios reviewed relative to:
          </Typography>
          {porfoliosReviewed?.map(portfolio => (
            <Typography key={portfolio} sx={bodyStyle}>
              {portfolio}
            </Typography>
          ))}
        </div>

        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>NOTE:</Typography>
          <ul
            style={{
              margin: 0,
              paddingLeft: '24px',
            }}
          >
            <li>
              <Typography sx={bodyStyle}>
                All returns are gross of fees. Please see AsssetMark factsheets
                that contain standardized performance periods and net of fees
                returns information
              </Typography>
            </li>
            <li>
              <Typography sx={bodyStyle}>
                All portfolios use annual rebalancing
              </Typography>
            </li>
            <li>
              <Typography sx={bodyStyle}>
                Global index blend uses monthly rebalancing
              </Typography>
            </li>
          </ul>
        </div>

        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>
            Strategist performance returns are used since the inception of the
            strategy which may include model and composite performance returns.
            Index/blend of indices is used prior to inception of the strategy.
          </Typography>

          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              margin: 0,
            }}
          >
            {strategistPerformance?.map(performance => (
              <li key={performance}>
                <Typography sx={bodyStyle}>{performance}</Typography>
              </li>
            ))}
          </ul>
        </div>

        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>
            Index and model returns use software programs to track investment
            portfolios, which are considered hypothetical performance and do not
            reflect actual performance. There are inherent limitations in the
            use of hypothetical performance and proxy index information.
          </Typography>
        </div>

        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>
            Bloomberg® and the referenced Bloomberg Index are service marks of
            Bloomberg Finance L.P. and its affiliates, (collectively,
            “Bloomberg” ) and are used under license. Bloomberg does not approve
            or endorse this material, nor guarantees the accuracy or
            completeness of any information herein. Bloomberg and AssetMark,
            Inc. are separate and unaffiliated companies.
          </Typography>
        </div>

        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>
            Please read the Terms of Use posted at{' '}
            <a href="https://www.ewealthmanager.com">www.ewealthmanager.com</a>{' '}
            that govern the use of these materials and also be advised:
          </Typography>
        </div>

        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>
            AssetMark uses financial market information (“Information”) from
            third-party providers (“Providers”) in reports (“Materials”). The
            Information includes, but is not limited to, financial market data,
            quotes, news, analyst opinions and research reports. The Materials
            are for informational purposes only, not a solicitation or for use
            in the creation/management/offering/sale of any financial instrument
            or product based thereon and should not be considered investment,
            legal or tax advice. The Information has been drawn from sources
            believed to be reliable, but its accuracy and timeliness is not
            guaranteed and is subject to change. You agree that neither
            AssetMark nor the Providers are liable for the use of the
            Information.
          </Typography>
        </div>

        <div style={{ padding: '12px 0' }}>
          <Typography sx={bodyStyle}>
            You agree not to redistribute the Information to recipients not
            authorized by AssetMark. You agree that the Providers are considered
            to be third-party beneficiaries of the Terms of Use.
          </Typography>
        </div>
      </div>
    </div>
  );
}
