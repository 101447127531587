import { Grid } from '@mui/material';
import { ChartBox, SlideTitle } from 'features/slides';
import { PortfolioStatisticsLineChart } from 'pages/Cases/components/PortfolioStatisticsLineChart';
import { portfolioStatisticsChartFilter } from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';

export type RollingThreeYearRiskAndSharpeRatioProps = {
  readonly filteredPerformance: any;
};

export function RollingThreeYearRiskAndSharpeRatio({
  filteredPerformance,
}: RollingThreeYearRiskAndSharpeRatioProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>Rolling 3-Year Risk and Sharpe Ratio</SlideTitle>
      </Grid>

      <ChartBox>
        <PortfolioStatisticsLineChart
          data={portfolioStatisticsChartFilter({
            filteredPerformance,
            name: 'rolling_three_year_stats',
            subName: '3_year_standard_deviation',
          })}
          formatChart={{
            unit: '%',
            decimals: 2,
            prefix: '',
            value: 'Standard Deviation',
            label: 'Standard Deviation',
          }}
          height={380}
        />
      </ChartBox>

      <ChartBox>
        <PortfolioStatisticsLineChart
          data={portfolioStatisticsChartFilter({
            filteredPerformance,
            name: 'rolling_three_year_stats',
            subName: '3_year_sharpe',
          })}
          formatChart={{
            unit: '',
            decimals: 2,
            prefix: '',
            value: 'Sharpe Ratio',
            label: 'Sharpe Ratio',
          }}
          height={380}
        />
      </ChartBox>
    </Grid>
  );
}
