import { Grid, IconButton } from '@mui/material';
import styled from 'styled-components';

export const SearchBoxContainer = styled(Grid)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: #eaeaea;
  font-family: 'Inter', sans-serif;
  transition: background 0.5s;
  z-index: 1;

  &:focus-within {
    background: #fff;
  }
`;

export const QuestionMarkCircle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 9px;
  padding-left: 9px;
  height: 30px;
  border-radius: 15px;
  background: #000;
  color: #fff;
  cursor: pointer;

  & > .question-mark {
    opacity: 1;
    display: inline-block;
    padding-top: 2px;
    width: 21px;
    font-family: 'Kallisto', sans-serif;
    transition:
      width 0.5s,
      opacity 0.5s;
  }

  & > .question-text {
    opacity: 0;
    display: inline-block;
    width: 0;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    transition:
      width 0.75s cubic-bezier(0.38, 0.28, 0.49, 1.16),
      opacity 0.5s;
  }

  &:hover {
    padding-left: 12px;
    background: #444;
  }

  &:hover > .question-mark {
    opacity: 0;
    width: 0;
  }

  &:hover > .question-text {
    opacity: 1;
    width: 135px;
  }
`;

export const RevealText = styled.div`
  // opacity: 0;
`;

export const InputField = styled.input`
  flex-grow: 1;
  padding-left: 10px;
  background: none;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

export const SuffixIcon = styled(IconButton)`
  opacity: 0;
  transition: opacity 0.5s ease-in !important;

  ${InputField}:focus + & {
    opacity: 1;
  }
`;
