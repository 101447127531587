import React from 'react';
import Divider from '@mui/material/Divider';
import { Grid, InputLabel, styled } from '@mui/material';
import FavButton from '../../../FavButton';
import { SponsorModel } from 'pages/Models/types';
import BackButton from 'components/BackButton';

interface HeaderProps {
  sponsorModel: SponsorModel;
}

const Header: React.FC<HeaderProps> = ({ sponsorModel }) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        color={'black'}
        paddingInline={{ xs: '10px', sm: '20px' }}
        height={50}
      >
        <Grid
          item
          xs={3.5}
          sm={3}
          md={2}
          lg={1}
          xl={1}
          justifyContent="flex-start"
          textAlign="start"
        >
          <BackButton name="Models" />
        </Grid>
        <Grid item xs={6.5} sm={7} md={8} lg={10} xl={10} textAlign="center">
          <InputTitle aria-multiline> {sponsorModel.modelName} </InputTitle>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={1} xl={1} textAlign="end">
          <FavButton
            id={sponsorModel.id}
            isFavorite={sponsorModel.favorite}
            name={sponsorModel.modelName}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default Header;

const InputTitle = styled(InputLabel)`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  line-height: 19px;
`;
