import { handleError } from '@common/error';
import { timeDifference } from '@common/timeDifference';
import { startProposal, takeAssessment } from '@pages/ClientsV2/mutation';
import { actions as globalActions } from 'app/features/Global/slice';
// import { settings } from 'config';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { ITEM_TYPES } from 'utils/constant/itemTypes';
import {
  IEnhancementFeaturesHookProps,
  IEnhancementFeaturesHookReturn,
} from '../types';

export const useChatEnhancementFeatures = ({
  content,
  type,
  createdAt,
  isLastItem,
  setProposalURL,
}: IEnhancementFeaturesHookProps): IEnhancementFeaturesHookReturn => {
  const dispatch = useDispatch();
  const advisorId =
    JSON.parse(localStorage.getItem('tifin-ai-loggedUser') || '{}')
      ?.advisorId || '';

  const {
    risk_capacity: riskCapacity,
    risk_preference: riskPreference,
    risk_score: portfolioRisk,
    client_id: clientId = '',
    model_id: modelId = '',
  } = content;

  const { mutate: startProposalMutate, isLoading: isProposalLoading } =
    useMutation(startProposal, {
      onError: (error: any) => handleError(dispatch, error),
    });

  const { mutate: takeAssessmentMutate, isLoading: isAssessmentLoading } =
    useMutation(takeAssessment, {
      onError: (err: any) => handleError(dispatch, err),
    });

  /* FOR TYPE: generate_proposal */
  const handleProposal = (contentList: any) => {
    if (clientId) {
      const payload = modelId
        ? {
            modelId,
            clientId,
            contentList,
          }
        : {
            clientId,
            contentList,
          };

      startProposalMutate(payload, {
        onSuccess: res => {
          const proposalLink = `${process.env.REACT_APP_IP_WEALTH}/?proposal_id=${res.data}&is_sage=true`;
          setProposalURL(proposalLink);
        },
      });
    }
  };

  /* FOR TYPE: send_assessment */
  const handleTakeAssessment = () => {
    if (advisorId && clientId) {
      takeAssessmentMutate(
        { advisorId, clientId },
        {
          onSuccess: res => {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'success',
                toastMessage: res?.message,
              }),
            );
          },
        },
      );
    }
  };

  /* FOR TYPE: send_assessment */
  useEffect(() => {
    if (
      type === ITEM_TYPES.send_assessment &&
      isLastItem &&
      createdAt &&
      timeDifference(createdAt, 1000)
    ) {
      handleTakeAssessment();
    }
  }, [type, isLastItem, createdAt]);

  return {
    handleTakeAssessment,
    isAssessmentLoading,
    handleProposal,
    isProposalLoading,
    portfolioRisk,
    riskCapacity,
    riskPreference,
  };
};
