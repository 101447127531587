import { Grid } from '@mui/material';
import SummaryPerformanceTable from 'pages/Cases/components/SummaryPerformanceTable';
import {
  columnsGrowth,
  getPortfolioStatisticsRows,
  portfolioStatisticsChartFilter,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import React from 'react';
import { SlideTitle } from '../../../slides';
export type GrowthSlideProps = {
  readonly filteredPerformance: any;
  readonly startDate?: string;
  readonly endDate?: string;
};

export function GrowthSlideTable({
  filteredPerformance,
  startDate,
  endDate,
}: GrowthSlideProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{
        width: '100%',
        padding: '16px',
      }}
    >
      <Grid>
        <SlideTitle>
          Growth of $ / Annualized Statistics: {`${startDate} - ${endDate}`}
        </SlideTitle>
      </Grid>

      <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
        <SummaryPerformanceTable
          rows={getPortfolioStatisticsRows(
            portfolioStatisticsChartFilter({
              filteredPerformance,
              name: 'performance_stats',
            }),
          )}
          columns={columnsGrowth}
        />
      </Grid>
    </Grid>
  );
}
