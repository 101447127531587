import React, { useState } from 'react';
import { Grid } from '@mui/material';

import CustomModelList from '../components/CustomModelList';
import CustomModelEdit from '../components/CustomModelEdit';

interface ChatCustomModelListProps {
  modelId: string;
}

const ChatCustomModelList: React.FC<ChatCustomModelListProps> = ({
  modelId,
}) => {
  const [id, setId] = useState(modelId);

  return (
    <Grid
      container
      sx={{
        padding: '10px',
        backgroundColor: '#ECECEC',
      }}
    >
      {id ? (
        <CustomModelEdit modelId={id} handleSaveSuccess={() => setId('')} />
      ) : (
        <CustomModelList handleClickEditModel={setId} />
      )}
    </Grid>
  );
};

export default ChatCustomModelList;
