import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
} from '@mui/material';
import Textarea from './components/Textarea';
import { Drawer, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { textareaToHtml } from '../../helpers/string';

export type EmailType = {
  subject: string;
  body: string;
};

export type EmailBodyBlocksProps = {
  type: // For html that is sent in the email body but is not rendered in the DOM.
  | 'html'
    // For text area inputs.
    // Allows receiving styles that are rendered in the DOM.
    | 'input'
    // For html that is sent in the email body and is also rendered in the DOM.
    // Allows receiving styles that are rendered in the DOM.
    | 'item';
  value: string;
  styles?: {
    margin?: string;
    padding?: string;
    height?: string;
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: string;
    color?: string;
  };
};

interface EmailEditorProps {
  title: string;
  subtitle: string;
  subject: string;
  bodyBlocks: any[];
  isLoading: boolean;
  isSending: boolean;
  isDrawerOpen: boolean;
  handleEmailSave: (email: EmailType) => void;
  handleEmailSend: () => void;
  handleCloseDrawer: () => void;
}

const EmailEditor: React.FC<EmailEditorProps> = ({
  title,
  subtitle,
  subject,
  bodyBlocks,
  isLoading,
  isSending,
  isDrawerOpen,
  handleEmailSave,
  handleEmailSend,
  handleCloseDrawer,
}) => {
  const [innerSubject, setInnerSubject] = useState<string>('');
  const [innerBodyBlocks, setInnerBodyBlocks] = useState<any[]>([]);

  useEffect(() => {
    if (!isLoading) {
      // set inner subject
      setInnerSubject(subject);
      // set inner body blocks
      const newInnerBodyBlocks = getInitialInnerBlocks();
      setInnerBodyBlocks([...newInnerBodyBlocks]);
      // update parent email data
      handleEmailChange(subject, newInnerBodyBlocks);
    }
  }, [isLoading]);

  const getInitialInnerBlocks = () => {
    return bodyBlocks.map(block => ({
      ...block,
      userValue: block.value,
    }));
  };

  // events helpers

  const handleEmailChange = (subject, bodyBlocks) => {
    const body = bodyBlocks.reduce((html, block) => {
      switch (block.type) {
        case 'html':
        case 'item':
          return html + block.value;
        case 'input':
          return html + textareaToHtml(block.userValue);
      }
    }, '');
    handleEmailSave({ subject, body });
  };

  const handleBlockChange = (index: number, value: string) => {
    setInnerBodyBlocks(prevState => {
      const newState = [...prevState];
      newState[index].userValue = value;
      return newState;
    });
  };

  const handleDiscard = () => {
    const newInnerBodyBlocks = getInitialInnerBlocks();
    setInnerSubject(subject);
    setInnerBodyBlocks(newInnerBodyBlocks);
    handleEmailChange(subject, newInnerBodyBlocks);
    handleCloseDrawer();
  };

  return (
    <Drawer open={isDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <TitleContainer container direction="row" justifyContent="space-between">
        <Grid item>
          <TitleLabel sx={{ mb: '4px' }}>{title}</TitleLabel>
          <SubTitleLabel>{subtitle}</SubTitleLabel>
        </Grid>
        <Grid item>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </TitleContainer>
      <LayoutEmailEditor container>
        <SubjectContainer container>
          <SubjectLabel>Subject</SubjectLabel>
          <SubjectInput
            type="text"
            value={innerSubject}
            onChange={ev => setInnerSubject(ev.currentTarget.value)}
          />
        </SubjectContainer>
        <EmailContainer container>
          <EmailTitleContainer item>
            <TitleLabel sx={{ mb: '4px' }}>Email Content</TitleLabel>
            <SubTitleLabel sx={{ mb: '24px' }}>
              Here’s a preview of the email your client will receive. Click on
              any text to edit.
            </SubTitleLabel>
          </EmailTitleContainer>
          <EmailContent container direction="column">
            {innerBodyBlocks.map((block, index) => {
              switch (block.type) {
                case 'input':
                  return (
                    <Textarea
                      key={index}
                      value={block.userValue}
                      style={block.styles}
                      handleChange={ev =>
                        handleBlockChange(index, ev.currentTarget.value)
                      }
                    />
                  );
                case 'item':
                  return (
                    <EmailItem
                      key={index}
                      dangerouslySetInnerHTML={{ __html: block.value }}
                      style={block.styles}
                      onClick={ev => {
                        // prevent clicking on a link inside dangerouslySetInnerHTML
                        ev.stopPropagation();
                        ev.preventDefault();
                      }}
                    />
                  );
              }
            })}
          </EmailContent>
        </EmailContainer>
      </LayoutEmailEditor>
      <ButtonsContainer container>
        <Grid item flexGrow={1}>
          <ButtonDiscardFooter
            sx={{ textTransform: 'none' }}
            onClick={handleDiscard}
          >
            Discard changes
          </ButtonDiscardFooter>
        </Grid>
        <Grid item flexGrow={1}>
          <ButtonSaveFooter
            startIcon={
              isSending ? <CircularProgress size={15} color="inherit" /> : null
            }
            sx={{ backgroundColor: '#000', textTransform: 'none' }}
            onClick={() => {
              handleEmailChange(innerSubject, innerBodyBlocks);
              handleEmailSend();
              handleCloseDrawer();
            }}
          >
            Save and send
          </ButtonSaveFooter>
        </Grid>
      </ButtonsContainer>
    </Drawer>
  );
};

export default EmailEditor;

const LayoutEmailEditor = styled(Grid)({
  width: '764px',
  padding: '32px',
  backgroundColor: '#fff',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontFamily: 'inter,sans-serif',
  gap: '20px',
});

const TitleContainer = styled(Grid)({
  background: '#fff',
  padding: '17px 32px 16px 32px',
  fontFamily: 'sans-serif',
  position: 'sticky',
  left: 0,
  top: 0,
  zIndex: 1,
  boxShadow: '0px 1px 8px 0px rgba(38, 38, 38, 0.13)',
});

const TitleLabel = styled(InputLabel)({
  fontFamily: 'inter, sans-serif',
  color: '#000000',
  fontWeight: '500',
  fontSize: '16px',
});

const SubTitleLabel = styled(InputLabel)({
  fontFamily: 'inter, sans-serif',
  color: '#4F4F4F',
  fontWeight: '400',
  fontSize: '14px',
});

const SubjectContainer = styled(Grid)({
  padding: '26px 50px 41px 50px',
  backgroundColor: '#f9f9f9',
  flexDirection: 'column',
  gap: '8px',
});

const SubjectLabel = styled(InputLabel)({
  color: '#000000',
  fontWeight: '500',
  fontSize: '16px',
  fontFamily: 'inter,sans-serif',
});

const SubjectInput = styled('input')({
  padding: '0 10px',
  fontFamily: 'inter,sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  color: '#000',
  height: '40px',
  borderRadius: '4px',
  border: '2px solid #E4E7EC',

  '&:focus': {
    outline: 'none',
    border: '2px solid #1BC29A',
  },
});

const EmailContainer = styled(Grid)({
  width: '100%',
  padding: '26px 50px 41px 50px',
  backgroundColor: '#f9f9f9',
  flexDirection: 'column',
});

const EmailTitleContainer = styled(Grid)({
  fontSize: '20px',
  fontWeight: 500,
  color: '#000',
});

const EmailContent = styled(Grid)({
  width: '100%',
  background: '#fff',
});

const EmailItem = styled('div')({
  transition: 'background 0.3s',
  '&, *': {
    cursor: 'not-allowed',
  },
  '&:hover': {
    background: '#f9f9f9',
  },
});

const ButtonsContainer = styled(Grid)({
  width: '100%',
  backgroundColor: '#fff',
  position: 'sticky',
  left: 0,
  bottom: 0,
  padding: '24px',
  flexDirection: 'row',
  textAlign: 'center',
  gap: '20px',
  boxShadow: '0px 1px 8px 0px rgba(38, 38, 38, 0.13)',
});

const ButtonDiscardFooter = styled(Button)({
  width: '100%',
  borderRadius: '20px',
  border: '1px solid',
  background: 'linear-gradient(0deg, #FFFFFF 0%, #D5D5D5 921.25%)',
  borderImageSource: 'linear-gradient(180deg, #FFFFFF 0%,#D5D5D5 75%)',
  boxShadow: '0px 1px 8px 0px rgba(38, 38, 38, 0.13)',
});

const ButtonSaveFooter = styled(Button)({
  width: '100%',
  color: '#fff',
  borderRadius: '20px',
  border: '1px solid',
  borderImageSource: 'linear-gradient(180deg,#FFFFFF 0%, #F7F7F700 42.5%)',
  background: 'linear-gradient(180deg, #222124 23.75%, #323232 120%)',
  boxShadow: '0px 1px 8px 0px rgba(38, 38, 38, 0.13)',
});
