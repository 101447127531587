import React, { createContext, useState, ReactNode, useContext } from 'react';
import { downloadPdfSlides } from '../utils';

interface SlidesUIContextType {
  zoom: number;
  setZoom: (zoom: number) => void;
  dirty: boolean;
  setDirty: (dirty: boolean) => void;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  isDownloading: boolean;
  handleDownload: (filename: string) => void;
}

export const SlidesUIContext = createContext<SlidesUIContextType | undefined>(
  undefined,
);

export const SlidesUIProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const [dirty, setDirty] = useState(false);
  const [zoom, setZoom] = useState(100);
  const [isSaving, setIsSaving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async (filename: string) => {
    try {
      setIsDownloading(true);
      const slides = Array.from(
        document.querySelectorAll('#slides-nav-panel .slide-item'),
      ) as HTMLElement[];
      await downloadPdfSlides(slides, filename);
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <SlidesUIContext.Provider
      value={{
        dirty,
        setDirty,
        zoom,
        setZoom,
        handleDownload,
        isSaving,
        setIsSaving,
        isDownloading,
      }}
    >
      {children}
    </SlidesUIContext.Provider>
  );
};

export const useSlidesUI = (): SlidesUIContextType => {
  const context = useContext(SlidesUIContext);
  if (!context) {
    throw new Error('useSlidesUI must be used within a SlidesUIProvider');
  }
  return context;
};
