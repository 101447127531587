/* eslint-disable react/prop-types */
import * as React from 'react';

function EditPencilIcon(props: any): React.JSX.Element {
  return (
    <svg
      width={props.width ?? '16'}
      height={props.height ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.fill || '#666666'}
        d="M11.615 1.25743L14.7433 4.38505C14.8566 4.49835 14.9465 4.63285 15.0078 4.78089C15.0691 4.92893 15.1007 5.08759 15.1007 5.24783C15.1007 5.40807 15.0691 5.56674 15.0078 5.71478C14.9465 5.86281 14.8566 5.99731 14.7433 6.1106C14.7433 6.1106 14.7433 6.11061 14.7433 6.11061L7.0739 13.78H13.8802C14.0552 13.78 14.2231 13.8495 14.3469 13.9733C14.4706 14.0971 14.5402 14.2649 14.5402 14.44C14.5402 14.615 14.4706 14.7829 14.3469 14.9067C14.2231 15.0305 14.0552 15.1 13.8802 15.1H2.12002C1.79645 15.1 1.48614 14.9715 1.25734 14.7427C1.02854 14.5139 0.900006 14.2036 0.900006 13.88V10.7518V10.7516H1.00001C0.999536 10.6045 1.02832 10.4587 1.08468 10.3228C1.14104 10.1869 1.22385 10.0636 1.32831 9.95994L11.615 1.25743ZM11.615 1.25743C11.5017 1.14411 11.3672 1.05422 11.2191 0.992895C11.0711 0.931566 10.9124 0.9 10.7522 0.9C10.5919 0.9 10.4333 0.931566 10.2852 0.992895C10.1372 1.05422 10.0027 1.14411 9.8894 1.25742C9.8894 1.25743 9.8894 1.25743 9.88939 1.25743L1.25788 9.88895C1.25779 9.88903 1.25771 9.88912 1.25762 9.88921L11.615 1.25743ZM11.267 7.71991L5.20694 13.78H2.22002V10.7931L8.28009 4.733L11.267 7.71991ZM12.2001 6.78677L9.21392 3.79987L10.7525 2.26127L13.7387 5.24817L12.2001 6.78677Z"
      />
    </svg>
  );
}

const MemoEditPencilIcon = React.memo(EditPencilIcon);
export default MemoEditPencilIcon;
