const Animations = `
  /*
   * Fade in and out in touch devices
   *
   * On touch devices hidden elements appear on hover.
   *
   * In this application there are hidden elements that are shown on hover using an opacity transition.
   * If these hidden elements are clickable, the click is triggered while the elements are transitioning from opacity 0 to 1.
   * That is, the user is clicking on a element with opacity 0.
   *
   * The solution is to apply these animations where at the beginning the element is set to display: none.
   *
   * How to use:
   * - Add fadeOutWithTouch in normal styles.
   * - Add fadeInWithTouch in hover styles.
   * - If you also want to avoid the first fadeOutWithTouch animation when rendering the component,
   * add visibility: hidden in normal styles.
   */

  @keyframes fadeInWithTouch {
    0% {
      opacity: 0;
      display: none;
    }
    1% {
      opacity: 0;
      display: block;
    }
    100% {
      opacity: 1;
      display: block;
      visibility: visible;
    }
  }

  @keyframes fadeOutWithTouch {
    0% {
      opacity: 1;
      display: block;
    }
    99% {
      opacity: 0;
      display: block;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`;

export default Animations;
