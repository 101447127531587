import { Grid, Typography } from '@mui/material';
import { SlideSubtitle, SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import GuidingPhilosophyImage from './AMK-presentation-approach.png';

export function GuidingPhilosophy(): React.ReactElement {
  return (
    <Grid container gap={1} className={styles.container}>
      <Grid item>
        <SlideTitle>Guiding Philosophy, Goals & Principles</SlideTitle>
      </Grid>
      <Grid item>
        <SlideSubtitle>Guiding Philosophy</SlideSubtitle>
        <Typography component="p" className={styles.paragraph}>
          Investing is hard, especially during market downturns when investor
          emotions can take over and potentially harm the long-term goals of
          their portfolios. To help investors stay the course for the long-term
          and avoid making potentially harmful mistakes, a portfolio needs to
          have a strong foundation from which additional strategies can be added
          to help meet specific investor goals. It’s critical that the
          foundation provides exposure to asset classes that respond to changes
          in economic growth and inflation. We also experience different market
          regimes and investor goals where additional strategies can be applied
          to the foundation to tailor the solution for different client goals
          and asset levels.
        </Typography>

        <Grid
          container
          width="100%"
          height="185px"
          justifyContent="center"
          alignItems="center"
          display="flex"
          marginTop="10px"
        >
          <img
            src={GuidingPhilosophyImage}
            alt="Guiding Philosophy"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <SlideSubtitle>Guiding Goals</SlideSubtitle>
        <Typography component="p" className={styles.paragraph}>
          Build model portfolios that include a diverse mix of complementary
          investment strategies that play specific roles within the portfolio.
          Be able to simplify a sophisticated solution to effectively tell the
          portfolio story to clients.
        </Typography>
      </Grid>

      <Grid item>
        <SlideSubtitle>Guiding Principles for Model Design</SlideSubtitle>

        <ul>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Center portfolios around foundational core position of a broadly
              diversified US strategy.
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Complement with tactical equity and fixed income strategies for
              additional alpha opportunities and ability to limit portfolio
              drawdown.
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Include alternatives to provide diversification from
              non-correlation.
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Use of SMAs for higher net worth accounts to allow access to
              individual securities and more alpha opportunity while keeping the
              same story.
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Emphasis of strategies & mangers with consistent excess returns
              over broad benchmarks over rolling 1-, 3-, 5-year periods.
            </Typography>
          </li>
          <li>
            <Typography component="p" className={styles.paragraph}>
              Consideration for fees, specifically visible platform fees
            </Typography>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
}
