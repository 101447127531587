import * as React from 'react';

function Chat() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7085 6.25H15.2085V3.75C15.2085 3.41848 15.0768 3.10054 14.8424 2.86612C14.608 2.6317 14.29 2.5 13.9585 2.5H3.9585C3.62698 2.5 3.30903 2.6317 3.07461 2.86612C2.84019 3.10054 2.7085 3.41848 2.7085 3.75V13.75C2.70886 13.8676 2.74239 13.9827 2.80523 14.0821C2.86807 14.1814 2.95767 14.2611 3.06374 14.3118C3.16981 14.3626 3.28804 14.3824 3.40486 14.369C3.52167 14.3555 3.63233 14.3094 3.72412 14.2359L6.4585 12.0312V14.375C6.4585 14.7065 6.59019 15.0245 6.82461 15.2589C7.05903 15.4933 7.37698 15.625 7.7085 15.625H15.0202L17.9429 17.9859C18.0535 18.0754 18.1913 18.1244 18.3335 18.125C18.4993 18.125 18.6582 18.0592 18.7754 17.9419C18.8926 17.8247 18.9585 17.6658 18.9585 17.5V7.5C18.9585 7.16848 18.8268 6.85054 18.5924 6.61612C18.358 6.3817 18.04 6.25 17.7085 6.25ZM6.03272 10.7641L3.9585 12.4414V3.75H13.9585V10.625H6.42568C6.28264 10.625 6.14393 10.6741 6.03272 10.7641ZM17.7085 16.1914L15.6343 14.5141C15.5237 14.4246 15.3859 14.3756 15.2437 14.375H7.7085V11.875H13.9585C14.29 11.875 14.608 11.7433 14.8424 11.5089C15.0768 11.2745 15.2085 10.9565 15.2085 10.625V7.5H17.7085V16.1914Z"
        fill="white"
      />
    </svg>
  );
}

const MemoChat = React.memo(Chat);
export default MemoChat;
