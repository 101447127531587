import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type Portfolios = {
  portfolio_name: string;
  risk_profile: number;
  holdings: {
    model_id: string;
    weight: number;
  }[];
};

type ModelSetFees = {
  model_set_name: string;
  portfolio_options: {
    option: number;
    portfolios: Portfolios[];
  }[];
};

type PortfolioFees = {
  portfolio_name: string;
  risk_profile: number;
  platform_fee: number;
  expense_ratio: number;
  total_expense: number;
};

export type PortfolioOptionFees = {
  option: number;
  portfolios: PortfolioFees[];
};

type UsePostCalculateFeesArguments = {
  body: ModelSetFees;
  enabled: boolean;
};

type UsePostCalculateFeesResult = {
  fees: UsePostCalculateFeesResponse | undefined;
  isFeesLoading: boolean;
};
export type UsePostCalculateFeesResponse = {
  expense_date: string;
  portfolio_options: PortfolioOptionFees[];
};

export const usePostCalculateFees = ({
  body,
  enabled,
}: UsePostCalculateFeesArguments): UsePostCalculateFeesResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<UsePostCalculateFeesResponse[]>(
    ['fees', JSON.stringify(body)],
    fetchQuery({
      path: `/v2/proxy/investment-engine/api/enterprise/amk/calculate-portfolio-fees`,
      body: body,
      method: 'POST',
    }),
    { enabled },
  );

  return {
    fees: data?.[0],
    isFeesLoading: isLoading,
  };
};
