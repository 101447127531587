// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/assetmark/logo-white.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_slide__IEC1B {
  background-color: #05acd8;
  color: #fff;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  align-items: center;
  padding-left: 5rem;
  font-size: 60px;
}

.styles_logo__R7ttN {
  width: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/features/slides/components/title-slide-template/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,yDAAiE;EACjE,iCAAiC;EACjC,4BAA4B;EAC5B,qBAAqB;EACrB,gBAAgB;EAChB,WAAW;EACX,iCAAiC;EACjC,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".slide {\n  background-color: #05acd8;\n  color: #fff;\n  background-image: url('../../../assets/assetmark/logo-white.png');\n  background-position: right bottom;\n  background-repeat: no-repeat;\n  background-size: auto;\n  min-height: 100%;\n  width: 100%;\n  font-family: 'Roboto', sans-serif;\n  justify-content: center;\n  align-items: center;\n  padding-left: 5rem;\n  font-size: 60px;\n}\n\n.logo {\n  width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": `styles_slide__IEC1B`,
	"logo": `styles_logo__R7ttN`
};
export default ___CSS_LOADER_EXPORT___;
