import { RestQueryConfig, useRESTQuery } from '@hooks/useRESTQuery';
import IconDotLine from '@images/icon-dot-line.svg';
import IconDotPlot from '@images/icon-dot-plot.svg';
import IconPeople from '@images/icon-people.svg';
import { useMemo } from 'react';

const categories = [
  {
    category: 'Model Construction',
    icon: IconPeople,
  },
  {
    category: 'Model Matching',
    icon: IconDotPlot,
  },
  {
    category: 'Meeting Prep',
    icon: IconDotLine,
  },
];

export type EnterpriseQuestions = {
  title: string;
  icon: string;
  questions: EnterpriseQuestion[];
};

export type EnterpriseQuestion = {
  text: string;
  prompt: string;
  navigate: string;
};

export type useEnterpriseQueriesReturnType = {
  questions: EnterpriseQuestions[];
  isLoaded: boolean;
  findQuestionByText: (question: string) => EnterpriseQuestion | undefined;
};

export function useEnterpriseQueries(): useEnterpriseQueriesReturnType {
  const token = localStorage.getItem('x-tifin-ai-token');
  const queryKey = ['extract-questions'];
  const config: RestQueryConfig = {
    method: 'GET',
    endpoint: `/enterprise/enterpriseQuestions`,
    headers: { 'X-Tifin-Ai-Auth': token },
  };

  const { data } = useRESTQuery(queryKey, config, {
    select: res => {
      return res.data.map(question => ({
        category: question?.category,
        questions: question?.question.map(parseQuestion),
      }));
    },
  });

  const questions = useMemo(
    () =>
      categories
        .map(({ category, icon }) => {
          const questions =
            data?.find(item => item.category === category)?.questions ?? [];
          return { title: category, icon, questions };
        })
        .filter(({ questions }) => questions.length),
    [data],
  );

  const findQuestionByText = (question: string) => {
    return questions
      .map(({ questions }) => questions)
      .flat()
      .find(({ text }) => text === question);
  };

  return { questions, isLoaded: !!data, findQuestionByText };
}

const parseQuestion = (question: string) => {
  const questionParts = question.split('|');

  // text
  const text = questionParts[0];

  // prompt
  const promptPart = questionParts.find(part => part.startsWith('Prompt='));
  const prompt = promptPart?.replace('Prompt=', '') || text;

  // navigate
  const navigatePart = questionParts.find(part => part.startsWith('Navigate='));
  const navigate = navigatePart?.replace('Navigate=', '') || '';

  return { text, prompt, navigate };
};
