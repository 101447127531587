import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';
import {
  PerformanceReportRequestType,
  PerformanceReportResponseType,
} from './types';

export type UsePostCalculatePerformanceArguments = {
  performanceData: PerformanceReportRequestType;
  enabled: boolean;
};

export type UsePostCalculatePerformanceResult = {
  data: PerformanceReportResponseType | undefined;
  isLoading: boolean;
};

export function usePostCalculatePerformance({
  performanceData,
  enabled,
}: UsePostCalculatePerformanceArguments): UsePostCalculatePerformanceResult {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<PerformanceReportResponseType>(
    ['modelSetPerformance', JSON.stringify(performanceData)],
    fetchQuery({
      path: `/v2/proxy/investment-engine/api/enterprise/amk/calculate_performance_data?required_stat=default_asset_mark`,
      body: performanceData,
      method: 'POST',
    }),
    { enabled },
  );
  return { data, isLoading };
}
