import React from 'react';
import { EditableField } from '../../components/EditableField';
import { type Slide } from '../../_data';
import { Grid } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Footer from '../Footer';

export type Slide6ContentType = [
  {
    field: 'title';
    label: string;
    value: string;
    type: 'text';
  },
  {
    field: 'table';
    label: string;
    value: any[];
    type: 'editableTextarea';
  },
];

const DescriptionCell = (params: GridRenderCellParams) => {
  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
        lineHeight: '1.5',
        padding: '8px 0',
      }}
    >
      {params.value}
    </div>
  );
};

const columns: GridColDef[] = [
  { field: 'strategy', headerName: 'STRATEGY', flex: 1, sortable: false },
  {
    field: 'description',
    headerName: 'DESCRIPTION',
    flex: 3,
    renderCell: DescriptionCell,
    sortable: false,
  },
  {
    field: 'implementation',
    headerName: 'IMPLEMENTATION',
    flex: 1,
    sortable: false,
  },
  {
    field: 'main_purpose',
    headerName: 'MAIN PURPOSE',
    flex: 3,
    renderCell: DescriptionCell,
    sortable: false,
  },
];

export const Slide6Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide6ContentType>;
    onChangeSlide: (
      slide: Slide<Slide6ContentType>,
      content: Slide6ContentType,
    ) => void;
  }): React.ReactElement => {
    const holdings = slide.content[1].value.flatMap((holding: any) =>
      holding.holdings.map((h: any, index: number) => ({
        id: index,
        ...h,
      })),
    );
    return (
      <Grid
        sx={{
          padding: '12px 12px 0 12px',
          height: '100%',
          width: '100%',
        }}
      >
        <Grid
          item
          sx={{
            fontSize: '24px',
            fontWeight: '500',
            color: '#004F7E',
            height: 'fit-content',
            marginBottom: '25px',
          }}
        >
          <EditableField
            field={'title'}
            slide={slide}
            onSlideContentChange={onChangeSlide}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            width: '100%',
            overflow: 'hidden',
            height: 'calc(100% - 102px)',
          }}
        >
          <Grid item width="100%" height="fit-content">
            <DataGrid
              rows={holdings}
              columns={columns}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
              disableColumnMenu
              disableColumnSelector
              disableColumnFilter
              getRowId={row => row.id}
              getRowHeight={() => 'auto'}
              sx={{
                '& .MuiDataGrid-cell': {
                  alignItems: 'flex-start',
                  padding: '8px',
                  whiteSpace: 'normal',
                  maxHeight: 'none !important',
                  borderRight: '1px solid #E4E7EC',
                },
                '& .MuiDataGrid-row': {
                  maxHeight: 'none !important',
                  borderBottom: '1px solid #E4E7EC',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#F9F9FA !important',
                  color: '#86858B',
                  fontSize: '12px',
                  fontWeight: '600',
                  borderBottom: '1px solid #E4E7EC',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#F9F9FA !important',
                  borderRight: '1px solid #E4E7EC',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
            />
          </Grid>
        </Grid>
        <Footer
          numberPage={slide.slideTemplateId}
          text="For Financial Advisor use only"
        />
      </Grid>
    );
  },
);

export default Slide6Content;
