import React from 'react';
import {
  DataBannerContainer,
  DataBannerMainNumber,
  DataBannerTitle,
} from './styles';

interface DataBannerProps {
  title: string;
  mainNumber: number | string;
}

function DataBanner(props: DataBannerProps): React.JSX.Element {
  return (
    <DataBannerContainer>
      <DataBannerTitle>{props.title}</DataBannerTitle>
      <DataBannerMainNumber>{props.mainNumber}</DataBannerMainNumber>
    </DataBannerContainer>
  );
}
//
export default DataBanner;
