import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import { SlideType, useSlideContext } from 'features/slides';
import React from 'react';
import { download } from '../../utils';
import { ChevronDownIcon } from './chevron-down-icon';
import { ChevronUpIcon } from './chevron-up-icon';
import { FooterButton } from './footer-button';
import { IconNoSidebar } from './icon-no-sidebar';
import { IconSidebar } from './icon-sidebar';
import styles from './styles.module.css';
import { usePostPdfToS3 } from '../../../performance-report/api/use-post-pdf-to-s3';
import { useParams } from 'react-router-dom';

export type SlidesFooterProps = {
  readonly title: string;
  readonly isPanelOpen: boolean;
  readonly slides: SlideType[];
  readonly setIsPanelOpen: (open: boolean) => void;
};

export function SlidesFooter({
  title,
  isPanelOpen,
  setIsPanelOpen,
  slides,
}: SlidesFooterProps): React.ReactElement {
  const { index, zoom, setIndex, setZoom, width, height, type } =
    useSlideContext();
  const { caseId, actionId } = useParams();
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const { postPdfToS3Mutate } = usePostPdfToS3();
  const handleDownload = React.useCallback(() => {
    setIsDownloading(true);
    const slides = document.querySelectorAll(
      '#slides-nav-panel .slide-item',
    ) as unknown as HTMLElement[];
    download({
      filename: title,
      slides,
      width: width,
      height: height,
      onSuccess: (pdfFile: File) => {
        setIsDownloading(false);
        if (type === 'performance_report') {
          postPdfToS3Mutate({
            pdf: pdfFile,
            caseId: caseId!,
            actions: actionId!,
            enabled: true,
            successToastMessage: '',
          });
        }
      },
      onError: () => setIsDownloading(false),
    });
  }, [title, postPdfToS3Mutate, caseId, actionId]);

  const handleSave = React.useCallback(() => {
    setIsSaving(true);
  }, []);

  const handleNavigate = React.useCallback(
    (direction: number) => {
      if (index + direction < 0 || index + direction >= slides.length) return;
      setIndex(index + direction);
    },
    [index, slides, setIndex],
  );

  return (
    <Box
      className={styles.footer}
      sx={{
        display: 'flex',
        backgroundColor: '#EFEFEF',
        py: 2,
        px: 3,
      }}
    >
      <div className={styles.footerLeft}>
        <div>{title}</div>
        <span>
          Page: {index + 1} / {slides.length}
        </span>
        <IconButton
          aria-label="Previous"
          disableRipple
          onClick={() => handleNavigate(-1)}
          sx={{
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            borderRadius: '7px',
            height: '32px',
            border: '1px solid #e5e5e5',
          }}
        >
          <ChevronUpIcon />
        </IconButton>
        <IconButton
          aria-label="Next"
          disableRipple
          onClick={() => handleNavigate(1)}
          sx={{
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#f2f2f2',
            },
            borderRadius: '7px',
            height: '32px',
            border: '1px solid #e5e5e5',
          }}
        >
          <ChevronDownIcon />
        </IconButton>
      </div>

      <div className={styles.footerRight}>
        <Select
          value={zoom}
          onChange={e => setZoom(Number(e.target.value))}
          displayEmpty
          inputProps={{ 'aria-label': 'Zoom' }}
          sx={{
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#e5e5e5',
            },
            color: '#535256',
            height: '34px',
            borderRadius: 2,
            fontSize: '12px',
            lineHeight: '20px',
            border: '1px solid hsl(0, 0.00%, 89.80%)',
            fontWeight: 500,
          }}
        >
          <MenuItem value={200}>200%</MenuItem>
          <MenuItem value={175}>175%</MenuItem>
          <MenuItem value={150}>150%</MenuItem>
          <MenuItem value={125}>125%</MenuItem>
          <MenuItem value={100}>100%</MenuItem>
          <MenuItem value={75}>75%</MenuItem>
          <MenuItem value={50}>50%</MenuItem>
        </Select>
        <FooterButton
          loading={isDownloading}
          onClick={() => handleDownload()}
          disabled={isDownloading}
        >
          Download
        </FooterButton>
        <FooterButton
          loading={isSaving}
          onClick={handleSave}
          disabled={true || isSaving}
        >
          Save
        </FooterButton>
        <ButtonGroup
          disableElevation
          disableRipple
          aria-label="Basic button group"
          sx={{
            borderRadius: '8px',
            p: '1px',
            backgroundColor: '#E5E5E5',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            gap: '1px',
          }}
        >
          <IconButton
            aria-label="Hide sidebar"
            disableRipple
            onClick={() => setIsPanelOpen(false)}
            sx={{
              backgroundColor: isPanelOpen ? 'transparent' : '#fff',
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              borderRadius: '7px',
              height: '32px',
            }}
          >
            <IconNoSidebar />
          </IconButton>
          <IconButton
            aria-label="Show sidebar"
            disableRipple
            onClick={() => setIsPanelOpen(true)}
            sx={{
              backgroundColor: isPanelOpen ? '#fff' : 'transparent',
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
              borderRadius: '7px',
              height: '32px',
            }}
          >
            <IconSidebar />
          </IconButton>
        </ButtonGroup>
      </div>
    </Box>
  );
}
