import React from 'react';
import { EditableField } from '../../components/EditableField';
import { type Slide } from '../../_data';
import { Grid, Typography } from '@mui/material';
import ModelSetSlideTable from '../../components/ModelSetSlideTable';
import { getSlideField } from '../../utils';
import Footer from '../Footer';
export type Slide5ContentType = [
  {
    field: 'title';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
  {
    field: 'subtitle1';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
  {
    field: 'modelSet';
    label: string;
    value: string;
    type: 'modelSet';
  },
];

export const Slide5Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide5ContentType>;
    onChangeSlide: (
      slide: Slide<Slide5ContentType>,
      content: Slide5ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        sx={{
          padding: '12px 12px 0 12px',
          height: '100%',
          width: '100%',
          fontFamily: 'Roboto',
        }}
      >
        <Grid
          container
          direction="column"
          gap={2}
          sx={{
            height: 'calc(100% - 100px)',
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            sx={{
              padding: '17px 28px 0px 28px',
              backgroundColor: '#DEE8F2',
              width: '60%',
              height: 'fit-content',
              '& h2 span': {
                fontSize: '18px',
              },
              '& ul': {
                marginBlock: '0.5rem',
                paddingLeft: '1.5rem',
              },
              '& li': {
                marginBlock: '6px',
              },
            }}
          >
            <Grid item height="fit-content" paddingBottom="22px">
              <EditableField
                field={'title'}
                slide={slide}
                onSlideContentChange={onChangeSlide}
                style={{
                  fontSize: '28px',
                  color: '#004F7E',
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                }}
                editorClassName="slide5"
              />
            </Grid>
            <Grid item height="fit-content">
              <EditableField
                field={'subtitle1'}
                slide={slide}
                onSlideContentChange={onChangeSlide}
                style={{
                  height: '480px',
                  fontSize: '15px',
                  fontWeight: '600',
                }}
                editorClassName="slide5"
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            sx={{
              padding: '16px',
              transform: 'scale(0.60)',
              transformOrigin: 'top left',
              height: '100%',
              marginTop: '50px',
            }}
          >
            <ModelSetField
              slide={slide}
              item={getSlideField(slide, 'modelSet')?.value}
              handleChange={onChangeSlide}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '12px ', height: '40px' }}>
          <Typography
            sx={{ fontSize: '10px', color: '#7D7D7D', fontWeight: '400' }}
          >
            AssetMark US/Global Market Blend is the default option for Core
            exposure in cases where an adviser has identified cost as a factor
            in the decision-making process and to the extent that an adviser is
            not seeking tactical, actively-managed, factor-based or
            socially-focused Core exposure. The consideration of AssetMark
            proprietary strategies, including strategies managed by AssetMark,
            during the investment consulting process creates a conflict of
            interest for AssetMark as AssetMark receives fees for the management
            of those strategies and funds.
          </Typography>
        </Grid>
        <Footer
          numberPage={slide.slideTemplateId}
          text="For Financial Advisor use only"
        />
      </Grid>
    );
  },
);

export default Slide5Content;

const ModelSetField = ({ item }: any) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        '& .MuiDataGrid-root': {
          width: '65%',
        },
        '& .MuiDataGrid-cell p': {
          whiteSpace: 'normal',
          lineHeight: '1.3',
        },
      }}
    >
      <ModelSetSlideTable
        slideMode
        action={{
          id: '',
          caseId: '',
          userId: '',
          milestoneId: '',
          versionName: '',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          draft: false,
          value: { model_set_holdings: [item] },
        }}
        modelSetName={item.model_set_name || ''}
      />
    </Grid>
  );
};
