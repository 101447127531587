import { Grid } from '@mui/material';
import { SlideTitle } from 'features/slides';
import { CaseMilestoneAction } from 'pages/Cases/hooks/useGetCases';
import React from 'react';
import { ModelSetsTable } from '../components/model-sets-table';

export type ModelSetProps = {
  readonly name: string;
  readonly action: CaseMilestoneAction;
  readonly options: number[];
};

export function ModelSet({
  name,
  action,
  options,
}: ModelSetProps): React.ReactElement {
  return (
    <Grid
      container
      flexDirection="column"
      gap={2}
      sx={{ width: '100%', padding: '24px' }}
    >
      <Grid>
        <SlideTitle>{name}</SlideTitle>
      </Grid>

      <Grid>
        {action && name ? (
          <ModelSetsTable
            action={action}
            modelSetName={name}
            options={options}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
