import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';
import { DisclosuresResponseType } from './types';

export type UseGetDisclosuresArguments = {
  enabled: boolean;
  caseId: string;
  actions: string;
  modelSetsNames: string[];
  options: number[];
  riskProfile: number;
};

export type UseGetDisclosuresResult = {
  data: DisclosuresResponseType | undefined;
  isLoading: boolean;
};

export function useGetDisclosures({
  enabled,
  caseId,
  actions,
  modelSetsNames,
  options,
  riskProfile,
}: UseGetDisclosuresArguments): UseGetDisclosuresResult {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<DisclosuresResponseType>(
    ['disclosures'],
    fetchQuery({
      path: `v2/cases/${caseId}/milestones/actions/${actions}/performanceReport/performanceDisclosures?modelSetsNames[]=${modelSetsNames.join(
        '&modelSetsNames[]=',
      )}&options=${options.join('&options=')}&riskProfile=${riskProfile}`,
    }),
    { enabled },
  );
  return { data, isLoading };
}
