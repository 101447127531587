import axios from 'axios';
import { useQuery } from 'react-query';
import { settings } from 'config';

interface UseGetFirmSettingsQueryResult {
  firmSettings:
    | {
        logoSrc: string;
        advisoryFee: number;
        firmDisclosure: string;
        firmDutiesAndResponsibilities: string;
        firmAdvisorFeeFullDisclosure: string;
        emailDisclosure: string;
      }
    | undefined;
}

export const useGetFirmSettingsQuery = (): UseGetFirmSettingsQueryResult => {
  const fetchData = async () => {
    const token = localStorage.getItem('x-tifin-ai-token') || '';
    const response = await axios.get(
      `${settings.api.baseUrl}/v2/advisors/settings`,
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'x-tifin-ai-auth': token,
        },
      },
    );
    return response.data;
  };

  const { data } = useQuery('firmSettings', fetchData, {
    select: data => {
      return {
        logoSrc: data.data.logo,
        advisoryFee: data.data.firmSettings.advisoryFee,
        firmDisclosure: data.data.firmSettings.disclosure,
        firmDutiesAndResponsibilities: data.data.firmSettings.duties,
        firmAdvisorFeeFullDisclosure:
          data.data.firmSettings.advisorFeeDisclosure,
        emailDisclosure: data.data.firmSettings.emailDisclosure,
      };
    },
  });

  return { firmSettings: data };
};
