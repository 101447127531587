import { handleError } from '@common/error';
import { timeDifference } from '@common/timeDifference';
import { takeAssessment } from '@pages/ClientsV2/mutation';
import { actions as globalActions } from 'app/features/Global/slice';
// import { settings } from 'config';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { ITEM_TYPES } from 'utils/constant/itemTypes';
import {
  IEnhancementFeaturesHookProps,
  IEnhancementFeaturesHookReturn,
} from '../types';

export const useChatEnhancementFeatures = ({
  content,
  type,
  createdAt,
  isLastItem,
}: IEnhancementFeaturesHookProps): IEnhancementFeaturesHookReturn => {
  const dispatch = useDispatch();
  const advisorId =
    JSON.parse(localStorage.getItem('tifin-ai-loggedUser') || '{}')
      ?.advisorId || '';

  const {
    risk_capacity: riskCapacity,
    risk_preference: riskPreference,
    risk_score: portfolioRisk,
    client_id: clientId = '',
  } = content;

  const { mutate: takeAssessmentMutate, isLoading: isAssessmentLoading } =
    useMutation(takeAssessment, {
      onError: (err: any) => handleError(dispatch, err),
    });

  /* FOR TYPE: send_assessment */
  const handleTakeAssessment = () => {
    if (advisorId && clientId) {
      takeAssessmentMutate(
        { advisorId, clientId },
        {
          onSuccess: res => {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'success',
                toastMessage: res?.message,
              }),
            );
          },
        },
      );
    }
  };

  /* FOR TYPE: send_assessment */
  useEffect(() => {
    if (
      type === ITEM_TYPES.send_assessment &&
      isLastItem &&
      createdAt &&
      timeDifference(createdAt, 1000)
    ) {
      handleTakeAssessment();
    }
  }, [type, isLastItem, createdAt]);

  return {
    handleTakeAssessment,
    isAssessmentLoading,
    portfolioRisk,
    riskCapacity,
    riskPreference,
  };
};
