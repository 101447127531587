import { Grid, Typography } from '@mui/material';
import React from 'react';
import styles from './styles.module.css';

export function Disclosures(): React.ReactElement {
  return (
    <Grid container direction="column" spacing={2} className={styles.container}>
      <Grid item>
        <Typography component="p" className={styles.paragraph}>
          Bloomberg® and the referenced Bloomberg Index are service marks of
          Bloomberg Finance L.P. and its affiliates, (collectively, “Bloomberg”)
          and are used under license. Bloomberg does not approve or endorse this
          material, nor guarantees the accuracy or completeness of any
          information herein. Bloomberg and AssetMark, Inc. are separate and
          unaffiliated companies.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          Please read the Terms of Use posted at www.ewealthmanager.com that
          govern the use of these materials and also be advised:
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          AssetMark uses financial market information (“Information”) from
          third-party providers (“Providers”) in reports (“Materials”). The
          Information includes, but is not limited to, financial market data,
          quotes, news, analyst opinions and research reports. The Materials are
          for informational purposes only, not a solicitation or for use in the
          creation/management/offering/sale of any financial instrument or
          product based thereon and should not be considered investment, legal
          or tax advice. The Information has been drawn from sources believed to
          be reliable, but its accuracy and timeliness is not guaranteed, and is
          subject to change. You agree that neither AssetMark nor the Providers
          are liable for the use of the Information.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          You agree not to redistribute the Information to recipients not
          authorized by AssetMark. You agree that the Providers are considered
          to be third-party beneficiaries of the Terms of Use.
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="h2" className={styles.subtitle}>
          Important Information
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          The consideration of AssetMark proprietary strategies, including
          strategies that invest in funds managed by AssetMark, during the
          investment consulting process creates a conflict of interest for
          AssetMark as AssetMark receives fees for the management of those
          strategies and funds.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          The recipient of this report, and not AssetMark, is responsible for
          the individualized suitability analyses and determinations as well as
          the investment recommendations for his or her clients. Materials
          provided by AssetMark in connection with this engagement, including
          any sample portfolios, are based on general information provided by
          the recipient of this report about his or her practice and are not
          individualized or client-specific investment advice. In providing
          investment consulting services AssetMark is not exercising investment
          discretion over any client account. Past investment performance is not
          a guarantee of future results and there is no guarantee that materials
          provided by AssetMark hereunder, including any sample portfolios, will
          produce any particular investment outcome.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          This report is for informational purposes only, is not a solicitation,
          and should not be considered investment, legal, or tax advice. The
          information has been drawn from sources believed to be reliable, but
          its accuracy is not guaranteed, and is subject to change. Financial
          advisors may seek more information by contacting AssetMark at
          800-664-5345.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          <b>
            Investing involves risk, including the possible loss of principal.
            Past performance does not guarantee future results.
          </b>
          Asset allocation cannot eliminate the risk of fluctuating prices and
          uncertain returns. There is no guarantee that a diversified portfolio
          will outperform a non-diversified portfolio. No investment strategy,
          such as asset allocation, can guarantee a profit or protect against
          loss. Actual client results will vary based on investment selection,
          timing, market conditions, and tax situation. It is not possible to
          invest directly in an index. Indexes are unmanaged, do not incur
          management fees, costs, and expenses, and cannot be invested in
          directly. Index performance assumes the reinvestment of dividends.
          Investments in equities, bonds, options, and other securities, whether
          held individually or through mutual funds and exchange-traded funds,
          can decline significantly in response to adverse market conditions,
          company-specific events, changes in exchange rates, and domestic,
          international, economic, and political developments. Investments in
          bonds and fixed income-related securities involve market and interest
          rate risk (prices can decline, if interest rates increase), and
          default risk (an issuer being unable to repay principal and interest).
          High-yield bonds are generally subject to a greater risk of default
          than investment-grade bonds. Real estate investments are subject to
          credit and market risks, typically based on changes in interest rates
          and varied economic conditions. Investing in alternative investments,
          including managed futures, commodities, and currencies is not
          appropriate for all persons, as the risk of loss is substantial.
          Investments in futures involve market, counterparty, leverage,
          liquidity, interest rate, foreign currency, commodity, volatility, and
          other risks.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          The exclusion of investments based on industry or ESG factors reduces
          the number of available investment choices and can impact overall
          investment performance.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          <b>
            For more complete information about the various investment solutions
            available, including the investment objectives, risks, and fees,
            please refer to the Disclosure Brochure and applicable Fund
            Prospectus. Please read them carefully before investing. For a copy,
            please contact AssetMark.
          </b>
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          AssetMark, Inc. is an investment adviser registered with the U.S.
          Securities and Exchange Commission. AssetMark Asset Management, a
          division of AssetMark, Inc., includes the firm’s proprietary
          investment strategies. GuideMark® and GuidePath®
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          Funds are distributed by AssetMark Brokerage , LLC, member FINRA, an
          affiliate of AssetMark, Inc. AssetMark and third-party strategists and
          service providers are separate and unaffiliated companies. Each party
          is responsible for its own content and services.
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          ©2024 AssetMark, Inc. All rights reserved.104380 | XXXXXXX.X 04/2024
          | EXP 04/2026
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          <b>AssetMark, Inc.</b>
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          1655 Grant Street 10th Floor Concord, CA 94520-2445 800-664-5345
        </Typography>
      </Grid>
    </Grid>
  );
}
