import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModelList from '../components/CustomModelList';

const CustomModelsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClickEditModel = (id: string) => {
    navigate(`/models/custom-models/${id}`);
  };

  return <CustomModelList handleClickEditModel={handleClickEditModel} />;
};

export default CustomModelsPage;
