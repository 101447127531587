import { ExcelTable } from '../../../../components/UploadExcel';

export const mapExcelToConsultForm = (excel: ExcelTable): any => {
  // getters

  const getNumber = (cellAddress: string, min = 0, max = Infinity) => {
    const value = excel[cellAddress]?.value || 0;
    if (value < min) {
      return min;
    } else if (value > max) {
      return max;
    } else {
      return value;
    }
  };

  const getString = (cellAddress: string) => {
    return (excel[cellAddress]?.stringValue || '').trim();
  };

  const getHighlightStrings = (cells: string[]): string[] => {
    const result: string[] = [];
    cells.forEach(cell => {
      if (hasBgColor(cell)) {
        result.push(getString(cell) as string);
      }
    });
    return result;
  };

  const hasBgColor = (cellAddress: string) => {
    return !!excel[cellAddress]?.hasBgColor;
  };

  // parse helpers

  const getSpecifiedPreference = (cellAddress: string) => {
    const value =
      excel[cellAddress] === undefined ? '' : excel[cellAddress]?.value + '';
    return value.trim() ? true : null;
  };

  const getRiskProfilesDetails = (): any => {
    const result: number[] = [];
    const cells = ['E35', 'F35', 'G35', 'H35', 'I35', 'J35'];
    cells.forEach((cell, index) => {
      if (hasBgColor(cell)) {
        result.push(index + 1);
      }
    });
    return result;
  };

  const getEquityGeographicFocus = (): string | null => {
    const result: string[] = [];
    const cells = ['E42', 'G42', 'I42', 'K42'];
    cells.forEach(cell => {
      if (hasBgColor(cell)) {
        result.push(getString(cell) as string);
      }
    });
    return result.length === 1 ? result[0] : null;
  };

  const getEquitySpecificFocus = (): string[] => {
    const result: string[] = [];
    const cells = ['E43', 'G43', 'I43', 'K43'];
    cells.forEach(cell => {
      if (hasBgColor(cell)) {
        result.push(getString(cell) as string);
      }
    });
    return result;
  };

  const getFixedIncomeGeographicFocus = (): string | null => {
    const result: string[] = [];
    const cells = ['E48', 'G48'];
    cells.forEach(cell => {
      if (hasBgColor(cell)) {
        result.push(getString(cell) as string);
      }
    });
    return result.length === 1 ? result[0] : null;
  };

  const getFixedIncomeSpecificFocus = (): string[] => {
    const result: string[] = [];
    const cells = ['E49', 'G49'];
    cells.forEach(cell => {
      if (hasBgColor(cell)) {
        result.push(getString(cell) as string);
      }
    });
    return result;
  };

  const getBrandDetails = (): string[] => {
    const result: string[] = [];
    const cells = ['E69', 'G69'];
    cells.forEach(cell => {
      if (hasBgColor(cell)) {
        result.push(getString(cell) as string);
      }
    });
    return result;
  };

  const getModelsRequestedDetails = (): any => {
    const isRowFilled = (rowNumber: number) => {
      const cells = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
      ];
      return cells.some(cell => excel[`${cell}${rowNumber}`]?.value);
    };

    const result: any[] = [];
    const firstRowNumber = 94;
    let currentRowNumber = firstRowNumber;
    while (isRowFilled(currentRowNumber)) {
      result.push({
        objectives_priority: [
          {
            objective: 'Growth/Total Return',
            priority_level: getNumber(`A${currentRowNumber}`, 0, 2),
          },
          {
            objective: 'Income',
            priority_level: getNumber(`B${currentRowNumber}`, 0, 2),
          },
          {
            objective: 'Preservation',
            priority_level: getNumber(`C${currentRowNumber}`, 0, 2),
          },
        ],
        risk_profiles: [
          getString(`E${currentRowNumber}`) ? 1 : null,
          getString(`F${currentRowNumber}`) ? 2 : null,
          getString(`G${currentRowNumber}`) ? 3 : null,
          getString(`H${currentRowNumber}`) ? 4 : null,
          getString(`I${currentRowNumber}`) ? 5 : null,
          getString(`J${currentRowNumber}`) ? 6 : null,
        ].filter(Boolean),
        tax_status: getString(`L${currentRowNumber}`)
          ? 'Non-Qualified'
          : 'Qualified',
        aum_amount: getNumber(`D${currentRowNumber}`),
      });
      currentRowNumber += 1;
    }
    return result;
  };

  // map

  return {
    focus_of_practice: {
      question: 'What is the focus of your practice?',
      notes: getString('A5'),
      specified_preference: getSpecifiedPreference('A5'),
    },
    story_told_to_clients: {
      question:
        'What is the story that you tell your clients about their investment portfolio?',
      notes: getString('A10'),
      specified_preference: getSpecifiedPreference('A10'),
    },
    typical_client_base: {
      question: 'How would you describe your typical client base?',
      notes: getString('A15'),
      specified_preference: getSpecifiedPreference('A15'),
    },
    client_segmentation: {
      question: 'How do you segment your clients?',
      value: '',
      notes: getString('A20'),
      specified_preference: getSpecifiedPreference('A20'),
    },
    types_of_accounts: {
      question: 'Types of Accounts: Qualified / Non-qualified — What % of AUM?',
      notes: getString('A27'),
      details: {
        qualified: hasBgColor('E25'),
        non_qualified: hasBgColor('G25'),
        qualified_percentage: null,
        non_qualified_percentage: null,
      },
      specified_preference:
        !!getString('A27') || hasBgColor('E25') || hasBgColor('G25')
          ? true
          : null,
    },
    investment_objective: {
      question:
        'Investment Objective (Growth/Total Return, Preservation, Income)',
      notes: getString('A32'),
      details: {
        growth_total_return: hasBgColor('E30'),
        preservation: hasBgColor('H30'),
        income: hasBgColor('J30'),
        order_of_preference: null,
      },
      specified_preference: getSpecifiedPreference('A32'),
    },
    risk_profiles_used: {
      question: 'What Risk Profiles is the advisor looking for? (1–6)',
      notes: '',
      details: getRiskProfilesDetails(),
      specified_preference: getRiskProfilesDetails().length > 0 ? true : null,
    },
    additional_advisor_notes: {
      question:
        "Do you have any additional notes on the advisor's account preferences?",
      notes: getString('A38'),
      specified_preference: getSpecifiedPreference('A38'),
    },
    equity: {
      question:
        'What are the Advisors preferences for Equities? Do they have any geographic preferences (US Only, US Bias, Global, Target International) or specific approaches (Broad Markets, Sectors, Styles, Factors)?',
      notes: getString('A45'),
      geographic_focus: getEquityGeographicFocus(),
      specific_focus: getHighlightStrings(['E43', 'G43', 'I43', 'K43']),
      equity_additional_notes: 'Preference for technology sectors.',
      specified_preference:
        getEquityGeographicFocus() === null &&
        getEquitySpecificFocus().length === 0
          ? null
          : true,
    },
    fixed_income: {
      question:
        'What are the advisors preferences for Fixed Income? Do they have geographic preferences (US Only or Global) or target a specific approach (High quality or Income focus)? Any focus on USD hedging or a specific maturity target?',
      geographic_focus: getFixedIncomeGeographicFocus(),
      specific_focus: getHighlightStrings(['E49', 'G49']),
      fixed_income_additional_notes: getString('A51'),
      specified_preference:
        getFixedIncomeGeographicFocus() === null &&
        getFixedIncomeSpecificFocus().length === 0
          ? null
          : true,
    },
    approach: {
      question:
        'Does the Advisor have a preference for a specific Investing Approach (Strategic, Tactical, Move to Cash, Alternatives)? Any max exposure preferences or additional notes?',
      notes: getString('A56'),
      details: {
        strategic: hasBgColor('E54'),
        tactical: hasBgColor('G54'),
        move_to_cash: hasBgColor('I54'),
        alternatives: hasBgColor('K54'),
        max_alternative_exposure: null,
      },
      specified_preference:
        hasBgColor('E54') ||
        hasBgColor('G54') ||
        hasBgColor('I54') ||
        hasBgColor('K54')
          ? true
          : null,
    },
    investment_vehicle: {
      question:
        'Which investment vehicles do you prefer (MFs, ETFs, SMAs, UMA)?',
      notes: getString('A61'),
      details: getHighlightStrings(['E59', 'G59', 'I59', 'K59']),
      specified_preference:
        getHighlightStrings(['E59', 'G59', 'I59', 'K59']).length > 0
          ? true
          : null,
    },
    fees_focus: {
      question:
        'Does the advisor have any preference for total or visible fees? Any strict guidelines for maximum fee?',
      notes: getString('A66'),
      details: {
        total: hasBgColor('E64'),
        visible: hasBgColor('G64'),
        maximum_fee: null,
      },
      specified_preference:
        !!getString('A66') || hasBgColor('E64') || hasBgColor('G64')
          ? true
          : null,
    },
    brand: {
      question:
        'Does the advisor have any preference for Institutional or Boutique strategists?',
      notes: getString('A71'),
      details: getBrandDetails(),
      specified_preference:
        !!getString('A71') || getBrandDetails().length > 0 ? true : null,
    },
    strategist: {
      question:
        'Does the advisor have strategists that they would like to include or exclude?',
      notes: getString('A75'),
      include: null,
      exclude: null,
      specified_preference: getSpecifiedPreference('A75'),
    },
    other_insights: {
      question: 'Any other insights?',
      notes: getString('A83'),
      specified_preference: getSpecifiedPreference('A83'),
    },
    models_requested: {
      question:
        'Which model sets would you like to build for this case? {UI PAYLOAD}',
      details: getModelsRequestedDetails(),
      specified_preference: true,
    },
  };
};
