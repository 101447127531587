/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { settings } from 'config';
import { useMutation, useQueryClient } from 'react-query';
import { checkNameConflict } from '../utils';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';

export const usePutCustomModelsMutation = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const fetchData = async data => {
    const hasNameConflict = await checkNameConflict(data.name, data.id);
    if (hasNameConflict) {
      throw new Error('Already exists a model with this name');
    }

    const token = localStorage.getItem('x-tifin-ai-token') || '';
    const response = await axios.put(
      `${settings.api.baseUrl}/v2/models/${data.id}`,
      {
        name: data.name,
        holdings: data.holdings,
      },
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'x-tifin-ai-auth': token,
        },
      },
    );
    return response.data;
  };

  const { mutate, error, isSuccess } = useMutation(fetchData, {
    onSuccess(data, variables) {
      queryClient.invalidateQueries('customModels');
      queryClient.invalidateQueries(['model', variables.id]);
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: 'Model updated successfully',
        }),
      );
    },
    onError(error) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: (error as Error).message,
        }),
      );
    },
  });

  return {
    putCustomModelMutate: mutate,
    putCustomModelError: error,
    putCustomModelisSuccess: isSuccess,
  };
};
