import React from 'react';
import BoltIcon from '@icons/bolt';
import FirmAdministratorSection from './FirmAdministratorSection';
import { useRESTQuery } from '@hooks/useRESTQuery';
import {
  AddFirmAdminButtonWrapper,
  FirmAdminCard,
  StyledHeaderDiv,
  StyledRectangle,
} from './styles';
import { Grid, Typography } from '@mui/material';
import BackArrowIcon from '@icons/BackArrow';
import { handleError } from '@common/error';
import { useDispatch } from 'react-redux';
import { price } from '@common/number';
import FirmAdminModal from './FirmAdminModal';
import Loader from '@components/Loader';
import { validArray } from '@common/validArray';

interface FirmDetailsPageProps {
  setStepper: React.Dispatch<
    React.SetStateAction<'FIRM_LISTING_PAGE' | 'FIRM_DETAILS_PAGE'>
  >;
  firmDetails: any;
  stepper: 'FIRM_LISTING_PAGE' | 'FIRM_DETAILS_PAGE';
}

function FirmDetailsPage({
  setStepper,
  firmDetails,
}: FirmDetailsPageProps): React.JSX.Element {
  const [showModel, setShowModel] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const { data, isLoading } = useRESTQuery(
    ['firm-details'],
    {
      endpoint: `/firms/advisors/`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      select: res => res?.data,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  const getFirmAdmin = data => {
    const adminData = validArray(data)
      ? data.filter(item => item.isFirmAdmin)
      : [];

    return adminData;
  };

  const getFirmAdvisor = data => {
    const advisorData = validArray(data)
      ? data.filter(item => !item.isFirmAdmin)
      : [];

    return advisorData;
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <Grid container>
        <Grid
          item
          container
          justifyContent={'flex-start'}
          alignItems={'center'}
          columnGap={1}
          onClick={() => setStepper('FIRM_LISTING_PAGE')}
          sx={{
            cursor: 'pointer',
            mb: 2,
            flexWrap: 'nowrap',
          }}
        >
          <Grid item>
            <BackArrowIcon />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm="auto"
            columnGap={1}
            flexWrap="nowrap"
          >
            <Grid item>
              <p>My Firms</p>
            </Grid>
            <Grid item>/</Grid>
            <Grid item>
              <p>{firmDetails?.name}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 180px)' }}
        >
          <Grid item container sx={{ width: '98%' }}>
            <StyledHeaderDiv
              item
              container
              xs={12}
              rowGap={3}
              p={'25px 15px 15px 0px'}
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Grid item container xs={12} sm={8} md={6} lg={5}>
                <StyledRectangle height={'45px'} />
                <Grid item container direction={'column'} ml={1}>
                  <Grid
                    item
                    color={'#1d2939'}
                    fontSize={'16px'}
                    fontWeight={500}
                  >
                    {firmDetails?.name}
                  </Grid>
                  <Grid
                    item
                    container
                    fontSize={'12px'}
                    direction={'row'}
                    columnGap={0.5}
                    color={'#667085'}
                    mt={'5px'}
                  >
                    <Grid item>
                      {getFirmAdmin(data?.membersData).length > 1
                        ? `${getFirmAdmin(data?.membersData).length} Admins`
                        : `${getFirmAdmin(data?.membersData).length} Admin`}
                    </Grid>
                    <Grid item>•</Grid>
                    <Grid item>{firmDetails?.totalAdvisors} Advisors</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <AddFirmAdminButtonWrapper
                item
                container
                xs={12}
                sm={4}
                md={3}
                justifyContent={'center'}
                alignItems={'center'}
                onClick={() => setShowModel(true)}
              >
                Add Firm Administrator
              </AddFirmAdminButtonWrapper>
            </StyledHeaderDiv>
            <Grid
              item
              container
              xs={12}
              pt={'15px'}
              columnGap={2}
              rowGap={2}
              justifyContent={'space-between'}
            >
              {data?.totalStats.map((item, index) => (
                <FirmAdminCard //
                  key={index}
                  item
                  container
                  xs={12}
                  sm={5.8}
                  md={3.8}
                >
                  <Grid
                    item
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    xs={2}
                  >
                    <BoltIcon
                      width="36px"
                      height="36px"
                      fill="#E8FFF7"
                      stroke="#333"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    height={'30x'}
                    mt={'-5px'}
                    justifyContent={'flex-start'}
                    xs={10}
                    direction={'column'}
                  >
                    <Typography sx={{ fontSize: '14px', color: '#475467' }}>
                      {item?.key}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        color: '#101828',
                        marginTop: '-3px',
                      }}
                    >
                      {item?.key === 'Total AUM'
                        ? price(item.value)
                        : item.value}
                    </Typography>
                  </Grid>
                </FirmAdminCard>
              ))}
            </Grid>

            <FirmAdministratorSection
              adminData={getFirmAdmin(data?.membersData) ?? []}
              advisorData={getFirmAdvisor(data?.membersData) ?? []}
            />
          </Grid>
        </Grid>
      </Grid>
      <FirmAdminModal
        isOpen={showModel}
        onClose={() => setShowModel(false)}
        firmDetails={firmDetails}
      />
    </>
  );
}
export default FirmDetailsPage;
