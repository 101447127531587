import Box from '@mui/material/Box';
import React from 'react';

export function IconNoSidebar(): React.ReactElement {
  return (
    <Box>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4167 2.5H2.58333C2.29602 2.5 2.02047 2.59658 1.8173 2.76849C1.61414 2.94039 1.5 3.17355 1.5 3.41667V12.5833C1.5 12.8264 1.61414 13.0596 1.8173 13.2315C2.02047 13.4034 2.29602 13.5 2.58333 13.5H13.4167C13.704 13.5 13.9795 13.4034 14.1827 13.2315C14.3859 13.0596 14.5 12.8264 14.5 12.5833V3.41667C14.5 3.17355 14.3859 2.94039 14.1827 2.76849C13.9795 2.59658 13.704 2.5 13.4167 2.5ZM13.4167 12.5833H2.58333V3.41667H13.4167V12.5833Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
