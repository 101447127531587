import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';

type UseToastResult = {
  displayErrorToast: (toastMessage: string, duration?: number) => void;
  displaySuccessToast: (toastMessage: string, duration?: number) => void;
};

export const useToast = (): UseToastResult => {
  const dispatch = useDispatch();

  const displaySuccessToast = (toastMessage: string, duration = 3000) => {
    dispatch(
      globalActions.displayToast({
        duration,
        toastType: 'success',
        toastMessage,
      }),
    );
  };

  const displayErrorToast = (toastMessage: string, duration = 3000) => {
    dispatch(
      globalActions.displayToast({
        duration,
        toastType: 'error',
        toastMessage,
      }),
    );
  };

  return { displayErrorToast, displaySuccessToast };
};
