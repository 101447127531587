import SquareIcon from '@mui/icons-material/Square';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface PortfolioStatisticsBarChartProps {
  data: Array<{
    modelSetName: string;
    data: Record<string, number>;
  }>;
  ytdFinal?: boolean;
  height?: number;
}

const OPTION1_COLORS = [
  '#04ACD7', // Blue
  '#004F7E', // Dark Blue
  '#003057', // Navy Blue
  '#00CFB4', // Teal
  '#8CAC89', // Sage Green
  '#00A892', // Green
];

const OPTION2_COLORS = [
  '#F4AF00', // Yellow
  '#E87722', // Orange
  '#AF0737', // Red
  '#8A1B61', // Deep Red
  '#CF9400', // Dark Yellow
  '#C2631C', // Dark Orange
];

export const PortfolioStatisticsBarChart = ({
  data = [],
  ytdFinal = false,
  height = 400,
}: PortfolioStatisticsBarChartProps): JSX.Element => {
  const allDates = [
    ...new Set(data.flatMap(item => Object.keys(item.data))),
  ].sort((a, b) => {
    // Special handling for 'ytd' based on ytdFinal
    if (a.toLowerCase() === 'ytd') return ytdFinal ? 1 : -1;
    if (b.toLowerCase() === 'ytd') return ytdFinal ? -1 : 1;

    // Extract numbers from strings like "5_year" or "10_years"
    const getYearNumber = (str: string) => {
      const match = str.match(/^(\d+)_years?$/i);
      return match ? parseInt(match[1]) : null;
    };

    const aNum = getYearNumber(a);
    const bNum = getYearNumber(b);

    // If both are in X_year(s) format
    if (aNum !== null && bNum !== null) {
      return aNum - bNum;
    }

    // If only one is in X_year(s) format, put it after
    if (aNum !== null) return 1;
    if (bNum !== null) return -1;

    // For normal dates or other formats, use standard sorting
    return a.localeCompare(b);
  });

  const chartData = allDates
    .map(date => {
      let name = moment(date).isValid()
        ? moment(date).format('YYYY')
        : date.toLowerCase() === 'ytd'
          ? 'YTD'
          : date.replace(/_/g, ' ');

      const numberPattern = /(\d+)\s*years?/i;
      const match = name.match(numberPattern);

      if (match) {
        const number = parseInt(match[1]);
        name = name.replace(/years?/i, number > 1 ? 'yrs' : 'yr');
      }

      const dateData = data.reduce(
        (acc, item) => ({
          ...acc,
          [item.modelSetName]: item.data[date],
        }),
        {},
      );

      // Only include dates that have at least one non-null value
      const hasValidValues = Object.values(dateData).some(
        value => value !== null,
      );
      if (!hasValidValues) return null;

      return {
        name,
        ...dateData,
      };
    })
    .filter(Boolean); // Remove null elements from array

  const minValue = Math.min(
    ...chartData.flatMap(item => {
      if (!item) return [];
      return Object.entries(item)
        .filter(([key]) => key !== 'name')
        .map(([, value]) => Number(value) || 0);
    }),
  );

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={chartData}
        margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis
          label={{
            value: 'Return',
            angle: -90,
            position: 'insideLeft',
            style: { textAnchor: 'middle' },
          }}
          domain={[minValue, 'auto']}
        />
        <Tooltip formatter={value => ` ${value}%`} />
        <Legend content={props => renderLegend(props, data)} />
        {data.map((item, index) => {
          const isBenchmark = index === data.length - 1;
          const optionNumber = item.modelSetName.includes('Opt1') ? 1 : 2;
          const colorIndex =
            index %
            (optionNumber === 1
              ? OPTION1_COLORS.length
              : OPTION2_COLORS.length);
          const color = isBenchmark
            ? '#000000'
            : optionNumber === 1
              ? OPTION1_COLORS[colorIndex]
              : OPTION2_COLORS[colorIndex];

          return (
            <Bar
              key={item.modelSetName}
              dataKey={item.modelSetName}
              name={item.modelSetName}
              fill={color}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

const renderLegend = (props, data) => {
  const { payload } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {payload.map((entry, index) => {
        const isBenchmark = index === payload.length - 1;
        const item = data.find(item => item.modelSetName === entry.value);
        const optionNumber = item?.modelSetName?.includes('Opt1') ? 1 : 2;
        const colorIndex =
          index %
          (optionNumber === 1 ? OPTION1_COLORS.length : OPTION2_COLORS.length);
        const color = isBenchmark
          ? '#000000'
          : optionNumber === 1
            ? OPTION1_COLORS[colorIndex]
            : OPTION2_COLORS[colorIndex];

        return (
          <Grid item key={`item-${index}`} display="flex" alignItems="center">
            <SquareIcon
              sx={{
                color,
                width: '12px',
                height: '12px',
                marginRight: '4px',
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                color,
              }}
            >
              {item?.displayPortfolioName ||
                item?.displayBenchmarkName ||
                entry.value}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
