import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import {
  Drawer,
  IconButton,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { makeStyles, styled } from '@mui/styles';
import { usePutCase } from '../hooks/usePutCase';
import { useGetCaseById } from '../hooks/useGetCaseById';
import { parseTier } from '../AllCases';
import MilestoneAccordion from '../components/MilestoneAccordion';
import CaseStatusChip from '../components/CaseStatusChip';
import CircularProgressBar from 'components/CircularProgressBar';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDeleteCase } from '../hooks/useDeleteCase';
import DeleteCaseModal from '../components/DeleteCaseModal';
const useStyles = makeStyles(() => ({
  paper: {
    width: 720,
    backgroundColor: '#fff!important',
  },
}));

interface DetailCaseProps {
  onClose: () => void;
}

const DetailCase: React.FC<DetailCaseProps> = ({ onClose }) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const caseId = searchParams.get('caseId')!;
  const { putCaseMutate } = usePutCase();
  const { caseData, isCaseLoading } = useGetCaseById({ id: caseId! });
  const { deleteCaseMutate, isLoading } = useDeleteCase();
  const handleStatusChange = (status: string) => {
    putCaseMutate({
      id: caseId,
      status,
      successToastMessage: 'Case status updated successfully',
    });
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={!!caseId}
      classes={{ paper: classes.paper }}
    >
      <DrawerContainer>
        <ContentContainer container>
          <Layout container>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              pb="24px"
            >
              <LayoutTitle>Case details</LayoutTitle>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {/* header */}
            {!isCaseLoading && (
              <>
                <Grid item container flexDirection="column" pb="16px">
                  <Grid item pb={1}>
                    <Name>{caseData?.name || ''}</Name>
                  </Grid>
                  <Grid item>
                    <Description>{caseData?.description || ''}</Description>
                  </Grid>
                </Grid>
                <Grid item container justifyContent="space-between" pb="24px">
                  <Grid item>
                    <FeatureLabel>Status</FeatureLabel>
                    <CaseStatusChip
                      isEditable
                      value={caseData?.status || ''}
                      handleStatusChange={handleStatusChange}
                    />
                  </Grid>
                  <Grid item>
                    <FeatureLabel>Advisor</FeatureLabel>
                    <FeatureValue>{caseData?.advisorName || ''}</FeatureValue>
                  </Grid>
                  <Grid item>
                    <FeatureLabel>Tier</FeatureLabel>
                    <FeatureValue>
                      {parseTier(caseData?.tier || '')}
                    </FeatureValue>
                  </Grid>
                  <Grid item>
                    <FeatureLabel>Last Update</FeatureLabel>
                    <FeatureValue>
                      {moment(caseData?.updatedAt).format('MM/DD/YYYY h:mm A')}
                    </FeatureValue>
                  </Grid>
                </Grid>

                <Grid
                  container
                  pb="24px"
                  direction="column"
                  sx={{
                    gap: '8px',
                    backgroundColor: '#F5F5F5',
                    padding: '16px',
                    borderRadius: '4px',
                  }}
                >
                  <AccordionTitle>Case Status</AccordionTitle>
                  {caseData?.milestones?.map((milestone, index) => (
                    <MilestoneAccordion
                      key={index}
                      index={index}
                      caseData={caseData}
                      milestone={milestone}
                    />
                  ))}
                </Grid>
              </>
            )}

            {isCaseLoading ? <CircularProgressBar size={50} /> : null}
          </Layout>
        </ContentContainer>

        <Footer container>
          <Grid item container justifyContent="flex-start" gap={2}>
            <IconButton
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
              disabled={isLoading}
              sx={{
                display: 'flex',
                gap: '8px',
                height: '40px',
                borderRadius: '4px',
                '&:hover': {
                  borderRadius: '4px',
                  backgroundColor: 'rgba(225, 29, 72, 0.04)',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: '#E11D48' }} />
              ) : (
                <>
                  <DeleteOutlineIcon sx={{ color: '#E11D48' }} />
                  <Typography sx={{ color: '#E11D48' }}>Delete case</Typography>
                </>
              )}
            </IconButton>
          </Grid>
        </Footer>
      </DrawerContainer>
      <DeleteCaseModal
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleDeleteCase={() => {
          onClose();
          setIsDeleteModalOpen(false);
          deleteCaseMutate({
            id: caseId,
            successToastMessage: 'Case deleted successfully',
          });
        }}
        caseName={caseData?.name || ''}
      />
    </Drawer>
  );
};

export default DetailCase;

const Layout = styled(Grid)({
  flexDirection: 'column',
  padding: '32px',
  width: '100%',
});

const LayoutTitle = styled('p')({
  fontSize: '16px',
  lineHeight: '20px',
  fontFamily: 'inter',
  margin: '0',
  color: '#86858B',
});

const Name = styled('p')({
  fontSize: '16px',
  fontFamily: 'inter',
  fontWeight: 500,
  color: '#000',
});

const Description = styled('p')({
  fontSize: '14px',
  fontFamily: 'inter',
  color: '#535256',
});

const FeatureLabel = styled('p')({
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '15px',
  fontFamily: 'inter',
  margin: '0 0 4px',
  color: '#282829',
});

const FeatureValue = styled('p')({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  fontFamily: 'inter',
  margin: '4px 0',
  color: '#000000',
});

const AccordionTitle = styled('p')({
  paddingBottom: '10px',
  fontSize: '16px',
  fontFamily: 'inter',
  color: '#000',
});

const DrawerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const ContentContainer = styled(Grid)({
  flex: 1,
  overflowY: 'auto',
});

const Footer = styled(Grid)({
  borderTop: '1px solid #E0E0E0',
  padding: '16px 32px',
  backgroundColor: '#fff',
});
