import { actions } from '@features/Chat/slice';
import React from 'react';

import MemoAnimator from '@components/UIComponents/MemoAnimator';
import {
  getChatInitResponseState,
  getSampleQuestionsState,
} from '@features/Chat/selector';
import MemoMagic from '@icons/Magic';
import Magic from '@images/Magic.svg';
import { getConvertedQuestion } from '@pages/Chat/NewHomePage';
import { EQuestionToNavigate } from 'app/enums';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MemoSpinnerContainer } from './styles';
import PrompQuestions from '@components/PrompQuestions';

export const MemoSpinner = ({
  hover,
}: {
  hover: boolean;
}): React.JSX.Element => {
  return (
    <MemoSpinnerContainer>
      {hover ? (
        <div className="memo-icon">
          <MemoMagic height={16} width={16} />
        </div>
      ) : (
        <img src={Magic} alt="Magic image" />
      )}
    </MemoSpinnerContainer>
  );
};
export const WhatElseButton = (): React.JSX.Element => {
  const dispatch = useDispatch();

  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );
  const { sampleQuestions: questionsData } = useSelector(
    getSampleQuestionsState,
  );

  const loadQuestions = () => {
    dispatch(actions.searchSampleQuestion({ isSampleQuestionAsked: false }));
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState,
          {
            data: 'Display Suggested Questions',
            isSender: true,
            time: '',
            id: '',
            type: 'text',
            isChatHistory: true,
            mock: true,
          },
          {
            id: '65b8f06d84d66bb8215d3173',
            isSender: false,
            time: '',
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,

            data: questionsData,
          },
        ],
      }),
    );
  };
  return (
    <MemoAnimator onClick={loadQuestions} text={'What else can I ask Sage?'} />
  );
};

export const SampleQuestions = ({
  data,
  hideWhatElse = false,
}: {
  data: any;
  hideWhatElse?: boolean;
}): React.JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChatStreaming = localStorage.getItem('streaming') as string;

  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );
  const { sampleQuestions: questionsData } = useSelector(
    getSampleQuestionsState,
  );

  const loadQuestions = () => {
    dispatch(actions.searchSampleQuestion({ isSampleQuestionAsked: false }));
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState,
          {
            data: 'Display Suggested Questions',
            isSender: true,
            time: '',
            id: '',
            type: 'text',
            isChatHistory: true,
            mock: true,
          },
          {
            id: '65b8f06d84d66bb8215d3173',
            isSender: false,
            time: '',
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,

            data: questionsData,
          },
        ],
      }),
    );
  };

  const triggerQuery = (query: string) => {
    query = getConvertedQuestion(query);
    switch (true) {
      case query === EQuestionToNavigate.WhichViewpointsImpactClients:
        navigate('/actions');
        return;
      default:
        break;
    }

    // If not navigating
    if (isChatStreaming === 'true') {
      dispatch(actions.setSearchQuery(query));
    } else {
      dispatch(actions.searchQueryRequest({ query }));
      // this ensures that regardless of the streaming status (true or not), the logging or recording of sample questions is consistently executed whenever a question is asked
      dispatch(
        actions.searchSampleQuestion({
          isSampleQuestionAsked: true,
          question: query,
        }),
      );
    }
  };

  const questions = data?.map(item => item.head) || [];
  if (!hideWhatElse) questions.push('What else can I ask Sage?');

  return (
    <PrompQuestions
      showDividers
      itemPy="10px"
      questions={questions}
      handleQuestionClick={question => {
        if (question === 'What else can I ask Sage?') {
          loadQuestions();
        } else {
          triggerQuery(question);
        }
      }}
    />
  );
};

function SampleCategories({ data }: { data: any }): React.JSX.Element {
  const dispatch = useDispatch();
  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );

  const getQuestions = (category: any) => {
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState.slice(0, initHistoryState.length - 1),

          {
            id: '65b8f06d84d66bb8215d3173',
            isSender: false,
            time: '',
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,
            data: [
              {
                type: 'text',
                content: 'Here you go! Select a prompt below.',
              },
              {
                type: 'sample_questions',
                content: category?.list.map(question => {
                  return {
                    head: question,
                  };
                }),
              },
            ],
          },
        ],
      }),
    );
  };

  return (
    <PrompQuestions
      showDividers
      itemPy="10px"
      questions={data?.map(item => item.head)}
      handleQuestionClick={question =>
        getQuestions(data.find(item => item.head === question))
      }
    />
  );
}

export default SampleCategories;
