import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  RealmPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import React from 'react';

import { Grid } from '@mui/material';
import styles from './styles.module.css';

export type EditableFieldProps<
  State extends Record<string, string | number | Date>,
> = {
  readonly field: keyof State;
  readonly state: State;
  readonly setState: (state: State) => void;
  readonly type?: 'inline' | 'block';
  readonly editable?: boolean;
  readonly editorClassName?: string;
};

export function EditableField<
  State extends Record<string, string | number | Date>,
>({
  field,
  state,
  setState,
  type = 'inline',
  editable = true,
  editorClassName = '',
}: EditableFieldProps<State>): React.ReactElement {
  const mdxEditorRef = React.useRef<MDXEditorMethods>(null);

  const handleChange = React.useCallback(
    (value: string) => {
      setState({ ...state, [field]: value });
    },
    [setState, state, field],
  );

  const value = React.useMemo(() => state[field], [state[field]]);

  React.useEffect(() => {
    mdxEditorRef.current?.setMarkdown(value as string);
  }, [value]);

  const toolbarContents = React.useCallback(
    () => (
      <>
        <BlockTypeSelect />
        <ListsToggle options={['number', 'bullet']} />
        <BoldItalicUnderlineToggles />
      </>
    ),
    [],
  );

  const plugins = React.useMemo(() => {
    const plugins: RealmPlugin[] = [];

    if (type === 'block' && editable) {
      plugins.push(markdownShortcutPlugin());
      plugins.push(listsPlugin());
      plugins.push(headingsPlugin());
      plugins.push(
        toolbarPlugin({
          toolbarClassName: styles.toolbar,
          toolbarContents,
        }),
      );
    }
    return plugins;
  }, [type, toolbarContents]);

  return (
    <Grid container className={styles.editableField} data-editable={editable}>
      <MDXEditor
        ref={mdxEditorRef}
        autoFocus={false}
        readOnly={!editable}
        markdown={value as string}
        onChange={handleChange}
        className={`${styles.mdxeditorRoot} ${styles[editorClassName] || ''}`}
        contentEditableClassName={
          !editorClassName ? styles.contentEditable : styles[editorClassName]
        }
        plugins={plugins}
        trim={true}
      />
    </Grid>
  );
}
