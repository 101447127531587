import React from 'react';

export function ChevronDownIcon(): React.ReactElement {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6848 5.55977L7.30977 9.93477C7.26914 9.97545 7.22089 10.0077 7.16778 10.0297C7.11467 10.0518 7.05774 10.0631 7.00024 10.0631C6.94275 10.0631 6.88582 10.0518 6.83271 10.0297C6.7796 10.0077 6.73134 9.97545 6.69071 9.93477L2.31571 5.55977C2.23362 5.47768 2.1875 5.36634 2.1875 5.25024C2.1875 5.13415 2.23362 5.0228 2.31571 4.94071C2.3978 4.85862 2.50915 4.8125 2.62524 4.8125C2.74134 4.8125 2.85268 4.85862 2.93477 4.94071L7.00024 9.00673L11.0657 4.94071C11.1064 4.90006 11.1546 4.86782 11.2077 4.84582C11.2608 4.82382 11.3178 4.8125 11.3752 4.8125C11.4327 4.8125 11.4897 4.82382 11.5428 4.84582C11.5959 4.86782 11.6441 4.90006 11.6848 4.94071C11.7254 4.98136 11.7577 5.02962 11.7797 5.08273C11.8017 5.13584 11.813 5.19276 11.813 5.25024C11.813 5.30773 11.8017 5.36465 11.7797 5.41776C11.7577 5.47087 11.7254 5.51913 11.6848 5.55977Z"
        fill="#343330"
      />
    </svg>
  );
}
