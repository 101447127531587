import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Grid } from '@mui/material';

import PageOverlay from '@components/PageOverlay';

import Input from '@components/Input';

import sageNew from '@images/sageNew.svg';
import loginImg from '@images/loginImg.svg';
import SageBlackLogo from '@icons/sage_logo_black';

import { IFormInput } from './types';
import {
  Form,
  FormField,
  FormFieldError,
  FormWrapper,
  LoginFormWrapper,
  LoginHeaderText,
  LoginHeaderTextWrapper,
  LoginInputBoxHeaderText,
  LoginSubHeaderText,
  LoginWrapper,
  LogoWrapper,
  StockImg,
} from '../Login/styles';

import { Button } from '@components/Button';

import checkmark from '@images/Checked.svg';
import PasswordSuccessModal from './PasswordSuccessModal';
import crossIcon from '@images/cross.svg';
import { useRESTQuery } from '@hooks/useRESTQuery';
import { Spinner } from '@pages/IntegrationPage/styles';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import {
  FirstItemsContainer,
  LoaderContainer,
  ValidationContainer,
  ValidationFirstBoxWrapper,
  ValidationFirstContentContainer,
} from './styles';

const VALIDATION_DATA = [
  {
    logo: <img src={checkmark} alt="Minimum character check mark" />,
    text: 'Min. 14 Characters',
  },
  {
    logo: <img src={checkmark} alt="Special character check mark" />,
    text: '1 Special Character',
  },
  {
    logo: <img src={checkmark} alt="Password match check mark" />,
    text: 'Password Match',
  },
  {
    logo: <img src={checkmark} alt="Uppercase check mark" />,
    text: 'At Least 1 Uppercase',
  },
  {
    logo: <img src={checkmark} alt="Number check mark" />,
    text: 'At Least 1 Number',
  },
];

function Signup(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const confirmPassword = useWatch({ control, name: 'confirmPassword' });
  const password = useWatch({ control, name: 'password' });
  const [isMinLengthValid, setMinLengthValid] = React.useState(true);
  const [hasSpecialChar, setHasSpecialChar] = React.useState(true);
  const [isPasswordMatch, setPasswordMatch] = React.useState(true);
  const [hasUppercase, setHasUppercase] = React.useState(true);
  const [hasNumber, setHasNumber] = React.useState(true);
  const [isFormValid, setIsFormValid] = React.useState(false);

  const [isConfirmButtonLoading, setConfirmButtonLoading] =
    React.useState(false);

  React.useEffect(() => {
    const newPassword = password || '';
    setMinLengthValid(newPassword.length >= 14);
    setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(newPassword));
    setPasswordMatch(newPassword === confirmPassword);
    setHasUppercase(/[A-Z]/.test(newPassword));
    setHasNumber(/\d/.test(newPassword));

    // Update the overall validation status
    setIsFormValid(
      newPassword.length >= 14 &&
        /[!@#$%^&*(),.?":{}|<>]/.test(newPassword) &&
        newPassword === confirmPassword &&
        /[A-Z]/.test(newPassword) &&
        /\d/.test(newPassword),
    );
  }, [confirmPassword, password]);

  const onSubmitForm: SubmitHandler<IFormInput> = async () => {
    setConfirmButtonLoading(true);

    try {
      // Call setPasswordApi and wait for the response
      await setPasswordApi();
    } catch (error) {
      console.error('Error updating password:', error);
    } finally {
      setConfirmButtonLoading(false);
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromURL = urlParams.get('token');

  const {
    refetch: verifyTokenApi,
    data,
    error,
    isLoading,
  } = useRESTQuery(
    ['verifyToken'],
    {
      endpoint: 'user/verifyToken',
      method: 'POST',
      body: {
        token: tokenFromURL || '',
      },
    },
    {
      enabled: true,
      onSuccess: res => {
        // Handle the successful response
        if (res && res.valid === true) {
          // Token is verified
          console.log('Token verification successful');
        } else {
          // Token is not verified
          console.error('Token verification failed:', res);
        }
      },
      onError: error => {
        console.error(error);
      },
    },
  );
  console.log('Data:', data);
  console.log('Error:', error);

  const { refetch: setPasswordApi } = useRESTQuery(
    ['setPassword'],
    {
      endpoint: 'user/password',
      method: 'POST',
      body: {
        token: tokenFromURL || '',
        password: confirmPassword || '',
      },
    },
    {
      enabled: false,
      onSuccess: res => {
        // Check for a successful response and open the success modal
        if (res && res.message) {
          setIsSuccessModalOpen(true);
        }
      },
      onError: error => {
        console.error('Error updating password:', error);
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage: 'Failed to update password. Please try again..',
          }),
        );
      },
    },
  );

  React.useEffect(() => {
    verifyTokenApi();
  }, [verifyTokenApi, tokenFromURL]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Spinner />
      </LoaderContainer>
    );
  }

  const handleConfirmClick = () => {
    navigate('/login');
  };

  return (
    <LoginWrapper
      container
      justifyContent={'center'}
      sx={{
        left: 0,
        right: 0,
      }}
    >
      <Grid
        width="50%"
        height="100%"
        position={'relative'}
        top={0}
        left={0}
        zIndex={3}
      >
        <Grid width="100%" height="100%" position={'relative'} top={0} left={0}>
          <StockImg alt="Login image" src={loginImg} />
        </Grid>
        <Grid
          position={'absolute'}
          width={'auto'}
          top={'42%'}
          bottom={0}
          left={'33%'}
          right={0}
          margin={'0 auto'}
        >
          <SageBlackLogo fill={'#fff'} width={'50%'} />
        </Grid>
      </Grid>
      <LoginFormWrapper position={'relative'} zIndex={2}>
        <FormWrapper
          item
          container
          sx={{
            margin: '0 auto',
            height: 'auto',
          }}
        >
          <LogoWrapper item xs={12}>
            <img alt="Signup image sage" src={sageNew} />
          </LogoWrapper>
          <LoginHeaderTextWrapper
            item
            xs={12}
            sx={{
              flexDirection: 'column',
            }}
          >
            <LoginHeaderText>Welcome To Sage</LoginHeaderText>
            <LoginSubHeaderText>Please set your password </LoginSubHeaderText>
          </LoginHeaderTextWrapper>
          <Form
            style={{
              width: '100%',
              maxWidth: '100%',
              padding: '4rem',
              paddingTop: '2rem',
              paddingBottom: '1rem',
              gap: '24px',
            }}
            onSubmit={handleSubmit(onSubmitForm)}
            autoComplete="off"
          >
            <FormField>
              <LoginInputBoxHeaderText
                mb={0.5}
              >{`Enter password`}</LoginInputBoxHeaderText>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id={'outlined-basic'}
                    type={'password'}
                    autoComplete={'off'}
                    placeholder={'Create a secure password'}
                    height={'40px'}
                  />
                )}
                rules={{
                  validate: {
                    isNotEmpty: val =>
                      val.trim() !== '' || 'Please enter password',
                  },
                }}
              />
            </FormField>
            <FormField>
              <LoginInputBoxHeaderText
                mb={0.5}
              >{`Re-enter password`}</LoginInputBoxHeaderText>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id={'outlined-basic'}
                    type={'password'}
                    autoComplete={'off'}
                    placeholder={'Confirm your password'}
                    height={'40px'}
                  />
                )}
                rules={{
                  validate: {
                    isNotEmpty: val => {
                      if (!val.trim()) {
                        return 'Please enter password';
                      }
                      return true;
                    },
                  },
                }}
              />
            </FormField>
            {errors.password && (
              <FormFieldError role="alert">
                {errors.password?.message}
              </FormFieldError>
            )}

            <FormField>
              <Button
                type={'submit'}
                disabled={!isFormValid || isConfirmButtonLoading}
                variant={'contained'}
                width={'100%'}
                customHeight={'40px'}
                style={{
                  textTransform: 'capitalize',
                  borderRadius: '20px',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {isConfirmButtonLoading ? <Spinner /> : 'Confirm'}
              </Button>
            </FormField>
          </Form>
          <ValidationContainer>
            <ValidationFirstBoxWrapper>
              {VALIDATION_DATA.slice(0, 3).map((item, index) => (
                <ValidationFirstContentContainer key={index}>
                  <FirstItemsContainer
                    style={{
                      paddingLeft:
                        (index === 0 && !isMinLengthValid) ||
                        (index === 1 && !hasSpecialChar) ||
                        (index === 2 && !isPasswordMatch)
                          ? '2px'
                          : '10px',
                    }}
                  >
                    <div
                      style={{
                        marginRight:
                          (index === 0 && !isMinLengthValid) ||
                          (index === 1 && !hasSpecialChar) ||
                          (index === 2 && !isPasswordMatch)
                            ? '2px'
                            : '10px',
                        display: 'flex',
                      }}
                    >
                      {index === 0 ? (
                        isMinLengthValid ? (
                          <img src={checkmark} alt="First check mark image" />
                        ) : (
                          <img
                            src={crossIcon}
                            alt="Cross image"
                            style={{ height: '35px' }}
                          />
                        )
                      ) : index === 1 ? (
                        hasSpecialChar ? (
                          <img src={checkmark} alt="Second check mark image" />
                        ) : (
                          <img
                            src={crossIcon}
                            alt="Cross image"
                            style={{ height: '35px' }}
                          />
                        )
                      ) : index === 2 ? (
                        isPasswordMatch ? (
                          <img src={checkmark} alt="Third check mark image" />
                        ) : (
                          <img
                            src={crossIcon}
                            alt="Cross image"
                            style={{ height: '35px' }}
                          />
                        )
                      ) : null}
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#101828',
                      }}
                    >
                      {item?.text}
                    </div>
                  </FirstItemsContainer>
                </ValidationFirstContentContainer>
              ))}
            </ValidationFirstBoxWrapper>
            <ValidationFirstBoxWrapper>
              {VALIDATION_DATA.slice(3).map((item, index) => (
                <ValidationFirstContentContainer key={index}>
                  <FirstItemsContainer
                    style={{
                      paddingLeft:
                        (index === 0 && !isMinLengthValid) ||
                        (index === 1 && !hasSpecialChar) ||
                        (index === 2 && !isPasswordMatch)
                          ? '2px'
                          : '10px',
                    }}
                  >
                    <div
                      style={{
                        marginRight:
                          (index === 0 && !isMinLengthValid) ||
                          (index === 1 && !hasSpecialChar) ||
                          (index === 2 && !isPasswordMatch)
                            ? '2px'
                            : '10px',
                        display: 'flex',
                      }}
                    >
                      {index === 0 ? (
                        hasUppercase ? (
                          <img src={checkmark} alt="Fourth check mark image" />
                        ) : (
                          <img
                            src={crossIcon}
                            alt="Cross image"
                            style={{ height: '35px' }}
                          />
                        )
                      ) : index === 1 ? (
                        hasNumber ? (
                          <img src={checkmark} alt="Fifth check mark image" />
                        ) : (
                          <img
                            src={crossIcon}
                            alt="Cross image"
                            style={{ height: '35px' }}
                          />
                        )
                      ) : null}
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#101828',
                      }}
                    >
                      {item?.text}
                    </div>
                  </FirstItemsContainer>
                </ValidationFirstContentContainer>
              ))}
            </ValidationFirstBoxWrapper>
          </ValidationContainer>
        </FormWrapper>
        <PageOverlay />
      </LoginFormWrapper>
      <PasswordSuccessModal
        isOpen={isSuccessModalOpen}
        handleConfirmClick={handleConfirmClick}
      />
    </LoginWrapper>
  );
}

export default Signup;
