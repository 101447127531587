import { Grid } from '@mui/material';
import React from 'react';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <h2>{message}</h2>
    </Grid>
  );
};

export default ErrorMessage;
