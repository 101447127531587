import { getClients } from './getClients';
import { getEnterpriseAiModules } from './getEnterpriseAiModules';
import { getEnterpriseQuestions } from './getEnterpriseQuestions';
import { getPooCommentaries } from './getPooCommentaries';
import { putClientNextBestActions } from './putClientNextBestActions';

export const handlers = [
  { enabled: false, handler: getClients },
  { enabled: false, handler: getEnterpriseAiModules },
  { enabled: false, handler: getEnterpriseQuestions },
  { enabled: false, handler: putClientNextBestActions },
  { enabled: false, handler: putClientNextBestActions },
  { enabled: false, handler: getPooCommentaries },
]
  .filter(({ enabled }) => enabled)
  .map(({ handler }) => handler);
