import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';
import { initialState } from './slice';

const selectSlice = (state: RootState) => state?.chat || initialState;

export const getQueryLoaderState = createSelector(
  [selectSlice],
  state => state.processingQuery,
);

export const getSearchedQuery = createSelector(
  [selectSlice],
  state => state.query,
);

export const getSearchedQueryData = createSelector(
  [selectSlice],
  state => state.queryData,
);

export const getChatInitResponseState = createSelector([selectSlice], state => {
  return {
    ...state.chatInitResponse,
    loading: state.loading,
  };
});

export const getChatFeedbackState = createSelector(
  [selectSlice],
  state => state.feedback,
);

export const isSampleQuestionsCalled = createSelector([selectSlice], state => {
  return {
    isSampleQuestionAsked: state.isSampleQuestionAsked,
  };
});

export const getSampleQuestionsState = createSelector([selectSlice], state => {
  return {
    sampleQuestions: state.sampleQuestions,
  };
});

export const getEventStreamData = createSelector(
  [selectSlice],
  state => state.eventStream,
);
