import axios from 'axios';
import { settings } from 'config';
import instance from 'utils/instances';

export interface IAddClient {
  firstName: string;
  lastName: string;
  email: string;
  advisorId: string | null;
  isProspect: boolean;
}
interface ITickerWeights {
  ticker: string;
  weight: number;
  tickerName?: any;
}

export interface IAddPortfolio {
  name: string;
  value: number;
  holdings: ITickerWeights[];
  clientId: string;
  advisorId: string | null;
}
export interface IAddPortfolioPayload {
  clientId: string;
  accountNumber: string;
  name: string;
  // investmentObjective: string;
  // riskProfile: string;
  holdings: ITickerWeights[];
  // advisorId: string | null;
  // portfolioConstraints: string;
  portfolioId?: string;
}
interface IAddRestriction {
  clientId: string;
  restrictions: string[];
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AddClient = async (payload: IAddClient) => {
  const createResponse = await instance.post('/client/', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AddPortfolioForClient = async (
  payload: IAddPortfolio | IAddPortfolioPayload,
): Promise<any> => {
  const createResponse = await instance.post('/client/portfolio', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UpdatePortfolioForClient = async (
  payload: IAddPortfolio | IAddPortfolioPayload,
): Promise<any> => {
  const createResponse = await instance.put('/client/portfolio', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AddRestrictionsForClient = async (payload: IAddRestriction) => {
  const createResponse = await instance.patch('/client/restrictions', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchTickerSearchResults = async (query: string) => {
  try {
    if (query !== '') {
      const response = await axios.get(
        `${settings.magnifi.baseUrl}/search/api/search/search-queries-by-frequency?q=${query}&universe=etf,etn,mf,cef,share`,
        {
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
        },
      );
      return response?.data?.investment_suggestions;
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const takeAssessment = async (payload: any) => {
  const response = await instance.post('/riskAssessment/link', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return response?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addNewModel = async (payload: any): Promise<any> => {
  const createResponse = await instance.post('/model', payload, {
    headers: {
      'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
    },
  });
  return createResponse?.data;
};

type UpdateClientNotificationQueryParams = {
  notificationId: string;
};

export const markAsReadClientNotificacion = async (
  query: UpdateClientNotificationQueryParams,
): Promise<any> => {
  const createResponse = await instance.put(
    `/v2/clients/nextBestActions/${query.notificationId}`,
    {},
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
  );
  return createResponse?.data;
};
