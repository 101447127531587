import React from 'react';
import { EditableField } from '../../components/EditableField';
import { type Slide } from '../../_data';
import { Grid } from '@mui/material';
import Footer from '../Footer';
export type Slide8ContentType = [
  {
    field: 'title';
    label: string;
    value: string;
    type: 'editableTextarea';
  },

  {
    field: 'text';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
];

export const Slide8Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide8ContentType>;
    onChangeSlide: (
      slide: Slide<Slide8ContentType>,
      content: Slide8ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        sx={{
          padding: '12px 12px 0 12px',
          height: '100%',
          width: '100%',
        }}
        direction="row"
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          sx={{
            height: 'calc(100% - 48px)',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              fontFamily: 'Roboto',
              height: 'fit-content',
            }}
          >
            <EditableField
              field={'title'}
              slide={slide}
              onSlideContentChange={onChangeSlide}
              style={{
                fontSize: '28px',
                color: '#004F7E',
                fontFamily: 'Roboto',
                fontWeight: '500',
                paddingBottom: '12px',
              }}
            />
            <EditableField
              field={'text'}
              slide={slide}
              onSlideContentChange={onChangeSlide}
              editorClassName="slide8-editor"
              style={{
                height: '560px',
                '& [aria-label="editable markdown"]': {
                  display: 'grid',
                  gridTemplateColumns: '180px 1fr',
                  padding: '40px 140px !important',
                },
                '& h2': {
                  fontSize: '15px',
                  fontWeight: '600',
                  textTransform: 'uppercase',
                  color: '#004F7E',
                  padding: '12px 12px',
                },
                '& :is(h2, ul):not(:last-of-type)': {
                  borderBottom: '1px solid #cde5fe',
                },
                '& ul': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  margin: '0',
                  padding: '12px 12px',
                  listStyleType: 'none',
                },
                '& li': {
                  margin: '0',
                },
              }}
            />
          </Grid>
        </Grid>
        <Footer
          numberPage={slide.slideTemplateId}
          text="For Financial Advisor use only"
        />
      </Grid>
    );
  },
);

export default Slide8Content;
