import { getUserInfo } from './api';
import { AllEffect, call, all, put, takeLatest } from 'redux-saga/effects';

import { actions } from './slice';
import { actions as globalActions } from 'app/features/Global/slice';

function* userInfo(request: any): Generator<any, void, any> {
  try {
    const { payload } = request;
    localStorage.setItem('x-tifin-ai-token', payload.token);

    if (!payload.token) throw '-- Token is missing in payload --';

    // set streaming to true by default on sign-in
    localStorage.setItem('streaming', 'true');

    yield put(actions.userInfoFetch());
    const response: any = yield call(getUserInfo, payload.token);

    if (response) {
      localStorage.setItem('tifin-ai-skipCache', '1');
      localStorage.setItem(
        'tifin-ai-loggedUser',
        JSON.stringify(response.data),
      );
      localStorage.setItem('tifin-ai-user-type', response.data?.roles[0]);
      yield put(
        actions.userInfoSuccess({
          email: response.data?.email,
          role: response.data?.roles[0],
          advisoryFirmId: response.data?.advisoryFirmId,
          advisorId: response.data?.advisorId,
          lastName: response.data?.lastName,
          firstName: response.data?.firstName,
          enterpriseId: response.data?.enterpriseId,
          oktaUid: response.data?.oktaUid,
          advisoryFirmName: response.data?.advisoryFirmName,
          expertMode: response.data?.expertMode,
          address: response.data?.address,
          city: response.data?.city,
          state: response.data?.state,
          zipcode: response.data?.zipcode,
          profilePicUrl: response.data?.profilePicUrl,
          termsAndConditionsAcceptedAt:
            response.data?.termsAndConditionsAcceptedAt,
          summary: {
            id: response.data?.summary?.id,
            noOfClients: response.data?.summary?.noClients,
            totalAum: response.data?.summary?.totalAum,
            recentlyViewed: response.data?.summary?.recentlyViewed || [],
          },
        }),
      );
    } else {
      throw response;
    }
  } catch (error: any) {
    console.error('Async action error:', error);
    console.error(error);
    if (error && error.message) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: error?.message,
        }),
      );
    }
  }
}

export function* profileSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([takeLatest(actions.userInfoRequest.type, userInfo)]);
}
