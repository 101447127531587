import React from 'react';
import styled from 'styled-components';

interface CustomLinearProgressProps {
  value: string;
  title: string;
}

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 5px;
  background: #f0f0f0;
  border-radius: 20px;
`;

const Bar = styled.div<{ value: number; title: string }>`
  width: ${props => `${props.value}%`};
  height: 100%;
  background: ${props => {
    const isCumulativeReturn = props.title === 'Cumulative Return';
    const isAboveAverage = props.value > 50;
    const isAverage = props.value === 50;

    if (isCumulativeReturn) {
      if (isAboveAverage) {
        return '#4caf50'; // green for above average
      } else if (isAverage) {
        return '#ffc107'; // yellow for average
      } else {
        return '#ff4d4d'; // red for below average
      }
    } else {
      if (isAboveAverage) {
        return '#ff4d4d'; // red for above average
      } else if (isAverage) {
        return '#ffc107'; // yellow for average
      } else {
        return '#4caf50'; // green for below average
      }
    }
  }};
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px 0 0 20px;
`;

const Label = styled.span`
  position: absolute;
  top: 12px;
  font-size: 9px;
  color: #000;
`;

const LowLabel = styled(Label)`
  left: 0;
`;

const AvgLabel = styled(Label)`
  left: 45%;
`;

const HighLabel = styled(Label)`
  left: 88%;
`;

const CustomLinearProgress: React.FC<CustomLinearProgressProps> = ({
  value,
  title = '',
}) => {
  let numericValue: number;

  switch (value) {
    case 'Average':
      numericValue = 50;
      break;
    case 'Low':
      numericValue = 10;
      break;
    case 'High':
      numericValue = 90;
      break;
    case 'Below Average':
      numericValue = 30;
      break;
    case 'Above Average':
      numericValue = 70;
      break;
    default:
      numericValue = 50;
  }

  return (
    <Container>
      <Bar value={numericValue} title={title}></Bar>
      <LowLabel>Low</LowLabel>
      <AvgLabel>Avg</AvgLabel>
      <HighLabel>High</HighLabel>
    </Container>
  );
};

export default CustomLinearProgress;
