/* eslint-disable react/prop-types */
import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Grid, Typography, styled } from '@mui/material';

interface DataPoint {
  name: string;
  value: number;
}

interface PieChartDataProps {
  data: DataPoint[];
}

function MarketplacePieChart({ data }: PieChartDataProps): React.JSX.Element {
  const colors = [
    '#00A9D7',
    '#F540DA',
    '#76E0D3',
    '#9A18F5',
    '#F7A206',
    '#FF5733',
    '#C70039',
    '#900C3F',
    '#581845',
  ];

  return (
    <Grid
      container
      item
      direction="row"
      xs={12}
      justifyContent="center"
      alignItems="center"
      height="100%"
      mb={5}
      border={1}
      borderRadius={1}
      borderColor={'#E4E7EC'}
    >
      <PieChart width={340} height={300}>
        <Tooltip
          formatter={(value, name, props) => {
            const percentage = props?.payload?.value;
            return [`${name}: ${percentage.toFixed(2)}%`];
          }}
        />
        <Pie
          data={data}
          cx={170}
          cy={145}
          innerRadius={80}
          outerRadius={130}
          fill="black"
          paddingAngle={2}
          dataKey="value"
          startAngle={90}
          endAngle={-360}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
      {data.length > 0 && (
        <Grid
          container
          item
          xs={9}
          sm={8}
          md={6}
          lg={6}
          xl={5}
          justifyContent="center"
          alignItems="center"
        >
          {data.map((item, index) => (
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={8}
              key={index}
              direction="row"
              mt={1}
            >
              <div
                style={{
                  display: 'flex',
                  width: '16px',
                  height: '16px',
                  backgroundColor: colors[index % colors.length],
                  marginRight: '15px',
                  borderRadius: '4px',
                }}
              />
              <PieChartLabel>{item.name}</PieChartLabel>
              <Grid container item xs justifyContent="end">
                <PieChartValue>{item.value.toFixed(2) + '%'}</PieChartValue>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default MarketplacePieChart;

const PieChartLabel = styled(Typography)`
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #101828;
  min-width: 100px;
`;

const PieChartValue = styled(Typography)`
  font-family: inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.36px;
  color: #101828;
`;
