// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__uN1Um {
  padding: 32px !important;
}

.styles_subtitle__nNfQI {
  font-family: Roboto;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  vertical-align: middle;
  padding-bottom: 10px;
  color: #004f7e;
}

.styles_paragraph__NcA-X {
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 8px !important;
  line-height: 11px !important;
  letter-spacing: 0% !important;
  vertical-align: middle;
  margin-bottom: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/premium-report/components/disclosures/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,yBAAyB;EACzB,4BAA4B;EAC5B,6BAA6B;EAC7B,sBAAsB;EACtB,8BAA8B;AAChC","sourcesContent":[".container {\n  padding: 32px !important;\n}\n\n.subtitle {\n  font-family: Roboto;\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 100%;\n  vertical-align: middle;\n  padding-bottom: 10px;\n  color: #004f7e;\n}\n\n.paragraph {\n  font-family: Roboto !important;\n  font-weight: 400 !important;\n  font-size: 8px !important;\n  line-height: 11px !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle;\n  margin-bottom: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__uN1Um`,
	"subtitle": `styles_subtitle__nNfQI`,
	"paragraph": `styles_paragraph__NcA-X`
};
export default ___CSS_LOADER_EXPORT___;
