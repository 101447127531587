import React from 'react';
import { StatusFilterContainer } from './styles';

type Status = {
  title: string;
  value: string;
  badgeNumber?: number;
  badgeColor?: string;
};

type StatusFilterProps = {
  currentStatus: string;
  statuses: Status[];
  handleStatusChange: (value: string) => void;
};

const StatusFilter = (props: StatusFilterProps): React.JSX.Element => {
  const getItemClassName = status =>
    `item ${props.currentStatus === status.value ? 'selected' : ''} ${status.badgeNumber === undefined ? 'empty-badge' : ''} ${status?.badgeNumber === 0 ? 'disabled' : ''}`;

  const handleStatusClick = (status: Status) => {
    if (status.badgeNumber === undefined || status.badgeNumber > 0) {
      props.handleStatusChange(status.value);
    }
  };

  return (
    <StatusFilterContainer>
      {props.statuses.map((status, index) => (
        <li
          key={index}
          className={getItemClassName(status)}
          onClick={() => handleStatusClick(status)}
        >
          <span className="title">{status.title}</span>
          <span className="badge" style={{ background: status.badgeColor }}>
            {status.badgeNumber}
          </span>
        </li>
      ))}
    </StatusFilterContainer>
  );
};

export default StatusFilter;
