import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type UseGetDownloadPerformanceReportPDFArguments = {
  caseId: string;
  actions: string;
};

type UseGetDownloadPerformanceReportPDFResult = {
  url: string | undefined;
  isLoading: boolean;
};

type UseGetDownloadPerformanceReportPDFResponse = {
  data: string;
};

export const useGetDownloadPerformanceReportPDF = ({
  caseId,
  actions,
}: UseGetDownloadPerformanceReportPDFArguments): UseGetDownloadPerformanceReportPDFResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } =
    useQuery<UseGetDownloadPerformanceReportPDFResponse>(
      ['cases', caseId, actions],
      fetchQuery({
        path: `/v2/cases/${caseId}/milestones/actions/${actions}/download`,
      }),
      { cacheTime: 30000, enabled: !!caseId && !!actions },
    );

  return {
    url: data?.data || '',
    isLoading,
  };
};
