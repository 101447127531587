import { type Slide } from './_data';
import html2pdf from 'html2pdf.js';
import { jsPDF } from 'jspdf';
//import * as PDFLib from 'pdf-lib';

export const getSlideField = (
  slide: Slide<any>,
  field: string,
): Record<string, any> | undefined => {
  if (!slide?.content || typeof field !== 'string') {
    return undefined;
  }
  return Object.freeze({ ...slide.content.find(item => item.field === field) });
};

export const createGoogleApiRequests = (slides: Slide<any>[]): any[] => {
  if (!Array.isArray(slides)) {
    return [];
  }

  return slides.flatMap(slide => {
    if (!slide?.content || !slide.slideTemplateId) {
      return [];
    }

    const editableFields = slide.content.filter(content => {
      if (!content?.type || !content.field) return false;
      return (
        content.type === 'editableText' || content.type === 'editableTextarea'
      );
    });

    return editableFields.map(editableField => ({
      replaceAllText: Object.freeze({
        pageObjectIds: [slide.slideTemplateId],
        containsText: {
          text: `{{${editableField.field}}}`,
          matchCase: true,
        },
        replaceText: String(editableField.value ?? ''),
      }),
    }));
  });
};

export const downloadPdfSlides = async (
  slides: HTMLElement[],
  filename: string,
): Promise<void> => {
  const width = 1280;
  const height = 720;
  // Crear una instancia de jsPDF con valores explícitos
  const doc = new jsPDF('landscape', 'px', [width, height]);

  // Configuración para html2canvas
  const html2canvasOptions = {
    scale: 2,
    useCORS: true,
    letterRendering: true,
    allowTaint: false,
    logging: false,
    width: width,
    height: height,
  };

  // Procesar cada slide individualmente
  for (let i = 0; i < slides.length; i++) {
    const slide = slides[i];

    // Clonar el slide actual para manipularlo sin afectar el original
    const slideClone = slide.cloneNode(true) as HTMLElement;
    slideClone.style.width = `${width}px`;
    slideClone.style.height = `${height}px`;

    // Crear un contenedor temporal para el slide
    const container = document.createElement('div');
    container.style.width = `${width}px !important`;
    container.style.height = `${height}px !important`;
    container.style.maxWidth = `${width}px !important`;
    container.style.maxHeight = `${height}px !important`;
    container.appendChild(slideClone);
    document.body.appendChild(container);

    try {
      // Usar html2pdf para obtener la imagen del slide
      const pageImage = await html2pdf()
        .from(container)
        .set({
          html2canvas: html2canvasOptions,
        })
        .outputImg();

      // Añadir una nueva página para todos los slides excepto el primero
      if (i !== 0) {
        doc.addPage([width, height], 'landscape');
      }

      // Añadir la imagen al documento PDF
      doc.addImage(
        pageImage.src,
        'JPEG',
        0,
        0,
        pageImage.width / 2,
        pageImage.height / 2,
      );
    } catch (error) {
      console.error(`Error processing slide ${i}:`, error);
    } finally {
      // Limpiar - eliminar el contenedor temporal
      if (document.body.contains(container)) {
        document.body.removeChild(container);
      }
    }
  }

  // Guardar el PDF
  doc.save(`${filename}.pdf`);
};

/*const unirPDFs = async (urls: string[], nombreFinal: string): Promise<void> => {
  const pdfFinal = await PDFLib.PDFDocument.create();

  for (const url of urls) {
    const pdfBytes = await fetch(url).then(res => res.arrayBuffer());
    const pdf = await PDFLib.PDFDocument.load(pdfBytes, {
      ignoreEncryption: true,
    });
    const paginas = await pdfFinal.copyPages(pdf, pdf.getPageIndices());
    paginas.forEach(pagina => pdfFinal.addPage(pagina));
  }

  const pdfBytesFinal = await pdfFinal.save();
  const blob = new Blob([pdfBytesFinal], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = nombreFinal;
  link.click();
  URL.revokeObjectURL(link.href);
};
*/
