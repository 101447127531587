import axios from 'axios';
import { settings } from 'config';
import { default as ApiInstance, default as instance } from 'utils/instances';
import { IReplacement } from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const queryBotResponse = async (param: any) => {
  const headers = param?.headers;
  if (param.headers) {
    delete param.headers;
  }
  const createResponse = await instance.get('/chat', {
    params: param,
    headers,
  });
  return createResponse;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveReplacement = async (payload: IReplacement) => {
  const createResponse = await instance.post(
    '/client/saveReplacements',
    payload,
    {
      headers: {
        'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
  );
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchData = async ({ ticker }: any) => {
  const response = await axios.get(
    `${settings.magnifi.baseUrl}/securities/pdp/search/?Ticker=${ticker}`,
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
    },
  );
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchMarketPriceData = async ({ ticker }: any) => {
  try {
    const response = await axios.get(
      `${settings.magnifi.baseUrl}/securities/real-time/search?Ticker=${ticker}`,
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
        },
      },
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchModelPortfolio = async (selectedModel: string) => {
  if (selectedModel) {
    const response = await ApiInstance.get(
      `client/sponsor-models?id=${selectedModel}`,
      {
        headers: {
          'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        },
      },
    );
    return response.data.data;
  }
};

export const generateChatlogId = async (
  query: string,
  advisorId: string,
): Promise<any> => {
  if (query && advisorId) {
    const payload = { query, advisorId };

    const response = await ApiInstance.post(
      `${process.env.REACT_APP_API_BASE_URL}/chat/stream`,
      payload,
      {
        headers: {
          'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        },
      },
    );
    return response;
  }
};
