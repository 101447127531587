// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__4nooY {
  padding: 32px !important;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.styles_paragraph__BOh9p {
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 17px !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/premium-report/components/stategy-allocation/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;AACnC","sourcesContent":[".container {\n  padding: 32px !important;\n  width: 100%;\n  max-width: 100%;\n  overflow: hidden;\n}\n\n.paragraph {\n  font-family: Roboto !important;\n  font-weight: 400 !important;\n  font-size: 11px !important;\n  line-height: 17px !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__4nooY`,
	"paragraph": `styles_paragraph__BOh9p`
};
export default ___CSS_LOADER_EXPORT___;
