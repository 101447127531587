import { AccordionDetails, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EnterpriseCard from './EnterpriseCard';
import { useRESTQuery } from '@hooks/useRESTQuery';
import MemoDeleteIcon from '@icons/DeleteIcon';
import { useMutation, useQueryClient } from 'react-query';
import { deleteViewpoint } from './mutate';
import { actions as globalActions } from 'app/features/Global/slice';
import { useDispatch } from 'react-redux';
import Loader from '@components/Loader';
import DialogModal from '@components/DialogModal';
import ConfirmationModal from './ConfirmationModal';
import LinearDeterminate from './LinearProgress';
import MemoBlackTrashIcon from '@icons/BlackTrashIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccordionSummary from '@mui/material/AccordionSummary';
import {
  CardMainContainer,
  FileWrapper,
  ParentContainer,
  PendingWrapper,
  StyledAccordion,
} from './styles';
import { validArray } from '@common/validArray';

function EnterpriseDashboardV2(): React.JSX.Element {
  const { mutate: deleteMutate, isLoading: deleteLoading } =
    useMutation(deleteViewpoint);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDocumentDetails, setDeletetDocumentDetails] = useState<any>({});
  const [expandedCards, setExpandedCards] = useState<number[]>([]);

  useEffect(() => {
    setExpandedCards([0]);
  }, []);
  const { data: extractData, isLoading } = useRESTQuery(
    ['extract-viewpoint'],
    {
      endpoint: `/viewPoints/extract`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      refetchInterval: res => {
        return Array.isArray(res) && res.some(item => item.status === 'pending')
          ? 10000
          : false; // check this on condition
      }, // check this on condition
      select: res => res.data,
    },
  );

  const handleDeleteFile = () => {
    const payload = {
      key: 'documentId',
      value: deleteDocumentDetails.id,
    };

    deleteMutate(payload, {
      onSuccess: res => {
        handleClose();
        queryClient.invalidateQueries('extract-viewpoint');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
    });
  };

  const handleDeleteClick = file => {
    setShowDeleteModal(true);
    setDeletetDocumentDetails(file);
  };

  const handleClose = () => {
    setDeletetDocumentDetails({});
    setShowDeleteModal(false);
  };
  const toggleCard = (index: number) => {
    if (expandedCards.includes(index)) {
      setExpandedCards(expandedCards.filter(cardIndex => cardIndex !== index));
    } else {
      setExpandedCards([index]);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <ParentContainer container px={isMobile ? 1 : 3}>
      <CardMainContainer
        item
        container
        rowGap={2}
        justifyContent={'flex-start'}
      >
        {Array.isArray(extractData) &&
          extractData?.length > 0 &&
          extractData.map((file, index) => (
            <React.Fragment key={index}>
              {(() => {
                switch (file.status) {
                  case 'pending' /*Case 0 */:
                    return (
                      <PendingWrapper
                        item
                        container
                        xs={12}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        rowGap={2}
                        height={'fit-content'}
                      >
                        <Grid item xs={6}>
                          <p className="pending-title">{file?.fileName}</p>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent={'flex-end'}
                          alignItems={'center'}
                          xs={6}
                          columnGap={2}
                        >
                          <Grid item>
                            <p className="status">Status:</p>
                          </Grid>
                          <Grid item className="pill">
                            <p className="pill-text">Pending</p>
                          </Grid>
                          <Grid
                            item
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteClick(file)}
                          >
                            <MemoDeleteIcon />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <p className="pending-title">
                            {`Thanks for uploading your file! We're now processing
                            it to ensure you get the best results. This may take
                            up to 10 minutes.`}
                          </p>
                          `
                        </Grid>
                        <Grid item xs={12}>
                          <LinearDeterminate />
                        </Grid>
                      </PendingWrapper>
                    );
                  case 'failed':
                    return (
                      <PendingWrapper
                        item
                        container
                        xs={12}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        rowGap={2}
                        height={'fit-content'}
                      >
                        <Grid item xs={6}>
                          <p className="pending-title">{file?.fileName}</p>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent={'flex-end'}
                          alignItems={'center'}
                          xs={6}
                          columnGap={2}
                        >
                          <Grid item>
                            <p className="status">Status:</p>
                          </Grid>
                          <Grid item className="pill">
                            <p className="pill-text">Failed</p>
                          </Grid>
                          <Grid
                            item
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteClick(file)}
                          >
                            <MemoDeleteIcon />
                          </Grid>
                        </Grid>
                      </PendingWrapper>
                    );
                  default:
                    return (
                      <FileWrapper item container rowGap={2}>
                        <StyledAccordion
                          expanded={expandedCards.includes(index)}
                          onChange={() => toggleCard(index)}
                          sx={{
                            width: '100%',
                            boxShadow: 'none',
                            padding: '8px',
                            // padding: 0
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<KeyboardArrowDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                              width: '100%',
                            }}
                          >
                            <Grid
                              item
                              container
                              justifyContent={'space-between'}
                              alignItems={'center'}
                            >
                              <Grid item container xs={6} columnGap={1}>
                                <Grid item>{file?.fileName} </Grid>
                                <Grid item>•</Grid>
                                <Grid item>{file?.createdAt}</Grid>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                onClick={event => {
                                  event.stopPropagation();
                                  handleDeleteClick(file);
                                }}
                                alignItems={'center'}
                              >
                                <Grid item container justifyContent={'center'}>
                                  <MemoBlackTrashIcon />
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid item container rowGap={3}>
                              {validArray(file.viewpoints) &&
                                file.viewpoints.map((viewpoint, index) => (
                                  <EnterpriseCard
                                    key={index}
                                    id={viewpoint?.id}
                                    documentId={viewpoint?.documentId}
                                    title={viewpoint?.focus}
                                    description={viewpoint?.summary}
                                    status={viewpoint?.status}
                                    source={viewpoint?.source}
                                    questions={viewpoint?.advisorQuestions}
                                    fileUrl={file.s3_url}
                                    imgUrl={viewpoint.image_s3_url}
                                  />
                                ))}
                            </Grid>
                          </AccordionDetails>
                        </StyledAccordion>
                      </FileWrapper>
                    );
                }
              })()}
            </React.Fragment>
          ))}
      </CardMainContainer>

      {showDeleteModal && (
        <DialogModal openModal={showDeleteModal} handleClose={handleClose}>
          <ConfirmationModal
            icon={<MemoDeleteIcon />}
            headerText={'Delete Viewpoint'}
            title={`You are about to permanently delete ${deleteDocumentDetails?.fileName} file for all your advisors. Are you sure you want to continue?`}
            subTitle={
              'Note - Once you delete this file it will not visible to any of your advisors.'
            }
            handleClose={handleClose}
            handleSubmit={handleDeleteFile}
            isLoading={deleteLoading}
          />
        </DialogModal>
      )}
    </ParentContainer>
  );
}

export default EnterpriseDashboardV2;
