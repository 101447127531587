import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import InputDataModal from './InputDataModal';
import PrompQuestions from '@components/PrompQuestions';

const PresentationOfOptions = ({
  type,
  case_id,
}: {
  type: 'presentation_of_options' | 'premium_report';
  case_id?: string;
}): React.ReactNode => {
  const navigate = useNavigate();
  const { caseId = case_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPremiumReport, setIsPremiumReport] = useState(false);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {type === 'presentation_of_options' && (
        <PrompQuestions
          showDividers
          itemPy="10px"
          questions={['Create a presentation of options']}
          handleQuestionClick={() => setIsModalOpen(true)}
        />
      )}
      {type === 'premium_report' && (
        <PrompQuestions
          showDividers
          itemPy="10px"
          questions={['Create a premium report']}
          handleQuestionClick={() => {
            setIsModalOpen(true);
            setIsPremiumReport(true);
          }}
        />
      )}
      {isModalOpen && (
        <InputDataModal
          caseId={caseId || ''}
          isPremiumReport={isPremiumReport}
          isModalOpen={isModalOpen}
          handleCreatePooActionId={pooActionId => {
            navigate(
              `/conversations/slides-editor/case/${caseId}/action/${pooActionId}`,
            );
          }}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </Grid>
  );
};

export default PresentationOfOptions;
