import { SlideTheme } from '../../types';

export const assetmarkTheme: SlideTheme = {
  colors: {
    primary: '#004F7E',
    white: '#FFFFFF',
    ghost: '#7D7D7D',
    neutral: '#353536',
  },
  fontFamily: {
    headings: 'Roboto',
    body: 'Roboto',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  fontSize: {
    title: '90px',
    subtitle: '60px',
    sm: '14px',
    md: '16px',
    xs: '12px',
    xl: '28px',
  },
};
