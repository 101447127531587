import React, { useEffect } from 'react';

import { Slides, SlideType } from '../../../slides';
import {
  AMKProfiles,
  Cover,
  CoverState,
  Disclosures,
  ExcessReturnPerformance,
  ExplanationOfModels,
  GuidingPhilosophy,
  PerformanceDisclosures,
  PortfolioCharacteristics,
  PortfolioStory,
  RiskAnalysis,
  StrategyAllocation,
  StrategySelection,
  TableOfContents,
  WhatYouWantToAccomplish,
} from '../../components';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useGetCaseById } from '../../../../pages/Cases/hooks/useGetCaseById';
import {
  ModelSetHolding,
  ModelSetPerformance,
} from 'features/premium-report/api/types';
import { chunkArray } from 'pages/Conversations/helpers/poo';
import { getRowsDisclosures } from '../../components/performance-disclosures/performance-disclosures';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';

export function PremiumReport(): React.ReactElement {
  const { actionId = '', caseId = '' } = useParams();

  const [coverState, setCoverState] = React.useState<CoverState>({
    preparedFor:
      '# Private Label\n# Wealth Management\n## Investment Consulting',
    preparedBy: '### Kezla Samuel, CFA, CAIA\n### Nick Carcione, CFA',
  });

  const { action, isActionLoading } = useGetCaseActionById({
    actionId: actionId as string,
    caseId: caseId as string,
  });

  const { caseData, isCaseLoading } = useGetCaseById({
    id: caseId,
  });
  const getRows = (modelSetHoldings: ModelSetHolding) => {
    let rowId = 0;
    if (
      !modelSetHoldings ||
      !Array.isArray(modelSetHoldings) ||
      modelSetHoldings.length === 0
    ) {
      return [];
    }
    return modelSetHoldings.flatMap(modelSet =>
      modelSet.portfolio_options.flatMap(option => {
        const allHoldings = option.portfolios.flatMap(
          (portfolio: any) => portfolio.holdings,
        );

        const uniqueHoldings = allHoldings.reduce(
          (acc: any[], current: any) => {
            const exists = acc.find(
              newHolding =>
                newHolding.model_id === current.model_id ||
                newHolding.strategy === current.strategy,
            );
            if (!exists) {
              acc.push({ ...current, id: rowId++ });
            }
            return acc;
          },
          [],
        );

        return uniqueHoldings;
      }),
    );
  };

  useEffect(() => {
    setCoverState({
      preparedFor: caseData?.name ?? '',
      preparedBy: Array.isArray(caseData?.owners)
        ? caseData.owners
            .map(owner => {
              return owner.firstName + ' ' + owner.lastName;
            })
            .join('\n')
        : '',
    });
  }, [isCaseLoading, isActionLoading]);

  const haveData = () => {
    return (
      !isActionLoading &&
      action.value.data.generatedCommentaries &&
      action.value.data.generatedCommentaries.model_set_holdings.length > 0 &&
      action.value.data.generatedCommentaries.model_set_performance.length >
        0 &&
      action.value.data.generatedCommentaries.model_set_characteristics.length >
        0
    );
  };
  const cover: SlideType<CoverState> = {
    id: 'cover',
    state: coverState,
    setState: (state: CoverState) => setCoverState(state),
    render: ({ state, setState, editable }) => (
      <Cover state={state} setState={setState} editable={editable} />
    ),
    footer: false,
  };

  const tableOfContents: SlideType = {
    id: 'table-of-contents',
    state: {},
    setState: () => {},
    render: () => <TableOfContents slides={slides} />,
  };

  const whatYouWantToAccomplish: SlideType = {
    id: 'what-you-want-to-accomplish',
    state: {},
    setState: () => {},
    render: () => (
      <WhatYouWantToAccomplish
        modelSetHoldings={
          action.value.data.generatedCommentaries.model_set_holdings ?? []
        }
        specificConsiderations={
          action.value.data.generatedCommentaries.specific_considerations ?? ''
        }
      />
    ),
  };

  const guidingPhilosophy: SlideType = {
    id: 'guiding-philosophy',
    state: {},
    setState: () => {},
    render: () => <GuidingPhilosophy />,
  };

  const modelSetHoldings = () => {
    if (!haveData()) {
      return [];
    }
    const modelSetHoldings =
      action.value.data.generatedCommentaries.model_set_holdings ?? [];

    return modelSetHoldings.flatMap(modelSet => {
      const modelSetPerformance =
        action.value.data.generatedCommentaries.model_set_performance.filter(
          performance => performance.model_set_name === modelSet.model_set_name,
        );
      const modelSetCharacteristics =
        action.value.data.generatedCommentaries.model_set_characteristics.filter(
          characteristic =>
            characteristic.model_set_name === modelSet.model_set_name,
        );
      return [
        createExplanationOfModels(modelSet),
        createStrategyAllocation(modelSet),
        ...multipleStrategySelection(modelSet),
        ...multiplePerformanceDisclosures(modelSet, modelSetPerformance),
        createExcessReturnPerformance(modelSetPerformance),
        createRiskAnalysis(modelSetPerformance),
        ...[1, 2, 3].map(type =>
          createPortfolioCharacteristics(type, modelSetCharacteristics),
        ),
      ];
    });
  };

  const createExplanationOfModels = (
    modelSetHoldings: ModelSetHolding,
  ): SlideType => {
    return {
      id: `explanation-of-model-${modelSetHoldings.model_set_name}`,
      state: {},
      setState: () => {},
      render: () => <ExplanationOfModels modelSetHoldings={modelSetHoldings} />,
    };
  };

  const createStrategyAllocation = (
    modelSetHoldings: ModelSetHolding,
  ): SlideType => {
    return {
      id: `strategy-allocation-${modelSetHoldings.model_set_name}`,
      state: {},
      setState: () => {},
      render: () => <StrategyAllocation modelSetHoldings={modelSetHoldings} />,
    };
  };

  const createPortfolioCharacteristics = (
    type: number,
    modelSetCharacteristics: any[],
  ): SlideType => {
    return {
      id: `portfolio-characteristics-${type}-${modelSetCharacteristics[0].model_set_name}`,
      state: {},
      setState: () => {},
      render: () => (
        <PortfolioCharacteristics
          portfolioCharacteristics={modelSetCharacteristics}
          type={type.toString()}
        />
      ),
    };
  };

  const multipleStrategySelection: (
    modelSetHoldings: ModelSetHolding,
  ) => SlideType[] = modelSetHoldings => {
    if (!haveData) {
      return [];
    }
    const rows = getRows(modelSetHoldings);
    const chunks = chunkArray(rows, 4);
    return chunks.map((chunk, index) => {
      return {
        id: `strategy-selection-${index}-${modelSetHoldings.model_set_name}`,
        state: {},
        setState: () => {},
        render: () => <StrategySelection modelSetHoldings={chunk} />,
      };
    });
  };
  const multiplePerformanceDisclosures = (
    modelSetHoldings: ModelSetHolding,
    modelSetPerformance: ModelSetPerformance[],
  ) => {
    if (!haveData()) {
      return [];
    }
    const rows = getRowsDisclosures(modelSetPerformance, modelSetHoldings);
    const chunks = chunkArray(rows, 7);
    return chunks.map(chunk => {
      return {
        id: `performance-disclosures-${modelSetHoldings.model_set_name}`,
        state: {},
        setState: () => {},
        render: () => <PerformanceDisclosures rows={chunk} />,
      };
    });
  };

  const createExcessReturnPerformance = (
    modelSetPerformance: ModelSetPerformance[],
  ): SlideType => {
    return {
      id: `excess-return-performance-${modelSetPerformance[0].model_set_name}`,
      state: {},
      setState: () => {},
      render: () => (
        <ExcessReturnPerformance modelSetPerformance={modelSetPerformance} />
      ),
    };
  };

  const createRiskAnalysis = (
    modelSetPerformance: ModelSetPerformance[],
  ): SlideType => {
    return {
      id: `risk-analysis-${modelSetPerformance[0].model_set_name}`,
      state: {},
      setState: () => {},
      render: () => <RiskAnalysis modelSetPerformance={modelSetPerformance} />,
    };
  };

  const portfolioStory: SlideType = {
    id: 'portfolio-story',
    state: {},
    setState: () => {},
    render: () => <PortfolioStory />,
  };

  const amkProfiles: SlideType = {
    id: 'amk-profiles',
    state: {},
    setState: () => {},
    render: () => <AMKProfiles />,
  };

  const disclosures: SlideType = {
    id: 'disclosures',
    state: {},
    setState: () => {},
    render: () => <Disclosures />,
  };

  const slides: SlideType[] = haveData()
    ? [
        cover,
        tableOfContents,
        whatYouWantToAccomplish,
        guidingPhilosophy,
        ...modelSetHoldings(),
        portfolioStory,
        amkProfiles,
        disclosures,
      ]
    : [];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isActionLoading || isCaseLoading ? (
        <CircularProgress />
      ) : haveData() ? (
        <Slides slides={slides} title="Premium Report" />
      ) : (
        <div>The Model Set is empty</div>
      )}
    </div>
  );
}
