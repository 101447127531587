import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CardContainer,
  ClientCardContainer,
  ClientsLoaderContainer,
  ClientsWrapper,
  NoSearchResultText,
  useStyles,
} from './styles';
import { Dialog, Drawer, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { actions as profileActions } from 'app/features/Profile/slice';

import ClientCard from './ClientCard';
import { Spinner } from '@pages/IntegrationPage/styles';
import ClientsDrawerData from './ClientDrawerData';
import { validArray } from '@common/validArray';
import { useGetClients } from '@features/apis/client/getClients';
import { useIntersectionObserver } from '@features/hooks/intersectObserver';
import useDebounce from '@features/hooks/useDebounce';
import CreateModelDialog from './CreateModelDialog';
import CustomAddClientBox from './CustomAddClientBox';
import { sortData } from './helpers';
import ClientFilter from './ClientFilter';
import NoClients from './NoClients';

function ClientsV2(): JSX.Element {
  const dispatch = useDispatch();
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedSortOption, setSelectedSortOption] = useState<string>('Name');
  const [selectedTitle, setSelectedTitle] = useState<string>('');
  const [portfolioDiff, setPortfolioDiff] = useState<number>(0);
  const [activeTab, setActiveButton] = useState<string>('All');
  const [selectedNotification, setSelectedNotification] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [clientData, setClientData] = useState<any[]>([]);
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [addClientModal, setAddClientModal] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState(true);

  const searchValue = useDebounce(search, 800);

  const {
    data: clientPaginatedData,
    fetchNextPage: fetchNextClientPage,
    hasNextPage: hasClientNextPage,
    isFetchingNextPage: isFetchingNextClientPage,
    isLoading: isClientLoading,
    isRefetching: isClientRefetching,
    refetch: refetchClients,
  } = useGetClients({
    search: searchValue,
    prospect: activeTab === 'Prospects',
    viewpoint: activeTab === 'Viewpoint Opportunities',
    allClients: activeTab === 'All',
    notifications: selectedNotification,
  });

  useIntersectionObserver(
    loadMoreRef,
    () => {
      if (hasClientNextPage && !isFetchingNextClientPage) {
        fetchNextClientPage();
      }
    },
    [hasClientNextPage, isFetchingNextClientPage],
    0.1,
  );

  useEffect(() => {
    if (!isClientLoading) {
      setInitialRender(false);
    }
    if (clientPaginatedData) {
      setClientData(clientPaginatedData.pages || []);
    }
  }, [
    clientPaginatedData,
    isClientLoading,
    isClientRefetching,
    isFetchingNextClientPage,
  ]);

  useEffect(() => {
    setClientData([]);
    refetchClients();
  }, [activeTab, searchValue, refetchClients]);

  const handleClientsDrawerOpen = useCallback(
    (id = '', title = '', portfolioDiff = 0, clientName = '') => {
      setSelectedClientId(id);
      setSelectedTitle(title);
      setPortfolioDiff(portfolioDiff);
      setOpen(true);
      if (id && clientName) {
        dispatch(profileActions.addToRecentlyViewed({ id, name: clientName }));
      }
    },
    [dispatch],
  );

  const openAddClientModal = () => setAddClientModal(true);

  const handleCloseModel = () => setOpenModelDialog(false);
  const handleClickOpenModel = () => setOpenModelDialog(true);

  const modelDialog = openModelDialog && (
    <Dialog
      open={openModelDialog}
      onClose={handleCloseModel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: {
            xs: '500px',
            md: '800px',
          },
        },
      }}
    >
      <CreateModelDialog handleCloseModel={handleCloseModel} />
    </Dialog>
  );

  if (isClientLoading) {
    return (
      <ClientsLoaderContainer>
        <Spinner />
      </ClientsLoaderContainer>
    );
  }

  if (
    !validArray(clientData) &&
    !search &&
    activeTab === 'All' &&
    !isClientRefetching &&
    !isClientLoading &&
    !initialRender
  ) {
    return (
      <>
        <CustomAddClientBox
          openAddClientModal={openAddClientModal}
          addClientModal={addClientModal}
          setAddClientModal={setAddClientModal}
          handleClickOpenModel={handleClickOpenModel}
        />
        <NoClients openAddClientModal={openAddClientModal} />
        {modelDialog}
      </>
    );
  }

  return (
    <>
      <ClientCardContainer
        sx={{ height: '88vh', overflowY: 'auto', scrollBehavior: 'smooth' }}
      >
        <ClientsWrapper container rowGap={2}>
          <ClientFilter
            selectedTab={activeTab}
            handleTabChange={setActiveButton}
            totalNotifications={clientPaginatedData?.totalNotifications}
            selectedNotification={selectedNotification}
            handleNotificationChange={setSelectedNotification}
            search={search}
            handleSearchChange={setSearch}
            selectedSortType={sortOrder}
            selectedSortOption={selectedSortOption}
            handleSortTypeChange={setSortOrder}
            handleSortOptionChange={setSelectedSortOption}
            openAddClientModal={openAddClientModal}
            addClientModal={addClientModal}
            setAddClientModal={setAddClientModal}
            handleClickOpenModel={handleClickOpenModel}
          />
          <Grid
            item
            container
            padding={{ xs: '16px' }}
            sx={{ background: '#f7f7f7', borderRadius: '8px' }}
          >
            <CardContainer
              item
              container
              columnSpacing={2}
              rowGap={2}
              wrap="wrap"
            >
              {validArray(clientData) ? (
                sortData(clientData, sortOrder, selectedSortOption)?.map(
                  (client: any, index: number) => {
                    const roundedPortfolioDiff = parseFloat(
                      client?.portfolioDiff?.toFixed(2),
                    );
                    return (
                      <Grid
                        key={index}
                        data-pendo={`client-card-${index}`}
                        item
                        xs={12}
                        sm={6}
                        lg={3}
                        ref={
                          index === clientData.length - 1 ? loadMoreRef : null
                        }
                      >
                        <ClientCard
                          clientName={client?.clientName}
                          priceValue={client?.value}
                          handleDrawerOpen={handleClientsDrawerOpen}
                          clientId={client?.id}
                          title={client?.viewpoint}
                          portfolioDiff={roundedPortfolioDiff || 0}
                          isProspect={client?.isProspect}
                          notifications={client.notifications}
                        />
                      </Grid>
                    );
                  },
                )
              ) : search && !isClientRefetching ? (
                <NoSearchResultText item container xs={12}>
                  {` There is no search result for this ${search}, please try with a different name.`}
                </NoSearchResultText>
              ) : (
                isClientLoading &&
                !isClientRefetching && (
                  <NoSearchResultText item container xs={12}>
                    {`No ${activeTab} found.`}
                  </NoSearchResultText>
                )
              )}
            </CardContainer>
            {(isFetchingNextClientPage || isClientRefetching) && (
              <ClientsLoaderContainer>
                <Spinner />
              </ClientsLoaderContainer>
            )}
          </Grid>
        </ClientsWrapper>
      </ClientCardContainer>
      <div className={classes.root}>
        <Drawer
          anchor="right"
          variant="temporary"
          open={open}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={() => setOpen(false)}
        >
          <ClientsDrawerData
            onClose={() => setOpen(false)}
            clientId={selectedClientId}
            viewpoints={selectedTitle}
            portfolioDiff={portfolioDiff}
            clientsData={clientData}
          />
        </Drawer>
      </div>
      {modelDialog}
    </>
  );
}

export default ClientsV2;
