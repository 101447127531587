import axios from 'axios';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { settings } from 'config';
import { actions as globalActions } from 'app/features/Global/slice';

interface useSendRiskAssessmentMutationResult {
  isSendRiskAssessmentLoading: boolean;
  sendRiskAssessmentMutate: (body: any) => void;
}

export const useSendRiskAssessmentMutation =
  (): useSendRiskAssessmentMutationResult => {
    const dispatch = useDispatch();

    const fetchData = async ({
      advisorId,
      clientId,
      emailSubject,
      emailBody,
    }) => {
      const response = await axios.post(
        `${settings.api.baseUrl}/riskAssessment/link`,
        { advisorId, clientId, emailSubject, emailBody },
        {
          headers: {
            Accept: 'application/json, text/plain, */*',
            'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token') || '',
          },
        },
      );
      return response.data;
    };

    const { isLoading, mutate } = useMutation(fetchData, {
      onSuccess: res => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
      },
    });

    return {
      isSendRiskAssessmentLoading: isLoading,
      sendRiskAssessmentMutate: mutate,
    };
  };
