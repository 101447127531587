import React from 'react';
import { EditableField } from '../../components/EditableField';
import { type Slide } from '../../_data';
import { Grid } from '@mui/material';
import AMKImage from './AMKImage.png';
import Footer from '../Footer';
export type Slide4ContentType = [
  {
    field: 'date';
    label: string;
    value: string;
    type: 'text';
  },
  {
    field: 'title';
    label: string;
    value: string;
    type: 'text';
  },
];

export const Slide4Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide4ContentType>;
    onChangeSlide: (
      slide: Slide<Slide4ContentType>,
      content: Slide4ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        sx={{
          padding: '12px 12px 0 12px',
          height: '100%',
          width: '100%',
          fontFamily: 'Roboto',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            height: 'calc(100% - 48px)',
          }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{
              textAlign: 'center',
              backgroundColor: 'var(--blue-light)',
              color: 'var(--white)',
              backgroundImage: `url(${AMKImage})`,
              backgroundSize: 'auto',
              backgroundPosition: 'right bottom',
              backgroundRepeat: 'no-repeat',
              minHeight: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingLeft: '5rem',
              fontFamily: 'Roboto',
              fontSize: '60px',
            }}
          >
            <Grid item xs={8}>
              <EditableField
                field={'title'}
                slide={slide}
                onSlideContentChange={onChangeSlide}
                style={{ textAlign: 'left' }}
              />
              <EditableField
                field={'date'}
                slide={slide}
                onSlideContentChange={onChangeSlide}
                type="simple"
                style={{ textAlign: 'left', fontSize: '38px' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Footer
          numberPage={slide.slideTemplateId}
          text="For Financial Advisor use only"
        />
      </Grid>
    );
  },
);

export default Slide4Content;
