import React from 'react';
import { useSlidesContent } from '../../context';
import SlideRenderer from '../SlideRenderer';
import styles from './styles.module.css';
import { type Slide } from '../../_data';

const NavPanel = React.memo(
  ({ panelOpen }: { panelOpen: boolean }): React.ReactElement => {
    const { slides, currentSlide, setCurrentSlide } = useSlidesContent();

    const handleClick = (slide: Slide<any>) => {
      setCurrentSlide(slide);
    };

    return (
      <div
        className={styles.panel}
        aria-hidden={!panelOpen}
        id="slides-nav-panel"
      >
        {slides.map((slide, index) => (
          <article
            className={styles.item}
            key={`${index + 1}-${slide.slideTemplateId}`}
          >
            <div className={styles.number}>{index + 1}</div>
            <div
              onClick={() => handleClick(slide)}
              className={styles.button}
              aria-current={
                currentSlide.slideTemplateId === slide.slideTemplateId
              }
            >
              <div
                className={styles.viewportMini}
                aria-current={
                  currentSlide.slideTemplateId === slide.slideTemplateId
                }
              >
                <SlideRenderer slide={slide} />
              </div>
            </div>
          </article>
        ))}
      </div>
    );
  },
);

export default NavPanel;
