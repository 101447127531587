import axios from 'axios';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { settings } from 'config';
import {
  getAdvisorSettingsKey,
  GetAdvisorSettingsResponse,
} from './useGetAdvisorSettings';

const saveAdvisorSettingsLogo = async (file: File) => {
  const token = localStorage.getItem('x-tifin-ai-token') || '';
  const url = `${settings.api.baseUrl}/v2/advisors/logo`;

  const formData = new FormData();
  formData.append('file', file);

  const config = {
    headers: {
      'x-tifin-ai-auth': token,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await axios.post(url, formData, config);
  return response.data;
};

type UseSaveAdvisorSettingsLogoResult = {
  saveAdvisorSettingsLogoMutate: UseMutateFunction<
    GetAdvisorSettingsResponse,
    unknown,
    File,
    unknown
  >;
  saveAdvisorSettingsLogoData: GetAdvisorSettingsResponse | undefined;
  saveAdvisorSettingsLogoLoading: boolean;
  saveAdvisorSettingsLogoError: boolean;
  saveAdvisorSettingsLogoSuccess: boolean;
};

export const useSaveAdvisorSettingsLogo =
  (): UseSaveAdvisorSettingsLogoResult => {
    const queryClient = useQueryClient();

    const { mutate, data, isLoading, isError, isSuccess } = useMutation(
      saveAdvisorSettingsLogo,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(getAdvisorSettingsKey);
        },
      },
    );

    return {
      saveAdvisorSettingsLogoMutate: mutate,
      saveAdvisorSettingsLogoData: data,
      saveAdvisorSettingsLogoLoading: isLoading,
      saveAdvisorSettingsLogoError: isError,
      saveAdvisorSettingsLogoSuccess: isSuccess,
    };
  };
