import { Grid, Typography } from '@mui/material';
import React from 'react';

import BgImage from '../../../assets/assetmark/bg-image.png';
import { useSlideContext } from '../../context';
import { EditableField } from '../editable-field';
import styles from './styles.module.css';

export type TitleSlideTemplateState = {
  title: string;
};

export type TitleSlideTemplateProps = {
  readonly state: TitleSlideTemplateState;
  readonly setState: (state: TitleSlideTemplateState) => void;
  readonly editable?: boolean;
};

export function TitleSlideTemplate({
  state,
  setState,
  editable = true,
}: TitleSlideTemplateProps): React.ReactElement {
  const { theme } = useSlideContext();

  return (
    <Grid
      container
      className={styles.slide}
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <Grid
        item
        sx={{
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: theme.fontSize.title,
            color: theme.colors.white,
            fontFamily: theme.fontFamily.headings,
            fontWeight: theme.fontWeight.bold,
          }}
        >
          <EditableField
            field="title"
            state={state}
            setState={setState}
            editable={editable}
          />
        </Typography>
      </Grid>
    </Grid>
  );
}
