import { Box, Typography } from '@mui/material';
import {
  getCellClassName,
  getColumns,
  getInitialModelSet,
  getRows,
  StyledDataGrid,
} from 'pages/Cases/components/ModelSetsTable';
import { getModelSetIndexByName } from 'pages/Cases/helpers/misc';
import { CaseMilestoneAction } from 'pages/Cases/hooks/useGetCases';
import React from 'react';

export type ModelSetsTableProps = {
  readonly action: CaseMilestoneAction;
  readonly modelSetName: string;
  readonly options?: number[];
};

const getOptionKey = (option: number, riskProfile: number) =>
  `option_${option}_riskprofile_${riskProfile}`;

const HeaderGroup = (params: any) => (
  <Box sx={{ textAlign: 'center', width: '100%' }}>
    <Typography
      variant="subtitle2"
      component="div"
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {params.headerName}
    </Typography>
    <Typography variant="caption" component="div">
      {params.description}
    </Typography>
  </Box>
);

const getColumnGroupingModel = (modelSet: any) =>
  modelSet?.portfolio_options.map((portfolioOptions: any) => ({
    groupId: `Option${portfolioOptions.option}`,
    headerName: `Option ${portfolioOptions.option}`,
    description: 'Client Risk Profile',
    headerAlign: 'center',
    renderHeaderGroup: HeaderGroup,
    children: portfolioOptions.portfolios.map((portfolio: any) => ({
      field: getOptionKey(portfolioOptions.option, portfolio.risk_profile),
      headerName: portfolio.risk_profile,
      renderHeader: () => portfolio.risk_profile.toString(),
    })),
  }));

export function ModelSetsTable({
  action,
  modelSetName,
  options,
}: ModelSetsTableProps): React.ReactElement {
  const modelSetIndex = getModelSetIndexByName(modelSetName, action);
  const modelSet = React.useMemo(
    () => getInitialModelSet(action, modelSetIndex),
    [action, modelSetIndex],
  );

  const filteredModelSet = React.useMemo(() => {
    const filteredPortfolioOptions = options?.length
      ? modelSet.portfolio_options.filter(({ option }) =>
          options.includes(option),
        )
      : modelSet.portfolio_options;

    return {
      ...modelSet,
      portfolio_options: filteredPortfolioOptions,
    };
  }, [modelSet, options]);

  const rows = React.useMemo(() => {
    const unfilteredRows = getRows(filteredModelSet) ?? [];

    return unfilteredRows.filter(row => {
      const optionKeys = Object.keys(row).filter(key =>
        key.startsWith('option_'),
      );
      return optionKeys.some(
        key => row[key] !== null && row[key] !== undefined && row[key] !== 0,
      );
    });
  }, [modelSet, options]);

  const rowHeight =
    rows.length < 20
      ? 36
      : rows.length <= 25
        ? 28
        : rows.length <= 30
          ? 26
          : 24;

  return (
    <Box sx={{ width: '100%' }}>
      <StyledDataGrid
        rows={[...rows]}
        columns={getColumns(filteredModelSet, true)}
        columnGroupingModel={getColumnGroupingModel(filteredModelSet)}
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        hideFooter
        rowHeight={rowHeight}
        getCellClassName={params => `${getCellClassName(params)}`}
        sx={{ height: '100%' }}
      />
    </Box>
  );
}
