import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface ProgressInvestedProps {
  value: number;
}

const resultMap = {
  success: 'success',
  error: 'error',
};

const colorMap = {
  success: 'success.main',
  error: 'error.main',
};

/**
 * @param value Float number. 1 = 100%
 * @example <ProgressInvested value={0.65} />
 * @returns
 */
const ProgressInvested: React.FC<ProgressInvestedProps> = ({ value }) => {
  const progress = Math.round(value * 100);
  const result = progress === 100 ? resultMap.success : resultMap.error;

  return (
    <Stack
      gap={'6px'}
      sx={{
        background: 'hsla(0, 0%, 96%, 1)',
        padding: '16px',
        borderRadius: '4px',
      }}
    >
      <Stack
        direction={'row'}
        gap={1}
        sx={{ fontSize: '24px', fontWeight: 600 }}
      >
        <Box sx={{ color: colorMap[result] }}>{progress.toFixed(0)}%</Box>
        <Box color="black">Invested</Box>
      </Stack>
      <LinearProgress
        variant="determinate"
        color={resultMap[result]}
        value={progress > 100 ? 100 : progress}
        sx={{ borderRadius: '50vw' }}
      />
      {result === 'error' && (
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'hsla(0, 0%, 31%, 1)',
          }}
        >
          Allocate 100% to save model
        </Typography>
      )}
    </Stack>
  );
};

export default ProgressInvested;
