import React, { useMemo, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Paper,
  Autocomplete,
  InputAdornment,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledInputLabel } from '@pages/Profile/styles';
import { TabHeader } from '@pages/SuggestedActions/styles';
import { EClientModelUploadTypes } from 'app/enums';
import Input, { DebounceInput } from '@components/UIComponents/Input';
import { useMutation, useQuery } from 'react-query';
import { addNewModel, fetchTickerSearchResults } from './mutation';
import { StyledApplyButton, StyledButton } from '@pages/CIOViewpoints/styles';
import Add from '@mui/icons-material/Add';
import {
  regExAcceptUpto2Decimals,
  regExpNumberWithDecimal,
} from '@common/string';
import { StyledTableHead } from './styles';
import { validArray } from '@common/validArray';
import { handleError } from '@common/error';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import CsvUpload from '@components/CsvUpload';
import clientSearch from '@images/client-search.svg';
import { makeStyles } from '@mui/styles';
import MemoDeleteIcon from '@icons/delete';
import { LoaderContainer } from '@pages/Signup/styles';
import { Spinner } from '@pages/IntegrationPage/styles';
import { Holding, Model } from '@components/CsvUpload/types';
import { convertToNDecimal } from '@common/number';
import { cashSecuritySearchList } from '@common/constant';

const useStyles = makeStyles({
  inputProps: {
    fontSize: '14px !important',
  },
});

const CreateModelDialog: React.FC<any> = ({
  handleCloseModel,
  handleSuccesRefetch,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const initialTickerStates = { ticker: '', weight: '', name: '' };

  const [selectedTab, setSelectedTab] = useState<EClientModelUploadTypes>(
    EClientModelUploadTypes.UploadModelCsv,
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  // modelList is used for bulk upload
  const [modelList, setModelList] = useState<Model[]>([]);

  // rest of the states are used for single upload
  const [modelName, setModelName] = useState<string>('');
  const [holdings, setHoldings] = useState<Holding[]>([]);

  const [inputValue, setInputValue] = React.useState('');
  const [optionList, setOptionList] = React.useState<string[]>([]);
  const [tickerData, setTickerdata] = useState<{
    ticker: string;
    weight: string;
    name: string;
  }>(initialTickerStates);

  const isCsvUpload = selectedTab === EClientModelUploadTypes.UploadModelCsv;

  const resetStates = () => {
    setModelName('');
    setHoldings([]);
    setModelList([]);
  };

  const toggleTabHeader = (tab: EClientModelUploadTypes) => {
    setSelectedTab(tab);
    resetStates();
    setErrorMessage('');
  };

  const tickerWeightsValidation = () =>
    holdings.every(
      tw =>
        tw?.ticker?.trim() !== '' &&
        !isNaN(Number(tw?.weight)) &&
        +tw?.weight > 0,
    );

  const calculation = () =>
    convertToNDecimal(
      holdings.reduce((sum, holding) => sum + Number(holding.weight), 0),
      2,
    );

  const totalWeightage = useMemo(() => calculation(), [holdings]);

  const addNewModelGeneric = (payload: any) => {
    addNewModelMutate(payload, {
      onSuccess: res => {
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: res?.message,
          }),
        );
        handleSuccesRefetch();
        resetStates();
        handleCloseModel();
      },
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    });
  };
  const isValid = isCsvUpload
    ? !errorMessage && validArray(modelList)
    : !errorMessage &&
      modelName.trim() !== '' &&
      holdings.length > 0 &&
      tickerWeightsValidation() &&
      totalWeightage === 100;

  const { mutate: addNewModelMutate, isLoading: isModelLoading } =
    useMutation(addNewModel);

  const handleCreateModel = () => {
    if (
      modelName.trim() !== '' &&
      validArray(holdings) &&
      totalWeightage === 100
    ) {
      const payload = {
        bulkModel: JSON.stringify([
          {
            name: modelName,
            holdings,
          },
        ]),
      };
      addNewModelGeneric(payload);
    }
  };

  // handler for bulk model upload
  const handleCreateModelWithCsv = () => {
    const payload = {
      bulkModel: JSON.stringify(modelList),
    };
    addNewModelGeneric(payload);
  };

  const { isLoading } = useQuery(
    ['tickerSearchResults', inputValue],
    () => fetchTickerSearchResults(inputValue),
    {
      enabled:
        !!inputValue &&
        !cashSecuritySearchList.includes(inputValue.toLowerCase()),
      onSuccess: res => {
        setOptionList(res);
      },
    },
  );

  const handleSearchSecurity = (name: string) => {
    if (
      cashSecuritySearchList.includes(name.toLowerCase()) &&
      optionList?.[0] !== '$CASH ($CASH)'
    ) {
      setOptionList(['$CASH ($CASH)', ...optionList]);
    } else {
      setInputValue(name);
    }
  };

  const handleAddSecutiy = () => {
    if (tickerData.ticker !== '' || tickerData.weight !== '') {
      const existingTicker = holdings.find(
        item => tickerData.ticker === item.ticker,
      );

      let updatedTickerWeights;
      if (existingTicker) {
        // Update the existing ticker's quantity
        updatedTickerWeights = holdings.map(item => {
          if (item.ticker === tickerData.ticker) {
            return {
              ...item,
              weight: Number(item.weight) + Number(tickerData.weight),
            };
          }
          return item;
        });
      } else {
        // Add the new ticker to the array with both ticker and ticker name
        updatedTickerWeights = [
          ...holdings,
          {
            ...tickerData,
          },
        ];
      }

      setHoldings(updatedTickerWeights);
      setTickerdata(initialTickerStates);
    }
  };

  const handleRemoveTicker = index => {
    const newTickerWeights = [...holdings];
    newTickerWeights.splice(index, 1);
    setHoldings(newTickerWeights);
  };

  const handleTickerChange = (field, newValue) => {
    if (field === 'ticker' && newValue) {
      const [symbol, name] = newValue.match(/^(.*?)\s\((.*?)\)$/).slice(1, 3);

      setTickerdata({
        ...tickerData,
        name,
        [field]: symbol,
      });
    } else {
      const availableWeight = convertToNDecimal(100 - totalWeightage, 2);

      setTickerdata({
        ...tickerData,
        [field]: newValue,
      });

      if (newValue <= availableWeight) {
        setErrorMessage('');
      } else {
        setErrorMessage('Weight cannot exceed more than 100');
      }
    }
  };

  if (isModelLoading) {
    return (
      <LoaderContainer
        style={{
          width: '52vw',
        }}
      >
        <Spinner />
      </LoaderContainer>
    );
  }

  return (
    <Grid container>
      <Grid item>
        <DialogTitle id="alert-dialog-title">Add Custom Model</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent
        sx={{
          background: '#eee',
        }}
      >
        <form
          style={{
            width: '100%',
          }}
        >
          <Grid container sx={{ background: '#fff' }} p={2} gap={2}>
            <Grid
              item
              container
              flexWrap="nowrap"
              sx={{ borderBottom: '1px solid #e4e7ec' }}
            >
              <TabHeader
                style={{
                  margin: '0px 20px 0px 0px',
                  paddingBottom: '10px',
                  fontSize: '14px',
                }}
                active={isCsvUpload}
                onClick={() =>
                  toggleTabHeader(EClientModelUploadTypes.UploadModelCsv)
                }
              >
                {EClientModelUploadTypes.UploadModelCsv}
              </TabHeader>
              <TabHeader
                style={{
                  margin: '0px 20px 0px 0px',
                  paddingBottom: '10px',
                  fontSize: '14px',
                }}
                active={!isCsvUpload}
                onClick={() =>
                  toggleTabHeader(EClientModelUploadTypes.InputModelManually)
                }
              >
                {EClientModelUploadTypes.InputModelManually}
              </TabHeader>
            </Grid>

            {isCsvUpload && (
              <CsvUpload
                setModelList={setModelList}
                setErrorMessage={setErrorMessage}
              />
            )}

            {!isCsvUpload && (
              <>
                <Grid item xs={12}>
                  <StyledInputLabel htmlFor="modelName">
                    Model Name
                  </StyledInputLabel>
                  <Input
                    id="modelName"
                    variant="outlined"
                    fullWidth
                    size={'small'}
                    placeholder={'Model Name'}
                    value={modelName}
                    onChange={e => setModelName(e.target.value)}
                    name="modelName"
                    InputProps={{
                      className: classes.inputProps,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={'space-between'}
                  alignItems={'end'}
                  rowGap={1}
                >
                  <Grid item xs={12} md={3.8}>
                    <StyledInputLabel htmlFor="ticker">
                      Security Name/Symbol
                    </StyledInputLabel>
                    <Autocomplete
                      size={'small'}
                      sx={{ pr: '0' }}
                      options={optionList}
                      defaultValue={tickerData?.ticker}
                      value={tickerData?.ticker}
                      onChange={(e: any, newValue: string | null) => {
                        handleTickerChange('ticker', newValue);
                      }}
                      loading={isLoading}
                      loadingText="Please wait..."
                      renderInput={params => (
                        <DebounceInput
                          className="autoCompleteTxt"
                          {...params}
                          variant="outlined"
                          type="text"
                          name="ticker"
                          id="ticker"
                          size="small"
                          debounceTimeout={300}
                          handleDebounce={(val: string) =>
                            handleSearchSecurity(val)
                          }
                          value={inputValue}
                          placeholder="Security Name/Symbol"
                          title={tickerData?.ticker}
                          InputProps={{
                            ...params.InputProps,
                            className: classes.inputProps,
                            startAdornment: (
                              <img
                                alt="Search image"
                                src={clientSearch}
                                style={{
                                  marginLeft: '8px',
                                  width: '16px',
                                  height: '16px',
                                }}
                              />
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          fontSize="14px"
                          {...props}
                          key={option}
                        >
                          {option}
                        </Box>
                      )}
                      isOptionEqualToValue={(options, value) =>
                        options.valueOf === value.valueOf
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3.8}>
                    <StyledInputLabel htmlFor="weight">
                      Weight as Percentage
                    </StyledInputLabel>
                    <Input
                      variant="outlined"
                      fullWidth
                      size={'small'}
                      type="text"
                      name="weight"
                      id="weight"
                      value={tickerData.weight}
                      InputProps={{
                        className: classes.inputProps,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={e =>
                        handleTickerChange(
                          'weight',
                          e.target.value
                            .replace(regExpNumberWithDecimal, '')
                            .replace(regExAcceptUpto2Decimals, '$1'),
                        )
                      }
                      placeholder="Weight as Percentage"
                    />
                  </Grid>
                  <Grid item xs={12} md={3.8}>
                    <StyledButton
                      variant="outlined"
                      onClick={handleAddSecutiy}
                      style={{
                        width: '100%',
                        fontSize: '14px',
                        marginTop: '10px',
                      }}
                      startIcon={<Add />}
                      disabled={
                        tickerData.ticker === '' ||
                        tickerData.weight === '' ||
                        Number(tickerData.weight) === 0 ||
                        Number(tickerData.weight) >
                          convertToNDecimal(100 - totalWeightage, 2)
                      }
                    >
                      Add Security
                    </StyledButton>
                  </Grid>
                </Grid>
                {validArray(holdings) && (
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: 550,
                      overflowY: 'auto',
                      border: '1px solid #E4E7EC',
                      boxShadow: 'none',
                    }}
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ py: '10px' }}>Security</TableCell>
                          <TableCell sx={{ py: '10px' }}>Weight</TableCell>
                          <TableCell sx={{ py: '10px' }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {holdings.map((holding, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell sx={{ py: '6px' }}>
                                <StyledTableHead
                                  item
                                  container
                                  direction={'column'}
                                >
                                  {holding?.ticker}
                                  <StyledTableHead
                                    item
                                    xs={12}
                                    color="#86858B"
                                    fontSize="12px"
                                  >
                                    {holding?.name}
                                  </StyledTableHead>
                                </StyledTableHead>
                              </TableCell>
                              <TableCell sx={{ py: '6px' }}>
                                {convertToNDecimal(holding?.weight, 2)}
                                {'%'}
                              </TableCell>
                              <TableCell sx={{ textAlign: 'end', py: '6px' }}>
                                <IconButton
                                  onClick={() => handleRemoveTicker(index)}
                                  title={'Delete holding'}
                                >
                                  <MemoDeleteIcon
                                    width={'20px'}
                                    height={'20px'}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            )}

            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
              width={'100%'}
            >
              <Typography color={'red'} fontSize={'14px'}>
                {errorMessage}
              </Typography>
              {!isCsvUpload && (
                <Typography textAlign={'right'} fontSize={'14px'} ml="auto">
                  Total: {convertToNDecimal(totalWeightage, 2)}%
                </Typography>
              )}
            </Box>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions
        sx={{
          width: '100%',
        }}
      >
        <Grid container px={2} gap={1}>
          <StyledButton variant="outlined" onClick={handleCloseModel}>
            Cancel
          </StyledButton>
          <StyledApplyButton
            autoFocus
            variant="outlined"
            onClick={isCsvUpload ? handleCreateModelWithCsv : handleCreateModel}
            disabled={!isValid}
          >
            Add Model
          </StyledApplyButton>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default CreateModelDialog;
