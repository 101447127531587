/* eslint-disable react/prop-types */
import * as React from 'react';

function DeleteIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? '24'}
      height={props.height ?? '24'}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.fill || 'currentColor'}
        d="M20.25 4.5H3.75a.75.75 0 000 1.5h.75v13.5A1.5 1.5 0 006 21h12a1.5 1.5 0 001.5-1.5V6h.75a.75.75 0 100-1.5zM18 19.5H6V6h12v13.5zM7.5 2.25a.75.75 0 01.75-.75h7.5a.75.75 0 110 1.5h-7.5a.75.75 0 01-.75-.75z"
      ></path>
    </svg>
  );
}

const MemoDeleteIcon = React.memo(DeleteIcon);
export default MemoDeleteIcon;
