import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ColorMediaIcon from '@icons/ColorMedia';
import { StyledApplyButton, StyledButton } from '@pages/CIOViewpoints/styles';
import {
  AcceptedFileTypeLabelDiv,
  FileUploadContainer,
  ModalContainer,
  StyledAdminText,
  StyledHorizontalRule,
  StyledLabel,
  StyledModalInput,
  UploadFileLabel,
  UploadFileLabelContainer,
} from './styles';
import { useMutation, useQueryClient } from 'react-query';
import { addEnterprise } from './mutation';
import { actions as globalActions } from 'app/features/Global/slice';
import { useDispatch } from 'react-redux';

interface AddEnterpriseModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleUpload: () => void;
  addEnterpriseStepper?: string;
  setEnterpriseStepper: React.Dispatch<'ADD_ENTERPRISE' | 'SUCCESS_SCREEN'>;
  logoFile: File[];
  setLogoFile: Dispatch<SetStateAction<File[]>>;
  handleSuccessAndClose: () => void;
}

function AddEnterpriseModal({
  isOpen,
  onClose,
  setEnterpriseStepper,
  setLogoFile,
  handleSuccessAndClose,
}: AddEnterpriseModalProps): React.JSX.Element {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [apl, setApl] = useState<any>('');
  const [entName, setEntName] = useState<string>('');
  const queryClient = useQueryClient();
  const addEnterpriseMutation = useMutation(addEnterprise);
  const dispatch = useDispatch();

  const handleFileUpload = e => {
    const uploadedFile = e.target.files[0];

    // Check if a file is selected
    if (uploadedFile) {
      // Get the file extension
      const extension = uploadedFile.name.split('.').pop().toLowerCase();

      // Check if the file extension is allowed (.png or .jpg)
      if (['png', 'jpg'].includes(extension)) {
        setLogoFile(uploadedFile);
      } else {
        console.error('Invalid file type. Please select a .png or .jpg file.');
      }
    }
  };

  const handleAddEnterpriseSubmit = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    const payload = {
      enterpriseName: entName,
      firstName,
      lastName,
      apl,
      adminEmail: email,
    };
    addEnterpriseMutation.mutate(payload, {
      onSuccess: (response: any) => {
        if (response && response.data) {
          // not going inside check this
          queryClient.invalidateQueries('get-all-enterprise');
          setEnterpriseStepper('SUCCESS_SCREEN');
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: response?.message,
            }),
          );
          setLogoFile([]);
          handleSuccessAndClose();
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Unexpected response from the server.',
            }),
          );
        }
      },
      onError: (error: any) => {
        console.log({ error });
        if (error?.response?.data && error?.response?.data?.message) {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: error?.response?.data?.message,
            }),
          );
        } else {
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'An error occurred while processing the query.',
            }),
          );
        }
      },
    });
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer container rowGap={2.5}>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h6" component="div">
            Add Enterprise
          </Typography>
          <Button
            onClick={onClose}
            style={{
              minWidth: 'unset',
              padding: '5px',
              marginLeft: 'auto',
            }}
          >
            <CloseIcon />
          </Button>
        </Grid>
        <Grid item container xs={12}>
          <StyledLabel htmlFor="entName">Enterprise Name</StyledLabel>
          <TextField
            id="entName"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Enterprise Name"
            style={{ paddingTop: '5px' }}
            value={entName}
            onChange={e => setEntName(e.target.value)}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={5}>
            <StyledAdminText>Administrator Details</StyledAdminText>
          </Grid>
          <Grid item xs={7} justifyContent={'center'} alignSelf={'center'}>
            <StyledHorizontalRule />
          </Grid>
        </Grid>
        <Grid item container xs={12} columnGap={1}>
          <Grid item container xs={5.8}>
            <StyledLabel htmlFor="firstName">First Name</StyledLabel>
            <TextField
              id="firstName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter First Name"
              style={{ paddingTop: '5px' }}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item container xs={5.8}>
            <StyledLabel htmlFor="lastName">Last Name</StyledLabel>
            <TextField
              id="lastName"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter Last Name"
              style={{ paddingTop: '5px' }}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <StyledLabel htmlFor="email">Email</StyledLabel>
          <TextField
            id="email"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Email"
            style={{ paddingTop: '5px' }}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Divider
          style={{
            border: '0.5px solid #e4e7ec',
            width: '100%',
          }}
        />
        <Grid item container xs={12}>
          <StyledLabel htmlFor="approvedProductList">
            Approved Product List
          </StyledLabel>
          <TextField
            id="approvedProductList"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Enter Product"
            style={{ paddingTop: '5px' }}
            value={apl}
            onChange={e => setApl(e.target.value)}
          />
        </Grid>
        <FileUploadContainer
          item
          container
          xs={12}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <UploadFileLabelContainer htmlFor="uploadLogoInput">
            <StyledModalInput
              type="file"
              id="uploadLogoInput"
              inputProps={{ accept: '.png, .jpg' }}
              onChange={e => handleFileUpload(e)}
            />
            <ColorMediaIcon />
            <UploadFileLabel>Click to upload enterprise logo</UploadFileLabel>
            <AcceptedFileTypeLabelDiv>.jpg or .PNG</AcceptedFileTypeLabelDiv>
          </UploadFileLabelContainer>
        </FileUploadContainer>
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <StyledButton
            variant="outlined"
            onClick={onClose}
            style={{ marginTop: '10px' }}
          >
            Cancel
          </StyledButton>
          <StyledApplyButton
            variant="outlined"
            onClick={handleAddEnterpriseSubmit}
            style={{ marginTop: '10px' }}
            disabled={!entName || !firstName || !lastName || !email}
          >
            Confirm
          </StyledApplyButton>
        </Grid>
      </ModalContainer>
    </Modal>
  );
}

export default AddEnterpriseModal;
