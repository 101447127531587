import React from 'react';
import SlidesEditor from './index';

const SlidesDemoPage: React.FC = () => {
  return (
    <>
      <SlidesEditor />
    </>
  );
};

export default SlidesDemoPage;
