import React from 'react';
import {
  Modal,
  Grid,
  IconButton,
  Box,
  Typography,
  Divider,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ModalButton from 'components/ModalButton';

interface DeleteCaseModalProps {
  open: boolean;
  handleClose: () => void;
  handleDeleteCase: () => void;
  caseName: string;
}

const DeleteCaseModal: React.FC<DeleteCaseModalProps> = ({
  open,
  handleClose,
  handleDeleteCase,
  caseName,
}) => {
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      sx={{
        display: 'flex',
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={theme => ({
          position: 'relative',
          width: 700,
          bgcolor: '#fff',
          boxShadow: theme.shadows[5],
          borderRadius: '4px',
        })}
      >
        <Box sx={{ padding: '16px' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '100%',
                  letterSpacing: '0%',
                }}
              >
                Delete case {caseName}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ backgroundColor: '#F7F7F7', padding: '16px' }}>
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#fff',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '23px',
                letterSpacing: '0%',
                textAlign: 'center',
              }}
            >
              Are you sure you want to delete it?
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '23px',
                letterSpacing: '0%',
                textAlign: 'center',
              }}
            >
              You are going to delete case {caseName}.<br /> This action cannot
              be undone.
            </Typography>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{ padding: '16px' }}
          display="flex"
          justifyContent="space-between"
          gap={1}
        >
          <ModalButton
            variant="secondary"
            label="Cancel"
            handleClick={handleClose}
          />
          <ModalButton
            label="Yes, delete case"
            handleClick={handleDeleteCase}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteCaseModal;
