import * as React from 'react';

function Configuration() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3335 6.25C3.3335 6.08424 3.39934 5.92527 3.51655 5.80806C3.63376 5.69085 3.79274 5.625 3.9585 5.625H6.4585C6.62426 5.625 6.78323 5.69085 6.90044 5.80806C7.01765 5.92527 7.0835 6.08424 7.0835 6.25C7.0835 6.41576 7.01765 6.57473 6.90044 6.69194C6.78323 6.80915 6.62426 6.875 6.4585 6.875H3.9585C3.79274 6.875 3.63376 6.80915 3.51655 6.69194C3.39934 6.57473 3.3335 6.41576 3.3335 6.25ZM17.7085 13.125H14.5835V11.875C14.5835 11.7092 14.5176 11.5503 14.4004 11.4331C14.2832 11.3158 14.1243 11.25 13.9585 11.25C13.7927 11.25 13.6338 11.3158 13.5166 11.4331C13.3993 11.5503 13.3335 11.7092 13.3335 11.875V15.625C13.3335 15.7908 13.3993 15.9497 13.5166 16.0669C13.6338 16.1842 13.7927 16.25 13.9585 16.25C14.1243 16.25 14.2832 16.1842 14.4004 16.0669C14.5176 15.9497 14.5835 15.7908 14.5835 15.625V14.375H17.7085C17.8743 14.375 18.0332 14.3092 18.1504 14.1919C18.2676 14.0747 18.3335 13.9158 18.3335 13.75C18.3335 13.5842 18.2676 13.4253 18.1504 13.3081C18.0332 13.1908 17.8743 13.125 17.7085 13.125ZM11.4585 13.125H3.9585C3.79274 13.125 3.63376 13.1908 3.51655 13.3081C3.39934 13.4253 3.3335 13.5842 3.3335 13.75C3.3335 13.9158 3.39934 14.0747 3.51655 14.1919C3.63376 14.3092 3.79274 14.375 3.9585 14.375H11.4585C11.6243 14.375 11.7832 14.3092 11.9004 14.1919C12.0176 14.0747 12.0835 13.9158 12.0835 13.75C12.0835 13.5842 12.0176 13.4253 11.9004 13.3081C11.7832 13.1908 11.6243 13.125 11.4585 13.125ZM8.9585 8.75C9.12426 8.75 9.28323 8.68415 9.40044 8.56694C9.51765 8.44973 9.5835 8.29076 9.5835 8.125V6.875H17.7085C17.8743 6.875 18.0332 6.80915 18.1504 6.69194C18.2676 6.57473 18.3335 6.41576 18.3335 6.25C18.3335 6.08424 18.2676 5.92527 18.1504 5.80806C18.0332 5.69085 17.8743 5.625 17.7085 5.625H9.5835V4.375C9.5835 4.20924 9.51765 4.05027 9.40044 3.93306C9.28323 3.81585 9.12426 3.75 8.9585 3.75C8.79274 3.75 8.63376 3.81585 8.51655 3.93306C8.39934 4.05027 8.3335 4.20924 8.3335 4.375V8.125C8.3335 8.29076 8.39934 8.44973 8.51655 8.56694C8.63376 8.68415 8.79274 8.75 8.9585 8.75Z"
        fill="white"
      />
    </svg>
  );
}

const MemoConfiguration = React.memo(Configuration);
export default MemoConfiguration;
