import html2pdf from 'html2pdf.js';

export type DownloadParams = {
  filename: string;
  slides: HTMLElement[];
  width?: number;
  height?: number;
  orientation?: 'landscape' | 'portrait';
  onSuccess?: (pdf: any) => void;
  onError?: (error: Error) => void;
};

export function download({
  filename,
  slides,
  width = 1200,
  height = 675,
  onSuccess,
  onError,
}: DownloadParams): void {
  const orientation = width > height ? 'landscape' : 'portrait';

  const options = {
    jsPDF: { unit: 'px', format: [width, height], orientation },
  };

  const html2canvasOptions = {
    scale: 2,
    useCORS: true,
    letterRendering: true,
    allowTaint: false,
    logging: false,
    width: width,
    height: height,
  };

  const container = document.createElement('div');

  slides.forEach(slide => {
    const slideClone = slide.cloneNode(true);
    container.appendChild(slideClone);
  });

  const worker = html2pdf();

  return worker
    .from(container)
    .set(options)
    .set(html2canvasOptions)
    .outputPdf('blob')
    .then(
      (pdfBlob: Blob) => {
        container.remove();
        const file = new File([pdfBlob], `${filename}.pdf`, {
          type: 'application/pdf',
        });
        const downloadUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${filename}.pdf`;
        link.click();
        URL.revokeObjectURL(downloadUrl);

        onSuccess?.(file);
      },
      err => {
        onError?.(err);
      },
    );
}
