import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type UsePostCalculatePerformanceArguments = {
  body: any;
  enabled: boolean;
};

type UsePostCalculatePerformanceResult = {
  performance: any;
  isPerformanceLoading: boolean;
};

export const usePostCalculatePerformance = ({
  body,
  enabled,
}: UsePostCalculatePerformanceArguments): UsePostCalculatePerformanceResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery(
    ['modelSetPerformance', JSON.stringify(body)],
    fetchQuery({
      path: `/v2/proxy/investment-engine/api/enterprise/amk/calculate_performance_data?required_stat=default_asset_mark`,
      body,
      method: 'POST',
    }),
    { enabled },
  );

  return {
    performance: data || [],
    isPerformanceLoading: isLoading,
  };
};
