import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import React, { useMemo } from 'react';
import { Link, matchPath, Outlet, useLocation } from 'react-router-dom';

interface TabItem {
  label: string;
  path: string;
  validPatterns?: string[];
  replaceLocation?: boolean;
}

interface TabLayoutProps {
  tabs: TabItem[];
  removeGreyFrame?: boolean;
  paddingBottom?: string;
  paddingRight?: string;
  marginBottom?: string;
  rightComponent?: React.ReactNode;
}

const TabsLayout: React.FC<TabLayoutProps> = ({
  tabs,
  removeGreyFrame = false,
  paddingBottom = '48px',
  paddingRight = '16px',
  marginBottom = '24px',
  rightComponent,
}) => {
  const location = useLocation();

  const currentTabPath = useMemo(() => {
    return tabs.find(tab => {
      const currentPath = location.pathname.split('/').pop() || '';
      return currentPath === tab.path
        ? true
        : tab.validPatterns?.some(pattern =>
            matchPath(pattern, location.pathname),
          );
    })?.path;
  }, [location.pathname, tabs]);

  const tabsItems = useMemo(() => {
    return tabs.map(tab => ({ ...tab, value: tab.path }));
  }, [tabs]);

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        paddingRight: paddingRight || '16px',
        paddingBottom: paddingBottom || '48px',
      }}
      direction="column"
    >
      <Box sx={{ borderBottom: 1, borderColor: '#ECECEC' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Tabs value={currentTabPath}>
            {tabsItems.map(tab => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                component={Link}
                to={tab.path}
                sx={{ textTransform: 'none' }}
                replace={!!tab.replaceLocation}
              />
            ))}
          </Tabs>
          <Grid item>{rightComponent}</Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          marginTop: '24px',
          marginBottom: marginBottom || '24px',
          padding: removeGreyFrame ? '0' : '16px',
          background: '#f5f5f5',
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            padding: removeGreyFrame ? '0' : '16px',
            width: '100%',
            background: '#fff',
            borderRadius: '4px',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Stack>
  );
};

export default TabsLayout;
