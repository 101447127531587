import axios from 'axios';
import { useQuery } from 'react-query';
import { settings } from 'config';
import { MarketplaceModel, SponsorsCount } from '../../types';

interface UseGetMarketplaceModelsQueryResult {
  pagination: {
    totalCount: number;
    totalPages: number;
    currentPage: number;
  };
  marketplaceModels: MarketplaceModel[] | [];
  sponsorsCount: SponsorsCount[] | [];
  isMarketplaceModelsLoading: boolean;
  refetch: () => void;
}

export interface GetMarketplaceModelsParams {
  limit?: number;
  page?: number;
  order?: 'asc' | 'desc';
  orderBy?: 'name' | 'updatedAt' | 'createdAt';
  search?: string;
  sponsorIds?: string;
  favorites?: boolean;
}

export type GetMarketplaceModelsResponse = {
  currentPage: number;
  data: MarketplaceModel[];
  sponsorsCount: SponsorsCount[];
  message: string;
  totalCount: number;
  totalPages: number;
};

export const getMarketplaceModelsQuery = async (
  params: GetMarketplaceModelsParams,
): Promise<GetMarketplaceModelsResponse> => {
  const limit = params?.limit || 10;
  const page = params?.page || 1;
  const token = localStorage.getItem('x-tifin-ai-token') || '';
  const queryParams = new URLSearchParams();
  queryParams.set('limit', limit.toString());
  queryParams.set('page', page.toString());
  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.set(key, value);
  });

  const response = await axios.get(
    `${settings.api.baseUrl}/v2/models/sponsors?${queryParams.toString()}`,
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'x-tifin-ai-auth': token,
      },
    },
  );
  return response.data;
};

export const useGetMarketplaceModelsQuery = (
  params?: GetMarketplaceModelsParams,
): UseGetMarketplaceModelsQueryResult => {
  const { data, isLoading, refetch } = useQuery(
    [
      'marketplaceModels',
      params?.search,
      params?.orderBy,
      params?.order,
      params?.page,
      params?.limit,
      params?.sponsorIds,
      params?.favorites,
    ],
    () => getMarketplaceModelsQuery(params || {}),
    {
      select: data => {
        const models = data.data.map(model => ({
          id: model.id,
          name: model.name,
          createdAt: new Date(model.createdAt),
          updatedAt: new Date(model.updatedAt),
          modelId: model.id,
          riskScore: model.riskScore,
          sponsorName: model.sponsorName,
          sponsorLogo: model.sponsorLogo,
          favorite: model.favorite,
          seriesName: model.seriesName,
        }));
        const sponsorCount = data.sponsorsCount.map(sponsor => ({
          modelCount: sponsor.modelCount,
          sponsorName: sponsor.sponsorName,
          sponsorId: sponsor.sponsorId,
          checked: false,
        }));
        return {
          models,
          sponsorCount,
          totalCount: data.totalCount,
          totalPages: data.totalPages,
          currentPage: data.currentPage,
        };
      },
    },
  );

  return {
    pagination: {
      totalCount: data?.totalCount || 0,
      totalPages: data?.totalPages || 0,
      currentPage: data?.currentPage || 0,
    },
    marketplaceModels: data?.models || [],
    sponsorsCount: data?.sponsorCount || [],
    isMarketplaceModelsLoading: isLoading,
    refetch,
  };
};
