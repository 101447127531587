import { Grid } from '@mui/material';
import styled from 'styled-components';

import './globals.css';

export const AppContainer = styled(Grid)`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
`;
