import { Grid } from '@mui/material';
import React, { useState } from 'react';
import {
  MemoSpinnerContainer,
  MemoCard,
  MemoCardText,
  MemoCardTextGreen,
} from './style';

import Magic from '@images/Magic.svg';
import MagicGreen from '@images/MagicGreen.svg';
interface Props {
  text: string | React.JSX.Element;
  onClick?: any;
  sx?: any;
  disabled?: boolean;
  green?: boolean;
}

export const MemoSpinner = ({
  hover = false,
  green,
}: {
  hover?: boolean;
  green?: boolean;
}): React.JSX.Element => {
  return (
    <MemoSpinnerContainer>
      {hover ? (
        <div className="memo-icon">
          {green ? (
            <img
              src={MagicGreen}
              alt="Magic image"
              style={{ display: 'flex' }}
            />
          ) : (
            <img src={Magic} alt="Magic image" style={{ display: 'flex' }} />
          )}
        </div>
      ) : (
        <img src={Magic} alt="Magic image" style={{ display: 'flex' }} />
      )}
    </MemoSpinnerContainer>
  );
};

const MemoAnimator: React.FC<Props> = ({
  text,
  onClick,
  sx = () => {},
  disabled = false,
  green,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return disabled ? (
    <MemoCard
      width="fit-content"
      alignItems={'center'}
      justifyContent={'center'}
      columnGap={0.75}
      mb={1.25}
      disabled={disabled}
      sx={sx}
    >
      <Grid item alignItems={'center'} display={'flex'}>
        <MemoSpinner />
      </Grid>
      <Grid item>
        <MemoCardText>{text}</MemoCardText>
      </Grid>
    </MemoCard>
  ) : (
    <MemoCard
      width="fit-content"
      alignItems={'center'}
      justifyContent={'center'}
      columnGap={0.75}
      mb={1.25}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      hover={isHovered}
      sx={sx}
    >
      <Grid item alignItems={'center'} display={'flex'}>
        <MemoSpinner green={green} hover={isHovered} />
      </Grid>
      <Grid item>
        {green ? (
          <MemoCardTextGreen>{text}</MemoCardTextGreen>
        ) : (
          <MemoCardText hover={isHovered}>{text}</MemoCardText>
        )}
      </Grid>
    </MemoCard>
  );
};

export default MemoAnimator;
