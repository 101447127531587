import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastAction } from 'app/components/ToastAlert/types';
import { GlobalState, MenuNavProps } from './types';
import { Navigation, NavigationProfile } from '@common/constant';

export const initialState: GlobalState = {
  /* Common State */
  chatWindowCollapse: false,
  headerMenuList: Navigation,
  profileMenuList: NavigationProfile,
  toastInfo: {
    duration: 6000,
    showToast: false,
    toastMessage: '',
    toastType: undefined,
  },
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    chatWindowState(state, action: PayloadAction<boolean>) {
      state.chatWindowCollapse = action.payload;
    },
    updateHeaderListState(state, action: PayloadAction<MenuNavProps>) {
      if (action.payload) {
        // state.headerMenuList = {
        //   ...state.headerMenuList,
        // };
      }
    },
    /* --- Toast Actions Start --- */
    displayToast(state, action: PayloadAction<ToastAction>) {
      state.toastInfo.showToast = true;
      state.toastInfo.toastMessage = action.payload.toastMessage;
      state.toastInfo.toastType = action.payload.toastType;
      state.toastInfo.duration = action.payload.duration;
    },
    closeToast(state) {
      state.toastInfo.showToast = false;
      state.toastInfo.toastMessage = '';
    },

    /* --- Toast Actions End --- */

    fetchUserDetails(state) {
      state.toastInfo = { ...state.toastInfo, showToast: true };
    },

    updateClientViewEnhancerRecommendations(state, action: PayloadAction<any>) {
      if (action.payload && action.payload.summaryReplacementData) {
        state.toastInfo = {
          ...state.toastInfo,
          showToast: false,
        };
      }
    },
  },
});

export const { actions, reducer, name: sliceKey } = globalSlice;
