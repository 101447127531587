import React from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { actions } from 'app/features/Global/slice';
import { selectToastState } from 'app/features/Global/selector';
import { ToastTypes } from './types';

import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

const StyledAlert = styled(Alert)`
  background-color: ${props => getBackgroundColor(props.severity)};
  color: ${props => getColor(props.severity)};
  border: 1px solid ${props => getBorder(props.severity)};
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.1);
  max-width: 28em;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;
`;

const getBackgroundColor = (type: ToastTypes) => {
  switch (type) {
    case 'success':
      return 'rgb(3 177 134 / 85%)';
    case 'info':
      return '#5d97f4';
    case 'warning':
      return '#FFF2E7';
    case 'error':
      return 'rgb(224 3 1 / 80%)';
    default:
      return 'rgb(255 191 155 / 50%)';
  }
};

const getColor = (type: ToastTypes) => {
  switch (type) {
    case 'success':
      return 'rgba(97, 255, 207, 1)';
    case 'info':
      return 'rgba(66, 207, 250, 1)';
    case 'warning':
      return 'rgba(255, 191, 155, 1)';
    case 'error':
      return '#ff707f';
    default:
      return 'rgba(97, 255, 207, 1)';
  }
};

const getIcon = (type: ToastTypes) => {
  switch (type) {
    case 'success':
      return <DoneIcon />;
    case 'info':
      return <InfoOutlinedIcon />;
    case 'warning':
      return <CancelOutlinedIcon />;
    case 'error':
      return <CancelOutlinedIcon />;
    default:
      return <DoneIcon />;
  }
};

const getBorder = (type: ToastTypes) => {
  switch (type) {
    case 'success':
      return '#B3DAC7';
    case 'info':
      return '#B6CFF3';
    case 'warning':
      return '#FFCA99';
    case 'error':
      return '#ff707f';
    default:
      return '#B3DAC7';
  }
};

function ToastAlert(): React.JSX.Element {
  const dispatch = useDispatch();
  const toastInfo = useSelector(selectToastState);
  const handleClose = (
    event?: Event | React.SyntheticEvent<any, Event> | undefined,
    reason?: SnackbarCloseReason | undefined,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(actions.closeToast());
  };

  return (
    <Snackbar
      open={toastInfo.showToast}
      autoHideDuration={toastInfo?.duration ?? 6000}
      TransitionComponent={Fade}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <StyledAlert
        onClose={handleClose}
        severity={toastInfo.toastType}
        icon={false}
      >
        <Grid container columnGap={2} wrap="nowrap">
          <Grid item>{getIcon(toastInfo.toastType)}</Grid>
          <Grid
            item
            color={toastInfo.toastType === 'warning' ? '#f49d6b' : '#fff'}
            lineHeight="23px"
          >
            {toastInfo.toastMessage}
          </Grid>
        </Grid>
      </StyledAlert>
    </Snackbar>
  );
}

export default ToastAlert;
