import React from 'react';
import { Typography } from '@mui/material';
import { styled } from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

interface HeaderProps {
  active?: boolean;
}

interface SwitchComponentProps {
  switchItem: {
    id: number;
    name: string;
    active: boolean;
  };
  setSwitches: React.Dispatch<
    React.SetStateAction<{ id: number; name: string; active: boolean }[]>
  >;
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({
  switchItem,
  setSwitches,
}) => {
  const toggleSwitches = (divNumber: number) =>
    setSwitches(prevState =>
      prevState.map(switchItem => ({
        ...switchItem,
        active: switchItem.id === divNumber,
      })),
    );
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    divNumber: number,
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleSwitches(divNumber);
    }
  };
  return (
    <Switch
      tabIndex={0}
      key={switchItem.id}
      active={switchItem.active}
      onClick={() => toggleSwitches(switchItem.id)}
      onKeyDown={event => handleKeyDown(event, switchItem.id)}
    >
      {switchItem.name}
    </Switch>
  );
};

export default SwitchComponent;

const Switch = styled(Typography).withConfig({
  shouldForwardProp,
})<HeaderProps>`
  cursor: pointer;
  color: ${({ active }) => (active ? '#fff' : '#475467')};
  background-color: ${({ active }) => (active ? '#000' : 'transparent')};
  border-radius: 50vw;
  padding: 4px 12px;
  font-size: 12px !important;
  line-height: 16px !important;
  width: 100%;
`;
