import { useQuery } from 'react-query';
import { SponsorModel } from 'pages/Models/types';
import { useFetchQuery } from '../../../../helpers/api';

type UseGetSponsorModelByIdArguments = {
  sponsorId: string;
};

type UseGetSponsorModelByIdResult = {
  getSponsorModelByIdData: SponsorModel;
};

export const useGetSponsorModel = ({
  sponsorId,
}: UseGetSponsorModelByIdArguments): UseGetSponsorModelByIdResult => {
  const { fetchQuery } = useFetchQuery();

  const { data } = useQuery(
    ['sponsorFavoriteModel', sponsorId],
    fetchQuery({ path: `/v2/models/sponsors/${sponsorId}` }),
    {
      select: (data: any) => ({
        ...data.data,
        holdings: parseHoldings(data.data.holdings),
      }),
    },
  );

  return { getSponsorModelByIdData: data || ({} as SponsorModel) };
};

const parseHoldings = (holdings: any[]) =>
  holdings?.map(holding => ({
    ...holding,
    assetClass: holding.asset_class,
  }));
