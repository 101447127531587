// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_cover__tX9fr {
  background-color: #004f7e;
  color: #fff;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: 90%;
  background-position: right bottom;
  background-repeat: no-repeat;
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
}

.styles_logo__jELt8 {
  width: 200px !important;
  margin-bottom: 18px !important;
}

.styles_title__l\\+L7e {
  margin-bottom: 48px !important;
  max-width: 520px !important;
}

.styles_titleText__hJAOi {
  font-size: 44px !important;
  font-weight: 600 !important;
}

.styles_preparedFor__MyY-J {
  margin-bottom: 48px !important;
}
.styles_preparedForTitle__F3FQw {
  font-family: Roboto !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 100% !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
}
.styles_preparedForText__wZRTM {
  font-family: Roboto !important;
  font-weight: 500 !important;
  font-size: 34px !important;
  line-height: 100% !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
}

.styles_preparedBy__yrWGF {
  margin-bottom: 48px !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/premium-report/components/cover/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,oBAAoB;EACpB,iCAAiC;EACjC,4BAA4B;EAC5B,4CAA4C;EAC5C,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,2BAA2B;AAC7B;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;EACjC,oCAAoC;AACtC;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;AACnC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".cover {\n  background-color: #004f7e;\n  color: #fff;\n  padding: 0 32px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  background-size: 90%;\n  background-position: right bottom;\n  background-repeat: no-repeat;\n  font-family: 'Roboto', sans-serif !important;\n  height: 100%;\n}\n\n.logo {\n  width: 200px !important;\n  margin-bottom: 18px !important;\n}\n\n.title {\n  margin-bottom: 48px !important;\n  max-width: 520px !important;\n}\n\n.titleText {\n  font-size: 44px !important;\n  font-weight: 600 !important;\n}\n\n.preparedFor {\n  margin-bottom: 48px !important;\n}\n.preparedForTitle {\n  font-family: Roboto !important;\n  font-weight: 500 !important;\n  font-size: 15px !important;\n  line-height: 100% !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n  text-transform: uppercase !important;\n}\n.preparedForText {\n  font-family: Roboto !important;\n  font-weight: 500 !important;\n  font-size: 34px !important;\n  line-height: 100% !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n}\n\n.preparedBy {\n  margin-bottom: 48px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cover": `styles_cover__tX9fr`,
	"logo": `styles_logo__jELt8`,
	"title": `styles_title__l+L7e`,
	"titleText": `styles_titleText__hJAOi`,
	"preparedFor": `styles_preparedFor__MyY-J`,
	"preparedForTitle": `styles_preparedForTitle__F3FQw`,
	"preparedForText": `styles_preparedForText__wZRTM`,
	"preparedBy": `styles_preparedBy__yrWGF`
};
export default ___CSS_LOADER_EXPORT___;
