/*
 * This is a component for uploading images.
 * Right now only the CSS is done because the component is read-only.
 * When the component is imported all this commented code will be cleaned up.
 */
import React, { FC, useState } from 'react';
import { Box, Button, Grid, styled } from '@mui/material';
import { InputLabels } from '../Buttons';
import SageBlackLogo from '../../../../../assets/icons/sage_logo_black';
import { DropzoneArea } from 'react-mui-dropzone';
import { useSaveAdvisorSettingsLogo } from '../../hooks/useSaveAdvisorSettingsLogo';

const Logo: FC<{
  logoSrc: string | undefined;
  onLogoChange: (value: string) => void;
  isLoading: boolean;
}> = ({ logoSrc, onLogoChange, isLoading }) => {
  const { saveAdvisorSettingsLogoMutate } = useSaveAdvisorSettingsLogo();
  const [newLogo, setNewLogo] = useState<File | null>(null);
  const [hasItemToUpload, setHasItemToUpload] = useState(false);

  const handleChange = (files: File[]) => {
    if (files.length > 0) {
      setNewLogo(files[0]);
      setHasItemToUpload(true);
    } else {
      setHasItemToUpload(false);
    }
  };

  const handleSaveLogo = () => {
    if (newLogo) {
      saveAdvisorSettingsLogoMutate(newLogo, {
        onSuccess: data => {
          onLogoChange(data.data.logo);
          setNewLogo(null);
          setHasItemToUpload(false);
        },
      });
    }
  };

  return (
    <Grid container my={'24px'}>
      <Grid item mr={'32px'} sx={{ width: '200px' }}>
        <Box mb={'6px'}>
          <InputLabels sx={{ color: '#000000', fontWeight: '500' }}>
            Firm Logo
          </InputLabels>
        </Box>
        <Box>
          <p style={{ color: '#4F4F4F', fontWeight: '400', fontSize: '14px' }}>
            Customize Sage`s outputs with your own Logo
          </p>
        </Box>
      </Grid>

      <Grid container sx={{ width: 'calc(100% - 232px)' }}>
        <Box
          mb={'6px'}
          sx={{
            p: '10px',
            mr: '24px',
            height: '84px',
            background: '#fff',
            border: '1px solid #ececec',
            borderRadius: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              boxSizing: 'border-box',
              height: '100%',
              padding: '16px',
              background: '#f5f5f5',
              borderRadius: '2px',
            }}
          >
            {!logoSrc && !isLoading ? (
              <PlaceholderLogo>
                <SageBlackLogo />
              </PlaceholderLogo>
            ) : (
              <img
                src={logoSrc}
                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
              />
            )}
          </Box>
        </Box>

        <Box
          mb={'6px'}
          sx={{
            p: '10px',
            mr: '24px',
            background: '#fff',
            border: '1px solid #ececec',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <form>
            <CustomDropzone>
              <DropzoneArea
                acceptedFiles={['image/jpeg', 'image/png']}
                classes={{ root: 'custom-dropzone' }}
                useChipsForPreview
                showPreviewsInDropzone={true}
                showFileNames={true}
                filesLimit={1}
                maxFileSize={300 * 1024}
                dropzoneText="Click to upload or drag and drop"
                showAlerts={false}
                onChange={handleChange}
              />
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Box component={'span'} color={'#535256'} fontSize={'10px'}>
                  PNG or JPG (300kb max)
                </Box>
              </Box>
            </CustomDropzone>
          </form>
          {hasItemToUpload && (
            <Button variant="contained" onClick={handleSaveLogo}>
              Upload logo
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Logo;

const PlaceholderLogo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& > svg': {
    maxHeight: '100%',
    width: 'auto',
  },
});

const CustomDropzone = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',

  '& > .custom-dropzone': {
    minHeight: '84px',
    padding: '1rem',
    backgroundColor: '#f5f5f5',
    border: 'none',
  },

  '& > .custom-dropzone p': {
    fontSize: '16px',
  },
});
