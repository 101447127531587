// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_viewport__pRpoK {
  box-sizing: border-box;
  display: block;
  background-color: #e0e0e0;
  border: 2px solid #ededee;
  overflow: auto;
}

.styles_viewportInner__nrvR8 {
  position: relative;
  transform-origin: top left;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Conversations/components/SlidesEditor/components/Viewport/styles.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,0BAA0B;AAC5B","sourcesContent":[".viewport {\n  box-sizing: border-box;\n  display: block;\n  background-color: #e0e0e0;\n  border: 2px solid #ededee;\n  overflow: auto;\n}\n\n.viewportInner {\n  position: relative;\n  transform-origin: top left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewport": `styles_viewport__pRpoK`,
	"viewportInner": `styles_viewportInner__nrvR8`
};
export default ___CSS_LOADER_EXPORT___;
