import { useSlideContext } from 'features/slides/context';
import { SlideType } from 'features/slides/types';
import React from 'react';
import { SlidesFooter } from '../slides-footer';
import { SlidesNavPanel } from '../slides-nav-panel';
import { SlidesViewport } from '../slides-viewport';
import styles from './styles.module.css';

export type SlidesProps = {
  readonly slides: SlideType[];
  readonly title: string;
};

export function Slides({ slides, title }: SlidesProps): React.ReactNode {
  const { index, isPanelOpen, setIsPanelOpen } = useSlideContext();

  return (
    <div className={styles.slides}>
      <SlidesViewport index={index} slide={slides[index]} editable={true} />
      <SlidesNavPanel slides={slides} isOpen={isPanelOpen} />
      <SlidesFooter
        title={title}
        slides={slides}
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
      />
    </div>
  );
}
