import {
  Accordion,
  Box,
  Button,
  Grid,
  Select,
  Typography,
} from '@mui/material';
import styled, { css } from 'styled-components';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';

interface DataDivWrapperProps {
  width?: string;
  textAlign?: string;
}

interface AssetAllocationProps {
  width?: number | string;
}

interface IndividualStackProps {
  width?: number | string;
  color?: string;
}

interface StyledRiskBoxProps {
  background?: string;
  color?: string;
  border?: string;
}

interface TableHeaderProps {
  width?: string;
  color?: string;
  fontSize?: string;
}

interface ComparisonContainerProps {
  padding?: string;
  width?: string;
  showStepper?: boolean;
}

interface GraphContainerProps {
  height?: string;
  maxHeight?: string;
}

export const ClientCardContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  background: #fff;
  padding-right: 0.5rem;
  margin-top: -25px;

  @media (min-width: 600px) {
    margin-top: 0;
  }

  @media (min-width: 900px) {
    padding-right: 2rem;
  }
`;

export const ContainerBox = styled(Box)`
  background-color: #ffffff;
  border: 1px solid #e4e7ec;
  height: auto;
`;

export const FooterButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: transparent;
  height: 10vh;
  border: 1px solid #e4e7ec;
  display: flex;
  align-items: center;
  backdrop-fliter: blur(8px);
`;

export const FooterButtonWrapper = styled(Grid)`
  border: 1px solid var(--Text-Dark-Secondary, #86858b);
  border-radius: 18px;
  padding: 10px 18px 10px 16px;
  background: #fff;
  height: 36px;
`;

export const PortfolioWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 10px 0px;
  align-items: center;
`;

export const StyledColumn1 = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
`;

export const StyledColumn2 = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 12px;
`;

export const PortfolioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  // max-height: 180px;
  height: 34%;
  // bottom: 5%;
`;

export const ReturnsHeader = styled.div`
  height: 250px;
`;

export const AssetAllocationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  // margin-bottom: 20px;
  background-color: #fff;
  width: 100%;
  padding: 1.5rem 1rem 1rem;
  border-radius: 8px;
`;

export const AssetAllocationHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  margin-top: 5px;
  overflow: hidden;
`;

export const IndividualStack = styled.div<IndividualStackProps>`
  width: ${props =>
    typeof props.width === 'string' ? props.width : `${props.width}%`};
  background: ${props => props.color};
  margin-right: 2px;
  &:last-child {
    margin-right: 0px;
  }
`;

export const CashFlowWrapper = styled.div<AssetAllocationProps>`
  width: ${props =>
    typeof props.width === 'string' ? props.width : `${props.width}%`};
  background: #fd9f74;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-right: 2px;
`;

export const EquityFlowWrapper = styled.div<AssetAllocationProps>`
  width: ${props =>
    typeof props.width === 'string' ? props.width : `${props.width}%`};
  background: #fdc174;
  margin-right: 2px;
`;

export const DebtWrapper = styled.div<AssetAllocationProps>`
  width: ${props =>
    typeof props.width === 'string' ? props.width : `${props.width}%`};
  background: #a0ccd9;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ProgressBarLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;

export const LegendColorDiv = styled.div`
  height: 12px;
  width: 12px;
  background: #fdc174;
  border-radius: 2px;
`;

export const LegendTextDiv = styled.div`
  padding: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DrawerContainer = styled.div`
  width: 95%;
  height: 96%;
  padding: 25px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  overflow-x: hidden;
  // overflow-y: auto;
  background: #fff;
  padding-right: 0px;
`;

export const DrawerHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  // padding: 0px 0px 15px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--neutral-900, #101828);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ExpectedFundDataContainer = styled(Grid)`
  row-gap: 16px;
  column-gap: 16px;
  padding-top: 16px;
  justify-content: space-between;
  width: 100%;
  // margin-bottom: 20px;
`;

export const ExpectedFundDataWrapper = styled(Grid)`
  // display: flex;
  // flex-direction: column;
  // margin-right: 15px;
`;

export const FundDataText = styled(Grid)`
  padding: 5px;
  color: var(--Gray-1, #818181);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const FundDataNumber = styled('p')`
  // padding: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--neutral-900, #101828);
`;

export const HoldingWrapper = styled.div`
  width: 94%;
  max-height: 150px;
  padding: 0px 20px 0px 10px;
  overflow-y: scroll;
  position: relative;
  top: 12%;
`;

export const ViewpointBox = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  // border: 1px solid ${props =>
    props.title === 'Rely on your core'
      ? 'orange'
      : 'var(--Info-Border, #b6cff3)'};
  background: ${props =>
    props.title === 'Rely on your core'
      ? '#EBDCF9'
      : props.title === 'Step out of cash'
        ? 'var(--Info-Surface, #ECF2FC)'
        : props.title === 'Rebuild Equity'
          ? 'var(--Success-Surface, #EBF5F0)'
          : 'var(--Info-Surface, #ecf2fc)'};
  font-size: 12px;
  color: ${props =>
    props.title === 'Rely on your core'
      ? '#9B51E0'
      : props.title === 'Step out of cash'
        ? '#0B5CD7'
        : props.title === 'Rebuild Equity'
          ? '#008243'
          : null};
  height: 15px;
  width: fit-content;
`;

export const FourthBox = styled.div`
  display: flex;
  padding: 12px 12px 12px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.title === 'Rely on your core'
        ? '#FFCA99'
        : props.title === 'Step out of cash'
          ? '#B6CFF3'
          : props.title === 'Rebuild Equity'
            ? '#B3DAC7'
            : null};
  background: ${props =>
    props.title === 'Rely on your core'
      ? '#FFF2E7'
      : props.title === 'Step out of cash'
        ? 'var(--Info-Surface, #ECF2FC)'
        : props.title === 'Rebuild Equity'
          ? 'var(--Success-Surface, #EBF5F0)'
          : null};
  font-size: 12px;
  color: #101828;
  height: 30px;
  width: 95%;
`;

export const ClientsWrapper = styled(Grid)`
  // border-radius: 8px;
  // background: #f7f7f7;
  // padding: 16px 16px 48px;
`;

export const OutlineBox = styled(Grid)`
  border-radius: 8px;
  border: 1px solid var(--Neutral-200, #e4e7ec);
  background: var(--Neutral-0, #fff);
  color: #353536;
  // padding: 8px;
  padding: 8px var(--Spacing-Small, 8px);
  /* Action Components */
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  // &:hover {
  //   color: white;
  //   background: black;
  //   border-radius: 5px;
  // }
`;

export const CardWrapper = styled(Grid)`
  padding: 16px 10px 10px 10px;
  background: #fff;
  cursor: pointer;
  border: 1px solid var(--Surface-Light-Border, #ededee);
  border-radius: 8px;

  .text {
    font-size: 16px;
    color: var(--Text-Dark-Strong, #282829);
    font-weight: 400;
    line-height: 20px;
  }

  .arrow-container {
    display: flex;
  }

  .profit_loss_info_text {
    // color: var(--Surface-Danger-Border, #e11d48);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .profile_value_info {
    background: var(--Neutral-50, #f7f7f7);
    border-radius: 4px;
    width: fit-content;
    padding: 5px 10px;
  }

  .viewpoint_info {
    border-radius: var(--Radius-Regular, 8px);
    background: #f9f9f9;
  }
  .viewpoint_proposal {
    background: #f9f9f9;
    color: #535256;
    height: 40px;
    font-weight: 500;
    font-size: 12px;
  }

  .viewpoint_wrapper {
    padding: 4px 8px;
    width: fit-content;
    border-radius: var(--Radius-Small, 4px);
    border: 0px solid var(--Surface-Light-Border, #ededee);
    background: var(--Surface-Light-Fill, #fff);
  }

  .viewpoint_text {
    color: var(--Text-Dark-Primary, #535256);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .additional_info {
    color: var(--Text-Dark-Primary, #535256);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: flex-start;
  }

  .no_additional_info {
    color: var(--Text-Dark-Primary, #535256);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: flex-start;
    margin-top: -37px;
    margin-left: -1px;
  }

  .name {
    color: #282829;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .price {
    color: #282829;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }

  .curve-border {
    border-radius: 18px;
    border: 1px solid var(--Text-Dark-Secondary, #86858b);
    background: #fff;
    padding: 9px 18px 10px 16px;
    height: 36px;
  }

  .circle-border {
    border-radius: 20px;
    border: 0.9px solid #818181;
    background: var(--Neutral-0, #fff);

    /* Action Components */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
    cursor: pointer;
  }

  .risk_text {
    font-size: 12px;
    color: #86868b;
    font-weight: 400;
    line-height: 12px;
  }

  .risk_score {
    color: #9c84fc;
    // font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const CardContainer = styled(Grid)`
  /* padding: 19px 18px; */
`;

export const ClientOverviewContainer = styled(Grid)`
  // border: 1px solid #e4e7ec;
  // display: flex;
  // padding: 16px;
  // border-radius: 3px;
  // align-items: flex-start;
  // width: 97%;
  // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  background: #fff;
  // flex-direction: row;
  // margin-bottom: 20px;
  // height: 75px;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin-top: 20px;

  @media (min-width: 600px) {
    margin-top: 0;
    flex-wrap: nowrap !important;
  }

  @media (min-width: 900px) {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .basic_info {
    border-radius: 8px;
    background: #f7f7f7;
  }
`;

export const ClientsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ClientsHeaderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // margin-right: 25px;
`;

export const FirstBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledIconContainer = styled(Grid)`
  // display: flex;
  padding: 4px;
  // border: 1px solid #7b73e4;
  border-radius: 4px;
  margin-right: 5px;
  // margin-bottom: 5px;
  // background: #7b73e4;
  // justify-content: center;
`;

export const FirstBlockText = styled(Typography)`
  // display: flex;
  // align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #818181;
`;

export const FirstBlockData = styled(Typography)`
  // display: flex;
  color: var(--Gray-2, #333);
  font-size: 28px;
  font-weight: 400;
  // margin-top: 10px;
  // margin-left: 2px;
`;

export const SecondBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

export const SecondBlockIconContainer = styled(Grid)`
  // display: flex;
  padding: 4px;
  // border: 1px solid #2196f3;
  border-radius: 4px;
  margin-right: 5px;
  // margin-bottom: 5px;
  // background: #2196f3;
  // justify-content: center;
`;

export const RecentlyViewedSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RecentlyViewedSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RecentlyViewedIconContainer = styled(Grid)`
  // display: flex;
  padding: 4px;
  // border: 1px solid #2196f3;
  border-radius: 4px;
  margin-right: 5px;
  // margin-bottom: 5px;
  // background: #2196f3;
  // justify-content: center;
`;

export const RecentlyViewedTextContainer = styled(Grid)`
  // display: flex;
  // align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #818181;
`;

export const RecentlyViewedDataWrapper = styled.div`
  display: flex;
  padding-top: 5px;
`;

export const RecentlyViewedUserContainer = styled(Grid)`
  border-radius: 13px;
  padding: 6px 12px;
  margin-right: 10px;
  cursor: pointer;
  background: #fff;
  gap: 10px;
  /* width: fit-content;
  align-items: center;
  justify-content: center; */
`;

export const ClientsInitialStateBodyWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AddClientBtnText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
`;

export const StyledGrid = styled(Grid)`
  font-size: 14px;
  color: #667085;
`;

export const ConnectPlatformButton = styled(Button)`
  && {
    border-radius: 20px;
    background: #000;
    color: #fff;
    font-size: 14px;
    /* text-transform: none; */
    width: 100%;
    /* height: 40px; */
    &:hover {
      opacity: 0.9;
      background: #000;
    }
  }
`;

export const StyledPTag = styled.p`
  font-size: 20px;
  color: #000;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export const StyledText = styled(Grid)`
  color: #667085;
  font-size: 14px;
  font-weight: 400;
`;

export const ClientsLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  margin: 0 auto;
`;
export const ReturnChartContainer = styled.div`
  display: flex;
  padding-left: 5px;
  width: 100%;
  flex-direction: column;
`;

export const ReturnChartText = styled.div`
  color: #101828;
  font-size: 16px;
  padding-bottom: 15px;
`;

export const ContentWrapper = styled(Grid)`
  // display: flex;
  border: 1px solid #e4e7ec;
  // height: 125px;
  // align-items: center;
  margin: 3px 0 1.5rem 10% !important;
  border-radius: 8px;
  padding: 16px;
  // justify-content: flex-start;
  margin-bottom: 10px;
  background: #fff;

  @media (min-width: 900px) {
    margin-left: 60px !important;
  }
`;

export const ContentInnerWrapper = styled(Grid)`
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // align-items: center;
  // justify-content: center;
`;
export const DateText = styled(Grid)`
  color: #86858b;
  font-size: 12px;
  font-weight: 400;
`;
export const DateWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;
export const DescText = styled(Typography)`
  font-size: 14px;
  color: #667085;
  font-weight: 400;
  // display: flex;
  // width: 100%;
  // justify-content: flex-start;
  // align-items: center;
`;
export const IconContainer = styled(Grid)`
  // display: flex;
  // align-items: center;
  // margin-right: 10px;
`;
export const Line = styled.div`
  position: absolute;
  top: 23%; /* Adjust this value to move the starting point */
  left: 4.5%;
  width: 3px;
  height: 83%; /* Adjust this value to control the height of the line */
  background: #e4e7ec;
  content: '';
`;
export const PortfolioHistoryContainer = styled(Grid)`
  height: 96vh;
  // width: 100%;
  // display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  // flex-direction: column;
  padding-top: 10px;
`;
export const PortfolioHistoryWrapper = styled(Grid)`
  position: relative;
  margin-bottom: 16px;
  height: fit-content;
`;
export const SubText = styled(Typography)`
  // padding-top: 6px;
  font-size: 14px;
  color: #86858b;
  font-weight: 500;
`;
export const TextContainer = styled(Grid)`
  // display: flex;
  // flex-direction: column;
  margin-top: 2px;
`;
export const TitleText = styled(Typography)`
  // display: flex;
  // width: 100%;
  // justify-content: flex-start;
  // align-items: center;
  // padding-bottom: 6px;
  font-size: 16px;
  color: #282829;
  font-weight: 500;
`;

export const ClientEmailWrapper = styled.div`
  color: #667085;
  font-size: 12px;
  padding-top: 3px;
`;
export const ClientNameWrapper = styled.div`
  color: #101828;
  font-size: 18px;
`;
export const DrawerHeaderDataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const DrawerDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledInfoBox = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid var(--Info-Border, #b6cff3);
  background: var(--Info-Surface, #ecf2fc);
  font-size: 12px;
  color: #0b5cd7;
  height: 15px;
  width: 45px;
`;
export const CurrentPortfolioWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  // padding-top: 20px;
`;

export const StyledNewSpan = styled.span`
  font-weight: bold;
  color: #000;
  font-size: 14px;
`;

export const InvestmentSpan = styled.span`
  color: #667085;
  font-size: 14px;
`;
export const AddClientsBtnText = styled.span`
  font-size: 14px;
`;

export const NoSearchResultText = styled(Grid)`
  height: 49vh;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  padding-left: 16px;
  font-size: 18px;
`;

export const ClientPieChartDiv = styled.div`
  width: 100%;
  margin-right: -1px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InvestmentHeader = styled.div`
  padding-left: 5px;
`;

export const ConstraintsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  // border: 1px solid var(--Warning-Border, #ffca99);
  background: var(--Neutral-50, #f7f7f7);
`;

export const ConstraintsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const TextDiv = styled.div`
  display: flex;
  align-items: center;
  color: #e11d48;
  font-size: 14px;
  font-weight: 500;
`;

export const DescDiv = styled.div`
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #475467;
`;
export const PortfolioDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;
export const HoldingText = styled.div`
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;

export const HoldingBody = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 95%;
  @media (min-width: 600px) {
    width: 100%;
  }
`;

export const MappedDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #e4e7ec;
  min-width: 360px;
  width: 100%;
  & > div {
    margin-left: 10px;
    &:first-child {
      margin-left: 0px;
    }
  }
  @media (min-width: 600px) {
    min-width: auto;
    margin-left: 0;
  }
`;

export const DataDivWrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['width', 'textAlign'].includes(prop),
})<DataDivWrapperProps>`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || ''};
  text-align: ${props => props.textAlign || ''};
`;

export const SubTextWrapper = styled.div`
  color: #475467;
  font-size: 12px;
`;
export const DataDiv = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const ClientsDrawerFooterButtonContainer = styled.div`
  // position: absolute;
  // left: 0;
  // bottom: 0;
  width: 100%;
  padding-top: 10px;
  border-top: 1px solid #e4e7ec;
  // background: transparent;
  height: 8vh;
  display: flex;
  align-items: center;
  // backdrop-filter: blur(8px);
  // border-top: 1px solid #e4e7ec;
`;
export const StyledRiskBox = styled.div<StyledRiskBoxProps>`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid ${props => props.border || ''};
  background: ${props => props.background || ''};
  font-size: 12px;
  color: ${props => props.color || ''};
  height: 15px;
  width: 45px;
`;
export const StyledTableContainer = styled(Grid)`
  // width: 95%;
  height: 162px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  // display: flex;
  // flex-direction: column;
  // margin-top: 20px;
  // margin-left: 16px;
`;

export const StyledTableHeaderContainer = styled(Grid)`
  // width: 96%;
  // display: flex;
  // flex-direction: row;
  height: 40px;
  background: #f9fafb;
  border-bottom: 1px solid #e4e7ec;
  padding: 10px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const StyledTableHead = styled(Grid)<TableHeaderProps>`
  // width: ${props => props.width || ''};
  font-size: ${props => props.fontSize || '14px'};
  color: ${props => props.color || '#282829'};
`;

export const StyledTableBodyContainer = styled(Grid)`
  // width: 97%;
  // display: flex;
  // flex-direction: column;
  padding: 0px 10px;
  max-height: 120px;
  overflow-y: auto;
`;

export const StyleTableBodyWrapper = styled(Grid)`
  // width: 100%;
  // display: flex;
  // flex-direction: row;
  border-bottom: 1px solid #e4e7ec;
  padding: 10px 0px 10px;
`;

export const HoldingHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const PortfolioValueContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  background: #f2f4f7;
  justify-content: center;
  align-items: center;
`;

export const StyledSpan = styled.span`
  font-size: 12px;
  color: #667085;
  display: flex;
  flex-direction: row;
`;

export const ValueSpan = styled.div`
  font-size: 12px;
  color: #101828;
  margin-left: 5px;
`;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #e4e7ec;
  padding-botton: 10px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const DrawerDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 98%;
`;

export const ClientCardCTABtnText = styled(Typography)`
  padding-left: 5px;
  font-size: 14px;
  color: var(--Text-Dark-Secondary, #86858b);
  font-weight: 500;
  line-height: normal;
`;

export const AccordionWrapper = styled(Grid)`
  border-radius: 8px;
  // background: #f9f9f9;
  box-shadow: none;
  margin-bottom: 10px;
`;

export const StyledAssessmentAccordion = styled(Accordion)`
  width: 100%;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: none;
`;

export const CustomizedSelect = styled(Select)`
  padding: 0;
  width: 100% !important;
  @media (min-width: 600px) {
    width: 200px !important;
  }
`;

export const ComparisonContainerHeader = styled(Typography)<any>`
  color: ${props => props?.color ?? '#282829'} !important;
  font-size: 14px !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  @media (min-width: 600px) {
    font-size: 16px !important;
  }
`;

export const StyledDiffText = styled('p')<any>`
  color: ${props => props?.color ?? '#282829'};
  font-size: ${props => props?.fontSize ?? '12px'};
  font-weight: ${props => props?.fontWeight ?? '400'};
  line-height: ${props => props?.lineHeight ?? '20px'};
`;

export const ComparisonHeaderText = styled(Typography)<any>`
  color: ${props => props?.color ?? '#282829'};
  font-size: ${props => props?.fontSize ?? '16px'};
  font-weight: ${props => props?.fontWeight ?? '500'};
`;

export const ComparisonContainer = styled(Grid).withConfig({
  shouldForwardProp: prop =>
    !['padding', 'width', 'showStepper'].includes(prop),
})<ComparisonContainerProps>`
  width: ${props => props.width || ''};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  ${({ showStepper }: any) =>
    showStepper &&
    css`
      padding: 0 10px !important;
    `}
  @media (min-width: 900px) {
    padding: ${props => props.padding ?? '0px 20px 20px'};
  }
`;
export const GraphContainer = styled(Grid)<GraphContainerProps>`
  width: 97%;
  max-height: ${({ maxHeight }) => maxHeight || ''};
  background: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  flex: 1 0 0;
  overflow-y: auto;
  height: ${({ height }) => height || ''};
`;

export const CurrentPortfolioDataType = styled(Grid)<any>`
  padding: 5px;
  color: var(--Gray-1, #818181);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const CurrentPortfolioDataValue = styled(Grid)<any>`
  padding-left: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 'normal';
  color: var(--neutral-900, #101828);
`;

export const PortfolioDiffTag = styled('p')<any>`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

export const ArrowImg = styled('img')<any>`
  height: 17px;
`;

export const TotalCard = styled(Grid)`
  background: #f7f7f7;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  border-radius: 8px;
`;

export const TotalCardDetails = styled(Box)`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

export const useStyles = makeStyles({
  drawer: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: 720,
    },
  },
  drawerPaper: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: 720,
    },
  },
  root: {
    display: 'flex',
  },
});
