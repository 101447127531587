import styled from 'styled-components';

export const InputFilterContainer = styled.div`
  display: flex;
  align-items: center;

  & .label {
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  & .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;
  }

  & .input {
    box-sizing: border-box;
    width: 100%;
    padding: 7px 8px 7px 32px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
  }
`;
