import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { type Slide } from '../_data';

interface SlidesContentContextType {
  slides: Slide<any>[];
  currentSlide: Slide<any>;
  setCurrentSlide: (slide: Slide<any>) => void;
  updateSlideContent: (
    slide: Slide<any>,
    content: Slide<any>['content'],
  ) => void;
  setCurrentSlideByIndex: (index: number) => void;
  handleSave: () => void;
}

export const SlidesContentContext = createContext<
  SlidesContentContextType | undefined
>(undefined);

export const SlidesContentProvider = ({
  children,
  action,
  handleSave,
  setDirty,
}: {
  children: ReactNode;
  action: any;
  handleSave: (slides: any) => void;
  setDirty: (dirty: boolean) => void;
}): React.ReactElement => {
  const [slides, setSlides] = useState<Slide<any>[]>(action.value.slides);
  const [currentSlide, setCurrentSlide] = useState<Slide<any>>(slides[0]);
  const isUpdatingRef = useRef(false);

  const setCurrentSlideByIndex = useCallback(
    (index: number) => {
      setCurrentSlide(slides[index]);
    },
    [slides],
  );

  const updateSlideContent = useCallback(
    (slide: Slide<any>, content: Slide<any>['content']) => {
      if (isUpdatingRef.current) return;
      isUpdatingRef.current = true;

      setSlides(prevSlides => {
        const slideIndex = prevSlides.findIndex(
          s => s.slideTemplateId === slide.slideTemplateId,
        );
        if (slideIndex === -1) return prevSlides;

        const newSlides = [...prevSlides];
        const updatedSlide = {
          ...prevSlides[slideIndex],
          content,
        };
        newSlides[slideIndex] = updatedSlide;

        if (slide.slideTemplateId === currentSlide.slideTemplateId) {
          setTimeout(() => {
            setCurrentSlide(updatedSlide);
          }, 0);
        }

        return newSlides;
      });

      setDirty(true);

      // Permitir la siguiente actualización después de un breve retraso
      setTimeout(() => {
        isUpdatingRef.current = false;
      }, 0);
    },
    [currentSlide.slideTemplateId, setDirty],
  );

  return (
    <SlidesContentContext.Provider
      value={{
        slides,
        currentSlide,
        setCurrentSlide,
        setCurrentSlideByIndex,
        updateSlideContent,
        handleSave: () => handleSave(slides),
      }}
    >
      {children}
    </SlidesContentContext.Provider>
  );
};

export const useSlidesContent = (): SlidesContentContextType => {
  const context = useContext(SlidesContentContext);
  if (!context) {
    throw new Error(
      'useSlidesContent must be used within a SlidesContentProvider',
    );
  }
  return context;
};
