import { Typography } from '@mui/material';
import { useSlideContext } from '../../context';

import React from 'react';

export interface SlideTitleProps {
  readonly children: React.ReactNode;
}

export function SlideTitle({ children }: SlideTitleProps): React.ReactElement {
  const { theme } = useSlideContext();

  return (
    <Typography
      component="h1"
      sx={{
        color: theme.colors.primary,
        fontFamily: theme.fontFamily.headings,
        fontWeight: theme.fontWeight.bold,
        fontSize: theme.fontSize.xl,
      }}
    >
      {children}
    </Typography>
  );
}
