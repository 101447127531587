import React, { useEffect } from 'react';
import { LoginCallback as OktaLoginCallback } from '@okta/okta-react';
import { usePostRecordLogin } from './hooks/usePostRecordLogin';

function LoginCallback(): React.JSX.Element {
  const { recordLoginMutate } = usePostRecordLogin();

  useEffect(() => recordLoginMutate(), []);

  return <OktaLoginCallback />;
}

export default LoginCallback;
