import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import styled, { css, keyframes } from 'styled-components';
import { shouldForwardProp } from 'utils/forwardProp';

interface FundInfoProps {
  width?: string;
}

interface MainContainerProps {
  padding?: string;
  width?: string;
}

export const ChatTableTitle = styled(Typography)`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const ChatTableSubTitle = styled(Typography)`
  color: #96969b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px !important;
`;

export const ChatContentContainer = styled(Grid)<{
  isminimized: number;
  isLoading?: boolean;
}>`
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 0 !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.grey4} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }

  transition:
    padding cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    display cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    opacity cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.3s;

  ${({ isminimized }: any) =>
    !isminimized &&
    css`
      padding: 0;
    `}

  ${({ isLoading }: any) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`;

export const ChatBoxContainer = styled(Grid).withConfig({
  shouldForwardProp,
})<{ isSender?: boolean }>`
  position: relative;
  width: 100%;
  padding: 1rem 4rem;

  ${({ isSender }: any) =>
    isSender &&
    css`
      background: rgba(213, 213, 213, 0.2);
    `}
`;

export const ChatBoxContent = styled(Grid).withConfig({
  shouldForwardProp,
})<{ isSender?: boolean }>`
  flex-direction: ${props =>
    props.isSender ? 'column !important' : 'column !important'};
  justify-content: ${props => (props.isSender ? 'flex-end' : 'flex-start')};
  align-items: ${props => (props.isSender ? 'flex-end' : 'flex-start')};
  max-width: ${props =>
    props.isSender ? '100%' : 'calc(100% - 50px) !important'};
  padding-left: 1rem;
`;

export const SliderChatWrapper = styled(Grid)`
  width: 100%;
  padding: 10px;
  color: ${props => props.theme.textBlack};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px 10px 10px 10px;
  background: linear-gradient(
    92deg,
    ${props => props.theme.gradientGreen} 0%,
    ${props => props.theme.gradientBlue} 100%
  );
`;

export const HoldingListWrapper = styled(Grid)`
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border-radius: 4px;
  background: rgba(249, 250, 251, 0.7);
  backdrop-filter: blur(2px);
  border: 1px solid #e4e7ec;
`;

export const AvatarWrapper = styled(Grid)<any>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

// Define keyframes
const dotPulseBefore = keyframes`
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
`;

const dotPulse = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;

const dotPulseAfter = keyframes`
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
`;

// Styled component
export const DotPulseContainer = styled.div`
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${props => props.theme.pink};
  color: ${props => props.theme.pink};
  box-shadow: 9999px 0 0 -5px;
  animation: ${dotPulse} 1.5s infinite linear;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => props.theme.pink};
    color: ${props => props.theme.pink};
  }

  &::before {
    box-shadow: 9984px 0 0 -5px;
    animation: ${dotPulseBefore} 1.5s infinite linear;
    animation-delay: 0s;
  }

  &::after {
    box-shadow: 10014px 0 0 -5px;
    animation: ${dotPulseAfter} 1.5s infinite linear;
    animation-delay: 0.5s;
  }
`;

export const ChatBoxContentWrapper = styled(Grid).withConfig({
  shouldForwardProp,
})<{
  isSender?: boolean;
  istablecontent?: number;
}>`
  width: max-content;
  max-width: 100%;
  padding: 10px 0;
  color: ${props => props.theme.textBlack};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px 10px 10px 10px;

  span {
    color: ${props => props.theme.textBlack};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  ${({ istablecontent }: any) =>
    istablecontent &&
    css`
      padding: 0;
      border-radius: 8px;
      box-shadow: 0px 4px 16px 0px rgba(22, 16, 40, 0.1);
    `}

  ${({ isSender }: any) =>
    isSender &&
    css`
      font-weight: 500;
    `}
`;

export const ChatTableContainer = styled(TableContainer)`
  width: 100%;
  width: fit-content;
  border: none;
  max-height: 60vh;
  margin-top: 1rem;
  overflow-x: auto;
  @media (min-width: 600px) {
    max-width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 1rem; /* add margin only if table container is not last child */
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.grey1} !important;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
    width: 2px;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 7px;
  }
`;

export const ChatTableWrapper = styled(Table)`
  /* display: block; */
  border: none;
  padding: 0;
  overflow-x: scroll;
  border-collapse: collapse;
  white-space: nowrap;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);

  & .MuiTableHead-root {
    & .MuiTableRow-root {
      th {
        padding: 12px 20px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: ${props => props.theme.solidWhite};
        text-align: left;
        background: ${props => props.theme.black};
        backdrop-filter: blur(2px);
        border: none;
        border-bottom: 1px solid ${props => props.theme.borderColor};

        &:first-of-type {
          padding: 12px 16px;
          text-align: left;
          border-top-left-radius: 6px;
        }
        &:last-child {
          padding: 12px 16px;
          text-align: left;
          border-top-right-radius: 6px;
        }
      }
    }
  }
  & .MuiTableBody-root {
    & .MuiTableRow-root {
      td {
        &:first-of-type {
          padding: 12px 16px;
          text-align: left;
        }
        &:last-child {
          padding: 12px 16px;
          text-align: left;
        }
      }
      &:last-child {
        td {
          border-bottom: 0px;
          &:first-of-type {
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }
`;

export const ChatTableHead = styled(TableHead)`
  th {
    font-weight: 500 !important;
    text-transform: capitalize !important;
    background: ${props => props.theme.solidWhite} !important;
    color: #86858b !important;
  }
  &:first-of-type {
    border-top-left-radius: 6px !important;
  }
  &:last-child {
    border-top-right-radius: 6px !important;
  }
`;

export const ChatTableBody = styled(TableBody)``;

export const ChatTableRow = styled(TableRow)`
  &:last-child {
    td {
      border-bottom: none !important;
    }
  }
`;

export const ChatTableCell = styled(TableCell)`
  padding: 12px 20px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 300;
  line-height: 16px !important;
  color: ${props => props.theme.black};
  text-align: left;
  background: ${props => props.theme.solidWhite};
  backdrop-filter: blur(2px);
  border: none;
  border-bottom: 1px solid ${props => props.theme.borderColor} !important;

  &:last-child {
    padding-left: 0 !important;
    padding-right: 10px !important;
  }
  @media (min-width: 600px) {
    font-size: 14px !important;
  }
`;

export const InvestmentContainer = styled(Container)`
  margin-left: 0px;
  padding: 0px !important;
`;
export const InvestmentCard = styled(Card)`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
export const Description = styled('div')`
  font-size: 12px;
  color: #4d4d4d;
  margin-top: 5px;
  padding-right: 15px;
`;

export const ButtonBox = styled(Box)`
  flex: 30%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ViewImplementationButton = styled(Button)`
  font-size: 12px !important;
  border: 1px solid #000;
  color: #000;
  border-radius: 16px !important;
  margin-right: 8px !important;
  text-transform: none !important;
`;

export const ApplyButton = styled(Button)`
  font-size: 12px !important;
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #000 !important;
  border-radius: 16px !important;
  text-transform: none !important;
`;

export const CIOCard = styled(Card)`
  background-color: #f9fafb;
`;

export const CIOChip = styled(Chip)`
  color: #5a53bf !important;
  background-color: #efeff8 !important;
  font-size: 10px !important;
`;

export const TextWrapper = styled('p')`
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
`;

export const ButtonContainer = styled(Grid)`
  border-radius: 8px;
  background: var(--neutral-100, #f2f4f7);
  padding: 16px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 124%;
  background: rgba(213, 213, 213, 0.2);
  padding: 15px;
  border-radius: 8px;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const QuestionItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  background: #fff;
  // width: 29%;
  flex-basis: calc(
    50% - 10px
  ); /* Set width for two cards in a row with some margin */
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const QuestionHead = styled.div`
  color: #333;
  font-size: 14px;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const QuestionListItem = styled.div`
  color: #667085;
  font-size: 14px;
  padding: 10px 0px;
  cursor: pointer;

  :hover {
    font-weight: 900;
    transition: font-size 0.2s linear 0s;
  }
`;

export const GuidanceText = styled.div`
  padding: 10px;
  font-size: 14px;
  color: #000;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
`;

export const StyledQuestionCardButton = styled(Button)`
  border-radius: 20px;
  color: #000;
  font-size: 14px;
  text-transform: none;
  border: 1px solid #e4e7ec;
  background: linear-gradient(0deg, #fff 0%, #d5d5d5 921.25%);
  background-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const FooterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionHeaderDiv = styled.div`
  display: flex;
  color: #000;
  padding-bottom: 15px;
  font-size: 14px;
`;

export const StyledDataTable = styled(MUIDataTable)`
  width: 100%;
  overflow-x: auto;

  & .MuiTable-root {
    border-collapse: collapse;
    width: 100%;
  }

  & .MuiTableCell-root {
    background-color: #333;
    color: #f7f7f7;
    padding: 10px;
    font-size: 16px; /* Default for smallest screens */
  }

  & .MuiTableHead-root {
    display: none; /* Default for smallest screens */
    background-color: #222;
  }

  & .MuiTableBody-root {
    & .MuiTableRow-root {
      display: block; /* Default for smallest screens */
      margin-bottom: 16px;
      border-bottom: 1px solid #ddd;
    }
  }

  @media (min-width: 480px) {
    & .MuiTableCell-root {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 8px;
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    & .MuiTableCell-root {
      display: table-cell;
      width: auto;
      padding: 10px;
      font-size: 12px;
    }

    & .MuiTableRow-root {
      display: table-row;
      margin-bottom: 0;
    }

    & .MuiTableHead-root {
      display: table-header-group;
    }
  }
`;

export const TickerDrawerContainer = styled.div.withConfig({
  shouldForwardProp,
})<MainContainerProps>`
  width: ${props => props.width || ''};
  // height: 91vh;
  height: auto;
  padding: ${props => props.padding ?? '0px 20px 20px'};
  overflow-x: hidden;
  overflow-y: auto;

  .close-container {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
`;

export const DrawerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 25px;

  p {
    font-size: 12px;
  }

  @media (min-width: 960px) {
    p {
      font-size: 14px;
    }
  }
`;

export const HeaderDivWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const HeaderAnalyticDataDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding-left: 10px;
`;

export const DescriptionDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const DescText = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
  font-size: 14px;
  color: #000;
  font-weight: 500;

  @media (min-width: 900px) {
    font-size: 16px;
  }
`;

export const DescPara = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  color: #3c3c3c;
  font-weight: 400;

  @media (min-width: 900px) {
    font-size: 14px;
  }
`;

export const ReturnsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const HeaderSection = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;

export const TickerDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
`;

export const TickerName = styled.div`
  font-size: 20px;
  color: #000;
  margin-right: 10px;

  @media (min-width: 900px) {
    font-size: 24px;
  }
`;

export const TickerOrgName = styled.div`
  font-size: 12px;
  color: #000;
  @media (min-width: 900px) {
    font-size: 14px;
  }
`;

export const LineGraphContainer = styled.div`
  width: 100%;
  display: flex;
  height: 300px;
  // margin-top: 15px;
`;

export const KeyStatisticsDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
`;

export const KeyStatisticsDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
`;

export const TitleText = styled.div`
  font-size: 12px;
  color: #667085;
  display: flex;
  width: 100%;
  padding-bottom: 10px;

  @media (min-width: 900px) {
    font-size: 14px;
  }
`;

export const PercentageDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 7px;
  align-items: center;
`;

export const CurrentPercentage = styled.div`
  font-size: 16px;
  color: #000;
`;

export const CategoryPercentage = styled.div`
  font-size: 10px;
  color: #000;
`;

export const FundInfoWrapper = styled.div<FundInfoProps>`
  width: ${props => props.width || '50%'};
  display: flex;
  flex-direction: column;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FundDataName = styled.div`
  font-size: 12px;
  color: #344054;
  font-weight: 400;

  @media (min-width: 900px) {
    font-size: 14px;
  }
`;

export const FundValue = styled.p`
  font-size: 14px;
  color: #3c3c3c;
  font-weight: 500;

  @media (min-width: 900px) {
    font-size: 12px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubText = styled.div`
  font-size: 10px;
  color: #979797;
`;

export const FundInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const FundInfoBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const FundBoxHeader = styled.p`
  font-size: 16px;
  color: #000;
  padding-bottom: 5px;
`;

export const FundBoxSubHeader = styled.div`
  font-size: 12px;
  color: #98a2b3;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceData = styled.div`
  display: flex;
  font-size: 20px;
  color: #000;
  justify-content: center;
  padding-bottom: 5px;
  width: 100%;
  @media (min-width: 900px) {
    font-size: 24px;
  }
`;

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -5px;
  p {
    font-size: 12px;
  }

  @media (min-width: 960px) {
    p {
      font-size: 14px;
    }
  }
`;

export const BackArrowWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (min-width: 600px) {
    margin-right: 20px;
  }
`;

export const PrevPageName = styled.div`
  margin-right: 5px;
  font-size: 12px;
  color: #667085;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  @media (min-width: 600px) {
    margin-right: 20px;
  }
`;

export const StyledDivider = styled.div`
  margin-right: 5px;
  color: #667085;
  @media (min-width: 600px) {
    margin-right: 20px;
  }
`;

export const CurrentPageName = styled.div`
  padding: 8px;
  border-radius: 4px;
  background: rgba(16, 24, 40, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 12px;
  color: #000;
  font-weight: 500;
`;

export const QuestionCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  .whatElseButton {
    margin: 20px 0;
    border-top: 5px solid #d5d5d533;
    width: fit-content;
    padding-top: 12px;
  }
  .viewMoreButton {
    cursor: none;
    color: '#818181';
  }
  .viewMoreButton:hover {
    cursor: pointer;
    text-decoration: underline;
    color: black;
  }
`;
export const MemoSpinnerContainer = styled(Box)<any>`
  height: 15px;
  width: 15px;
  .memo-icon {
    animation: rotation 3s infinite linear;
    height: 15px;
    width: 15px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
export const QuestionCategories = styled(Box).withConfig({
  shouldForwardProp,
})<any>`
  border-radius: 18px;
  border: 1px solid ${props => (props.hover ? 'black' : '#818181')};
  background: #fff;
  padding: 8px 18px;
  min-height: 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
`;

export const QuestionsCardText = styled.p.withConfig({
  shouldForwardProp,
})<any>`
  font-size: 14px;
  color: ${props => (props.hover ? 'black' : '#818181')};
  font-weight: 500;
  text-wrap: wrap;
`;
export const WhatElseResponseContainer = styled(Box)<any>`
  width: 100%;
  display: flex;
  column-gap: 10px;
`;

export const PopoverMenuItem = styled(MenuItem)`
  & .MuiListItemIcon-root {
    min-width: 28px !important;
  }
`;

export const PopoverListText = styled(ListItemText)`
  & .MuiTypography-root {
    color: #535256 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
`;

export const TimestampStamp = styled(Grid)`
  text-align: right;
  padding: 0;
  margin-top: 0.5rem !important;
  flex-basis: 100%;
  margin-right: 10px !important;

  @media (min-width: 900px) {
    margin-right: 0 !important;
    text-align: right;
    padding: 10px 0;
    margin-top: 0 !important;
  }
`;

export const FundSwapComponent = styled(Box)`
  width: 100%;
  height: fit-content;
  color: #424242;
  background: #f7f7f7;
  padding: 15px;
  @media (min-width: 900px) {
    min-width: fit-content;
  }
`;

export const ChatFeedbackContainer = styled(Grid)`
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 900px) {
    padding-right: 30px;
    column-gap: 0.5rem;
  }
`;

export const ChatFeedbackGrid = styled(Grid)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: 56px !important;
  @media (min-width: 600px) {
    margin: 0px 40px;
  }
`;

/** EXTENDED MARKDOWN STYLING STARTS **/

export const MdxContainer = styled(Box)`
  width: 100%;
  color: #222124;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
`;

export const MdxTypography = styled(Typography)`
  font-size: 12px !important; /* Default for smallest screens */
  font-style: normal;
  font-weight: 400;
  line-height: 1.3rem !important;
  margin-top: -2px !important;
  // margin-bottom: 2px !important;

  @media (min-width: 600px) {
    font-size: 14px !important;
  }
`;

export const MdxList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: 1.5px 0 3px 0px;
  padding: 0;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  list-style-position: inside;
  list-style: none;
  @media (min-width: 600px) {
    font-size: 14px !important;
  }
`;

export const MdxListItem = styled.li`
  position: relative;
  list-style: none;
  margin-left: 1rem;

  &::before {
    content: '-';
    position: absolute;
    left: -1rem;
    // padding-left: 0.4rem;
  }

  > ul {
    list-style: none;
    padding-left: 1.5rem;
    padding-top: 0.1rem;

    > li {
      position: relative;
      // padding-left: 1rem;

      &::before {
        content: '●';
        position: absolute;
        left: -1rem;
      }

      /* Style for nested ul/li */
      ul {
        list-style: none; /* Hide bullet beyond this level */
        padding-left: 1.5rem;
        padding-top: 0.1rem;

        > li {
          position: relative;

          &::before {
            content: '○';
            position: absolute;
            left: -1rem;
          }

          /* Recursive styling for nested ul/li */
          ul {
            list-style: none; /* Hide bullet beyond this level */
            padding-left: 1.5rem;
            padding-top: 0.1rem;

            > li {
              position: relative;

              &::before {
                content: '○';
                position: absolute;
                left: -1rem;
              }

              ul {
                list-style: none; /* Hide bullet beyond this level */
                padding-left: 1.5rem;
                padding-top: 0.1rem;

                > li {
                  position: relative;

                  &::before {
                    content: '○';
                    position: absolute;
                    left: -1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MdxOrderedList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin: 0px 0 0;
  padding: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  list-style-position: inside;
  counter-reset: item;
  list-style: none;
  @media (min-width: 600px) {
    font-size: 14px;
  }

  > li {
    counter-increment: item;
    position: relative;
    padding-left: 1rem;
    list-style: none;

    &::before {
      content: counter(item) '.';
      position: absolute;
      left: 0;
    }

    /* Style for nested ul/li */
    ul {
      margin: 0;
      padding-left: 1.5rem;
      list-style: none;
      // padding-top: 0.2rem;

      > li {
        position: relative;
        // padding-left: 1rem;

        &::before {
          content: '●';
          position: absolute;
          left: -1rem;
        }

        /* Recursive styling for nested ul/li */
        ul {
          list-style: none; /* Hide bullet beyond this level */
          padding-left: 1.5rem;
          padding-top: 0.2rem;
          margin-bottom: 4px;

          > li {
            position: relative;

            &::before {
              content: '○';
              position: absolute;
              left: -1rem;
            }
            ul {
              list-style: none; /* Hide bullet beyond this level */
              padding-left: 1.5rem;
              padding-top: 0.2rem;
              margin-bottom: 4px;

              > li {
                position: relative;

                &::before {
                  content: '○';
                  position: absolute;
                  left: -1rem;
                }
              }
            }
          }
        }
      }
    }
  }
  &:last-of-type {
    margin-bottom: 10px;
  }
`;

export const MdxSpan = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

/** EXTENDED MARKDOWN STYLING ENDS **/

export const RiskAssessmentContainer = styled(Box)`
  background: #f7f7f7;
  padding: 15px;
  border-radius: 5px;
`;

export const SourceDocumentWrapper = styled(Grid)`
  width: 100%;
  color: #282829;
  padding: 10px 0;

  .card {
    opacity: 0.8;
    padding: 10px;
    background: var(--Neutral-100, #ededee);
    cursor: pointer;
  }

  .documentName {
    color: #5736db;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }

  .fileUrl {
    overflow: hidden;
    color: var(--Neutral-900, #535256);
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }
`;

export const AssetContainer = styled(Grid)`
  width: 97%;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  color: #000;
`;
