import React from 'react';

import { SlideContextProvider } from 'features/slides';
import { assetmarkTheme } from 'features/slides/themes/assetmark/assetmark';
import { PerformanceReportSlides } from '../components';

export function PerformanceReportPage(): React.ReactNode {
  return (
    <SlideContextProvider
      theme={assetmarkTheme}
      width={1280}
      height={960}
      type="performance_report"
    >
      <PerformanceReportSlides />
    </SlideContextProvider>
  );
}
