import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { price } from '@common/number';
import { Box, Grid } from '@mui/material';
import { actions as chatActions } from '@features/Chat/slice';
import { getAuthDetails } from '@features/Profile/selector';
import { useGetIsNewUser } from '../../../pages/Conversations/hooks/useGetIsNewUser';
import { useGetEnterpriseQuestions } from 'pages/Conversations/hooks/useEnterpriseQuestions';
import DataBanner from '@components/DataBanner';
import QuestionsCards from '@components/QuestionsCards';
import NewUserStarterCards from '@components/NewUserStarterCards';
import { GreyLabelText } from '@styles/Typography';
import { CarrouselContainer, CarrouselSlide } from './styles';
import colorLine from '@images/color-line.svg';
import IconDotHand from '@images/icon-dot-hand.svg';
import IconDotChart from '@images/icon-dot-chart.svg';
import { isAmkExperience, isSeiExperience } from '../../../helpers/experience';

function SubsequentLogin(): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isNewUserLogin } = useGetIsNewUser();

  const advisorDetails = useSelector(getAuthDetails);

  const { questions, isLoaded, findQuestionByText } =
    useGetEnterpriseQuestions();

  const handleWorkflowsQuestionClick = (questionText: string) => {
    const question = findQuestionByText(questionText)!;

    if (question.navigate !== '') {
      navigate(question.navigate);
    } else {
      const isChatStreaming =
        (localStorage.getItem('streaming') as string) === 'true';
      if (isChatStreaming) {
        dispatch(chatActions.setSearchQuery(question.prompt));
      } else {
        dispatch(chatActions.searchQueryRequest({ query: question.prompt }));
      }
      navigate('/conversations/start');
    }
  };

  return (
    <Grid
      id="subsequent-login"
      container
      alignItems="center"
      overflow="auto"
      padding="48px"
      height="calc(100vh - 184px)"
    >
      <Grid container>
        <GreyLabelText fontSize="24px" color="#000" mt={1} letterSpacing="-1px">
          {advisorDetails?.firstName && isNewUserLogin
            ? `Welcome  ${advisorDetails?.firstName}`
            : `Welcome back ${advisorDetails?.firstName}
          `}
        </GreyLabelText>

        <Box
          sx={{
            width: '100%',
            paddingBottom: '24px',
            marginBottom: '32px',
            backgroundImage: `url('${colorLine}')`,
            backgroundRepeat: 'repeat-x',
            backgroundPositionY: 'bottom',
          }}
        >
          <GreyLabelText
            fontSize="48px"
            color="#000"
            fontWeight={700}
            fontFamily="Inter"
            letterSpacing="-3px"
            lineHeight="77px"
          >
            {isNewUserLogin
              ? 'Delighted you’re here'
              : 'Here’s the latest on your book'}
          </GreyLabelText>
        </Box>

        {!isNewUserLogin && (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
              <DataBanner
                title="AUM"
                mainNumber={price(advisorDetails.summary.totalAum)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
              <DataBanner
                title="Clients"
                mainNumber={advisorDetails.summary.noOfClients}
              />
            </Grid>
          </Grid>
        )}

        {isLoaded &&
        isNewUserLogin &&
        !isSeiExperience() &&
        !isAmkExperience() ? (
          <CarrouselContainer>
            <CarrouselSlide id="carrousel">
              <NewUserStarterCards
                title="Get Started"
                titleIcon={IconDotHand}
              />

              <QuestionsCards
                title="Workflows"
                titleIcon={IconDotChart}
                questions={questions}
                handleQuestionClick={handleWorkflowsQuestionClick}
              />
            </CarrouselSlide>
          </CarrouselContainer>
        ) : (
          <QuestionsCards
            title="Workflows"
            titleIcon={IconDotChart}
            questions={questions}
            handleQuestionClick={handleWorkflowsQuestionClick}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SubsequentLogin;
