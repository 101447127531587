import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';
import { initialState } from './slice';

const selectSlice = (state: RootState) => state?.global || initialState;

export const selectToastState = createSelector(
  [selectSlice],
  state => state.toastInfo,
);

export const getChatWindowCollapseState = createSelector(
  [selectSlice],
  state => state.chatWindowCollapse,
);

export const getHeaderMenuList = createSelector(
  [selectSlice],
  state => state.headerMenuList,
);

export const getProfileMenuList = createSelector(
  [selectSlice],
  state => state.profileMenuList,
);
