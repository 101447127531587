import { Box, Grid, TextField } from '@mui/material';
import React, { FC } from 'react';
import { InputLabels } from '../Buttons';

interface DisclosuresProps {
  disclosure: string | undefined;
  dutiesAndResponsibilities: string | undefined;
  advisorFeeFullDisclosure: string | undefined;
  editable: boolean;
  onDisclosureChange: (value: string) => void;
  onDutiesAndResponsibilitiesChange: (value: string) => void;
  onAdvisorFeeFullDisclosureChange: (value: string) => void;
}

const Disclosures: FC<DisclosuresProps> = ({
  disclosure,
  dutiesAndResponsibilities,
  advisorFeeFullDisclosure,
  editable,
  onDisclosureChange,
  onDutiesAndResponsibilitiesChange,
  onAdvisorFeeFullDisclosureChange,
}) => {
  return (
    <Grid container my={'24px'}>
      <Grid item sx={{ mr: '32px', width: '200px' }}>
        <Box mb={'6px'}>
          <InputLabels sx={{ color: '#000000', fontWeight: '500' }}>
            Firm Disclosures
          </InputLabels>
        </Box>
        <Box>
          <p style={{ color: '#4F4F4F', fontWeight: '400', fontSize: '14px' }}>
            Updated 10.18.2025
          </p>
        </Box>
      </Grid>

      <Grid sx={{ width: 'calc(100% - 232px)' }}>
        <InputLabels
          htmlFor="weight"
          sx={{
            fontWeight: '500',
            color: '#000',
            marginBottom: '8px',
            fontSize: '14px',
          }}
        >
          General
        </InputLabels>

        <TextField
          multiline
          fullWidth
          disabled={!editable}
          sx={{ paddingBottom: '24px' }}
          placeholder="You have not updated your firm disclosures"
          value={disclosure || ''}
          onChange={e => onDisclosureChange(e.target.value)}
        />

        <InputLabels
          htmlFor="weight"
          sx={{
            fontWeight: '500',
            color: '#000',
            marginBottom: '8px',
            fontSize: '14px',
          }}
        >
          Duties and Responsibilities
        </InputLabels>

        <TextField
          multiline
          fullWidth
          disabled={!editable}
          sx={{ paddingBottom: '24px' }}
          placeholder="You have not updated your firm disclosures"
          value={dutiesAndResponsibilities || ''}
          onChange={e => onDutiesAndResponsibilitiesChange(e.target.value)}
        />

        <InputLabels
          htmlFor="weight"
          sx={{
            fontWeight: '500',
            color: '#000',
            marginBottom: '8px',
            fontSize: '14px',
          }}
        >
          Advisor Fee
        </InputLabels>

        <TextField
          multiline
          fullWidth
          disabled={!editable}
          placeholder="You have not updated your firm disclosures"
          value={advisorFeeFullDisclosure || ''}
          onChange={e => onAdvisorFeeFullDisclosureChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default Disclosures;
