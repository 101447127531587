import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChatState,
  ChatInterface,
  ChatActionPayloadType,
  ChatFeedbackInterface,
  ChatInitResponseInterface,
} from './types';

export const initialState: ChatState = {
  /* Common State */
  processingQuery: false,
  query: '',
  loading: false,
  isSampleQuestionAsked: false,
  sampleQuestions: [],
  chatInitResponse: {
    chatHistory: [],
    isValid: false,
    user: '',
  },
  feedback: {
    loading: false,
    openModal: false,
    scale: null,
    chatLogId: null,
    comment: null,
  },
  eventStream: {
    eventSource: null,
    isStreamInitated: false,
  },
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    /* --- Chat History --- */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadChatRequest(state) {},
    loadChatFetch(state) {
      state.loading = true;
    },
    loadChatSuccess(state, action: PayloadAction<ChatInitResponseInterface>) {
      state.loading = false;
      state.chatInitResponse = {
        ...state.chatInitResponse,
        ...action.payload,
      };
    },
    pushToChatHistory(state, action: PayloadAction<ChatInterface>) {
      const CurrentChatHistory = state.chatInitResponse?.chatHistory;
      state.loading = false;
      state.isSampleQuestionAsked = false;
      if (action.payload && typeof action.payload === 'object') {
        CurrentChatHistory.push(action.payload);
      }
      state.chatInitResponse = {
        ...state.chatInitResponse,
        chatHistory: CurrentChatHistory,
      };
    },

    /* --- CHAT QUERY --- */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchQueryRequest(state, action: PayloadAction<any>) {
      state.query = action?.payload;
    },

    setSampleQuestion(state, action: PayloadAction<any>) {
      state.sampleQuestions = action?.payload?.sampleQuestions;
    },
    searchSampleQuestion(state, action: PayloadAction<any>) {
      state.isSampleQuestionAsked = action?.payload?.isSampleQuestionAsked;
    },

    setSearchQuery(state, action: PayloadAction<any>) {
      state.query = action?.payload;
    },
    searchQueryFetch(state) {
      state.processingQuery = true;
    },
    searchQuerySuccess(state, action: PayloadAction<ChatActionPayloadType>) {
      state.processingQuery = false;
      state.query = action.payload?.query;
    },

    /* --- CHAT FEEDBACK --- */
    toggleChatFeedbackModal(state, action: PayloadAction<any>) {
      state.feedback = {
        ...state.feedback,
        ...action.payload,
        loading: false,
      };
    },
    submitChatFeedbackRequest(state, action: PayloadAction<any>) {
      state.query = action?.payload;
    },
    submitChatFeedbackFetch(state) {
      state.feedback = {
        ...state.feedback,
        loading: true,
      };
    },
    submitChatFeedbackSuccess(
      state,
      action: PayloadAction<ChatFeedbackInterface>,
    ) {
      const CurrentChatHistory = state.chatInitResponse?.chatHistory;
      const { response: feedbackResponse, scale, chatLogId } = action.payload;

      // find and update the chat from chatHistory
      const chatIndex = CurrentChatHistory.findIndex(k => k.id === chatLogId);
      if (chatIndex > -1) {
        const existingFeedback = CurrentChatHistory[chatIndex]['feedback'];
        if (existingFeedback) {
          // Merge the existing feedback with the new feedbackResponse
          CurrentChatHistory[chatIndex]['feedback'] = {
            ...existingFeedback,
            ...feedbackResponse,
            scale,
          };
        } else {
          // If there was no existing feedback, set the new feedbackResponse
          CurrentChatHistory[chatIndex]['feedback'] = feedbackResponse;
        }
      }

      state.chatInitResponse = {
        ...state.chatInitResponse,
        chatHistory: CurrentChatHistory,
      };

      state.feedback = {
        ...state.feedback,
        ...action.payload,
        loading: false,
      };
    },

    /* ---- Chat Event Stream ---- */
    initiateEventStream(state, action: PayloadAction<{ advisorId: string }>) {
      const es = new EventSource(
        `${process.env.REACT_APP_API_BASE_URL}/chat/stream/${action.payload.advisorId}`,
      );
      state.eventStream = {
        eventSource: es,
        isStreamInitated: true,
      };
    },
    clearEventStream(state) {
      state.eventStream.eventSource?.close();
      state.eventStream = {
        eventSource: null,
        isStreamInitated: false,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = chatSlice;
