import React, { useRef } from 'react';
import ExcelJS from 'exceljs';

export type ExcelTable = {
  [key: string]: {
    stringValue: string;
    value: ExcelJS.ValueType;
    type: string;
    hasBgColor: boolean;
  };
};

type UploadExcelProps = {
  handleExcelUpload: (data: ExcelTable) => void;
};

const UploadExcel = ({
  handleExcelUpload,
}: UploadExcelProps): React.ReactNode => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async ev => {
    const file = ev.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = async ev => {
      const result: ExcelTable = {};
      const buffer = ev.target!.result as ArrayBuffer;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);
      const worksheet = workbook.getWorksheet(1);
      worksheet?.eachRow(row => {
        row.eachCell(cell => {
          result[cell.address] = {
            stringValue: cell.value + '',
            value: parseCellValue(cell.value, cell.type),
            type: parseCellType(cell.type),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            hasBgColor: cell.fill?.bgColor !== undefined,
          };
        });
      });
      handleExcelUpload(result);
      inputRef.current!.value = '';
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <input
      ref={inputRef}
      type="file"
      accept=".xlsx, .xls"
      onChange={handleFileUpload}
      style={{ color: '#000' }}
    />
  );
};

export default UploadExcel;

const parseCellValue = (value: any, type: ExcelJS.ValueType): any => {
  switch (type) {
    case ExcelJS.ValueType.Number:
      return Number(value);
    case ExcelJS.ValueType.Boolean:
      return Boolean(value);
    case ExcelJS.ValueType.Date:
      return new Date(value);
    case ExcelJS.ValueType.Null:
      return null;
    case ExcelJS.ValueType.String:
    case ExcelJS.ValueType.Hyperlink:
    case ExcelJS.ValueType.Formula:
    default:
      return value;
  }
};

const parseCellType = (type: ExcelJS.ValueType): string => {
  switch (type) {
    case ExcelJS.ValueType.String:
      return 'string';
    case ExcelJS.ValueType.Number:
      return 'number';
    case ExcelJS.ValueType.Boolean:
      return 'boolean';
    case ExcelJS.ValueType.Date:
      return 'date';
    case ExcelJS.ValueType.Hyperlink:
      return 'hyperlink';
    case ExcelJS.ValueType.Formula:
      return 'formula';
    case ExcelJS.ValueType.Null:
      return 'null';
    default:
      return 'unknown';
  }
};
