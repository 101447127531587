// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_footer__IeliI {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  border-top: 1px solid #e1e1e1;
  grid-column: 1 / 3;
  z-index: 10;
}

.styles_footerLeft__rF1OQ {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.styles_footerRight__LNS20 {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Conversations/components/SlidesEditor/components/Footer/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".footer {\n  display: flex;\n  gap: 1rem;\n  justify-content: space-between;\n  align-items: center;\n  align-items: center;\n  border-top: 1px solid #e1e1e1;\n  grid-column: 1 / 3;\n  z-index: 10;\n}\n\n.footerLeft {\n  display: flex;\n  gap: 1rem;\n  align-items: center;\n}\n\n.footerRight {\n  display: flex;\n  gap: 0.25rem;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `styles_footer__IeliI`,
	"footerLeft": `styles_footerLeft__rF1OQ`,
	"footerRight": `styles_footerRight__LNS20`
};
export default ___CSS_LOADER_EXPORT___;
