import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Header from './components/Header';
import ModelName from './components/ModelName';
import ProgressInvested from './components/ProgressInvested';
import TickerForm from './components/TickerForm';
import TickerTable from './components/TickerTable';
import { useGetCustomModelsQuery } from '../../Custom/hooks/useGetCustomModelsQuery';
import { usePutCustomModelsMutation } from '../../Custom/hooks/usePutCustomModelsMutation';

interface CustomModelEditProps {
  modelId: string;
  handleSaveSuccess: () => void;
}

const CustomModelEdit: React.FC<CustomModelEditProps> = ({
  modelId,
  handleSaveSuccess,
}) => {
  const { existsOtherModelWithSameName } = useGetCustomModelsQuery();
  const { putCustomModelMutate } = usePutCustomModelsMutation();
  const { findById } = useGetCustomModelsQuery();
  const model = findById(modelId);
  const [name, setName] = useState('');
  const [holdings, setHoldings] = useState<any[]>([]);

  useEffect(() => {
    setName(model?.name || '');
    setHoldings(model?.holdings || []);
  }, [model?.name]);

  // helpers

  const getTotalHoldingsWeight = () => {
    const total = holdings.reduce(
      // user cannot add decimals in a holding
      // multiply and divide by 100 to avoid floating point error
      (total, holding) => total + holding.weight * 100,
      0,
    );
    return total / 100;
  };

  const isValidFormData = () => name !== '' && getTotalHoldingsWeight() === 1;

  // events

  const handleSave = () => {
    if (isValidFormData() && !existsOtherModelWithSameName(model!.id, name)) {
      putCustomModelMutate({
        id: model!.id,
        name: name,
        holdings: holdings.map(holding => ({
          ticker: holding.ticker,
          weight: holding.weight,
          name: holding.name,
        })),
      });
      handleSaveSuccess();
    }
  };

  const handleAddHolding = (ticker, name, weight) => {
    const existsHolding = !!holdings.find(holding => holding.ticker === ticker);
    if (!existsHolding) {
      const newHoldings = [...holdings];
      newHoldings.push({
        id: `${Date.now()}`,
        ticker: ticker,
        name: name,
        weight: weight,
      });
      setHoldings(newHoldings);
    }
  };

  const handleEditHoldingWeight = (holdingId, weight) => {
    const holding = holdings.find(holding => holding.id === holdingId);
    holding.weight = weight;
    setHoldings([...holdings]);
  };

  const handleDeleteHolding = holdingId => {
    const newHoldings = holdings.filter(holding => holding.id !== holdingId);
    setHoldings(newHoldings);
  };

  return (
    <Box sx={{ background: '#fff', borderRadius: '4px', width: '100%' }}>
      <Header name={name} handleBackButtonClick={handleSaveSuccess} />
      <Grid container gap={2} padding={{ xs: '10px', sm: '20px' }}>
        <Grid item xs={12}>
          <ModelName
            name={name}
            disableSaveButton={!isValidFormData()}
            handleChange={setName}
            handleSave={handleSave}
          />
        </Grid>
        <Grid item xs>
          <Stack gap={2}>
            <ProgressInvested value={getTotalHoldingsWeight()} />
            <TickerForm handleAddSecurity={handleAddHolding} />
          </Stack>
        </Grid>
        <Grid item xs>
          <TickerTable
            holdings={holdings}
            handleDeleteHolding={handleDeleteHolding}
            handleEditHoldingWeight={handleEditHoldingWeight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomModelEdit;
