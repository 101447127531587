import { Box, Grid, InputLabel, TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { InputLabels } from '../Buttons';

interface FeesProps {
  fee: number | undefined;
  onAdvisoryFeeChange: (value: number) => void;
  editable: boolean;
}

const convertToStr = (value: number | undefined) => {
  return value ? (value * 100).toFixed(2).toString() : '0';
};

const convertToNum = (value: string) => {
  const parsedNumber = parseFloat(value);
  const sanitizedNumber = isNaN(parsedNumber) ? 0 : parsedNumber;
  return Number((sanitizedNumber / 100).toFixed(4));
};

const Fees: FC<FeesProps> = ({ fee, editable, onAdvisoryFeeChange }) => {
  const [feeValueStr, setFeeValueStr] = useState(convertToStr(fee));

  const handleAdvisoryFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) < 0) {
      setFeeValueStr('0');
    } else if (Number(value) > 100) {
      setFeeValueStr('100');
    } else {
      setFeeValueStr(value);
    }

    if (!isNaN(e.target.valueAsNumber)) {
      const value = Math.max(0, Math.min(convertToNum(e.target.value), 100));
      onAdvisoryFeeChange(value);
    }
  };

  useEffect(() => {
    fee && setFeeValueStr(convertToStr(fee));
  }, [fee]);

  return (
    <Grid container my={'24px'}>
      <Grid item sx={{ mr: '32px', width: '200px' }}>
        <Box mb={'6px'}>
          <InputLabels sx={{ color: '#000', fontWeight: '500' }}>
            Fees
          </InputLabels>
        </Box>
        <Box>
          <p style={{ color: '#4F4F4F', fontWeight: '400', fontSize: '14px' }}>
            Manage your firm&apos;s fee across your client base
          </p>
        </Box>
      </Grid>

      <Grid item sx={{ width: 'calc(100% - 232px)' }}>
        <InputLabel
          htmlFor="fiatfee"
          sx={{
            fontWeight: '500',
            color: '#000',
            fontSize: '14px',
          }}
        >
          Flat fee (%)
        </InputLabel>
        <TextField
          type="number"
          size="small"
          disabled={!editable}
          value={feeValueStr}
          sx={{
            width: '220px',
            paddingTop: '5px',
            '& input[type=number]': {
              '-moz-appearance': 'textfield',
            },
            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
              {
                '-webkit-appearance': 'none',
                margin: 0,
              },
          }}
          onChange={handleAdvisoryFeeChange}
        />
      </Grid>
    </Grid>
  );
};

export default Fees;
