import axios from 'axios';
import { useQuery } from 'react-query';
import { settings } from 'config';
import { strIncludes } from 'helpers/string';

interface CustomModel {
  id: string;
  name: string;
  updatedAt: string;
  securities: number;
  holdings: {
    id: string;
    name: string;
    ticker: string;
    weight: number;
  }[];
}

interface UseGetCustomModelsQueryResult {
  customModels: CustomModel[] | undefined;
  isCustomModelsLoading: boolean;
  isCustomModelsError: boolean;
  refetch: () => void;
  findById: (filterText: string | undefined) => CustomModel | undefined;
  filterByName: (filterText: string) => CustomModel[];
  existsOtherModelWithSameName: (id: string, name: string) => boolean;
}

export const useGetCustomModelsQuery = (): UseGetCustomModelsQueryResult => {
  const fetchData = async () => {
    const token = localStorage.getItem('x-tifin-ai-token') || '';
    const response = await axios.get(
      `${settings.api.baseUrl}/v2/models?limit=100`,
      {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'x-tifin-ai-auth': token,
        },
      },
    );
    return response.data;
  };

  const { data, isLoading, isError, refetch } = useQuery(
    'customModels',
    fetchData,
    {
      select: ({ data }) => {
        return data.map(model => ({
          id: model.id,
          name: model.name,
          updatedAt: new Date(model.updatedAt),
          securities: model.holdings.length,
          holdings: model.holdings.map(holding => ({
            id: holding.id,
            name: holding.instrument.name,
            ticker: holding.instrument.ticker,
            weight: holding.weight,
          })),
        }));
      },
    },
  );

  const findById = (modelId: string | undefined): CustomModel | undefined =>
    data?.find(model => model.id === modelId) || undefined;

  const filterByName = (filterText: string): CustomModel[] =>
    data?.filter(model => strIncludes(model.name, filterText)) || [];

  const existsOtherModelWithSameName = (id: string, name: string): boolean =>
    !!data?.some(model => model.id !== id && model.name === name);

  return {
    customModels: data,
    isCustomModelsLoading: isLoading,
    isCustomModelsError: isError,
    refetch,
    findById,
    filterByName,
    existsOtherModelWithSameName,
  };
};
