import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../../helpers/api';

type DeleteFavoriteModelArguments = {
  modelId: string;
  successToastMessage: string;
};

type UseDeleteFavoriteModelMutationResult = {
  deleteFavoriteModelMutate: (params: DeleteFavoriteModelArguments) => void;
  deleteFavoriteModelLoading: boolean;
};

export const useDeleteMarketplaceFavoriteMutation =
  (): UseDeleteFavoriteModelMutationResult => {
    const { fetchMutation } = useFetchMutation();

    const { mutate, isLoading } = useMutation(
      ({ modelId, successToastMessage }: DeleteFavoriteModelArguments) =>
        fetchMutation({
          path: `/v2/models/favorites/${modelId}`,
          method: 'DELETE',
          params: { modelId: modelId },
          invalidateQueries: [['marketplaceModels'], ['sponsorFavoriteModel']],
          successToastMessage: successToastMessage,
        }),
    );

    return {
      deleteFavoriteModelMutate: mutate,
      deleteFavoriteModelLoading: isLoading,
    };
  };
