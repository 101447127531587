import React, { useState } from 'react';
import { Button, Dialog, Grid, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import EditPencilIcon from '@icons/EditPencilIcon';
import DeleteIcon from '@icons/delete';
import { LoadingButton } from '@mui/lab';

import { AddClientsBtnText } from '@pages/ClientsV2/styles';
import CreateModelDialog from '@pages/ClientsV2/CreateModelDialog';

import CustomModelsTable from '../../Custom/components/CustomModelsTable';
import InputFilter from '@components/InputFilter';
import EmptyList from '../../../../components/EmptyList';

import { useGetCustomModelsQuery } from '../../Custom/hooks/useGetCustomModelsQuery';
import { useDeleteCustomModelsMutation } from '../../Custom/hooks/useDeleteCustomModelsMutation';

export interface CustomModel {
  id: number;
  name: string;
  updated: string;
  securities: number;
}

interface CustomModelListProps {
  handleClickEditModel: (id: string) => void;
}

const CustomModelList: React.FC<CustomModelListProps> = ({
  handleClickEditModel,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [openModelDialog, setOpenModelDialog] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);

  const { deleteCustomModelMutate } =
    useDeleteCustomModelsMutation(setCurrentItemId);

  const {
    customModels,
    isCustomModelsError,
    isCustomModelsLoading,
    refetch,
    filterByName,
  } = useGetCustomModelsQuery();

  // event helpers

  const handleSearchChange = (value: string) => {
    setSearchText(value);
  };

  const handleCloseModel = () => setOpenModelDialog(false);

  const handleClickOpenModel = () => setOpenModelDialog(true);

  const handleSuccesRefetch = () => refetch();

  // render helpers

  const ActionButtons = data => {
    return (
      <Grid
        container
        wrap="wrap"
        rowGap={2}
        direction="column"
        color={'#000000'}
        padding={{ xs: '10px', sm: '20px' }}
        sx={{ borderRadius: '4px' }}
      >
        <Grid container item xs={12} justifyContent={'center'}>
          <Grid item width="20px">
            <IconButton
              style={{
                textTransform: 'none',
                background: 'transparent',
                border: 'none',
                padding: 0,
                pointerEvents: 'auto',
              }}
              onClick={ev => {
                ev.stopPropagation();
                handleClickEditModel(data.params.id);
              }}
            >
              <EditPencilIcon width="16px" height="16px" />
            </IconButton>
          </Grid>
          <Grid item width="20px">
            <LoadingButton
              style={{
                textTransform: 'none',
                background: 'transparent',
                border: 'none',
                padding: 0,
                color: '#000000',
                pointerEvents: 'auto',
              }}
              id={data.params.id}
              loading={data.params.id === currentItemId}
              loadingPosition="start"
              startIcon={<DeleteIcon width="18px" height="18px" />}
              variant="outlined"
              onClick={ev => {
                ev.stopPropagation();
                deleteCustomModelMutate({
                  id: data.params.id,
                  setCurrentItemId,
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderEmptyList = () => {
    if (customModels?.length === 0) {
      return (
        <EmptyList
          loading={isCustomModelsLoading}
          title="It seems that there are no custom models yet"
          buttons={[
            {
              content: (
                <>
                  <AddIcon fontSize="small" /> Add Custom Model
                </>
              ),
              handleClick: handleClickOpenModel,
            },
          ]}
        />
      );
    } else if (filterByName(searchText).length === 0) {
      return (
        <EmptyList
          loading={isCustomModelsLoading}
          title="There are no results that match these filters"
          buttons={[
            {
              content: 'Clear all filters',
              handleClick: () => setSearchText(''),
            },
          ]}
        />
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Model name',
      display: 'flex',
      flex: 3,
      editable: false,
    },
    {
      field: 'updatedAt',
      type: 'date',
      headerName: 'Updated',
      display: 'flex',
      flex: 2,
      editable: false,
      renderCell: params => (
        <div>{moment(params.value).format('MM/DD/YYYY')}</div>
      ),
    },
    {
      field: 'securities',
      headerName: 'Securities',
      display: 'flex',
      flex: 2,
      editable: false,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      hideSortIcons: true,
      display: 'flex',
      flex: 0,
      renderCell: params => <ActionButtons params={params} />,
    },
  ];

  const modelDialog = openModelDialog && (
    <Dialog
      open={openModelDialog}
      onClose={handleCloseModel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: {
            xs: '500px',
            md: '800px',
          },
        },
      }}
    >
      <CreateModelDialog
        handleCloseModel={handleCloseModel}
        handleSuccesRefetch={handleSuccesRefetch}
      />
    </Dialog>
  );

  if (isCustomModelsError) {
    return <div>Error fetching data</div>;
  }

  return (
    <Grid
      container
      wrap="wrap"
      rowGap={2}
      direction="column"
      padding={{ xs: '10px', sm: '20px' }}
      sx={{ background: '#fff', borderRadius: '4px' }}
    >
      <Grid container justifyContent="flex-start" alignItems="center" gap={1}>
        <Grid item flexGrow={1}>
          <InputFilter
            placeholder="Search for Model name"
            value={searchText}
            handleInputChange={handleSearchChange}
          />
        </Grid>

        <Grid item>
          <Grid container sx={{ justifyContent: 'end' }}>
            <Button
              variant="outlined"
              style={{
                borderRadius: '25px',
                textTransform: 'none',
                height: 32,
                background: '#F1F1F1',
                border: 'none',
              }}
              color="primary"
              onClick={handleClickOpenModel}
            >
              <AddIcon fontSize="small" />
              &nbsp;<AddClientsBtnText>Add Custom Model</AddClientsBtnText>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        {renderEmptyList()}

        {/* We hide the table so that when it appears again it retains the previous state, that is, it retains the column order, pagination... */}
        <div
          hidden={
            isCustomModelsLoading || filterByName(searchText).length === 0
          }
        >
          <CustomModelsTable
            rows={filterByName(searchText)}
            columns={columns}
            handleRowClick={handleClickEditModel}
            initialState={{
              sorting: { sortModel: [{ field: 'updatedAt', sort: 'desc' }] },
            }}
          />
        </div>
      </Grid>
      {modelDialog}
    </Grid>
  );
};

export default CustomModelList;
