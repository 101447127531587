import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EmptyListComponent from 'components/EmptyList';
import FilterMenu from 'components/FilterMenu';
import SelectComponent from 'components/Select';
import { PortfolioStatisticsBarChart } from 'pages/Cases/components/PortfolioStatisticsBarChart';
import { PortfolioStatisticsLineChart } from 'pages/Cases/components/PortfolioStatisticsLineChart';
import SummaryPerformanceTable from 'pages/Cases/components/SummaryPerformanceTable';
import { SummaryStatisticsScatterChart } from 'pages/Cases/components/SummaryStatisticsScatterChart';
import React, { useEffect, useState } from 'react';
import ChartPopup from './components/ChartPopup';

const PerformanceReport = ({
  performance,
  isPerformanceLoading,
}: {
  performance: any;
  isPerformanceLoading: boolean;
}): JSX.Element => {
  const [filter, setFilter] = useState({
    activeNames: [] as string[],
    activeOptions: [1] as number[],
    activeRiskProfile: 3,
  });

  const [expandedPanels, setExpandedPanels] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
  });

  const [popupState, setPopupState] = useState({
    growthChart: false,
    annualizedReturns: false,
    calendarYearReturns: false,
    returnStandardDeviation: false,
    returnMaxDrawdown: false,
    maxDrawdown: false,
    rolling1YReturns: false,
    rolling1YExcessReturns: false,
    rolling3YReturns: false,
    rolling3YExcessReturns: false,
    rolling3YRisk: false,
    rolling3YSharpe: false,
  });

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanels({
        ...expandedPanels,
        [panel]: isExpanded,
      });
    };

  const handlePopupOpen = (chart: keyof typeof popupState) => {
    setPopupState(prev => ({ ...prev, [chart]: true }));
  };

  const handlePopupClose = (chart: keyof typeof popupState) => {
    setPopupState(prev => ({ ...prev, [chart]: false }));
  };

  // Calculate filtered performance only when we have data
  const filteredPerformance = performance
    ? filterPerformance(
        performance,
        filter.activeNames,
        filter.activeOptions,
        filter.activeRiskProfile,
      )
    : [];

  return (
    <Grid container>
      <Grid item container gap={2}>
        <ModelSetsFilter
          performance={performance}
          activeNames={filter.activeNames}
          setFilter={setFilter}
        />
        <OptionFilter
          activeOptions={filter.activeOptions}
          setFilter={setFilter}
        />
        <RiskProfileFilter
          activeRiskProfile={filter.activeRiskProfile}
          setFilter={setFilter}
        />
      </Grid>

      <EmptyList
        filteredPerformance={filteredPerformance}
        isPerformanceLoading={isPerformanceLoading}
      />
      {filteredPerformance.length > 0 && (
        <>
          <Grid container>
            <Grid
              container
              flexDirection="column"
              gap={2}
              sx={{
                width: '100%',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                mt: 2,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Growth of $100:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton onClick={() => handlePopupOpen('growthChart')}>
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'performance_chart',
                  })}
                  formatChart={{
                    unit: '',
                    decimals: 2,
                    prefix: '$',
                  }}
                />
              </Grid>
              <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
                <Accordion
                  sx={{
                    backgroundColor: '#fff',
                    boxShadow: 'none',
                    '&:before': {
                      display: 'none',
                    },
                  }}
                  defaultExpanded
                  onChange={handleAccordionChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      backgroundColor: '#f5f5f5',
                      justifyContent: 'flex-end',
                      '& .MuiAccordionSummary-content': {
                        justifyContent: 'flex-end',
                      },
                    }}
                  >
                    <ViewListOutlinedIcon sx={{ color: '#535256' }} />
                    <Typography color={'#535256'} component="span">
                      {expandedPanels.panel1
                        ? 'Hide metrics table'
                        : 'Show metrics table'}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <SummaryPerformanceTable
                      rows={getPortfolioStatisticsRows(
                        portfolioStatisticsChartFilter({
                          filteredPerformance,
                          name: 'performance_stats',
                        }),
                      )}
                      columns={columnsGrowth}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid
              container
              flexDirection="column"
              gap={2}
              sx={{
                width: '100%',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                mt: 2,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Annualized Returns:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton
                  onClick={() => handlePopupOpen('annualizedReturns')}
                >
                  <OpenInFullIcon />
                </IconButton>
              </Grid>

              <Grid
                item
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0',
                }}
              >
                <PortfolioStatisticsBarChart
                  data={portfolioStatisticsAnnualChartFormatedNames(
                    filteredPerformance,
                    columnsTrailingYearReturns,
                  )}
                  ytdFinal={false}
                />
              </Grid>
              <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
                <Accordion
                  sx={{
                    backgroundColor: '#fff',
                    boxShadow: 'none',
                    '&:before': {
                      display: 'none',
                    },
                  }}
                  defaultExpanded
                  onChange={handleAccordionChange('panel2')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    sx={{
                      backgroundColor: '#f5f5f5',
                      justifyContent: 'flex-end',
                      '& .MuiAccordionSummary-content': {
                        justifyContent: 'flex-end',
                      },
                    }}
                  >
                    <ViewListOutlinedIcon sx={{ color: '#535256' }} />
                    <Typography color={'#535256'} component="span">
                      {expandedPanels.panel2
                        ? 'Hide metrics table'
                        : 'Show metrics table'}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <SummaryPerformanceTable
                      rows={getPortfolioStatisticsRows(
                        portfolioStatisticsChartFilter({
                          filteredPerformance,
                          name: 'trailing_year_returns',
                        }),
                      )}
                      columns={columnsTrailingYearReturns}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid
              container
              flexDirection="column"
              gap={2}
              sx={{
                width: '100%',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                mt: 2,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Calendar Year Returns:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton
                  onClick={() => handlePopupOpen('calendarYearReturns')}
                >
                  <OpenInFullIcon />
                </IconButton>
              </Grid>

              <Grid
                item
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0',
                }}
              >
                <PortfolioStatisticsBarChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'calendar_yr_returns',
                  })}
                  ytdFinal={true}
                />
              </Grid>
              <Grid sx={{ width: '100%', backgroundColor: '#fff' }}>
                <Accordion
                  sx={{
                    backgroundColor: '#fff',
                    boxShadow: 'none',
                    '&:before': {
                      display: 'none',
                    },
                  }}
                  defaultExpanded
                  onChange={handleAccordionChange('panel3')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    sx={{
                      backgroundColor: '#f5f5f5',
                      justifyContent: 'flex-end',
                      '& .MuiAccordionSummary-content': {
                        justifyContent: 'flex-end',
                      },
                    }}
                  >
                    <ViewListOutlinedIcon sx={{ color: '#535256' }} />
                    <Typography color={'#535256'} component="span">
                      {expandedPanels.panel3
                        ? 'Hide metrics table'
                        : 'Show metrics table'}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <SummaryPerformanceTable
                      rows={getPortfolioStatisticsRows(
                        portfolioStatisticsChartFilter({
                          filteredPerformance,
                          name: 'calendar_yr_returns',
                        }),
                      )}
                      columns={columnsCalendarYearAsOf}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
            <Grid
              container
              flexDirection="column"
              gap={2}
              sx={{
                width: '100%',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                mt: 2,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Return and Risk Statistics:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent="space-between"
                direction="row"
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    backgroundColor: '#fff',
                    padding: '16px 16px 0 0',
                    position: 'relative',
                  }}
                >
                  <IconButton
                    onClick={() => handlePopupOpen('returnStandardDeviation')}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      zIndex: 1,
                    }}
                  >
                    <OpenInFullIcon />
                  </IconButton>
                  <SummaryStatisticsScatterChart
                    data={portfolioStatisticsChartFilter({
                      filteredPerformance,
                      name: 'performance_stats',
                    })}
                    xValue="standard_deviation"
                    yValue="cagr"
                    name="Return / Standard Deviation"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    backgroundColor: '#fff',
                    padding: '16px 16px 0 0',
                    position: 'relative',
                  }}
                >
                  <IconButton
                    onClick={() => handlePopupOpen('returnMaxDrawdown')}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      zIndex: 1,
                    }}
                  >
                    <OpenInFullIcon />
                  </IconButton>
                  <SummaryStatisticsScatterChart
                    data={portfolioStatisticsChartFilter({
                      filteredPerformance,
                      name: 'performance_stats',
                    })}
                    xValue="max_drawdown"
                    yValue="cagr"
                    name="Return / Maximum Drawdown"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Maximum Drawdown:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton onClick={() => handlePopupOpen('maxDrawdown')}>
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  position: 'relative',
                  padding: '16px 16px 0 0',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'drawdown_series',
                  })}
                  formatChart={{
                    unit: '%',
                    decimals: 2,
                    prefix: '',
                    value: 'Maximum Drawdown',
                    label: 'Maximum Drawdown',
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{
                width: '100%',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                mt: 2,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Rolling 1Y Returns:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton onClick={() => handlePopupOpen('rolling1YReturns')}>
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                display="flex"
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0px',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'rolling_one_year_stats',
                    subName: '1_year_rolling_returns',
                  })}
                  formatChart={{
                    unit: '%',
                    decimals: 2,
                    prefix: '',
                    value: 'Return',
                    label: 'Return',
                  }}
                />
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Rolling 1Y Excess Returns:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton
                  onClick={() => handlePopupOpen('rolling1YExcessReturns')}
                >
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                display="flex"
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0px',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'rolling_one_year_stats',
                    subName: '1_year_excess_returns',
                  })}
                  formatChart={{
                    unit: '%',
                    decimals: 2,
                    prefix: '',
                    value: 'Excess Return',
                    label: 'Excess Return',
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{
                width: '100%',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                mt: 2,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Rolling 3Y Returns:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton onClick={() => handlePopupOpen('rolling3YReturns')}>
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                display="flex"
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0px',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'rolling_three_year_stats',
                    subName: '3_year_rolling_returns',
                  })}
                  formatChart={{
                    unit: '%',
                    decimals: 2,
                    prefix: '',
                    value: 'Return',
                    label: 'Return',
                  }}
                />
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Rolling 3Y Excess Returns:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton
                  onClick={() => handlePopupOpen('rolling3YExcessReturns')}
                >
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                display="flex"
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0px',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'rolling_three_year_stats',
                    subName: '3_year_excess_returns',
                  })}
                  formatChart={{
                    unit: '%',
                    decimals: 2,
                    prefix: '',
                    value: 'Excess Return',
                    label: 'Excess Return',
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{
                width: '100%',
                backgroundColor: '#F5F5F5',
                padding: '16px',
                mt: 2,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Rolling 3Y Risk:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton onClick={() => handlePopupOpen('rolling3YRisk')}>
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                display="flex"
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0px',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'rolling_three_year_stats',
                    subName: '3_year_standard_deviation',
                  })}
                  formatChart={{
                    unit: '%',
                    decimals: 2,
                    prefix: '',
                    value: 'Standard Deviation',
                    label: 'Standard Deviation',
                  }}
                />
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid>
                  <Typography>
                    Rolling 3Y Sharpe Ratio:{' '}
                    {`${performance[0].performance_metadata.start_date} - ${performance[0].performance_metadata.end_date}`}
                  </Typography>
                </Grid>
                <IconButton onClick={() => handlePopupOpen('rolling3YSharpe')}>
                  <OpenInFullIcon />
                </IconButton>
              </Grid>
              <Grid
                display="flex"
                justifyContent="flex-start"
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '16px 16px 0 0px',
                }}
              >
                <PortfolioStatisticsLineChart
                  data={portfolioStatisticsChartFilter({
                    filteredPerformance,
                    name: 'rolling_three_year_stats',
                    subName: '3_year_sharpe',
                  })}
                  formatChart={{
                    unit: '',
                    decimals: 2,
                    prefix: '',
                    value: 'Sharpe Ratio',
                    label: 'Sharpe Ratio',
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <ChartPopup
            open={popupState.growthChart}
            onClose={() => handlePopupClose('growthChart')}
            title="Growth of $100"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'performance_chart',
              })}
              formatChart={{
                unit: '',
                decimals: 2,
                prefix: '$',
              }}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.annualizedReturns}
            onClose={() => handlePopupClose('annualizedReturns')}
            title="Annualized Returns"
          >
            <PortfolioStatisticsBarChart
              data={portfolioStatisticsAnnualChartFormatedNames(
                filteredPerformance,
                columnsTrailingYearReturns,
              )}
              ytdFinal={false}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.calendarYearReturns}
            onClose={() => handlePopupClose('calendarYearReturns')}
            title="Calendar Year Returns"
          >
            <PortfolioStatisticsBarChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'calendar_yr_returns',
              })}
              ytdFinal={true}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.returnStandardDeviation}
            onClose={() => handlePopupClose('returnStandardDeviation')}
            title="Return / Standard Deviation"
          >
            <SummaryStatisticsScatterChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'performance_stats',
              })}
              xValue="standard_deviation"
              yValue="cagr"
              name="Return / Standard Deviation"
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.returnMaxDrawdown}
            onClose={() => handlePopupClose('returnMaxDrawdown')}
            title="Return / Maximum Drawdown"
          >
            <SummaryStatisticsScatterChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'performance_stats',
              })}
              xValue="max_drawdown"
              yValue="cagr"
              name="Return / Maximum Drawdown"
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.maxDrawdown}
            onClose={() => handlePopupClose('maxDrawdown')}
            title="Maximum Drawdown"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'drawdown_series',
              })}
              formatChart={{
                unit: '%',
                decimals: 2,
                prefix: '',
                value: 'Maximum Drawdown',
                label: 'Maximum Drawdown',
              }}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.rolling1YReturns}
            onClose={() => handlePopupClose('rolling1YReturns')}
            title="Rolling 1Y Returns"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'rolling_one_year_stats',
                subName: '1_year_rolling_returns',
              })}
              formatChart={{
                unit: '%',
                decimals: 2,
                prefix: '',
                value: 'Return',
                label: 'Return',
              }}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.rolling1YExcessReturns}
            onClose={() => handlePopupClose('rolling1YExcessReturns')}
            title="Rolling 1Y Excess Returns"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'rolling_one_year_stats',
                subName: '1_year_excess_returns',
              })}
              formatChart={{
                unit: '%',
                decimals: 2,
                prefix: '',
                value: 'Excess Return',
                label: 'Excess Return',
              }}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.rolling3YReturns}
            onClose={() => handlePopupClose('rolling3YReturns')}
            title="Rolling 3Y Returns"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'rolling_three_year_stats',
                subName: '3_year_rolling_returns',
              })}
              formatChart={{
                unit: '%',
                decimals: 2,
                prefix: '',
                value: 'Return',
                label: 'Return',
              }}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.rolling3YExcessReturns}
            onClose={() => handlePopupClose('rolling3YExcessReturns')}
            title="Rolling 3Y Excess Returns"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'rolling_three_year_stats',
                subName: '3_year_excess_returns',
              })}
              formatChart={{
                unit: '%',
                decimals: 2,
                prefix: '',
                value: 'Excess Return',
                label: 'Excess Return',
              }}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.rolling3YRisk}
            onClose={() => handlePopupClose('rolling3YRisk')}
            title="Rolling 3Y Risk"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'rolling_three_year_stats',
                subName: '3_year_standard_deviation',
              })}
              formatChart={{
                unit: '%',
                decimals: 2,
                prefix: '',
                value: 'Standard Deviation',
                label: 'Standard Deviation',
              }}
            />
          </ChartPopup>

          <ChartPopup
            open={popupState.rolling3YSharpe}
            onClose={() => handlePopupClose('rolling3YSharpe')}
            title="Rolling 3Y Sharpe Ratio"
          >
            <PortfolioStatisticsLineChart
              data={portfolioStatisticsChartFilter({
                filteredPerformance,
                name: 'rolling_three_year_stats',
                subName: '3_year_sharpe',
              })}
              formatChart={{
                unit: '',
                decimals: 2,
                prefix: '',
                value: 'Sharpe Ratio',
                label: 'Sharpe Ratio',
              }}
            />
          </ChartPopup>
        </>
      )}
    </Grid>
  );
};

export default PerformanceReport;

const formatModelSetName = (name: string): string => {
  if (!name) return '';

  let formattedName = name;

  // Common pattern replacements
  const replacements = {
    minimum: 'Min',
    Qualified: 'Qual',
    'Non-Qual': 'NQ',
    'Growth/Total Return': 'Growth',
    Preservation: 'Pres',
    Income: 'Income',
    // Add more replacements as needed
  };

  // Apply all replacements
  Object.entries(replacements).forEach(([pattern, replacement]) => {
    formattedName = formattedName.replace(
      new RegExp(pattern, 'i'),
      replacement,
    );
  });

  return formattedName.trim();
};

export const filterPerformance = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  performance: any,
  activeNames: string[],
  activeOptions: number[],
  activeRiskProfile: number,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const effectiveOptions = activeOptions.length === 0 ? [1] : activeOptions;

  // Filter by risk profile
  let filteredPerformance = performance.map(modelSet => ({
    ...modelSet,
    portfolio_options: modelSet.portfolio_options.map(portfolioOption => ({
      ...portfolioOption,
      portfolios: portfolioOption.portfolios.filter(
        portfolio => portfolio.profile === activeRiskProfile,
      ),
    })),
  }));

  // Filter by option - show option 1 if none selected
  filteredPerformance = filteredPerformance.map(modelSet => ({
    ...modelSet,
    portfolio_options: modelSet.portfolio_options.filter(
      portfolioOption =>
        effectiveOptions.includes(portfolioOption.option) &&
        portfolioOption.portfolios.length > 0,
    ),
  }));

  // Modify name to include specific option
  filteredPerformance = filteredPerformance.flatMap(modelSet =>
    modelSet.portfolio_options.map(option => ({
      ...modelSet,
      model_set_name: `${modelSet.model_set_name} - Opt${option.option}`,
      formatted_name: `${modelSet.abbreviated_model_set_name || formatModelSetName(modelSet.model_set_name)} - Opt${option.option}`,
      display_portfolio_name: option.portfolios[0].display_portfolio_name,
      display_benchmark_name: option.portfolios[0].display_benchmark_name,
      portfolio_options: [option],
    })),
  );

  // Filter by model name and limit to 7 items
  filteredPerformance = filteredPerformance.filter(modelSet => {
    if (modelSet.portfolio_options.length === 0) {
      return false;
    }

    // If no active names, show all
    if (activeNames.length === 0) {
      return true;
    }

    // Check both full name and name without option suffix
    return (
      activeNames.includes(modelSet.model_set_name) ||
      activeNames.includes(modelSet.model_set_name.split(' - Opt')[0])
    );
  });

  // Limit to first 7 items if more than 7 are selected
  if (filteredPerformance.length > 7) {
    filteredPerformance = filteredPerformance.slice(0, 7);
  }

  return filteredPerformance || [];
};

export const ModelSetsFilter = ({
  performance,
  activeNames,
  setFilter,
}: {
  performance: any;
  activeNames: string[];
  setFilter: React.Dispatch<
    React.SetStateAction<{
      activeNames: string[];
      activeOptions: number[];
      activeRiskProfile: number;
    }>
  >;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
}) => {
  const rows =
    performance?.map(modelSet => ({
      id: modelSet.model_set_name,
      name: modelSet.model_set_name,
      checked: activeNames.includes(modelSet.model_set_name),
    })) || [];

  const activeRows = rows.filter(row => activeNames.includes(row.id));

  const handleChange = (id: string) => {
    setFilter(prev => {
      const newActiveNames = prev.activeNames.includes(id)
        ? prev.activeNames.filter(modelSetId => modelSetId !== id)
        : [...prev.activeNames, id];

      // Limit to first 7 selections
      return {
        ...prev,
        activeNames: newActiveNames.slice(0, 7),
      };
    });
  };

  const placeholder = activeRows.map(row => row.name).join(', ') || 'View All';

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography sx={{ fontSize: 14, fontWeight: 'normal', color: '#4F4F4F' }}>
        Model Sets (max 7)
      </Typography>
      <Box width="200px">
        <FilterMenu
          variant="select"
          optionTitle="Model Sets"
          placeholder={placeholder}
          rows={rows}
          activeRows={activeRows}
          handleFilter={handleChange}
        />
      </Box>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const OptionFilter = ({ activeOptions, setFilter }: any) => {
  const options = [1, 2];

  useEffect(() => {
    if (activeOptions.length === 0) {
      setFilter((prev: any) => ({
        ...prev,
        activeOptions: [1],
      }));
    }
  }, []);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography sx={{ fontSize: 14, fontWeight: 'normal', color: '#4F4F4F' }}>
        Model Sets Option
      </Typography>
      <Box width="200px">
        <FilterMenu
          variant="select"
          optionTitle="Options"
          placeholder={
            activeOptions.length > 0
              ? activeOptions.map(opt => `Option ${opt}`).join(', ')
              : 'View All'
          }
          rows={options.map(option => ({
            id: option.toString(),
            name: `Option ${option}`,
            checked: activeOptions.includes(option),
          }))}
          activeRows={options
            .filter(option => activeOptions.includes(option))
            .map(option => ({
              id: option.toString(),
              name: `Option ${option}`,
              checked: activeOptions.includes(option),
            }))}
          handleFilter={id => {
            const optionNumber = Number(id);
            setFilter((prev: any) => ({
              ...prev,
              activeOptions: prev.activeOptions.includes(optionNumber)
                ? prev.activeOptions.filter(opt => opt !== optionNumber)
                : [...prev.activeOptions, optionNumber],
            }));
          }}
        />
      </Box>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const RiskProfileFilter = ({ activeRiskProfile, setFilter }: any) => {
  const options = [1, 2, 3, 4, 5, 6];

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography sx={{ fontSize: 14, fontWeight: 'normal', color: '#4F4F4F' }}>
        Risk Profile
      </Typography>
      <Box width="80px">
        <SelectComponent
          id="model-sets-risk-profile"
          name="model-sets-risk-profile"
          value={activeRiskProfile.toString()}
          options={options.map(option => ({
            key: option.toString(),
            label: option.toString(),
          }))}
          handleChange={(key, value) =>
            setFilter((prev: any) => ({
              ...prev,
              activeRiskProfile: Number(value),
            }))
          }
        />
      </Box>
    </Box>
  );
};

const EmptyList = ({ filteredPerformance, isPerformanceLoading }: any) => {
  return filteredPerformance.length === 0 ? (
    <Grid item xs={12} mt={4}>
      <EmptyListComponent
        title="There is no data matching these filters. Please change the filters to view data."
        loading={isPerformanceLoading}
      />
    </Grid>
  ) : null;
};

export const portfolioStatisticsAnnualChartFormatedNames = (
  filteredPerformance: any[],
  columns: GridColDef[],
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const data = portfolioStatisticsChartFilter({
    filteredPerformance,
    name: 'trailing_year_returns',
  });

  const columnFields = columns
    .map(col => col.field)
    .filter(field => field !== 'name');

  return data.map(item => {
    const filteredData = {
      modelSetName: item.modelSetName,
      displayPortfolioName: item.displayPortfolioName,
      displayBenchmarkName: item.displayBenchmarkName,
      data: Object.keys(item.data)
        .filter(key => columnFields.includes(key))
        .reduce(
          (obj, key) => {
            obj[key] = item.data[key];
            return obj;
          },
          {} as Record<string, any>,
        ),
    };
    return filteredData;
  });
};

export const portfolioStatisticsChartFilter = ({
  filteredPerformance,
  name,
  subName,
}: {
  filteredPerformance: any[];
  name: string;
  subName?: string;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
}) => {
  if (!filteredPerformance || !Array.isArray(filteredPerformance)) {
    return [];
  }

  const processStats = (stats: any) => {
    return Object.entries(stats).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          typeof value === 'number'
            ? name === 'performance_chart' ||
              key === 'sharpe_ratio' ||
              key === 'beta' ||
              (name === 'rolling_three_year_stats' &&
                subName === '3_year_sharpe')
              ? Number(value.toFixed(2))
              : Number((value * 100).toFixed(2))
            : value,
      }),
      {},
    );
  };

  const portfolioStats = filteredPerformance
    .map(modelSet => {
      let stats;
      if (subName) {
        stats =
          modelSet.portfolio_options[0]?.portfolios[0]?.portfolio_statistics
            ?.portfolio_statistics[name]?.[subName];
      } else {
        stats =
          modelSet.portfolio_options[0]?.portfolios[0]?.portfolio_statistics
            ?.portfolio_statistics[name];
      }

      return {
        modelSetName: modelSet.formatted_name,
        displayPortfolioName: modelSet.display_portfolio_name,
        displayBenchmarkName: '',
        data: stats ? processStats(stats) : {},
      };
    })
    .filter(item => Object.keys(item.data).length > 0);

  const benchmarkStats =
    filteredPerformance[0]?.portfolio_options[0]?.portfolios[0]
      ?.benchmark_statistics?.portfolio_statistics[name];

  if (benchmarkStats) {
    if (subName && benchmarkStats[subName]) {
      portfolioStats.push({
        modelSetName:
          filteredPerformance[0]?.portfolio_options[0]?.portfolios[0]
            .benchmark_name,
        data: processStats(benchmarkStats[subName]),
        displayPortfolioName: '',
        displayBenchmarkName:
          filteredPerformance[0]?.portfolio_options[0]?.portfolios[0]
            .display_benchmark_name,
      });
    } else if (!subName) {
      portfolioStats.push({
        modelSetName:
          filteredPerformance[0]?.portfolio_options[0]?.portfolios[0]
            .benchmark_name,
        displayPortfolioName: '',
        displayBenchmarkName:
          filteredPerformance[0]?.portfolio_options[0]?.portfolios[0]
            .display_benchmark_name,
        data: processStats(benchmarkStats),
      });
    }
  }

  return portfolioStats;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPortfolioStatisticsRows = (data: any) => {
  return data.map((portfolio: any, index: number) => {
    const stats = portfolio.data;
    const formattedStats = Object.entries(stats || {}).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: typeof value === 'number' ? Number(value).toFixed(2) : value,
      }),
      {},
    );

    return {
      id: index,
      name: portfolio.modelSetName,
      ...formattedStats,
    };
  });
};

export const columnsGrowth: GridColDef[] = [
  {
    field: 'name',
    headerName: '',
    flex: 1,
    minWidth: 310,
    cellClassName: 'MuiDataGrid-cell--multiline',
    renderCell: params => (
      <Box
        sx={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: 'cagr',
    headerName: 'Return',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
        title="Return"
      >
        Return
      </Typography>
    ),
    valueFormatter: params => params,
  },
  {
    field: 'standard_deviation',
    headerName: 'Standard Deviation',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
          whiteSpace: 'normal',
          textAlign: 'center',
        }}
        title="Standard Deviation"
      >
        Standard Deviation
      </Typography>
    ),
    valueFormatter: params => params,
  },
  {
    field: 'max_drawdown',
    headerName: 'Maximum Drawdown',
    type: 'number',
    flex: 1,
    minWidth: 83,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
          whiteSpace: 'normal',
          textAlign: 'center',
        }}
        title="Maximum Drawdown"
      >
        Maximum Drawdown
      </Typography>
    ),
    valueFormatter: params => params,
  },
  {
    field: 'sharpe_ratio',
    headerName: 'Sharpe Ratio',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
          whiteSpace: 'normal',
          textAlign: 'center',
        }}
        title="Sharpe Ratio"
      >
        Sharpe Ratio
      </Typography>
    ),
  },
  {
    field: 'excess_return',
    headerName: 'Excess Return',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',

    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
          whiteSpace: 'normal',
          textAlign: 'center',
        }}
        title="Excess Return"
      >
        Excess Return
      </Typography>
    ),
    valueFormatter: params => params,
  },
  {
    field: 'alpha',
    headerName: 'Alpha',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
          whiteSpace: 'normal',
          textAlign: 'center',
        }}
        title="Alpha"
      >
        Alpha
      </Typography>
    ),
    valueFormatter: params => params,
  },
  {
    field: 'beta',
    headerName: 'Beta',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
          whiteSpace: 'normal',
          textAlign: 'center',
        }}
        title="Beta"
      >
        Beta
      </Typography>
    ),
  },
  {
    field: 'tracking_error',
    headerName: 'Tracking Error',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
          whiteSpace: 'normal',
          textAlign: 'center',
        }}
        title="Tracking Error"
      >
        Tracking Error
      </Typography>
    ),
    valueFormatter: params => params,
  },
];

export const columnsTrailingYearReturns: GridColDef[] = [
  {
    field: 'name',
    headerName: '',
    flex: 1,
    minWidth: 350,
    cellClassName: 'MuiDataGrid-cell--multiline',
    renderCell: params => (
      <Box
        sx={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: 'ytd',
    headerName: 'YTD',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        YTD
      </Typography>
    ),
    valueFormatter: params => params,
  },
  {
    field: '1_year',
    headerName: '1 Year',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',

    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        1 Year
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '3_year',
    headerName: '3 Year',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        3 Year
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '5_year',
    headerName: '5 Year',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        5 Year
      </Typography>
    ),
  },
  {
    field: '7_year',
    headerName: '7 Year',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',

    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        7 Year
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '10_year',
    headerName: '10 Year',
    type: 'number',
    flex: 1,
    align: 'center',
    headerAlign: 'center',

    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        10 Year
      </Typography>
    ),

    valueFormatter: params => params,
  },
];

export const columnsCalendarYearAsOf: GridColDef[] = [
  {
    field: 'name',
    headerName: '',
    flex: 1,
    minWidth: 310,
    cellClassName: 'MuiDataGrid-cell--multiline',
    renderCell: params => (
      <Box
        sx={{
          whiteSpace: 'normal',
          lineHeight: 'normal',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {params.value}
      </Box>
    ),
  },
  {
    field: 'ytd',
    headerName: 'YTD',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
        title="YTD"
      >
        YTD
      </Typography>
    ),
    valueFormatter: params => params,
  },
  {
    field: '2015',
    headerName: '2015',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',

    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2015
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2016',
    headerName: '2016',
    type: 'number',
    flex: 1,
    align: 'center',
    minWidth: 75,
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2016
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2017',
    headerName: '2017',
    type: 'number',
    flex: 1,
    align: 'center',
    minWidth: 75,
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2017
      </Typography>
    ),
  },
  {
    field: '2018',
    headerName: '2018',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',

    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2018
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2019',
    headerName: '2019',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',

    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2019
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2020',
    headerName: '2020',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2020
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2021',
    headerName: '2021',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2021
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2022',
    headerName: '2022',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2022
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2023',
    headerName: '2023',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2023
      </Typography>
    ),

    valueFormatter: params => params,
  },
  {
    field: '2024',
    headerName: '2024',
    type: 'number',
    flex: 1,
    minWidth: 75,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => (
      <Typography
        sx={{
          color: '#666666',
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: '400',
        }}
      >
        2024
      </Typography>
    ),

    valueFormatter: params => params,
  },
];
