import { PrimaryButton } from '@components/Button';
import MemoDeleteIcon from '@icons/delete';
import { CircularProgress, Grid } from '@mui/material';
import React, { useState } from 'react';
import Input from '@components/Input';
import { updateQuestions, uploadQuestions } from './mutate';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { EditWrapper } from './styles';
import { handleError } from '@common/error';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
interface QuestionsModalProps {
  description: string;
  questions: string[];
  handleClose: any;
  id: string;
  setShowSuccessModal?: React.Dispatch<boolean>;
}

function QuestionsModal({
  description: initialDescription,
  questions: initialQuestions,
  handleClose,
  id,
  setShowSuccessModal = () => {},
}: QuestionsModalProps): React.JSX.Element {
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useMutation(updateQuestions);
  const { mutate: addMutate, isLoading: isAddLoading } =
    useMutation(uploadQuestions);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [description, setDescription] = useState<string>(initialDescription);
  const [questions, setQuestions] = useState<string[]>(initialQuestions);
  const [formSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const handleDelete = (index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };
  const handleAdd = () => {
    const updatedQuestions = [...questions];
    updatedQuestions.push('');
    setQuestions(updatedQuestions);
  };
  const questionsValid = questions.every(question => question.trim());
  const handleSubmit = e => {
    e.preventDefault();
    setIsFormSubmitted(true);

    if (!description.trim() || !questionsValid) {
      // Display an error message for empty fields
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Please fill all the mandatory fields.',
        }),
      );
      return; // Prevent the form submission if validation fails
    } else {
      const payload = {
        category: description,
        questions: questions,
      };

      if (!id) {
        addMutate(payload, {
          onSuccess: res => {
            handleSuccess(res);
          },
          onError: (error: any) => {
            handleError(dispatch, error);
          },
        });
      } else {
        updateMutate(
          { id, ...payload },
          {
            onSuccess: res => {
              handleSuccess(res);
            },
            onError: (error: any) => {
              handleError(dispatch, error);
            },
          },
        );
      }
    }
  };

  const handleSuccess = res => {
    handleClose();
    setShowSuccessModal(true);
    queryClient.invalidateQueries('extract-questions');
    dispatch(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'success',
        toastMessage: res?.message,
      }),
    );
  };

  return (
    <EditWrapper container rowGap={2}>
      <Grid item container xs={12} direction={'column'} rowGap={2}>
        <Grid item>
          <p className="label">Enter Category</p>
        </Grid>
        <Grid item>
          <Input
            className={
              !description.trim() && formSubmitted
                ? 'custom-input-error'
                : 'custom-input'
            }
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {!description.trim() && formSubmitted && (
            <span className="mandatory-text">This field is mandatory</span>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12} rowGap={2} py={2}>
        <Grid item xs={12}>
          <p className="title">Enter Questions</p>
        </Grid>
        {questions?.map((item, index) => (
          <Grid
            key={index}
            item
            container
            columnSpacing={1}
            alignItems={'start'}
          >
            <Grid item xs={10}>
              <Input
                className={
                  !item.trim() && formSubmitted
                    ? 'custom-input-error'
                    : 'custom-input'
                }
                value={item}
                onChange={e => {
                  const updatedQuestions = [...questions];
                  updatedQuestions[index] = e.target.value;
                  setQuestions(updatedQuestions);
                }}
              />
              {!item.trim() && formSubmitted && (
                <span className="mandatory-text">This field is mandatory</span>
              )}
            </Grid>
            {questions.length > 1 && (
              <Grid
                item
                xs={1}
                onClick={() => handleDelete(index)}
                className="icon"
              >
                <MemoDeleteIcon />
              </Grid>
            )}
            {index === questions.length - 1 && (
              <Grid item xs={1} onClick={() => handleAdd()} className="icon">
                <AddCircleOutlineIcon />
              </Grid>
            )}
          </Grid>
        ))}
        <Grid item container>
          <div className="question-modal-help">
            <p>Help:</p>
            <ul className="question-modal-help-list">
              <li>
                Specify the question that will be displayed to the user at
                startup. <br />
                Eg:{' '}
                <span className="question-modal-help-example">
                  Send a risk assessment to &lt;CLIENT&gt;
                </span>
              </li>
              <li>
                If you want to add a custom prompt, type |Prompt=Prompt
                Question. <br />
                Eg:{' '}
                <span className="question-modal-help-example">
                  Send a risk assessment to &lt;CLIENT&gt;|Prompt=Send to
                  &lt;CLIENT&gt; a risk assessment
                </span>
              </li>
              <li>
                If you want to navigate when the user clicks, type
                |Navigate=/destination-path <br />
                Eg:{' '}
                <span className="question-modal-help-example">
                  Send a risk assessment to &lt;CLIENT&gt;|Navigate=/actions
                </span>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <Grid item container columnSpacing={2}>
        <Grid item xs={6}>
          <PrimaryButton
            variant="outlined"
            className="button"
            onClick={handleClose}
          >
            Cancel
          </PrimaryButton>
        </Grid>
        <Grid item xs={6}>
          <PrimaryButton
            className="button"
            onClick={handleSubmit}
            disabled={isUpdateLoading || isAddLoading}
          >
            {isUpdateLoading || isAddLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Submit'
            )}
          </PrimaryButton>
        </Grid>
      </Grid>
    </EditWrapper>
  );
}

export default QuestionsModal;
