import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UseDeleteCaseArguments = {
  id: string;
  successToastMessage: string;
};

type UseDeleteCaseResult = {
  deleteCaseMutate: (data: UseDeleteCaseArguments) => void;
  isLoading: boolean;
  isSuccess: boolean;
};

export const useDeleteCase = (): UseDeleteCaseResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate, isLoading, isSuccess } = useMutation(
    (data: UseDeleteCaseArguments) =>
      fetchMutation({
        path: `/v2/cases/${data.id}`,
        method: 'DELETE',
        invalidateQueries: [['cases']],
        successToastMessage: data.successToastMessage,
      }),
  );

  return { deleteCaseMutate: mutate, isLoading, isSuccess };
};
