import { Grid, Typography } from '@mui/material';
import { SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import foto1 from './foto1.png';
import foto2 from './foto2.png';
import foto3 from './foto3.png';

export function AMKProfiles(): React.ReactElement {
  return (
    <Grid container direction="column" spacing={2} className={styles.container}>
      <Grid item>
        <SlideTitle>About AssetMark Investment Consulting</SlideTitle>
        <Typography component="p" className={styles.paragraph}>
          AssetMark’s Investment Consulting team works with advisors as an
          expansion of their team, providing guidance around investment-related
          topics, including the creation of investment models to improve the
          efficiency of an advisor&apos;s practice.
        </Typography>
      </Grid>
      <Grid item sx={{ paddingTop: '0px !important' }}>
        <Typography component="h2" className={styles.subtitle}>
          Team members are:
        </Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid item sx={{ width: '120px', height: '160px' }}>
              <img src={foto1} alt="foto1" style={{ width: '100%' }} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography component="p" className={styles.paragraph}>
              <b>Zoë Brunson, CFA</b>
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Chief Investment Strategist, SVP, Platform Investment Strategies
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Zoë oversees the Platform Investment Strategies Group. She is
              responsible for overseeing all due diligence on investment
              strategists on the AssetMark platform and AssetMark’s investment
              consulting services.
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Zoë has over 25 years’ experience and joined AssetMark in 2007.
              Previously Zoë was at Standard & Poor’s where she led the
              investment advisory services team responsible for manager due
              diligence and creation of multi-manager strategies.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid item sx={{ width: '120px', height: '160px' }}>
              <img src={foto2} alt="foto2" style={{ width: '100%' }} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography component="p" className={styles.paragraph}>
              <b>Rob Carlen</b>
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              VP, Investment Consulting
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Rob is responsible for leading our investment consulting efforts
              providing guidance to advisors through building portfolios on the
              AssetMark platform, including selecting the right investment
              managers and providing objective risk-based analysis, research,
              and insights
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Rob has over 20 years’ experience and joined AssetMark in 2025.
              Previously, Rob spent 17 years at Northern Trust Asset Management
              where he worked in their OCIO practice and most recently led the
              NTAM Investment Institute, where he was responsible for providing
              advisors with thought leadership and investment consulting
              services.
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid item sx={{ width: '120px', height: '160px' }}>
              <img src={foto3} alt="foto3" style={{ width: '100%' }} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography component="p" className={styles.paragraph}>
              <b>Keith Parker, CFA</b>
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Investment Consultant
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Keith Parker provides investment consulting to guide advisors
              through building portfolios on the AssetMark platform, including
              selecting the right investment managers and providing objective
              risk-based analysis, research, and insights.
            </Typography>
            <Typography component="p" className={styles.paragraph}>
              Keith has over 25 years’ experience and joined AssetMark in 2022.
              Prior to joining AssetMark, Keith spent 8 years as a Senior
              Investment Analyst at the Wells Fargo Investment Institute, 5
              years as a Senior Fund Analyst/Product Specialist for HighMark
              Capital Management, and 3 years as a Senior Analyst for the Schwab
              Center for Financial Research.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
