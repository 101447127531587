import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type UseGetClientDetailsArguments = {
  clientId: string;
};

type UseGetClientDetailsResult = {
  client:
    | {
        email: string;
        firstName: string;
        lastName: string;
      }
    | undefined;
  isClientLoading: boolean;
};

type UseGetClientDetailsResponse = {
  email: string;
  firstName: string;
  lastName: string;
};

export const useGetClientDetails = ({
  clientId,
}: UseGetClientDetailsArguments): UseGetClientDetailsResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<UseGetClientDetailsResponse>(
    ['clientDetails', clientId],
    fetchQuery({ path: `/client/details?id=${clientId}` }),
    {
      select: (data: any) => ({
        email: data.clientRecord.email,
        firstName: data.clientRecord.firstName,
        lastName: data.clientRecord.lastName,
      }),
    },
  );

  return {
    client: data,
    isClientLoading: isLoading,
  };
};
