import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { oktaAuth } from '@services/oktaAuth';

import {
  getHeaderMenuList,
  getProfileMenuList,
} from '@features/Global/selector';
import { getAuthDetails } from '@features/Profile/selector';

import DrawerComp from './components/Drawer';
import MenuProfile from './components/MenuProfile';

import { actions as chatActions } from 'app/features/Chat/slice';

import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import Logo from '@images/LogoSvg.svg';

const NavBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileState = useSelector(getAuthDetails);
  const headerMenuList = useSelector(getHeaderMenuList);
  const profileMenuList = useSelector(getProfileMenuList);
  const userType = localStorage.getItem('tifin-ai-user-type');
  const [reRoute, setReRoute] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    dispatch(chatActions.clearEventStream());
    localStorage.clear();
    try {
      await oktaAuth.signOut();
    } catch (error) {
      console.error('Sign out error:', error);
    }
  };

  const currentTabPath = useMemo(() => {
    return headerMenuList.find(tab => {
      const currentPath = location.pathname || '';
      return currentPath === tab.path
        ? true
        : Array.isArray(tab.path) &&
            tab.path.some(pattern => matchPath(pattern, location.pathname));
    })?.path;
  }, [location.pathname, headerMenuList]);

  const tabsItems = useMemo(() => {
    return headerMenuList.map(tab => ({ ...tab, value: tab.path }));
  }, [headerMenuList]);

  const selectedTabsCount = useMemo(() => {
    return tabsItems.filter(item => item.showToUser.includes(userType)).length;
  }, [tabsItems, userType]);

  const breakpoint = useMemo(() => {
    return tabsItems.reduce((acc, item) => {
      return item.showToUser.includes(userType)
        ? selectedTabsCount > 4
          ? acc + 170
          : acc + 240
        : acc;
    }, 0);
  }, [tabsItems, userType]);

  const showMobileMenu = useMediaQuery(
    `@media (max-width:${breakpoint + 80}px)`,
  );

  useEffect(() => {
    if (reRoute) {
      setReRoute(false);
      if (
        profileState?.role?.length > 0 &&
        (profileState.termsAndConditionsAcceptedAt === '' ||
          profileState.termsAndConditionsAcceptedAt === null)
      ) {
        navigate('/terms-condition');
      } else {
        if (profileState.role === 'ADVISOR') {
          navigate('/conversations');
        } else if (profileState.role === 'FIRM_ADMIN') {
          navigate('/manage-firms');
        } else if (profileState.role === 'SUPER_ADMIN') {
          navigate('/admin-console');
        } else if (profileState.role === 'ENTERPRISE_ADMIN') {
          navigate('/firms');
        }
      }
    }
  }, [profileState]);
  return (
    <Box sx={{ width: '100%', height: '80px' }}>
      <AppBar
        sx={{
          background: '#ffffff',
          boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)',
          color: 'black',
          padding: '0 36px',
        }}
      >
        <Toolbar sx={{ height: '80px' }}>
          <Grid container justifyContent={'start'} alignItems={'center'}>
            <Grid item xs={2}>
              <Box component={Link} to="/conversations">
                <img src={Logo} style={{ transform: 'scale(1.3)' }} />
              </Box>
            </Grid>
            {showMobileMenu ? (
              <DrawerComp
                handleLogout={handleLogout}
                handleClose={handleClose}
                headerMenuList={headerMenuList}
                profileMenuList={profileMenuList}
              />
            ) : (
              <Grid
                container
                item
                xs={10}
                justifyContent={'end'}
                alignItems={'center'}
              >
                <Tabs
                  indicatorColor="primary"
                  textColor="inherit"
                  value={currentTabPath || false}
                >
                  {tabsItems.map(item => {
                    if (item.showToUser.includes(userType)) {
                      return (
                        <Tab
                          component={Link}
                          to={item.path}
                          key={item.path}
                          value={item.path}
                          icon={
                            item.Icon &&
                            (typeof item.Icon === 'string' ? (
                              <img
                                src={item.Icon}
                                alt={item.name}
                                style={{ marginRight: '10px' }}
                              />
                            ) : (
                              <item.Icon />
                            ))
                          }
                          style={{
                            textTransform: 'none',
                            maxWidth: '170px',
                            height: '80px',
                          }}
                          iconPosition="start"
                          label={item.name}
                        />
                      );
                    }
                  })}
                </Tabs>
                <MenuProfile
                  profileMenuList={profileMenuList}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  handleClose={handleClose}
                  handleLogout={handleLogout}
                />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
