import React from 'react';
import { IEnhancementFeaturesProps } from '@components/ChatContent/types';
import { useChatEnhancementFeatures } from '@components/ChatContent/hooks/useChatEnhancementFeatures';
import AssessmentRiskResult from '@pages/ClientsV2/AssessmentRiskResult';
import { RiskAssessmentContainer } from '@components/ChatContent/styles';

const ChatEnhancementFeatures: React.FC<IEnhancementFeaturesProps> = ({
  type,
  content,
  isLastItem,
  createdAt,
}) => {
  const { portfolioRisk, riskCapacity, riskPreference } =
    useChatEnhancementFeatures({
      content,
      type,
      createdAt,
      isLastItem,
    });

  return (
    <RiskAssessmentContainer>
      <AssessmentRiskResult
        riskCapacity={riskCapacity}
        riskPreference={riskPreference}
        portfolioRisk={portfolioRisk}
      />
    </RiskAssessmentContainer>
  );
};

export default ChatEnhancementFeatures;
