import { Grid, Typography } from '@mui/material';
import React from 'react';
import styles from './styles.module.css';
import Backbone from './Backbone.png';
import Arms from './Arms.png';
import Legs from './Legs.png';
import ArmsAndLegs from './Arms-Legs.png';

export function PortfolioStory(): React.ReactElement {
  return (
    <Grid container direction="column" spacing={2} className={styles.container}>
      <Grid item>
        <Typography component="h1" className={styles.title}>
          Sample Portfolio Story for Growth Low Minimum Model
        </Typography>
        <Typography component="h2" className={styles.subtitle}>
          Written from the voice of the advisor
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          We&apos;ve built a portfolio that takes a dynamic approach to
          investing, adapting to changing market cycles and adjusting for
          today&apos;s opportunities. The portfolio was created from a mix of
          strategies that were specifically selected to play a unique role
          within the portfolio.
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={2}>
          <div
            style={{
              width: '64px',
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={Backbone} alt="Backbone" />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography component="p" className={styles.paragraph}>
            We start with having a sound framework and base structure for the
            portfolio. It&apos;s like the backbone of your body that keeps you
            upright and functioning. For the foundation of your portfolio, we
            use a strategy providing lower cost, broad and efficient exposure to
            US equity and fixed income markets through ETFs.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} md={2}>
          <div
            style={{
              width: '64px',
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={ArmsAndLegs} alt="ArmsAndLegs" />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography component="p" className={styles.paragraph}>
            We complement this foundation with other active and tactical
            strategies that can help the portfolio move around, taking advantage
            of opportunities or helping provide steadiness in market falls.
            These are like the arms and legs of the portfolio, where the arms
            help to reach for opportunities, and the legs provide steadiness
            when the ground is uneven.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} md={2}>
          <div
            style={{
              width: '64px',
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={Arms} alt="Arms" />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography component="p" className={styles.paragraph}>
            To help us climb higher and remain nimble, we use two equity
            strategies focused on enhancing returns. The first provides
            diversified global equity exposure with active mutual funds, aiming
            to enhance returns through active security selection and asset
            allocation. The second focuses on disruptive growth, investing in a
            diversified range of themes undergoing transformation to capture
            long term, high growth opportunities.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} md={2}>
          <div
            style={{
              width: '64px',
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={Legs} alt="Legs" />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography component="p" className={styles.paragraph}>
            To help us remain steady, we use two risk management strategies.
            First is an active fixed income strategy, rotating across bond
            sectors to optimize yield relative to risk taken. Second is an
            alternatives strategy focused on providing risk management through
            diversification. The strategy provides niche exposures to different
            alternative strategies that have little correlation to the
            traditional assets in the rest of your portfolio.
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography component="p" className={styles.paragraph}>
          We selected these strategies from a list of solutions that have gone
          through AssetMark&apos;s due diligence review process. These
          strategies are continuously monitored by a dedicated team of research
          analysts at AssetMark, and should there be any concerns, we can make
          changes to your portfolio.
        </Typography>
      </Grid>
    </Grid>
  );
}
