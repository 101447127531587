// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__D5tZQ {
  padding: 32px !important;
}
.styles_subtitle__zacuS {
  font-family: Roboto !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
  margin-bottom: 5px !important;
  color: #004f7e !important;
}
.styles_paragraph__6\\+uxe {
  font-family: Roboto !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 17px !important;
  letter-spacing: 0% !important;
  vertical-align: middle !important;
  margin-bottom: 5px !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/premium-report/components/amk-profiles/styles.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;EACjC,6BAA6B;EAC7B,yBAAyB;AAC3B;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,iCAAiC;EACjC,6BAA6B;AAC/B","sourcesContent":[".container {\n  padding: 32px !important;\n}\n.subtitle {\n  font-family: Roboto !important;\n  font-weight: 700 !important;\n  font-size: 16px !important;\n  line-height: 24px !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n  margin-bottom: 5px !important;\n  color: #004f7e !important;\n}\n.paragraph {\n  font-family: Roboto !important;\n  font-weight: 400 !important;\n  font-size: 11px !important;\n  line-height: 17px !important;\n  letter-spacing: 0% !important;\n  vertical-align: middle !important;\n  margin-bottom: 5px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__D5tZQ`,
	"subtitle": `styles_subtitle__zacuS`,
	"paragraph": `styles_paragraph__6+uxe`
};
export default ___CSS_LOADER_EXPORT___;
