import React from 'react';
import {
  DataGrid,
  type GridColDef,
  type GridCellParams,
} from '@mui/x-data-grid';
import { Box, Grid, Typography } from '@mui/material';
import { UsePostCalculatePortfolioCharacteristicsResponse } from 'pages/Cases/hooks/usePostCalculatePortfolioCharacteristics';
import { PORTFOLIO_CHARACTERISTICS_GROUPS } from 'pages/Cases/hooks/usePostCalculatePortfolioCharacteristics';
interface ModelSetsPortfolioCharacteristicsTableProps {
  portfolioCharacteristics: UsePostCalculatePortfolioCharacteristicsResponse;
}

const ModelSetsPortfolioCharacteristicsTable: React.FC<
  ModelSetsPortfolioCharacteristicsTableProps
> = ({
  portfolioCharacteristics,
}: ModelSetsPortfolioCharacteristicsTableProps) => {
  const { portfolio_date } = portfolioCharacteristics;

  const getColumns = (rows: any) => {
    if (rows.length === 0) {
      return [];
    }
    const baseColumns: GridColDef[] = [
      {
        field: 'metric',
        headerName: '',
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderHeader: () => (
          <Typography
            sx={{
              color: '#666666',
              fontSize: '14px',
              fontFamily: 'Inter',
            }}
          >
            As of: {portfolio_date}
          </Typography>
        ),
      },
    ];
    const optionColumns = Object.keys(rows[0])
      .filter(key => key.startsWith('option'))
      .map(field => ({
        field,
        headerName: field.split('_')[1],
        sortable: false,
        flex: 0.4,
        minWidth: 60,
        headerAlign: 'center' as const,
        align: 'center' as const,
        editable: false,
        type: 'number' as const,
        renderHeader: () => (
          <Typography
            sx={{
              color: '#666666',
              fontSize: '14px',
              fontFamily: 'Inter',
            }}
          >
            {field.split('_')[1]}
          </Typography>
        ),
      }));
    return [...baseColumns, ...optionColumns];
  };

  const getColumnGroupingModel = (modelSet: any) =>
    modelSet?.portfolio_options.map((option: any) => ({
      groupId: `Option${option.option}`,
      description: modelSet.model_set_name + ' - Option ' + option.option,
      headerAlign: 'center',
      renderHeaderGroup: HeaderGroup,
      children: option.portfolios.map(portfolio => ({
        field: `option${option.option}_${portfolio.risk_profile}`,
        headerName: portfolio.risk_profile.toString(),
        renderHeader: () => portfolio.risk_profile.toString(),
      })),
    }));
  const HeaderGroup = (params: any) => (
    <Box
      sx={{
        textAlign: 'center',
        width: '100%',
        color: '#666666',
        fontSize: '12px',
        fontFamily: 'Inter',
      }}
    >
      <Typography variant="caption" component="div">
        {params.description}
      </Typography>
    </Box>
  );

  const rows = PORTFOLIO_CHARACTERISTICS_GROUPS.rows.map((row, index) => {
    const baseId = typeof row === 'string' ? row : row.id;
    return {
      id: `${baseId}_${index}`,
      baseId,
      metric: typeof row === 'string' ? row : row.name,
      editable: false,
      ...Object.fromEntries(
        portfolioCharacteristics.portfolio_options.flatMap(option =>
          option.portfolios.map(portfolio => [
            `option${option.option}_${portfolio.risk_profile}`,
            typeof portfolio[baseId] === 'number'
              ? Number(portfolio[baseId]).toFixed(1)
              : portfolio[baseId],
          ]),
        ),
      ),
    };
  });

  const getCellClassName = (params: GridCellParams): string => {
    const row = rows.find(r => r.id === params.id);
    const baseId = row?.baseId;

    if (
      baseId === 'yield' ||
      baseId === 'bonds' ||
      baseId === 'equity' ||
      baseId === 'total_expense'
    ) {
      return 'blue';
    } else if (
      baseId === 'us_equity_style_exposure' ||
      baseId === 'of_equity' ||
      baseId === 'pct_of_bonds'
    ) {
      return 'grey';
    }
    return '';
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataGrid
          rows={rows}
          columns={getColumns(rows)}
          columnGroupingModel={getColumnGroupingModel(portfolioCharacteristics)}
          hideFooter={true}
          rowHeight={36}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getCellClassName={getCellClassName}
          sx={{
            borderTop: 'none',
            borderRight: 'none',
            fontFamily: 'Inter',
            fontWeight: '500',
            maxHeight: '750px',
            '& *': {
              outline: 'none!important',
              cursor: 'default!important',
            },
            '& svg': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              borderBottom: 'none!important',
            },
            '& .MuiDataGrid-columnHeader': {
              borderTop: `1px solid rgba(224, 224, 224, 1)`,
              borderRight: `1px solid rgba(224, 224, 224, 1)`,
              backgroundColor: 'rgba(250, 250, 250, 1)',
              '&[data-field^="option"]': {
                backgroundColor: 'rgba(250, 250, 250, 1)',
              },
            },
            '& .MuiDataGrid-cell': {
              borderRight: `1px solid rgba(224, 224, 224, 1)`,
            },
            '& .blue': {
              backgroundColor: '#F5F9FF !important',
              fontWeight: 'bold',
              '&.total-error': {
                color: '#f00 !important',
              },
            },
            '& .grey': {
              backgroundColor: '#F5F5F5 !important',
              fontWeight: 'bold',
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ModelSetsPortfolioCharacteristicsTable;
