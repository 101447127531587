import * as React from 'react';

function Search() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7759 17.0578L14.8641 13.1469C15.9979 11.7857 16.5633 10.0398 16.4426 8.27245C16.3219 6.50506 15.5245 4.85223 14.2163 3.65779C12.908 2.46335 11.1896 1.81926 9.4186 1.85951C7.64756 1.89976 5.96021 2.62125 4.70757 3.87389C3.45492 5.12653 2.73344 6.81388 2.69319 8.58493C2.65294 10.356 3.29702 12.0744 4.49146 13.3826C5.68591 14.6909 7.33874 15.4883 9.10612 15.6089C10.8735 15.7296 12.6194 15.1642 13.9805 14.0305L17.8915 17.9422C17.9496 18.0003 18.0185 18.0463 18.0944 18.0777C18.1702 18.1092 18.2516 18.1253 18.3337 18.1253C18.4158 18.1253 18.4971 18.1092 18.573 18.0777C18.6489 18.0463 18.7178 18.0003 18.7759 17.9422C18.8339 17.8841 18.88 17.8152 18.9114 17.7393C18.9428 17.6634 18.959 17.5821 18.959 17.5C18.959 17.4179 18.9428 17.3366 18.9114 17.2607C18.88 17.1848 18.8339 17.1159 18.7759 17.0578ZM3.95867 8.75C3.95867 7.63748 4.28857 6.54994 4.90666 5.62492C5.52474 4.69989 6.40325 3.97892 7.43108 3.55318C8.45891 3.12743 9.58991 3.01604 10.6811 3.23308C11.7722 3.45012 12.7745 3.98585 13.5611 4.77252C14.3478 5.55919 14.8836 6.56147 15.1006 7.65262C15.3176 8.74376 15.2062 9.87476 14.7805 10.9026C14.3548 11.9304 13.6338 12.8089 12.7088 13.427C11.7837 14.0451 10.6962 14.375 9.58367 14.375C8.09234 14.3733 6.66256 13.7802 5.60803 12.7256C4.55349 11.6711 3.96033 10.2413 3.95867 8.75Z"
        fill="white"
      />
    </svg>
  );
}

const MemoSearch = React.memo(Search);
export default MemoSearch;
