/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
} from '@mui/material';

interface ProposalCheckboxesProps {
  disabled: boolean;
  handleCheckboxChange: (newCheckboxes: any) => void;
}

const checkboxesInitialState = [
  {
    title: 'General',
    column: 1,
    elements: [
      {
        title: 'Cover',
        type: 'COVER',
        hidden: false,
        checked: true,
      },
      {
        title: 'Table of Contents',
        type: 'TABLE_OF_CONTENTS',
        hidden: false,
        checked: true,
      },
      {
        title: 'IPS Introduction',
        type: 'IPS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Driver Results',
    column: 1,
    elements: [
      {
        title: 'Risk Band',
        type: 'RISK_BAND',
        hidden: false,
        checked: true,
      },
      {
        title: 'Assessment Inputs',
        type: 'ASSESSMENT_INPUTS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Recommendation',
    column: 1,
    elements: [
      {
        title: 'Snapshot',
        type: 'RECOMMENDED_PORTFOLIO_SNAPSHOT',
        hidden: false,
        checked: true,
      },
      {
        title: 'Holdings',
        type: 'RECOMMENDED_PORTFOLIO_HOLDINGS',
        hidden: false,
        checked: true,
      },
      {
        title: 'Risk',
        type: 'RECOMMENDED_PORTFOLIO_RISK_SCORE',
        hidden: false,
        checked: true,
      },
      {
        title: 'Expenses',
        type: 'RECOMMENDED_PORTFOLIO_EXPENSE_RATIO',
        hidden: false,
        checked: true,
      },
      {
        title: 'Allocations',
        type: 'RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Current Portfolio',
    column: 1,
    elements: [
      {
        title: 'Holdings',
        type: 'CURRENT_PORTFOLIO_HOLDINGS',
        hidden: false,
        checked: true,
      },
      {
        title: 'Allocations',
        type: 'CURRENT_PORTFOLIO_ALLOCATION_CHARTS',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Compare',
    column: 2,
    elements: [
      {
        title: 'Risk Scores',
        type: 'COMPARE_RISK',
        hidden: false,
        checked: true,
      },
      {
        title: 'Expense Ratio',
        type: 'COMPARE_EXPENSE_RATIO',
        hidden: false,
        checked: true,
      },
      {
        title: 'Holdings',
        type: 'COMPARE_HOLDINGS',
        hidden: false,
        checked: true,
      },
      {
        title: 'Asset Allocation',
        type: 'ASSET_ALLOCATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Region Allocation',
        type: 'REGION_ALLOCATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Historical Performance',
        type: 'HISTORICAL_PERFORMANCE',
        hidden: false,
        checked: true,
      },
      {
        title: 'Return Simulation',
        type: 'RETURN_SIMULATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Historical Return Simulation',
        type: 'COMPARE_HISTORICAL_RETURN_SIMULATION',
        hidden: false,
        checked: true,
      },
      {
        title: 'Sectors',
        type: 'SECTOR_ALLOCATION',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Analytics',
    column: 2,
    elements: [
      {
        title: 'Historical Returns',
        type: 'HISTORICAL_RETURN',
        hidden: false,
        checked: true,
      },
      {
        title: 'Potential Return',
        type: 'POTENTIAL_RETURN',
        hidden: false,
        checked: true,
      },
      {
        title: 'Risk Statistics',
        type: 'POTENTIAL_RISK',
        hidden: false,
        checked: true,
      },
    ],
  },
  {
    title: 'Disclosures',
    column: 2,
    elements: [
      {
        title: 'Duties and Responsibilities',
        type: 'DUTIES_AND_RESPONSIBILITIES',
        hidden: false,
        checked: true,
      },
      {
        title: 'Disclosures',
        type: 'DISCLOSURES',
        hidden: false,
        checked: true,
      },
      {
        title: 'Advisor Fee Disclosure',
        type: 'ADVISOR_FEE_DISCLOSURE',
        hidden: false,
        checked: true,
      },
      {
        title: 'Risks and Disclosures',
        type: 'RISK_DISCLOSURES',
        hidden: false,
        checked: true,
      },
      {
        title: 'Appendix',
        type: 'APPENDIX',
        hidden: false,
        checked: true,
      },
      {
        title: 'Appendix CMA',
        type: 'APPENDIX_CMA',
        hidden: false,
        checked: true,
      },
    ],
  },
];

const ProposalCheckboxes: FC<ProposalCheckboxesProps> = ({
  disabled,
  handleCheckboxChange,
}) => {
  const [checkboxes, setCheckboxes] = useState(checkboxesInitialState);

  useEffect(() => {
    handleCheckboxChange(getCheckedElements(checkboxes));
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCheckboxes = checkboxes.map(group => ({
      ...group,
      elements: group.elements.map(element =>
        element.type === event.target.name
          ? {
              ...element,
              checked: event.target.checked,
            }
          : element,
      ),
    }));
    setCheckboxes(updatedCheckboxes);
    handleCheckboxChange(getCheckedElements(updatedCheckboxes));
  };

  const getCheckedElements = updatedCheckboxes =>
    updatedCheckboxes.map(group => ({
      title: group.title,
      elements: group.elements
        .filter(element => element.checked)
        .map(({ hidden, checked, ...rest }) => rest),
    }));

  const groupedCheckboxes = checkboxes.reduce((acc, group) => {
    if (!acc[group.column]) {
      acc[group.column] = [];
    }
    acc[group.column].push(group);
    return acc;
  }, {});

  const columns = Object.values(groupedCheckboxes).map(
    (checkboxGroups: any) => {
      return checkboxGroups.reduce((acc, checkboxGroup) => {
        acc[checkboxGroup.title] = checkboxGroup.elements
          .filter(element => !element.hidden)
          .map(element => {
            return { [element.type]: element.title };
          });
        return acc;
      }, {});
    },
  );

  useEffect(() => {
    handleCheckboxChange(getCheckedElements(checkboxes));
  }, []);

  return (
    <Grid container justifyContent={'start'}>
      {columns.map((column, index) => (
        <Grid item mb={2} mr={6} key={index}>
          <FormControl
            sx={{ color: 'black' }}
            component="fieldset"
            variant="standard"
            disabled={disabled}
          >
            {Object.entries(column).map(([key, value]) => (
              <Grid item key={key} sx={{ padding: '16px 0 0 0' }}>
                <FormLabel
                  component="legend"
                  sx={{ fontWeight: 'bold', padding: '5px 0 0 0' }}
                >
                  {key}
                </FormLabel>
                <FormGroup sx={{ paddingLeft: '20px', paddingTop: '5px' }}>
                  {(value as { [key: string]: string }[]).map(item => {
                    const itemKey = Object.keys(item)[0];
                    const itemLabel = item[itemKey];
                    return (
                      <FormControlLabel
                        key={itemKey}
                        control={
                          <Checkbox
                            checked={
                              checkboxes
                                .flatMap(group => group.elements)
                                .find(element => element.type === itemKey)
                                ?.checked === true
                            }
                            onChange={handleChange}
                            name={itemKey}
                            sx={{ padding: '2px' }}
                          />
                        }
                        label={itemLabel}
                      />
                    );
                  })}
                </FormGroup>
              </Grid>
            ))}
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProposalCheckboxes;
