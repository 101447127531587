import React, { useEffect, useRef } from 'react';
import {
  Stack,
  Paper,
  MenuList,
  Popper,
  Grow,
  ClickAwayListener,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  styled,
  InputLabel,
  Button,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FilterMenuProps {
  placeholder?: string;
  variant?: 'filter' | 'select';
  optionTitle?: string;
  activeRows: {
    id: string;
    name: string;
    checked: boolean;
  }[];
  rows: {
    id: string;
    name: string;
    checked: boolean;
  }[];
  handleFilter: (id: string) => void;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  placeholder = 'Filter',
  variant = 'filter',
  optionTitle = '',
  rows,
  activeRows,
  handleFilter,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      !(
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      )
    ) {
      setOpen(false);
    }
  };

  const isChecked = (id: string) => activeRows.some(check => check.id === id);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Stack>
      {variant === 'filter' && (
        <FilterButton
          ref={anchorRef}
          id="filter-button"
          aria-controls={open ? 'filter-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <FilterListIcon
            sx={{ width: '16px', height: '16px', marginRight: '5px' }}
          />
          {placeholder}
        </FilterButton>
      )}

      {variant === 'select' && (
        <SelectButton
          ref={anchorRef}
          sx={{
            height: '40px',
            textTransform: 'none',
            fontWeight: 'normal',
            justifyContent: 'space-between',
          }}
          onClick={handleToggle}
        >
          <div
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {placeholder}
          </div>
          <ExpandMoreIcon />
        </SelectButton>
      )}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        hidden={rows.length === 0}
        sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'top-start' ? 'right top' : 'right top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <FilterMenuList
                  autoFocusItem={open}
                  id="filter-menu"
                  aria-labelledby="filter-button"
                >
                  <div
                    style={{
                      padding: '16px 16px 3px 16px',
                    }}
                  >
                    {optionTitle && (
                      <InputLabel
                        sx={{ color: '#000', fontSize: 10, fontWeight: 500 }}
                      >
                        {optionTitle}
                      </InputLabel>
                    )}
                  </div>

                  <div
                    style={{
                      maxHeight: 300,
                      overflow: 'auto',
                      padding: '0 16px 0px 16px',
                    }}
                  >
                    <FormGroup>
                      {rows.map(row => (
                        <FormLabel
                          key={row.id}
                          control={
                            <Checkbox
                              key={row.id}
                              id={`row-menu-${row.id}`}
                              checked={isChecked(row.id)}
                              size="medium"
                            />
                          }
                          label={
                            <FilterLabelCheckbox htmlFor={`row-menu-${row.id}`}>
                              {row.name}
                            </FilterLabelCheckbox>
                          }
                          onChange={() => handleFilter(row.id)}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </FilterMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

export default FilterMenu;

const FilterButton = styled(IconButton)`
  padding: 7px;
  background: transparent;
  pointer-events: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  min-width: 82px;
`;

const SelectButton = styled(Button)`
  padding: 7px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
`;

const FilterMenuList = styled(MenuList)`
  background-color: #fff;
`;

const FilterLabelCheckbox = styled(InputLabel)`
  color: #101828;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  font-family: 'Inter';
  cursor: pointer;
`;

const FormLabel = styled(FormControlLabel)`
  span {
    border: 0;

    &:hover {
      background-color: transparent;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
