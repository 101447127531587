export function str(key: string, fallback: string): string;
export function str(key: string, fallback?: undefined): string | undefined;
export function str(key: string, fallback?: string): string | undefined {
  const value = process.env[key];
  if (value === undefined || value === '') return fallback;
  return value;
}

export function bool(key: string, fallback: boolean): boolean;
export function bool(key: string, fallback?: undefined): boolean | undefined;
export function bool(key: string, fallback?: boolean): boolean | undefined {
  const value = process.env[key];
  if (value === undefined) return fallback;
  return ['0', 'false', 'off', ''].includes(value.toLowerCase()) === false;
}

export function int(key: string, fallback: number): number;
export function int(key: string, fallback?: undefined): number | undefined;
export function int(key: string, fallback?: number): number | undefined {
  const value = process.env[key];
  if (value === undefined || value === '') return fallback;
  return parseInt(value);
}

export function list(key: string, fallback: string[]): string[];
export function list(key: string, fallback?: undefined): string[] | undefined;
export function list(key: string, fallback?: string[]): string[] | undefined {
  const value = process.env[key];
  if (value === undefined || value === '') return fallback;
  return value.split(',').map(value => value.trimEnd());
}
