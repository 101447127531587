import { Typography } from '@mui/material';
import React from 'react';
import { SlideTitle, useSlideContext } from '../../../../features/slides';

export function Disclosures(): React.ReactElement {
  const { theme } = useSlideContext();

  const bodyStyle = {
    fontFamily: theme.fontFamily.body,
    fontWeight: theme.fontWeight.regular,
    fontSize: theme.fontSize.sm,
    color: theme.colors.neutral,
  };

  const titleStyle = {
    fontFamily: theme.fontFamily.headings,
    fontWeight: theme.fontWeight.medium,
    fontSize: theme.fontSize.sm,
    color: theme.colors.neutral,
  };

  return (
    <div>
      <div style={{ padding: '16px 48px' }}>
        <SlideTitle>Disclosures</SlideTitle>
      </div>

      <div
        style={{
          padding: '16px 48px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Typography sx={bodyStyle}>
          Source: MSCI. MSCI information may only be used for your internal use,
          may not be reproduced or redisseminated in any form and may not be
          used as a basis for or a component of any financial instruments or
          products or indices. None of the MSCI information is intended to
          constitute investment advice or recommendation to make (or refrain
          from making) any kind of investment decision and may not be relied on
          as such. Historical data and analysis should not be taken as an
          indication or guarantee of any future performance. MSCI information is
          provided on an &quot;as is&quot; basis and the user of this
          information assumes the entire risk of any use made of this
          information.
        </Typography>

        <Typography sx={bodyStyle}>
          MSCI, each of its affiliates and each other person involved in or
          related to compiling, computing or creating any MSCI information
          (collectively, the &quot;MSCI Parties&quot;) expressly disclaims all
          warranties with respect to this information. In no event shall any of
          the MSCI Parties have any liability for any direct, indirect, special,
          incidental, punitive, consequential (including, without limitation,
          lost profits) or any other damages. (
          <a href="https://www.msci.com">www.msci.com</a>).
        </Typography>

        <Typography sx={titleStyle}>IMPORTANT INFORMATION</Typography>

        <Typography sx={bodyStyle}>
          This is for informational purposes only, is not a solicitation, and
          should not be considered investment, legal, or tax advice. The
          information has been drawn from sources believed to be reliable, but
          its accuracy is not guaranteed and is subject to change. Financial
          advisors may seek more information by contacting AssetMark at
          800-664-5345.
        </Typography>

        <Typography sx={bodyStyle}>
          <b style={{ fontWeight: theme.fontWeight.medium }}>
            Investing involves risk, including the possible loss of principal.
            Past performance does not guarantee future results.
          </b>{' '}
          Asset allocation cannot eliminate the risk of fluctuating prices and
          uncertain returns. There is no guarantee that a diversified portfolio
          will outperform a non-diversified portfolio investment strategy, such
          as asset allocation, that can guarantee a profit or protect against
          loss. Actual client results will vary based on investment selection,
          timing, market conditions, and tax situation. It is not possible to
          invest directly in an index. Indexes are unmanaged, do not incur
          management fees, costs, and expenses and cannot be invested in
          directly. Index performance assumes the reinvestment of dividends.
          Investments in equities, bonds, options, and other securities, whether
          held individually or through mutual funds and exchange-traded funds,
          can decline significantly in response to adverse market conditions,
          company-specific events, changes in exchange rates, and domestic,
          international, economic, and political developments. Investments in
          bonds and fixed income-related securities involve market and interest
          rate risk (prices can decline if interest rates increase) and default
          risk (an issuer being unable to repay principal and interest).
          High-yield bonds are generally subject to greater risk of default than
          investment-grade bonds. Real estate investments are subject to credit
          and market risks, typically based on changes in interest rates and
          varied economic conditions. Investing in alternative investments,
          including managed futures, commodities, and currencies, is not
          appropriate for all persons, as the risk of loss is substantial.
          Investments in futures involve market, counterparty, leverage,
          liquidity, interest rate, foreign currency, commodity, volatility, and
          other risks. The exclusion of investments based on industry or ESG
          factors reduces the number of available investment choices and can
          impact overall investment performance.
        </Typography>

        <Typography sx={{ ...bodyStyle, fontWeight: theme.fontWeight.medium }}>
          For more complete information about the various investment solutions
          available, including the investment objectives, risks, and fees,
          please refer to the Disclosure Brochure and applicable Fund
          Prospectus. Please read them carefully before investing. For a copy,
          please contact AssetMark.
        </Typography>

        <Typography sx={bodyStyle}>
          AssetMark, Inc. is an investment adviser registered with the U.S.
          Securities and Exchange Commission. AssetMark Asset Management, a
          division of AssetMark, Inc., includes the firm’s proprietary
          investment strategies. GuideMark® and GuidePath® Funds are
          distributed by AssetMark Brokerage™, LLC, member FINRA, an affiliate
          of AssetMark, Inc. AssetMark, and third-party strategists and service
          providers are separate and unaffiliated companies. Each party is
          responsible for their own content and services.
        </Typography>

        <Typography sx={bodyStyle}>
          ©2025 AssetMark, Inc. All rights reserved.
          <br />
          6813343.1 | 07/2024 | EXP 07/2026
        </Typography>
      </div>
    </div>
  );
}
