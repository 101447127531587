import { Grid } from '@mui/material';
import React from 'react';

export type ChartBoxProps = {
  readonly children: React.ReactNode;
};

export function ChartBox({ children }: ChartBoxProps): React.ReactElement {
  return (
    <Grid
      item
      sx={{
        width: '100%',
        padding: '16px 16px 0 0',
        border: '1px solid #e0e0e0',
      }}
    >
      {children}
    </Grid>
  );
}
