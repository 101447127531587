import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../../helpers/api';

type UseDeleteCustomModelArguments = {
  modelId: string;
  successToastMessage: string;
};

type UseDeleteCustomModelResult = {
  deleteCustomModelLoading: boolean;
  deleteCustomModelMutate: (data: {
    modelId: string;
    successToastMessage: string;
  }) => void;
};

export const useDeleteCustomModelsMutation = (): UseDeleteCustomModelResult => {
  const { fetchMutation } = useFetchMutation();

  const { mutate, isLoading } = useMutation(
    (data: UseDeleteCustomModelArguments) =>
      fetchMutation({
        path: `/v2/models/${data.modelId}`,
        method: 'DELETE',
        invalidateQueries: 'customModels',
        successToastMessage: data.successToastMessage,
      }),
  );

  return {
    deleteCustomModelLoading: isLoading,
    deleteCustomModelMutate: mutate,
  };
};
