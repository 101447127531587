import React, { useCallback } from 'react';
import { slidesComponents, type Slide } from '../../_data';
import { useSlidesContent } from '../../context';
import styles from './styles.module.css';

type SlideRendererProps = {
  slide: Slide<any>;
};

export const SlideRenderer = React.memo(
  ({ slide }: SlideRendererProps): React.ReactElement => {
    const { updateSlideContent } = useSlidesContent();

    const Component = slidesComponents[slide.id];

    if (!Component) {
      return <div>Component for slide {slide.id} not found</div>;
    }

    const handleChangeSlide = useCallback(
      (s: Slide<any>, content: any) => updateSlideContent(s, content),
      [updateSlideContent],
    );

    console.log('render');
    return (
      <div key={slide.slideTemplateId} className={`${styles.slide} slide-item`}>
        <Component slide={slide} onChangeSlide={handleChangeSlide} />
      </div>
    );
  },
);

export default SlideRenderer;
