import { bool, int, str } from './getters';

export const settings = {
  environment: str('NODE_ENV', 'development'),
  mockServer: {
    enabled: bool('REACT_APP_MOCK_SERVER_ENABLED', false),
  },
  app: {
    baseUrl: str('REACT_APP_FRONTEND_URL', 'http://localhost:3000'),
    maxFileSize: int('REACT_APP_MAX_FILE_SIZE_MB', 30),
  },
  api: {
    baseUrl: str('REACT_APP_API_BASE_URL', 'http://localhost:8080/user-ms/api'),
  },
  magnifi: {
    baseUrl: str('REACT_APP_MAGNIFI'),
  },
  sentry: {
    environment: str('REACT_APP_SENTRY_ENVIRONMENT', 'dev'),
    dsn: str('REACT_APP_SENTRY_DSN'),
  },
  okta: {
    issuer: str('REACT_APP_OKTA_ISSUER'),
    clientId: str('REACT_APP_OKTA_CLIENT_ID'),
  },
  wealthbox: {
    baseUrl: str('REACT_APP_WEALTHBOX_AUTH_URL'),
    clientId: str('REACT_APP_WEALTHBOX_CLIENT_ID'),
  },
  wealth: {
    baseUrl: str('REACT_APP_IP_WEALTH'),
  },
  saleforce: {
    baseUrl: str('REACT_APP_SALESFORCE_AUTH_URL'),
    clientId: str('REACT_APP_SALESFORCE_CLIENT_ID'),
  },
};
