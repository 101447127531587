import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export type FooterButtonProps = {
  readonly disabled: boolean;
  readonly loading?: boolean;
  readonly children: React.ReactNode;
  readonly onClick: () => void;
  readonly sx?: React.CSSProperties;
};

export function FooterButton({
  children,
  onClick,
  disabled,
  sx,
  loading = false,
}: FooterButtonProps): React.ReactElement {
  return (
    <MuiButton
      onClick={onClick}
      disabled={disabled || loading}
      disableRipple
      aria-busy={loading}
      sx={{
        ...(sx
          ? {
              ...sx,
              '&:hover': {
                background: '#ddd',
                color: '#000',
                boxShadow: 'none',
              },
              '&:disabled': {
                background: '#ddd',
                color: '#888',
                boxShadow: 'none',
              },
            }
          : {
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#e5e5e5',
              },
              '&:disabled': {
                backgroundColor: 'hsl(0, 0.00%, 85%)',
                color: '#535256',
              },
              color: '#535256',
              py: 1,
              px: 2,
              borderRadius: 2,
              fontSize: '12px',
              lineHeight: '16px',
              border: '1px solid hsl(0, 0.00%, 89.80%)',
              textTransform: 'none',
              fontWeight: 500,
            }),
      }}
    >
      {loading && <CircularProgress size={12} sx={{ mr: 1 }} />}
      {children}
    </MuiButton>
  );
}
