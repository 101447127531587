import React from 'react';
import { EditableField } from '../../components/EditableField';
import { type Slide } from '../../_data';
import { Grid } from '@mui/material';
import Footer from '../Footer';

export type Slide2ContentType = [
  {
    field: 'title1';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
  {
    field: 'title2';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
  {
    field: 'model_request';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
  {
    field: 'model_developed';
    label: string;
    value: string;
    type: 'editableTextarea';
  },
];

export const Slide2Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide2ContentType>;
    onChangeSlide: (
      slide: Slide<Slide2ContentType>,
      content: Slide2ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <Grid
        container
        sx={{
          padding: '12px 12px 0 12px',
          width: '100%',
          height: '100%',
          fontFamily: 'Roboto',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            height: 'calc(100% - 48px)',
          }}
        >
          <Grid container item xs={12}>
            <Grid
              item
              justifyContent="flex-start"
              xs={6}
              sx={{
                textAlign: 'left',
                padding: '12px',
                color: 'var(--blue-dark)',
              }}
            >
              <Grid item>
                <EditableField
                  field={'title1'}
                  slide={slide}
                  onSlideContentChange={onChangeSlide}
                  editorClassName="slide2"
                />
              </Grid>
              <Grid item>
                <EditableField
                  field={'model_request'}
                  slide={slide}
                  onSlideContentChange={onChangeSlide}
                  style={{
                    height: '520px',
                  }}
                  editorClassName="slide2"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                backgroundColor: '#004F7E',
                color: '#FFFFFF',
                padding: '12px',
                width: '100%',
                fontFamily: 'Roboto',
              }}
            >
              <Grid item height="fit-content">
                <EditableField
                  slide={slide}
                  field={'title2'}
                  onSlideContentChange={onChangeSlide}
                  style={{
                    fontSize: '38px',
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                  }}
                  editorClassName="slide2-1"
                />
              </Grid>
              <Grid item height="fit-content">
                <EditableField
                  slide={slide}
                  field={'model_developed'}
                  onSlideContentChange={onChangeSlide}
                  style={{
                    height: '520px',
                  }}
                  editorClassName="slide2-1"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Footer
          numberPage={slide.slideTemplateId}
          text="For Financial Advisor use only"
        />
      </Grid>
    );
  },
);

export default Slide2Content;
