import { Box, Grid, InputLabel, TextField } from '@mui/material';
import React, { FC } from 'react';
import { InputLabels } from '../Buttons';

interface FirmFeesProps {
  fee: number | undefined;
}

const FirmFees: FC<FirmFeesProps> = ({ fee }) => {
  return (
    <Grid container my={'24px'}>
      <Grid item sx={{ mr: '32px', width: '200px' }}>
        <Box mb={'6px'}>
          <InputLabels sx={{ color: '#000', fontWeight: '500' }}>
            Fees
          </InputLabels>
        </Box>
        <Box>
          <p style={{ color: '#4F4F4F', fontWeight: '400', fontSize: '14px' }}>
            Manage your firm&apos;s fee across your client base
          </p>
        </Box>
      </Grid>

      <Grid item sx={{ width: 'calc(100% - 232px)' }}>
        <InputLabel
          htmlFor="fiatfee"
          sx={{
            fontWeight: '500',
            color: '#000',
            fontSize: '14px',
          }}
        >
          Flat fee (%)
        </InputLabel>
        <TextField
          size="small"
          disabled
          placeholder="Enter Flat fee"
          sx={{ width: '220px', paddingTop: '5px' }}
          value={fee === undefined ? '' : fee * 100}
        />
      </Grid>
    </Grid>
  );
};

export default FirmFees;
