import { Box, Grid, TextField } from '@mui/material';
import React, { FC } from 'react';
import { InputLabels } from '../Buttons';

interface FirmDisclosuresProps {
  disclosure: string | undefined;
  dutiesAndResponsibilities: string | undefined;
  advisorFeeFullDisclosure: string | undefined;
}

const FirmDisclosures: FC<FirmDisclosuresProps> = ({
  disclosure,
  dutiesAndResponsibilities,
  advisorFeeFullDisclosure,
}) => {
  return (
    <Grid container my={'24px'}>
      <Grid item sx={{ mr: '32px', width: '200px' }}>
        <Box mb={'6px'}>
          <InputLabels sx={{ color: '#000000', fontWeight: '500' }}>
            Firm Disclosures
          </InputLabels>
        </Box>
        <Box>
          <p style={{ color: '#4F4F4F', fontWeight: '400', fontSize: '14px' }}>
            Updated 10.18.2025
          </p>
        </Box>
      </Grid>

      <Grid sx={{ width: 'calc(100% - 232px)' }}>
        <InputLabels
          htmlFor="weight"
          sx={{
            fontWeight: '500',
            color: '#000',
            marginBottom: '8px',
            fontSize: '14px',
          }}
        >
          Firm Disclosure
        </InputLabels>

        <TextField
          multiline
          fullWidth
          disabled
          sx={{ paddingBottom: '24px' }}
          value={disclosure || ''}
        />

        <InputLabels
          htmlFor="weight"
          sx={{
            fontWeight: '500',
            color: '#000',
            marginBottom: '8px',
            fontSize: '14px',
          }}
        >
          Duties and Responsibilities
        </InputLabels>

        <TextField
          multiline
          fullWidth
          disabled
          sx={{ paddingBottom: '24px' }}
          value={dutiesAndResponsibilities || ''}
        />

        <InputLabels
          htmlFor="weight"
          sx={{
            fontWeight: '500',
            color: '#000',
            marginBottom: '8px',
            fontSize: '14px',
          }}
        >
          Advisor Fee Full Disclosure
        </InputLabels>

        <TextField
          multiline
          fullWidth
          disabled
          value={advisorFeeFullDisclosure || ''}
        />
      </Grid>
    </Grid>
  );
};

export default FirmDisclosures;
