import React from 'react';
import { Box } from '@mui/material';

interface LogoProps {
  src: string;
}
const Logo: React.FC<LogoProps> = ({ src }) => {
  return (
    <Box height={100}>
      <img src={src}></img>
    </Box>
  );
};

export default Logo;
