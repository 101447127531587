import React, { useMemo } from 'react';
import { Box, Divider, Grid, Stack } from '@mui/material';
import Header from './components/Header';
import Logo from './components/Logo';
import DescriptionModel from './components/DescriptionModel';
import PrompQuestions from '@components/PrompQuestions';
import MarketplaceTable from './components/MarketplaceTable';
import MarketplacePieChart from './components/PieChart';
import { useGetSponsorModel } from '../../hooks/useGetSponsorModel';
import SwitchComponent from 'components/SwitchComponent';
import { useDispatch } from 'react-redux';
import { actions } from '@features/Chat/slice';
import { useNavigate } from 'react-router-dom';

interface MarketplaceModelDetailProps {
  modelId: string;
  handleBackButtonClick: () => void;
}

const MarketplaceModelDetail: React.FC<MarketplaceModelDetailProps> = ({
  modelId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getSponsorModelByIdData } = useGetSponsorModel({
    sponsorId: modelId,
  });

  const [switches, setSwitches] = React.useState([
    { id: 1, name: 'Holdings', active: true },
    { id: 2, name: 'Asset Allocation', active: false },
    { id: 3, name: 'Region Allocation', active: false },
    { id: 4, name: 'Sector Allocation', active: false },
  ]);

  const prompQuestions = useMemo(() => {
    return [
      {
        question: `What is the expected risk and return?`,
        prompt: `What is the expected risk and return for ${getSponsorModelByIdData.modelName}?`,
      },
      {
        question: `What is the impact if US equities fall 20%?`,
        prompt: `What is the impact to the ${getSponsorModelByIdData.modelName} if US equities fall 20%?`,
      },
      {
        question: `What is the 2023 Historical Performance?`,
        prompt: `What is the 2023 historical performance of the ${getSponsorModelByIdData.modelName}?`,
      },
    ];
  }, [getSponsorModelByIdData.modelName]);

  const getSelectedSwitch = () => {
    switch (switches.find(switchItem => switchItem.active)?.id) {
      case 1:
        return <MarketplaceTable holdings={getSponsorModelByIdData.holdings} />;
      case 2:
        return (
          <MarketplacePieChart data={getSponsorModelByIdData.assetAllocation} />
        );
      case 3:
        return (
          <MarketplacePieChart
            data={getSponsorModelByIdData.regionAllocation}
          />
        );
      case 4:
        return (
          <MarketplacePieChart
            data={getSponsorModelByIdData.sectorAllocation}
          />
        );
    }
  };

  const handlePrompQuestionClick = question => {
    prompQuestions.forEach(prompQuestion => {
      if (prompQuestion.question === question) {
        dispatchChatQuestion(prompQuestion.prompt);
      }
    });
  };

  const dispatchChatQuestion = (question: string) => {
    const isChatStreaming = localStorage.getItem('streaming') === 'true';
    navigate('/conversations/stream');
    dispatch(
      isChatStreaming
        ? actions.setSearchQuery(question)
        : actions.searchQueryRequest({ query: question }),
    );
  };

  return (
    <Box sx={{ background: '#fff', borderRadius: '4px', width: '100%' }}>
      <Header sponsorModel={getSponsorModelByIdData} />
      <Grid
        container
        item
        direction="row"
        padding={{ xs: '10px', sm: '20px' }}
        xs={12}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          xl={3}
          paddingRight={{ xs: '0px', sm: '0px', md: '24px', xl: '24px' }}
        >
          <Grid
            item
            sx={{ background: '#F5F5F5', padding: '24px', width: '100%' }}
          >
            <Logo src={getSponsorModelByIdData.sponsorLogo} />
            <DescriptionModel
              name={getSponsorModelByIdData.modelName}
              series={getSponsorModelByIdData.seriesName}
            />
            <Divider />
            <Box sx={{ pt: '32px' }}>
              <PrompQuestions
                itemPy="10px"
                questions={prompQuestions.map(question => question.question)}
                handleQuestionClick={handlePrompQuestionClick}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8}
          xl={9}
          mt={{ xs: '20px', sm: '20px', md: '0px', xl: '0px' }}
          sx={{ alignContent: 'flex-start' }}
        >
          <Stack
            direction="row"
            p={'4px'}
            width="500px"
            sx={{
              backgroundColor: '#f5f5f5',
              borderRadius: '14px',
              textAlign: 'center',
              height: '24px',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            {switches.map(switchItem => (
              <SwitchComponent
                key={switchItem.id}
                switchItem={switchItem}
                setSwitches={setSwitches}
              />
            ))}
          </Stack>
          <Box width={'100%'} minHeight={'400px'}>
            {getSelectedSwitch()}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketplaceModelDetail;
