import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DataGrid,
  type GridColDef,
  GridRenderCellParams,
  type GridRenderEditCellParams,
  GridRowId,
  GridValidRowModel,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid';
import {
  getHoldingCategoryId,
  getHoldingCategoryOrder,
  getModelSetIndexByName,
  holdingsCategories,
} from 'pages/Cases/helpers/misc';
import { CaseMilestoneAction } from 'pages/Cases/hooks/useGetCases';
import { usePutCaseActionById } from 'pages/Cases/hooks/usePutCaseActionById';
import React, { useEffect, useState } from 'react';

import InputFilter from '@components/InputFilter';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GreyFrame from 'components/GrayFrame';
import SecondaryButton from 'components/SecondaryButton';
import { useGetAllModels } from 'pages/Cases/hooks/useGetAllModels';
import { FooterButton } from 'features/slides/components/slides-footer/footer-button';
// props

type ModelSetsTableProps = {
  action: CaseMilestoneAction;
  isDraft?: boolean;
  modelSetName: string;
  slideMode?: boolean;
  onChangeSlide?: (cells: any) => void;
};

// main component

const ModelSetsTable = ({
  action,
  modelSetName,
  isDraft,
  slideMode = false,
  onChangeSlide,
}: ModelSetsTableProps): JSX.Element => {
  const modelSetIndex = getModelSetIndexByName(modelSetName, action);
  const [modelSet, setModelSet] = useState<any>(() =>
    getInitialModelSet(action, modelSetIndex),
  );
  const [modelSetDraft, setModelSetDraft] = useState<any>(() =>
    JSON.parse(JSON.stringify(modelSet)),
  );
  const [rows, setRows] = useState<ModelSetTableRow[]>([]);
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const { putCaseActionByIdMutate, isSuccess, putCaseActionByIdIsLoading } =
    usePutCaseActionById();
  const { models } = useGetAllModels({
    model_ids: [],
  });

  const apiRef = useGridApiRef();

  const [selectedOption, setSelectedOption] = useState<{
    current: string;
    prev: string;
  }>(() => {
    const finalOption = action.value.model_set_holdings[
      modelSetIndex
    ]?.portfolio_options.find((opt: any) => opt.is_final);
    const value = finalOption ? finalOption.option.toString() : 'none';
    return {
      current: value,
      prev: value,
    };
  });

  const [isTableEditable, setIsTableEditable] = useState<boolean>(() => {
    const finalOption = action.value.model_set_holdings[
      modelSetIndex
    ]?.portfolio_options.find((opt: any) => opt.is_final);
    return isDraft && !finalOption ? true : false;
  });

  useEffect(() => {
    setRows(getRows(modelSetDraft));
  }, [modelSetDraft]);

  useEffect(() => {
    if (isSuccess) {
      setModelSet(JSON.parse(JSON.stringify(modelSetDraft)));
    }
  }, [isSuccess]);

  const handleCloseModal = () => {
    setOpen(false);
    setSearchFilter('');
  };

  useEffect(() => {
    if (slideMode) {
      onChangeSlide?.({
        // ToDo: cambiar esta estructura de datos por una que sirva para pintar la tabla en Google API
        rows: getRows(modelSet),
        columns: getColumns(modelSet, slideMode),
      });
    }
  }, [slideMode, modelSet]);

  const handleRowChange = (
    rows: ModelSetTableRow[],
    newRow: GridValidRowModel,
  ) => {
    // update edited row
    const updatedRows = rows.map(row =>
      row.id === newRow.id ? newRow : row,
    ) as ModelSetTableRow[];

    // ensure all weights
    mapRowsWeights(updatedRows, (row: ModelSetTableRow, key: string) => {
      if (row[key] === undefined) {
        delete row[key];
      } else {
        row[key] = ensureWeight(row[key] as number);
      }
    });

    // Find which cell was edited by comparing values
    const editedKey = Object.keys(newRow).find(
      key =>
        key.startsWith('option') &&
        !key.endsWith('_prev') &&
        newRow[key] !== newRow[`${key}_prev`],
    );

    iterateModelSet(
      modelSetDraft,
      (portfolioOptions: any, portfolio: any, holding: any) => {
        const row = findRow(updatedRows, holding.strategy, holding.profile);
        if (row) {
          const weightKey = getOptionKey(
            portfolioOptions.option,
            portfolio.risk_profile,
          );
          holding.weight = ensureWeight((row[weightKey] as number) / 100, 4);

          // If this is the edited cell, update the model_id
          if (weightKey === editedKey && Number(row[weightKey]) > 0) {
            const matchingModel = models.find(
              m =>
                m.strategy === holding.strategy &&
                m.profile === holding.profile &&
                m.risk_profile === portfolio.risk_profile,
            );
            if (matchingModel) {
              holding.model_id = matchingModel.model_id;
            }
          }
        }
      },
    );

    setModelSetDraft({ ...modelSetDraft });
    setRows([...updatedRows]);
    return newRow;
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(prev => ({
      current: value,
      prev: prev.prev,
    }));

    // Update is_final in modelSetDraft
    const updatedModelSetDraft = JSON.parse(JSON.stringify(modelSetDraft));
    updatedModelSetDraft.portfolio_options.forEach((opt: any) => {
      opt.is_final = opt.option.toString() === value;
    });
    setModelSetDraft(updatedModelSetDraft);
  };

  const handleDiscardChanges = () => {
    setModelSetDraft(JSON.parse(JSON.stringify(modelSet)));
    setSelectedOption(prev => ({
      current: prev.prev,
      prev: prev.prev,
    }));
    mapRowsWeights(rows, (row: ModelSetTableRow, key: string) => {
      row[key] = row[`${key}_prev`];
    });
    setRows([...rows]);
  };

  const handleSaveChanges = () => {
    const modelSetToSave = JSON.parse(JSON.stringify(modelSetDraft));

    // Update is_final in all options
    modelSetToSave.portfolio_options.forEach((opt: any) => {
      opt.is_final = opt.option.toString() === selectedOption.current;
    });

    updateModelSet(modelSetToSave, rows);

    setSelectedOption(prev => ({
      current: prev.current,
      prev: prev.current,
    }));

    // Update isTableEditable only after saving
    setIsTableEditable(selectedOption.current === 'none');

    putCaseActionByIdMutate({
      caseId: action.caseId,
      actionId: action.id,
      versionName: action.versionName,
      value: {
        ...action.value,
        model_set_holdings: action.value.model_set_holdings.map(
          (ms: any, index: number) =>
            index === modelSetIndex ? modelSetToSave : ms,
        ),
      },
      successToastMessage: 'Model set updated successfully',
    });
  };

  const handleAddRow = (holding: any) => {
    modelSetDraft.portfolio_options.forEach(portfolioOptions => {
      portfolioOptions.portfolios.forEach(portfolio => {
        portfolio.holdings.push({
          ...holding,
          weight: 0,
        });
      });
    });
    setModelSetDraft({ ...modelSetDraft });
    handleCloseModal();
  };

  const handleDeleteRow = (rowToDelete: ModelSetTableRow) => {
    iterateModelSet(modelSetDraft, (portfolioOptions: any, portfolio: any) => {
      portfolio.holdings = portfolio.holdings.filter(
        (holding: any) =>
          !(
            holding.strategy === rowToDelete.strategy &&
            holding.profile === rowToDelete.profile
          ),
      );
    });

    setModelSetDraft({ ...modelSetDraft });
  };

  const columns: GridColDef[] = [
    {
      field: 'full_model_name',
      headerName: 'Strategy Name',
      sortable: false,
      editable: false,

      ...getColumnWidth(
        'full_model_name',
        modelSet.portfolio_options.length,
        slideMode,
      ),
      renderCell: params => {
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              '&:hover .delete-button': {
                opacity: 1,
              },
            }}
          >
            <Tooltip title={params.value} placement="top">
              <Typography sx={{ flex: 1 }}>{params.value}</Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    { field: 'category', headerName: 'Category', flex: 1 },
    {
      field: 'action',
      headerName: '',
      flex: 0.5,
      align: 'center' as const,
      renderCell: params => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAddRow(params.row)}
            sx={{
              backgroundColor: '#F1F1F1',
              border: '1px solid #F1F1F1',
              borderRadius: '20px',
              color: '#000',
              boxShadow: 'none',
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: '500',
              '&:hover': {
                backgroundColor: '#000',
                border: '1px solid #000',
                boxShadow: 'none',
                color: '#fff',
              },
            }}
          >
            Add
          </Button>
        );
      },
    },
  ];

  const getFilteredModels = () => {
    if (!searchFilter) return models;

    return models.filter(model =>
      model.strategy.toLowerCase().includes(searchFilter.toLowerCase()),
    );
  };

  const renderRadioOptions = () => {
    return (
      <RadioGroup
        aria-labelledby="model-sets-option"
        name="model-sets-option"
        value={selectedOption.current}
        onChange={e => handleOptionChange(e.target.value)}
        row
        sx={{ opacity: isDraft ? 1 : 0.5 }}
      >
        <FormControlLabel
          value="none"
          control={<Radio size="small" />}
          label="None"
          disabled={!isDraft}
        />
        {modelSet?.portfolio_options.map((opt: any) => (
          <FormControlLabel
            key={opt.option}
            value={opt.option.toString()}
            control={<Radio size="small" />}
            label={`Option ${opt.option}`}
            disabled={!isDraft}
          />
        ))}
      </RadioGroup>
    );
  };

  const hasChanges = () => {
    if (isTableEditable) {
      return hasTableChanged(rows, modelSetDraft, modelSet, selectedOption);
    }
    return selectedOption.current !== selectedOption.prev;
  };

  return (
    <Grid item xs={12} sx={{ paddingBottom: '16px' }}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={!isTableEditable || !isDraft}
          sx={{
            borderRadius: '8px',
            border: '1px solid #E5E5E5',
            color: '#535256',
            backgroundColor: '#fff',
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: '12px',
            fontWeight: '500',
            '&:hover': {
              backgroundColor: '#000',
              border: '1px solid #000',
              color: '#fff',
              '& .MuiSvgIcon-root': {
                color: '#fff',
              },
            },
            '& .MuiSvgIcon-root': {
              color: '#535256',
            },
            opacity: isTableEditable && isDraft ? 1 : 0.5,
          }}
        >
          <AddCircleOutlineOutlinedIcon
            sx={{ mr: 1, width: '16px', height: '16px' }}
          />
          Add Strategy
        </Button>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <SecondaryButton
            text="Discard changes"
            heightVariant="small"
            disabled={!hasChanges() || !isDraft}
            handleClick={handleDiscardChanges}
          />
          <FooterButton
            loading={putCaseActionByIdIsLoading}
            disabled={
              !hasChanges() ||
              (isTableEditable && !isValidTable(rows)) ||
              !isDraft
            }
            onClick={handleSaveChanges}
            sx={{
              borderRadius: '20px',
              backgroundColor: '#000',
              color: '#fff',
              padding: '6px 16px',
              textTransform: 'none',
              boxShadow: 'none',
              fontSize: '12px',
            }}
          >
            Save changes
          </FooterButton>
        </Box>
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" gap={1}>
        <Typography
          sx={{ fontSize: 14, fontWeight: 'normal', color: '#4F4F4F' }}
        >
          Select final model set:
        </Typography>
        {renderRadioOptions()}
      </Grid>
      <Box sx={{ width: '100%' }}>
        <StyledDataGrid
          apiRef={apiRef}
          rows={[...rows, getTotalsRow(rows)]}
          columns={getColumns(
            modelSet,
            slideMode,
            handleDeleteRow,
            isTableEditable,
          )}
          columnGroupingModel={getColumnGroupingModel(modelSet, modelSetName)}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          hideFooter
          rowHeight={36}
          onCellClick={params => {
            if (
              params.colDef.editable &&
              params.id !== 'totals' &&
              params.cellMode === 'view'
            ) {
              apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
              });
            }
          }}
          getCellClassName={params =>
            `${getCellClassName(params)}${!isTableEditable ? ' disabled-cell' : ''}`
          }
          processRowUpdate={
            isTableEditable
              ? newRow => handleRowChange(rows, newRow)
              : undefined
          }
        />
      </Box>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          p: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: 800,
            backgroundColor: '#fff',
          }}
        >
          <Grid
            container
            sx={{ padding: '16px' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Add Strategy</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box>
            <Grid container>
              <GreyFrame>
                <Grid container item p={2} direction="column">
                  <Grid item pb={2}>
                    <InputFilter
                      value={searchFilter}
                      placeholder="Search for Strategy name"
                      debounceTime={500}
                      handleInputChange={searchFilter =>
                        setSearchFilter(searchFilter)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <DataGrid
                      rows={getFilteredModels()}
                      columns={columns}
                      getRowId={getModelId}
                      disableColumnMenu
                      disableColumnResize
                      disableRowSelectionOnClick
                      disableColumnSorting
                      hideFooter
                      sx={{
                        height: '500px',
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontWeight: 600,
                          color: '#666666',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </GreyFrame>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default ModelSetsTable;

// keys helpers

const getOptionKey = (option: number, riskProfile: number) =>
  `option_${option}_riskprofile_${riskProfile}`;

// model set helpers

const getModelSet = (action: any, modelSetIndex: number) =>
  action?.value?.model_set_holdings[modelSetIndex];

const iterateModelSet = (modelSet: any, predicate: any) => {
  modelSet?.portfolio_options.forEach((portfolioOptions: any) => {
    portfolioOptions.portfolios.forEach((portfolio: any) => {
      portfolio.holdings.forEach((holding: any) => {
        predicate(portfolioOptions, portfolio, holding);
      });
    });
  });
};

const updateModelSet = (modelSet: any, rows: ModelSetTableRow[]) => {
  // First update weights
  iterateModelSet(
    modelSet,
    (portfolioOptions: any, portfolio: any, holding: any) => {
      const row = findRow(rows, holding.strategy, holding.profile)!;
      const weightKey = getOptionKey(
        portfolioOptions.option,
        portfolio.risk_profile,
      );
      holding.weight = ensureWeight((row[weightKey] as number) / 100, 4);
    },
  );

  // Remove holdings with weight 0
  modelSet.portfolio_options.forEach((portfolioOptions: any) => {
    portfolioOptions.portfolios.forEach((portfolio: any) => {
      portfolio.holdings = portfolio.holdings.filter(
        (holding: any) => holding.weight > 0,
      );
    });
  });
};

// table helpers

const hasTableChanged = (
  rows: ModelSetTableRow[],
  modelSetDraft: any,
  modelSet: any,
  selectedOption: { current: string; prev: string },
) => {
  const draftRows = getRows(modelSetDraft);
  const originalRows = getRows(modelSet);

  const hasModelSetChanged =
    JSON.stringify(draftRows) !== JSON.stringify(originalRows);

  const hasRowsChanged = rows.some(row =>
    Object.keys(row).some(key => {
      if (key.startsWith('option')) {
        if (!key.endsWith('_prev') && row[key] !== row[`${key}_prev`]) {
          return true;
        }
      }
      return false;
    }),
  );

  const hasOptionChanged = selectedOption.current !== selectedOption.prev;

  return hasModelSetChanged || hasRowsChanged || hasOptionChanged;
};

const isValidTable = (rows: ModelSetTableRow[]) => {
  const totalsRow = getTotalsRow(rows);
  return Object.keys(getTotalsRow(rows)).every(key =>
    key.startsWith('option') ? totalsRow[key] === 100 : true,
  );
};

export const getColumns = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  modelSet: any,
  slideMode: boolean,
  onDeleteRow?: (row: ModelSetTableRow) => void,
  isEditable: boolean = true,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => {
  const baseColumns: GridColDef[] = [
    {
      field: 'strategy',
      headerName: 'Strategist',
      sortable: false,
      headerClassName: 'base-columns-header',
      editable: false,
      ...getColumnWidth(
        'strategy',
        modelSet.portfolio_options.length,
        slideMode,
      ),
      renderCell: params => {
        return (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              '&:hover .delete-button': {
                opacity: isEditable ? 1 : 0,
              },
            }}
          >
            <Typography sx={{ flex: 1 }}>{params.value}</Typography>
            {onDeleteRow ? (
              <Button
                className="delete-button"
                variant="outlined"
                size="small"
                onClick={isEditable ? () => onDeleteRow(params.row) : undefined}
                sx={{
                  backgroundColor: '#fff',
                  opacity: 0,
                  position: 'absolute',
                  right: '12px',
                  minWidth: 'unset',
                  transition: 'opacity 0.2s',
                  textTransform: 'none',
                  fontSize: '12px',
                  fontWeight: '500',
                  border: 'none',
                  gap: '12px',
                  color: isEditable ? '#000' : '#ccc',
                  cursor: isEditable ? 'pointer' : 'not-allowed',
                  pointerEvents: isEditable ? 'auto' : 'none',
                  '&:hover': {
                    border: 'none',
                    backgroundColor: isEditable ? '#000' : '#fff',
                    color: isEditable ? '#fff' : '#ccc',
                    boxShadow: isEditable
                      ? '1px 1px 1px 1px rgba(0, 0, 0, 0.1)'
                      : 'none',
                  },
                }}
              >
                Delete Strategy
              </Button>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: 'profile',
      headerName: 'Profile',
      sortable: false,
      headerClassName: 'base-columns-header',
      headerAlign: 'center' as const,
      align: 'center' as const,
      editable: false,
      type: 'number' as const,
      cellClassName: 'profile-column',
      ...getColumnWidth(
        'profile',
        modelSet.portfolio_options.length,
        slideMode,
      ),
    },
  ];

  const optionColumns: GridColDef[] = [];

  modelSet.portfolio_options.forEach((portfolioOptions: any) => {
    portfolioOptions.portfolios.forEach(
      (portfolio: any, index: number, portfolios: any[]) => {
        optionColumns.push({
          field: getOptionKey(portfolioOptions.option, portfolio.risk_profile),
          headerName: portfolioOptions.option,
          sortable: false,
          headerAlign: 'center' as const,
          align: 'center' as const,
          editable: isEditable || !slideMode,

          type: 'number' as const,
          cellClassName:
            index === portfolios.length - 1 ? 'last-risk-profile-column' : '',
          ...getColumnWidth('', modelSet.portfolio_options.length, slideMode),
          renderHeader: () => portfolio.risk_profile,
          renderEditCell: (params: GridRenderEditCellParams) => {
            return <CustomEditCell {...params} isEditable={isEditable} />;
          },
          renderCell: (params: GridRenderCellParams) => {
            return formatWeight(params.formattedValue, params.id);
          },
          preProcessEditCellProps: (params: any) => {
            const value = Number(params.props.value) || 0;
            const hasError = isNaN(value) || value < 0 || value > 100;
            return { ...params.props, error: hasError, value: value };
          },
        });
      },
    );
  });

  return [...baseColumns, ...optionColumns];
};

// column helpers

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getColumnGroupingModel = (modelSet: any, modelSetName: string) =>
  modelSet?.portfolio_options.map((portfolioOptions: any) => ({
    groupId: `Option${portfolioOptions.option}`,
    headerName: `${modelSetName}. Option ${portfolioOptions.option}`,
    description: 'Client Risk Profile',
    headerAlign: 'center',
    renderHeaderGroup: HeaderGroup,
    children: portfolioOptions.portfolios.map((portfolio: any) => ({
      field: getOptionKey(portfolioOptions.option, portfolio.risk_profile),
      headerName: portfolio.risk_profile,
      renderHeader: () => portfolio.risk_profile.toString(),
    })),
  }));

const getColumnWidth = (
  field: string,
  modelSetLength: number,
  slideMode: boolean,
) => {
  if (field === 'full_model_name' || field === 'strategy') {
    if (slideMode && modelSetLength >= 2) {
      return { minWidth: 200, flex: 1.85 };
    } else {
      return { minWidth: 200, flex: 1.4 };
    }
  } else if (field === 'profile') {
    return { minWidth: 70, flex: 0.3 };
  } else {
    return { minWidth: 60, flex: 0.3 };
  }
};

// rows helpers

export type ModelSetTableRow = {
  id: number | string;
  strategy: string;
  profile: number | null;
  rowClassName: string;
  order: number;
  [key: string]: number | string | null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRows = (modelSet: any): ModelSetTableRow[] => {
  const rows: any[] = [];

  // First collect all unique strategies
  const uniqueStrategies = new Set<string>();
  iterateModelSet(
    modelSet,
    (portfolioOptions: any, portfolio: any, holding: any) => {
      uniqueStrategies.add(
        JSON.stringify({
          strategy: holding.strategy,
          profile: holding.profile,
          category_role: holding.category_role,
        }),
      );
    },
  );

  // Create rows for each unique strategy
  uniqueStrategies.forEach(strategyJson => {
    const { strategy, profile, category_role } = JSON.parse(strategyJson);
    rows.push({
      id: rows.length,
      strategy,
      profile,
      rowClassName: `category-${getHoldingCategoryId(category_role)}`,
      order: getHoldingCategoryOrder(category_role),
    });
  });

  // Add weights for each option and risk profile
  iterateModelSet(
    modelSet,
    (portfolioOptions: any, portfolio: any, holding: any) => {
      const row = findRow(rows, holding.strategy, holding.profile)!;
      const weightKey = getOptionKey(
        portfolioOptions.option,
        portfolio.risk_profile,
      );

      // Set both normal and _prev values
      row[weightKey] = ensureWeight(holding.weight * 100);
      row[`${weightKey}_prev`] = ensureWeight(holding.weight * 100);
    },
  );

  // Ensure all rows have all options
  modelSet.portfolio_options.forEach((portfolioOptions: any) => {
    portfolioOptions.portfolios.forEach((portfolio: any) => {
      const weightKey = getOptionKey(
        portfolioOptions.option,
        portfolio.risk_profile,
      );
      rows.forEach(row => {
        if (row[weightKey] === undefined) {
          row[weightKey] = 0;
          row[`${weightKey}_prev`] = 0;
        }
      });
    });
  });

  return sortRows(rows) as ModelSetTableRow[];
};

const findRow = (rows: ModelSetTableRow[], strategy: string, profile: number) =>
  rows.find(row => row.strategy === strategy && row.profile === profile);

const sortRows = (rows: ModelSetTableRow[]) => {
  // sort rows by profile
  rows.sort((a, b) => (a.profile ?? Infinity) - (b.profile ?? Infinity));
  // sort rows by strategy
  rows.sort((a, b) => a.strategy.localeCompare(b.strategy));
  // sort rows by category role order
  rows.sort((a, b) => a.order - b.order);
  return rows;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTotalsRow = (rows: ModelSetTableRow[]) => {
  const totalsRow: ModelSetTableRow = {
    id: 'totals',
    strategy: '',
    profile: null,
    rowClassName: 'totals-row',
    order: Infinity,
  };

  mapRowsWeights(rows, (row: ModelSetTableRow, key: string) => {
    totalsRow[key] = Number(totalsRow[key]) || 0;
    totalsRow[key] = totalsRow[key] + (Number(row[key]) || 0);
    totalsRow[key] = parseFloat(totalsRow[key].toFixed(2));
  });

  return totalsRow;
};

// cell helpers

// Primero definimos la interfaz para los props de CustomEditCell
interface CustomEditCellProps extends GridRenderEditCellParams {
  isEditable?: boolean;
}

// Actualizamos el componente CustomEditCell
const CustomEditCell = (props: CustomEditCellProps) => {
  const { id, value = 0, field, isEditable = true } = props;
  const api = useGridApiContext();

  if (isEditable) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <input
          autoFocus
          type="number"
          min={0}
          max={100}
          value={formatWeight(value as number, id).replace('%', '')}
          onChange={ev => {
            const newValue =
              ev.target.value === '' ? 0 : ev.target.valueAsNumber;
            api.current.setEditCellValue({
              id,
              field,
              value: ensureWeight(newValue),
            });
          }}
          style={{
            width: '90%',
            height: '80%',
            border: '1px solid #ddd',
            borderRadius: '6px',
            backgroundColor: 'fff',
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 'bold',
            outline: 'none',
          }}
        />
      </Grid>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        cursor: 'not-allowed !important',
      }}
    >
      {formatWeight(value as number, id)}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCellClassName = (params: any) => {
  let className = '';
  if (params.id === 'totals') {
    className = 'total-row';
    const value = params.row[params.field];
    if (value !== 100) {
      className += ' total-error';
    }
  } else {
    if (params.field.startsWith('option')) {
      className += ' input-cell';
    }
    className += ` ${params.row.rowClassName}`;
  }

  if (params.row[params.field] !== undefined) {
    className += ' weight-cell';
  }

  return className;
};

// weights helpers

const ensureWeight = (value: number = 0, decimals: number = 2) => {
  const percentage = Math.max(0, Math.min(value, 100));
  return parseFloat(percentage.toFixed(decimals));
};

const mapRowsWeights = (rows: ModelSetTableRow[], predicate: any) => {
  return rows.map(row => {
    Object.keys(row).forEach(key => {
      if (key.startsWith('option') && !key.endsWith('_prev')) {
        predicate(row, key);
      }
    });
  });
};

// components

const HeaderGroup = (params: any) => (
  <Box sx={{ textAlign: 'center', width: '100%' }}>
    <Typography
      variant="subtitle2"
      component="div"
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {params.headerName}
    </Typography>
    <Typography variant="caption" component="div">
      {params.description}
    </Typography>
  </Box>
);

export const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  return {
    ...getCategoryClassNames(),
    borderTop: 'none',
    borderRight: 'none',
    '& *': {
      outline: 'none!important',
      cursor: 'default!important',
      userSelect: 'none',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
    },
    '& input': {
      userSelect: 'text',
      '-webkit-user-select': 'text',
      '-moz-user-select': 'text',
      '-ms-user-select': 'text',
    },
    '& .delete-button': {
      cursor: 'pointer!important',
      '&:hover': {
        cursor: 'pointer!important',
      },
    },
    '& svg': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      color: '#666666',
      borderBottom: 'none!important',
    },
    '& .MuiDataGrid-columnHeader': {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      '&[data-field^="option"]': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    '& .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiDataGrid-cell--editable.weight-cell': {
      boxShadow: 'none!important',
      padding: '0px!important',
      '&:hover': {
        backgroundColor: '#fff !important',
        fontWeight: 'bold',
      },
    },
    '& .profile-column, & .last-risk-profile-column': {
      borderRightWidth: '3px',
    },
    '& .total-row': {
      backgroundColor: '#f5f5f5 !important',
      fontWeight: 'bold',
      pointerEvents: 'none',
      '&.total-error': {
        color: '#f00 !important',
      },
    },
    '& .total-row[data-colindex="0"]': {
      borderRight: 'none!important',
    },
    '& input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },
    '& input[type="number"]': {
      MozAppearance: 'textfield',
    },
    '& .disabled-cell': {
      backgroundColor: '#f5f5f5',
      cursor: 'not-allowed!important',
      '&:hover': {
        backgroundColor: '#f5f5f5!important',
        cursor: 'not-allowed!important',
      },
    },
  };
});

const getCategoryClassNames = () =>
  holdingsCategories.reduce((css, category) => {
    css[`& .category-${category.id}`] = {
      backgroundColor: `${category.color} !important;`,
    };
    return css;
  }, {});

const getModelId = (row: any) => {
  return row.model_id;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialModelSet = (action: any, modelSetIndex: number) => {
  const originalModelSet = getModelSet(action, modelSetIndex);
  const fullModelSet = JSON.parse(JSON.stringify(originalModelSet));

  // Collect all unique strategies
  const allStrategies = new Set<string>();
  iterateModelSet(originalModelSet, (po: any, p: any, h: any) => {
    allStrategies.add(
      JSON.stringify({
        strategy: h.strategy,
        profile: h.profile,
        category_role: h.category_role,
        model_id: h.model_id,
      }),
    );
  });

  // Ensure all portfolio_options have all strategies
  fullModelSet?.portfolio_options?.forEach((portfolioOption: any) => {
    portfolioOption.portfolios?.forEach((portfolio: any) => {
      const existingStrategies = new Set(
        portfolio.holdings?.map((h: any) =>
          JSON.stringify({
            strategy: h.strategy,
            profile: h.profile,
            category_role: h.category_role,
            model_id: h.model_id,
          }),
        ),
      );

      allStrategies.forEach(strategyJson => {
        if (!existingStrategies.has(strategyJson)) {
          const strategy = JSON.parse(strategyJson);
          portfolio.holdings.push({
            strategy: strategy.strategy,
            profile: strategy.profile,
            category_role: strategy.category_role,
            weight: 0,
            model_id: strategy.model_id,
          });
        }
      });
    });
  });

  return fullModelSet;
};

// Agregar esta función helper cerca de los otros helpers
const formatWeight = (weight: number | undefined, id: GridRowId): string => {
  const numValue = isNaN(Number(weight)) ? 0 : Number(weight);
  if (id === 'totals') {
    return numValue === 0 ? '0%' : `${numValue}%`;
  } else {
    return numValue === 0 ? '' : `${numValue}%`;
  }
};
