import { Typography } from '@mui/material';
import { useSlideContext } from '../../context';

import React from 'react';

export interface SlideSubtitleProps {
  readonly children: React.ReactNode;
}

export function SlideSubtitle({
  children,
}: SlideSubtitleProps): React.ReactElement {
  const { theme } = useSlideContext();

  return (
    <Typography
      component="h2"
      sx={{
        color: theme.colors.primary,
        fontFamily: theme.fontFamily.headings,
        fontWeight: theme.fontWeight.bold,
        fontSize: theme.fontSize.md,
      }}
    >
      {children}
    </Typography>
  );
}
