import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/prop-types
const OktaSigninWidget = ({ config, onSuccess, onError }) => {
  const widgetRef = useRef<any>();
  useEffect(() => {
    const currentRef = widgetRef.current;
    const widget =
      currentRef && new OktaSignIn({ ...config, el: `#${currentRef.id}` });

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => {
      currentRef && widget.remove();
    };
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};
export default OktaSigninWidget;
