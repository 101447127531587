import React from 'react';

import { Dialog } from '@mui/material';
import CreateModelDialog from '@pages/ClientsV2/CreateModelDialog';

interface CustomModelUploadProps {
  openModelDialog: boolean;
  handleCloseModel: () => void;
  handleSuccesRefetch: () => void;
}

const CustomModelUpload: React.FC<CustomModelUploadProps> = ({
  handleCloseModel,
  handleSuccesRefetch,
  openModelDialog,
}) => {
  return (
    <Dialog
      open={openModelDialog}
      onClose={handleCloseModel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: {
            xs: '500px',
            md: '800px',
          },
        },
      }}
    >
      <CreateModelDialog
        handleCloseModel={handleCloseModel}
        handleSuccesRefetch={handleSuccesRefetch}
      />
    </Dialog>
  );
};

export default CustomModelUpload;
