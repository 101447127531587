import React, { useEffect, useState } from 'react';
import { Chip, Grid, styled } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  GetMarketplaceModelsParams,
  useGetMarketplaceModelsQuery,
} from '../../hooks/useGetMarketplaceModelsQuery';
import CustomModelsTable from '../../../Custom/components/CustomModelsTable';
import InputFilter from '@components/InputFilter';
import EmptyList from 'components/EmptyList';
import FavButton from '../FavButton';
import FilterMenu from '../FilterMenu';

interface MarketplaceModelListProps {
  isFavorites: boolean;
  handleClickEditModel: (id: string) => void;
}

const MarketplaceModelList: React.FC<MarketplaceModelListProps> = ({
  isFavorites,
  handleClickEditModel,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [sponsorsChecked, setSponsorsChecked] = useState<any[]>([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  const [queryParams, setQueryParams] = useState<GetMarketplaceModelsParams>({
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    favorites: isFavorites,
  });

  const {
    marketplaceModels,
    sponsorsCount,
    isMarketplaceModelsLoading,
    pagination,
  } = useGetMarketplaceModelsQuery(queryParams);

  useEffect(() => {
    setQueryParams(prev => ({ ...prev, search: searchText }));
  }, [searchText]);

  useEffect(() => {
    const sponsorIds = sponsorsChecked
      .filter(row => row.checked)
      .map(row => row.sponsorId)
      .join(',');
    setQueryParams(prev => ({ ...prev, sponsorIds: sponsorIds }));
  }, [sponsorsChecked]);

  const handleFilterSponsor = id => {
    sponsorsCount.map(row => {
      if (row.sponsorId === id) {
        row.checked = !row.checked;
        const exists = sponsorsChecked.some(
          sponsor => sponsor.sponsorId === row.sponsorId,
        );
        if (exists) {
          setSponsorsChecked(prev =>
            prev.filter(sponsor => sponsor.sponsorId !== row.sponsorId),
          );
        } else {
          setSponsorsChecked(prev => [...prev, { ...row }]);
        }
      }
    });
  };

  const renderEmptyList = () => {
    if (marketplaceModels.length === 0 && searchText !== '') {
      return (
        <EmptyList
          loading={isMarketplaceModelsLoading}
          title="There are no results that match these filters"
          buttons={[
            {
              content: 'Clear all filters',
              handleClick: () => {
                setSearchText('');
                setSponsorsChecked([]);
              },
            },
          ]}
        />
      );
    } else if (marketplaceModels.length === 0) {
      return (
        <EmptyList
          loading={isMarketplaceModelsLoading}
          title="It seems that there are no models yet"
        />
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Model name',
      display: 'flex',
      flex: 3,
      editable: false,
    },
    {
      field: 'sponsorName',
      headerName: 'Model Sponsor',
      hideSortIcons: true,
      sortable: false,
      display: 'flex',
      flex: 2,
      editable: false,
    },
    {
      field: 'seriesName',
      headerName: 'Series Name',
      hideSortIcons: true,
      sortable: false,
      display: 'flex',
      flex: 2,
      editable: false,
    },
    {
      field: 'updatedAt',
      type: 'date',
      headerName: 'Updated',
      hideSortIcons: true,
      sortable: false,
      display: 'flex',
      flex: 2,
      editable: false,
    },

    {
      field: 'favorite',
      headerName: '',
      sortable: false,
      hideSortIcons: true,
      display: 'flex',
      flex: 0,
      align: 'center',
      renderCell: params => <ActionButtons params={params} />,
    },
  ];

  const ActionButtons: React.FC<{ params: any }> = ({ params }) => (
    <FavButton
      id={params.row.id}
      isFavorite={params.row.favorite}
      name={params.row.name}
    />
  );

  return (
    <Grid
      container
      wrap="wrap"
      rowGap={2}
      direction="column"
      padding={{ xs: '10px', sm: '20px' }}
      sx={{ background: '#fff', borderRadius: '4px' }}
    >
      <Grid container justifyContent="flex-start" alignItems="center" gap={1}>
        <Grid item flexGrow={1}>
          <InputFilter
            placeholder="Search for Model name"
            value={searchText}
            debounceTime={500}
            handleInputChange={setSearchText}
          />
        </Grid>

        <FilterMenu
          activeChecks={sponsorsChecked}
          rows={sponsorsCount}
          handleFilter={handleFilterSponsor}
        />
      </Grid>
      <Grid item sx={{ width: '100%' }} hidden={sponsorsChecked.length === 0}>
        {sponsorsChecked.map(
          item =>
            item.checked && (
              <ChipSponsor
                label={item.sponsorName}
                key={item.sponsorId}
                onDelete={() => handleFilterSponsor(item.sponsorId)}
              />
            ),
        )}
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        {renderEmptyList()}

        <div
          hidden={isMarketplaceModelsLoading || marketplaceModels.length === 0}
        >
          <CustomModelsTable
            rows={marketplaceModels || []}
            columns={columns}
            handleRowClick={handleClickEditModel}
            getRowId={row => row.id}
            sortModel={
              queryParams.orderBy
                ? [{ field: queryParams.orderBy, sort: queryParams.order }]
                : []
            }
            onSortModelChange={newSortModel => {
              if (newSortModel.length === 0) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { orderBy, order, ...restParams } = queryParams;
                setQueryParams(restParams);
                return;
              }
              setQueryParams({
                ...queryParams,
                orderBy: newSortModel[0].field as 'name' | 'updatedAt',
                order: newSortModel[0].sort as 'asc' | 'desc',
              });
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel({
                page: newPaginationModel.page,
                pageSize: newPaginationModel.pageSize,
              });

              setQueryParams({
                ...queryParams,
                page: newPaginationModel.page + 1,
                limit: newPaginationModel.pageSize,
              });
            }}
            paginationMeta={{
              rowCount: pagination.totalCount,
              totalPages: pagination.totalPages,
              currentPage: pagination.currentPage,
              hasNextPage:
                pagination.currentPage < pagination.totalPages &&
                pagination.totalCount > 0,
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default MarketplaceModelList;

const ChipSponsor = styled(Chip)`
  margin-right: 8px;
  background: #535256;
  svg {
    fill: #fff;
  }
  color: #fff;
  &:hover {
    background: #000;
  }
`;
