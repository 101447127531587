import React from 'react';

import BgImage from '../../../assets/assetmark/bg-image.png';
import Logo from '../../../assets/assetmark/logo-white.png';

import { EditableField } from '../editable-field';
import styles from './styles.module.css';

export type CoverSlideTemplateState = {
  title: string;
  subtitle1: string;
  subtitle2: string;
  date: Date;
};

export type CoverSlideTemplateProps = {
  readonly state: CoverSlideTemplateState;
  readonly setState: (state: CoverSlideTemplateState) => void;
  readonly editable?: boolean;
};

export function CoverSlideTemplate({
  state,
  setState,
  editable = true,
}: CoverSlideTemplateProps): React.ReactNode {
  return (
    <div
      className={styles.slide}
      style={{ backgroundImage: `url(${BgImage})` }}
    >
      <img className={styles.logo} src={Logo} alt="AMK Logo" />
      <div className={styles.title}>
        <EditableField
          field="title"
          state={state}
          setState={setState}
          editable={editable}
        />
      </div>
      <div className={styles.subtitleContainer}>
        <div className={styles.subtitle1}>
          <EditableField
            field="subtitle1"
            state={state}
            setState={setState}
            editable={editable}
          />
        </div>
      </div>
      <div className={styles.subtitle2}>
        <EditableField
          field="subtitle2"
          state={state}
          setState={setState}
          editable={editable}
        />
      </div>
      <div className={styles.date}>{state.date.toLocaleDateString()}</div>
    </div>
  );
}
