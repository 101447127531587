import styled from 'styled-components';

export const SortMenuContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  font-size: 14px;

  // sort icon

  & .sort-icon {
    height: 16px;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  & .sort-icon:hover {
    background-color: #e5e5e5;
  }

  // placeholder

  & .placeholder {
    display: inline-block;
    padding: 0 8px;
  }

  // arrow icon

  & .arrow-icon {
    height: 22px;
    border-radius: 4px;
    font-size: 20px;
    color: '#535256';
    cursor: pointer;
    transition: background-color 0.3s;
  }

  & .arrow-icon:hover {
    background-color: #e5e5e5;
  }
`;
