import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DataGrid,
  type GridColDef,
  type GridRenderEditCellParams,
  type GridValueFormatter,
  useGridApiContext,
} from '@mui/x-data-grid';
import theme from '@styles/theme';
import {
  getHoldingCategoryId,
  getHoldingCategoryOrder,
  getModelSetIndexByName,
  holdingsCategories,
} from 'pages/Cases/helpers/misc';
import { CaseMilestoneAction } from 'pages/Cases/hooks/useGetCases';
import React, { useEffect, useState } from 'react';

type ModelSetsTableProps = {
  action: CaseMilestoneAction;
  isDraft?: boolean;
  modelSetName: string;
  slideMode?: boolean;
  groupHeaderHeight?: number;
  columnHeaderHeight?: number;
  rowHeight?: number;
  maxHeight?: number;
  fontSize?: number;
};

// main component

const ModelSetsTable = ({
  action,
  modelSetName,
  slideMode = false,
  groupHeaderHeight = 56,
  columnHeaderHeight = 32,
  rowHeight = 36,
  fontSize = 14,
}: ModelSetsTableProps): JSX.Element => {
  const modelSetIndex = getModelSetIndexByName(modelSetName, action);
  const [modelSet] = useState<any>(() =>
    getInitialModelSet(action, modelSetIndex),
  );
  const [modelSetDraft] = useState<any>(() =>
    JSON.parse(JSON.stringify(modelSet)),
  );
  const [rows, setRows] = useState<ModelSetTableRow[]>([]);

  useEffect(() => {
    setRows(getRows(modelSetDraft));
  }, [modelSetDraft]);

  const getColumns = (modelSet: any) => {
    const baseColumns: GridColDef[] = [
      {
        field: 'strategy',
        headerName: 'Strategist',
        sortable: false,
        headerClassName: 'base-columns-header',
        editable: false,
        headerAlign: 'left' as const,
        ...getColumnWidth('strategy'),
        renderCell: params => {
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                position: 'relative',
                '&:hover .delete-button': {
                  opacity: 1,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: fontSize,
                  whiteSpace: 'normal',
                  lineHeight: 1.2,
                  overflow: 'hidden',
                  textAlign: 'left',
                }}
              >
                {params.value}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'profile',
        headerName: 'Profile',
        sortable: false,
        headerClassName: 'base-columns-header',
        headerAlign: 'center' as const,
        align: 'center' as const,
        editable: false,
        type: 'number' as const,
        cellClassName: 'profile-column',
        ...getColumnWidth('profile'),
      },
    ];

    const optionColumns: GridColDef[] = [];

    modelSet.portfolio_options.forEach((portfolioOptions: any) => {
      portfolioOptions.portfolios.forEach(
        (portfolio: any, index: number, portfolios: any[]) => {
          optionColumns.push({
            field: getOptionKey(
              portfolioOptions.option,
              portfolio.risk_profile,
            ),
            headerName: portfolioOptions.option,
            sortable: false,
            headerAlign: 'center' as const,
            align: 'center' as const,
            editable: false,
            type: 'number' as const,
            cellClassName:
              index === portfolios.length - 1 ? 'last-risk-profile-column' : '',
            ...getColumnWidth(''),
            renderHeader: () => portfolio.risk_profile,
            renderEditCell: (params: GridRenderEditCellParams) => {
              if (slideMode) {
                return params.formattedValue;
              } else if (params.row[params.field] !== undefined) {
                return <CustomEditCell {...params} fontSize={fontSize} />;
              } else {
                return null;
              }
            },
            valueFormatter: (weight: GridValueFormatter) =>
              weight === undefined || Number(weight) === 0
                ? ''
                : `${Number(weight)}%`,
            preProcessEditCellProps: (params: any) => {
              const value = params.props.value;
              const hasError = isNaN(value) || value < 0 || value > 100;
              return { ...params.props, error: hasError };
            },
          });
        },
      );
    });

    return [...baseColumns, ...optionColumns];
  };

  return (
    <Grid item xs={12} sx={{ paddingBottom: '16px' }}>
      <Box sx={{ width: '100%' }}>
        <StyledDataGrid
          rows={[...rows]}
          columns={getColumns(modelSet)}
          columnGroupingModel={getColumnGroupingModel(modelSet)}
          columnGroupHeaderHeight={groupHeaderHeight}
          columnHeaderHeight={columnHeaderHeight}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          hideFooter
          autoHeight
          sx={{
            overflowY: 'hidden',
            '& .MuiDataGrid-cell': {
              fontSize: fontSize,
              borderRight: `1px solid ${theme.palette.divider}`,
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
            '& .MuiDataGrid-row': {
              alignItems: 'stretch',
            },
            '& .MuiDataGrid-renderingZone': {
              '& .MuiDataGrid-row': {
                alignItems: 'stretch',
              },
            },
            '& .MuiDataGrid-columnHeaders': {
              height: 'auto !important',
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-columnHeader': {
              height: 'auto !important',
              maxHeight: 'none !important',
              fontSize: fontSize,
            },
            '& .MuiDataGrid-columnGroupHeader': {
              height: '100%',
              fontSize: fontSize,
            },
            '& .MuiDataGrid-columnGroup': {
              height: '100%',
            },
            '& .MuiDataGrid-virtualScroller': {
              overflow: 'hidden !important',
              overflowY: 'hidden !important',
              overflowX: 'hidden !important',
            },
            '& .MuiDataGrid-main': {
              overflow: 'hidden !important',
              overflowY: 'hidden !important',
              overflowX: 'hidden !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              overflow: 'hidden !important',
            },
            '& .MuiDataGrid-virtualScrollerRenderZone': {
              overflow: 'hidden !important',
            },
            '& .MuiDataGrid-footerContainer': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              fontSize: fontSize,
            },

            '& .MuiTypography-caption': {
              fontSize: fontSize,
            },
          }}
          rowHeight={rowHeight}
          getCellClassName={params => `${getCellClassName(params)}}`}
        />
      </Box>
    </Grid>
  );
};

export default ModelSetsTable;

// keys helpers

const getOptionKey = (option: number, riskProfile: number) =>
  `option_${option}_riskprofile_${riskProfile}`;

// model set helpers

const getModelSet = (action: any, modelSetIndex: number) =>
  action?.value?.model_set_holdings[modelSetIndex];

const iterateModelSet = (modelSet: any, predicate: any) => {
  modelSet?.portfolio_options.forEach((portfolioOptions: any) => {
    portfolioOptions.portfolios.forEach((portfolio: any) => {
      portfolio.holdings.forEach((holding: any) => {
        predicate(portfolioOptions, portfolio, holding);
      });
    });
  });
};

// column helpers

const getColumnGroupingModel = (modelSet: any) =>
  modelSet?.portfolio_options.map((portfolioOptions: any) => ({
    groupId: `Option${portfolioOptions.option}`,
    description: 'Client Risk Profile',
    headerAlign: 'center',
    renderHeaderGroup: HeaderGroup,
    children: portfolioOptions.portfolios.map((portfolio: any) => ({
      field: getOptionKey(portfolioOptions.option, portfolio.risk_profile),
      headerName: portfolio.risk_profile,
      renderHeader: () => portfolio.risk_profile.toString(),
    })),
  }));

const getColumnWidth = (field: string) => {
  if (field === 'full_model_name' || field === 'strategy') {
    return { minWidth: 100, flex: 1.5 };
  } else if (field === 'profile') {
    return { minWidth: 65, flex: 0.4 };
  } else {
    return {
      minWidth: 40,
      flex: 0.4,
      align: 'center' as const,
      justify: 'center' as const,
    };
  }
};

// rows helpers

type ModelSetTableRow = {
  id: number | string;
  strategy: string;
  profile: number | null;
  rowClassName: string;
  order: number;
  [key: string]: number | string | null;
};

const getRows = (modelSet: any): ModelSetTableRow[] => {
  const rows: any[] = [];

  // First collect all unique strategies
  const uniqueStrategies = new Set<string>();
  iterateModelSet(
    modelSet,
    (portfolioOptions: any, portfolio: any, holding: any) => {
      uniqueStrategies.add(
        JSON.stringify({
          strategy: holding.strategy,
          profile: holding.profile,
          category_role: holding.category_role,
        }),
      );
    },
  );

  // Create rows for each unique strategy
  uniqueStrategies.forEach(strategyJson => {
    const { strategy, profile, category_role } = JSON.parse(strategyJson);
    rows.push({
      id: rows.length,
      strategy,
      profile,
      rowClassName: `category-${getHoldingCategoryId(category_role)}`,
      order: getHoldingCategoryOrder(category_role),
    });
  });

  // Add weights for each option and risk profile
  iterateModelSet(
    modelSet,
    (portfolioOptions: any, portfolio: any, holding: any) => {
      const row = findRow(rows, holding.strategy, holding.profile)!;
      const weightKey = getOptionKey(
        portfolioOptions.option,
        portfolio.risk_profile,
      );

      // Set both normal and _prev values
      row[weightKey] = ensureWeight(holding.weight * 100);
      row[`${weightKey}_prev`] = ensureWeight(holding.weight * 100);
    },
  );

  // Ensure all rows have all options
  modelSet.portfolio_options.forEach((portfolioOptions: any) => {
    portfolioOptions.portfolios.forEach((portfolio: any) => {
      const weightKey = getOptionKey(
        portfolioOptions.option,
        portfolio.risk_profile,
      );
      rows.forEach(row => {
        if (row[weightKey] === undefined) {
          row[weightKey] = 0;
          row[`${weightKey}_prev`] = 0;
        }
      });
    });
  });

  return sortRows(rows) as ModelSetTableRow[];
};

const findRow = (rows: ModelSetTableRow[], strategy: string, profile: number) =>
  rows.find(row => row.strategy === strategy && row.profile === profile);

const sortRows = (rows: ModelSetTableRow[]) => {
  // sort rows by profile
  rows.sort((a, b) => (a.profile ?? Infinity) - (b.profile ?? Infinity));
  // sort rows by strategy
  rows.sort((a, b) => a.strategy.localeCompare(b.strategy));
  // sort rows by category role order
  rows.sort((a, b) => a.order - b.order);
  return rows;
};

// cell helpers

const CustomEditCell = (props: GridRenderEditCellParams) => {
  const { id, value, field, fontSize } = props;
  const api = useGridApiContext();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input
        autoFocus
        type="number"
        min={0}
        max={100}
        value={formatWeight(value as number).replace('%', '')}
        onChange={ev => {
          const newValue = ev.target.value === '' ? 0 : ev.target.valueAsNumber;
          api.current.setEditCellValue({
            id,
            field,
            value: ensureWeight(newValue),
          });
        }}
        style={{
          width: '55px',
          padding: '7px 4px 5px 4px',
          border: '1px solid #ddd',
          borderRadius: '6px',
          backgroundColor: '#fff',
          textAlign: 'center',
          fontSize: fontSize,
          fontWeight: 'bold',
          outline: 'none',
          fontFamily: 'Roboto',
        }}
      />
    </Box>
  );
};

const getCellClassName = (params: any) => {
  let className = '';
  if (params.id === 'totals') {
    className = 'total-row';
    const value = params.row[params.field];
    if (value !== 100) {
      className += ' total-error';
    }
  } else {
    if (params.field.startsWith('option')) {
      className += ' input-cell';
    }
    className += ` ${params.row.rowClassName}`;
  }

  if (params.row[params.field] !== undefined) {
    className += ' weight-cell';
  }

  return className;
};

// weights helpers

const ensureWeight = (value: number = 0, decimals: number = 2) => {
  const percentage = Math.max(0, Math.min(value, 100));
  return parseFloat(percentage.toFixed(decimals));
};

// components

const HeaderGroup = (params: any, fontSize: number) => (
  <Box
    sx={{
      textAlign: 'center',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
    }}
  >
    <Typography
      variant="caption"
      component="div"
      sx={{
        textAlign: 'center',
        fontSize: fontSize,
      }}
    >
      {params.description}
    </Typography>
  </Box>
);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  ...getCategoryClassNames(),
  borderTop: 'none',
  borderRight: 'none',
  overflow: 'hidden',
  '& *': {
    outline: 'none!important',
    cursor: 'default!important',
  },
  '& svg': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    color: '#666666',
  },
  '& .MuiDataGrid-columnHeader': {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,

    '&[data-field^="option"]': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.divider}`,
    whiteSpace: 'normal',
    lineHeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  '& .MuiDataGrid-row': {
    alignItems: 'stretch',
  },
  '& .MuiDataGrid-renderingZone': {
    '& .MuiDataGrid-row': {
      alignItems: 'center',
    },
  },
  '& .profile-column, & .last-risk-profile-column': {
    borderRightWidth: '3px',
  },
  '& input[type="number"]': {
    MozAppearance: 'textfield',
    textAlign: 'center',
  },
}));

const getCategoryClassNames = () =>
  holdingsCategories.reduce((css, category) => {
    css[`& .category-${category.id}`] = {
      backgroundColor: `${category.color} !important;`,
    };
    return css;
  }, {});

const getInitialModelSet = (action: any, modelSetIndex: number) => {
  const originalModelSet = getModelSet(action, modelSetIndex);
  const fullModelSet = JSON.parse(JSON.stringify(originalModelSet));

  // Collect all unique strategies
  const allStrategies = new Set<string>();
  iterateModelSet(originalModelSet, (po: any, p: any, h: any) => {
    allStrategies.add(
      JSON.stringify({
        strategy: h.strategy,
        profile: h.profile,
        category_role: h.category_role,
        model_id: h.model_id,
      }),
    );
  });

  // Ensure all portfolio_options have all strategies
  fullModelSet?.portfolio_options?.forEach((portfolioOption: any) => {
    portfolioOption.portfolios?.forEach((portfolio: any) => {
      const existingStrategies = new Set(
        portfolio.holdings?.map((h: any) =>
          JSON.stringify({
            strategy: h.strategy,
            profile: h.profile,
            category_role: h.category_role,
            model_id: h.model_id,
          }),
        ),
      );

      allStrategies.forEach(strategyJson => {
        if (!existingStrategies.has(strategyJson)) {
          const strategy = JSON.parse(strategyJson);
          portfolio.holdings.push({
            strategy: strategy.strategy,
            profile: strategy.profile,
            category_role: strategy.category_role,
            weight: 0,
            model_id: strategy.model_id,
          });
        }
      });
    });
  });

  return fullModelSet;
};

const formatWeight = (weight: number | undefined): string => {
  return weight === undefined || Number(weight) === 0
    ? ''
    : `${Number(weight)}%`;
};
