import React, { useState } from 'react';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import {
  Stack,
  IconButton,
  TextField,
  inputBaseClasses,
  InputAdornment,
} from '@mui/material';
import DeleteIcon from '../../../../../../static/icons/Delete';
import { Model } from 'pages/Models/types';

interface TickerTableProps {
  holdings: Model['holdings'];
  handleDeleteHolding: (id: number) => void;
  handleEditHoldingWeight: (id: number, weight: number) => void;
}

const TickerTable: React.FC<TickerTableProps> = ({
  holdings,
  handleDeleteHolding,
  handleEditHoldingWeight,
}) => {
  const ActionsCell: React.FC = (holdind: any) => {
    const handleDelete = () => {
      handleDeleteHolding(holdind.id);
    };

    return (
      <Stack direction="row" gap={1} alignItems="center">
        <IconButton
          aria-label="delete"
          size="small"
          onClick={handleDelete}
          sx={{ color: '#4F4F4F' }}
        >
          <DeleteIcon width={16} height={16} />
        </IconButton>
      </Stack>
    );
  };

  const EditCell: React.FC = (holdind: any) => {
    const [fieldValue, setFieldValue] = useState(
      Number((holdind.value * 100).toFixed(4)),
    );

    const handleInputChange = ev => {
      setFieldValue(ev.currentTarget.value);
      let value = parseFloat(ev.currentTarget.value);

      if (!isNaN(value)) {
        setFieldValue(value);
        value = Math.min(100, Math.max(0, value));
        value = Number((value / 100).toFixed(4));
        handleEditHoldingWeight(holdind.id, value);
      } else {
        handleEditHoldingWeight(holdind.id, 0);
      }
    };

    return (
      <Stack direction="row" gap={1} alignItems="center">
        <TextField
          type="number"
          sx={{
            [`& .${inputBaseClasses.input}`]: {
              padding: '5px',
            },
          }}
          value={fieldValue}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Security',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      display: 'flex',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      valueParser: (value: string) => {
        const parsed = parseFloat(value);
        return isNaN(parsed) ? 0 : Math.min(100, Math.max(0, parsed));
      },
      renderCell: EditCell,
    },
    {
      field: 'actions',
      headerName: '',
      display: 'flex',
      renderCell: ActionsCell,
      width: 20,
    },
  ];

  return (
    <DataGrid
      rows={holdings.map(holding => ({
        id: holding.id,
        name: holding.instrument.name,
        weight: holding.weight,
      }))}
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSelector
      disableMultipleRowSelection
      disableColumnResize={true}
      hideFooter
      sx={{
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
          {
            outline: 'none',
          },
      }}
    />
  );
};

export default TickerTable;
