import React from 'react';
import { useNavigate } from 'react-router-dom';
import PrompQuestions from '@components/PrompQuestions';
import { useGetCaseById } from 'pages/Cases/hooks/useGetCaseById';

type UploadConsultFormNavigatorProps = {
  caseId: string;
};

const UploadConsultFormNavigator = ({
  caseId,
}: UploadConsultFormNavigatorProps): React.ReactNode => {
  const navigate = useNavigate();

  const { caseData } = useGetCaseById({ id: caseId });

  const handleNavigate = () => {
    const lastActionId = getLastPortfolioConstructionId(caseData);
    if (lastActionId) {
      navigate(
        `/cases/${caseId}/portfolio-constructions/${lastActionId}/summary`,
      );
    } else {
      navigate(`/cases/all-cases?caseId=${caseId}`);
    }
  };

  return (
    <PrompQuestions
      showDividers
      itemPy="10px"
      questions={['Navigate to Model Sets']}
      handleQuestionClick={handleNavigate}
    />
  );
};

export default UploadConsultFormNavigator;

const getLastPortfolioConstructionId = (caseData: any): string => {
  const milestone = caseData?.milestones?.find(
    milestone => milestone.type === 'portfolio_construction',
  );

  if (!milestone) {
    return '';
  }

  const sortedActions = milestone!.actions.sort(
    (a: any, b: any) => b.createdAt - a.createdAt,
  );
  return sortedActions[0]?.id;
};
