import React from 'react';
import styles from './styles.module.css';
import { EditableField } from '../../components/EditableField';
import Logo from './AMKLogoWhite.png';
import BgImage from './AMKImage.png';
import { type Slide } from '../../_data';
import { getSlideField } from '../../utils';
export type Slide1ContentType = [
  {
    field: 'title';
    label: string;
    value: string;
    type: 'text';
  },
  {
    field: 'subtitle1';
    label: string;
    value: string;
    type: 'text';
  },
  {
    field: 'subtitle2';
    label: string;
    value: string;
    type: 'text';
  },
  {
    field: 'date';
    label: string;
    value: string;
    type: 'text';
  },
];

export const Slide1Content = React.memo(
  ({
    slide,
    onChangeSlide,
  }: {
    slide: Slide<Slide1ContentType>;
    onChangeSlide: (
      slide: Slide<Slide1ContentType>,
      content: Slide1ContentType,
    ) => void;
  }): React.ReactElement => {
    return (
      <div
        className={styles.slide}
        style={{ backgroundImage: `url(${BgImage})` }}
      >
        <img className={styles.logo} src={Logo} alt="AMK Logo" />
        <div className={styles.title}>
          {getSlideField(slide, 'title')?.value}
        </div>
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitle1}>
            <EditableField
              field={'subtitle1'}
              slide={slide}
              onSlideContentChange={onChangeSlide}
              type="simple"
            />
          </div>
          <div className={styles.subtitle2}>
            {getSlideField(slide, 'subtitle2')?.value}
          </div>
        </div>
        <div className={styles.date}>
          <EditableField
            field={'date'}
            slide={slide}
            onSlideContentChange={onChangeSlide}
            type="simple"
          />
        </div>
      </div>
    );
  },
);

export default Slide1Content;
