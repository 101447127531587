import { useMutation } from 'react-query';
import { useFetchMutation } from '../../../helpers/api';

type UsePostPdfToS3Arguments = {
  pdf: File;
  caseId: string;
  actions: string;
  enabled: boolean;
  successToastMessage: string;
};

type UsePostPdfToS3Result = {
  postPdfToS3Mutate: (data: UsePostPdfToS3Arguments) => void;
};

export const usePostPdfToS3 = (): UsePostPdfToS3Result => {
  const { fetchMutation } = useFetchMutation();

  const { mutate } = useMutation((data: UsePostPdfToS3Arguments) => {
    const formData = new FormData();
    formData.append('file', data.pdf);

    return fetchMutation({
      path: `/v2/cases/${data.caseId}/milestones/actions/performanceReport/upload`,
      method: 'POST',
      body: formData,
      contentType:
        'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
      invalidateQueries: [['cases']],
      successToastMessage: data.successToastMessage,
    });
  });

  return { postPdfToS3Mutate: mutate };
};
