export const strIncludes = (str: string, substr: string): boolean =>
  str.toLowerCase().includes(substr.toLowerCase());

export const textareaToHtml = (text: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/g;
  const breakLineRegex = /(?:\n)/g;

  return text
    .replace(
      urlRegex,
      url =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`,
    )
    .replace(emailRegex, email => `<a href="mailto:${email}">${email}</a>`)
    .replace(breakLineRegex, '<br />');
};
