import React, { lazy, Suspense } from 'react';

import { Drawer, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgressBar from 'components/CircularProgressBar';

const PdfModule = lazy(() => import('remoteApp/Proposals'));

interface ProposalViewProps {
  proposalId: string;
  contentList: any[];
  isDrawerOpen: boolean;
  handleCloseDrawer: () => void;
}

const ProposalView: React.FC<ProposalViewProps> = ({
  contentList,
  proposalId,
  isDrawerOpen,
  handleCloseDrawer,
}) => {
  const renderLoading = () => {
    return (
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ flexGrow: 1, height: '100%' }}
      >
        <CircularProgressBar text="Please wait while we generate a proposal for you." />
      </Stack>
    );
  };

  return (
    <DrawerCustom variant="persistent" anchor="right" open={isDrawerOpen}>
      <DrawerHeader sx={{ borderBottom: '1px solid #f1f1f1' }}>
        <IconButton onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>

      <DrawerContent>
        {proposalId === '' ? (
          renderLoading()
        ) : (
          <Suspense fallback={renderLoading()}>
            <PdfModule
              showDownloadButton={false}
              sageProposalId={proposalId}
              pageList={contentList}
            />
          </Suspense>
        )}
      </DrawerContent>
    </DrawerCustom>
  );
};

export default ProposalView;

export const DrawerCustom = styled(Drawer)({
  variants: 'persistent',
  '& .MuiDrawer-paper': {
    width: '830px',
    '@media (max-width: 830px)': {
      width: '100%',
    },
  },
});

const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  justifyContent: 'flex-start',
});

const DrawerContent = styled('div')({
  height: 'calc(100% - 81px)',
  overflowY: 'auto',
});
