import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CaseMilestoneAction } from 'pages/Cases/hooks/useGetCases';
import { usePostCaseAction } from 'pages/Cases/hooks/usePostCaseAction';
import ModalButton from 'components/ModalButton';
import { useLocation, useNavigate } from 'react-router-dom';

type SummarySaveAsNewVersionProps = {
  action: CaseMilestoneAction;
  caseId: string;
  isOpen: boolean;
  handleClose: () => void;
};

const SummarySaveAsNewVersion = ({
  action,
  caseId,
  isOpen,
  handleClose,
}: SummarySaveAsNewVersionProps): React.ReactNode => {
  const [versionName, setVersionName] = useState('');
  const { postCaseActionMutate, isSuccess, data } = usePostCaseAction();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = () => {
    if (versionName) {
      postCaseActionMutate({
        caseId,
        milestoneId: action.milestoneId,
        versionName,
        value: action.value,
        isDraft: true,
        successToastMessage: 'New version saved successfully',
      });

      handleClose();
    }
  };
  useEffect(() => {
    if (isSuccess && data?.data?.actionId) {
      const newPath = `/cases/${caseId}/portfolio-constructions/${data.data.actionId}/summary${location.search}`;
      navigate(newPath, { replace: true });
    }
  }, [isSuccess, data, caseId, location.search]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={theme => ({
          width: 500,
          bgcolor: '#fff',
          boxShadow: theme.shadows[5],
          borderRadius: '4px',
        })}
      >
        <Grid
          item
          container
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          p="8px 8px 8px 16px"
        >
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
            Save as new version
          </Typography>
          <Button
            style={{
              minWidth: 'unset',
              padding: '5px',
              marginLeft: 'auto',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Grid>

        <Grid
          item
          container
          px={2}
          sx={{ border: '16px solid #f8f8f8', padding: '16px' }}
        >
          <InputLabel
            htmlFor="versionName"
            sx={{ fontSize: '13px', fontWeight: 400 }}
          >
            Version name
          </InputLabel>

          <TextField
            fullWidth
            id="versionName"
            size="small"
            style={{ paddingTop: '5px' }}
            value={versionName}
            onChange={ev => setVersionName(ev.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          p={2}
          gap={1}
        >
          <ModalButton
            label="Cancel"
            variant="secondary"
            handleClick={handleClose}
          />
          <ModalButton label="Save version" handleClick={handleSubmit} />
        </Grid>
      </Box>
    </Modal>
  );
};

export default SummarySaveAsNewVersion;
