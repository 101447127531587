import { getClients } from './get-clients';
import { getEnterpriseQuestions } from './get-enterprise-questions';
import { putClientNextBestActions } from './put-client-next-best-actions';

export const handlers = [
  { enabled: true, handler: getClients },
  { enabled: true, handler: getEnterpriseQuestions },
  { enabled: true, handler: putClientNextBestActions },
]
  .filter(({ enabled }) => enabled)
  .map(({ handler }) => handler);
