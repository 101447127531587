import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CustomModels from './Custom';
import CustomModelId from './Custom/Id';
import TabsLayout from '../../components/TabsLayout/TabsLayout';

const ModelsPage: React.FC = () => {
  const tabs = [
    {
      label: 'Custom Models',
      path: 'custom-models',
      validPatterns: ['/models/custom-models/:id'],
    },
  ];

  return (
    <Routes>
      <Route element={<TabsLayout tabs={tabs} />}>
        <Route index element={<Navigate to="custom-models" replace />} />
        <Route path="/custom-models" element={<CustomModels />} />
        <Route path="/custom-models/:id" element={<CustomModelId />} />
      </Route>
    </Routes>
  );
};

export default ModelsPage;
