// import { Grid } from '@mui/material';
import styled from 'styled-components';

export const DataBannerContainer = styled.div`
  margin-bottom: 6px;
  padding: 16px;
  background: #f5f5f5;
`;

export const DataBannerTitle = styled.h3`
  margin: 0 0 6px 0;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #4f4f4f;
`;

export const DataBannerMainNumber = styled.p`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #000;
`;
