import { Grid, Typography } from '@mui/material';
import { SlideSubtitle, SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import { ModelSetPerformance } from 'features/premium-report/api/types';
import {
  filterPerformance,
  portfolioStatisticsChartFilter,
} from 'pages/Cases/PortfolioConstruction/Summary/PerformanceReport';
import { PortfolioStatisticsLineChart } from 'pages/Cases/components/PortfolioStatisticsLineChart';
import { calculateDateRange } from 'features/premium-report/utils';

export function RiskAnalysis({
  modelSetPerformance,
}: {
  modelSetPerformance: ModelSetPerformance[];
}): React.ReactElement {
  const filteredPerformance = filterPerformance(modelSetPerformance, [], [], 3);

  // Verificar si hay datos filtrados antes de renderizar
  if (!filteredPerformance || filteredPerformance.length === 0) {
    return (
      <Grid
        container
        direction="column"
        spacing={2}
        className={styles.container}
      >
        <Grid item>
          <SlideTitle>Risk Analysis</SlideTitle>
        </Grid>
        <Grid item>
          <Typography>No data available for risk analysis</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" spacing={2} className={styles.container}>
      <Grid item>
        <SlideTitle>Risk Analysis</SlideTitle>
      </Grid>
      <Grid item>
        <Typography component="p" className={styles.paragraph}>
          <b>Model vs Benchmark: Standard Deviation</b>
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          {calculateDateRange(
            filteredPerformance,
            'rolling_three_year_stats',
            '3_year_rolling_returns',
            '36',
          )}
        </Typography>
        <Grid item>
          <PortfolioStatisticsLineChart
            data={portfolioStatisticsChartFilter({
              filteredPerformance: filteredPerformance,
              name: 'rolling_three_year_stats',
              subName: '3_year_rolling_returns',
            })}
            height={218}
            fontSize={12}
            formatChart={{
              unit: '%',
              decimals: 2,
              prefix: '',
              value: 'Standard Deviation',
              label: 'Standard Deviation',
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography component="p" className={styles.paragraph}>
          <b>Model vs Benchmark: Maximum Drawdown</b>
        </Typography>
        <Typography component="p" className={styles.paragraph}>
          {calculateDateRange(
            filteredPerformance,
            'rolling_three_year_stats',
            '3_year_max_drawdown',
            '36',
          )}
        </Typography>
        <Grid item>
          <PortfolioStatisticsLineChart
            data={portfolioStatisticsChartFilter({
              filteredPerformance: filteredPerformance,
              name: 'rolling_three_year_stats',
              subName: '3_year_max_drawdown',
            })}
            height={218}
            fontSize={12}
            formatChart={{
              unit: '%',
              decimals: 2,
              prefix: '',
              value: 'Maximum Drawdown',
              label: 'Maximum Drawdown',
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <SlideSubtitle>Rolling 3-year period risk and drawdown</SlideSubtitle>
        <Typography component="p" className={styles.paragraph}>
          Volatility, as measured by standard deviation, generally trended in
          line with the Global 60/40 blended index over the analysis period.
          Recent periods of lower volatility relative to the index were driven
          by the model&apos;s use of alternatives. The alternatives funded from
          the portfolio&apos;s fixed income allocation benefited during a period
          when bonds experienced heightened volatility from rising interest
          rates. The model effectively reduced the maximum drawdown, falling
          ~21% when the index was down ~23%
        </Typography>
      </Grid>
    </Grid>
  );
}
