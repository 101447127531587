import { settings } from 'config';
import { delay, http, HttpResponse } from 'msw';

function success() {
  const data = { message: 'Success' };
  const status = 200;
  return HttpResponse.json(data, { status });
}

export const putClientNextBestActions = http.put(
  `${settings.api.baseUrl}/v2/clients/nextBestActions/notification-id-1`,
  async () => {
    await delay(1000);
    return success();
  },
);
