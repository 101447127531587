import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import React from 'react';

interface SummaryPerformanceTableProps {
  rows: any[];
  columns: GridColDef[];
}

const SummaryPerformanceTable = ({
  rows,
  columns,
}: SummaryPerformanceTableProps): JSX.Element => {
  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnResize
        hideFooter
        disableColumnSorting
        sx={{
          borderTop: 'none',
          borderRight: 'none',
          fontFamily: 'Inter',
          fontWeight: '500',
          '& .MuiDataGrid-cell': {
            fontSize: '0.875rem',
            borderRight: `1px solid rgba(224, 224, 224, 1)`,
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#f5f5f5',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            borderRight: `1px solid rgba(224, 224, 224, 1)`,
          },
          '& *': {
            outline: 'none!important',
            cursor: 'default!important',
          },

          '& .MuiDataGrid-columnHeaderTitleContainer': {
            borderBottom: 'none!important',
          },
        }}
      />
    </Box>
  );
};

export default SummaryPerformanceTable;
