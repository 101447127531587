import { settings } from 'config';
import { delay, http, HttpResponse } from 'msw';

function success() {
  const data = {
    message: 'Enterprise questions retrieved successfully',
    data: [
      {
        id: '7e2b157b-7746-849a-c088-526c585657771',
        category: 'Viewpoints',
        question: [
          'Which viewpoints impact my clients?',
          'Which clients are most impacted if equity markets fall?',
          'Which clients are most exposed to China?',
        ],
        status: 'published',
        allowPublish: false,
      },
      {
        id: '0bb2688b-a708-1f2a-547c-e1afa5b15993',
        category: 'Personalization',
        question: [],
        status: 'published',
        allowPublish: false,
      },
      {
        id: 'cb6d2654-7a14-2ba7-4139-62ca004810b6',
        category: 'Investment Solutions',
        question: [
          'Which model providers are available?',
          'Compare the performance of funds in different market environments',
          'Find replacements for a fund',
        ],
        status: 'published',
        allowPublish: false,
      },
    ],
  };

  const status = 200;
  return HttpResponse.json(data, { status });
}

export const getEnterpriseQuestions = http.get(
  `${settings.api.baseUrl}/enterprise/enterpriseQuestions`,
  async () => {
    await delay(1000);
    return success();
  },
);
