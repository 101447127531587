import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';
import { usePutCaseActionById } from 'pages/Cases/hooks/usePutCaseActionById';
import { SlidesProvider } from './context';
import Viewport from './components/Viewport';
import NavPanel from './components/NavPanel';
import Footer from './components/Footer';
import styles from './styles.module.css';
import { Box, CircularProgress } from '@mui/material';

interface SlidesEditorProps {}

const SlidesEditor: React.FC<SlidesEditorProps> = () => {
  const { actionId, caseId } = useParams();
  const { action, isActionLoading } = useGetCaseActionById({
    actionId: actionId as string,
    caseId: caseId as string,
  });
  const { putCaseActionByIdMutate } = usePutCaseActionById();
  const [panelOpen, setPanelOpen] = useState(true);

  const handleSave = slides => {
    putCaseActionByIdMutate({
      caseId: caseId as string,
      actionId: actionId as string,
      versionName: action!.versionName,
      value: { ...action.value, slides },
      successToastMessage: 'Presentation saved successfully',
    });
  };

  return isActionLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <div className={styles.slidesEditor}>
      <SlidesProvider action={action} handleSave={handleSave}>
        <Viewport />
        <NavPanel panelOpen={panelOpen} />
        <Footer
          title={action.versionName || ''}
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
        />
      </SlidesProvider>
    </div>
  );
};

export default SlidesEditor;
