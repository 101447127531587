import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

interface DescriptionModelProps {
  name: string;
  series: string;
}

const DescriptionModel: React.FC<DescriptionModelProps> = ({
  name,
  series,
}) => {
  return (
    <Box sx={{ paddingBottom: '32px' }}>
      <TitleInputLabels>Model name</TitleInputLabels>
      <DescriptionInputLabels>{name}</DescriptionInputLabels>
      <TitleInputLabels>Series name</TitleInputLabels>
      <DescriptionInputLabels>{series}</DescriptionInputLabels>
    </Box>
  );
};

export default DescriptionModel;

const TitleInputLabels = styled(InputLabel)`
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  color: #282829;
  margin-top: 16px;
  line-height: 14.5px;
`;

const DescriptionInputLabels = styled(InputLabel)`
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-top: 6px;
  line-height: 17px;
`;
