import React, { useEffect } from 'react';
import styled from 'styled-components';
import searchIcon from '@images/client-search.svg';

interface InputFilterProps {
  value: string;
  placeholder?: string;
  debounceTime?: number;
  handleInputChange: (value: string) => void;
}

function InputFilter({
  value,
  placeholder = '',
  debounceTime = 0,
  handleInputChange,
}: InputFilterProps): React.JSX.Element {
  const [innerValue, setInnerValue] = React.useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    if (value !== innerValue) {
      const timeoutId = setTimeout(() => {
        handleInputChange(innerValue);
      }, debounceTime);
      return () => clearTimeout(timeoutId);
    }
  }, [innerValue]);

  return (
    <InputFilterContainer>
      <label className="label">
        <img className="icon" alt="" src={searchIcon} />
        <input
          type="text"
          className="input"
          placeholder={placeholder}
          value={innerValue}
          onChange={ev => setInnerValue(ev.target.value)}
        />
      </label>
    </InputFilterContainer>
  );
}

export default InputFilter;

const InputFilterContainer = styled.div`
  display: flex;
  align-items: center;

  & .label {
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  & .icon {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;
  }

  & .input {
    box-sizing: border-box;
    width: 100%;
    padding: 7px 8px 7px 32px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
  }
`;
