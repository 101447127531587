import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { ButtonParent, CancelButton, Container, UploadButton } from './styles';
import FileDragger from '@components/FileDragger';
import { uploadCSV } from './mutation';
import { useMutation, useQueryClient } from 'react-query';
import { actions as globalActions } from 'app/features/Global/slice';
import { useDispatch } from 'react-redux';

interface UploadModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadModal: React.FC<UploadModalProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const [csvFile, setCsvFile] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const uploadCSVMutation = useMutation(uploadCSV);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseModal = e => {
    setIsModalOpen(false);
    setCsvFile([]);
    e.stopPropagation();
  };

  const uploadCSVSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const formData = new FormData();
    setIsLoading(true);
    if (csvFile.length > 0) {
      formData.append('file', csvFile[0]);
      uploadCSVMutation.mutate(formData, {
        onSuccess: (res: any) => {
          setIsLoading(false);
          queryClient.invalidateQueries('extract-viewpoint');
          if (res && res.status === 200) {
            dispatch(
              globalActions.displayToast({
                duration: 4000,
                toastType: 'success',
                toastMessage: res?.message,
              }),
            );

            setIsModalOpen(false);
            setCsvFile([]);
          } else {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'error',
                toastMessage: 'Unexpected response from the server.',
              }),
            );
          }
        },
        onError: (error: any) => {
          setIsLoading(false);
          console.log({ error });
          if (error?.response?.data && error?.response?.data?.message) {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'error',
                toastMessage: error?.response?.data?.message,
              }),
            );
          } else {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'error',
                toastMessage: 'An error occurred while processing the query.',
              }),
            );
          }
        },
      });
    }
  };

  return (
    <Grid>
      <Dialog
        open={isModalOpen}
        onClose={e => handleCloseModal(e)}
        PaperProps={{
          style: {
            width: isMobile ? '93%' : '600px',
            position: 'absolute',
            top: '50%',
            left: isMobile ? '42%' : '50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: isMobile ? '90%' : 'calc(100vh - 200px)',
          },
        }}
      >
        <DialogTitle>Upload Investment Insights</DialogTitle>
        <DialogContent
          style={{
            padding: isMobile ? '8px' : '16px',
            maxHeight: isMobile ? 'calc(100vh - 200px)' : 'calc(100vh - 400px)',
          }}
        >
          <Container container>
            <FileDragger
              setPdfFile={setCsvFile}
              upload={['pdf', 'csv']}
              uploadedFiles={csvFile}
            />
          </Container>
        </DialogContent>
        <DialogActions
          style={{ padding: isMobile ? '8px' : '0px 16px 20px 0px' }}
        >
          <>
            <ButtonParent>
              <Grid>
                <CancelButton
                  variant="outlined"
                  onClick={e => handleCloseModal(e)}
                  style={{
                    width: isMobile ? '48%' : 'auto',
                  }}
                >
                  Cancel
                </CancelButton>
              </Grid>
              <Grid>
                <UploadButton
                  variant="contained"
                  disabled={isLoading || csvFile.length === 0}
                  onClick={uploadCSVSubmit}
                  style={{
                    background: csvFile.length === 0 ? 'grey' : '',
                    width: isMobile ? '48%' : '100px',
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      color="inherit"
                      style={{ position: 'absolute', margin: '6px' }}
                    />
                  ) : (
                    'Upload'
                  )}
                </UploadButton>
              </Grid>
            </ButtonParent>
          </>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default UploadModal;
