import React from 'react';
import { Grid } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';

interface MarketplaceTableProps {
  holdings: any[];
}

const MarketplaceTable: React.FC<MarketplaceTableProps> = ({ holdings }) => {
  const columns: GridColDef[] = [
    {
      field: 'ticker',
      headerName: 'Holdings',
      display: 'flex',
      flex: 1,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      display: 'flex',
      flex: 3,
      editable: false,
    },
    {
      field: 'weight',
      headerName: 'Weight (%)',
      display: 'flex',
      flex: 2,
      editable: false,
    },
    {
      field: 'assetClass',
      headerName: 'Instrument',
      display: 'flex',
      flex: 2,
      editable: false,
    },
  ];

  const parseHoldings = () =>
    holdings?.map(holding => ({
      ...holding,
      weight: (holding.weight * 100).toFixed(2),
    }));

  return (
    <Grid item sx={{ width: '100%' }}>
      <DataGrid
        disableColumnMenu
        hideFooter
        loading={holdings === undefined}
        columns={columns}
        rows={parseHoldings()}
        getRowId={row => row.ticker}
        rowSelection={false}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: 'none',
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            },
        }}
      />
    </Grid>
  );
};

export default MarketplaceTable;
