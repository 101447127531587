import { validArray } from '@common/validArray';
import HorizontalBarChart from '@components/Chart/BarChart';
import ChatEnhancementFeatures from '@components/ChatContent/ChatTypes/ChatEnhancementFeatures';
import ModelPortfolioDrawer from '@components/ModelPortfolioDrawer';
import { getAuthDetails } from '@features/Profile/selector';
import SageAvatar from '@icons/sageAvatar';
import UserAvatar from '@icons/UserAvatar';
import { Drawer, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Grey2SubLabelText } from '@styles/Typography';
import CustomModelEdit from 'pages/Conversations/components/CustomModelEdit';
import ProposalGenerator from 'pages/Conversations/components/ProposalGenerator';
import RiskAssessmentEmailEditor from 'pages/Conversations/components/RiskAssessmentEmailEditor';
import UploadConsultForm from 'pages/Conversations/components/UploadConsultForm';
import UploadConsultFormNavigator from 'pages/Conversations/components/UploadConsultForm/UploadConsultFormNavigator';
import React, { Fragment, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { ITEM_TYPES } from 'utils/constant/itemTypes';
import CustomModelCreate from '../../../pages/Conversations/components/CustomModelCreate';
import BulletPointRenderer from './BulletPointRenderer';
import ChatFeedbackV2 from './ChatFeedbackV2';
import ChatMarkdownText from './ChatMarkdownText';
import AllocationBarChartChat from './ChatTypes/AllocationBarChartChat';
import HistoricalPerformanceChat from './ChatTypes/HistoricalReturnsChat';
import RiskAssessmentFiles from './ChatTypes/RiskAssessmentFiles';
import SourceDocument from './ChatTypes/SourceDocument';
import CIOCardComponent from './CIOCard';
import ContentDefault from './ContentDefault';
import Explanations from './Explanations';
import FundSwappingSlider from './FundSwappingSlider';
import InvestmentInsightsList from './InvestmentInsightsList';
import Options from './Options';
import PerformanceComparisonModule from './PerformanceComparisonModule';
import PortfolioList from './PortfolioList';
import SampleCategories, { SampleQuestions } from './SampleQuestionsV2';
import SearchSuggestions from './SearchSuggestions';
import SecurityTable from './SecuritiesTable';
import {
  AvatarWrapper,
  ChatBoxContainer,
  ChatBoxContent,
  ChatBoxContentWrapper,
  ChatFeedbackGrid,
  TimestampStamp,
} from './styles';
import SuggestedQuestions from './SuggestedQuestions';
import Suggestions from './Suggestions';
import Table from './Table';
import TickerDrawer from './TickerDrawer';
import { ChatBoxProps } from './types';
import WhatElseResponse from './WhatElseResponse';
import MeetingPrepDownload from '@components/ChatContent/ChatTypes/MeetingPrepDownload';
import PresentationOfOptions from 'pages/Conversations/components/PresentationOfOptions';

const useStyles = makeStyles({
  drawer: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: 720,
    },
  },
  drawerPaper: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: 720,
    },
  },
  root: {
    display: 'flex',
  },
});

function ChatBox({
  isLastItem,
  chat,
  onSelectChatOption,
  portfolioOptions,
  setLoopCounter,
  loopCounter,
  onFeedbackSubmit,
  chatContentRef,
  scrollToMessageTop,
}: ChatBoxProps): React.JSX.Element {
  const {
    id: chatId,
    data = [],
    isSender = false,
    type,
    debugObj = null,
    createdAt,
  } = chat;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const queryClient = useQueryClient();
  const classes = useStyles();
  const advisorDetails = useSelector(getAuthDetails);

  const [selectedTicker, setSelectedTicker] = useState<string>('');
  const [selectedModel, setSelectedModel] = React.useState<string>('');

  // const chatBoxContainerRef = useRef<HTMLDivElement | null>(null);

  const onOptionClick = (payload: any, portfolioOptions: any) => {
    if (onSelectChatOption && chatId) {
      queryClient.invalidateQueries('get-clients');
      onSelectChatOption(
        {
          chatId,
          selectedOption: payload?.id,
          clientName: payload?.display ?? payload?.Display,
        },
        portfolioOptions,
      );
    }
  };

  const getOptions = () => {
    if (type === 'enhancement_queue') {
      return (
        validArray(data) && data.filter(o => o.type === 'options')[0]?.content
      );
    }
    return [];
  };

  if (!data)
    return (
      <ChatBoxContentWrapper>
        {`Sorry, I wasn't able to process this request.`}
      </ChatBoxContentWrapper>
    );

  return (
    <ChatBoxContainer
      container
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      isSender={isSender}
      // ref={chatBoxContainerRef}
      alignContent={'flex-start'}
      ref={chatContentRef}
    >
      <Grid
        item
        container
        sm={12}
        md={10}
        lg={11}
        justifyContent="flex-start"
        alignItems="flex-start"
        columnGap={1}
        width={isMobile ? '100%' : 'calc(100% - 100px)'}
      >
        <AvatarWrapper>
          {isSender ? (
            <UserAvatar />
          ) : (
            <SageAvatar
              borderOpacity={0.3}
              fill={theme.palette.primary['solidWhite']}
            />
          )}
        </AvatarWrapper>
        <ChatBoxContent item container sm={12} lg={10} rowGap={2}>
          {validArray(data) ? (
            data
              .filter((k: any) => k.display || typeof k.display === 'undefined')
              .sort((a, b) =>
                a.type === 'explanation'
                  ? 1
                  : b.type === 'explanation'
                    ? -1
                    : 0,
              ) // remove this if backend can do this
              .map((item, index: number) => {
                const key = `${chatId}-${index}`;
                switch (item.type) {
                  case ITEM_TYPES.text:
                    return (
                      <ContentDefault
                        key={key}
                        isSender={isSender}
                        type={item?.type}
                        data={item?.content || item?.data}
                        selectTicker={setSelectedTicker}
                      />
                    );
                  case ITEM_TYPES.markdown: {
                    return (
                      <ChatMarkdownText
                        key={key}
                        isStreaming={false}
                        content={item.content || item.data}
                        graphData={item.chartData}
                        isSender={isSender}
                        selectTicker={setSelectedTicker}
                        setSelectedModel={setSelectedModel}
                      />
                    );
                  }
                  case ITEM_TYPES.risk_assessment_email_editor:
                    return isLastItem ? (
                      <RiskAssessmentEmailEditor
                        key={key}
                        clientId={item.content.client_id}
                        mode="edit"
                      />
                    ) : null;
                  case ITEM_TYPES.send_assessment:
                    return (
                      <RiskAssessmentEmailEditor
                        key={key}
                        clientId={item.content.client_id}
                        mode="send"
                      />
                    );
                  case ITEM_TYPES.generate_proposal:
                    return (
                      <ProposalGenerator
                        key={key}
                        modelId={item.content.model_id}
                        clientId={item.content.client_id}
                        isLastItem={isLastItem}
                        mode="proposal"
                      />
                    );
                  case ITEM_TYPES.risk_assessment_answers:
                    return (
                      <ProposalGenerator
                        key={key}
                        modelId={item.content.model_id}
                        clientId={item.content.client_id}
                        isLastItem={isLastItem}
                        mode="riskAssessmentAnwsers"
                      />
                    );
                  case ITEM_TYPES.risk_details:
                    return (
                      <ChatEnhancementFeatures
                        key={key}
                        type={item.type}
                        content={item.content}
                        isLastItem={isLastItem}
                        createdAt={createdAt}
                      />
                    );
                  case ITEM_TYPES.risk_methodology_pdf:
                    return (
                      <RiskAssessmentFiles
                        key={key}
                        link={'RiskMethodology'}
                        pdfName={'TIFIN Risk - Methodology'}
                      />
                    );
                  case ITEM_TYPES.risk_questionnaire_pdf:
                    return (
                      <RiskAssessmentFiles
                        key={key}
                        link={'RiskAssessment'}
                        pdfName={'TIFIN Risk Assessment - Questionaire'}
                      />
                    );
                  case ITEM_TYPES.performance_graph:
                    return (
                      <HistoricalPerformanceChat
                        key={key}
                        content={item?.content}
                        currentPortfolioName={
                          item?.currentPortfolioName || 'Current Portfolio'
                        }
                        recommendedPortfolioName={
                          item?.recommendedPortfolioName || 'Proposed Portfolio'
                        }
                      />
                    );
                  case ITEM_TYPES.allocation_bar_chart:
                    return (
                      <AllocationBarChartChat
                        key={key}
                        content={item?.content}
                      />
                    );
                  case ITEM_TYPES.suggestions:
                    return (
                      isLastItem && (
                        <SuggestedQuestions
                          key={key}
                          chatId={chatId}
                          data={item.content || []}
                        />
                      )
                    );
                  case ITEM_TYPES.plot: {
                    const fundsSwapItems = item.content?.filter(
                      k => k && k.type === 'funds_swap',
                    );
                    const fundsComparisonItems = item.content?.filter(
                      f => f && f.type === 'funds_comparison',
                    );

                    if (validArray(fundsSwapItems)) {
                      return (
                        <FundSwappingSlider
                          key={key}
                          data={fundsSwapItems}
                          selectTicker={setSelectedTicker}
                          chatId={chatId}
                        />
                      );
                    } else if (fundsComparisonItems?.length) {
                      return (
                        <PerformanceComparisonModule
                          key={`${key}-comparison`}
                          data={fundsComparisonItems[0].content}
                        />
                      );
                    }
                    return null;
                  }
                  case ITEM_TYPES.enhancement_data:
                    return (
                      <Suggestions
                        key={key}
                        id={chatId}
                        data={item.content}
                        options={getOptions()}
                        onOptionClick={onOptionClick}
                        portfolioOptions={portfolioOptions}
                        setLoopCounter={setLoopCounter}
                        loopCounter={loopCounter}
                      />
                    );
                  case ITEM_TYPES.search_data:
                    return (
                      <SearchSuggestions
                        key={key}
                        id={chatId}
                        data={item.content}
                      />
                    );
                  case ITEM_TYPES.options:
                    return (
                      type !== 'enhancement_queue' && (
                        <Options
                          key={key}
                          index={index}
                          chatId={chatId}
                          data={item.content}
                          onOptionClick={onOptionClick}
                          buttonView={item.view}
                        />
                      )
                    );
                  case ITEM_TYPES.cio_card:
                    return (
                      <InvestmentInsightsList
                        key={key}
                        investments={item.content || item.data}
                      />
                    );
                  case ITEM_TYPES.portfolio_card:
                    return (
                      <PortfolioList
                        key={key}
                        portfolios={item.content || item.data}
                      />
                    );
                  case ITEM_TYPES.cio_details_card:
                    return (
                      <CIOCardComponent
                        key={key}
                        title={item.content?.title || item.data?.title}
                        desc={item.content?.desc || item.data?.desc}
                        cioList={
                          item.content?.portfolios || item.data?.portfolios
                        }
                      />
                    );
                  case ITEM_TYPES.barchart:
                    return <HorizontalBarChart key={key} data={item.content} />;
                  case ITEM_TYPES.bullet_points:
                    return (
                      <BulletPointRenderer key={key} data={item.content} />
                    );
                  case ITEM_TYPES.sample_categories:
                    return <SampleCategories key={key} data={item.content} />;
                  case ITEM_TYPES.sample_questions:
                    return (
                      <SampleQuestions
                        key={key}
                        data={item.content}
                        hideWhatElse={
                          item.type === ITEM_TYPES.sample_basic_queries
                        }
                      />
                    );
                  case ITEM_TYPES.source_document:
                    return (
                      <SourceDocument
                        key={key}
                        data={item?.content}
                        isLastItem={isLastItem}
                      />
                    );
                  case ITEM_TYPES.sample_basic_queries:
                    return (
                      <SampleQuestions
                        key={key}
                        data={item.content}
                        hideWhatElse={
                          item.type === ITEM_TYPES.sample_basic_queries
                        }
                      />
                    );
                  case ITEM_TYPES.explanation:
                    return (
                      advisorDetails?.expertMode && (
                        <Explanations
                          key={key}
                          data={item.content}
                          dataToVisualize={debugObj}
                        />
                      )
                    );
                  case ITEM_TYPES.security_table:
                    return (
                      <SecurityTable
                        key={key}
                        data={item.content}
                        setTicker={setSelectedTicker}
                      />
                    );
                  case ITEM_TYPES.table:
                    return <Table key={key} data={item.content} />;
                  case ITEM_TYPES.whatElseButton:
                    return (
                      <WhatElseResponse
                        key={key}
                        isFeedbackEnabled={isLastItem}
                        chatLogId={chat['feedback']['chatLogId']}
                        scale={chat['feedback']['scale']}
                        onFeedbackSubmit={onFeedbackSubmit}
                      />
                    );
                  case ITEM_TYPES.upload_model_portfolio:
                    return isLastItem && <CustomModelCreate key={key} />;
                  case ITEM_TYPES.edit_model_portfolio:
                    return isLastItem ? (
                      <CustomModelEdit
                        key={key}
                        modelId={item.content.model_id}
                      />
                    ) : null;
                  case ITEM_TYPES.case_upload_initial_consult_form:
                    return isLastItem ? (
                      <UploadConsultForm
                        key={key}
                        caseId={item.content.case_id}
                        isLastItem={isLastItem}
                      />
                    ) : null;
                  case ITEM_TYPES.navigate_to_construction_page:
                    return isLastItem ? (
                      <UploadConsultFormNavigator
                        key={key}
                        caseId={item.content.case_id}
                      />
                    ) : null;
                  case ITEM_TYPES.report_modal:
                    return isLastItem ? (
                      <PresentationOfOptions
                        key={key}
                        type={'presentation_of_options'}
                        case_id={item.content.case_id}
                      />
                    ) : null;
                  case ITEM_TYPES.premium_report:
                    return isLastItem ? (
                      <PresentationOfOptions
                        key={key}
                        type={'premium_report'}
                        case_id={item.content.case_id}
                      />
                    ) : null;
                  case ITEM_TYPES.download_meeting_prep:
                    return isLastItem ? (
                      <MeetingPrepDownload
                        key={key}
                        filename={item.content.filename}
                        url={item.content.url}
                      />
                    ) : null;
                  default:
                    return <Fragment key={key}></Fragment>;
                }
              })
          ) : (
            <ContentDefault
              isSender={isSender}
              type="text"
              data={data}
              scrollToMessageTop={scrollToMessageTop}
            />
          )}
        </ChatBoxContent>
      </Grid>
      {chat.time && (
        <TimestampStamp item sm={12} md={2} lg={1}>
          <Grey2SubLabelText>{chat.time}</Grey2SubLabelText>
        </TimestampStamp>
      )}
      {!isSender && chat['feedback']?.chatLogId && chat['isShowFeedback'] && (
        <ChatFeedbackGrid container item>
          <ChatFeedbackV2
            isFeedbackEnabled={isLastItem}
            chatLogId={chat['feedback']['chatLogId']}
            scale={chat['feedback']['scale']}
            onFeedbackSubmit={onFeedbackSubmit}
          />
        </ChatFeedbackGrid>
      )}
      <div className={classes.root}>
        <Drawer
          anchor="right"
          variant="temporary"
          open={Boolean(selectedTicker)}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={() => {
            setSelectedTicker('');
            setSelectedModel('');
          }}
        >
          <TickerDrawer
            selectedTicker={selectedTicker}
            setSelectedTicker={setSelectedTicker}
            showStepper={false}
            onClose={() => {
              setSelectedTicker('');
              setSelectedModel('');
            }}
          />
        </Drawer>
        <Drawer
          anchor="right"
          variant="temporary"
          open={Boolean(selectedModel)}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={() => setSelectedModel('')}
        >
          <ModelPortfolioDrawer
            setSelectedTicker={setSelectedTicker}
            setSelectedModel={setSelectedModel}
            selectedModel={selectedModel}
          />
        </Drawer>
      </div>
    </ChatBoxContainer>
  );
}

export default ChatBox;
