import React from 'react';

import { useNavigate } from 'react-router-dom';

import { IconButton, Menu, MenuItem } from '@mui/material';

import ProfileMenuIcon from '@icons/ProfileMenuIcon';

interface MenuProfileProps {
  anchorEl: null | HTMLElement;
  profileMenuList: any;
  setAnchorEl: (value: null | HTMLElement) => void;
  handleClose: () => void;
  handleLogout: () => void;
}

const MenuProfile: React.FC<MenuProfileProps> = ({
  anchorEl,
  profileMenuList,
  setAnchorEl,
  handleClose,
  handleLogout,
}) => {
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {profileMenuList.map((item: any) => (
          <MenuItem
            key={item.path}
            onClick={() => {
              if (item.path === '/logout') {
                handleLogout();
              } else {
                navigate(item.path);
              }
            }}
          >
            {item.Icon && <item.Icon sx={{ mr: 1.5 }} />}
            <span>{item.name}</span>
          </MenuItem>
        ))}
      </Menu>
      <IconButton
        id="profile-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ marginLeft: '10px' }}
      >
        <ProfileMenuIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default MenuProfile;
