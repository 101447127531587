import { getCustomModelsQuery } from './hooks/useGetCustomModelsQuery';

export const checkNameConflict = async (
  name: string,
  id: string | undefined = undefined,
): Promise<boolean> => {
  const params = {
    search: name,
  };
  const { data } = await getCustomModelsQuery(params);
  return (
    data.length > 0 &&
    data.some(model => model.name === name && model.id !== id)
  );
};
