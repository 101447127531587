import { useQuery, UseQueryResult } from 'react-query';
import { fetchTickerSearchResults } from '@pages/ClientsV2/mutation';
import { cashSecuritySearchList } from '@common/constant';

const isCashSecurity = (name: string): boolean => {
  return cashSecuritySearchList.includes(name.toLowerCase());
};

const useSecuritySearch = (searchTerm: string): UseQueryResult<any, Error> => {
  return useQuery(
    ['tickerSearchResults', searchTerm],
    async () => await fetchTickerSearchResults(searchTerm),
    {
      enabled: !!searchTerm && !isCashSecurity(searchTerm),
    },
  );
};

export default useSecuritySearch;
