import React from 'react';
import MarketplaceModelList from './components/MarketplaceModelList';
import { useNavigate } from 'react-router-dom';

interface MarketplaceProps {
  isFavorites: boolean;
}

const Marketplace: React.FC<MarketplaceProps> = ({ isFavorites }) => {
  const navigate = useNavigate();

  const handleClickEditModel = (id: string) => {
    navigate(`/models/marketplace/${id}`);
  };

  return (
    <MarketplaceModelList
      isFavorites={isFavorites}
      handleClickEditModel={handleClickEditModel}
    />
  );
};

export default Marketplace;
