import React, { useRef, useState } from 'react';
import sortIcon from '@images/ArrowsDownUp.svg';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SortMenuContainer } from './styles';
import { Menu, MenuItem } from '@mui/material';

type SortMenuProps = {
  selectedType: 'asc' | 'desc';
  selectedOptionValue: string;
  options: {
    value: string; // value to be passed to the parent component
    placeholder: string; // placeholder to be displayed in the selector
    title: string; // title to be displayed in the floating menu
  }[];
  handleSortTypeChange: (type: 'asc' | 'desc') => void;
  handleOptionChange: (value: string) => void;
};

const SortMenu = (props: SortMenuProps): React.JSX.Element => {
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const selectedPlaceholder = props.options.find(
    option => option.value === props.selectedOptionValue,
  )?.placeholder;

  const handleOpen = () => setMenuAnchorEl(mainContainerRef.current);

  const handleClose = () => setMenuAnchorEl(null);

  const handleSelect = option => {
    props.handleOptionChange(option.value);
    handleClose();
  };

  const handleTypeChange = () =>
    props.handleSortTypeChange(props.selectedType === 'asc' ? 'desc' : 'asc');

  return (
    <SortMenuContainer ref={mainContainerRef}>
      <img
        className="sort-icon"
        src={sortIcon}
        alt=""
        onClick={handleTypeChange}
      />
      <span className="placeholder">{selectedPlaceholder}</span>
      <ArrowDownIcon className="arrow-icon" onClick={handleOpen} />

      {/* floating menu */}
      <Menu
        className="menu"
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        sx={{ '& .MuiPaper-root': { background: '#fff' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {props.options.map((option, index) => (
          <MenuItem
            key={index}
            sx={{ fontSize: '14px' }}
            onClick={() => handleSelect(option)}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </SortMenuContainer>
  );
};

export default SortMenu;
