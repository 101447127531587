import { Grid } from '@mui/material';
import { SlideTitle } from 'features/slides';
import React from 'react';
import styles from './styles.module.css';
import ModelSetSlideTable from '../ModelSetSlideTable';
import { ModelSetHolding } from 'features/premium-report/api/types';
export function StrategyAllocation({
  modelSetHoldings,
}: {
  modelSetHoldings: ModelSetHolding;
}): React.ReactElement {
  return (
    <Grid container direction="row" spacing={1} className={styles.container}>
      <Grid item xs={12}>
        <SlideTitle>Strategy Allocations</SlideTitle>

        <Grid item xs={12} sx={{ width: '100%' }}>
          <ModelSetSlideTable
            key={modelSetHoldings.model_set_name}
            slideMode
            action={{
              id: '',
              caseId: '',
              userId: '',
              milestoneId: '',
              versionName: '',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              draft: false,
              value: { model_set_holdings: [modelSetHoldings] },
            }}
            modelSetName={modelSetHoldings.model_set_name || ''}
            maxHeight={480}
            fontSize={11}
            rowHeight={29.9}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
