import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { getClientDisplayName } from './helpers';
import { price } from '@common/number';
import { getChatInitResponseState } from '@features/Chat/selector';
import { markAsReadClientNotificacion } from './mutation';
import { actions } from '@features/Chat/slice';
import PrompQuestions from '@components/PrompQuestions';
import ChatIcon from '@icons/Chat';
import ConfigurationIcon from '@icons/Configuration';
import SearchIcon from '@icons/Search';
import ClientIcon from '@icons/UserCircleOutlined.svg';
import ProspectIcon from '@icons/UserSquareOutlined.svg';
import { ClientCardContainer } from './ClientCardStyles';

interface ClientCardNotification {
  id: string;
  category: string;
  type: string;
}

interface ClientCardProps {
  clientName: string;
  priceValue: number;
  handleDrawerOpen: any;
  clientId?: string;
  title?: string[];
  portfolioDiff: number;
  isProspect: boolean;
  notifications: ClientCardNotification[];
}

const ClientCard = ({
  clientName,
  priceValue,
  handleDrawerOpen,
  clientId,
  title = [],
  portfolioDiff,
  isProspect,
  notifications,
}: ClientCardProps): React.JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { chatHistory: initHistoryState } = useSelector(
    getChatInitResponseState,
  );

  const { mutate: markAsReadMutation } = useMutation(
    markAsReadClientNotificacion,
  );

  const cardName = getClientDisplayName(clientName);

  // render helpers

  const renderCardContent = () => (
    <div className="card-content">
      <div className="card-content-inner">
        <h3 className="card-name">{cardName}</h3>
        <span className="card-price">
          {priceValue || priceValue === 0
            ? price(priceValue, true, false, false)
            : 'N/A'}
        </span>
      </div>
    </div>
  );

  const renderCardType = () => (
    <span className="card-type" aria-label={isProspect ? 'Prospect' : 'Client'}>
      <img
        className="card-type-icon"
        src={isProspect ? ProspectIcon : ClientIcon}
        alt={isProspect ? 'Prospect' : 'Client'}
      />
    </span>
  );

  const renderCardNbaInfo = () => {
    const allNotificationCategories = notifications.map(
      notification => notification.category,
    );
    const uniqueNotificationCategories = [
      ...new Set(allNotificationCategories),
    ];

    return uniqueNotificationCategories.length >= 0 ? (
      <div className="card-content-nba">
        <ul className="card-content-nba-badges">
          {uniqueNotificationCategories.map((category, index) => (
            <li key={index} className={`card-nba-badge ${category}`} />
          ))}
        </ul>
      </div>
    ) : null;
  };

  const renderCardButtons = () => {
    const buttons = [
      {
        title: 'Analyze',
        icon: <SearchIcon />,
        question: `Analyze ${clientName}’s portfolio`,
        response: 'Sure! What would you like to analyze?',
        subquestions: [
          { head: `Show ${clientName}’s asset allocation` },
          { head: `How has ${clientName}’s portfolio performed Year To Date?` },
          {
            head: `How would ${clientName}’s portfolio be impacted if US equities fell 20%?`,
          },
        ],
      },
      {
        title: 'Adjust',
        icon: <ConfigurationIcon />,
        question: `Make Adjustments to ${clientName}’s Portfolio`,
        response: 'Sure! What would you like to adjust?',
        subquestions: [
          { head: `Suggest fund options for ${clientName} to lower risk` },
          { head: `Suggest fund options for ${clientName} to lower fees` },
          {
            head: `What adjustments can I make to reduce ${clientName}’s sensitivity to Equity Prices?`,
          },
        ],
      },
      {
        title: 'Outputs',
        icon: <ChatIcon />,
        question: `Suggest some client communications actions for ${clientName}`,
        response: 'Sure! What would you like to prepare for your client?',
        subquestions: [
          { head: `Suggest a Model for ${clientName}` },
          { head: `Send a Risk Assessment to ${clientName}` },
          { head: `Generate a proposal for ${clientName}` },
        ],
      },
    ];

    return (
      <div className="card-buttons">
        {buttons.map((button, index) => (
          <Button
            key={index}
            className="card-button"
            onClick={() =>
              handleButtonClick({
                question: button.question,
                system: button.response,
                subquestions: button.subquestions,
              })
            }
          >
            <span className="card-button-icon">{button.icon}</span>
            <span className="card-button-text">{button.title}</span>
          </Button>
        ))}
      </div>
    );
  };

  const renderNbaPrompQuestions = () => {
    const firstNotifications = notifications
      // get notification type info
      .map(notification => ({
        ...notification,
        ...getNotificationTypeInfo(notification.type),
      }))
      // remove notifications without question or prompt
      .filter(
        notification =>
          notification.question !== '' && notification.prompt !== '',
      )
      // get first 3 valid notifications
      .slice(0, 3);

    return (
      <div className="card-nba-promp-questions">
        <ul className="card-nba-promp-questions-badges">
          {firstNotifications.map((notification, index) => (
            <li
              key={index}
              className={`card-nba-badge ${notification.category}`}
            ></li>
          ))}
        </ul>
        <PrompQuestions
          showEllypsis
          questions={firstNotifications.map(
            notification => notification.question,
          )}
          handleQuestionClick={clickedQuestion => {
            const clickedNotification = firstNotifications.find(
              notification => notification.question === clickedQuestion,
            );
            handlePrompQuestionClick(clickedNotification!.id);
          }}
        />
      </div>
    );
  };

  const getNotificationTypeInfo = (type: string) => {
    switch (type) {
      case 'new_risk_assessment':
        return {
          question: 'New risk assessment',
          prompt: `Show the risk Assessment for ${cardName}`,
        };
      case 'risk_score_misalignment':
        return {
          question: 'Risk score misalignment',
          prompt: `Show the Risk Assessment for ${cardName}`,
        };
      case 'excess_cash':
        return {
          question: 'Excess Cash',
          prompt: `What is ${cardName}’s allocation to Cash?`,
        };
      case 'prospect_recently_added':
        return {
          question: 'Prospect Recently Added',
          prompt: `Show the Holdings for ${cardName}`,
        };
      case 'concentrated_position':
        return {
          question: 'Concentrated Position',
          prompt: `Show ${cardName}’s top portfolio holdings`,
        };
      case 'higher_risk_account':
        return {
          question: 'Higher Risk Account',
          prompt: `Suggest some fund options to reduce risk for ${cardName}’s Portfolio`,
        };
      case 'risk_assessment_not_sent':
        return {
          question: 'Send Risk Assessment',
          prompt: `Send a risk assessment to ${cardName}`,
        };
      default:
        return { question: '', prompt: `` };
    }
  };

  // events

  const handleButtonClick = ({ question, subquestions, system }) => {
    navigate('/conversations/start');
    dispatch(actions.searchSampleQuestion({ isSampleQuestionAsked: false }));
    dispatch(
      actions.loadChatSuccess({
        chatHistory: [
          ...initHistoryState,
          {
            id: '',
            time: '',
            isSender: true,
            isChatHistory: true,
            mock: true,
            type: 'text',
            data: question,
          },
          {
            id: '65b8f06d84d66bb8215d3173',
            time: '',
            isSender: false,
            isShowFeedback: false,
            isChatHistory: false,
            mock: true,
            data: [
              { type: 'text', content: system },
              { type: 'sample_basic_queries', content: subquestions },
            ],
          },
        ],
      }),
    );
  };

  const handlePrompQuestionClick = (notificationId: string) => {
    const notification = notifications.find(
      notification => notification.id === notificationId,
    );
    const notificationTypeInfo = getNotificationTypeInfo(notification!.type);
    dispatchChatQuestion(notificationTypeInfo.prompt);
    markAsReadMutation({ notificationId });
  };

  const dispatchChatQuestion = (question: string) => {
    const isChatStreaming = localStorage.getItem('streaming') === 'true';
    navigate('/conversations/stream');
    dispatch(
      isChatStreaming
        ? actions.setSearchQuery(question)
        : actions.searchQueryRequest({ query: question }),
    );
  };

  return (
    <ClientCardContainer
      item
      onClick={() =>
        handleDrawerOpen(clientId, title, portfolioDiff, clientName)
      }
    >
      {renderCardContent()}

      <div className="card-footer">
        {renderCardNbaInfo()}
        {renderCardType()}
      </div>
      <div className="card-hover-content">
        {renderCardButtons()}
        {renderNbaPrompQuestions()}
      </div>
    </ClientCardContainer>
  );
};

export default ClientCard;
