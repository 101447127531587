import {
  Case,
  CaseMilestoneAction,
  CaseMilestoneType,
} from '../hooks/useGetCases';

export const holdingsCategories = [
  {
    id: 'core-return',
    category: 'Core - Return',
    color: '#E3EDFD',
  },
  {
    id: 'core-return-tax-aware',
    category: 'Core - Return - Tax Aware',
    color: '#E3EDFD',
  },
  {
    id: 'core-income',
    category: 'Core - Income',
    color: '#E3EDFD',
  },
  {
    id: 'core-income-tax-aware',
    category: 'Core - Income - Tax Aware',
    color: '#E3EDFD',
  },
  {
    id: 'equity-return',
    category: 'Equity - Return',
    color: '#CAF1E3',
  },
  {
    id: 'equity-return-higher-beta',
    category: 'Equity - Return - Higher Beta',
    color: '#CAF1E3',
  },
  {
    id: 'equity-income',
    category: 'Equity - Income',
    color: '#FCC8C1',
  },
  {
    id: 'equity-lower-beta',
    category: 'Equity - Lower Beta',
    color: '#FCC8C1',
  },
  {
    id: 'bonds-return',
    category: 'Bonds - Return',
    color: '#FFEEC3',
  },
  {
    id: 'bonds-return-tax-aware',
    category: 'Bonds - Return - Tax Aware',
    color: '#FFEEC3',
  },
  {
    id: 'bonds-income',
    category: 'Bonds - Income',
    color: '#FFEEC3',
  },
  {
    id: 'bonds-shorter-duration',
    category: 'Bonds - Shorter Duration',
    color: '#FFEEC3',
  },
  {
    id: 'bonds-shorter-duration-tax-aware',
    category: 'Bonds - Shorter Duration - Tax Aware',
    color: '#FFEEC3',
  },
  {
    id: 'alternatives-diversification',
    category: 'Alternatives - Diversification',
    color: '#FEDDBD',
  },
  {
    id: 'comprehensive-return',
    category: 'Comprehensive - Return',
    color: '#FEDDBD',
  },
  {
    id: 'comprehensive-preservation',
    category: 'Comprehensive - Preservation',
    color: '#FEDDBD',
  },
  {
    id: 'comprehensive-income',
    category: 'Comprehensive - Income',
    color: '#FEDDBD',
  },
  {
    id: 'complete',
    category: 'Complete',
    color: '#CDD6FF',
  },
];

export const getHoldingCategoryOrder = (category: string): number =>
  holdingsCategories.findIndex(holding => holding.category === category);

export const getHoldingCategoryColor = (categoryRole: string): string =>
  holdingsCategories.find(holding => holding.category === categoryRole)
    ?.color || '';

export const getHoldingCategoryId = (categoryRole: string): string =>
  holdingsCategories.find(holding => holding.category === categoryRole)?.id ||
  '';

export const getModelSetIndexByName = (
  name: string,
  action: CaseMilestoneAction,
): number => {
  const modelSetHoldings = action?.value?.model_set_holdings || [];
  return modelSetHoldings.findIndex(
    (modelSet: any) => modelSet.model_set_name === name,
  );
};

export const getMilestoneId = (
  caseData: Case,
  milestoneType: CaseMilestoneType,
): string => {
  const milestone = caseData!.milestones!.find(
    milestone => milestone.type === milestoneType,
  );
  return milestone!.id;
};

export const getLastActionId = (
  caseData: Case,
  milestoneType: CaseMilestoneType,
): string => {
  const milestone = caseData?.milestones?.find(
    milestone => milestone.type === milestoneType,
  );
  const sortedActions = milestone!.actions.sort(
    (a: any, b: any) => b.createdAt - a.createdAt,
  );
  return sortedActions[0]?.id;
};
