import { StyledButton } from '@pages/CIOViewpoints/styles';
import React, { useState } from 'react';
import BoltIcon from '@icons/bolt';
import FirmDetailsTable from './FirmDetailsTable';
import { Grid, Typography } from '@mui/material';
import { useRESTQuery } from '@hooks/useRESTQuery';
import BackArrowIcon from '@icons/BackArrow';
import { DetailsContainer, StyledText } from './styles';
import { price } from '@common/number';
import { handleError } from '@common/error';
import { useDispatch } from 'react-redux';
import FirmAdminModal from './FirmAdminModal';
import AddAdvisorModal from './AddAdvisorModal';
import Loader from '@components/Loader';
import {
  FirmAdminCard,
  StyledHeaderDiv,
  StyledRectangle,
} from '@pages/EnterpriseAdmin/styles';
import AdvisorUploadSuccessModal from '@pages/FirmAdmin/AdvisorUploadSuccessModal';

export interface FirmDetailsProps {
  setStepper: React.Dispatch<
    React.SetStateAction<
      'ENTERPRISE_LISTING_PAGE' | 'FIRM_LISTING_PAGE' | 'FIRM_DETAILS_PAGE'
    >
  >;
  firmDetails: any;
}

function FirmCardDetails({
  setStepper,
  firmDetails,
}: FirmDetailsProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [showModel, setShowModel] = useState(false);
  const [openAdvisorModal, setOpenAdvisorModal] = useState(false);
  const [addAdvisorStepper, setAddAdvisorStepper] = useState<
    'ADD_ADVISOR' | 'SUCCESS_SCREEN'
  >('ADD_ADVISOR');

  const handleAddAdvisor = () => {
    setOpenAdvisorModal(true);
  };

  const handleCloseModal = () => {
    setOpenAdvisorModal(false);
  };

  const { data: selectedFirmDetails, isLoading } = useRESTQuery(
    ['firm-details', firmDetails],
    {
      endpoint: `/firms/firmDetails?id=${firmDetails.id}`,
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      enabled: !!firmDetails,
      select: res => res.data,
      onError: (error: any) => {
        handleError(dispatch, error);
      },
    },
  );

  const totalAdmins = () =>
    Array.isArray(selectedFirmDetails?.stats) &&
    selectedFirmDetails?.stats?.length > 0 &&
    selectedFirmDetails?.stats.find(item => item.key === 'Total Admins');

  const totalAdvisors = () =>
    Array.isArray(selectedFirmDetails?.stats) &&
    selectedFirmDetails?.stats?.length > 0 &&
    selectedFirmDetails?.stats.find(item => item.key === 'Total Advisors');

  if (isLoading) return <Loader />;

  return (
    <>
      <Grid container xs={12}>
        <DetailsContainer item xs={12}>
          <Grid
            item
            container
            xs={11.7}
            justifyContent={'flex-start'}
            alignItems={'center'}
            columnGap={1}
            onClick={() => setStepper('FIRM_LISTING_PAGE')}
            style={{ cursor: 'pointer' }}
          >
            <Grid item>
              <BackArrowIcon />
            </Grid>
            <Grid item>
              <p>All Enterprises</p>
            </Grid>
            <Grid item>/</Grid>
            <Grid item>
              <p>{firmDetails?.name}</p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              maxHeight: 'calc(100vh - 140px)',
              overflowY: 'auto',
            }}
          >
            <StyledHeaderDiv
              item
              container
              xs={11.7}
              padding={'15px 15px 15px 0px'}
              rowGap={3}
              justifyContent={'space-between'}
              direction={'row'}
            >
              <Grid item container xs={6.7}>
                <StyledRectangle xs={0.8} height={'40px'} />
                <Grid item container xs={6}>
                  <Grid item justifyContent={'flex-start'} mt={'-3px'}>
                    <StyledText>{firmDetails?.name}</StyledText>
                  </Grid>
                  <Grid
                    item
                    container
                    fontSize={'12px'}
                    direction={'row'}
                    columnGap={0.5}
                    color={'#667085'}
                    mt={'5px'}
                  >
                    <Grid item>
                      {totalAdmins()?.value > 1
                        ? `${totalAdmins().value} Admins`
                        : `${totalAdmins().value} Admin`}
                    </Grid>
                    <Grid item>•</Grid>
                    <Grid item>
                      {totalAdvisors()?.value > 1
                        ? `${totalAdvisors().value} Advisors`
                        : `${totalAdvisors().value} Advisors`}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={5}
                justifyContent={'flex-end'}
                alignItems={'center'}
                columnGap={1.2}
              >
                <StyledButton
                  variant="outlined"
                  onClick={() => setShowModel(true)}
                  style={{
                    fontSize: '12px',
                    width: '40%',
                  }}
                >
                  Add Firm Administrator
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  onClick={handleAddAdvisor}
                  style={{
                    fontSize: '12px',
                    width: '40%',
                  }}
                >
                  Add Firm Advisor
                </StyledButton>
              </Grid>
            </StyledHeaderDiv>
            <Grid
              item
              container
              xs={11.7}
              pt={'15px'}
              columnGap={3}
              rowGap={1.5}
            >
              {Array.isArray(selectedFirmDetails?.stats) &&
                selectedFirmDetails?.stats?.length > 0 &&
                selectedFirmDetails?.stats
                  .filter(item => item.key !== 'Total Admins')
                  .map((item, index) => (
                    <>
                      <FirmAdminCard item container xs={3.8} key={index}>
                        <Grid
                          item
                          alignItems={'center'}
                          justifyContent={'flex-start'}
                          xs={2}
                        >
                          <BoltIcon
                            width="36px"
                            height="36px"
                            fill="#E8FFF7"
                            stroke="#333"
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          // height={'30x'}
                          mt={'-5px'}
                          justifyContent={'flex-start'}
                          xs={10}
                          direction={'column'}
                        >
                          <Typography
                            sx={{ fontSize: '14px', color: '#475467' }}
                          >
                            {item?.key}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '18px',
                              color: '#101828',
                              marginTop: '-3px',
                            }}
                          >
                            {item.key === 'Total AUM'
                              ? price(item.value)
                              : item.value}
                          </Typography>
                        </Grid>
                      </FirmAdminCard>
                    </>
                  ))}
            </Grid>
            <Grid item container xs={11.7}>
              <FirmDetailsTable
                adminData={selectedFirmDetails?.firmAdminstrationUsers}
                enterpriseData={selectedFirmDetails?.enterpriseAdmins}
                advisorData={selectedFirmDetails?.firmUsers}
              />
            </Grid>
          </Grid>
        </DetailsContainer>
      </Grid>
      <FirmAdminModal
        isOpen={showModel}
        onClose={() => setShowModel(false)}
        firmDetails={firmDetails}
      />
      <AddAdvisorModal
        isOpen={openAdvisorModal}
        onClose={handleCloseModal}
        setAddAdvisorStepper={setAddAdvisorStepper}
        firmDetails={firmDetails}
      />
      <AdvisorUploadSuccessModal
        isOpen={addAdvisorStepper === 'SUCCESS_SCREEN'}
        onClose={() => setAddAdvisorStepper('ADD_ADVISOR')}
      />
    </>
  );
}

export default FirmCardDetails;
