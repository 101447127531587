import { Button, CircularProgress } from '@mui/material';
import React from 'react';

interface ModalButtonProps {
  label: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  handleClick: () => void;
  isLoading?: boolean;
  loadingLabel?: string;
}

const ModalButton: React.FC<ModalButtonProps> = ({
  label,
  variant = 'primary',
  disabled = false,
  handleClick,
  isLoading = false,
  loadingLabel,
}) => {
  return variant === 'primary' ? (
    <Button
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: '20px',
        borderColor: '#000',
        backgroundColor: '#000',
        textTransform: 'capitalize',

        color: '#fff',
        '&:hover': {
          backgroundColor: '#96969B',
          borderColor: '#96969B',
        },
      }}
      disabled={disabled}
      onClick={handleClick}
    >
      {isLoading ? (
        <CircularProgress size={12} sx={{ mr: 1, color: '#fff' }} />
      ) : null}
      {isLoading && loadingLabel ? loadingLabel : label}
    </Button>
  ) : (
    <Button
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: '20px',
        borderColor: '#ddd',
        textTransform: 'capitalize',
        color: '#000',
      }}
      disabled={disabled}
      onClick={handleClick}
    >
      {isLoading ? (
        <CircularProgress size={12} sx={{ mr: 1, color: '#fff' }} />
      ) : null}
      {isLoading && loadingLabel ? loadingLabel : label}
    </Button>
  );
};

export default ModalButton;
