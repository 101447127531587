import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useGetCaseActionById } from 'pages/Cases/hooks/useGetCaseActionById';
import { useGetCaseById } from 'pages/Cases/hooks/useGetCaseById';
import BackButton from 'components/BackButton';
import GreyFrame from 'components/GrayFrame';
import SummaryHeader from 'pages/Cases/components/SummaryHeader';
import ModelSetsTable from 'pages/Cases/components/ModelSetsTable';
import { usePostCalculateFees } from 'pages/Cases/hooks/usePostCalculateFees';
import ModelSetsFeesTable from 'pages/Cases/components/ModelSetsFeesTable';
import TabsLayout from 'components/TabsLayout/TabsLayout';
import ModelSetsPortfolioCharacteristicsTable from 'pages/Cases/components/ModelSetsPortfolioCharacteristicsTable';
import { usePostCalculatePortfolioCharacteristics } from 'pages/Cases/hooks/usePostCalculatePortfolioCharacteristics';

const modelSetsTabs = [
  {
    label: 'Model Sets',
    path: '',
    validPatterns: [
      'cases/:caseId/portfolio-constructions/:portfolioId/model-sets/:modelSetName',
    ],
    replaceLocation: true,
  },
  {
    label: 'Portfolio Characteristics',
    path: 'portfolio-characteristics',
    replaceLocation: true,
  },
];

const ModelSets: React.FC = () => {
  const { actionId, caseId, modelSetName } = useParams();
  const { caseData } = useGetCaseById({ id: caseId! });
  const { action, isActionLoading, isActionFetching, getModelSetHolding } =
    useGetCaseActionById({
      actionId: actionId || '',
      caseId: caseId || '',
    });

  const { fees, isFeesLoading } = usePostCalculateFees({
    enabled: !!action && !isActionLoading && !isActionFetching,
    body: modelSetName && getModelSetHolding(modelSetName),
  });

  const { portfolioCharacteristics, isPortfolioCharacteristicsLoading } =
    usePostCalculatePortfolioCharacteristics({
      enabled: !!action && !isActionLoading && !isActionFetching,
      portfolioCharacteristics:
        modelSetName && getModelSetHolding(modelSetName),
    });

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        paddingBottom: '48px',
      }}
      direction="column"
    >
      <Grid container sx={{ padding: '16px' }}>
        <Grid item xs={12} sx={{ marginBottom: '16px' }}>
          <BackButton name="Summary" />
        </Grid>
        <GreyFrame>
          <Grid
            container
            item
            xs={12}
            sx={{ padding: '16px' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <SummaryHeader caseData={caseData} action={action} />
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography
                gutterBottom
                sx={{ fontSize: '16px', fontWeight: '600' }}
              >
                {modelSetName}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container sx={{ padding: '16px' }}>
            <Routes>
              <Route
                element={
                  <TabsLayout
                    tabs={modelSetsTabs}
                    removeGreyFrame
                    paddingBottom="0px"
                    paddingRight="0px"
                    marginBottom="0px"
                  />
                }
              >
                <Route
                  path="/"
                  element={
                    <>
                      {!isActionLoading && (
                        <ModelSetsTable
                          action={action}
                          isDraft={action.isDraft}
                          modelSetName={modelSetName || ''}
                        />
                      )}
                      {!isFeesLoading && fees && (
                        <ModelSetsFeesTable fees={fees} />
                      )}
                    </>
                  }
                />
                <Route
                  path="/portfolio-characteristics"
                  element={
                    !isPortfolioCharacteristicsLoading &&
                    portfolioCharacteristics && (
                      <ModelSetsPortfolioCharacteristicsTable
                        portfolioCharacteristics={portfolioCharacteristics}
                      />
                    )
                  }
                />
              </Route>
            </Routes>
          </Grid>
        </GreyFrame>
      </Grid>
    </Stack>
  );
};

export default ModelSets;
