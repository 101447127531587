import React, { useMemo } from 'react';
import { Tab, Tabs, Box, Stack } from '@mui/material';
import { Outlet, Link, useLocation, matchPath } from 'react-router-dom';

interface TabItem {
  label: string;
  path: string;
  validPatterns?: string[];
}

interface TabLayoutProps {
  tabs: TabItem[];
}

const TabsLayout: React.FC<TabLayoutProps> = ({ tabs }) => {
  const location = useLocation();

  const currentTabPath = useMemo(() => {
    return tabs.find(tab => {
      const currentPath = location.pathname.split('/').pop() || '';
      return currentPath === tab.path
        ? true
        : tab.validPatterns?.some(pattern =>
            matchPath(pattern, location.pathname),
          );
    })?.path;
  }, [location.pathname, tabs]);

  const tabsItems = useMemo(() => {
    return tabs.map(tab => ({ ...tab, value: tab.path }));
  }, [tabs]);

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        paddingRight: '32px',
        paddingBottom: '48px',
      }}
      direction="column"
    >
      <Box sx={{ borderBottom: 1, borderColor: '#ECECEC' }}>
        <Tabs value={currentTabPath}>
          {tabsItems.map(tab => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              component={Link}
              to={tab.path}
              sx={{ textTransform: 'none' }}
            />
          ))}
        </Tabs>
      </Box>

      <Box
        sx={{
          background: '#f5f5f5',
          padding: '16px',
          marginTop: '24px',
          marginBottom: '24px',
          p: '16px',
        }}
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            background: '#fff',
            borderRadius: '4px',
            width: '100%',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Stack>
  );
};

export default TabsLayout;
