import { settings } from 'config';
import { delay, http, HttpResponse } from 'msw';

function success() {
  const data = {
    data: {
      clients: [
        {
          id: '607d5785-e265-4526-b00b-20b5dfb5d600',
          apl: null,
          email: 'new1new1@yopmail.com',
          firstName: 'Alex',
          lastName: 'Marvin',
          marketValue: 0,
          restrictions: [],
          portfolioDiff: 0,
          currentPortfolioRisk: null,
          isProspect: false,
          notifications: [
            {
              id: 'notification-id-1',
              type: 'new_risk_assessment',
              category: 'risk',
            },
            {
              id: 'notification-id-2',
              type: 'risk_score_misalignment',
              category: 'opportunity',
            },
            {
              id: 'notification-id-3',
              type: 'excess_cash',
              category: 'risk',
            },
          ],
          viewpoint: '',
        },
        {
          id: '00ea4691-d215-45fb-b171-5c38b02e4b3b',
          apl: null,
          email: 'KEVIN69@sagedummy.com',
          firstName: 'KEVIN',
          lastName: 'AUFIERO',
          marketValue: 99223.70321000002,
          restrictions: [],
          portfolioDiff: 0,
          currentPortfolioRisk: 23,
          isProspect: false,
          notifications: [
            {
              id: 'notification-id-1',
              type: 'concentrated_position',
              category: 'risk',
            },
          ],
          viewpoint: '',
        },
        {
          id: '0370ebbb-f8ae-4028-9b3e-9d91a772e9a6',
          apl: null,
          email: 'KEVIN70@sagedummy.com',
          firstName: 'KEVIN',
          lastName: 'BROOKES',
          marketValue: 145776.27999999997,
          restrictions: [],
          portfolioDiff: 0,
          currentPortfolioRisk: 41,
          isProspect: false,
          notifications: [
            {
              id: 'notification-id-3',
              type: 'higher_risk_account',
              category: 'risk',
            },
            {
              id: 'notification-id-4',
              type: 'prospect_recently_added',
              category: 'risk',
            },
          ],
          viewpoint: '',
        },
        {
          id: '4f0e1756-05b9-4446-b6d6-3ff58fdc2808',
          apl: null,
          email: 'KEVIN42@sagedummy.com',
          firstName: 'KEVIN',
          lastName: 'GABBAY',
          marketValue: 121279.933065,
          restrictions: [],
          portfolioDiff: 0,
          currentPortfolioRisk: 30,
          isProspect: false,
          notifications: [],
          viewpoint: '',
        },
        {
          id: '8a1571d0-6d64-4bb2-8a59-eee83dc59b88',
          apl: null,
          email: 'KEVIN54@sagedummy.com',
          firstName: 'KEVIN',
          lastName: 'VANDERWAL',
          marketValue: 4656.739999999999,
          restrictions: [],
          portfolioDiff: 0,
          currentPortfolioRisk: 1,
          isProspect: false,
          notifications: [],
          viewpoint: '',
        },
      ],
      totalNotifications: {
        risk: '2',
        opportunity: '1',
      },
    },
    message: 'Success',
    totalCount: 303,
    totalPages: 31,
    currentPage: 1,
  };

  const status = 200;
  return HttpResponse.json(data, { status });
}

export const getClients = http.get(
  `${settings.api.baseUrl}/v2/clients?page=1&limit=18&allClients=true`,
  async () => {
    await delay(1000);
    return success();
  },
);
