import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';

export const GreyLabelText = styled(Typography)<any>`
  // font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '12px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '500'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.grey['500']} !important;
  width: ${props => props.width ?? '100%'} !important;
`;

export const GreySubLabelText = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '10px'} !important;
  font-weight: ${props => props.fontWeight ?? '400'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.lightGrey} !important;
`;

export const Grey2SubLabelText = styled(Grid)<any>`
  font-size: ${props => props?.fontSize ?? '10px'} !important;
  font-weight: ${props => props.fontWeight ?? '400'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.grey3} !important;
`;

export const ParagraphBoldtText = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '500'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.textBlack} !important;
`;

export const ParagraphLightText = styled(Typography)<any>`
  font-size: 12px;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '400'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.grey2} !important;
  @media (min-width: 768px) {
    font-size: ${props => props?.fontSize ?? '14px'} !important;
  }
`;

export const PageHeading = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '28px'} !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  line-height: ${props => props?.lineHeight ?? '34px'} !important;
`;

export const SubHeading = styled(Typography)<any>`
  font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '16px'} !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  line-height: ${props => props.lineHeight ?? '19px'} !important;
`;
export const SmallText = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '12px'} !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  line-height: ${props => props?.lineHeight ?? '18.9px'} !important;
  margin: ${props => props.margin ?? '0px'} !important;
  color: ${props => props.color ?? '#000000'} !important;
`;
