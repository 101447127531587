import axios from 'axios';
import { useQueryClient } from 'react-query';
import { settings } from 'config';
import { useToast } from './toast';

// query methods

type UseFetchQueryArguments = {
  headers?: Record<string, any>;
  body?: any;
  path: string;
  params?: Record<string, any>;
  method?: 'GET' | 'POST';
};

type UseFetchQueryResult = {
  fetchQuery: (endpoint: UseFetchQueryArguments) => any;
};

export const useFetchQuery = (): UseFetchQueryResult => {
  const { displayErrorToast } = useToast();

  const fetchQuery = (endpoint: UseFetchQueryArguments) => {
    return () =>
      axios({
        baseURL: settings.api.baseUrl,
        data: endpoint.body,
        headers: {
          ...getCommonHeaders(),
          ...endpoint.headers,
        },
        method: endpoint.method || 'GET',
        params: endpoint.params,
        responseType: 'json',
        url: endpoint.path,
      })
        .then(response => {
          // show error toast message
          if (response.status !== 200) {
            displayErrorToast('An error has occurred. Please try again later.');
          }
          return response.data;
        })
        .catch(error => {
          // show error toast message
          if (typeof error?.response?.data?.message === 'string') {
            displayErrorToast(error.response.data.message);
          } else if (typeof error?.response?.data === 'string') {
            displayErrorToast(error.response.data);
          } else if (typeof error?.response?.data?.error === 'string') {
            displayErrorToast(error.response.data.error);
          } else {
            displayErrorToast('An error has occurred. Please try again later.');
          }
          throw error;
        });
  };

  return { fetchQuery };
};

// mutation methods

type UseFetchMutationArguments = {
  headers?: Record<string, any>;
  invalidateQueries?: string | string[][];
  method: 'POST' | 'PUT' | 'DELETE';
  path: string;
  body?: any;
  params?: Record<string, any>;
  successToastMessage?: string;
  contentType?: string;
};

type UseFetchMutationResult = {
  fetchMutation: (endpoint: UseFetchMutationArguments) => any;
};

export const useFetchMutation = (): UseFetchMutationResult => {
  const queryClient = useQueryClient();
  const { displayErrorToast, displaySuccessToast } = useToast();

  const fetchMutation = (endpoint: UseFetchMutationArguments) => {
    return axios({
      baseURL: settings.api.baseUrl,
      data: endpoint.body,
      headers: {
        ...getCommonHeaders(endpoint.contentType),
        ...endpoint.headers,
      },
      method: endpoint.method,
      params: endpoint.params,
      responseType: 'json',
      url: endpoint.path,
    })
      .then(response => {
        // invalidate queries
        if (
          endpoint.invalidateQueries &&
          Array.isArray(endpoint.invalidateQueries)
        ) {
          endpoint.invalidateQueries.forEach(invalidQuery =>
            queryClient.invalidateQueries(invalidQuery),
          );
        } else
          endpoint.invalidateQueries &&
            queryClient.invalidateQueries(endpoint.invalidateQueries);

        // show success toast message
        if (
          (response.status === 200 && endpoint.successToastMessage) ||
          (response.status === 204 && endpoint.successToastMessage)
        ) {
          displaySuccessToast(endpoint.successToastMessage);
        }

        // show error toast message
        if (response.status !== 200 && response.status !== 204) {
          displayErrorToast('An error has occurred. Please try again later.');
        }

        return response.data;
      })
      .catch(error => {
        // show error toast message
        if (typeof error?.response?.data?.message === 'string') {
          displayErrorToast(error.response.data.message);
        } else if (typeof error?.response?.data === 'string') {
          displayErrorToast(error.response.data);
        } else {
          displayErrorToast('An error has occurred. Please try again later.');
        }
      });
  };

  return { fetchMutation };
};

// common methods

const getCommonHeaders = (contentType = 'application/json') => {
  const headers: Record<string, string> = {
    Accept: '*/*',
    http_host: 'TG',
    'Access-Control-Allow-Origin': process.env.REACT_APP_FRONTEND_URL || '',
    'Access-Control-Allow-Headers': '*',
    'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token') || '',
  };

  // Solo agregar Content-Type si se especifica
  if (contentType) {
    headers['Content-Type'] = contentType;
  }
  const impersonationId = localStorage.getItem('impersonation-id');
  if (impersonationId) {
    headers['impersonation-id'] = impersonationId;
  }
  return headers;
};
