import { createTheme } from '@mui/material';
import { COLORS as customColors, CustomPaletteOptions } from './colors';
import GeneralSansTTF from '../../assets/fonts/GeneralSans-Variable.ttf';
import GeneralSansWOFF from '../../assets/fonts/GeneralSans-Variable.woff';
import GeneralSansWOFF2 from '../../assets/fonts/GeneralSans-Variable.woff2';
import GeneralSansEOT from '../../assets/fonts/GeneralSans-Variable.eot';

declare module '@mui/material/styles' {
  interface DefaultTheme {
    solidWhite: string;
    // You can add other properties if needed
  }
  interface TypographyVariants {
    inherit: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    h5: React.CSSProperties;
    h6: React.CSSProperties;
    caption: React.CSSProperties;
    button: React.CSSProperties;
    overline: React.CSSProperties;
    smReg: React.CSSProperties;
    smMed: React.CSSProperties;
    mdReg: React.CSSProperties;
    mdMed: React.CSSProperties;
    lgReg: React.CSSProperties;
    lgMed: React.CSSProperties;
    headingSm: React.CSSProperties;
    headingMd: React.CSSProperties;
    headingLg: React.CSSProperties;
    headingXl: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    smReg?: React.CSSProperties;
    smMed?: React.CSSProperties;
    mdReg?: React.CSSProperties;
    mdMed?: React.CSSProperties;
    lgReg?: React.CSSProperties;
    lgMed?: React.CSSProperties;
    headingSm?: React.CSSProperties;
    headingMd?: React.CSSProperties;
    headingLg?: React.CSSProperties;
    headingXl?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1: true;
    body2: true;
    h1: false;
    h2: false;
    h3: true;
    h4: false;
    h5: false;
    h6: true;
    caption: true;
    button: false;
    overline: false;
    smReg: true;
    smMed: true;
    mdReg: true;
    mdMed: true;
    lgReg: true;
    lgMed: true;
    headingSm: true;
    headingMd: true;
    headingLg: true;
    headingXl: true;
  }
}

// Merge custom colors with the default theme
const mergedColors: CustomPaletteOptions = {
  ...createTheme().palette, // Use default theme colors
  ...customColors, // Override with custom colors
};

// Define the @font-face rule
const fontFace = `
  @font-face {
    font-family: 'General Sans Variable';
    font-display: swap;
    src: url(${GeneralSansTTF}) format('truetype'),
         url(${GeneralSansWOFF}),
         url(${GeneralSansWOFF2}) format('woff'),
         url(${GeneralSansEOT});
    /* Add more src entries for other formats if needed */
    font-weight: light;
    font-style: normal;
  }
`;

export default createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: mergedColors.main,
            opacity: 0.5,
            color: 'white',
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          smReg: 'p',
          smMed: 'p',
          mdReg: 'p',
          mdMed: 'p',
          lgReg: 'p',
          lgMed: 'p',
          headingSm: 'h4',
          headingMd: 'h3',
          headingLg: 'h2',
          headingXl: 'h1',
        },
      },
    },
  },
  palette: {
    primary: mergedColors,
    secondary: {
      dark: '#333340',
      main: '#EDEDED',
      light: '#d9e7df',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F9FAFE',
    },
    error: {
      main: '#FF0000',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    // Include the custom font in typography
    fontFamilyBold: {
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    smReg: {
      fontSize: 12,
      fontWeight: 400,
    },
    smMed: {
      fontSize: 12,
      fontWeight: 500,
    },
    mdReg: {
      fontSize: 14,
      fontWeight: 400,
    },
    mdMed: {
      fontSize: 14,
      fontWeight: 500,
    },
    lgReg: {
      fontSize: 16,
      fontWeight: 400,
    },
    lgMed: {
      fontSize: 16,
      fontWeight: 500,
    },
    headingSm: {
      fontSize: 20,
      fontWeight: 500,
    },
    headingMd: {
      fontSize: 28,
      fontWeight: 500,
    },
    headingLg: {
      fontSize: 36,
      fontWeight: 600,
    },
    headingXl: {
      fontSize: 48,
      fontWeight: 700,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        // Apply the @font-face rule globally
        '@font-face': fontFace,
      },
    },
  },
} as any);
