/*
 * This is a component for uploading images.
 * Right now only the CSS is done because the component is read-only.
 * When the component is imported all this commented code will be cleaned up.
 */
import React, { FC } from 'react';

import { Box, Grid } from '@mui/material';
import { InputLabels } from '../Buttons';
// import {
//   AcceptedFileTypeLabelDiv,
//   FileUploadContainer,
//   StyledModalInput,
//   UploadFileLabel,
//   UploadFileLabelContainer,
// } from '@pages/SuperAdminPage/styles';
// import { UploadButton } from '@layout/styles';
// import { StyledCard } from '@pages/Profile/styles';
// import GenericImage from '@images/image-generic.svg';

//import { getAuthDetails } from '@features/Profile/selector';
//import { useSelector } from 'react-redux';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface FirmLogo {
  logoSrc: string | undefined;
}

const FirmLogo: FC<FirmLogo> = ({ logoSrc }) => {
  // const [logoFile] = useState<File | null>(null);
  //const [uploadedImageUrl] = useState<string | null>(null);
  // const fileInputRef = useRef<HTMLInputElement>(null);
  // const advisorDetails = useSelector(getAuthDetails);

  return (
    <Grid container my={'24px'}>
      <Grid item mr={'32px'} sx={{ width: '200px' }}>
        <Box mb={'6px'}>
          <InputLabels sx={{ color: '#000000', fontWeight: '500' }}>
            Firm Logo
          </InputLabels>
        </Box>
        <Box>
          <p style={{ color: '#4F4F4F', fontWeight: '400', fontSize: '14px' }}>
            Customize Sage`s outputs with your own Logo
          </p>
        </Box>
      </Grid>

      <Grid container sx={{ width: 'calc(100% - 232px)' }}>
        <Box
          mb={'6px'}
          sx={{
            p: '10px',
            mr: '24px',
            height: '84px',
            background: '#fff',
            border: '1px solid #ececec',
            borderRadius: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              boxSizing: 'border-box',
              height: '100%',
              padding: '16px',
              background: '#f5f5f5',
              borderRadius: '2px',
            }}
          >
            {logoSrc && (
              <img
                src={logoSrc}
                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
              />
            )}
          </Box>
        </Box>

        <Box
          mb={'6px'}
          sx={{
            p: '10px',
            mr: '24px',
            height: '84px',
            background: '#fff',
            border: '1px solid #ececec',
            borderRadius: '4px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: '16px',
              background: '#f5f5f5',
              borderRadius: '2px',
              height: '100%',
            }}
          >
            {uploadIcon}

            <div style={{ marginLeft: '16px' }}>
              <p style={{ fontSize: '16px', marginBottom: '4px' }}>
                Click to upload or{' '}
                <span style={{ color: '#4f4f4f' }}>drag and drop</span>
              </p>
              <p style={{ fontSize: '12px', color: '#4f4f4f' }}>
                SVG, PNG or JPG (300kb max)
              </p>
            </div>
          </Box>
        </Box>

        {/* <FileUploadContainer
          item
          sx={{
            padding: '5px',
            height: '140px',
          }}
        >
          <UploadFileLabelContainer
            htmlFor="uploadLogoInput"
            disabled
            tabIndex={0}
          >
            <StyledModalInput
              type="file"
              id="uploadLogoInput"
              disabled
              inputProps={{ accept: '.png, .jpg, .svg' }}
              ref={fileInputRef}
            />
            <CloudUploadIcon sx={{ width: '100px', height: '50px' }} />
            <UploadFileLabel
              style={{
                fontSize: '14px',
                padding: '0px',
                whiteSpace: 'normal',
              }}
            >
              Click to upload or drag and drop
            </UploadFileLabel>
            <AcceptedFileTypeLabelDiv>
              SVG, PNG or JPG (300kb max)
            </AcceptedFileTypeLabelDiv>
          </UploadFileLabelContainer>
        </FileUploadContainer> */}
      </Grid>
      {/* {logoFile && (
        <UploadButton variant="contained" color="primary">
          Upload
        </UploadButton>
      )} */}
    </Grid>
  );
};

export default FirmLogo;

const uploadIcon = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="32" height="32" rx="16" fill="white" />
    <rect
      x="2"
      y="2"
      width="36"
      height="36"
      rx="18"
      stroke="#AAAAAA"
      strokeOpacity="0.2"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M17.5 26.25H15.625C14.4647 26.25 13.3519 25.7891 12.5314 24.9686C11.7109 24.1481 11.25 23.0353 11.25 21.875C11.25 20.7147 11.7109 19.6019 12.5314 18.7814C13.3519 17.9609 14.4647 17.5 15.625 17.5C15.991 17.4999 16.3557 17.5445 16.7109 17.6328"
      stroke="#4F4F4F"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 20C16.25 19.0097 16.4853 18.0335 16.9366 17.152C17.3879 16.2705 18.0422 15.5088 18.8456 14.9297C19.649 14.3507 20.5784 13.9708 21.5574 13.8215C22.5364 13.6721 23.5369 13.7576 24.4764 14.0707C25.4159 14.3839 26.2676 14.9158 26.9612 15.6227C27.6547 16.3296 28.1704 17.1912 28.4657 18.1365C28.761 19.0817 28.8274 20.0836 28.6595 21.0596C28.4916 22.0356 28.0942 22.9577 27.5 23.75"
      stroke="#4F4F4F"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2266 22.6484L21.875 20L24.5234 22.6484"
      stroke="#4F4F4F"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.875 26.25V20"
      stroke="#4F4F4F"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
