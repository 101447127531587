import { Button, CircularProgress, Grid } from '@mui/material';
import React, { FC, ReactElement } from 'react';

interface EmptyListProps {
  title?: string;
  loading?: boolean;
  buttons?: {
    content: string | ReactElement;
    handleClick: () => void;
  }[];
}

const EmptyList: FC<EmptyListProps> = ({ loading, title, buttons }) => {
  return (
    <Grid container color={'#000000'}>
      <Grid container justifyContent={'center'}>
        {loading === true && <CircularProgress />}
        {loading === false && <p>{title}</p>}
      </Grid>

      <Grid container justifyContent={'center'} sx={{ mt: 2 }}>
        {loading === false &&
          buttons?.map(({ content, handleClick }, index) => (
            <Button
              key={index}
              variant="contained"
              color="primary"
              sx={{
                mx: 1,
                maxHeight: '32px',
                boxShadow: 'none',
                borderRadius: '16px',
                textTransform: 'none',
              }}
              onClick={handleClick}
            >
              {content}
            </Button>
          ))}
      </Grid>
    </Grid>
  );
};

export default EmptyList;
