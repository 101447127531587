import { useQuery } from 'react-query';
import { useFetchQuery } from '../../../helpers/api';

type Portfolios = {
  portfolio_name: string;
  risk_profile: number;
  holdings: {
    model_id: string;
    weight: number;
  }[];
};

type ModelSetPortfolioCharacteristics = {
  model_set_name: string;
  portfolio_options: {
    option: number;
    portfolios: Portfolios[];
  }[];
};

export interface PortfolioCharacteristics {
  // Portfolio Info
  portfolio_name: string;
  risk_profile: number;
  platform_fee: number;
  expense_ratio: number;
  total_expense: number;

  // Asset Allocation
  equity: number;
  bonds: number;
  real_estate: number;
  commodities: number;
  alternatives: number;
  cash: number;
  other: number;

  // Equity Breakdown
  us_equity: number;
  us_large_cap: number;
  us_mid_cap: number;
  us_small_cap: number;
  non_us_equity: number;
  non_us_developed_equity: number;
  non_us_em_equity: number;

  // Bond Breakdown
  bonds_core: number;
  bonds_non_core: number;
  bonds_high_yield: number;
  bonds_em: number;
  bonds_municipals: number;

  // Style Breakdown
  large_growth: number;
  large_value: number;
  large_core: number;
  mid_growth: number;
  mid_value: number;
  mid_core: number;
  small_growth: number;
  small_value: number;
  small_core: number;
  unassigned_style: number;

  // Fixed Income Characteristics
  yield: number;
  duration: number;
}

export type PortfolioOptionCharacteristics = {
  option: number;
  portfolios: PortfolioCharacteristics[];
};

type UsePostCalculatePortfolioCharacteristicsArguments = {
  portfolioCharacteristics: ModelSetPortfolioCharacteristics;
  enabled: boolean;
};

type UsePostCalculatePortfolioCharacteristicsResult = {
  portfolioCharacteristics:
    | UsePostCalculatePortfolioCharacteristicsResponse
    | undefined;
  isPortfolioCharacteristicsLoading: boolean;
};

export type UsePostCalculatePortfolioCharacteristicsResponse = {
  portfolio_date: string;
  portfolio_options: PortfolioOptionCharacteristics[];
};

export const calculateAdditionalCharacteristics = (
  portfolio: PortfolioCharacteristics,
): PortfolioCharacteristics => {
  // Validate that required values exist
  if (!portfolio) return portfolio;

  const equityTotal =
    portfolio.large_growth +
    portfolio.large_value +
    portfolio.large_core +
    portfolio.mid_growth +
    portfolio.mid_value +
    portfolio.mid_core +
    portfolio.small_growth +
    portfolio.small_value +
    portfolio.small_core;

  const additionalCharacteristics = {
    // High Yield & EM calculation
    high_yield_and_em:
      (portfolio.bonds_high_yield || 0) + (portfolio.bonds_em || 0),

    // Alts Exposure calculation
    alts_exposure: (portfolio.alternatives || 0) + (portfolio.commodities || 0),

    // Equity percentages
    us_equity_pct: portfolio.equity
      ? (portfolio.us_equity / portfolio.equity) * 100
      : 0,
    international_equity_pct: portfolio.equity
      ? (portfolio.non_us_equity / portfolio.equity) * 100
      : 0,

    // Style breakdown percentages
    growth_pct: equityTotal
      ? ((portfolio.large_growth +
          portfolio.mid_growth +
          portfolio.small_growth) /
          equityTotal) *
        100
      : 0,
    core_pct: equityTotal
      ? ((portfolio.large_core + portfolio.mid_core + portfolio.small_core) /
          equityTotal) *
        100
      : 0,
    value_pct: equityTotal
      ? ((portfolio.large_value + portfolio.mid_value + portfolio.small_value) /
          equityTotal) *
        100
      : 0,

    // Bond percentages
    bonds_core_pct: portfolio.bonds
      ? (portfolio.bonds_core / portfolio.bonds) * 100
      : 0,
    bonds_munis_pct: portfolio.bonds
      ? (portfolio.bonds_municipals / portfolio.bonds) * 100
      : 0,
    bonds_non_core_pct: portfolio.bonds
      ? (portfolio.bonds_non_core / portfolio.bonds) * 100
      : 0,
  };

  return {
    ...portfolio,
    ...additionalCharacteristics,
  };
};

export const usePostCalculatePortfolioCharacteristics = ({
  portfolioCharacteristics,
  enabled,
}: UsePostCalculatePortfolioCharacteristicsArguments): UsePostCalculatePortfolioCharacteristicsResult => {
  const { fetchQuery } = useFetchQuery();

  const { data, isLoading } = useQuery<
    UsePostCalculatePortfolioCharacteristicsResponse[]
  >(
    ['portfolio-characteristics', JSON.stringify(portfolioCharacteristics)],
    async () => {
      const response = await fetchQuery({
        path: `/v2/proxy/investment-engine/api/enterprise/amk/calculate-portfolio-characteristics`,
        body: portfolioCharacteristics,
        method: 'POST',
      })();

      return response.map(item => ({
        ...item,
        portfolio_options: item.portfolio_options.map(option => ({
          ...option,
          portfolios: option.portfolios.map(portfolio =>
            calculateAdditionalCharacteristics(portfolio),
          ),
        })),
      }));
    },
    { enabled },
  );

  return {
    portfolioCharacteristics: data?.[0],
    isPortfolioCharacteristicsLoading: isLoading,
  };
};

export interface CalculatePortfolioCharacteristicsResponse {
  characteristics: PortfolioCharacteristics[];
}

export interface UseCalculatePortfolioCharacteristicsArgs {
  portfolioData: {
    holdings: Array<{
      model_id: string;
      weight: number;
    }>;
  };
  enabled?: boolean;
}

export type PortfolioCharacteristicsRow = {
  id: string;
  name: string;
  values: number[];
  color?: string;
};

export const PORTFOLIO_CHARACTERISTICS_ROWS = {
  // Platform Info
  PLATFORM_FEE: { id: 'platform_fee', name: 'Platform Fee* (bps)' },
  EXPENSE_RATIO: { id: 'expense_ratio', name: 'Expense Ratio (bps)' },
  TOTAL_EXPENSE: {
    id: 'total_expense',
    name: 'Total Expense* (bps)',
  },

  // Equity Exposure
  EQUITY_EXPOSURE: {
    id: 'equity',
    name: 'Equity Exposure (%)',
  },

  US_EQUITY: { id: 'us_equity', name: 'US Equity Exposure (%)' },
  LARGE_CAP: { id: 'us_large_cap', name: 'US Large Cap' },
  MID_CAP: { id: 'us_mid_cap', name: 'US Mid Cap' },
  SMALL_CAP: { id: 'us_small_cap', name: 'US Small Cap' },
  INTERNATIONAL_EQUITY: {
    id: 'non_us_equity',
    name: 'International Equity Exposure (%)',
  },

  DEVELOPED_MARKET: { id: 'non_us_developed_equity', name: 'Developed Market' },
  EMERGING_MARKET: { id: 'non_us_em_equity', name: 'Emerging Market' },
  REAL_ESTATE: { id: 'real_estate', name: 'Real Estate Exposure (%)' },

  // Style Breakdown
  OF_EQUITY: {
    id: 'of_equity',
    name: '% of Equity',
  },
  US_STYLE: {
    id: 'us_equity_pct',
    name: 'US',
  },
  INTERNATIONAL_STYLE: {
    id: 'international_equity_pct',
    name: 'International',
  },

  US_EQUITY_STYLE_EXPOSURE: {
    id: 'us_equity_style_exposure',
    name: 'US Equity Style Exposure (% of Equity)',
  },
  GROWTH: { id: 'growth_rescaled', name: 'Growth' },
  CORE_US: { id: 'core_rescaled', name: 'Core' },
  VALUE: { id: 'value_rescaled', name: 'Value' },
  LARGE_CAP_GROWTH: { id: 'large_growth', name: 'Large Cap Growth' },
  LARGE_CAP_CORE: { id: 'large_core', name: 'Large Cap Core' },
  LARGE_CAP_VALUE: { id: 'large_value', name: 'Large Cap Value' },
  MID_CAP_GROWTH: { id: 'mid_growth', name: 'Mid Cap Growth' },
  MID_CAP_CORE: { id: 'mid_core', name: 'Mid Cap Core' },
  MID_CAP_VALUE: { id: 'mid_value', name: 'Mid Cap Value' },
  SMALL_CAP_GROWTH: { id: 'small_growth', name: 'Small Cap Growth' },
  SMALL_CAP_CORE: { id: 'small_core', name: 'Small Cap Core' },
  SMALL_CAP_VALUE: { id: 'small_value', name: 'Small Cap Value' },

  // Bond Exposure
  BOND_EXPOSURE: { id: 'bonds', name: 'Bond Exposure (%)' },
  CORE_BONDS: { id: 'bonds_core', name: 'Core Bonds' },
  MUNICIPAL_BONDS: { id: 'bonds_municipals', name: 'Municipal Bonds' },
  NON_CORE_BONDS: { id: 'bonds_non_core', name: 'Non-Core Bonds' },
  HIGH_YIELD_EM: { id: 'hy_and_em_bonds', name: 'High Yield & EM' },
  CASH: { id: 'cash', name: 'Cash' },

  // Bond Characteristics
  YIELD_OF_BONDS: { id: 'pct_of_bonds', name: '% of Bonds' },
  CORE: { id: 'core_bonds_rescaled', name: 'Core' },
  MUNIS: { id: 'municipal_bonds_rescaled', name: 'Munis' },
  NON_CORE: { id: 'non_core_bonds_rescaled', name: 'Non-Core' },
  DURATION: { id: 'duration', name: 'Duration (years)' },

  // Other Exposures
  ALTS_EXPOSURE: {
    id: 'alts_exposure',
    name: 'Alts Exposure (%)',
  },
  COMMODITIES: { id: 'commodities', name: 'Commodities' },
  YIELD_TOTAL: { id: 'yield', name: 'Yield (%)' },
} as const;

export type PortfolioCharacteristicsGroup = {
  id: string;
  name: string;
  rows: PortfolioCharacteristicsRow[];
};

export const PORTFOLIO_CHARACTERISTICS_GROUPS = {
  id: 'equity_exposure',
  name: 'Equity Exposure',
  rows: [
    PORTFOLIO_CHARACTERISTICS_ROWS.PLATFORM_FEE,
    PORTFOLIO_CHARACTERISTICS_ROWS.EXPENSE_RATIO,
    PORTFOLIO_CHARACTERISTICS_ROWS.TOTAL_EXPENSE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.EQUITY_EXPOSURE,
    PORTFOLIO_CHARACTERISTICS_ROWS.US_EQUITY,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP,
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP,
    PORTFOLIO_CHARACTERISTICS_ROWS.INTERNATIONAL_EQUITY,
    PORTFOLIO_CHARACTERISTICS_ROWS.DEVELOPED_MARKET,
    PORTFOLIO_CHARACTERISTICS_ROWS.EMERGING_MARKET,
    PORTFOLIO_CHARACTERISTICS_ROWS.REAL_ESTATE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.OF_EQUITY,
    PORTFOLIO_CHARACTERISTICS_ROWS.US_STYLE,
    PORTFOLIO_CHARACTERISTICS_ROWS.INTERNATIONAL_STYLE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.US_EQUITY_STYLE_EXPOSURE,
    PORTFOLIO_CHARACTERISTICS_ROWS.GROWTH,
    PORTFOLIO_CHARACTERISTICS_ROWS.CORE_US,
    PORTFOLIO_CHARACTERISTICS_ROWS.VALUE,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP_GROWTH,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP_CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP_VALUE,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP_GROWTH,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP_CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP_VALUE,
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP_GROWTH,
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP_CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP_VALUE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.BOND_EXPOSURE,
    PORTFOLIO_CHARACTERISTICS_ROWS.CORE_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.MUNICIPAL_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.NON_CORE_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.HIGH_YIELD_EM,
    PORTFOLIO_CHARACTERISTICS_ROWS.CASH,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.YIELD_OF_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.MUNIS,
    PORTFOLIO_CHARACTERISTICS_ROWS.NON_CORE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.DURATION,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.ALTS_EXPOSURE,
    PORTFOLIO_CHARACTERISTICS_ROWS.COMMODITIES,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.YIELD_TOTAL,
  ],
};

export const PORTFOLIO1 = {
  id: 'equity_exposure',
  name: 'Equity Exposure',
  rows: [
    PORTFOLIO_CHARACTERISTICS_ROWS.PLATFORM_FEE,
    PORTFOLIO_CHARACTERISTICS_ROWS.EXPENSE_RATIO,
    PORTFOLIO_CHARACTERISTICS_ROWS.TOTAL_EXPENSE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.EQUITY_EXPOSURE,
    PORTFOLIO_CHARACTERISTICS_ROWS.US_EQUITY,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP,
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP,
    PORTFOLIO_CHARACTERISTICS_ROWS.INTERNATIONAL_EQUITY,
    PORTFOLIO_CHARACTERISTICS_ROWS.DEVELOPED_MARKET,
    PORTFOLIO_CHARACTERISTICS_ROWS.EMERGING_MARKET,
  ],
};

export const PORTFOLIO2 = {
  id: 'equity_exposure',
  name: 'Equity Exposure',
  rows: [
    PORTFOLIO_CHARACTERISTICS_ROWS.REAL_ESTATE,
    PORTFOLIO_CHARACTERISTICS_ROWS.OF_EQUITY,
    PORTFOLIO_CHARACTERISTICS_ROWS.US_STYLE,
    PORTFOLIO_CHARACTERISTICS_ROWS.INTERNATIONAL_STYLE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.US_EQUITY_STYLE_EXPOSURE,
    PORTFOLIO_CHARACTERISTICS_ROWS.GROWTH,
    PORTFOLIO_CHARACTERISTICS_ROWS.CORE_US,
    PORTFOLIO_CHARACTERISTICS_ROWS.VALUE,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP_GROWTH,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP_CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.LARGE_CAP_VALUE,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP_GROWTH,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP_CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.MID_CAP_VALUE,
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP_GROWTH,
  ],
} as const;

export const PORTFOLIO3 = {
  id: 'equity_exposure',
  name: 'Equity Exposure',
  rows: [
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP_CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.SMALL_CAP_VALUE,
    PORTFOLIO_CHARACTERISTICS_ROWS.BOND_EXPOSURE,
    PORTFOLIO_CHARACTERISTICS_ROWS.CORE_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.MUNICIPAL_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.NON_CORE_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.HIGH_YIELD_EM,
    PORTFOLIO_CHARACTERISTICS_ROWS.CASH,
    PORTFOLIO_CHARACTERISTICS_ROWS.YIELD_OF_BONDS,
    PORTFOLIO_CHARACTERISTICS_ROWS.CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.MUNIS,
    PORTFOLIO_CHARACTERISTICS_ROWS.NON_CORE,
    PORTFOLIO_CHARACTERISTICS_ROWS.DURATION,
    PORTFOLIO_CHARACTERISTICS_ROWS.ALTS_EXPOSURE,
    '',
    PORTFOLIO_CHARACTERISTICS_ROWS.YIELD_TOTAL,
  ],
} as const;
