import React from 'react';
import { SlideType, useSlideContext } from '../..';
import { Slide } from '../slide';
import styles from './styles.module.css';

export type SlidesNavPanelProps = {
  readonly isOpen: boolean;
  readonly slides: SlideType[];
};

export function SlidesNavPanel({
  isOpen,
  slides,
}: SlidesNavPanelProps): React.ReactElement {
  const { index, setIndex, width, height } = useSlideContext();
  const scale = 0.125;

  return (
    <div className={styles.panel} aria-hidden={!isOpen} id="slides-nav-panel">
      {slides.map((slide, idx) => (
        <div className={styles.item} key={slide.id}>
          <div className={styles.number}>{idx + 1}</div>
          <div
            onClick={() => setIndex(idx)}
            className={styles.button}
            aria-current={idx === index}
          >
            <div
              className={styles.viewportMini}
              aria-current={idx === index}
              style={{
                width,
                height,
                aspectRatio: width / height,
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                marginRight: (width - width * scale) * -1 + 'px',
                marginBottom: (height - height * scale) * -1 + 'px',
              }}
            >
              <Slide slide={slide} index={idx} editable={false} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
