import React from 'react';

export function ChevronUpIcon(): React.ReactElement {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6848 9.05988C11.6441 9.10055 11.5959 9.13282 11.5428 9.15484C11.4897 9.17686 11.4327 9.18819 11.3752 9.18819C11.3177 9.18819 11.2608 9.17686 11.2077 9.15484C11.1546 9.13282 11.1063 9.10055 11.0657 9.05988L7.00024 4.99386L2.93477 9.05988C2.85268 9.14197 2.74134 9.18809 2.62524 9.18809C2.50915 9.18809 2.3978 9.14197 2.31571 9.05988C2.23362 8.97778 2.1875 8.86644 2.1875 8.75034C2.1875 8.63425 2.23362 8.52291 2.31571 8.44081L6.69071 4.06581C6.73134 4.02514 6.7796 3.99287 6.83271 3.97085C6.88582 3.94883 6.94275 3.9375 7.00024 3.9375C7.05774 3.9375 7.11467 3.94883 7.16778 3.97085C7.22089 3.99287 7.26914 4.02514 7.30977 4.06581L11.6848 8.44081C11.7255 8.48144 11.7577 8.5297 11.7797 8.58281C11.8018 8.63592 11.8131 8.69285 11.8131 8.75034C11.8131 8.80784 11.8018 8.86477 11.7797 8.91788C11.7577 8.97099 11.7255 9.01924 11.6848 9.05988Z"
        fill="#343330"
      />
    </svg>
  );
}
