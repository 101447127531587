import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomModelEdit from 'pages/Models/components/CustomModelEdit';

const CustomModelId: React.FC = () => {
  const navigate = useNavigate();
  const { id: modelId } = useParams();

  const handleSaveSuccess = () => {
    navigate(-1);
  };

  return (
    <CustomModelEdit
      modelId={modelId || ''}
      handleSaveSuccess={handleSaveSuccess}
    />
  );
};

export default CustomModelId;
