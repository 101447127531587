import axios from 'axios';
import { useQueryClient } from 'react-query';
import { settings } from 'config';
import { useToast } from './toast';

// query methods

type UseFetchQueryArguments = {
  headers?: Record<string, any>;
  path: string;
  params?: Record<string, any>;
};

type UseFetchQueryResult = {
  fetchQuery: (endpoint: UseFetchQueryArguments) => any;
};

export const useFetchQuery = (): UseFetchQueryResult => {
  const { displayErrorToast } = useToast();

  const fetchQuery = (endpoint: UseFetchQueryArguments) => {
    return () =>
      axios({
        baseURL: settings.api.baseUrl,
        headers: {
          ...getCommonHeaders(),
          ...endpoint.headers,
        },
        method: 'GET',
        params: endpoint.params,
        responseType: 'json',
        url: endpoint.path,
      }).then(response => {
        // show error toast message
        if (response.status !== 200) {
          displayErrorToast('An error has occurred. Please try again later.');
        }
        return response.data;
      });
  };

  return { fetchQuery };
};

// mutation methods

type UseFetchMutationArguments = {
  headers?: Record<string, any>;
  invalidateQueries?: string | string[][];
  method: 'POST' | 'PUT' | 'DELETE';
  path: string;
  body?: any;
  params?: Record<string, any>;
  successToastMessage?: string;
};

type UseFetchMutationResult = {
  fetchMutation: (endpoint: UseFetchMutationArguments) => any;
};

export const useFetchMutation = (): UseFetchMutationResult => {
  const queryClient = useQueryClient();
  const { displayErrorToast, displaySuccessToast } = useToast();

  const fetchMutation = (endpoint: UseFetchMutationArguments) => {
    return axios({
      baseURL: settings.api.baseUrl,
      data: endpoint.body,
      headers: {
        ...getCommonHeaders(),
        ...endpoint.headers,
      },
      method: endpoint.method,
      params: endpoint.params,
      responseType: 'json',
      url: endpoint.path,
    }).then(response => {
      // invalidate queries
      if (
        endpoint.invalidateQueries &&
        Array.isArray(endpoint.invalidateQueries)
      ) {
        endpoint.invalidateQueries.forEach(invalidQuery =>
          queryClient.invalidateQueries(invalidQuery),
        );
      } else
        endpoint.invalidateQueries &&
          queryClient.invalidateQueries(endpoint.invalidateQueries);

      // show success toast message
      if (response.status === 200 && endpoint.successToastMessage) {
        displaySuccessToast(endpoint.successToastMessage);
      }

      // show error toast message
      if (response.status !== 200) {
        displayErrorToast('An error has occurred. Please try again later.');
      }

      return response.data;
    });
  };

  return { fetchMutation };
};

// common methods

const getCommonHeaders = () => {
  const headers = {
    Accept: '*/*',
    http_host: 'TG',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': process.env.REACT_APP_FRONTEND_URL,
    'Access-Control-Allow-Headers': '*',
    'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
  };
  if (localStorage.getItem('impersonation-id')) {
    headers['impersonation-id'] = localStorage.getItem('impersonation-id');
  }
  return headers;
};
