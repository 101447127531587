import RectangleIcon from '@mui/icons-material/Rectangle';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface PortfolioStatisticsLineChartProps {
  formatChart?: {
    value?: string;
    unit?: string;
    decimals?: number;
    prefix?: string;
    suffix?: string;
    label?: string;
  };
  fontSize?: number;
  data: Array<{
    modelSetName: string;
    displayPortfolioName?: string;
    displayBenchmarkName?: string;
    data: Record<string, number>;
  }>;
  height?: number;
  name?: string;
}

const OPTION1_COLORS = [
  '#04ACD7', // Blue
  '#004F7E', // Dark Blue
  '#003057', // Navy Blue
  '#00CFB4', // Teal
  '#8CAC89', // Sage Green
  '#00A892', // Green
];

const OPTION2_COLORS = [
  '#F4AF00', // Yellow
  '#E87722', // Orange
  '#AF0737', // Red
  '#8A1B61', // Deep Red
  '#CF9400', // Dark Yellow
  '#C2631C', // Dark Orange
];

export const PortfolioStatisticsLineChart = ({
  data = [],
  formatChart,
  height = 400,
  fontSize = 14,
}: PortfolioStatisticsLineChartProps): JSX.Element => {
  const allDates = [
    ...new Set(data.flatMap(item => Object.keys(item.data))),
  ].sort();

  const chartData = allDates.map(date => {
    const name = moment(date).isValid()
      ? moment(date).format('MM-YYYY')
      : date.toLowerCase() === 'ytd'
        ? 'YTD'
        : date.replace(/_/g, ' ');

    return {
      name,
      ...data.reduce(
        (acc, item) => ({
          ...acc,
          [item.modelSetName]: item.data[date],
        }),
        {},
      ),
    };
  });

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item sx={{ width: '100%' }}>
        <ResponsiveContainer width="100%" height={height}>
          <LineChart
            data={chartData}
            margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis
              dataKey="name"
              fontSize={fontSize}
              ticks={chartData.reduce<string[]>((ticks, entry, index) => {
                const date = moment(entry.name, 'MM-YYYY');
                if (!date.isValid()) return ticks;
                if (index === 0 || date.format('MM') === '12') {
                  return [...ticks, entry.name];
                }
                return ticks;
              }, [])}
            />
            <YAxis
              domain={['auto', 'auto']}
              style={{
                fontSize: fontSize,
              }}
              fontSize={fontSize}
              interval={0}
              label={{
                value: formatChart?.value || '',
                angle: -90,
                position: 'insideLeft',
                style: {
                  textAnchor: 'middle',
                  fontSize: fontSize,
                },
              }}
            />
            <Tooltip
              formatter={value =>
                `${formatChart?.prefix || ''}${Number(value).toFixed(formatChart?.decimals ?? 2)}${formatChart?.unit || ''}${
                  formatChart?.suffix || ''
                } `
              }
              contentStyle={{
                fontSize: fontSize,
                whiteSpace: 'normal',
                width: '80%',
              }}
            />
            <Legend content={props => renderLegend(props, data, fontSize)} />

            {data.map((item, index) => {
              const isBenchmark = index === data.length - 1;
              const optionNumber = item.modelSetName.includes('Opt1') ? 1 : 2;
              const colorIndex =
                index %
                (optionNumber === 1
                  ? OPTION1_COLORS.length
                  : OPTION2_COLORS.length);
              const color = isBenchmark
                ? '#000000'
                : optionNumber === 1
                  ? OPTION1_COLORS[colorIndex]
                  : OPTION2_COLORS[colorIndex];

              return (
                <Line
                  type="monotone"
                  key={item.modelSetName}
                  dot={false}
                  dataKey={item.modelSetName}
                  name={item.modelSetName}
                  stroke={color}
                  strokeWidth={isBenchmark ? 2 : 1}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

const renderLegend = (props, data, fontSize) => {
  const { payload } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      fontSize={fontSize}
    >
      {payload.map((entry, index) => {
        const isBenchmark = index === payload.length - 1;
        const item = data.find(item => item.modelSetName === entry.value);
        const optionNumber = item?.modelSetName?.includes('Opt1') ? 1 : 2;
        const colorIndex =
          index %
          (optionNumber === 1 ? OPTION1_COLORS.length : OPTION2_COLORS.length);
        const color = isBenchmark
          ? '#000000'
          : optionNumber === 1
            ? OPTION1_COLORS[colorIndex]
            : OPTION2_COLORS[colorIndex];

        return (
          <Grid item key={`item-${index}`} display="flex" alignItems="center">
            <RectangleIcon
              sx={{
                color,
                width: '12px',
                height: '12px',
                marginRight: '4px',
              }}
            />
            <Typography
              sx={{
                fontSize: fontSize,
                color,
              }}
            >
              {item?.displayPortfolioName ||
                item?.displayBenchmarkName ||
                entry.value}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
