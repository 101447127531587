import { createContext, useContext } from 'react';

import { SlideContextType } from './types';

export const SlideContext = createContext<SlideContextType | null>(null);

export function useSlideContext(): SlideContextType {
  const context = useContext(SlideContext);

  if (!context) {
    throw new Error(
      'useSlideContext must be used within a SlideContextProvider',
    );
  }

  return context;
}
