import React from 'react';
import { Grid, Typography } from '@mui/material';
import AssetMarkLogo from './LogoAMK.png';

interface FooterProps {
  text?: string;
  numberPage?: string | undefined;
}

const Footer: React.FC<FooterProps> = ({
  text = 'Powered by AI',
  numberPage = 0,
}) => {
  return (
    <Grid
      container
      item
      justifyContent="space-between"
      alignItems="center"
      sx={{
        height: '48px',
        backgroundColor: '#fff',
        borderTop: '1px solid #E4E7EC',
      }}
    >
      <Grid item>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#7D7D7D',
            fontWeight: '400',
            marginLeft: '12px',
          }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={3}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid item alignItems="flex-end" justifyContent="flex-end">
          <img
            src={AssetMarkLogo}
            alt="AssetMark"
            style={{
              height: '20px',
              width: '135px',
              borderRight: '1px solid #DEE8F2',
              paddingRight: '12px',
            }}
          />
        </Grid>
        <Grid item sx={{ marginRight: '12px' }}>
          <Typography sx={{ fontSize: '12px' }}>{numberPage}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
